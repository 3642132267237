export const TealiumUtils = (uploadedFiles) => {
    let dataTypes = uploadedFiles.map((data) => data.label);
    let dataTypeString = '';
    const dataTypeCounts = {};
    if (Object.values(dataTypes).length) {
        dataTypes.forEach((element) => {
            dataTypeCounts[element] = (dataTypeCounts[element] || 0) + 1;
        });
        dataTypeString = 'gp_upload,';
        Object.keys(dataTypeCounts).forEach(data => {
            dataTypeString += data + ',' + dataTypeCounts[data] + ',';
        });
        dataTypeString = dataTypeString.replace(/,$/, '');
    }
    return dataTypeString;
};
