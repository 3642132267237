import React, { useEffect } from "react";
import AccommodationInfo from "./AccommodationInfo";
import { useExperience } from "../../../hooks";
import useGet from "../../../../../hooks/useGet";
import { decode } from "../../../utilities/hash";
import { Link, useParams } from "react-router-dom";
import useGoogleAnalytics from "../../../../../hooks/useGoogleAnalytics";
import queryString from "query-string";
import EmployeeDetails from "../../EmployeeDetails/EmployeeDetails";
import CorrespondenceTab from "./CorrespondenceTab";
import { ExperienceCode } from "../../../../../types/Experience";
import useAuthentication from "../../../../../hooks/useAuthentication";
import NotesTab from "./NotesTab";
import { useTranslation } from "react-i18next";
import RequestDetailsTab from "./RequestDetailsTab";
import useTealium from "../../../../../hooks/useTealium";
import { TEALIUM_PAGE_VIEW } from "../../../../../constants/tealiumConstants";
import { TabContainer } from "@digitools/honest-react";
import Heading from "@digitools/honest-react/dist/lib/components/Heading";
import Spinner from "@digitools/honest-react/dist/lib/components/Spinner";
import Icon from "@digitools/honest-react/dist/lib/components/Icon";
import titleCase from "src/packages/status/utilities/titleCase";
const AccommodationDetails = () => {
    const { experience } = useExperience();
    const { trackClick } = useGoogleAnalytics();
    const { user } = useAuthentication();
    const { empNum, eventId } = useParams();
    const { t } = useTranslation();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.VIEW_ACCOMMODATION
        });
    }, []);
    const decodedEmpNum = decode(empNum);
    const accommodationEventId = decode(eventId);
    window.scrollTo(0, 0); // FIXME: wtf is this for?
    let showExtendedView = false;
    let showRequestsTab = false;
    const accommodationdetailsbaseUrl = "/status-service/accommodations/" + accommodationEventId;
    let accommodationDetailsUrl;
    if (experience === ExperienceCode.EMPLOYEE) {
        showRequestsTab = user.applicationPermissions.indexOf("statusEE.viewAccommodationStatus.viewBasicAsRequest") > -1;
        accommodationDetailsUrl = accommodationdetailsbaseUrl + "/basicDetails";
    }
    else if (experience === ExperienceCode.EMPLOYER) {
        showExtendedView = user.applicationPermissions.indexOf("statusER.viewAccommodationStatus.viewFullAsRequest") > -1;
        showRequestsTab = user.applicationPermissions.indexOf("statusER.viewAccommodationStatus.viewBasicAsRequest") > -1;
        accommodationDetailsUrl = showExtendedView ? accommodationdetailsbaseUrl + "/extendedDetails" : accommodationdetailsbaseUrl + "/basicDetails";
    }
    const { loading: loadingAccommodationDetails, data: accommodationDetailsData } = useGet({ url: accommodationDetailsUrl });
    const { data: employeeData, } = useGet({
        url: '/status-service/employee/search/searchByEmpNum',
        options: {
            params: {
                empNum: decodedEmpNum,
            },
        },
    });
    if (loadingAccommodationDetails) {
        return <div data-testid="accomodation-loading"><Spinner id="accommodation-details-spinner"/></div>;
    }
    const expPrefix = experience === "EE" ? "employee" : "employer";
    const parsed = queryString.parse(location.search);
    // By default page goes back to coverage details
    // FIXME: rearrange this if/else
    let to = `/status/${expPrefix}/${empNum}/coverageDetail`;
    let text = t("returnCoverageDetailsText");
    let gaText = "Return to coverage details";
    if (parsed.from === "search") {
        to = "/status/employer/search";
        text = t("returnToSearch");
        gaText = "Return to Search";
    }
    else if (parsed.from === "teamView") {
        to = `/status/${expPrefix}/${empNum}/coverageDetail?from=teamView`;
        text = t("returnCoverageDetailsText");
    }
    const handleBackClick = () => {
        trackClick(gaText);
    };
    let showCorrespondenceTab = false;
    let showNotesTab = false;
    if (experience === ExperienceCode.EMPLOYEE) {
        showCorrespondenceTab =
            user.applicationPermissions.indexOf("statusEE.viewAccommodationStatus.viewAsCorrespondence") > -1;
    }
    else if (experience === ExperienceCode.EMPLOYER) {
        showCorrespondenceTab =
            user.applicationPermissions.indexOf("statusER.viewAccommodationStatus.viewAsCorrespondence") > -1;
        showNotesTab = user.applicationPermissions.indexOf("statusER.viewAccommodationStatus.asNotes") > -1;
    }
    const empName = employeeData?.empFirstName && employeeData?.empLastName
        ? `${titleCase(employeeData.empFirstName)} ${titleCase(employeeData.empLastName)}`
        : null;
    return (<div data-public={true}>
      <div>
        <Icon name="long-arrow-left-regular" size="small" className="mr-2"/>
        <Link to={to} onClick={handleBackClick}>
          {text}
        </Link>
      </div>
      <br />
      <Heading data-testid="status-header">{t("statusHeader").toString()}</Heading>
      <EmployeeDetails empNum={decodedEmpNum} empEmailAddress={employeeData?.empEmailAddr}/>
      <br />
      <div className="mb-4">
        <AccommodationInfo detailsData={accommodationDetailsData} employeeName={empName}/>
      </div>
      <TabContainer>
        {showRequestsTab && <RequestDetailsTab accommodationRequestDetails={accommodationDetailsData.accommodationRequestDetails} showExtendedView={showExtendedView}/>}
        {showCorrespondenceTab && <CorrespondenceTab accommodationId={accommodationEventId}/>}
        {showNotesTab && <NotesTab accommodationId={accommodationEventId} employeeNumber={decodedEmpNum}/>}
      </TabContainer>
      {experience === ExperienceCode.EMPLOYEE &&
            <div style={{ marginTop: "1rem" }} data-testid="ee-callforhelp">{t("eecallforhelp").toString()}</div>}
    </div>);
};
export default AccommodationDetails;
