import React from 'react';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { ScalableBorderlessGrayBox, ScalableDiv } from '../../../../../../components/Formatting/StyledComponents';
import { fieldRequired, valueMatchRequired } from '../../../../utils/FormFieldValidations';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import useTranslate from '../../../../../../hooks/useTranslate';
import { differenceInYears, format, parse } from 'date-fns';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import { Icon } from '@digitools/honest-react';
import useEoiContext from '../../../../hooks/useEoiContext';
import { trim } from 'lodash';
const ESignatureContent = (props) => {
    const { t } = useTranslate();
    const { interviewAnswers } = useEoiContext();
    const dateText = () => {
        return `${t(EoiGeneralText.date)}: ${format(new Date(), 'MM/dd/yyyy')}`;
    };
    let label = `${props.applicant.firstName} ${props.applicant.lastName}`;
    let shouldValidateNameMatch = true;
    if (props.type === 'dependent' &&
        props.applicant.dateOfBirth &&
        differenceInYears(Date.now(), parse(props.applicant.dateOfBirth, 'MM/dd/yyyy', new Date())) < 18) {
        label = t(ReviewAndSignText.underEighteenLabel) + label;
        shouldValidateNameMatch = false;
    }
    return (<ScalableDiv data-testid={'esignature-content'}>
      <Label theme={themes.lincoln} aria-label={`${props.applicant.firstName}
      ${props.applicant.lastName}`} style={{ marginBottom: '0.5rem', paddingLeft: '1.5rem' }} data-private={true}>
        {label}
      </Label>
      <ScalableBorderlessGrayBox>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <TextInput theme={themes.lincoln} 
    //@ts-ignore FIXME: typing error
    field={props.type === 'dependent' ? props.applicant.dependentId : props.type} label={t(ReviewAndSignText.firstLastName)} placeholder={t(ReviewAndSignText.firstLastName)} validate={value => shouldValidateNameMatch ? valueMatchRequired(value?.trim(), trim(props.applicant.firstName) + " " + trim(props.applicant.lastName)) : fieldRequired(value)} validateOnBlur={true} aria-label={'E-Signature'} data-testid={props.type === 'dependent'
            ? `${props.type}-${props.applicant.dependentId}-signature`
            : `${props.type}-signature`} data-private={true}/>
            {props.valid && (<Icon name={'success'} color={'secondary'} theme={themes.lincoln} size={'medium'} style={{ paddingTop: '0.4375rem', marginLeft: '0.5rem' }}/>)}
          </div>

          <span style={{ fontSize: '0.875rem', marginTop: '-1rem' }}>
            {' '}
            {/* Need the negative margin here because the styles you pass to text input don't apply to the container that the input is embedded into.*/}
            {dateText()}
          </span>
        </div>
      </ScalableBorderlessGrayBox>
    </ScalableDiv>);
};
export default ESignatureContent;
