import { Experience } from '../types/Experience';
import { EMPLOYEE, EMPLOYER, INTERNAL } from './userRoleUtils';
export const getHomeUrl = (experience, roles) => {
    if (experience === Experience.EMPLOYEE)
        return '/employee/home';
    else if (experience === Experience.EMPLOYER) {
        return '/employer/home';
    }
    else if (roles?.includes(EMPLOYEE)) {
        return '/employee/home';
    }
    else if (roles?.includes(EMPLOYER)) {
        return '/employer/home';
    }
    else if (roles?.includes(INTERNAL)) {
        return '/employer/home';
    }
    else {
        return '/public/login';
    }
};
export const getFormsUrl = (experience) => {
    if (experience === Experience.EMPLOYEE)
        return '/employee-forms';
    else if (experience === Experience.EMPLOYER) {
        return '/employer/resource-center';
    }
    else {
        return '/public/login';
    }
};
