import { Col } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField from '../ReviewField';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
const ReviewOtherFlow = () => {
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { relationships } = useCliCustomerConfigContext();
    const shouldDisplayOtherRelationship = absenceData?.relationship === 'OT';
    const shouldDisplayRelationship = (absenceData?.leaveReason === 'SC' || absenceData?.leaveReason === 'WC');
    return (<>
            {absenceData?.leaveType && (<>
                    {shouldDisplayRelationship && (<Col xs={12} md={6} lg={6} data-testid='relationship'>
                            <ReviewField label={t({ english: 'Relationship', spanish: 'relación' })} value={absenceData && (t(relationships.filter(relationship => relationship.code == absenceData?.relationship)[0]))}/>
                        </Col>)}
                    {shouldDisplayOtherRelationship && (<Col xs={12} md={6} lg={6} data-testid='otherRelationship'>
                            <ReviewField label={t(formLabels.otherRelationship)} value={absenceData && absenceData.otherRelationship}/>
                        </Col>)}
                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-testid='leaveBeginDate' label={t(formLabels.leaveBeginDateShortLabel)} value={absenceData && absenceData.leaveBeginDate}/>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-testid='leaveEndDate' label={t(formLabels.leaveEndDateShortLabel)} value={absenceData.leaveEndDate}/>
                    </Col>
                </>)}
        </>);
};
export default ReviewOtherFlow;
