import React from 'react';
import { Heading, Link } from '@digitools/honest-react';
import styled from 'styled-components';
import useTranslate from '../../hooks/useTranslate';
import ResetPassword from './resetPassword/ResetPassword';
import { SecurityAndSettingsText } from './i18n/SecurityAndSettingsText';
import withGoogleAnalytics from '../../hocs/WithGoogleAnalytics';
import useAuthentication from '../../hooks/useAuthentication';
import { getEnvVariable, isProd } from '../../utils/envUtils';
const StyledHRElement = styled('hr') `
  margin: 1rem 0 1.5rem;
`;
const StyledHeading = styled(Heading) `
  font-family: "PublicoBanner-Light", "Publico Banner Light", "Publico Banner", sans-serif;
  font-weight: 200 !important;
  font-style: normal;
  font-size: 34px !important;
`;
const LfgRestrictedPasswordReset = () => {
    const { t } = useTranslate();
    return (<>
      {t(SecurityAndSettingsText.resetPWRestrictedMsg)}
      <Link data-testid="publicReset" target="_blank" href={getEnvVariable('lfgPublicPasswordResetUrl')}>
          {t(SecurityAndSettingsText.resetPWRestrictedLink)}
      </Link>
    </>);
};
const SecurityAndSettingsContainer = () => {
    const { t } = useTranslate();
    const { user } = useAuthentication();
    return (<>
      <StyledHeading data-public={true} elemType={'h2'} elemStyle={'h3'} color={'primary'} data-testid="security-and-settings-heading">
        {t(SecurityAndSettingsText.profileHeading)}
        </StyledHeading>
        <StyledHRElement />

        {(user?.auth0Roles?.includes('MLPEmployee') || (!isProd() && user?.isIdentityVerified && user?.auth0Roles?.includes('MLPEmployee'))) ?
            <LfgRestrictedPasswordReset /> :
            <ResetPassword data-testid="reset-password"></ResetPassword>}
    </>);
};
export default withGoogleAnalytics(SecurityAndSettingsContainer);
