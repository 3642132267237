import React from 'react';
import { Col, Heading, Row, themes } from '@digitools/honest-react';
import GetHelpModal from '../../GetHelpModal';
const ReportDetailHeader = props => {
    const { reportFilterCategory } = props;
    return (<>
      <Row style={{ marginTop: '0.2rem' }}>
        <Col>
          <Heading elemType='h2' style={{ paddingTop: '1rem', paddingBottom: '1rem' }} color={'primary'} theme={themes.lincoln}>
            {'My report selection for: '} {reportFilterCategory}
          </Heading>
        </Col>
        <Col>
          <GetHelpModal headerText={'Create My Report - Help Guide'}/>
        </Col>
      </Row>
    </>);
};
export default ReportDetailHeader;
