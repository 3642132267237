import { useEffect } from 'react';
function useAnyAction(callback) {
    useEffect(() => {
        if (callback) {
            // trigger callback on key, click, or mouse move
            window.addEventListener('keydown', callback);
            window.addEventListener('click', callback);
            window.addEventListener('mousemove', callback);
        }
        return () => {
            if (callback) {
                window.removeEventListener('keydown', callback);
                window.removeEventListener('click', callback);
                window.removeEventListener('mousemove', callback);
            }
        };
    }, [callback]);
}
export default useAnyAction;
