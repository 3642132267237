import styled from 'styled-components';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { Button } from '@digitools/honest-react';
export const BorderLessGrayBox = styled(Box) `
  background-color: ${themes.lincoln.palette.grayLightest};
  border: none;
  display: flex;
`;
export const GraySpacerDiv = styled('div') `
  background-color: ${themes.lincoln.palette.grayLightest};
  margin-left: 0;
  margin-right: auto;
  height: 6px;
`;
export const GrayTextDiv = styled('div') `
  color: ${themes.lincoln.palette.grayDark};
  font-weight: 500;
  font-size: 0.875rem;
`;
// margin-bottom: 0.5rem;
export const ScalableDiv = styled('div') `
  width: 50%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const ScalableBorderlessGrayBox = styled(Box) `
  margin-bottom: 1rem;
  width: 75%;
  background-color: ${themes.lincoln.palette.grayLightest};
  margin-left: 1.5rem;
  border: none;
  display: flex;
  @media (max-width: 500px) {
    width:100%;
    margin-left: 0;
  }
`;
export const FlexRow = styled(Row) `
@media (max-width: 500px) {
  flex-direction: column;
}
`;
export const RedBulletList = styled('ul') `
  margin-top: .25rem;
  margin-left: 2rem;
  li {
    color: ${themes.lincoln.palette.primary};
    margin-top: .25rem;
    list-style-position: outside;
    span {
      color: black;
    }
  }
`;
export const StyledDiv = styled('div') `
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 765px) {
    flex-direction: column-reverse;
  }
`;
export const StyledButton = styled(Button) `
  text-wrap: wrap;
  width: 100% !important;
`;
