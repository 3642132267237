import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { TabSection, CompositeTable, Button, Icon } from '@digitools/honest-react';
import { compareDesc, parse } from 'date-fns';
// @ts-ignore
import styles from './AccommodationDetails.scss';
import { useTranslation } from 'react-i18next';
import RequestExtendedDetails from './RequestExtendedDetails';
import dashedDateFormatToString from '../../../utilities/dashedDateFormatToString';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
const ExpandCollapseCell = ({ data }) => {
    const requestsRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    const setNoteHeight = () => {
        // @ts-ignore
        setHeight(requestsRef.current.clientHeight);
    };
    useEffect(() => {
        // @ts-ignore
        setHeight(requestsRef.current.clientHeight);
        window.addEventListener('resize', setNoteHeight);
        return () => window.removeEventListener('resize', setNoteHeight);
    });
    return (<div style={{ paddingBottom: `${height}px` }} data-expanded={expanded} data-testid='expand-collapse-cell'>
            <Button aria-label='Expand/Collapse' onClick={toggleExpand} size={'small'} className={styles.icon}>
                {expanded ? <Icon name='subtract' color={'primary'}/> : <Icon name='add' color={'primary'}/>}
            </Button>
            {data.expandCollapse.accommodationBasicDetails.accmNum}
            <div ref={requestsRef} className={styles.rowContent} data-expanded={expanded} style={{ border: 'none' }}>
                <RequestExtendedDetails requestDetails={data.expandCollapse.accommodationExtendedDetails}/>
            </div>
        </div>);
};
const RequestDetailsTab = React.memo((props) => {
    const { trackEvent } = useTealium();
    const { t } = useTranslation();
    const formattedData = useMemo(() => {
        const formattedDataList = [];
        props.accommodationRequestDetails.forEach(detail => {
            const formattedRequest = {
                accommodationExtendedDetails: detail.accommodationExtendedDetails,
                accommodationBasicDetails: {
                    accmNum: detail.accommodationBasicDetails.accmNum,
                    accmRecdDte: dashedDateFormatToString(detail.accommodationBasicDetails.accmRecdDte),
                    accmSttusCde: detail.accommodationBasicDetails.accmSttusCde,
                    accomTypdesc: detail.accommodationBasicDetails.accomTypdesc,
                    prvdAccmTypDesc: detail.accommodationBasicDetails.prvdAccmTypDesc,
                    statusCode_EN: detail.accommodationBasicDetails.statusCode_EN,
                    statusCode_ES: detail.accommodationBasicDetails.statusCode_ES,
                }
            };
            formattedDataList.push(formattedRequest);
        });
        return formattedDataList;
    }, [props.accommodationRequestDetails]);
    const requestsColumns = [
        {
            Header: t('request') + ' ' + t('receivedDateText'),
            accessor: 'accommodationBasicDetails.accmRecdDte',
            id: 'accommodationBasicDetails.accmRecdDte',
            sortMethod: (a, b) => compareDesc(parse(a, 'yyyy-MM-dd', new Date()), parse(b, 'yyyy-MM-dd', new Date())),
        },
        {
            Header: t('requestType').toString(),
            accessor: 'accommodationBasicDetails.accomTypdesc',
            id: 'accommodationBasicDetails.accomTypdesc',
        },
        {
            Header: t('providedType').toString(),
            accessor: 'accommodationBasicDetails.prvdAccmTypDesc',
            id: 'accommodationBasicDetails.prvdAccmTypDesc',
        },
        {
            Header: t('status').toString(),
            accessor: 'accommodationBasicDetails.statusCode_EN',
            id: 'accommodationBasicDetails.statusCode_EN',
        },
    ];
    if (props.showExtendedView) {
        requestsColumns.unshift({
            Header: t('request').toString(),
            accessor: '',
            id: 'expandCollapse',
            Cell: ExpandCollapseCell
        });
    }
    else {
        requestsColumns.unshift({
            Header: t('request').toString(),
            accessor: 'accommodationBasicDetails.accmNum',
            id: 'accommodationBasicDetails.accmNum',
        });
    }
    const defaultRequestsSort = [
        {
            id: 'accommodationBasicDetails.accmRecdDte',
            desc: false,
        },
    ];
    const trackRequestTabClick = useCallback(() => trackEvent({
        event_action: TEALIUM_EVENT.EVENT_ACTION.TAB, event_type: TEALIUM_EVENT.EVENT_TYPE.AS_REQUEST, event_name: 'AS Requests Tab'
    }), [trackEvent]);
    return (<TabSection name='as-requests-tab' label='Requests' onClick={trackRequestTabClick}>
            {props.accommodationRequestDetails && props.accommodationRequestDetails.length > 0 && (<CompositeTable data-testid='requests-table' data={formattedData} columns={requestsColumns} defaultSorted={defaultRequestsSort} paginated={true} defaultPageSize={10} isHoverable={false}/>)}
        </TabSection>);
});
export default RequestDetailsTab;
