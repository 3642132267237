import React, { useEffect, useState } from 'react';
import { Button, Col, Heading, Icon, Row, Spinner, themes } from '@digitools/honest-react';
import styled from 'styled-components';
import { getClmLveDetails } from '../../api/RscApi';
import useAuthentication from '../../hooks/useAuthentication';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import usePortalMessenger from '../../hooks/usePortalMessenger';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import { CustomEmployeeIdText, CustomEmployeeText } from '../../utils/CustomExperienceText';
import { formatInTimeZone } from 'date-fns-tz';
const RSC_CLAIM_DETAILS_PAGE = '/rsc/claim/details';
const RSC_LEAVE_DETAILS_PAGE = '/rsc/leave/details';
const Bold = styled('span') `
  font-weight: bold;
`;
const StyledRow = styled(Row) `
  margin-top: 0.5rem !important;
`;
const SpinnerContainer = styled('div') `
  text-align: center;
`;
const StyledButton = styled(Button) `
  background-color: #ffffff;
  padding: 0.05rem;
`;
const ClaimOrLeaveDetail = ({ searchData, clearDetails }) => {
    const [details, setDetails] = useState();
    const { authHeader, user } = useAuthentication();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { addMessage, clearMessages } = usePortalMessenger();
    const custDiv = user ? user.customerDiv || '' : '';
    const custSerial = user ? user.customerSerial || '' : '';
    const getPage = () => {
        return (searchData.product || '').toLowerCase() === 'leave' ? RSC_LEAVE_DETAILS_PAGE : RSC_CLAIM_DETAILS_PAGE;
    };
    useEffect(() => {
        const getDetails = async () => {
            const clmLveDetails = {
                custDiv,
                custSeral: custSerial,
                ntcfId: searchData.ntcf_id,
                prodType: searchData.product,
            };
            try {
                const response = await getClmLveDetails(authHeader(), clmLveDetails);
                setDetails(response.data);
            }
            catch (e) {
                addMessage({ type: 'error', message: ['Unable to find details.', 'Unable to find details.'] });
                // @ts-ignore
                setDetails({ clmDetails: {}, lveDetails: {} });
            }
        };
        getDetails();
        return () => {
            clearMessages();
        };
    }, []);
    const goBack = () => {
        trackClickWithPage('Back', getPage());
        clearDetails(undefined);
    };
    return (<>
      {!details ? (<SpinnerContainer>
          <Spinner id='claim-or-leave-detail-spinner' isShown={true}/>
        </SpinnerContainer>) : (<>
          <div className='mb-3' data-testid={'claim-or-leave-detail'}>
            <StyledButton data-testid='claim-leave-details-back' type='button' buttonType='text' onClick={goBack}>
              <Icon theme={themes.lincoln} color={'secondary'} name='arrow-circle-left'>
                &nbsp;
              </Icon>
              Back
            </StyledButton>
          </div>
          <div className='mb-5'>
            {details && searchData.product.toLowerCase() !== 'leave' && <ClaimDetails details={details.clmDetails}/>}
            {details && searchData.product.toLowerCase() === 'leave' && <LeaveDetails details={details.lveDetails}/>}
          </div>
        </>)}
    </>);
};
const getFormattedDateString = (date) => {
    if (!date || date.includes('01/01/1900') || date.includes('12/31/1899')) {
        return '-';
    }
    return new Date(date).toLocaleDateString();
};
export const getFormattedDateTimeString = (date) => {
    if (!date || date.includes('01/01/1900') || date.includes('12/31/1899')) {
        return '-';
    }
    return formatInTimeZone(new Date(), 'America/New_York', 'P');
};
const getDetailString = (detail) => {
    if (!detail) {
        return '-';
    }
    const trimmedDetail = detail.trim();
    if (!trimmedDetail) {
        return '-';
    }
    return detail;
};
const ClaimDetails = ({ details }) => {
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RSC,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.RSC.CLAIM_DETAILS,
        });
    }, []);
    return (<>
      <Heading elemType={'h1'} theme={themes.lincoln} data-testid='claim-detail' className='mb-3'>
        Recent status change claim detail
      </Heading>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>{CustomEmployeeText()} Name</Bold>
        </Col>
        <Col data-private={true} sm={8} className='mt-2'>
          {getDetailString(details?.employeeName)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>{CustomEmployeeIdText()}</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.employeeId)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Claim ID</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.claimId)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Coordinated leave ID</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.coordinatedLeaveId)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Product name</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.productName)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Last day worked</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.lastDayWorked)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Date of disability</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.disabilityDate)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Benefit begin date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.benefitBeginDate)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Current status</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.currentStatus)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Status reason</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details?.statusReason)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Approved through date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.approvedThruDate)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Scheduled part time return to work date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.scheduledPartTime)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Scheduled full time return to work date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details?.scheduledFullTime)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Time stamp</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {details?.timeStamp}
        </Col>
      </StyledRow>
    </>);
};
const LeaveDetails = ({ details }) => {
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RSC,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.RSC.LEAVE_DETAILS,
        });
    }, []);
    return (<>
      <Heading data-testid='leave-detail' className='mb-3'>
        Recent Status Change Leave Detail
      </Heading>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>{CustomEmployeeText()} Name</Bold>
        </Col>
        <Col data-private={true} sm={8} className='mt-2'>
          {getDetailString(details.employeeName)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>{CustomEmployeeIdText()}</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.employeeId)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave ID</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.leaveId)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Coordinated Claim Number</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.coordinatedClaimNo)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Product Name</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.productName)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave Type</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.leaveType)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave Reason</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.leaveReason)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave Category</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.leaveCategory)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Current Status</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.currentStatus)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Status Reason</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getDetailString(details.statusReason)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave Begin Date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details.leaveBeginDate)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Leave End Date</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {getFormattedDateString(details.leaveEndDate)}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm={4} className='mt-2'>
          <Bold>Time Stamp</Bold>
        </Col>
        <Col sm={8} className='mt-2'>
          {details.timeStamp}
        </Col>
      </StyledRow>
    </>);
};
export default ClaimOrLeaveDetail;
