export const workStatesModel = [
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'AWS',
        stateName: 'All',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'AK',
        stateName: 'Alaska',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'AL',
        stateName: 'Alabama',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'AR',
        stateName: 'Arkansas',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'AZ',
        stateName: 'Arizona',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'CA',
        stateName: 'California',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'CO',
        stateName: 'Colorado',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'CT',
        stateName: 'Connecticut',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'DC',
        stateName: 'District of Columbia',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'DE',
        stateName: 'Delaware',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'FL',
        stateName: 'Florida',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'GA',
        stateName: 'Georgia',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'HI',
        stateName: 'Hawaii',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'IA',
        stateName: 'Iowa',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'ID',
        stateName: 'Idaho',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'IL',
        stateName: 'Illinois',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'IN',
        stateName: 'Indiana',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'KS',
        stateName: 'Kansas',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'KY',
        stateName: 'Kentucky',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'LA',
        stateName: 'Louisiana',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MA',
        stateName: 'Massachusetts',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MD',
        stateName: 'Maryland',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'ME',
        stateName: 'Maine',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MI',
        stateName: 'Michigan',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MN',
        stateName: 'Minnesota',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MO',
        stateName: 'Missouri',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MS',
        stateName: 'Mississippi',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'MT',
        stateName: 'Montana',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NC',
        stateName: 'North Carolina',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'ND',
        stateName: 'North Dakota',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NE',
        stateName: 'Nebraska',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NH',
        stateName: 'New Hampshire',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NJ',
        stateName: 'New Jersey',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NM',
        stateName: 'New Mexico',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NV',
        stateName: 'Nevada',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'NY',
        stateName: 'New York',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'OH',
        stateName: 'Ohio',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'OK',
        stateName: 'Oklahoma',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'OR',
        stateName: 'Oregon',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'PA',
        stateName: 'Pennsylvania',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'RI',
        stateName: 'Rhode Island',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'SC',
        stateName: 'South Carolina',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'SD',
        stateName: 'South Dakota',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'TN',
        stateName: 'Tennessee',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'TX',
        stateName: 'Texas',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'UT',
        stateName: 'Utah',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'VA',
        stateName: 'Virginia',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'VT',
        stateName: 'Vermont',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'WA',
        stateName: 'Washington',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'WI',
        stateName: 'Wisconsin',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'WV',
        stateName: 'West Virginia',
    },
    {
        checked: false,
        locationTypeCode: 1,
        stateCode: 'WY',
        stateName: 'Wyoming',
    },
];
export const workProvincesModel = [
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'AWL',
        provinceName: 'All',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'AB',
        provinceName: 'Alberta',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'BC',
        provinceName: 'British Columbia',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'MB',
        provinceName: 'Manitoba',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'NB',
        provinceName: 'New Brunswick',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'NL',
        provinceName: 'Newfoundland-Labrador',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'NS',
        provinceName: 'Nova Scotia',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'NT',
        provinceName: 'Northwest Territories',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'NU',
        provinceName: 'Nunavut',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'ON',
        provinceName: 'Ontario',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'PE',
        provinceName: 'Prince Edward Island',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'QC',
        provinceName: 'Quebec',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'SK',
        provinceName: 'Saskatchewan',
    },
    {
        checked: false,
        locationTypeCode: 2,
        provinceCode: 'YT',
        provinceName: 'Yukon',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'AS',
        provinceName: 'American Somoa',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'GU',
        provinceName: 'Guam',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'IP',
        provinceName: 'Island Possessions',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'MP',
        provinceName: 'Northern Mariana Islands',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'PR',
        provinceName: 'Puerto Rico',
    },
    {
        checked: false,
        locationTypeCode: 3,
        provinceCode: 'VI',
        provinceName: 'Virgin Islands',
    },
    {
        checked: false,
        locationTypeCode: 4,
        provinceCode: 'OT',
        provinceName: 'Other',
    },
];
