import React, { useState } from 'react';
import { Row, Col, Checkbox, CheckboxGroup, SelectAllCheckbox, Box } from '@digitools/honest-react';
import { handleProductAllSelection, checkBoxSelection } from '../api';
import { useFieldApi } from 'informed';
const ViewStatus = (props) => {
    const [userInfo, setUserInfo] = useState(props.userInformation);
    const [viewBasicAsRequestChecked, setViewBasicAsRequestChecked] = useState(userInfo.viewBasicAsRequest);
    let hasCorrespondenceAccess = false;
    let hasPaymentsAccess = false;
    let hasClaimNotesAccess = false;
    let hasLeaveNotesAccess = false;
    let hasEmployeeDetailsAccess = false;
    const hasStatusERAccess = props.permissions.permission.statusER; // TODO: pretty sure we can make permissions prop not undefined
    if (hasStatusERAccess && hasStatusERAccess.claimLeaveStatus) {
        hasCorrespondenceAccess = hasStatusERAccess.claimLeaveStatus.viewCorrespondence;
        hasPaymentsAccess = hasStatusERAccess.claimLeaveStatus.viewPaymentInformation;
        hasClaimNotesAccess = hasStatusERAccess.claimLeaveStatus.viewClaimNotes;
        hasLeaveNotesAccess = hasStatusERAccess.claimLeaveStatus.viewLeaveNotes;
        hasEmployeeDetailsAccess = hasStatusERAccess.claimLeaveStatus.viewEmployeeDetails;
    }
    let hasViewBasicAsRequest = false;
    let hasViewFullAsRequest = false;
    let hasViewAsCorrespondence = false;
    let hasAsNotes = false;
    if (hasStatusERAccess && hasStatusERAccess.viewAccommodationStatus) {
        hasViewBasicAsRequest = hasStatusERAccess.viewAccommodationStatus.viewBasicAsRequest;
        hasViewFullAsRequest = hasStatusERAccess.viewAccommodationStatus.viewFullAsRequest;
        hasViewAsCorrespondence = hasStatusERAccess.viewAccommodationStatus.viewAsCorrespondence;
        hasAsNotes = hasStatusERAccess.viewAccommodationStatus.asNotes;
    }
    const asCheckBoxGroup = [
        {
            fieldId: 'viewFullAsRequest',
            label: 'View AS Extended Request',
            initialValue: userInfo.viewFullAsRequest,
            fieldApi: useFieldApi('viewFullAsRequest'),
        },
        {
            fieldId: 'viewAsCorrespondence',
            label: 'View AS Correspondence',
            initialValue: userInfo.viewAsCorrespondence,
            fieldApi: useFieldApi('viewAsCorrespondence'),
        },
        {
            fieldId: 'asNotes',
            label: 'View AS Notes',
            initialValue: userInfo.asNotes,
            fieldApi: useFieldApi('asNotes'),
        },
    ];
    /**
     * Used to handle the grouping of view As options & paired uncheck behavior
     */
    const onViewBasicAsRequestOptionChange = (event) => {
        userInfo.viewBasicAsRequest = event.currentTarget.checked; // also part of the redundant/unecessary step (see below)
        setViewBasicAsRequestChecked(event.currentTarget.checked); // required for disabled logic
        if (!event.currentTarget.checked) {
            // set values to false
            // This below is what we should be using, this tells the fields to update their value & rerender (Checkbox extends a informed Field)
            asCheckBoxGroup[0].fieldApi.setValue(false);
            asCheckBoxGroup[1].fieldApi.setValue(false);
            asCheckBoxGroup[2].fieldApi.setValue(false);
            // this is redundant - why are we not using fieldApi's current values to submit?? this & all the onChange updates to userInfo on each checkbox could be avoided
            userInfo.viewFullAsRequest = false;
            userInfo.viewAsCorrespondence = false;
            userInfo.asNotes = false;
        }
    };
    return (<>
      {hasStatusERAccess ? (<div style={{ marginTop: '1.5rem' }}>
          <Box type='header' color={'grayLighter'}>
            <Checkbox data-testid='viewStatus' field='viewStatus' label={'View Status'} initialValue={userInfo.viewStatus} onChange={props.onViewStatusOptionChange}/>
          </Box>
          <Box>
            <Row>
              <Col md={6} xs={12} sm={12}>
                {hasCorrespondenceAccess && (<div>
                    <Checkbox data-testid='viewCorrespondence' field='viewCorrespondence' label={'View Correspondence'} disabled={!props.statusChange} initialValue={userInfo.viewCorrespondence} onChange={(event) => (userInfo.viewCorrespondence = event.currentTarget.checked)}/>
                  </div>)}
                {hasPaymentsAccess && (<div style={{ marginTop: '.8rem' }}>
                    <Checkbox data-testid='viewPayments' field='viewPayments' label={'View Payment Information'} disabled={!props.statusChange} initialValue={userInfo.viewPayments} onChange={(event) => (userInfo.viewPayments = event.currentTarget.checked)}/>
                  </div>)}
                {hasClaimNotesAccess &&
                <div style={{ marginTop: '.8rem' }}>
                    <Checkbox data-testid='viewClaimNotes' field='viewClaimNotes' label={'View Claim Notes'} disabled={!props.statusChange} initialValue={userInfo.viewClaimNotes} onChange={(event) => (userInfo.viewClaimNotes = event.currentTarget.checked)}/>
                  </div>}
                {hasLeaveNotesAccess &&
                <div style={{ marginTop: '.8rem' }}>
                    <Checkbox data-testid='viewLeaveNotes' field='viewLeaveNotes' label={'View Leave Notes'} disabled={!props.statusChange} initialValue={userInfo.viewLeaveNotes} onChange={(event) => (userInfo.viewLeaveNotes = event.currentTarget.checked)}/>
                  </div>}

                {hasEmployeeDetailsAccess &&
                <div style={{ marginTop: '.8rem' }}>
                    <Checkbox data-testid='viewEmployeeDetails' field='viewEmployeeDetails' label={'View Employee Details'} disabled={!props.statusChange} initialValue={userInfo.viewEmployeeDetails} onChange={(event) => (userInfo.viewEmployeeDetails = event.currentTarget.checked)}/>
                  </div>}
              </Col>

              <Col md={6} xs={12} sm={12}>
                {hasViewBasicAsRequest && (<div style={{ marginTop: '.8rem' }}>
                    <Checkbox data-testid='viewBasicAsRequest' field='viewBasicAsRequest' label={'View Accommodation Status (AS)'} disabled={!props.statusChange} initialValue={userInfo.viewBasicAsRequest} onChange={(event) => onViewBasicAsRequestOptionChange(event)}/>
                  </div>)}

                {hasViewFullAsRequest && (<div style={{ marginTop: '.8rem', marginLeft: '1rem' }}>
                    <Checkbox data-testid={asCheckBoxGroup[0].fieldId} field={asCheckBoxGroup[0].fieldId} label={asCheckBoxGroup[0].label} initialValue={asCheckBoxGroup[0].initialValue} disabled={!props.statusChange || !viewBasicAsRequestChecked} onChange={(event) => (userInfo.viewFullAsRequest = event.currentTarget.checked)}/>
                  </div>)}

                {hasViewAsCorrespondence && (<div style={{ marginTop: '.8rem', marginLeft: '1rem' }}>
                    <Checkbox data-testid={asCheckBoxGroup[1].fieldId} field={asCheckBoxGroup[1].fieldId} label={asCheckBoxGroup[1].label} initialValue={asCheckBoxGroup[1].initialValue} disabled={!props.statusChange || !viewBasicAsRequestChecked} onChange={(event) => (userInfo.viewAsCorrespondence = event.currentTarget.checked)}/>
                  </div>)}

                {hasAsNotes && (<div style={{ marginTop: '.8rem', marginLeft: '1rem' }}>
                    <Checkbox data-testid={asCheckBoxGroup[2].fieldId} field={asCheckBoxGroup[2].fieldId} label={asCheckBoxGroup[2].label} initialValue={asCheckBoxGroup[2].initialValue} disabled={!props.statusChange || !viewBasicAsRequestChecked} onChange={(event) => (userInfo.asNotes = event.currentTarget.checked)}/>
                  </div>)}
              </Col>
            </Row>

             {/* TODO: TEST THIS LOGIC BELOW */}
            {props.statusSensitiveData.length > 0 &&
                props.customerConfig.sharedFields.filter(fields => fields.application === 'Status').length > 0 ? (<div style={{ marginTop: '1rem' }}>
                <CheckboxGroup field={'statusSensitiveDataGroup'} label={'Status Sensitive Data'} validateOnChange={true}>
                  <Row>
                    <Col lg={4}>
                      <SelectAllCheckbox field={'statusSensitiveAll'} disabled={!props.statusChange} onChange={(event) => handleProductAllSelection({ code: 'statusSensitiveAll', label: 'All', checked: event.currentTarget.checked }, props.statusSensitiveData, 'statusSensitive', props.userInformation, props.customerConfig)}/>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '-0.05rem' }}>
                    {checkBoxSelection(props.statusSensitiveData, 'statusSensitive', props.statusChange, props.userInformation, props.customerConfig)}
                  </Row>
                </CheckboxGroup>
              </div>) : (<></>)}
          </Box>
        </div>) : (<></>)}
    </>);
};
export default ViewStatus;
