import FileSaver from 'file-saver';

const downloadPdf = (data, docTitle) => {
  const blob = new Blob([data], { type: 'application/pdf' });

  let fileName = `${docTitle}_document.pdf`;

  FileSaver.saveAs(blob, fileName);
};

export default downloadPdf;
