import React from 'react';
import { Link } from 'react-router-dom';
import useTranslate from '../../../hooks/useTranslate';
import { getEnvVariable } from '../../../utils/envUtils';
import { CustomEmployeeText } from '../../../utils/CustomExperienceText';
//@ts-ignore
import styles from './NavMenu.scss';
import useRouteCheck from 'src/hooks/useRouteCheck';
import useAuthentication from 'src/hooks/useAuthentication';
//TODO: clean up styles on this file - coming/overwriting from too many places
export const navL3ItemStyle = {
    display: 'block',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    paddingLeft: '0',
    paddingRight: '0',
    marginTop: '0'
};
//FIXME: fix the messy things to the navMenu (whathavetheydonetomyboy.jpg) -- lots of backend logic here that shoulld be in ui-service
/**
 * Used to display a top level Nav Link in the NavMenu
 * Does not look at or render any children, click will navigate directly to the navLink url //FIXME: this design principle was broken recently - needs to be fixed
 * uses externalLink & angular flags to pivot to build the right kind of link
 */
const NavLink = ({ angular, children, cnvgSso, externalLink, proveRequired, id, index, l4bSso, label, lamsSSO, style, url = '', ...rest }) => {
    const { t } = useTranslate();
    const { navigationCheck } = useRouteCheck();
    const { user } = useAuthentication();
    return (<>
      {angular && (<a key={`navMenu-child-${index}`} data-testid='navMenu-child-angular' href={getEnvVariable('mlpHomeUrl') + url} data-public={true} className={styles.navItem} style={style ? style : navL3ItemStyle} {...rest}>
          {CustomEmployeeText(t(label))}
          {children}
        </a>)}
      {!externalLink && !angular && !cnvgSso && !lamsSSO && !l4bSso && (<Link key={`navMenu-child-${index}`} data-testid='navMenu-child' data-public={true} to={url} className={styles.navItem} style={style ? style : navL3ItemStyle} {...rest}>
          {CustomEmployeeText(t(label))}
          {children}
        </Link>)}
      {/* FIXME: this below issue is infecting all of navmenu code... its so ugly now... NEED to fix this soon or nav debugging will be a nightmare */}
      {/* FIXME: merge these 2 conditions below for external links - they shouldn't have umnique behavior on the UI -- should be folded into the UI service payload so only flags dictate what the UI should do (blockNav, sso etc) */}
      {externalLink && (id === 'VIS' || id === 'DHMO') && (<a onClick={navigationCheck(() => {
                window.open(url, '_blank');
            }, id)} className={styles.navItem} key={`navMenu-child-${index}`} data-public={true} data-testid='navMenu-child-external' style={style ? style : navL3ItemStyle}>
          {CustomEmployeeText(t(label))}
          {children}
        </a>)}
      {externalLink && !(id === 'VIS' || id === 'DHMO') && !cnvgSso && !lamsSSO && !l4bSso && <>
        {proveRequired && !user?.isIdentityVerified ? <Link key={`navMenu-child-${index}`} data-testid='navMenu-child-external' to={{ pathname: '/prove' }} data-public={true} className={styles.navItem} style={style ? style : navL3ItemStyle} {...rest}>
          {CustomEmployeeText(t(label))}
          {children}
        </Link>
                : <a key={`navMenu-child-${index}`} data-testid='navMenu-child-external' href={url} data-public={true} target='_blank' className={styles.navItem} style={style ? style : navL3ItemStyle} {...rest}>
            {CustomEmployeeText(t(label))}
            {children}
          </a>}
      </>}
      {/* FIXME: pretty sure this link will never work... there's no href, just a blank open new tab? --  */}
      {/* FIXME: for this case there's a hacky passed in onClick to hand the url one layer above for this condition - this should def be changed */}
      {(cnvgSso || lamsSSO || l4bSso) && externalLink && (<a key={`navMenu-child-${index}`} data-testid='navMenu-child-external' target='_blank' data-public={true} className={styles.navItem} style={style ? style : navL3ItemStyle} {...rest}>
          {CustomEmployeeText(t(label))}
          {children}
        </a>)}
    </>);
};
export default NavLink;
