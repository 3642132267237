import { Col, Row } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import { VanityPageText } from '../constants/VanityPageText';
import { useNavigate } from 'react-router-dom';
import useStepperContext from './useStepperContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { StyledBox, StyledHeading, StepperButton, BackButton } from './StyledComponents';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
const VanityPageQuestions3 = () => {
    const { t } = useTranslate();
    const navigate = useNavigate();
    const { back, setIsLfg, goToStep, setIsMigrated } = useStepperContext();
    const { trackEvent } = useTealium();
    const { language } = useExperience();
    const handleOnClickNotMigrated = () => {
        setIsLfg(false);
        setIsMigrated(false);
        goToStep(5);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.notMigrated.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickMigrated = () => {
        setIsLfg(true);
        setIsMigrated(true);
        goToStep(5);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.alreadyMigrated.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickNotSure = () => {
        navigate(`/public/learn-more?${language === Language.SPANISH ? '&lang=es' : ''}`);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.unknown.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    return (<div>
      <StyledBox>
        <Row>
          <Col>
            <StyledHeading elemType='h2'>
              {t(VanityPageText.migrateAccount)}
            </StyledHeading>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} className='mx-auto'>
            <StepperButton className='d-block w-100 mb-2' buttonType='primary' onClick={handleOnClickMigrated}>
              {t(VanityPageText.alreadyMigrated)}
            </StepperButton>
            <StepperButton className='d-block w-100 ml-0 mb-2' buttonType='primary' onClick={handleOnClickNotMigrated}>
              {t(VanityPageText.notMigrated)}
            </StepperButton>
            <StepperButton className='d-block w-100 ml-0 mb-3' buttonType='primary' onClick={handleOnClickNotSure}>
              {t(VanityPageText.unknown)}
            </StepperButton>
          </Col>
        </Row>
      </StyledBox>
      <BackButton onClick={() => back()}/>
    </div>);
};
export default VanityPageQuestions3;
