import { useNavigate } from 'react-router-dom';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import React from 'react';
import { encode } from 'status/utilities/hash';
import { Button, Spinner } from '@digitools/honest-react';
import MemberDropdown from 'src/packages/dental/components/MemberDropdown';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import PlanDeductibles from 'src/packages/dental/components/PlanDeductibles';
import useSetCurrentMember from 'src/packages/dental/hooks/useSetCurrentMember';
import Coinsurance from 'src/packages/dental/components/Coinsurance';
export const DeductibleAndCoinsuranceInfo = () => {
    const { infoLoading, success, errorMessage } = useDentalDataContext();
    if (infoLoading) {
        return <Spinner data-testid='deductible-spinner'/>;
    }
    return (<>
      {success ? (<>
          <PlanDeductibles /> <Coinsurance />
        </>) : (<>{errorMessage}</>)}
    </>);
};
const DeductibleAndCoinsurance = () => {
    const navigate = useNavigate();
    const { currentMember, subscriber, success, errorMessage } = useDentalDataContext();
    const { trackEvent, trackView } = useTealium();
    const { t } = useTranslate();
    const trackDropdownEvent = (member) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
            event_name: TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: member,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            user_role: 'employee',
        });
    };
    useSetCurrentMember();
    return (<>
      {success ? (<>
          {currentMember && subscriber && (<>
              <Button style={{ padding: '0' }} data-testid={'backButton'} onClick={() => {
                    navigate(`/employee/dental/members/${encode(currentMember.id)}`);
                }}>
                &lt; {t(DentalText.backText)}
              </Button>

              <MemberDropdown url={'deductibles'} trackDropdownEvent={trackDropdownEvent}/>
              <p>{t(DentalText.disclaimerText)}</p>
              <DeductibleAndCoinsuranceInfo />
            </>)}
        </>) : (<>{errorMessage}</>)}
    </>);
};
export default DeductibleAndCoinsurance;
