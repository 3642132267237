import axios from 'axios';
import { UnauthenticatedError } from '../types/UnauthenticatedError';
import { UnauthorizedError } from '../types/UnauthorizedError';
import { NotFoundError } from '../types/NotFoundError';
const api = '/mlp-ui-service/services/v1/communicationPreferences';
export const getPreferences = async (authHeader) => {
    const response = await axios.get(api, {
        headers: {
            Authorization: authHeader,
        },
    });
    const status = response.status;
    if (status === 200) {
        return response.data;
    }
    if (status === 401) {
        throw new UnauthenticatedError('Invalid Access Token');
    }
    else if (status === 403) {
        throw new UnauthorizedError('User Unauthorized');
    }
    else if (status === 404) {
        throw new NotFoundError('Alerts & notifications Not Found');
    }
    else {
        throw new Error('Unable to retrieve Alerts & notifications');
    }
};
export const savePreferences = async (preferencesData, authHeader) => {
    const response = await axios.put(api, preferencesData, {
        headers: {
            Authorization: authHeader,
        },
    });
    const status = response.status;
    if (status === 200) {
        return status;
    }
    if (status === 401) {
        throw new UnauthenticatedError('Invalid Access Token');
    }
    else if (status === 403) {
        throw new UnauthorizedError('User Unauthorized');
    }
    else if (status === 404) {
        throw new NotFoundError('Alerts & notifications Not Found');
    }
    else {
        throw new Error('Unable to retrieve Alerts & notifications');
    }
};
export const communicationPreferences = (custDiv, custSeral, authHeader) => {
    return axios.get('/mlp-ui-service/services/v1/notificationsEnable', {
        headers: {
            Authorization: authHeader,
        },
        params: {
            custDiv,
            custSeral,
        },
    });
};
export const resendConfirmation = async (confirmationData, authHeader) => {
    const response = await axios.put('/mlp-ui-service/services/v1/communicationPreferences/resend', confirmationData, {
        headers: {
            Authorization: authHeader,
        },
    });
    return response.status;
};
// Get cliamleave number from TDS service 2nd level check for communication preference
export const getClaimLeaveNumber = (authHeader, username) => {
    return axios.get(`/mlp-ui-service/services/v1/customer/claim-leave-number/${username}`, {
        headers: {
            Authorization: authHeader,
        },
    });
};
export const findMyInformation = (empInfo, authHeader) => {
    return axios.post('/mlp-ui-service/services/v1/profile/find-my-information-with-accommodation', empInfo, {
        headers: {
            Authorization: authHeader,
        },
    });
};
