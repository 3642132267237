import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { emailPattern } from './validationUtils';
export const usernameRegex = /^[a-zA-Z0-9_.+\-]{4,127}[a-zA-Z0-9_+\-]$/;
export const nameRegex = /^[a-zA-Z-' ]{0,35}$/;
export const passwordRegex = /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])))(?=.{8,})(\S+$)/;
// 5-35 characters, at least 1 letter, no special characters, spaces, or 9 consecutive numbers
// it's the order of patterns in combination of greedy and reluctant qualifiers that make this work
export const idmUsernameRegex = /(?!^[0-9]*$)(?!.*?[0-9]{9})^([a-zA-Z0-9]{5,35})$/;
export function isNotEmpty(input) {
    return !!input;
}
export function isValidUsername(value) {
    if (!value) {
        return true;
    }
    return usernameRegex.test(value);
}
export function isValidMigrateUsername(value) {
    return idmUsernameRegex.test(value);
}
export function phoneIsNotBlacklisted(value) {
    const blacklist = [
        '93',
        '375',
        '243',
        '236',
        '225',
        '86',
        '53',
        '964',
        '98',
        '850',
        '961',
        '231',
        '218',
        '95',
        '234',
        '40',
        '7',
        '252',
        '211',
        '963',
        '380',
        '967',
        '263',
    ];
    if (value == null) {
        return true;
    }
    else {
        const formattedPhoneNumber = value.replace(/-/g, '').replace(/ /g, '');
        const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
        const countryCode = formattedPhoneNumber && formattedPhoneNumber.length > 10 && parsedPhoneNumber
            ? parsedPhoneNumber.countryCallingCode
            : '1';
        for (const blacklistedCode of blacklist) {
            if (blacklistedCode === countryCode) {
                return false;
            }
        }
        return true;
    }
}
export function isValidPhoneNumber(value) {
    if (!value) {
        return true;
    }
    else {
        const formattedPhoneNumber = value.replace(/-/g, '').replace(/ /g, '').replace(/[()]/g, '');
        const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
        return !((parsedPhoneNumber && !parsedPhoneNumber.isValid()) || formattedPhoneNumber.length != 10);
    }
}
export function isValidEmail(email) {
    if (!email) {
        return true;
    }
    return emailPattern.test(email) && email.length < 140;
}
export function isValidName(value) {
    if (!value) {
        return true;
    }
    return nameRegex.test(value);
}
export function isPasswordFormatValid(value, values) {
    if (!value) {
        return true;
    }
    return passwordRegex.test(value);
}
export function isLfgPasswordFormatValid(value, values) {
    if (!value) {
        return true;
    }
    const lowercasePassword = value.toLowerCase();
    const username = values?.idmUsername != undefined ? values?.idmUsername : values?.tokenUsername;
    return (passwordRegex.test(value) &&
        !(username != undefined && lowercasePassword.includes(username.toString().toLowerCase())));
}
export function isPasswordSecure(password, values) {
    if (!password || !values) {
        return true;
    }
    return password !== values.username;
}
export function isValidReEnteredPassword(reEnteredPassword, values) {
    if (!reEnteredPassword || !values) {
        return true;
    }
    return reEnteredPassword === values.pwd;
}
