// Status
export const EMPLOYER_STATUS_CODE = 'statusER';
export const EMPLOYEE_STATUS_CODE = 'statusEE';
// EOI
export const EMPLOYEE_EOI_CODE = 'eoiEE';
// AS
export const EMPLOYEE_AS_CODE = 'asEE';
export const EMPLOYER_AS_CODE = 'asER';
// CRS
export const EMPLOYER_CRS_CODE = 'crsER';
// RIA
export const EMPLOYEE_RIA_CODE = 'riaEE';
// RAB
export const EMPLOYEE_RAB_CODE = 'rabEE';
// BDM
export const EMPLOYEE_BDM_CODE = 'bdmEE';
// LIFE
export const EMPLOYER_LIFE_CODE = 'lciER';
// CLI
export const EMPLOYER_CLI_CODE = 'cliER';
export const EMPLOYEE_CLI_CODE = 'cliEE';
// LANDING PAGES
export const EMPLOYEE_HOME = 'employeeHome';
export const EMPLOYER_HOME = 'employerHome';
// ADMINISTRATIVE FUNCTIONALITIES
export const USER_ADMIN = 'userAdmin';
export const EMAIL_ADMIN = 'emailAdmin';
export const EMPLOYER_APPLICATION_PERMISSIONS_ADMIN = 'appPermissionAdmin';
