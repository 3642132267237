import platform from 'platform';
function getPlatformInfo() {
    return {
        name: platform.name,
        version: platform.version,
        os: platform.os ? platform.os.toString() : undefined,
    };
}
class Sawmill {
    config = { dev: true, serviceUrl: '/ui-logging-service/logs' };
    error(error, message) {
        // @ts-ignore
        if (this.config.dev || !newrelic) {
            console.error(error, message);
        }
        else {
            this.postLog(error, message);
        }
    }
    configure(opts) {
        this.config = { ...this.config, ...opts };
    }
    async postLog(error, message) {
        if (!this.config.serviceUrl) {
            console.warn('You must configure a non-null service url for the sawmill to send logs.');
        }
        else {
            const payload = {
                message,
                path: window.location.pathname,
                platform: getPlatformInfo(),
                user: this.config.userInfo,
            };
            // @ts-ignore
            newrelic.noticeError(error, payload);
        }
    }
}
const instance = new Sawmill();
export default instance;
