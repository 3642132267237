import { Banner, BannerMenu, Box, CardItem, Col, Heading, ListItem, Row, themes } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useAuthentication from '../../../../hooks/useAuthentication';
import useCustomerConfig from '../../../../hooks/useCustomerConfig';
import useExperience from '../../../../hooks/useExperience';
import useTealium from '../../../../hooks/useTealium';
import useTranslate from '../../../../hooks/useTranslate';
import { Experience } from '../../../../types/Experience';
import { getEnvVariable } from '../../../../utils/envUtils';
import { LandingPageBannerText } from './LandingPageBannerText';
import ViewRequestStatusForm from './ViewRequestStatusForm';
const StyledEmployerSearchBox = styled(Box) `
  padding: 45px 30px;
  width: 340px;
  @media (max-width: 991px) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 300px;
    h3 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 769px) {
    width: 100%;
    display: block;
    margin-left: 0;
    margin-top: 1rem;
  }
`;
const StyledEmployerBanner = styled(Banner) `
  overflow: visible;
  @media (max-width: 769px) {
    img {
      display: none;
    }
    img + div {
      position: static;
    }
  }
`;
const StyledBannerMenuContainer = styled.div `
  margin: 0 auto;
  position: relative;
  margin-top: ${({ bannerMenuType }) => (bannerMenuType === 'card' ? '-2%' : '10px')};
  width: 94%;
  @media (max-width: 992px) {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
const transformBannerMenuLinks = (menuLinks, translate) => {
    return menuLinks?.map((link) => {
        let result = {
            index: link.index,
            iconName: link.iconName,
            text: translate(link.text),
            url: link.externalLink ? getEnvVariable('mlpHomeUrl') + link.url : link.url,
        };
        return result;
    });
};
const LandingPageBanner = () => {
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { bannerMenu } = useCustomerConfig();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const trackPageEvent = (itemText) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: itemText.toLowerCase(),
            event_type: TEALIUM_EVENT.EVENT_TYPE.HOME_PAGE,
            event_version: TEALIUM_EVENT.EVENT_TYPE.LANDING_PAGE_BANNER,
        });
    };
    const isStatusER = user && user?.applicationPermissions && user?.applicationPermissions?.indexOf('statusER') > -1;
    return (<Row>
      <Col>
        {experience === Experience.EMPLOYEE && (<Banner bannerMenu={<StyledBannerMenuContainer bannerMenuType={'card'}>
                <BannerMenu theme={themes.lincoln}>
                  {bannerMenu &&
                    transformBannerMenuLinks(bannerMenu, t).map((item) => (<CardItem onClick={() => trackPageEvent(item.text)} data-public={true} iconName={item.iconName} text={item.text} url={item.url}/>))}
                </BannerMenu>
              </StyledBannerMenuContainer>} data-testid={'employee-landing-page-banner'} imageAltText={`Woman with a laptop and her dog`} imageUrl={getEnvVariable('mlpHomeUrl') + '/assets/images/woman-with-laptop-dog.jpg'} primaryMessage={t(LandingPageBannerText.eePrimaryMessage)} secondaryMessage={t(LandingPageBannerText.eeSecondaryMessage)}/>)}
        {experience === Experience.EMPLOYER && (<StyledEmployerBanner bannerMenu={<StyledBannerMenuContainer bannerMenuType={'list'}>
                <BannerMenu theme={themes.lincoln}>
                  {bannerMenu &&
                    transformBannerMenuLinks(bannerMenu, t).map((item, i) => (<ListItem key={i} onClick={() => trackPageEvent(item.text)} data-public={true} text={item.text} url={item.url}/>))}
                </BannerMenu>
              </StyledBannerMenuContainer>} data-testid={'employer-landing-page-banner'} imageAltText={`Woman standing at her desk with a laptop`} imageUrl={getEnvVariable('mlpHomeUrl') + '/assets/images/woman-with-laptop.jpg'} primaryMessage={t(LandingPageBannerText.eePrimaryMessage)} secondaryMessage={t(LandingPageBannerText.eeSecondaryMessage)}>
            {isStatusER && (<StyledEmployerSearchBox data-testid={`employerSearchBox`} type={`color`}>
                <Heading className={`mb-4`} color={`white`} elemType={`h2`}>
                  View request status
                </Heading>
                <ViewRequestStatusForm />
              </StyledEmployerSearchBox>)}
          </StyledEmployerBanner>)}
      </Col>
    </Row>);
};
export default LandingPageBanner;
