import React, { useEffect, useState } from 'react';
import useAuthentication from 'src/hooks/useAuthentication';
import { getMissingNPPIFields } from 'src/api/AuthServiceApi';
import { ProveDataContext } from './ProveDataContext';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import ProveText from './ProveText';
import useTealium from 'src/hooks/useTealium';
import useTranslate from 'src/hooks/useTranslate';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
/**
 *  Provides ProveDataContext -
 *  Used to store data related to prove identity verification during the prove process on MLP
 */
export const ProveDataProvider = ({ children }) => {
    const { user, authHeader } = useAuthentication();
    const [proveFieldsRequired, setProveFieldsRequired] = useState();
    const [verificationData, setVerificationData] = useState();
    const [trustScore, setTrustScore] = useState();
    const [isUserLockedOut, setUserLockedOut] = useState(false);
    const [isProveSuccess, setIsProveSuccess] = useState(false);
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const pageL4 = CustomEmployeeText('employee view');
    /* - future state
    const failCount
    const destination
    */
    /* Call for missing NPPI data the Prove form will need to ask for */
    useEffect(() => {
        const callForProveFieldsRequired = async () => {
            try {
                const response = await getMissingNPPIFields(authHeader());
                setProveFieldsRequired(response.data);
            }
            catch (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status == 423) {
                        setUserLockedOut(true);
                        trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                            event_name: t(ProveText.authVerifyError),
                            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                            event_version: 'phone lookup',
                            event_results: 'error occurred due to user access is blocked for 24 hours',
                            page_l4: pageL4,
                            page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                        });
                    }
                }
                //TODO: need defined state for what happens if this get fails
                // console.log('error retrieving fields required for Prove');
            }
        };
        callForProveFieldsRequired();
    }, [user, authHeader]);
    /* Build Context Object */
    const proveDataContext = {
        proveFieldsRequired,
        verificationData,
        trustScore: trustScore,
        isVerified: !!trustScore?.valid,
        isProveSuccess,
        isUserLockedOut,
        setVerificationData,
        setTrustScore,
        setIsProveSuccess,
    };
    // console.log('~* Prove Data Context Debug *~', proveDataContext);
    return (<ProveDataContext.Provider value={proveDataContext}>
      {children}
    </ProveDataContext.Provider>);
};
export default ProveDataProvider;
