import React, { createContext, useEffect, useState } from 'react';
import useExperience from '../../../hooks/useExperience';
import { Experience, ExperienceCode } from '../../../types/Experience';
import { getCliCustomerConfigV2 } from '../api/CliApiCalls';
import { INCOME_TYPE_OPTIONS } from '../constants/CliConstants';
import { isProd } from 'src/utils/envUtils';
export const CliConfigContext = createContext({
    autoPrepop: undefined,
    claimantCondition: [],
    cliConfigError: undefined,
    cliConfigLoading: undefined,
    configErrorV2: undefined,
    configLoadingV2: undefined,
    deliveryIndicators: [],
    empIdText: undefined,
    employmentCity: undefined,
    employmentCountry: undefined,
    employmentProvince: undefined,
    employmentState: undefined,
    hasAdditionalInfo: undefined,
    hasPrePopEnabled: undefined,
    hasSaveForLater: undefined,
    isNYDICustomer: undefined,
    isClaimCustomer: undefined,
    isClaimLeaveCustomer: undefined,
    isLeaveCustomer: undefined,
    leaveCategoryDays: undefined,
    leaveReasons: [],
    leaveTypes: [],
    lookupByEmpId: undefined,
    lookupBySsn: undefined,
    empId: undefined,
    lastName: undefined,
    messageHeading: undefined,
    messageText: undefined,
    overviewBodyConfirmation: undefined,
    overviewTitleConfirmation: undefined,
    paidLeaveStates: [],
    primaryWorkLocation: undefined,
    relationships: [],
    savedCliInterview: undefined,
    saveForLaterDays: undefined,
    saveForLaterEntries: {
        rowID: undefined,
        updatedDateAndTime: undefined,
    },
    setSaveForLaterEntries: () => { },
    identityInfoText: undefined,
    uniqueIDText: undefined,
    displayMedicalTiers: false
});
export var LeaveType;
(function (LeaveType) {
    LeaveType["FAMILY_CARE"] = "FMC";
    LeaveType["OTHER"] = "OTH";
    LeaveType["OWN_ILLNESS"] = "OWN";
    LeaveType["PARENTAL"] = "BND";
    LeaveType["PREGNANT"] = "MAT";
    LeaveType["MILITARY"] = "XML";
})(LeaveType || (LeaveType = {}));
export var DeliveryIndicator;
(function (DeliveryIndicator) {
    DeliveryIndicator["CSECTION"] = "Yes, C-Section";
    DeliveryIndicator["NO"] = "No, Not yet";
    DeliveryIndicator["VAGINAL"] = "Yes, Vaginal";
})(DeliveryIndicator || (DeliveryIndicator = {}));
/**
 *  Provides customer configuration data loaded from cli api to all children
 */
export const CliConfigProvider = ({ children }) => {
    const [saveForLaterEntries, setSaveForLaterEntries] = useState(); // TODO: Pick off saveForLaterEntries shape in ICliConfigContext?
    const { experience, language } = useExperience();
    // TODO: getCliCustomerConfig was complaining about types b/c I think { experienceCode } initializes as Public for a sec on render
    const experienceCode = experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER;
    // TODO: Remove V2 names when old endpoint is retired
    const { loading: configLoadingV2, data: configDataV2, error: configErrorV2 } = getCliCustomerConfigV2(experienceCode);
    useEffect(() => {
        if (configErrorV2) {
            throw new Error();
        }
        if (experienceCode === 'EE') {
            if (configDataV2 && configDataV2?.startPage && configDataV2?.startPage?.saveForLaterListEntries && configDataV2?.startPage?.saveForLaterListEntries?.length < 0) {
                setSaveForLaterEntries(null);
            }
            else {
                setSaveForLaterEntries((prevState) => {
                    return {
                        ...prevState,
                        ...configDataV2?.startPage?.saveForLaterListEntries[0]
                    };
                });
            }
        }
    }, [configDataV2, configErrorV2]);
    const formatSalaryTypeItems = (items) => {
        return items.map(item => ({ label: item.english, value: item.code }));
    };
    //TODO: --- fix syntax below to clean up
    const cliConfigContext = {
        autoPrepop: configDataV2 ? configDataV2?.config?.autoPrepop : undefined,
        claimantCondition: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.claimantCondition : [],
        configErrorV2,
        configLoadingV2,
        deliveryIndicators: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.deliveryIndicators : [],
        empIdText: configDataV2 ? configDataV2.startPage.empIdText : undefined,
        employmentCity: configDataV2 ? configDataV2.aboutYouPage.employmentCity : undefined,
        employmentCountry: configDataV2 ? configDataV2.aboutYouPage.employmentCountry : undefined,
        employmentProvince: configDataV2 ? configDataV2.aboutYouPage.employmentProvince : undefined,
        employmentState: configDataV2 ? configDataV2.aboutYouPage.employmentState : undefined,
        hasAdditionalInfo: configDataV2 ? configDataV2?.additionalInfoPage.hasAdditionalInfo : false,
        hasPrePopEnabled: configDataV2 ? configDataV2?.config?.hasPrePopEnabled : undefined,
        hasSaveForLater: configDataV2 ? configDataV2?.config?.hasSaveForLater : undefined,
        isNYDICustomer: configDataV2 ? configDataV2?.config?.isNYDICustomer : undefined,
        isClaimCustomer: configDataV2 ? configDataV2?.config?.isClaimCustomer : undefined,
        isClaimLeaveCustomer: configDataV2 ? configDataV2?.config?.isClaimLeaveCustomer : undefined,
        isLeaveCustomer: configDataV2 ? configDataV2?.config?.isLeaveCustomer : undefined,
        isPaidLeaveCustomer: configDataV2 ? configDataV2?.config?.isPaidLeaveCustomer : undefined,
        leaveCategoryDays: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.leaveCategoryDays : undefined,
        leaveReasons: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.leaveReasons : [],
        leaveTypes: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.leaveTypes : [],
        lookupByEmpId: configDataV2 ? configDataV2.startPage?.lookupByEmpId : undefined,
        lookupBySsn: configDataV2 ? configDataV2.startPage?.lookupBySsn : undefined,
        empId: configDataV2 ? configDataV2.startPage?.empId : undefined,
        lastName: configDataV2 ? configDataV2.startPage?.lastName : undefined,
        messageHeading: configDataV2 ? configDataV2.startPage.messageHeading : undefined,
        messageText: configDataV2 ? configDataV2.startPage.messageText : undefined,
        overviewBodyConfirmation: configDataV2 ? configDataV2.confirmationPage?.overviewBodyConfirmation : undefined,
        overviewTitleConfirmation: configDataV2 ? configDataV2.confirmationPage?.overviewTitleConfirmation : undefined,
        paidLeaveStates: configDataV2 ? configDataV2.aboutAbsencePage.paidLeaveStates : [],
        primaryWorkLocation: configDataV2 ? configDataV2.aboutYouPage.primaryWorkLocation : undefined,
        relationships: configDataV2 && configDataV2.aboutAbsencePage ? configDataV2.aboutAbsencePage.relationships : [],
        salaryTypeItems: configDataV2 && configDataV2.additionalInfoPage && configDataV2.additionalInfoPage.salaryTypeItems ? formatSalaryTypeItems(configDataV2.additionalInfoPage.salaryTypeItems) : INCOME_TYPE_OPTIONS,
        savedCliInterview: configDataV2 ? configDataV2.startPage?.saveForLaterListEntries : undefined,
        saveForLaterDays: configDataV2 ? configDataV2.config?.saveForLaterDays : undefined,
        saveForLaterEntries,
        setSaveForLaterEntries,
        identityInfoText: configDataV2 ? configDataV2.startPage.identityInfoText : undefined,
        uniqueIDText: configDataV2 ? configDataV2.startPage.uniqueIDText : undefined,
        displayMedicalTiers: !isProd
    };
    return (<CliConfigContext.Provider value={cliConfigContext}>
      {children}
    </CliConfigContext.Provider>);
};
