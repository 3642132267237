import React from 'react';
import { FileType } from '@digitools/honest-react/dist/lib/types/FileType';
import { Icon } from '@digitools/honest-react';
export const renderIcon = (type) => {
    switch (type) {
        case FileType.DOC:
        case FileType.DOCX:
            return <Icon name='file-word-regular' color='secondaryDark'/>;
        case FileType.XLS:
        case FileType.XLSM:
        case FileType.XLSX:
            return <Icon name='file-excel-regular' color='successDark'/>;
        case FileType.PDF:
            return <Icon name='pdf' color='error'/>;
        default:
            return <Icon name='file-regular'/>;
    }
};
