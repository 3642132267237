import React, { useContext } from 'react';
import { Form } from 'informed';
import PersonalIdentificationForm from './PersonalIdentificationForm';
import useCliDataContext from '../../hooks/useCliDataContext';
import { Loader, StepperContext } from '@digitools/honest-react';
import useAuthentication from 'src/hooks/useAuthentication';
import { CheckForPrePropData } from '../../api/CliApiCalls';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { Environment } from 'src/types/Environment';
import { getEnvironment } from 'src/utils/envUtils';
const IdentityVerification = () => {
    const { authHeader } = useAuthentication();
    const { claimantData, setClaimantData, setIsPrePopLoading, isPrePopLoading, setHasEligibilityEmpState, } = useCliDataContext();
    const { hasPrePopEnabled } = useCliCustomerConfigContext();
    const { experience } = useExperience();
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const stepper = useContext(StepperContext);
    const onSubmit = async (values) => {
        setIsPrePopLoading(true);
        const payload = {
            // @ts-ignore
            lastName: values?.claimantData?.lastName ? values.claimantData.lastName.trim() : user?.lastName || null,
            // @ts-ignore
            empID: values?.claimantData?.empID ? values.claimantData.empID.trim() : null,
            // @ts-ignore
            ssn: values?.claimantData?.ssn ? values.claimantData.ssn.trim() : null,
        };
        if ((getEnvironment() === Environment.DEV || getEnvironment() === Environment.NON_PROD) && user?.isLfgUser) {
            if (user?.isIdentityVerified) {
                const { response } = await CheckForPrePropData(payload, authHeader());
                if (response.code === 200) {
                    if (hasPrePopEnabled) {
                        //FIXME: GENDER HACK -- push this into the back-end
                        const hackedResponse = response;
                        if (hackedResponse?.data?.gender && hackedResponse?.data?.gender !== 'M' && hackedResponse?.data?.gender !== 'F') {
                            hackedResponse.data.gender = undefined;
                        }
                        if (hackedResponse?.data?.selectedEmpStateID?.length !== 2) {
                            hackedResponse.data.selectedEmpStateID = undefined;
                        }
                        if (experience === Experience.EMPLOYEE) {
                            setClaimantData({ ...hackedResponse.data, ...claimantData, ...payload, });
                        }
                        else {
                            setClaimantData({ ...claimantData, ...hackedResponse.data, ...payload });
                        }
                    }
                    else {
                        setClaimantData({ ...payload });
                    }
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                        event_name: 'continue',
                        event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                        event_version: 'getting started',
                        event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                    });
                    setIsPrePopLoading(false);
                    setHasEligibilityEmpState(true);
                    if (response?.data?.selectedEmpStateID === "  ") {
                        setHasEligibilityEmpState(false);
                    }
                    else {
                        setHasEligibilityEmpState(true);
                    }
                    stepper.next();
                }
                if (response.code === 404) {
                    setClaimantData({ ...claimantData, ...payload });
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                        event_name: 'continue',
                        event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                        event_version: 'getting started',
                        event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                    });
                    setIsPrePopLoading(false);
                    stepper.next();
                    window.scrollTo(0, 0);
                }
                if (response.code !== 200 && response.code !== 404) {
                    setIsPrePopLoading(false);
                }
            }
            else {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: 'getting started',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                });
                setClaimantData({ ...payload });
                setIsPrePopLoading(false);
                stepper.next();
            }
        }
        else {
            const { response } = await CheckForPrePropData(payload, authHeader());
            if (response.code === 200) {
                if (hasPrePopEnabled) {
                    //FIXME: GENDER HACK -- push this into the back-end
                    const hackedResponse = response;
                    if (hackedResponse?.data?.gender && hackedResponse?.data?.gender !== 'M' && hackedResponse?.data?.gender !== 'F') {
                        hackedResponse.data.gender = undefined;
                    }
                    if (hackedResponse?.data?.selectedEmpStateID?.length !== 2) {
                        hackedResponse.data.selectedEmpStateID = undefined;
                    }
                    if (experience === Experience.EMPLOYEE) {
                        setClaimantData({ ...hackedResponse.data, ...claimantData, ...payload, });
                    }
                    else {
                        setClaimantData({ ...claimantData, ...hackedResponse.data, ...payload });
                    }
                }
                else {
                    setClaimantData({ ...payload });
                }
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: 'getting started',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                });
                setIsPrePopLoading(false);
                setHasEligibilityEmpState(true);
                if (response?.data?.selectedEmpStateID === "  ") {
                    setHasEligibilityEmpState(false);
                }
                else {
                    setHasEligibilityEmpState(true);
                }
                stepper.next();
            }
            if (response.code === 404) {
                setClaimantData({ ...claimantData, ...payload });
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: 'getting started',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                });
                setIsPrePopLoading(false);
                stepper.next();
                window.scrollTo(0, 0);
            }
            if (response.code !== 200 && response.code !== 404) {
                setIsPrePopLoading(false);
            }
        }
    };
    return (<Loader viewportCentered={false} id='cli-eligibility-spinner' isShown={isPrePopLoading} data-testid={'cli-eligibility-spinner'}>
            <Form onSubmit={onSubmit} data-testid='identity-verification-form' style={{ marginTop: '3rem' }}>
                {({ formState }) => (<PersonalIdentificationForm formState={formState}/>)}
            </Form>
        </Loader>);
};
export default IdentityVerification;
