import React from 'react';
import { Col, Row, SelectInput, themes } from '@digitools/honest-react';
import GetHelpModal from '../GetHelpModal';
const lowerCaseProducts = (filterReportLabels) => {
    const lowerCaseProducts = [];
    filterReportLabels.forEach((product) => {
        lowerCaseProducts.push({
            label: product.label.toLowerCase(),
            value: product.value,
        });
    });
    return lowerCaseProducts;
};
const FilterReportByProduct = ({ handleReportByCategory, reportCategory, filterReportLabels, }) => {
    const filterReportByType = (event) => {
        handleReportByCategory(event.target.value);
    };
    return (<>
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={3} md={12}>
          <SelectInput field={'reportCategory'} theme={themes.lincoln} onChange={filterReportByType} placeholderText={'Select report type'} label={'Select report type'} initialValue={reportCategory} options={lowerCaseProducts(filterReportLabels)} errorMessage={'Required'}/>
        </Col>
        <Col lg={9} md={12}>
          <GetHelpModal headerText={'Create My Report - Help Guide'}/>
        </Col>
      </Row>
    </>);
};
export default FilterReportByProduct;
