export const QuestionsText = {
    qualifyingQuestionsSubheading: {
        english: 'The following health questions must be answered fully and truthfully to the best of your knowledge and belief for each applicant.',
        spanish: 'Las siguientes preguntas de salud deben responderse de manera completa y veraz según su leal saber y entender para cada solicitante.'
    },
    medicalInformation: {
        english: 'Medical information',
        spanish: 'Información médica',
    },
    medicalInformationHeading: {
        english: 'Diseases, illnesses, or conditions',
        spanish: 'Enfermedades, enfermedades o afecciones'
    },
    agreementOfTerms: {
        english: 'Agreement of terms',
        spanish: 'Acuerdo de términos',
    },
    tobaccoOrNicotine: {
        english: 'Tobacco or nicotine products',
        spanish: 'Productos de tabaco o nicotina',
    },
    criticalIllness: {
        english: 'Critical illness information',
        spanish: 'Información sobre enfermedades críticas',
    },
    criticalIllnessHeading: {
        english: 'Specialty illnesses, or conditions',
        spanish: 'Especialidad, enfermedades o afecciones',
    },
    criticalIllnessDescription: {
        english: 'You must complete this section of questions if applying for Critical Illness insurance. You must answer yes or no for each question per applicant to avoid a processing delay.',
        spanish: 'Debe completar esta sección de preguntas si solicita un seguro contra enfermedades graves. Debe responder sí o no a cada pregunta por solicitante para evitar demoras en el procesamiento.',
    },
    criticalIllnessDescriptionNY: {
        english: 'Applicants complete if applying for critical illness insurance. You must answer yes or no for each question per applicant to avoid a processing delay.',
        spanish: 'Los solicitantes deben completar si solicitan un seguro de enfermedad crítica. Debe responder SÍ o NO para cada pregunta por solicitante para evitar una demora en el procesamiento'
    },
    additionalQuestions: {
        english: 'Additional Qualifying Questions',
        spanish: 'Preguntas de calificación adicionales',
    },
    reflexiveQuestionsSubHeading: {
        english: 'Based on your answers to the previous set of health questions, we need additional information to properly assess the application.',
        spanish: 'Según sus respuestas al conjunto anterior de preguntas de salud, necesitamos información adicional para evaluar correctamente la solicitud.'
    },
    selectAllThatApply: {
        english: 'Select all that apply:',
        spanish: 'Seleccione todas las que correspondan:'
    },
    reflectiveQuestionValidationError: {
        english: 'Please select at least one option.',
        spanish: 'Seleccione al menos una opción.',
    }
};
