import React from 'react';
import { Button, Modal, themes } from '@digitools/honest-react';
import { workStatesModel, workProvincesModel } from '../../../filter-my-report/work-location-model/initialStateAndProvinces';
import WorkLocations from '../../../filter-my-report/work-location-model/workLocations';
const WorkLocationModalView = ({ prevSelectedStates, prevSelectedProvinces }) => {
    const RenderOpenModalButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick}>
        View work locations
      </Button>);
    };
    workStatesModel.map((workStateModel) => {
        workStateModel.checked = prevSelectedStates.filter(prevSelectedStates => prevSelectedStates.stateCode === workStateModel.stateCode).length > 0;
    });
    workProvincesModel.map((workProvinceModel) => {
        workProvinceModel.checked = prevSelectedProvinces.filter(prevSelectedLocations => prevSelectedLocations.provinceCode === workProvinceModel.provinceCode).length > 0;
    });
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            hideModal();
        };
        return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick}>
        Close
      </Button>);
    };
    return (<Modal hasCloseIcon={false} headerText='Employee work location' modalOpenRenderButton={RenderOpenModalButton} size={'large'} theme={themes.lincoln} disabledBackdropClick={true} footerPrimaryRenderButton={FooterPrimaryRenderButton}>
      <WorkLocations onStateSelect={() => { }} onProvinceSelect={() => { }} onClearAllState={() => { }} onClearAllProvince={() => { }} workStates={workStatesModel} workProvinces={workProvincesModel} disableForm={true}/>
    </Modal>);
};
export default WorkLocationModalView;
