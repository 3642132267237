export const validationDisplayTextEn = {
    confirmEmailNotMatching: 'Entered email address does not match, please try again!',
    duplicateEmail: 'Email address is already in use.',
    emptyPassword: 'Password is required.',
    emptyUsername: 'Username is required.',
    fieldRequired: 'This field is required.',
    invalidAllocation: 'Enter allocation amount 1-100.',
    invalidAddress: 'Please enter a valid address.',
    invalidCharityContact: 'Contact Name is invalid.',
    invalidCharityName: 'Charity Name is invalid.',
    invalidCity: 'City cannot exceed 30 characters.',
    invalidEmail: 'Email address is not valid.',
    invalidEmployeeID: 'Employee ID is not valid.',
    invalidGuardianInfo: 'Guardian Info is invalid.',
    invalidOtherAddress: 'Other Address cannot exceed 255 characters.',
    invalidPassword: 'Password is not valid.',
    invalidPasswordMatch: 'Passwords must match.',
    passwordUsernameErrorMessage: 'Username and Password cannot be the same.',
    invalidPhone: 'Enter a valid phone number (e.g.,123-456-7890).',
    invalidSSN: 'Enter a valid SSN.',
    invalidTaxId: 'Enter a valid SSN or Tax ID (e.g., #########). Cannot be 9 identical numbers.',
    invalidTrustName: 'Trust Name is invalid.',
    invalidFirstName: 'First name is invalid.',
    invalidLastName: 'Last name is invalid.',
    invalidUsername: 'Username is not valid.',
    invalidZipCode: 'Please enter a valid zip code in the format of "XXXXX".',
    invalidZipCodeUSA: 'Please enter a valid zip code in the format of "XXXXX".',
    invalidZipCodeCAN: 'Please enter a valid postal code in the format of "A1A1A1".',
    phoneErrorMessage: 'This phone number is not valid.',
    nonApprovedCountryError: 'This country is not approved for Two Factor Authentication. Please contact technical assistance.',
    zipCodeRequired: 'Zip Code is required.',
    zipCodeRequiredUSA: 'Zip Code is required.',
    zipCodeRquiredCAN: 'Postal Code is required.',
    usernameInvalidErrormessage: 'Enter a valid username',
    invalidPostal: 'Please enter a valid postal code.',
};
