const ForgotInfoDisplayTextEn = {
    forgotUsernameHeader: 'Forgot Username',
    forgotPasswordHeader: 'Forgot Password',
    forgotUsernameParagraph: 'Enter the email address for your account and your username will be emailed to you.',
    forgotPasswordParagraph: 'If you have forgotten your password, please enter your username. We will attempt to look up your account and allow you to reset your password.',
    forgotUsernameSuccessAlertTitle: 'Successfully submitted.',
    forgotUsernameSuccessAlertBody: 'If the information provided matches an account, your username will be emailed to you.',
    forgotPasswordSuccessAlertTitle: 'Successfully submitted.',
    forgotPasswordSuccessAlertBody: 'If the information provided matches an account, you will receive an email with a link to reset your password. The link will expire in 10 minutes.',
    unverifiedEmailTitle: 'Unable to verify account!',
    unverifiedEmailBody: 'Account email verification required. Please check your inbox or spam folder to verify your email before you can reset your password.',
    unexpectedBody: 'Please try submitting your information again, if you continue to experience any problems, contact us for further assistance.',
    unexpectedTitle: 'Something went wrong!',
    emailAddressLabel: 'Email Address',
    usernameLabel: 'Username',
    submitButton: 'Submit',
    fieldRequired: 'This field is required.',
    backToLogin: 'Return to Log in',
    movedMessage: 'Important message for employees',
    mightHaveMoved: 'Your benefits account might have already been moved. If you already have an account on LincolnFinancial.com, log in ',
    here: 'here',
};
export default ForgotInfoDisplayTextEn;
