const AsVerificationText = {
    verifyRequiredError: {
        english: 'This field is required',
        spanish: 'Este campo es requerido',
    },
    invalidCharactersMessage: {
        english: 'Field must be less than 250 characters and may contain any character but \\ \` ~ ^ [ ] and "',
        spanish: 'El campo debe tener menos de 250 caracteres y puede contener cualquier carácter excepto \\ \` ~ ^ [ ] y "'
    },
    dateFutureError: {
        english: 'Date cannot be in the future.',
        spanish: 'La fecha no puede estar en el futuro.'
    },
    endDateIsBefore: {
        english: 'Date cannot be before the start date.',
        spanish: 'La fecha no puede ser anterior a la fecha de inicio.'
    },
    endDateIsEqual: {
        english: 'Date cannot be equal to the start date.',
        spanish: 'La fecha no puede ser la misma que la fecha de inicio.'
    }
};
export default AsVerificationText;
