export const ContentBlocksText = {
    verifyBlockHeadingText: {
        english: 'Take advantage of additional features',
        spanish: 'Aproveche las funciones adicionales'
    },
    verifyBlockBodyText: {
        english: 'Quickly verify your identity to secure confidential access to your private information, and enable additional features like claim status.',
        spanish: 'Verifique rápidamente su identidad para asegurar el acceso confidencial a su información privada y habilite funciones adicionales como el estado de la reclamación.'
    },
    verifyBlockLinkText: {
        english: 'Verify my identity',
        spanish: 'Verificar mi identidad'
    },
    commPrefBlockHeadingText: {
        english: 'Sign-up for text notifications',
        spanish: 'Regístrese para recibir notificaciones de texto'
    },
    commPrefBlockBodyText: {
        english: 'Update your Alerts & notifications in your profile to sign up for text notifications related to claim status.',
        spanish: 'Actualice sus alertas y notificaciones en su perfil para suscribirse a notificaciones de texto relacionadas con el estado del reclamo.'
    },
    commPrefBlockLinkText: {
        english: 'Update my preferences',
        spanish: 'Actualizar mis preferencias'
    },
    wellnessBlockHeadingText: {
        english: 'Improve your financial wellness!',
        spanish: 'Mejora tu salud financiera'
    },
    wellnessBlockBodyText: {
        english: 'Using Lincoln’s new financial wellness tool, WellnessPATH, determine your financial goals, track your progress, and improve your overall financial health.',
        spanish: 'Utilizando la nueva herramienta de bienestar financiero de Lincoln, WellnessPATH, determine sus objetivos financieros, realice un seguimiento de su progreso y mejore su salud financiera general.'
    },
    wellnessBlockLinkText: {
        english: 'Begin your short assessment with WellnessPATH',
        spanish: 'Comience su breve evaluación con WellnessPATH'
    },
    lcnNo: {
        english: 'LCN-5766810-062223',
        spanish: 'LCN-5766810-062223'
    }
};
