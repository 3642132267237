export const INITIAL_CONFIG_DATA = {
    customerId: '',
    customerDisplayName: '',
    customerInternalName: '',
    custDiv: '',
    custSeral: '',
    bdmApplication: {
        customerId: '',
        enabled: false,
        uniqueIdIndicator: 'BOTH',
        ssoManualSupport: false,
        customMessageHeaderConfirmation: {
            english: '',
            spanish: '',
        },
        customMessageConfirmation: {
            english: '',
            spanish: '',
        },
        bdmEffectiveDate: '',
    },
    lciApplication: {
        customerId: '',
        enabled: true,
        uniqueIdIndicator: 'BOTH',
        lciEffectiveDate: '',
    },
    bdmLciRelationshipDropdownValues: [],
    customContents: [
        {
            uniqueId: '21',
            applicationInternalName: '',
            title: 'EmployeeIDText',
            value_EN: '',
            value_ES: '',
        },
        {
            uniqueId: '2',
            applicationInternalName: '',
            title: 'EmployeeText',
            value_EN: '',
            value_ES: '',
        },
    ],
};
export const MOCK_CONFIG_DATA = {
    customerId: '35fa9418-7cab-4634-bcdd-456979f3d624',
    customerDisplayName: 'Catholic Health',
    customerInternalName: 'Catholic Health',
    custDiv: '08',
    custSeral: '035788',
    lciApplication: {
        customerId: '35fa9418-7cab-4634-bcdd-456979f3d624',
        enabled: true,
        uniqueIdIndicator: 'BOTH',
        lciEffectiveDate: '09/27/2016',
    },
    bdmApplication: {
        customerId: '',
        enabled: false,
        uniqueIdIndicator: '',
        ssoManualSupport: false,
        customMessageHeaderConfirmation: {
            english: 'English Test Confirm Header',
            spanish: 'Spanish Test Confirm Header',
        },
        customMessageConfirmation: {
            english: 'English Test confirm message',
            spanish: 'Spanish Test confirm message',
        },
        bdmEffectiveDate: '',
    },
    bdmLciRelationshipDropdownValues: [
        {
            uniqueId: '1',
            enabled: true,
            systemOneValue: 'SPOUSE',
            fieldValue_ES: 'Cónyuge/Pareja doméstica',
            fieldValue_EN: 'Spouse/Domestic Partner',
            field_requestStatus: '',
        },
        {
            uniqueId: '2',
            enabled: true,
            systemOneValue: 'CHILD',
            fieldValue_ES: 'Hijo/a',
            fieldValue_EN: 'Child',
            field_requestStatus: '',
        },
        {
            uniqueId: '3',
            enabled: true,
            systemOneValue: 'PARENT',
            fieldValue_ES: 'Padre/Madre',
            fieldValue_EN: 'Parent',
            field_requestStatus: '',
        },
        {
            uniqueId: '4',
            enabled: true,
            systemOneValue: 'SIBLING',
            fieldValue_ES: 'Hermano/a',
            fieldValue_EN: 'Sibling',
            field_requestStatus: '',
        },
        {
            uniqueId: '5',
            enabled: true,
            systemOneValue: 'RELATIVE',
            fieldValue_ES: 'Pariente',
            fieldValue_EN: 'Relative',
            field_requestStatus: '',
        },
        {
            uniqueId: '6',
            enabled: true,
            systemOneValue: 'OTHER',
            fieldValue_ES: 'Otro',
            fieldValue_EN: 'Other',
            field_requestStatus: '',
        },
    ],
    customContents: [
        {
            uniqueId: '3',
            applicationInternalName: '',
            title: 'SpouseText',
            value_EN: 'Spouse',
            value_ES: 'Spouse',
        },
        {
            uniqueId: '',
            applicationInternalName: '',
            title: 'EmployerMessage',
            value_EN: '<p>This is a Test Message from your Employer&nbsp;</p>',
            value_ES: '<p>Este es un mensaje de prueba de su empleador</p>',
        },
        {
            uniqueId: '21',
            applicationInternalName: '',
            title: 'EmployeeIDText',
            value_EN: 'Associate ID',
            value_ES: '',
        },
        {
            uniqueId: '2',
            applicationInternalName: '',
            title: 'EmployeeText',
            value_EN: 'Associate',
            value_ES: '',
        },
        {
            uniqueId: '',
            applicationInternalName: '',
            title: 'EmployerMessage',
            value_EN: '<p>This is a Test Message from your Employer&nbsp;</p>',
            value_ES: '<p>Este es un mensaje de prueba de su empleador</p>',
        },
    ],
};
