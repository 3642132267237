import { Directory, Button, Alert } from '@digitools/honest-react';
import React from 'react';
const UserAccessDetails = (props) => {
    let alert;
    if (props.isAlert) {
        alert = props.errorMessage ? (<Alert type='error' closeable={true} onClose={props.closeAlert}>{props.errorMessage}</Alert>) : (<Alert type='success' closeable={true} onClose={props.closeAlert}>Success!</Alert>);
    }
    else {
        alert = <></>;
    }
    return (<>
      <Directory checkbox={true} defaultOpen={true} selectAllCheckbox={true} directoryItems={props.directories} onCheckboxChange={props.onCheckboxChange} title={'Folder Permissions'} textColor={'grayLighter'}/>
      {alert}
      <Button buttonType={'primary'} size={'medium'} aria-label='Save Folder Permissions' onClick={props.saveUserPermissions}>
        Save Changes
      </Button>
      <Button buttonType={'secondary'} size={'medium'} aria-label='Undo Folder Permissions' onClick={props.undoUserPermissions}>
        Undo
      </Button>
    </>);
};
export default UserAccessDetails;
