import React from 'react';
const CommPrefDisplayText = {
    communicationPreferences: {
        english: 'Alerts & notifications',
        spanish: 'Alertas y notificaciones',
    },
    notificationSettings: {
        english: 'Notification settings',
        spanish: 'Configuración de las notificaciones'
    },
    howItWorks: {
        english: 'How it works',
        spanish: 'Cómo funciona',
    },
    success: {
        english: 'Success! Your information has been saved.',
        spanish: '¡Éxito! tu información ha sido guardada.',
    },
    verificationResent: {
        english: 'Verification resent successfully.',
        spanish: 'Verificación reenviada con éxito.',
    },
    error: {
        english: 'We\'re sorry! Something went wrong on our end.',
        spanish: '¡Lo sentimos! Algo salió mal por nuestra parte.',
    },
    modalGeneralText: {
        english: (<>
        <p>After signing up you will receive either an automated phone call or a confirmation text. For phone, follow the prompts to confirm your enrollment. For text, reply "YES" to confirm your enrollment. You can manage your Alerts & notifications at any time online or by texting "STOP" to 78897. The Alerts & notifications status will remain as pending until you confirm enrollment.</p>
        <p>Alerts & notifications will need to be reset after six months of inactivity.</p>
      </>),
        spanish: (<>
        <p>Después de registrarse, usted recibirá una llamada telefónica automatizada o un mensaje de texto de confirmación. Para seleccionar teléfono, siga las instrucciones para confirmar su inscripción. Para seleccionar mensajes de texto, responda "YES" para confirmar su inscripción. Usted puede administrar sus Alertas y notificaciones en cualquier momento en línea o si envía el mensaje de texto con la palabra "STOP" al 78897. El estado de sus Alertas y notificaciones quedará como pendiente hasta que confirme su inscripción.</p>
        <p>Las alertas y notificaciones deberán restablecerse después de seis meses de inactividad.</p>
      </>),
    },
    modalConfirmationsText: {
        english: 'When you sign up to receive return to work confirmations, you will receive an automated message regarding your return to work date based on the Alerts & notifications selected.',
        spanish: 'Al registrarse, usted recibirá confirmaciones de regreso al trabajo, recibirá un mensaje automatizado relacionado con su fecha de regreso al trabajo con base en la preferencia de comunicación seleccionada.',
    },
    modalNotificationsText: {
        english: 'When you sign up to receive notifications, you will receive automated text messages regarding the status of your claim or leave.',
        spanish: 'Cuando se registre para recibir notificaciones, recibirá mensajes de texto automatizados relacionados con el estado de su reclamo o permiso.',
    },
    confirmations: {
        english: 'Confirmations',
        spanish: 'Confirmaciones',
    },
    notifications: {
        english: 'Notifications',
        spanish: 'Notificaciones',
    },
    youCanChoose: {
        english: 'You can choose which text notifications you would like to receive, related to the status of a claim or leave submission, and also how you would like to receive return to work updates.',
        spanish: 'Puede elegir qué notificaciones de texto le gustaría recibir, relacionadas con el estado de un reclamo o envío de licencia, y también cómo le gustaría recibir actualizaciones de regreso al trabajo.',
    },
    termsAndConditions: {
        english: <>For more details please read the Lincoln Financial Group SMS Message <a href={'/static-docs/LLAC+Terms+and+Conditions_Final.pdf'} target={'_blank'}>terms and conditions</a>.</>,
        spanish: <>Para obtener más detalles, lea los <a href='https://www.mylincolnportal.com/static-docs/LLAC+Terms+and+Conditions_Final.pdf' target='_blank'> términos y condiciones </a> para mensajes de SMS de Lincoln Financial Group.</>,
    },
    save: {
        english: 'Save',
        spanish: 'Guardar',
    },
    claimOrLeave: {
        english: 'CLAIM OR LEAVE STATUS NOTIFICATIONS',
        spanish: 'NOTIFICACIONES DE ESTADO DE RECLAMAR O DEJAR',
    },
    returnToWork: {
        english: 'RETURN TO WORK NOTIFICATIONS',
        spanish: 'NOTIFICACIONES DE REGRESO AL TRABAJO',
    },
    ourRecordsIndicate: {
        english: 'Our records indicate that you have not yet confirmed your enrollment.  If you would like to receive a new phone or text message to confirm your enrollment, we can ',
        spanish: 'Nuestros registros indican que aún no ha confirmado su inscripción. Si desea recibir un nuevo teléfono o mensaje de texto para confirmar su inscripción, podemos ',
    },
    resendItNow: {
        english: 'resend it now.',
        spanish: 'reenviarlo ahora.',
    },
    statusPending: {
        english: 'Status pending',
        spanish: 'Estado: pendiente',
    },
    textNotification: {
        english: 'Text notification',
        spanish: 'Notificación por mensaje de texto',
    },
    phoneCall: {
        english: 'Phone call',
        spanish: 'Llamada telefónica',
    },
    phoneNumber: {
        english: 'Phone number',
        spanish: 'Número de teléfono',
    },
    timeZone: {
        english: 'Time zone',
        spanish: 'Zona horaria',
    },
    edit: {
        english: 'Edit',
        spanish: 'Editar',
    },
    deliveryMethod: {
        english: 'Delivery method',
        spanish: 'Método de entrega',
    },
    deliveryStatus: {
        english: 'Delivery status',
        spanish: 'Estado de entrega'
    },
    contactInfo: {
        english: 'Contact info',
        spanish: 'Datos de contacto',
    },
};
export default CommPrefDisplayText;
