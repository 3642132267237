import React, { useContext } from 'react';
import { StepperContext, Row, Col, Collapse, themes } from '@digitools/honest-react';
import ReviewField, { EditReviewField } from './ReviewField';
import useCliDataContext from '../../hooks/useCliDataContext';
import useTranslate from 'src/hooks/useTranslate';
import { CLI_EMPLOYEE_PHYSICAL_DEMANDS, CLI_EMPLOYEE_TYPE, CLI_EMPLOYEE_WORK_STATUS, CLI_STATES, INCOME_TYPE_OPTIONS } from '../../constants/CliConstants';
import { ReviewText } from '../../constants/ReviewText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { AdditionalInfoText } from '../../constants/AdditionalInfoText';
const ReviewAdditionalInfo = () => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const { additionalDetails } = useCliDataContext();
    const daysOfWeekFound = additionalDetails && Object.keys(additionalDetails)?.filter((detail) => {
        return daysOfTheWeek.indexOf(detail) != -1;
    });
    const capitalizedWords = (arr) => {
        return arr?.map(element => {
            return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
        });
    };
    const employeeType = CLI_EMPLOYEE_TYPE.find(employeeType => employeeType.value === additionalDetails?.employeeType);
    const physicalDemands = CLI_EMPLOYEE_PHYSICAL_DEMANDS.find(physicalDemand => physicalDemand.value === additionalDetails?.physicalDemands);
    const payType = INCOME_TYPE_OPTIONS.find(incomeType => incomeType.value === additionalDetails?.payType);
    const workStatus = CLI_EMPLOYEE_WORK_STATUS.find(workStatus => workStatus.value === additionalDetails?.workstatus);
    const workState = CLI_STATES.find(state => state.value === additionalDetails?.workState);
    return (<><div data-testid="AdditionalInfoReview">
            <div className={`mb-3`}>
                <Collapse headerRightDisplay={<EditReviewField data-testid="editAdditionalInfo" onClick={() => { stepper.edit(3); }}/>} title={t(AdditionalInfoText.pageTitle)} theme={themes.lincoln} type='alternate'>
                    <Row>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.dateOfHireLabel)} value={additionalDetails && additionalDetails.dateOfHire}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={CustomEmployeeText(t(AdditionalInfoText.employeeIDLabel))} value={additionalDetails && additionalDetails?.employeeID}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.workStateLabel)} value={workState?.label}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.subsidaryLabel)} value={additionalDetails && additionalDetails.subsidiary}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.locationBranchLabel)} value={additionalDetails && additionalDetails.locationBranch}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={CustomEmployeeText(t(AdditionalInfoText.employeeTypeLabel))} value={employeeType?.label}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.jobReqsLabel)} value={additionalDetails && additionalDetails.jobReqDesc}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.physicalDemandsLabel)} value={physicalDemands?.label}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.payTypeLabel)} value={payType?.label}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.earningsLabel)} value={additionalDetails?.earnings && `$${additionalDetails?.earnings}`}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.incomeClassLabel)} value={additionalDetails && additionalDetails.incomeClass}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.benefitPercentLabel)} value={additionalDetails?.benefitPercent && `${additionalDetails?.benefitPercent}%`}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={CustomEmployeeText(t(AdditionalInfoText.EEPremConLabel))} value={additionalDetails?.EEPremCon && `${additionalDetails?.EEPremCon}%`}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.ERPremConLabel)} value={additionalDetails?.ERPremCon && `${additionalDetails?.ERPremCon}%`}/>
                        </Col>
                    </Row>
                </Collapse>
            </div>

            <div className={`mb-3`}>
                <Collapse headerRightDisplay={<EditReviewField data-testid="editWorkSchedule" onClick={() => { stepper.edit(3); }}/>} title={'Work schedule (at last time worked) (optional)'} theme={themes.lincoln} type='alternate'>
                    <Row>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.DaysPerWeek)} value={additionalDetails && additionalDetails.daysperweek}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.HoursPerDay)} value={additionalDetails && additionalDetails.hoursperday}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.WorkStatus)} value={workStatus?.label}/>
                        </Col>
                        <Col md={6}>
                            <ReviewField label={t(ReviewText.additionalInfo.ScheduledWorkDays)} value={capitalizedWords(daysOfWeekFound)?.join(', ')}/>
                        </Col>
                    </Row>
                </Collapse>
            </div>
        </div></>);
};
export default ReviewAdditionalInfo;
