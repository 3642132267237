import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './packages/status/i18n/languages/english';
import spanish from './packages/status/i18n/languages/spanish';
import RabSpanish from './packages/rab/i18n/languages/spanish';
import RabEnglish from './packages/rab/i18n/languages/english';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translations: english,
        rabTranslation: RabEnglish,
      },
      es: {
        translations: spanish,
        rabTranslation: RabSpanish,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
