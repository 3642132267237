import { Box, Button, Col, Heading, Icon, Row, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImportantMessageBox from '../../components/ImportantMessageBox';
import useExperience from '../../../../hooks/useExperience';
import { Experience } from '../../../../types/Experience';
import { getEnvVariable } from '../../../../utils/envUtils';
import NextSteps from './NextSteps';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import { CliConfirmationText } from '../../i18n/CliConfirmationText';
import useTranslate from '../../../../hooks/useTranslate';
import VideoBox from '../../components/VideoBox';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import useTealium from '../../../../hooks/useTealium';
import { TaggedCustomerName } from '../../components/UtilityComponents';
const BorderedTextDiv = styled('div') `
  border-left: 3px solid ${({ theme }) => theme.palette.primary};
  padding-left: .5rem;
  margin-bottom: 1rem;
`;
const GrayBottomDiv = styled('div') `
  color: ${({ theme }) => theme.palette.grayDark};
  padding-bottom: .5rem;
  border-bottom: 6px solid ${({ theme }) => theme.palette.grayLightest};
`;
const PrettyBox = styled(Box) `
  border-width: 1px;
  border-color: ${({ theme }) => theme.palette.grayLighter};
  box-sizing: border-box;
  transition: none;
`;
const InlineTextButton = styled(Button) `
  padding: 0px;
  color: ${({ theme }) => theme.palette.secondaryDark};
`;
const Confirmation = (props) => {
    const { experience } = useExperience();
    const { trackEvent, trackView } = useTealium();
    const { t } = useTranslate();
    const { overviewTitleConfirmation, overviewBodyConfirmation } = useCliCustomerConfigContext();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CLI,
            page_l4: experience === Experience.EMPLOYEE ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER,
            page_l5: TEALIUM_PAGE_VIEW.PAGE_L4.CLI.CLI_CONFIRMATION,
        });
    }, []);
    /* ************ END LOADING LOGIC ************/
    // TODO: (probably should put into useEffect once this is fully hooked up to prevent more re-reads)
    let cliData;
    let submissionType = 'claim';
    let claimant;
    let eventNumber;
    let submissionDate;
    if (window.sessionStorage.getItem('CliSubmitData')) {
        const stringifiedCliData = window.sessionStorage.getItem('CliSubmitData');
        if (stringifiedCliData) {
            cliData = JSON.parse(stringifiedCliData);
            // --- We've got data from sessionStorage - pull useful bits into easy to access vars
            if (cliData.validationResults.submissionType === "Claim") {
                submissionType = 'claim';
            }
            else if (cliData.validationResults.submissionType === "Leave") {
                submissionType = 'leave';
            }
            else if (cliData.validationResults.submissionType === "Both") {
                submissionType = 'claimleave';
            }
            else {
                // throw error //FIXME: need to handle this case
            }
            claimant = `${cliData.claimantData.firstName} ${cliData.claimantData.lastName}`;
            submissionDate = `${cliData.validationResults.dateOfSubmit} ${cliData.validationResults.timeOfSubmit}`;
            eventNumber = `${cliData.validationResults.claimOrLeaveNumber}`;
        }
        else {
            // throw error //FIXME: need to handle this case
        }
    }
    else {
        console.log('Error submitting CLI');
        // throw error
    }
    /* ************ END LOADING LOGIC ************/
    const viewSubmission = () => {
        let url;
        if (experience === Experience.EMPLOYEE) {
            url = getEnvVariable('cliUrlEE');
            // url = 'http://localhost:8091/cli/employee';
        }
        else {
            url = getEnvVariable('cliUrl');
            // url = 'http://localhost:8091/cli/employer';
        }
        url += '/viewSubmission';
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_name: `${submissionType} view submission`,
        });
        window.open(url, "_blank"); // open page in new tab
    };
    const [hasVideo, setHasVideo] = useState(CliConfirmationText[submissionType].videoUrl !== undefined);
    const errorLoadingVideo = () => {
        setHasVideo(false);
        console.log('video does not exist');
    };
    useEffect(() => {
        if (hasVideo) {
            let videoElement = document.getElementById('video-message-link');
            if (videoElement) {
                videoElement.addEventListener('click', () => {
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                        event_name: `${submissionType} video link clicked`,
                    });
                });
            }
            document.getElementsByClassName('brightcove-react-player-loader')[0]
                .addEventListener('click', () => {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.VIDEO_ENGAGEMENT,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_name: `${submissionType} video box clicked`,
                });
            });
        }
    }, []);
    // TODO: determine mobile/responsive location for video content (below? or slotted in between other elements)
    // TODO: need spanish text for all static content/labels on this page
    // TODO:L button color for text needs to match the same blue we use for links -> will be an Honest change
    return (<>
      <TaggedCustomerName />
      <Row data-public='true' className={hasVideo ? "justify-content-lg-between justify-content-md-center" : ""}>
        {/* Main Content Column */}
        <Col lg={hasVideo ? 7 : 10} md={12} sm={12} xs={12}>
          <Heading elemType={'h1'} elemStyle={'h3'} data-testid='confirmationHeading'>
            {t(CliConfirmationText[submissionType].pageHeading)}
          </Heading>

          <BorderedTextDiv data-testid='confirmationSubHeading'>
            <span data-testid='confirmationSubheadingSpan'>{t(CliConfirmationText[submissionType].subHeading)}</span>
            <br />
            {/* TODO: Translate/put in CliConfirmationText - during video story where this may change */}
            {hasVideo && t(CliConfirmationText[submissionType].videoMessage)}
          </BorderedTextDiv>

          <PrettyBox>
            <Row>
              <Col>
                {eventNumber &&
            <Heading elemType={'h2'} elemStyle={'h3'} color={'primaryDarker'}>
                    <span data-testid='eventHeading'>
                      {t(CliConfirmationText[submissionType].eventNumber)} {eventNumber}
                    </span>
                  </Heading>}
              </Col>
              <Col>
                <InlineTextButton buttonType='text' onClick={viewSubmission}><Icon name='print-solid' style={{ color: themes.lincoln.palette.secondaryDark }}/>
                  {t({ english: ' View and print submission', spanish: ' Ver e imprimir envío' })}
                </InlineTextButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <GrayBottomDiv data-private='true'>
                  <strong>{t({ english: 'CLAIMANT NAME', spanish: 'NOMBRE DEL RECLAMANTE' })}</strong>
                  <br />
                  {claimant}
                </GrayBottomDiv>
              </Col>
              <Col>
                <GrayBottomDiv>
                  <strong>{t({ english: 'TODAY\'S DATE', spanish: 'FECHA' })}</strong>
                  <br />
                  {submissionDate}
                </GrayBottomDiv>
              </Col>
            </Row>
          </PrettyBox>
          {(overviewBodyConfirmation && overviewBodyConfirmation.english) && (<ImportantMessageBox aria-label={`Getting started message`} data-testid={`employerMessage`} title={overviewTitleConfirmation ? t(overviewTitleConfirmation) : ''}>
              {overviewBodyConfirmation && t(overviewBodyConfirmation)}
            </ImportantMessageBox>)}
          <Heading elemType='h3' elemStyle='h3'>{t(CliConfirmationText[submissionType].ourNextStepsHeading)}</Heading>
          <p>{t(CliConfirmationText[submissionType].ourNextStepsBody)}</p>

          <Heading elemType='h3' elemStyle='h3'>{t(CliConfirmationText[submissionType].nextStepsHeading)}</Heading>
          {submissionType && <NextSteps eventNum={eventNumber} submissionType={submissionType}/>}

          <br style={{ marginBottom: '1rem' }}/>
          <p data-testid='confirmationQuestions'>
            {t(CliConfirmationText[submissionType].questions)}
          </p>

        </Col>

        {/* SideBar Column */}
        {hasVideo &&
            <Col lg={4} md={7} sm={12} xs={12} data-testid='videoBox'>
            <VideoBox submissionType={submissionType} onFailure={errorLoadingVideo}></VideoBox>
          </Col>}
      </Row>
    </>);
};
export default Confirmation;
