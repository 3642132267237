import styled from 'styled-components';
import useTranslate from '../../../hooks/useTranslate';
import useEoiContext from '../hooks/useEoiContext';
const FormNumbersContainer = styled('div') `
    font-weight: 500;
    font-size: 1rem;
    color: black;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
`;
const LegalFormNumbers = ({ showSupplementalForm }) => {
    const { legalText, } = useEoiContext();
    const { t } = useTranslate();
    return (<FormNumbersContainer data-testid='form-numbers'>
      {legalText && <>
        <span data-testid='form-num'>{t(legalText.footerPreText)} {t(legalText.footerSufText)}</span>
        {showSupplementalForm && legalText.supplementalForm
                && <><span style={{ marginLeft: '.5rem', marginRight: '.5rem' }}> | </span><span data-testid='supp-form-num'>{t(legalText.supplementalForm)}</span></>}</>}
    </FormNumbersContainer>);
};
export default LegalFormNumbers;
