export const BdmAddBeneficiaryModalDisplayTextEs = {
    addBeneficiary: 'Añadir Beneficiario',
    address1: 'Dirección 1',
    address1LCI: 'Dirección 1 (opcional)',
    address2: 'Dirección 2 (opcional)',
    allocation: 'Asignación %',
    beneficiaryCategoryContingent: 'Contingente',
    beneficiaryCategory: 'Categoría de Beneficiario',
    beneficiaryCategoryPrimary: 'Primario',
    beneficiaryTypePlaceholder: 'Seleccione Tipo',
    beneficiaryType: 'Tipo de Beneficiario',
    cancelButton: 'Cancelar',
    charityOrgName: 'Nombre de Caridad/Organización',
    city: 'Ciudad',
    cityLCI: 'Ciudad (opcional)',
    contactName: 'Nombre de Contacto',
    country: 'País',
    countryLCI: 'País (opcional)',
    dateOfBirth: 'Fecha de Nacimiento',
    dateOfTrust: 'Fecha de Fideicomiso',
    delete: 'Eliminar',
    deleteMessage: '¿Está seguro de que desea eliminar este beneficiario?',
    edit: 'Editar',
    editBeneficiary: 'Editar Beneficiario',
    emailAddress: 'Correo de electrónico (opcional)',
    equal: 'IGUAL',
    firstName: 'Primer Nombre',
    gender: 'Sexo (opcional)',
    genderPlaceholder: 'Seleccione un Sexo',
    guardianAdvisory: 'Por favor tenga en cuenta que si el beneficiario es menor de 18 años, la tutela de las propiedades del menor deberán ser establecidas a través de un Tribunal de Sucesiones',
    guardianInformation: 'Información del Tutor (opcional)',
    guardianName: 'Nombre del Tutor (opcional)',
    lastName: 'Apellido',
    middleInitial: 'MI (opcional)',
    modalHeader: 'Información del Beneficiario',
    otherAddress: 'Dirección e información de contacto',
    phoneNumber: 'Número de Teléfono (opcional)',
    postalCode: 'Código Postal',
    postalCodeLCI: 'Código Postal (opcional)',
    province: 'Provincia',
    provinceLCI: 'Provincia (opcional)',
    relationship: 'Parentesco',
    relationshipPlaceholder: 'Seleccione Parentesco',
    ssnOrTaxID: 'NSS o Número de Identificación Fiscal (opcional)',
    state: 'Estado',
    stateLCI: 'Estado (opcional)',
    stateAndProvincePlaceholder: 'Seleccione una opción',
    trustName: 'Nombre del Fideicomiso',
};
