import React, { useEffect } from 'react';
import { Icon } from '@digitools/honest-react';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
const EmployeeNameLogout = ({ employeeName, triggerFormReset, page }) => {
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.EMPLOYEE_LOGOUT,
        });
    }, []);
    const logout = () => {
        trackClickWithPage('Logout', '/uaMobile/' + page);
        triggerFormReset();
    };
    return (<div style={{ color: '#002663', fontWeight: 'bold', fontSize: '16px' }}>
        <span style={{ float: 'left' }} data-testid='employee-name'>
          <span data-private={true}>{employeeName}</span>
        </span>
        <a data-testid='logout-link' onClick={logout} style={{ float: 'right', color: '#002663' }}>
          <Icon style={{ color: '#002663' }} name={'lock'}/>
          <span> Logout</span>
        </a>
      <br />
      <br />
    </div>);
};
export default EmployeeNameLogout;
