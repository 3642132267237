import { Button, Col, Row, Slideout } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import { PROVE_PROMPT_TEXT } from './ProveText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const ProvePrompt = () => {
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const { user } = useAuthentication();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const navigate = useNavigate();
    const { trackEvent } = useTealium();
    // 👩‍💻 local  hack to reset session storage
    // sessionStorage.setItem('skipIdentityVerify', 'false');
    // Creates slideout delay  ~* ✨ Effects ✨ *~ by not initializing as true on first render
    useEffect(() => {
        if (user?.isLfgUser && experience === Experience.EMPLOYEE) {
            const isIdentityVerified = user?.isIdentityVerified; // Mocked for now - user object
            const identityVerifyFailCount = user?.identityVerifyFailCount || 0; // Set to fill in 0 for undefined value
            const skipIdentityVerify = sessionStorage.getItem('skipIdentityVerify') === 'true';
            const completeIdentityVerify = sessionStorage.getItem('completeIdentityVerify') === 'true';
            if (!isIdentityVerified && !skipIdentityVerify && !identityVerifyFailCount && !completeIdentityVerify) {
                setIsPromptOpen(true);
                trackEvent({
                    page_l3: TEALIUM_EVENT.EVENT_VERSION.HOMEPAGE,
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SLIDE_OUT,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                    event_name: 'verify your identity',
                    event_version: TEALIUM_EVENT.EVENT_VERSION.HOMEPAGE
                });
            }
        }
    }, [user]);
    const skipForNow = () => {
        setIsPromptOpen(false);
        sessionStorage.setItem('skipIdentityVerify', 'true');
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VERIFY_YOUR_IDENTITY,
            event_name: 'skip for now',
        });
    };
    const verifyNow = () => {
        setIsPromptOpen(false);
        sessionStorage.setItem('completeIdentityVerify', 'true');
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VERIFY_YOUR_IDENTITY,
            event_name: 'complete identity verification',
        });
        //TODO: could add in delay for some slide out steeze before navigation - maybe 1.5 second setTimeout (make sure tests still pass)
        navigate('/prove');
    };
    return (<>
    {user?.isLfgUser && experience === Experience.EMPLOYEE &&
            <Slideout 
            // Moving this into honest in follow up
            // heading={(<div style={{color: themes.secureSite.palette.primaryDark}}><strong><Icon name='document-magnify' color='primaryDark' size='large' className='mr-2'/></strong>{t(PROVE_PROMPT_TEXT.HEADING)}</div>) as any}
            heading={t(PROVE_PROMPT_TEXT.HEADING)} inline={false} setIsOpenOverride={setIsPromptOpen} isOpenOverride={isPromptOpen} hideButton={true} openButton={''} //Ignored because of hide, required by interface still
            >
          <hr />
        <p>
          {t(PROVE_PROMPT_TEXT.P1)}
        </p>
        <p>
          {t(PROVE_PROMPT_TEXT.P2)}
        </p>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <Button buttonType='primary' type='submit' onClick={() => verifyNow()}>
              {t(PROVE_PROMPT_TEXT.COMPLETE_BUTTON)}
            </Button>
            <Button buttonType='text' type='button' onClick={() => skipForNow()}>
              {t(PROVE_PROMPT_TEXT.SKIP_BUTTON)}
            </Button>
          </Col>
        </Row>
      </Slideout>}
    </>);
};
export default ProvePrompt;
