const absReasons = {
  FAMILY: 'Sick Family Member',
  SELF: 'Personal Sickness',
  BEREAV: 'Death in the Family',
  TRANSP: 'Transportation Problem',
  HOUSE: 'House Maintenance Problem',
  CIVIC: 'Civic Duty',
  OTHER: 'Other',
};

const absTypes = {
  OUT: 'Absent for Full Shift',
  EARLYNORTN: 'Leaving Early (Not Returning)',
  EARLYRTN: 'Leaving Early (Returning)',
  LATE: 'Arriving Late',
};

const enteredByTypes = {
  E: 'Employee',
  M: 'Manager',
  O: 'Other',
};

const submissionMethods = {
  I: 'IVR',
  M: 'Mobile',
  W: 'Web',
};

const transTypes = {
  A: 'New',
  D: 'Cancel',
  C: 'Change',
  I: 'Info',
};

export { absReasons, absTypes, enteredByTypes, submissionMethods, transTypes };
