import React, { useEffect, useState } from 'react';
import IdentityVerification from '../verification/IdentityVerification';
import LoadSavedCli from '../verification/LoadSavedCli';
import { RedBulletList } from 'src/components/Formatting/StyledComponents';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import ImportantMessageBox from 'src/packages/cli/components/ImportantMessageBox';
import GettingStartedText from './GettingStartedText';
import { Box, Heading, themes, Collapse, Icon } from '@digitools/honest-react';
import styled from 'styled-components';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
// @ts-ignore
import QuickTipsImg from 'src/assets/quick_tips.svg';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useCliContext from '../../hooks/useCliContext';
const BoxContainer = styled('div') `
  @media (max-width: 1024px) {
    display: block;
  }
  display: flex;
  min-height: 8.125rem;
`;
const InlineColorHeaderBox = styled(Box) `
  @media (max-width: 1024px) {
    width: 100%;
    flex: 1;
  }
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  /* align-items: center; */
  padding: 1.5rem;
  min-width: 100px; 
  width: 8.125rem;
`;
const InlineContentBox = styled(Box) `
  @media (min-width: 1025px) {
    border-left: none;
  }e
  display: flex;
  flex: 1;
  margin-top: 0px;
`;
const StyledIcon = styled(Icon) `
  font-size: 2.5rem; 
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 2rem;
}
`;
const StyledNYDIBox = styled(Box) `
  background-color: ${({ theme }) => theme.palette.grayLightest};
  display: flex;
  flexDirection: row;

`;
const welcomeTranslatable = {
    english: 'Welcome',
    spanish: 'Bienvenido'
};
const GettingStarted = () => {
    const { t } = useTranslate();
    const { user } = useAuthentication();
    const { messageHeading, messageText, identityInfoText } = useCliCustomerConfigContext();
    const [showVerification, setShowVerification] = useState(false);
    const { experience } = useExperience();
    const { saveForLaterEntries, isNYDICustomer } = useCliContext();
    const [showNYDIBlock, setShowNYDIBlock] = useState();
    const quickTipsHeaderLabel = experience === Experience.EMPLOYEE ?
        t(GettingStartedText.quickTipsHeaderEE) :
        `${t(GettingStartedText.quickTipsHeaderER)}`;
    const collapseHeaderLabel = experience === Experience.EMPLOYEE ?
        t(GettingStartedText.collapseHeader) :
        `${t(GettingStartedText.collapseHeaderER)}`;
    // Dev use only: Use this to jump around the interview steps
    // const stepper = useContext(StepperContext);
    // useEffect(() => {
    //   stepper.goToStep(4);
    // }, []);
    useEffect(() => {
        if (isNYDICustomer && user?.customerInContext?.smallMid) {
            return setShowNYDIBlock(true);
        }
        else {
            return setShowNYDIBlock(false);
        }
    });
    return (<>
      <Heading data-private={true} elemType='h2' color='primary' data-testid={`cliWelcomeMessage`}>{t(welcomeTranslatable)} {user?.firstName}</Heading>
      {showNYDIBlock && <StyledNYDIBox data-testid={`NYDIBlockMessage`}>
            <StyledIcon name={'important-message'} size={'large'} color={'primary'}/>
            <p className={'mt-0'}>{t(GettingStartedText.pageInfo)}</p>
          </StyledNYDIBox>}
      {(messageText && messageText.english) && (<ImportantMessageBox aria-label={`Getting started message`} data-testid={`getting-started-message`} title={messageHeading ? t(messageHeading) : ''}>
          {messageText && t(messageText)}
        </ImportantMessageBox>)}
      <BoxContainer data-testid='cli-quick-tips' style={{ marginTop: '1.875rem' }} data-public={true}>
        <InlineColorHeaderBox type='color' theme={themes.lincoln} data-public={true}>
          <QuickTipsImg width='60px' height='66px' aria-hidden={true} data-public={true}/>
        </InlineColorHeaderBox>
        <InlineContentBox data-public={true}>
          <div style={{ display: 'block' }}>
            <Heading elemType='h3' theme={themes.lincoln}>{t(GettingStartedText.header)}</Heading>
            <Collapse type='alternate' isOpen={true} title={collapseHeaderLabel} theme={themes.lincoln}>
              <div style={{ marginLeft: '1rem' }}>
                <Heading elemType='h3' elemStyle='h4'>{t(GettingStartedText.verificationInfoHeader)}</Heading>
                <RedBulletList>
                  <li><span>
                    {identityInfoText && CustomEmployeeText(t(identityInfoText))}
                  </span></li>
                </RedBulletList>
                {experience === Experience.EMPLOYEE && (<><Heading elemType='h3' elemStyle='h4'>{t(GettingStartedText.claimDetailsHeader)}</Heading><RedBulletList>
                    <li><span>{t(GettingStartedText.reasonForClaim)}</span></li>
                  </RedBulletList><Heading elemType='h3' elemStyle='h4'>{t(GettingStartedText.medicalInfoHeader)}</Heading><RedBulletList>
                      <li><span>{t(GettingStartedText.physician)}</span></li>
                    </RedBulletList></>)}
                <Heading elemType='h3' elemStyle='h4'>{quickTipsHeaderLabel}</Heading>
                <RedBulletList>
                  <li><span>{t(GettingStartedText.quickTipsText)}</span></li>
                  {experience === Experience.EMPLOYEE && (<><li><span>{t(GettingStartedText.timeToFill)}</span></li>
                      <li><span>{t(GettingStartedText.timeOut)}</span></li></>)}
                  {experience === Experience.EMPLOYER && (<li><span>{t(GettingStartedText.timeOutER)} <strong>{t(GettingStartedText.timeOutER1)}</strong> {CustomEmployeeText(t(GettingStartedText.timeOutER2))}</span></li>)}
                </RedBulletList>
                <br />
              </div>
            </Collapse>
          </div>
        </InlineContentBox>
      </BoxContainer>
      {(saveForLaterEntries?.rowID && !showVerification) ? <LoadSavedCli setShowVerification={setShowVerification}/> : <IdentityVerification />}
    </>);
};
export default GettingStarted;
