import { Col } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField from '../ReviewField';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { DeliveryIndicator, LeaveType } from '../../../providers/CliConfigProvider';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
const ReviewPregnancyFlow = () => {
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { experience } = useExperience();
    const { deliveryIndicators } = useCliCustomerConfigContext();
    const getDeliveryComplicationsAnswer = (deliveryComplicationIndicator) => {
        switch (deliveryComplicationIndicator) {
            case 'Y': {
                return t({ english: 'Yes', spanish: 'Sí' });
            }
            case 'N': {
                return 'No';
            }
            case 'U': {
                return t({ english: 'Unknown', spanish: 'No conocida' });
            }
            default: {
                return '';
            }
        }
    };
    const shouldDisplayDeliveryIndicator = absenceData?.claimantCondition === 'P' || absenceData?.leaveType === LeaveType.PREGNANT && absenceData?.leaveCategory;
    const shouldDisplayDeliveryDate = shouldDisplayDeliveryIndicator && absenceData?.deliveryIndicator;
    const shouldDisplayLastDayWorked = shouldDisplayDeliveryDate && absenceData?.deliveryDate && absenceData?.deliveryDate.length === 10;
    return (<>    
            {absenceData?.leaveType && (<>
                    {absenceData?.deliveryIndicator && shouldDisplayDeliveryIndicator && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-testid='deliveryIndicator' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.deliveryTypeEE)
                    : t(formLabels.deliveryTypeER))} value={absenceData && (t(deliveryIndicators.filter(deliveryIndicator => deliveryIndicator.code == absenceData?.deliveryIndicator)[0]))}/>
                        </Col>)}
                    {(absenceData?.deliveryIndicator === DeliveryIndicator.VAGINAL || absenceData?.deliveryIndicator === DeliveryIndicator.CSECTION) && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-testid='deliveryDate' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.deliveryDateShortLabel)
                    : t(formLabels.deliveryDateShortLabel))} value={absenceData && absenceData.deliveryDate}/>
                        </Col>)}
                    {absenceData?.deliveryIndicator === DeliveryIndicator.NO && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-testid='expectedDate' label={t(formLabels.deliveryDateExpectedShortLabel)} value={absenceData && absenceData.deliveryDate}/>
                        </Col>)}
                    {shouldDisplayLastDayWorked && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-testid='lastDayWorked' label={t(formLabels.lastDayWorkedShort)} value={absenceData && absenceData.lastDayWorked}/>
                        </Col>)}

                    {absenceData?.deliveryIndicator === DeliveryIndicator.NO && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-testid='deliveryComplicationIndicator' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.deliveryComplicationsEE)
                    : t(formLabels.deliveryComplicationsER))} value={absenceData && getDeliveryComplicationsAnswer(absenceData.deliveryComplicationIndicator)}/>
                        </Col>)}
                    {(absenceData?.deliveryIndicator === DeliveryIndicator.VAGINAL || absenceData?.deliveryIndicator === DeliveryIndicator.CSECTION) && (<>
                            <Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='admissionDate' label={t(formLabels.admissionDateShortLabel)} value={absenceData && absenceData.admissionDate}/>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='releaseDate' label={t(formLabels.releaseDateShortLabel)} value={absenceData && absenceData.releaseDate}/>
                            </Col>
                        </>)}
                    {(absenceData?.leaveType === LeaveType.PREGNANT || absenceData?.claimantCondition === 'P') && (<Col xs={12} md={6} lg={6}>
                            <ReviewField data-private={true} label={t(formLabels.surgeryComplicationsTextShort)} value={absenceData && absenceData?.surgeryComplicationText}/>
                        </Col>)}
                </>)}
        </>);
};
export default ReviewPregnancyFlow;
