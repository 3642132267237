import React, { memo, useContext, useEffect, useRef } from 'react';
import { Button, Modal } from '@digitools/honest-react';
import useTimeout from '../../hooks/useTimeout';
import { AuthenticationContext } from '../../providers/AuthenticationProvider';
import useAnyAction from '../../hooks/useAnyAction';
import useExperience from '../../hooks/useExperience';
import TimeoutModalDisplayTextEn from './i18n/TimeoutModalDisplayTextEn';
import TimeoutModalDisplayTextEs from './i18n/TimeoutModalDisplayTextEs';
import getDisplayText from '../../utils/languageDisplay';
const TimeoutModal = ({ ms = 1200000, sRemainingForModal = 300 }) => {
    const { isAuthenticated, logout } = useContext(AuthenticationContext);
    const [ready, sRemaining, reset] = useTimeout(ms, isAuthenticated);
    const { language } = useExperience();
    const isOpenRef = useRef(() => { });
    const showingModal = sRemaining <= sRemainingForModal;
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, TimeoutModalDisplayTextEn, TimeoutModalDisplayTextEs, language]);
    const setControl = (setIsOpen) => {
        isOpenRef.current = setIsOpen;
    };
    // Don't reset timer if modal is already showing
    useAnyAction(!showingModal && isAuthenticated ? reset : undefined);
    useEffect(() => {
        if (showingModal) {
            isOpenRef.current(true);
        }
    }, [showingModal]);
    // TODO: release render props types from Honest
    const FooterPrimaryButton = memo(({ hideModal }) => {
        const handleClick = () => {
            reset();
            hideModal();
        };
        return (<Button data-testid={'timeout-continue-button'} buttonType={'primary'} onClick={handleClick}>
        {getLocalDisplayText('continue')}
      </Button>);
    });
    if (ready && sRemaining < 1) {
        isOpenRef.current(false);
        logout();
    }
    return (<>
      {showingModal && (<Modal headerText={getLocalDisplayText('title')} footerPrimaryRenderButton={FooterPrimaryButton} getControl={setControl}>
          <div data-testid={'timeout-modal-content'}>
            <p>{getLocalDisplayText('timeout')(sRemaining)}</p>
            <p>{getLocalDisplayText('clickHere')}</p>
          </div>
        </Modal>)}
    </>);
};
export default memo(TimeoutModal);
