import { Button, Col, Heading, Row, StepperContext, TextInput } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { formatPhone, lettersOnly, validateName } from '../../../../utils/validationUtils';
import Address from '../address/Address';
import CancelLci from '../cancelLCI/CancelLci';
import { MAX_LENGTH_FNAME, MAX_LENGTH_LNAME } from '../../constants/LCIConstant';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
const StyledTextInput = styled(TextInput) `
  ‚ @media (max-width: 48rem) {
    width: 100%;
  }
  @media (min-width: 48rem) {
    width: 5rem;
    min-width: 5rem;
  }
`;
const fieldMap = {
    callerInfo_firstName: 'firstName',
    callerInfo_lastName: 'lastName',
    callerInfo_middleInitial: 'middleInitial',
    callerInfo_phoneNumber: 'phoneNumber',
    callerInfo_relationshipToDeceased: 'relationshipToDeceased',
    callerInfo_street1: 'streetAddress1',
    callerInfo_street2: 'streetAddress2',
    callerInfo_city: 'city',
    callerInfo_postalcode: 'postalcode',
    callerInfo_state: 'state',
    callerInfo_province: 'province',
    callerInfo_country: 'country',
};
const CallerInfo = ({ onComplete, claimant, event }) => {
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    function handleContinue(values) {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'caller info',
            event_status: event === 'death' && claimant === 'dependent' ? 'step 7' : 'step 6',
        });
        const callerInfoObj = {};
        Object.entries(fieldMap)
            .filter(([key, value]) => !!values[key])
            .map(([key, value]) => {
            callerInfoObj[value] = values[key];
        });
        onComplete(callerInfoObj);
        stepper.next();
    }
    const handleBack = () => {
        stepper.back();
    };
    return (<Form onSubmit={handleContinue}>
      <Heading elemType='h2' color='primary'>
        Caller Information
      </Heading>
      <Row>
        <Col>
          <BorderLessGrayBox>
            <Heading elemType='h3' elemStyle='h4'>
              Caller Personal Information
            </Heading>
          </BorderLessGrayBox>
        </Col>
      </Row>
      <Row>
        <Col lg={5} md={5} sm={12}>
          <TextInput ariaLabelText={'FirstName'} field={'callerInfo_firstName'} label={'First Name (optional)'} maxLength={MAX_LENGTH_FNAME} validate={(value) => validateName(value, MAX_LENGTH_FNAME, 'First Name', false)} validateOnBlur={true} type={'text'} data-private={true}/>
        </Col>
        <Col lg={5} md={5} sm={12}>
          <TextInput ariaLabelText={'LastName'} field={'callerInfo_lastName'} label={'Last Name (optional)'} maxLength={MAX_LENGTH_LNAME} validate={(value) => validateName(value, MAX_LENGTH_LNAME, 'Last Name', false)} validateOnBlur={true} type={'text'} data-private={true}/>
        </Col>
        <Col lg={2} md={2} sm={12}>
          <StyledTextInput ariaLabelText={'MiddleInitial'} field={'callerInfo_middleInitial'} label={'MI (optional)'} maxLength={1} mask={lettersOnly} type={'text'} data-private={true}/>
        </Col>
      </Row>
      <Row className='mt-0'>
        <Col>
          <TextInput ariaLabelText={'PhoneNumber'} field={'callerInfo_phoneNumber'} label={'Phone Number (optional)'} mask={formatPhone} maxLength={12} validateOnBlur={true} type={'text'}/>
        </Col>
        <Col>
          <TextInput ariaLabelText={'Relationship To Deceased'} field={'callerInfo_relationshipToDeceased'} label={'Relationship To Deceased (optional)'} validateOnBlur={true} type={'text'}/>
        </Col>
      </Row>
      <Address field='callerInfo' title='Caller Address' required={false}/>
      <StyledDiv>
        <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='back_caller_info' type='button' onClick={handleBack}>
          Back
        </Button>
        <CancelLci step={event === 'death' && claimant === 'dependent' ? 'step 7' : 'step 6'} pageTitle={'caller info'} sectionInfo={'Caller info'}/>
        <Button aria-label={'Continue'} buttonType='primary' role='button' id='continue_caller_info' type='submit'>
          Continue
        </Button>
      </StyledDiv>
    </Form>);
};
export default CallerInfo;
