import React, { useReducer } from 'react';
import { Col, Row, TextInput } from '@digitools/honest-react';
import { MigrateDisplayText } from '../i18n/MigrateDisplayText';
import useTranslate from 'src/hooks/useTranslate';
import reducer, { initialState } from '../reducer';
import * as actions from 'src/packages/userMigration/actions';
import useValidMigrateUsername from 'src/hooks/fieldValidation/useValidMigrateUsername';
const Username = ({ hasMigrationError, setHasMigrationError, initialUsername, ...props }) => {
    const { language, getLocalDisplayText } = props;
    const validateUsername = useValidMigrateUsername(true);
    const { t } = useTranslate();
    const format = (value) => (value != null ? value.trim() : value);
    const parse = (value) => (value != null ? value.trim() : value);
    const [fieldError, dispatch] = useReducer(reducer, initialState);
    const usernameHelperText = usernameHelperText => {
        return (<ul className={'mt-0'}>
        <li className={'mt-0'}>{usernameHelperText[0]}</li>
        <li className={'mt-0'}>{usernameHelperText[1]}</li>
        <li className={'mt-0'}>{usernameHelperText[2]}</li>
        <li className={'mt-0'}>{usernameHelperText[3]}</li>
      </ul>);
    };
    const clearError = (e) => {
        dispatch(actions.clear());
        if (!initialUsername || e.target.value != initialUsername) {
            setHasMigrationError(false);
        }
    };
    return (<Row className={'mt-3 mb-0'}>
      <Col sm={12} className={'mb-0 mb-sm-0'}>
        <TextInput data-public={true} data-testid={'idm-username-field'} field={'idmUsername'} format={format} label={t(MigrateDisplayText.updateUsername.usernameLabel)} placeholder={t(MigrateDisplayText.updateUsername.usernameLabel)} onChange={e => {
            clearError(e);
        }} parse={parse} validate={validateUsername} validateOnBlur={true} helperText={usernameHelperText(getLocalDisplayText('usernameHelperTextMigration'))} maxLength={35} language={language}/>
      </Col>
    </Row>);
};
export default Username;
