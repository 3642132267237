import React from 'react';
import moment from 'moment';
import { BeneficiaryRow } from '../components/BdmBeneficiaryRow';
import { BDM_BENEFICIARY_TYPES } from '../constants/BdmConstants';
import { findMyInformationDisplayTextEn as LanguageEn } from '../language/FindMyInformationDisplayTextEn';
import { findMyInformationDisplayTextEs as LanguageEs } from '../language/FindMyInformationDisplayTextEs';
import { Language } from '../../../types/Language';
import LanguageToggle from '../../../utils/LanguageToggle';
import { validationDisplayTextEn } from '../../../utils/language/validationDisplayTextEn';
import { validationDisplayTextEs } from '../../../utils/language/validationDisplayTextEs';
import { uniqueId } from 'lodash';
const ssnPattern = /^[0-9]{9}$/;
const benPerson = BDM_BENEFICIARY_TYPES[0].value;
const benEstate = BDM_BENEFICIARY_TYPES[1].value;
const benCharity = BDM_BENEFICIARY_TYPES[2].value;
const benTrust = BDM_BENEFICIARY_TYPES[3].value;
const CATEGORY_KEYS = {
    PRIMARY: {
        beneficiaryCategory: 'PRIMARYbeneficiaryCategory',
        beneficiaryType: 'PRIMARYbeneficiaryType',
        allocation: 'PRIMARYallocation',
        firstName: 'PRIMARYfirstName',
        lastName: 'PRIMARYlastName',
        middleInitial: 'PRIMARYmiddleInitial',
        dateOfBirth: 'PRIMARYdateOfBirth',
        guardianName: 'PRIMARYguardianName',
        guardianInfo: 'PRIMARYguardianInfo',
        relationship: 'PRIMARYrelationship',
        phoneNumber: 'PRIMARYphoneNumber',
        emailAddress: 'PRIMARYemailAddress',
        gender: 'PRIMARYgender',
        charityName: 'PRIMARYcharityName',
        contactName: 'PRIMARYcontactName',
        trustName: 'PRIMARYtrustName',
        dateOfTrust: 'PRIMARYdateOfTrust',
        ssnOrTaxID: 'PRIMARYssnOrTaxID',
        address1: 'PRIMARYaddress1',
        address2: 'PRIMARYaddress2',
        country: 'PRIMARYcountry',
        state: 'PRIMARYstate',
        province: 'PRIMARYprovince',
        otherAddress: 'PRIMARYotherAddress',
        city: 'PRIMARYcity',
        postalCode: 'PRIMARYpostalCode',
    },
    CONTINGENT: {
        beneficiaryCategory: 'CONTINGENTbeneficiaryCategory',
        beneficiaryType: 'CONTINGENTbeneficiaryType',
        allocation: 'CONTINGENTallocation',
        firstName: 'CONTINGENTfirstName',
        lastName: 'CONTINGENTlastName',
        middleInitial: 'CONTINGENTmiddleInitial',
        dateOfBirth: 'CONTINGENTdateOfBirth',
        guardianName: 'CONTINGENTguardianName',
        guardianInfo: 'CONTINGENTguardianInfo',
        relationship: 'CONTINGENTrelationship',
        phoneNumber: 'CONTINGENTphoneNumber',
        emailAddress: 'CONTINGENTemailAddress',
        gender: 'CONTINGENTgender',
        charityName: 'CONTINGENTcharityName',
        contactName: 'CONTINGENTcontactName',
        trustName: 'CONTINGENTtrustName',
        dateOfTrust: 'CONTINGENTdateOfTrust',
        ssnOrTaxID: 'CONTINGENTssnOrTaxID',
        address1: 'CONTINGENTaddress1',
        address2: 'CONTINGENTaddress2',
        country: 'CONTINGENTcountry',
        state: 'CONTINGENTstate',
        province: 'CONTINGENTprovince',
        otherAddress: 'CONTINGENTotherAddress',
        city: 'CONTINGENTcity',
        postalCode: 'CONTINGENTpostalCode',
    },
};
export const BdmBeneficiaryUtils = {
    getTotalAllocationPercentage(beneficiaries, isToggleOn) {
        return isToggleOn
            ? 100
            : beneficiaries.map(b => b.allocation).reduce((prev, curr) => Number(prev) + Number(curr), 0);
    },
    generateBeneficiaryRows(beneficiaries, setBeneficiaries, toggleButtonState, updateBeneficiaries) {
        return beneficiaries.map((beneficiary, i) => {
            const onValueChange = (value) => {
                const allocation = value === '' ? '0' : value;
                const updatedBeneficiaries = beneficiaries.map((ben, j) => {
                    if (i === j) {
                        ben.allocation = allocation;
                    }
                    return ben;
                });
                setBeneficiaries(updatedBeneficiaries);
            };
            const handleDeleteBeneficiary = (index) => {
                const updatedBeneficiaries = [...beneficiaries];
                updatedBeneficiaries.splice(index, 1);
                setBeneficiaries(updatedBeneficiaries);
            };
            beneficiary.id = !!beneficiary.id ? beneficiary.id : uniqueId(beneficiary.beneficiaryCategory);
            beneficiary.allocation = beneficiary.allocation === null ? '0' : beneficiary.allocation;
            return (<BeneficiaryRow toggleButtonState={toggleButtonState} key={beneficiary.id} index={i} beneficiary={beneficiary} beneficiaries={beneficiaries} onValueChange={onValueChange} handleDeleteBeneficiary={handleDeleteBeneficiary} updateBeneficiaries={updateBeneficiaries}/>);
        });
    },
    formatName(name) {
        return name ? name.replace(/[^a-z-.' ]+/gi, '') : undefined;
    },
    formatGuardianName(name) {
        return name ? name.replace(/[^a-z-.' ]+/gi, '') : undefined;
    },
    filterOutUndefinedKeys(keysArray, values, sharedFields) {
        return keysArray
            .filter(key => !!key)
            .reduce((prev, currKey = '') => {
            return prev && !!values[currKey];
        }, sharedFields);
    },
    validateAllocationInForm(input) {
        if (typeof input === 'number') {
            return input;
        }
        else if (!input) {
            return 0;
        }
        return input.replace(/[^\d]/g, '');
    },
    validateBdmUSAPostalCodes(postalCode) {
        if (!postalCode) {
            return (<LanguageToggle en={validationDisplayTextEn.zipCodeRequiredUSA} es={validationDisplayTextEs.zipCodeRequired}/>);
        }
        else if (!postalCode.match('^(\\d{5}(-\\d{4})?)$')) {
            return (<LanguageToggle en={validationDisplayTextEn.invalidZipCodeUSA} es={validationDisplayTextEs.invalidZipCodeUSA}/>);
        }
        return undefined;
    },
    formatBdmUSAPostalCodes(postalCode) {
        postalCode = postalCode || '';
        return postalCode.replace(/[^\d-]/gi, '');
    },
    validateBdmCANPostalCodes(postalCode) {
        if (!postalCode) {
            return (<LanguageToggle en={validationDisplayTextEn.zipCodeRquiredCAN} es={validationDisplayTextEs.zipCodeRequired}/>);
        }
        else if (!postalCode.match('^([a-zA-Z]\\d[a-zA-Z]) *(\\d[a-zA-Z]\\d)$')) {
            return (<LanguageToggle en={validationDisplayTextEn.invalidZipCodeCAN} es={validationDisplayTextEs.invalidZipCodeCAN}/>);
        }
        return undefined;
    },
    formatBdmCANPostalCodes(postalCode) {
        postalCode = postalCode || '';
        return postalCode.replace(/[^A-Za-z\d ]/gi, '');
    },
    idBuilder(beneficiaryCategory, beneficiaryIndex) {
        if (beneficiaryIndex || beneficiaryIndex === 0) {
            return `${beneficiaryCategory}${beneficiaryIndex}`;
        }
        return beneficiaryCategory;
    },
    mapKeysForEditForm(values, idPrefix) {
        const mappedObject = {
            ...values,
            beneficiaryCategory: `${idPrefix}beneficiaryCategory`,
            beneficiaryType: `${idPrefix}beneficiaryType`,
            allocation: `${idPrefix}allocation`,
            firstName: `${idPrefix}firstName`,
            lastName: `${idPrefix}lastName`,
            middleInitial: `${idPrefix}middleInitial`,
            dateOfBirth: `${idPrefix}dateOfBirth`,
            guardianName: `${idPrefix}guardianName`,
            guardianInfo: `${idPrefix}guardianInfo`,
            relationship: `${idPrefix}relationship`,
            phoneNumber: `${idPrefix}phoneNumber`,
            emailAddress: `${idPrefix}emailAddress`,
            gender: `${idPrefix}gender`,
            charityName: `${idPrefix}charityName`,
            contactName: `${idPrefix}contactName`,
            trustName: `${idPrefix}trustName`,
            dateOfTrust: `${idPrefix}dateOfTrust`,
            ssnOrTaxID: `${idPrefix}ssnOrTaxID`,
            address1: `${idPrefix}address1`,
            address2: `${idPrefix}address2`,
            country: `${idPrefix}country`,
            state: `${idPrefix}state`,
            province: `${idPrefix}province`,
            otherAddress: `${idPrefix}otherAddress`,
            city: `${idPrefix}city`,
            postalCode: `${idPrefix}postalCode`,
        };
        return mappedObject;
    },
    formToBeneficiary(values, beneficiaryCategory, beneficiaryIndex, formType) {
        let keysFromForm = CATEGORY_KEYS[beneficiaryCategory];
        if (formType && formType === 'edit') {
            const idPrefix = this.idBuilder(beneficiaryCategory, beneficiaryIndex);
            keysFromForm = this.mapKeysForEditForm(keysFromForm, idPrefix);
        }
        const mapped = { ...keysFromForm };
        Object.entries(keysFromForm).map(([key, formKey]) => {
            values[formKey] !== undefined
                ? (mapped[key] = values[formKey])
                : delete mapped[key];
        });
        return mapped;
    },
    checkForFormCompletion(values, toggleButtonState, beneficiaryType, beneficiaryCategory, setFormFilled, isLci, beneficiaryIndex, formType) {
        let keys = CATEGORY_KEYS[beneficiaryCategory];
        if (formType && formType === 'edit') {
            const idPrefix = this.idBuilder(beneficiaryCategory, beneficiaryIndex);
            keys = this.mapKeysForEditForm(keys, idPrefix);
        }
        const sharedFieldKeys = [keys.address1, keys.country, keys.city];
        const sharedFieldLciKeys = [];
        // @ts-ignore
        if (values[keys.country] === 'USA') {
            sharedFieldKeys.push(keys.state, keys.postalCode);
        }
        // @ts-ignore
        if (values[keys.country] === 'CANADA') {
            sharedFieldKeys.push(keys.province, keys.postalCode);
        }
        // @ts-ignore
        if (values[keys.country] === 'OTHERCOUNTRIES') {
            sharedFieldKeys.push(keys.otherAddress);
        }
        if (!toggleButtonState) {
            sharedFieldKeys.push(keys.allocation);
            sharedFieldLciKeys.push(keys.allocation);
        }
        const checkRequiredFiledKeys = (obj) => {
            return obj
                .filter((key) => !!key)
                .reduce((prev, currKey = '') => {
                return prev && !!values[currKey];
            }, true);
        };
        const sharedFields = isLci
            ? checkRequiredFiledKeys(sharedFieldLciKeys)
            : checkRequiredFiledKeys(sharedFieldKeys);
        if (beneficiaryType === benPerson) {
            const personKeys = [keys.firstName, keys.lastName, keys.relationship];
            if (!isLci) {
                personKeys.push(keys.dateOfBirth);
            }
            setFormFilled(this.filterOutUndefinedKeys(personKeys, values, sharedFields));
        }
        if (beneficiaryType === benEstate) {
            const estateKeys = [keys.firstName, keys.lastName];
            setFormFilled(this.filterOutUndefinedKeys(estateKeys, values, sharedFields));
        }
        if (beneficiaryType === benCharity) {
            const charityKeys = [keys.charityName, keys.contactName];
            setFormFilled(this.filterOutUndefinedKeys(charityKeys, values, sharedFields));
        }
        if (beneficiaryType === benTrust) {
            const trustKeys = [keys.trustName, keys.dateOfTrust];
            setFormFilled(this.filterOutUndefinedKeys(trustKeys, values, sharedFields));
        }
    },
    checkIfMinor(dateString) {
        let isMinor = false;
        if (dateString) {
            const dateOfBirth = moment(new Date(dateString));
            if (dateString && dateString.length === 10 && dateOfBirth > moment(new Date('01/01/1900'))) {
                const now = moment(new Date());
                const beneficiaryAge = moment.duration(now.add(1, 'days').diff(dateOfBirth)).years();
                isMinor = beneficiaryAge < 18 ? true : false;
            }
        }
        return isMinor;
    },
    convertProvinceFieldName(input) {
        if (input.province) {
            input.state = input.province;
            delete input.province;
        }
        return input;
    },
    formatOtherAddress(input) {
        return input ? input.replace(/[^0-9a-z\-.'/,_#?@ \r|\n]+/gi, '') : undefined;
    },
    formatSigAndCity(input) {
        return input ? input.replace(/[^a-z\-.' ]+/gi, '') : undefined;
    },
    getCustomConfirmation(customContents, language) {
        if (language === Language.SPANISH) {
            return customContents.spanish;
        }
        return customContents.english;
    },
    getCustomContent(customContents, fieldTitle, language) {
        const customContent = customContents.filter(c => c.title == fieldTitle)[0];
        if (language === Language.SPANISH) {
            return customContent.value_ES;
        }
        return customContent.value_EN;
    },
    handleEditFormTypeChange: (benValuesAtTypeChange, newType, idPrefix) => {
        switch (newType) {
            case benPerson:
                benValuesAtTypeChange = {
                    ...benValuesAtTypeChange,
                    [`${idPrefix}firstName`]: undefined,
                    [`${idPrefix}lastName`]: undefined,
                    [`${idPrefix}dateOfBirth`]: undefined,
                    [`${idPrefix}relationship`]: undefined,
                };
                break;
            case benEstate:
                benValuesAtTypeChange = {
                    ...benValuesAtTypeChange,
                    [`${idPrefix}firstName`]: undefined,
                    [`${idPrefix}lastName`]: undefined,
                };
                break;
            case benCharity:
                benValuesAtTypeChange = {
                    ...benValuesAtTypeChange,
                    [`${idPrefix}charityName`]: undefined,
                    [`${idPrefix}contactName`]: undefined,
                };
                break;
            case benTrust:
                benValuesAtTypeChange = {
                    ...benValuesAtTypeChange,
                    [`${idPrefix}trustName`]: undefined,
                    [`${idPrefix}dateOfTrust`]: undefined,
                };
                break;
        }
        return benValuesAtTypeChange;
    },
    removeSecondsFromTime: (date, replaceComma, replaceWith = ' at') => {
        let formattedDate = date.substring(0, date.length - 6);
        formattedDate += date.substring(date.length - 3, date.length);
        if (replaceComma) {
            formattedDate = formattedDate.replace(',', replaceWith);
        }
        return formattedDate;
    },
    validateAllocationInModal(input) {
        if (!input) {
            return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
        }
        else if ((input && parseInt(input) > 100) || parseInt(input) < 1) {
            return (<LanguageToggle en={validationDisplayTextEn.invalidAllocation} es={validationDisplayTextEs.invalidAllocation}/>);
        }
        return undefined;
    },
    validateSSN(ssn) {
        if (!ssn) {
            return <LanguageToggle en={LanguageEn.fieldRequired} es={LanguageEs.fieldRequired}/>;
        }
        else if (!ssnPattern.test(ssn)) {
            return <LanguageToggle en={LanguageEn.invalidSSN} es={LanguageEs.invalidSSN}/>;
        }
        return undefined;
    },
};
export default BdmBeneficiaryUtils;
