import React from 'react';
import { Stepper, Loader } from '@digitools/honest-react';
import LoginBox from './components/login/LoginBox';
import TimeOff from './components/timeOff/TimeOff';
import VerifySubmission from './components/verifySubmission/VerifySubmission';
import { getEmployeeName, submit } from './api/USAAApi';
import ConfirmationScreen from './components/confirmationScreen/ConfirmationScreen';
import Notifications, { FunctionalArea } from '../../../components/Notifications/Notifications';
const initialTimeOffValues = {
    spansMidnight: false,
    timeOffType: '',
    reasonForTimeOff: '',
    existingLeave: false,
    date: '',
    startTime: '',
    endTime: ''
};
export class USAAMobileAppStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enteredBy: '',
            employeeName: '',
            personNotified: '',
            employeeId: '',
            transactionNumber: '',
            timeOffValues: initialTimeOffValues,
            employeeLookupStatus: 0,
            isLoading: false,
            resetStatus: false
        };
    }
    resetState = () => {
        this.setState({
            enteredBy: '',
            employeeName: '',
            employeeLookupStatus: 0,
            timeOffValues: initialTimeOffValues
        });
    };
    /* This is going to trigger component level callbacks that are resetForm hooks */
    triggerFormReset = async () => {
        this.setState({ resetStatus: true });
        await this.render();
        this.setState({ resetStatus: false });
    };
    submitUA = async () => {
        this.setState({ isLoading: true });
        try {
            const response = await submit(this.state.timeOffValues.timeOffType, this.state.timeOffValues.date, this.state.timeOffValues.spansMidnight, this.state.timeOffValues.startTime, this.state.timeOffValues.endTime, this.state.timeOffValues.normalStartTime, this.state.timeOffValues.estimatedArrival, this.state.timeOffValues.departureTime, this.state.timeOffValues.regularEndTime, this.state.timeOffValues.estimatedReturn, this.state.timeOffValues.durationHours, this.state.timeOffValues.durationMinutes, this.state.timeOffValues.reasonForTimeOff, this.state.timeOffValues.existingLeave, this.state.timeOffValues.leaveId, this.state.timeOffValues.immediateFamily, '', this.state.employeeId, this.state.enteredBy);
            this.setState({ isLoading: false, transactionNumber: response.data[0].transactionNumber });
            return response.status;
        }
        catch (error) {
            this.setState({ isLoading: false });
            return error.response.status;
        }
    };
    updateFormState = async (formData) => {
        this.setState({
            timeOffValues: formData
        });
    };
    // Seems to be used for debugging so commenting out
    // public getMaintenanceStatus = async () => {
    //   const response = await getMaintenanceStatus();
    //   console.log(response);    
    // }
    setEmployeeState = (values) => {
        this.setState({
            employeeId: values.employeeId,
            enteredBy: values.enteredBy
        });
    };
    getEmployee = async (empId, empBthDte) => {
        this.setState({ isLoading: true });
        try {
            const response = await getEmployeeName(empId, empBthDte);
            this.setState({ employeeName: response.data, employeeLookupStatus: response.status, isLoading: false, employeeId: empId });
            return response.status;
        }
        catch (error) {
            this.setState({ employeeLookupStatus: error.response.status, isLoading: false });
            return error.response.status;
        }
    };
    render() {
        return (<div data-public={true}>
        <Loader id='usaa-mobile-app-stepper-spinner' isShown={this.state.isLoading} language={'en'}>
        <Notifications functionalArea={FunctionalArea.USAA_MOBILE}></Notifications>
          {/* @ts-ignore */}
          <Stepper stepTitles={[]}>
            <LoginBox getEmployee={this.getEmployee} employeeLookupStatus={this.state.employeeLookupStatus} resetStatus={this.state.resetStatus} setEmployeeState={this.setEmployeeState}/>
            <TimeOff updateFormState={this.updateFormState} employeeName={this.state.employeeName} resetStatus={this.state.resetStatus} triggerFormReset={this.triggerFormReset}/>
            <VerifySubmission triggerFormReset={this.triggerFormReset} employeeName={this.state.employeeName} timeOffValues={this.state.timeOffValues} resetState={this.resetState} submitUA={this.submitUA}/>
            <ConfirmationScreen transactionNumber={this.state.transactionNumber} employeeName={this.state.employeeName} personNotified={this.state.personNotified} timeOffValues={this.state.timeOffValues} triggerFormReset={this.triggerFormReset} resetState={this.resetState}/>
          </Stepper>
        </Loader>
      </div>);
    }
}
export default USAAMobileAppStepper;
