import { useContext } from 'react';
import { ProveDataContext } from './ProveDataContext';
/**
 *  useProveData Hook
 *  accesses functions to access and edit data related to prove identity verification during the prove process on MLP
 */
function useProveData() {
    return useContext(ProveDataContext);
}
export default useProveData;
