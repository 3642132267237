import jwtDecode from 'jwt-decode';
import { getEnvVariable } from './envUtils';
const mlpVanityURL = getEnvVariable('vanityUrl');
export const getJtiUUID = () => {
    const accessToken = window.sessionStorage.getItem('mlpBearerToken');
    if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        //@ts-ignore
        return decodedToken[`${mlpVanityURL}jti`];
    }
    return null;
};
