import React, { useContext, useRef } from 'react';
import { StepperContext, Form } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import { AboutYouText } from './../../constants/AboutYouText';
import useAsDataContext from '../../hooks/useAsDataContext';
import StepperButtons from '../../components/StepperButtons';
import PersonalInformation from '../../components/PersonalInformation';
import ResidentialInformation from '../../components/ResidentialInformation';
import PreferredContactInformation from '../../components/PreferredContactInformation';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { StyledHeading } from '../../components/LayoutComponents';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { useNavigate } from 'react-router-dom';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const AboutYou = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { claimantData, setClaimantData } = useAsDataContext();
    const navigate = useNavigate();
    const { trackEvent } = useTealium();
    const pageTitleLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutYouText.aboutClaimantEE) : t(AboutYouText.aboutClaimantER));
    const pageSubTitleLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutYouText.pageSubTitle1EE) : t(AboutYouText.pageSubTitle1ER));
    const handleBackClick = () => {
        navigate(`/as/${experience.toLowerCase()}`);
    };
    const submit = (values) => {
        const mergedClaimantData = { ...claimantData, ...values.claimantData };
        mergedClaimantData.empPhoneNum = values?.claimantData?.empPhoneNum ? values?.claimantData?.empPhoneNum?.replace(/[^0-9.]/g, '') : claimantData?.empPhoneNum?.replace(/[^0-9.]/g, '');
        mergedClaimantData.uniqueID = mergedClaimantData.ssn ? 'ssn' : 'empID';
        { /* Need to clear out state/province values if user selects another country but CAN or US */ }
        if (values.claimantData.empCtryCde !== 'CAN' && values.claimantData.empCtryCde !== 'USA') {
            mergedClaimantData.empState = undefined;
        }
        { /* Need to clear out state/province values if user selects another country but CAN or US */ }
        if (values.claimantData.empCtryCde !== 'CAN' && values.claimantData.empCtryCde !== 'USA') {
            mergedClaimantData.empState = undefined;
        }
        mergedClaimantData.empLine1Addr = values?.claimantData?.empLine1Addr;
        mergedClaimantData.empLine2Addr = values?.claimantData?.empLine2Addr;
        mergedClaimantData.empCity = values?.claimantData?.empCity;
        mergedClaimantData.empZip = values?.claimantData?.empZip;
        setClaimantData(mergedClaimantData);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'save and continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            event_version: pageTitleLabel,
            event_status: 'step 2',
        });
        /* Send to the next step */
        stepper.next();
        window.scrollTo(0, 0);
    };
    const ref = useRef();
    return (<div ref={ref}>
      <StyledHeading elemType={'h2'} color={'primary'}>{pageTitleLabel}</StyledHeading>
      <p>
        {pageSubTitleLabel}<strong>{t(AboutYouText.pageSubTitle2)}</strong>
      </p>
      <Form onSubmit={submit} action='#'>
            <PersonalInformation />
            <PreferredContactInformation />
            <ResidentialInformation />
            <StepperButtons suppressNext analyticsPageName='about you' onClickBack={handleBackClick}/> 
      </Form>
    </div>);
};
export default AboutYou;
