import React from 'react';
import queryString from 'query-string';
import JwtDecode from 'jwt-decode';
import { AuthenticationContext } from './AuthenticationProvider';
import PageLoader from '../components/PageLoader/PageLoader';
import { withRouter } from 'src/utils/withRouter';
export class MfaProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: undefined,
        };
    }
    async componentDidMount() {
        const { register } = this.props.authenticationContext;
        const login = async () => { };
        const logout = async () => { };
        register({
            init: this.init,
            getUserContext: this.getUserContext,
            getIsAuthenticated: this.getIsAuthenticated,
            login,
            logout,
            getAuthHeader: this.getAuthHeader,
            refreshToken: this.refreshToken,
            getAuth0State: this.getAuth0State,
        });
    }
    async componentDidUpdate(prevProps, prevState) {
        const login = async () => { };
        const logout = async () => { };
        const { register } = this.props.authenticationContext;
        if (prevState.token !== this.state.token) {
            register({
                init: async () => { },
                getUserContext: this.getUserContext,
                getIsAuthenticated: this.getIsAuthenticated,
                login,
                logout,
                getAuthHeader: this.getAuthHeader,
                refreshToken: this.refreshToken,
                getAuth0State: this.getAuth0State,
            });
        }
    }
    getAuthHeader = async () => {
        return `Bearer ${this.state.token}`;
    };
    getIsAuthenticated = async () => {
        return false;
    };
    init = async () => {
        const { token } = this.getParamsFromUrl();
        sessionStorage.setItem('mlpBearerToken', token);
        this.setState({
            token,
        });
    };
    getUserContext = async () => {
        const token = this.state.token ? this.state.token : '';
        const user = {
            ...JwtDecode(token),
        };
        return user;
    };
    refreshToken = async (token) => {
        sessionStorage.setItem('mlpBearerToken', token);
        await this.setState({
            token,
        });
    };
    getAuth0State = async () => {
        const { state } = this.getParamsFromUrl();
        return state;
    };
    getParamsFromUrl = () => {
        const values = queryString.parseUrl(window.location.href);
        const token = values.query.token;
        const state = values.query.state;
        return { token, state };
    };
    render() {
        const { authenticationContext, children } = this.props;
        return authenticationContext.loading ? <PageLoader id='mfa-provider-spinner'/> : children;
    }
}
export default withRouter((props) => (<AuthenticationContext.Consumer>
    {(context) => <MfaProvider {...props} authenticationContext={context}/>}
  </AuthenticationContext.Consumer>));
