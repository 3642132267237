import React from 'react';
import { encode } from '../../status/utilities/hash';
import { Link } from 'react-router-dom';
import { getFormattedEmpName } from '../api';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const EmployeeOverviewLink = ({ empId, employee, }) => {
    const { trackEvent } = useTealium();
    const urlId = encode(empId, true);
    const employeeOverviewClick = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYEE_OVERVIEW,
            event_name: 'Employee Overview'
        });
    };
    return (<>
      <Link to={`/status/employer/${urlId}/coverageDetail?from=teamView`} data-testid={`employee-overview`} style={{ alignItems: 'right', justifyContent: 'flex-end' }} onClick={employeeOverviewClick} aria-label={`Press enter to view ${getFormattedEmpName(employee)}'s employee overview`}>
        Employee overview
      </Link>
    </>);
};
export default EmployeeOverviewLink;
