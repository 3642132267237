export const VerifyEmailDisplayTextEn = {
    alertStaticText: 'Email verification required!',
    bodySection1: `Please click the link in the email that was sent to you to continue to My Lincoln Portal.`,
    bodySection2: `The email may take up to 24 hours for delivery. Please check your spam or junk folder for an email from `,
    title: 'Almost There',
    //
    // Enrollment
    //
    enrollmentTitle: 'Almost There',
    emailSentSuccess: 'Verification Email Sent!',
    enrollmentSuccessBody1: 'A verification link has been sent to the email associated with this account. You will not be able to login until you verify your email.',
    enrollmentSuccessBody2: 'This link will expire in 5 days.',
};
