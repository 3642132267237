import { Button, Modal, StepperContext, themes } from '@digitools/honest-react';
import React, { useContext } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import styled from 'styled-components';
import { SaveForLaterText } from '../../eoi/i18n/SaveForLaterText';
import { useNavigate } from 'react-router-dom';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useCliDataContext from '../hooks/useCliDataContext';
import { AboutYouText } from '../constants/AboutYouText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { getEventStatusByCurrentStep } from '../utils/eventStatusStepUtil';
const StyledCancelButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
    margin-left: 0rem !important;
  }
`;
const CancelApplicationButton = ({ analyticsPageName }) => {
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const { resetCliDataContext, setIsSflModalOpen } = useCliDataContext();
    const navigate = useNavigate();
    const stepper = useContext(StepperContext);
    const aboutYouPageTitleER = CustomEmployeeText(t(AboutYouText.pageTitleER));
    const ReturnToApplicationButton = ({ hideModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(false);
            hideModal();
        };
        return (<Button type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-return-button'}>
        {t(SaveForLaterText.returnToApplication)}
      </Button>);
    };
    // Cancel on ER only, Cancel Modal wraps this button
    const CancelButton = ({ showModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(true);
            showModal();
        };
        return (<StyledCancelButton type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-cancel-button'}>
        {t(SaveForLaterText.cancelApplication)}
      </StyledCancelButton>);
    };
    const CancelApplicationButton = () => {
        const handleOnClick = async () => {
            setIsSflModalOpen(false);
            resetCliDataContext();
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'cancel application',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t, aboutYouPageTitleER),
            });
            navigate(0);
        };
        return (<Button data-testid='sfl-cancel-application' type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleOnClick}>
        {t(SaveForLaterText.cancelApplication)}
      </Button>);
    };
    return (<Modal headerText={t(SaveForLaterText.cancelApplication)} modalOpenRenderButton={CancelButton} footerPrimaryRenderButton={CancelApplicationButton} footerSecondaryRenderButton={ReturnToApplicationButton} footerContentPosition={'flex-start'} theme={themes.lincoln} data-testid={'sfl-cancel-modal'} aria-label={'Cancel Application Modal'}>
      <p>
        <strong>{t(SaveForLaterText.cancelApplicatinModalText)}</strong>
      </p>
    </Modal>);
};
export default CancelApplicationButton;
