import React from 'react';
import { Alert, Spinner } from '@digitools/honest-react';
import useGet from '../../../../../hooks/useGet';
import AccommodationTable from './AccommodationTable';

const Accommodation = ({ empNum, fromTeamView }) => {
  const params = {
    empNum,
    fromTeamView,
  };

  const {
    error: accommodationError,
    loading: accommodationLoading,
    data: accommodationData,
  } = useGet({
    url: '/status-service/accommodations',
    options: {
      params,
    },
    handleUnauthorized: true,
  });

  if (accommodationLoading) {
    return (
      <div data-testid={'accomodation-loading'}>
        <Spinner id='accomodation-spinner' size='small' center={true} />
      </div>
    );
  }

  if (accommodationError) {
    return (
      <Alert type={'error'} closeable={true}>
        <span>
          <strong>Error!</strong>&nbsp;Something went wrong when retrieving accommodation.
        </span>
      </Alert>
    );
  }

  if (!accommodationData || accommodationData.length === 0) {
    return <Alert type={'info'}>There is no accommodation data to display at this time</Alert>;
  }

  return (
    <AccommodationTable
      accommodations={accommodationData}
      accommodationLoading={accommodationLoading}
      accommodationError={accommodationError}
      empNum={empNum}
      fromTeamView={fromTeamView}
    />
  );
};

export default Accommodation;
