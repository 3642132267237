import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import CommPrefDisplayText from './language/CommPrefDisplayText';
import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
const DeliveryMethodRadioButtons = (props) => {
    const { t } = useTranslate();
    const validateDeliveryMethod = (values) => {
        if (props.toggleOn) {
            if (values === undefined) {
                return t({ english: 'This field is required.', spanish: 'Este campo es obligatorio.' });
            }
        }
        return undefined;
    };
    return (props.toggleOn ?
        <RadioGroup label={'delivery method'} hideLabel={true} field={'deliveryMethod'} theme={themes.lincoln} initialValue={props.toggleOn && props.deliveryMethod ? (props.deliveryMethod === 'TXT' ? 'textNotification' : 'phoneCall') : undefined} validate={validateDeliveryMethod} validateOnBlur={true} validateOnChange={true}>
        <Radio name={'deliveryMethod'} id={'textNotification'} label={t(CommPrefDisplayText.textNotification)} aria-label={t(CommPrefDisplayText.textNotification)} value={'textNotification'} theme={themes.lincoln} disabled={!props.toggleOn} containerStyle={{ marginBottom: '0.5rem' }} data-testid={'delivery-method-text-notification'}/>
        <Radio name={'deliveryMethod'} id={'phoneCall'} label={t(CommPrefDisplayText.phoneCall)} aria-label={t(CommPrefDisplayText.phoneCall)} value={'phoneCall'} theme={themes.lincoln} disabled={!props.toggleOn} containerStyle={{ marginBottom: '0.5rem' }} data-testid={'delivery-method-phone-call'}/>
      </RadioGroup>
        : <>
          <span style={{ color: themes.lincoln.palette.gray }}>{t(CommPrefDisplayText.textNotification)}</span>
          <span style={{ color: themes.lincoln.palette.gray }}>{t(CommPrefDisplayText.phoneCall)}</span>
        </>);
};
export default DeliveryMethodRadioButtons;
