import React from 'react';
import { ExperienceContext } from '../context';
import useExperience from 'src/hooks/useExperience';

// Convert our experience code to the experience used by the main app
// TODO remove during final status app cleanup ( not big deal tho )
const portalExp2StatusExp = exp => {
  switch (exp) {
    case 'Employer':
      return 'ER';
    case 'Employee':
      return 'EE';
    default:
      return 'Public';
  }
};

const statusExp2PortalExp = exp => {
  switch (exp) {
    case 'ER':
      return 'Employer';
    case 'EE':
      return 'Employee';
    default:
      return 'Public';
  }
};

const ExperienceProvider = ({ children }) => {
  const { setExperience: setPortalExperience, experience, getExperience: getPortalExperience } = useExperience();

  // TODO need to map status exp to portal exp ( will update but not big deal for now )
  const setExperience = exp => {
    setPortalExperience(statusExp2PortalExp(exp));
  };

  const getExperience = () => {
    return portalExp2StatusExp(getPortalExperience());
  };

  return (
    <ExperienceContext.Provider
      value={{
        experience: portalExp2StatusExp(experience),
        setExperience,
        getExperience,
      }}>
      {children}
    </ExperienceContext.Provider>
  );
};

export default ExperienceProvider;
