import React from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router-dom';
import Intake from '../pages/intake/intake';
import CliConfirmation from '../pages/confirmation/Confirmation';
import CliConfirmationNew from '../pages/confirmation/ConfirmationNew';
const CliRouter = () => {
    return (<Routes>
      <Route path={`*`} element={<Outlet />}>
        <Route path={`confirmation`} element={<CliConfirmation />}/>
        {/* Experience routes only needed on confirmation for referrer header to work with api */}
        <Route path={`employee/confirmationNew`} element={<CliConfirmationNew />}/>
        <Route path={`employer/confirmationNew`} element={<CliConfirmationNew />}/>
        {/* Experience routes to derive proper experience when directly routing to these pages */}
        <Route path={`employer/intake/*`} element={<Intake />}/>
        <Route path={`employee/intake/*`} element={<Intake />}/>
        <Route path={`employee`} element={<Navigate to={`/cli/employee/intake`} replace/>}/>
        <Route path={`employer`} element={<Navigate to={`/cli/employer/intake`} replace/>}/>
      </Route>
    </Routes>);
};
export default CliRouter;
