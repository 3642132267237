import React from 'react';
import { Row, Col } from '@digitools/honest-react';
// @ts-ignore
import styles from './AccommodationDetails.scss';
const RequestExtendedDetailsRow = ({ header, value, withBackground }) => {
    return (<div className={styles.detailsRowContent}>
      <Row className={withBackground ? styles.detailsRowContentWithBackground : styles.detailsRowContent}>
        <Col xs={12} sm={6} md={4} lg={4}>
          <dt className={styles.dt}>{header}</dt>
        </Col>
        <Col xs={12} sm={6} md={8} lg={8}>
          <dd>{value}</dd>
        </Col>
      </Row>
    </div>);
};
export default RequestExtendedDetailsRow;
