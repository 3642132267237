import { Col, Row, TabContainer, TabSection } from '@digitools/honest-react';
import { DeductibleAndCoinsuranceInfo } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import { CoveredServicesInfo } from 'src/packages/dental/pages/CoveredServices';
import { ClaimsInfo } from 'src/packages/dental/pages/Claims';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { encode } from 'status/utilities/hash';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import DeductibleImg from '../../../assets/Deductible&Coinsurance.svg';
import CoveredServicesImg from '../../../assets/CoveredServices.svg';
import ClaimsImg from '../../../assets/Claims.svg';
import styled from 'styled-components';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
const OuterDiv = styled.button `
  width: 100%;
  height: 120%;
  background: white;
  box-shadow: 4px 4px 12px rgba(171.57, 171.57, 171.57, 0.15);
  border-radius: 4px;
  border: ${({ theme }) => `.5px solid ${theme.palette.grayLighter}`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5rem;
`;
const MediumDiv = styled.div `
  position: relative;
`;
const InnerDiv = styled.div `
  justify-content: left;
`;
const TextDiv = styled.div `
  //text-align: center;
  color: #244f8b;
  font-size: 15px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 5px;
`;
const TabContent = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    const { trackEvent, trackView } = useTealium();
    let memberEvent = 'subscriber';
    if (currentMember && subscriber?.dependents?.indexOf(currentMember) !== -1) {
        memberEvent = 'member' + (subscriber?.dependents?.indexOf(currentMember) + 1);
    }
    const trackDropdownEvent = (button) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: button,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: memberEvent,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            user_role: 'employee',
        });
    };
    const BenefitButton = (props) => {
        return (<Col>
        <OuterDiv data-testid={`benefitButton-${props.url}`} onClick={() => {
                navigate(`/employee/dental/members/${encode(currentMember?.id)}/${props.url}`);
                trackDropdownEvent(props.event);
            }}>
          <MediumDiv>
            <InnerDiv>{props.image}</InnerDiv>
          </MediumDiv>
          <TextDiv>{props.title}</TextDiv>
        </OuterDiv>
      </Col>);
    };
    return (<>
      {isMobile ? (<>
          <Row className={'mt-3'}>
            <Col>
              <p>{t(DentalText.disclaimerText)}</p>
            </Col>
          </Row>
          <Row>
            <BenefitButton event={'covered services'} url={'coveredServices'} title={t(DentalText.coveredServicesText)} image={<CoveredServicesImg />}/>
            <BenefitButton event={'deductibles and coinsurance'} url={'deductibles'} title={t(DentalText.deductibleText)} image={<DeductibleImg />}/>
            <BenefitButton event={'claims'} url={'claims'} title={t(DentalText.claimsText)} image={<ClaimsImg />}/>
          </Row>
        </>) : (<div style={{
                paddingTop: '27px',
                textAlign: 'left',
            }} className='mb-4'>
          <p>{t(DentalText.disclaimerText)}</p>
          <TabContainer>
            <TabSection name={t(DentalText.coveredServicesText)} order={1}>
              <CoveredServicesInfo />
            </TabSection>
            <TabSection name={t(DentalText.deductibleText)} order={2}>
              <DeductibleAndCoinsuranceInfo />
            </TabSection>
            <TabSection name={t(DentalText.claimsText)} order={3}>
              <ClaimsInfo />
            </TabSection>
          </TabContainer>
        </div>)}
    </>);
};
export default TabContent;
