import { themes } from '@digitools/honest-react';
import React from 'react';
import styled, { withTheme } from 'styled-components';
const ColorDiv = styled.div `
  background-color: ${({ theme, backgroundColor }) => backgroundColor ? theme.palette[backgroundColor] : theme.palette.grayDarker};
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;
  display: inline-block;
`;
const ColorButton = styled.button `
  background-color: ${({ theme, backgroundColor }) => backgroundColor ? theme.palette[backgroundColor] : theme.palette.grayDarker};
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;
  display: inline-block;
  border: none !important;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 2px 6px ${({ theme }) => theme.palette.secondaryLight};
  }
`;
export const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
        case 'Pended':
        case 'Pending':
        case 'Pendiente':
        case 'Open':
        case 'Abierto':
        case 'In Progress':
        case 'En Progreso':
            return 'accent2Light';
        case 'Approved':
        case 'Aprobado':
            return 'success';
        case 'Closed':
        case 'Cerrado':
            return 'gray';
        case 'Denied':
        case 'Rechazado':
            return 'black';
        default:
            return 'gray';
    }
};
const StatusBadge = ({ status, backgroundColor, children, theme = themes.secureSite, ...rest }) => {
    return (<ColorDiv data-testid='statusBadge' backgroundColor={backgroundColor ? backgroundColor : status && getStatusColor(status)} theme={theme} {...rest}>
      {children}
    </ColorDiv>);
};
export const BadgeButton = ({ backgroundColor, children, theme = themes.secureSite, ...rest }) => {
    return (<ColorButton backgroundColor={backgroundColor} theme={theme} {...rest}>
      {children}
    </ColorButton>);
};
export default withTheme(StatusBadge);
