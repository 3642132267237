import axios from 'axios';
const api = '/bdm-service/services/v1/bdm-verification';
export const findMyInformation = (authHeader, username, values) => {
    const requestBody = {
        ...values,
        loginId: username,
    };
    return axios.post(api, requestBody, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
};
