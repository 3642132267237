import moment from 'moment';
function isCustomerUSAA() {
    const hostname = window.location.href;
    return hostname.indexOf('usaa') > -1;
}
const customer = {
    properties: {
        DEV: {
            customerId: 'd59ae2b0-51f9-49d9-8a8a-dccb55564c2d',
            customerInternalName: 'USAA 2',
        },
        NON_PROD: {
            customerId: 'af1b27a0-111e-495f-9f18-1765fa5f6a64',
            customerInternalName: 'USAA',
        },
        PROD: {
            customerId: '1fb075ae-df24-4c02-834c-d42ca0c474f1',
            customerInternalName: 'USAA',
        },
    },
};
export function isTeamViewReadOnly(user) {
    let teamViewReadOnly = false;
    if (user.customerInContext !== undefined && user.applicationPermissions !== undefined) {
        teamViewReadOnly = user.applicationPermissions.indexOf('teamView.readOnly') > -1;
    }
    return teamViewReadOnly;
}
export function isTeamView(user) {
    let teamView = false;
    if (user.customerInContext !== undefined && user.applicationPermissions !== undefined) {
        teamView = user.applicationPermissions.indexOf('teamView') > -1;
    }
    return teamView;
}
export function isUSAA(customerId) {
    const nonProdUSSACustomerId = customer.properties['NON_PROD'].customerId;
    const prodUSSACustomerId = customer.properties['PROD'].customerId;
    return nonProdUSSACustomerId === customerId || prodUSSACustomerId === customerId;
}
function convert(input) {
    return moment(input, 'HH:mm').format('h:mm A');
}
const exportFunctions = {
    isCustomerUSAA,
    convert,
};
export default exportFunctions;
