import { handleProductAllSelection, checkBoxSelection } from '../api';
import { SelectAllCheckbox, CheckboxGroup, Collapse, Col, Row } from '@digitools/honest-react';
import React from 'react';
const Products = (props) => {
    return (<>
      <div style={{ marginTop: '1.5rem' }}>
        <Collapse type='box' isOpen={true} title='Products' id='productCollapse' aria-labelledby='productCollapse' data-testid={'productCollapse'}>
          {props.products.length > 0 ? (<CheckboxGroup field={'productGroup'} label={'Products'} validateOnChange={true}>
              <Row>
                <Col lg={4}>
                  <SelectAllCheckbox field={'productAll'} onChange={(event) => handleProductAllSelection({ code: 'productAll', label: 'All', checked: event.currentTarget.checked }, props.products, 'products', props.userInfo, props.customerConfig)}/>
                </Col>
              </Row>
              <Row style={{ marginTop: '-0.05rem' }}>{checkBoxSelection(props.products, 'products', true, props.userInfo, props.customerConfig)}</Row>
            </CheckboxGroup>) : (<></>)}
        </Collapse>
      </div>
    </>);
};
export default Products;
