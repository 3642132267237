import Button from '@digitools/honest-react/dist/lib/components/Button';
import { Modal } from '@digitools/honest-react/dist/lib/components/Modal';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import React from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import useTranslate from '../../../hooks/useTranslate';
import { DeleteInterviewData } from '../api/EoiApiCalls';
import { ApplicantCoverageText } from '../i18n/ApplicantCoverageText';
import { SaveForLaterText } from '../i18n/SaveForLaterText';
const DuplicateApplicationModal = (props) => {
    const { t } = useTranslate();
    const { authHeader, user } = useAuthentication();
    const hasEoiStatusEnabled = user && user.applicationPermissions && user.applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
    const ExitApplicationButton = () => {
        return (<Button type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={async () => {
                await DeleteInterviewData(authHeader());
                window.location.assign('/customer/employee/home');
            }}>
        {t(SaveForLaterText.exitApplication)}
      </Button>);
    };
    return (<Modal theme={themes.lincoln} disabledBackdropClick={true} footerPrimaryRenderButton={ExitApplicationButton} getControl={props.setControl} footerContentPosition={'flex-start'} headerText={t(ApplicantCoverageText.duplicateApplication)} modalContainerStyle={{ minWidth: '50%' }}>
      {hasEoiStatusEnabled
            ? t(ApplicantCoverageText.duplicateApplicationModalTextEoiStatus)
            : t(ApplicantCoverageText.duplicateApplicationModalText)}
    </Modal>);
};
export default DuplicateApplicationModal;
