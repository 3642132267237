import React, { createContext, useEffect, useState } from 'react';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import { getCliSavedInterview, SaveInterviewData, UpdateInterviewData } from '../api/CliApiCalls';
import useCliCustomerConfigContext from '../hooks/useCliContext';
export const CliDataContext = createContext({
    claimantData: undefined,
    additionalDetails: undefined,
    isInterviewLoading: undefined,
    absenceData: undefined,
    savedInterview: undefined,
    contactData: undefined,
    paymentMethodData: undefined,
    validationResults: undefined,
    claimActive: undefined,
    leaveActive: undefined,
    isSflModalOpen: undefined,
    isDirectDepositSuccess: undefined,
    hasEligibilityEmpState: undefined,
    resetCliDataContext: () => { },
    documents: [],
    uploadedFiles: [],
    setUploadedFiles: () => { },
    isLoading: false,
    isPrePopLoading: false,
    setValidationResults: () => { },
    setContactData: () => { },
    setPaymentMethodData: () => { },
    setAdditionalDetails: () => { },
    setClaimantData: () => { },
    setAbsenceData: () => { },
    setIsInterviewLoading: () => { },
    setClaimActive: () => { },
    setLeaveActive: () => { },
    saveAndUpdateInterview: () => { },
    setIsLoading: () => { },
    setIsPrePopLoading: () => { },
    setDocuments: () => { },
    submitErrors: false,
    setSubmitErrors: () => { },
    setIsSflModalOpen: () => { },
    setIsDirectDepositSuccess: () => { },
    setHasEligibilityEmpState: () => { },
});
/**
 * Provides CLI Interview data & functions to set this data to all children
 */
export const CliDataProvider = ({ children, }) => {
    const [additionalDetails, setAdditionalDetails] = useState();
    const [claimantData, setClaimantData] = useState();
    const [absenceData, setAbsenceData] = useState();
    const [contactData, setContactData] = useState();
    const [paymentMethodData, setPaymentMethodData] = useState();
    const [isInterviewLoading, setIsInterviewLoading] = useState(false); //TODO: FIXME: vague loading states - we should consolidate and name properly
    const [validationResults, setValidationResults] = useState();
    const [claimActive, setClaimActive] = useState(false);
    const [leaveActive, setLeaveActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false); //TODO: FIXME: vague loading states - we should consolidate and name properly
    const [isPrePopLoading, setIsPrePopLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [submitErrors, setSubmitErrors] = useState(false);
    const [isSflModalOpen, setIsSflModalOpen] = useState(false);
    const [isDirectDepositSuccess, setIsDirectDepositSuccess] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [hasEligibilityEmpState, setHasEligibilityEmpState] = useState(false);
    const { experienceCode, language } = useExperience();
    const { authHeader, user } = useAuthentication();
    const { savedCliInterview, isClaimCustomer, isClaimLeaveCustomer, isLeaveCustomer, hasSaveForLater, saveForLaterEntries, setSaveForLaterEntries, } = useCliCustomerConfigContext();
    const interviewID = savedCliInterview && savedCliInterview[0] && savedCliInterview[0]?.rowID;
    const { loading: cliSavedInterviewLoading, data: cliSavedInterviewData, error: cliSavedInterviewError, get: triggerGetCliSavedInterview, } = getCliSavedInterview(interviewID);
    useEffect(() => {
        if (interviewID) {
            triggerGetCliSavedInterview();
        }
    }, [interviewID]);
    useEffect(() => {
        if (isClaimLeaveCustomer) {
            setClaimActive(true);
            setLeaveActive(true);
        }
        isClaimCustomer && setClaimActive(true);
        isLeaveCustomer && setLeaveActive(true);
    }, [isClaimCustomer, isClaimLeaveCustomer, isLeaveCustomer]);
    const resetCliDataContext = () => {
        setAdditionalDetails({});
        setClaimantData({});
        setAbsenceData({});
        setContactData({});
        setPaymentMethodData({});
        setClaimActive(false);
        setLeaveActive(false);
        setSaveForLaterEntries({});
        setIsDirectDepositSuccess(false);
        setHasEligibilityEmpState(false);
        setDocuments([]);
        setUploadedFiles([]);
    };
    const parsedSavedInterview = cliSavedInterviewData && cliSavedInterviewData.savedData && JSON.parse(cliSavedInterviewData.savedData);
    useEffect(() => {
        setClaimantData({ ...claimantData, ...parsedSavedInterview?.claimantData });
        setAbsenceData({ ...absenceData, ...parsedSavedInterview?.absenceData });
        setAdditionalDetails({ ...additionalDetails, ...parsedSavedInterview?.additionalDetails });
        setContactData({ ...contactData, ...parsedSavedInterview?.contactData });
        setPaymentMethodData({ ...paymentMethodData, ...parsedSavedInterview?.paymentMethodData });
    }, [cliSavedInterviewData]);
    /* AboutYou - Dev & Demo Shortcut - prefills test code to skip About You page (Leave in for Demo day to speed things up) */
    // useEffect(() => {
    //     setClaimantData({
    //         firstName: 'first',
    //         empID: 'n0171778',
    //         middleName: 'M',
    //         lastName: 'last',
    //         dob: '06/06/1991',
    //         gender: 'M',
    //         selectedCountryID: 'USA',
    //         selectedStateID: 'NH',
    //         residentialAdd1: '123 Add 1',
    //         residentialAdd2: '456 Add 2',
    //         residentialCity: 'City',
    //         postalCode: '98765',
    //         selectedpreferredmethod: 'F',
    //         email: 'test@email.com',
    //         claimantpreferredfax: '1234569999',
    //         phone: '0001112222',
    //         selectedEmpCountryID: 'USA',
    //         selectedEmpStateID: 'NH',
    //         uniqueID: 'empID'
    //     });
    // window.location.pathname.includes('employer') && setAdditionalDetails({
    //     countryOfEmployment: 'USA',
    //     stateOfEmployment: 'CA',
    //     provinceOfEmployment: 'Test Province',
    //     dateOfHire: '01/21/2010',
    //     workState: 'NH',
    //     subsidiary: 'TC',
    //     locationBranch: 'West Building',
    //     employeeType: 'F',
    //     jobReqDesc: 'This is a test description.',
    //     physicalDemands: 'M',
    //     payType: 'VA',
    //     earnings: '100000.10',
    //     incomeClass: 'EC',
    //     benefitPercent: '39',
    //     EEPremCon: '49',
    //     ERPremCon: '51',
    //     daysperweek: '4',
    //     hoursperday: '8',
    //     workstatus: 'A',
    //     monday: true,
    //     wednesday: true,
    //     friday: true,
    // });
    // }, []);
    /* ----- */
    // TODO: Update to return an await in component call
    // to get cleaner loader. (load before stepper.next())
    const saveAndUpdateInterview = async (values) => {
        const savedData = {
            claimantData: { ...claimantData, ...values?.claimantData },
            absenceData: { ...absenceData, ...values?.absenceData },
            contactData: { ...contactData, ...values?.contactData },
        };
        setIsLoading(true);
        if (hasSaveForLater) {
            if (!saveForLaterEntries?.rowID) {
                try {
                    SaveInterviewData({
                        savedData,
                        language,
                        title: experienceCode,
                        // @ts-ignore
                        preferredEmailAddress: user?.emailAddress,
                    }, authHeader()).then((response) => {
                        setSaveForLaterEntries({
                            rowID: response?.data?.rowID,
                            updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                        });
                    });
                }
                catch (error) {
                    console.error('Error sending data to endpoint', error);
                }
            }
            else {
                try {
                    UpdateInterviewData({
                        savedData,
                        language,
                        title: experienceCode,
                        rowID: saveForLaterEntries?.rowID,
                        //@ts-ignore
                        preferredEmailAddress: user?.emailAddress,
                    }, authHeader()).then((response) => {
                        setSaveForLaterEntries((prevState) => {
                            return {
                                ...prevState,
                                updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                            };
                        });
                    });
                }
                catch (error) {
                    console.error('Error sending data to endpoint', error);
                }
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    };
    //TODO: for the CliDataContext object, we'll want to either
    // A make this as simple as possible to assign/read per form by having it contain our field/form values objects (like claimantInfo)
    // B make this an exact copy of what the submit object needs to be for our backend (could require more specific data mapping work in each form's submit)
    // ideally - it's both, and the BE can handle how to submit this into S1 properly by changing the data around - but TBD
    const cliDataContext = {
        // TODO: Is it possible to set the parsedSavedInterview values using the
        // set claimantData and absenceData methods above in a useEffect()?
        claimantData,
        absenceData,
        additionalDetails,
        contactData,
        paymentMethodData,
        isInterviewLoading: cliSavedInterviewLoading || isInterviewLoading,
        validationResults,
        claimActive,
        leaveActive,
        documents,
        isSflModalOpen,
        resetCliDataContext,
        setValidationResults,
        setContactData,
        setPaymentMethodData,
        setAdditionalDetails,
        setClaimantData,
        setAbsenceData,
        setDocuments,
        setIsInterviewLoading,
        setClaimActive,
        setLeaveActive,
        saveAndUpdateInterview,
        isLoading,
        isPrePopLoading,
        setIsLoading,
        setIsPrePopLoading,
        submitErrors,
        setSubmitErrors,
        setIsSflModalOpen,
        isDirectDepositSuccess,
        setIsDirectDepositSuccess,
        setUploadedFiles,
        uploadedFiles,
        hasEligibilityEmpState,
        setHasEligibilityEmpState,
    };
    return <CliDataContext.Provider value={cliDataContext}>{children}</CliDataContext.Provider>;
};
