import React from 'react';
import { Button, Icon, themes, Box } from "@digitools/honest-react";
import useAuthentication from "src/hooks/useAuthentication";
import useTranslate from "src/hooks/useTranslate";
import styled from "styled-components";
import { downloadCliConfirmationPdf } from "../../api/CliApiCalls";
const ViewAndPrintBox = styled(Box) `
  margin-bottom: 1rem;
  margin-top: 1.875rem;
  width: 425px;
  background-color: ${themes.lincoln.palette.grayLightest};
  margin-left: 1rem;
  border: none;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
`;
const DownloadAndPrint = (props) => {
    const { setErrorMessage, cliContextClone } = props;
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    return (
    // <ViewAndPrintBox data-testid={'download-and-print'}>
    <Button theme={themes.lincoln} type={'button'} onClick={() => {
            //TODO: analytics call
            downloadCliConfirmationPdf(authHeader(), cliContextClone, setErrorMessage, t({ english: 'There was an error downloading your confirmation', spanish: 'Hubo un error al descargar su confirmación' }));
        }} data-testid={'download-button'}>
      <Icon name={'pdf'} size='medium' color={'secondary'} style={{ marginRight: '0.5rem' }}/>
      {t({ english: 'View and print your submission', spanish: 'Ver e imprimir su envío' })}
    </Button>
    // </ViewAndPrintBox>
    );
};
export default DownloadAndPrint;
