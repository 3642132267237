export const VerificationText = {
    verificationHeadingEE: {
        english: 'To better assist you with your request, please confirm who you are.',
        spanish: 'Para ayudarlo mejor con su solicitud, confirme quién es usted.',
    },
    verificationHeadingER: {
        english: 'First, we need to establish your Employee\'s identity',
        spanish: 'First, we need to establish your Employee\'s identity',
    },
    verificationSubHeading: {
        english: 'Given this information, we\'ll check our systems and prepopulate as much information as we can for you.',
        spanish: 'Una vez que tengamos esta información, comprobaremos nuestros sistemas y completaremos toda la información que podamos por usted.',
    },
    employeeIdLabel: {
        english: 'Employee ID',
        spanish: 'ID de empleado',
    },
    ssnRadioLabel: {
        english: 'Social security number',
        spanish: 'Número de Seguro Social',
    },
    ssnLabel: {
        english: 'SSN (xxx-xx-xxxx)',
        spanish: 'SSN (xxx-xx-xxxx)',
    },
    lastNameLabel: {
        english: 'Last name',
        spanish: 'Apellido',
    },
    verifyButton: {
        english: 'CONTINUE',
        spanish: 'CONTINUAR',
    },
    verifyRequiredError: {
        english: 'This field is required',
        spanish: 'Este campo es requerido',
    },
    invalidEmpId: {
        english: 'Invalid Employee ID',
        spanish: 'ID de empleado no válido',
    },
    invalidSsn: {
        english: 'Invalid Social security number',
        spanish: 'Número de seguridad social no válido',
    },
    verifyRadioButtonLegend: {
        english: 'Choose to display Social security number or Employee ID text fields.',
        spanish: 'Elija mostrar los campos de texto Número de seguro social o ID de empleado.',
    },
    invalidUserEmail: {
        english: 'Please update the preferred email address on your My Lincoln Portal Profile to proceed. Allowed characters are: A-Z a-z . - ',
        spanish: 'Actualice la dirección de correo electrónico preferida en su perfil de My Lincoln Portal para continuar. Los caracteres permitidos son: A-Z a-z . - '
    }
};
