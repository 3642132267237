import { Icon, themes } from '@digitools/honest-react';
import { format, startOfTomorrow } from 'date-fns';
import React from 'react';
import { SaveInterviewData } from '../api/EoiApiCalls';
export const customize = (text, customContent, initialInformation, lowerCase) => {
    if (!customContent || !initialInformation) {
        return { english: '', spanish: '' };
    }
    const employeeTextEnglish = lowerCase
        ? customContent.employeeText.english.toLowerCase()
        : customContent.employeeText.english;
    const employeeTextSpanish = lowerCase
        ? customContent.employeeText.spanish.toLowerCase()
        : customContent.employeeText.spanish;
    const spouseTextEnglish = lowerCase
        ? initialInformation.employerConfiguration.preference.spouseLabel.english.toLowerCase()
        : initialInformation.employerConfiguration.preference.spouseLabel.english;
    const spouseTextSpanish = lowerCase
        ? initialInformation.employerConfiguration.preference.spouseLabel.spanish.toLowerCase()
        : initialInformation.employerConfiguration.preference.spouseLabel.spanish;
    const employeeIdTextEnglish = lowerCase
        ? customContent.employeeIdText.english.toLowerCase()
        : customContent.employeeIdText.english;
    const employeeIdTextSpanish = lowerCase
        ? customContent.employeeIdText.spanish.toLowerCase()
        : customContent.employeeIdText.spanish;
    return {
        english: text.english
            .replace(new RegExp('<EMPLOYEE>', 'g'), employeeTextEnglish)
            .replace(new RegExp('<SPOUSE>', 'g'), spouseTextEnglish)
            .replace(new RegExp('<EMPLOYEEID>', 'g'), employeeIdTextEnglish),
        spanish: text.spanish
            .replace(new RegExp('<EMPLOYEE>', 'g'), employeeTextSpanish)
            .replace(new RegExp('<SPOUSE>', 'g'), spouseTextSpanish)
            .replace(new RegExp('<EMPLOYEEID>', 'g'), employeeIdTextSpanish),
    };
};
export const colorOrder = {
    0: 'primary',
    1: 'primaryDarker',
    2: 'warning',
};
export const coverageOrder = {
    LTD: 0,
    STD: 1,
    BEL: 2,
    OEL: 3,
    BSL: 4,
    OSL: 5,
    BCL: 6,
    OCL: 7,
    CIE: 8,
    CIS: 9,
    BFL: 10,
    OFL: 11,
};
export const sortCoverages = (coverages) => {
    return coverages.sort((a, b) => {
        // @ts-ignore
        const difference = coverageOrder[a.coverageCode] - coverageOrder[b.coverageCode];
        if (difference === 0) {
            return 0;
        }
        else if (difference < 0) {
            return -1;
        }
        else {
            return 1;
        }
    });
};
export const sortProducts = (products) => {
    return products.sort((a, b) => {
        // @ts-ignore
        const difference = coverageOrder[a.productCode] - coverageOrder[b.productCode];
        if (difference === 0) {
            return 0;
        }
        else if (difference < 0) {
            return -1;
        }
        else {
            return 1;
        }
    });
};
export const getCoverageIcon = (coverageCode, coverageColor) => {
    const coverageIconMap = {
        LTD: 'long-term-disability',
        STD: 'short-term-disability',
        BEL: 'life',
        OEL: 'life',
        BSL: 'life',
        OSL: 'life',
        BCL: 'life',
        OCL: 'life',
        BFL: 'life',
        OFL: 'life',
        CIE: 'critical-illness',
        CIS: 'critical-illness',
    };
    if (Object.keys(coverageIconMap).indexOf(coverageCode) > -1) {
        // @ts-ignore
        return <Icon name={coverageIconMap[coverageCode]} size={'small'} color={coverageColor} theme={themes.lincoln}/>;
    }
    else {
        return <></>;
    }
};
/**
 * Takes interviewAnswers and responds true if employee has CI selected
 * @param interviewAnswers
 */
export const hasCIEmployeeCoverageSelected = (interviewAnswers) => {
    if (interviewAnswers.coverageElections && interviewAnswers.applicantTypes) {
        let ciEmployee = false;
        if (interviewAnswers.coverageElections.employeeCoverage) {
            ciEmployee =
                interviewAnswers.coverageElections.employeeCoverage.filter(coverage => coverage.coverageCode === 'CIE')
                    .length >= 1;
        }
        return ciEmployee;
    }
};
/**
 * Takes interviewAnswers and responds true if if spouse has CI selected
 * @param interviewAnswers
 */
export const hasCISpouseCoverageSelected = (interviewAnswers) => {
    if (interviewAnswers.coverageElections && interviewAnswers.applicantTypes) {
        let ciSpouse = false;
        if (interviewAnswers.coverageElections.spouseCoverage) {
            ciSpouse =
                interviewAnswers.coverageElections.spouseCoverage.filter(coverage => coverage.coverageCode === 'CIS').length >=
                    1;
        }
        return ciSpouse;
    }
};
/**
 * Takes interviewAnswers and interrogates the object to see if the user has only CI coverage selected.
 * Any dependent coverage would mean more than just CI since it is not an applicable coverage option
 * @param interviewAnswers
 */
export const hasOnlyCICoverageSelected = (interviewAnswers) => {
    if (interviewAnswers.coverageElections) {
        let employeeHasCIOnly = true; //
        let spouseHasCIOnly = true;
        if (interviewAnswers.coverageElections.dependentCoverage &&
            interviewAnswers.coverageElections.dependentCoverage.length > 0) {
            return false;
        }
        else if (interviewAnswers.coverageElections.familyCoverage &&
            interviewAnswers.coverageElections.familyCoverage.length > 0) {
            return false;
        }
        if (interviewAnswers.coverageElections.employeeCoverage &&
            interviewAnswers.coverageElections.employeeCoverage.length > 0) {
            employeeHasCIOnly =
                interviewAnswers.coverageElections.employeeCoverage.length === 1 &&
                    interviewAnswers.coverageElections.employeeCoverage[0].coverageCode === 'CIE';
        }
        if (interviewAnswers.coverageElections.spouseCoverage &&
            interviewAnswers.coverageElections.spouseCoverage.length > 0) {
            spouseHasCIOnly =
                interviewAnswers.coverageElections.spouseCoverage.length === 1 &&
                    interviewAnswers.coverageElections.spouseCoverage[0].coverageCode === 'CIS';
        }
        return spouseHasCIOnly && employeeHasCIOnly;
    }
};
/**
 * Takes interviewAnswers and responds true if this applicant has only CI coverager selected
 * @param interviewAnswers
 */
export const isApplicantCICoverageOnly = (applicant, interviewAnswers) => {
    if (interviewAnswers.coverageElections) {
        let ciOnly = false;
        if (applicant === 'employee' &&
            interviewAnswers.coverageElections.employeeCoverage &&
            interviewAnswers.coverageElections.employeeCoverage.length > 0) {
            ciOnly =
                interviewAnswers.coverageElections.employeeCoverage.length === 1 &&
                    interviewAnswers.coverageElections.employeeCoverage[0].coverageCode === 'CIE';
        }
        else if (applicant === 'spouse') {
            if (interviewAnswers.coverageElections.familyCoverage &&
                interviewAnswers.coverageElections.familyCoverage.length > 0) {
                ciOnly = false;
            }
            else if (interviewAnswers.coverageElections.spouseCoverage &&
                interviewAnswers.coverageElections.spouseCoverage.length > 0) {
                ciOnly =
                    interviewAnswers.coverageElections.spouseCoverage.length === 1 &&
                        interviewAnswers.coverageElections.spouseCoverage[0].coverageCode === 'CIS';
            }
        }
        return ciOnly;
    }
};
export const tomorrowDateString = () => {
    const date = startOfTomorrow();
    return format(date, 'MM/dd/yyyy');
};
export const saveInterview = async (interviewAnswers, authHeader, language) => {
    let copiedInterviewAnswers = JSON.parse(JSON.stringify(interviewAnswers)); // deep copy
    if (copiedInterviewAnswers.additionalInformation) {
        copiedInterviewAnswers.additionalInformation.liscenseAgreement = false;
    }
    try {
        await SaveInterviewData(copiedInterviewAnswers, authHeader, language);
        return true;
    }
    catch (error) {
        return false;
    }
};
export const displayProductNote = (coverages, initialInformation) => {
    const productNoteCoverages = ['CIE', 'OEL', 'BEL'];
    const shouldDisplay = initialInformation &&
        initialInformation.employerConfiguration &&
        initialInformation.employerConfiguration.productNote &&
        // Since ITranslatable is always defined by the backend, we check whether or not the text is defined. Should only be defined when RTMUS sends a product note with the employer configuration.
        (initialInformation.employerConfiguration.productNote.english ||
            initialInformation.employerConfiguration.productNote.spanish) &&
        coverages
            .map(coverage => coverage.coverageCode)
            .some(coverageCode => productNoteCoverages.indexOf(coverageCode) !== -1);
    return shouldDisplay || false;
};
export const isSitusStateNY = (initialInformation) => {
    return (initialInformation &&
        initialInformation.employerConfiguration &&
        initialInformation.employerConfiguration.situsStateCode.toUpperCase() === 'NY');
};
export const isSitusStateCA = (initialInformation) => {
    return (initialInformation &&
        initialInformation.employerConfiguration &&
        initialInformation.employerConfiguration.situsStateCode.toUpperCase() === 'CA');
};
export const isSitusStateMA = (initialInformation) => {
    return (initialInformation &&
        initialInformation.employerConfiguration &&
        initialInformation.employerConfiguration.situsStateCode.toUpperCase() === 'MA');
};
