import { useContext } from 'react';
import { RiaConfigContext } from '../provider/RiaConfigProvider';
/* Ria Config
 *
 * Used to access and store customer configuration data loaded from ria api
*/
function useRiaConfig() {
    return useContext(RiaConfigContext);
}
export default useRiaConfig;
