export const VerifyEmailDisplayTextEs = {
    alertStaticText: 'Se requiere verificación por correo electrónico!',
    bodySection1: `Haga clic en el enlace del correo electrónico que se le envió para continuar a My Lincoln Portal.`,
    bodySection2: `El correo electrónico puede demorar hasta 24 horas para la entrega. Por favor revise su carpeta de correo no deseado o basura para un correo electrónico de `,
    title: 'Verificar correo electrónico',
    // 
    // Enrollment
    // 
    enrollmentTitle: 'Verificar correo electrónico',
    emailSentSuccess: 'Se ha enviado un correo electrónico de verificación!',
    enrollmentSuccessBody1: 'Se ha enviado un enlace de verificación al correo electrónico asociado con esta cuenta.',
    enrollmentSuccessBody2: 'Por favor, haga clic en el enlace para verificar su correo electrónico y continuar. Este enlace expirará en 5 días.',
};
