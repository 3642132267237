import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import ImportantMessageIconSvg from '../assets/important-message.svg';
const ImportantMessageDiv = styled('div') `
  background-color: ${({ theme }) => theme.palette.grayLightest};
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: pre-line;
`;
const Title = styled('div') `
  font-weight: bold;
`;
const Body = styled('div') `
`;
const ImportantMessageBox = ({ children, title, ...rest }) => {
    return (<ImportantMessageDiv role="alert" className={'d-flex'} {...rest}>
      <ImportantMessageIconSvg aria-hidden={true} height='3rem' className={`d-inline-block mr-3 my-auto`}/>
      <div className={`d-flex flex-column justify-content-center`}>
        {title && (<Title>
            {title}
          </Title>)}
        <Body>
          {children}
        </Body>
      </div>
    </ImportantMessageDiv>);
};
export default ImportantMessageBox;
