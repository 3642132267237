import axios from 'axios';
import React, { useEffect } from 'react';
import PageLoader from 'src/components/PageLoader/PageLoader';
import { getEnvVariable } from 'src/utils/envUtils';
import { useParams } from 'react-router-dom';
const SsoErrorPageUrl = getEnvVariable('ssoErrorUrl');
const accessToken = window.sessionStorage.getItem('mlpBearerToken');
export const performSsoRedirectLAMS = async (userId) => {
    const formData = {};
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        authorization: `Bearer ${accessToken}`,
    };
    await axios
        .post(getEnvVariable('gatewaySSODataUrl'), null, { headers: headers })
        .then((response) => {
        if (response.data.STATUS === 'SUCCESS' && response.data.ssoUrl) {
            window.location.href = response.data.ssoUrl;
        }
        else {
            window.location.href = getEnvVariable('formPageUrl');
        }
    }, error => {
        // console.log('Error on:', error); //FIXME: THESE ARE RANDOMLY BREAKING TESTS -- they log after the tests are done
        window.location.href = SsoErrorPageUrl;
    })
        .catch(() => {
        window.location.href = SsoErrorPageUrl;
        // console.log('Un handled exception') //FIXME: THESE ARE RANDOMLY BREAKING TESTS -- they log after the tests are done
    });
};
export const performSsoRedirectL4B = async (userId) => {
    const formData = {
        SERVICE_ID: 'AWS_PEX_SSO_TOKEN',
        Role: 'PA',
        planKey: 'FW16',
        Site: 'GA',
        Page: 'WELCOME_L4B',
    };
    const headers = {
        OAM_REMOTE_USER: userId,
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`,
    };
    await axios
        .post(getEnvVariable('awsGatewaySSOUrl'), formData, { headers: headers })
        .then((response) => {
        console.log('L4B response : ', response);
        const ssoLogonHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (!response.data.data.hasWebErrorData && response.data.data.destinationUrl && response.data.data.paramsMap) {
            let urlSearchParams = new URLSearchParams(Object.entries(response.data.data.paramsMap));
            let formElement = document.createElement('form');
            formElement.setAttribute('method', 'post');
            formElement.setAttribute('action', getEnvVariable('groupSsoUrl') + response.data.data.destinationUrl);
            formElement.setAttribute('target', '_parent');
            urlSearchParams.forEach((value, key) => {
                let hiddenField = document.createElement('input');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', value);
                formElement.appendChild(hiddenField);
            });
            document.body.appendChild(formElement);
            formElement.submit();
        }
        else {
            window.location.href = SsoErrorPageUrl;
        }
    }, error => {
        console.log('Error on:', error); //FIXME: THESE ARE RANDOMLY BREAKING TESTS -- they log after the tests are done
        window.location.href = SsoErrorPageUrl;
    })
        .catch(() => {
        window.location.href = SsoErrorPageUrl;
    });
};
export const aciRedirect = async (userId) => {
    const payload = {
        SERVICE_ID: 'INT_SSO_VITECH_PROVIDER',
    };
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        authorization: `Bearer ${accessToken}`,
    };
    await axios
        .post(getEnvVariable('aciGatewaySSODataUrl'), null, { headers: headers })
        .then((response) => {
        if (response.data.responseStatus && response.data.responseStatus === 'SUCCESS') {
            if (response.data && response.data.ssoUrl) {
                window.location.href = response.data.ssoUrl;
            }
            else {
                window.location.href = SsoErrorPageUrl;
            }
        }
    }, error => {
        console.log('Error on:', error);
        window.location.href = SsoErrorPageUrl;
    })
        .catch(() => {
        window.location.href = SsoErrorPageUrl;
        console.log('Un handled exception');
    });
};
const SsoService = () => {
    const { id } = useParams();
    const performSsoRedirect = () => {
        var userId = sessionStorage.getItem('loginId');
        if (userId && id) {
            if (id.toUpperCase() == 'CNVG') {
                aciRedirect(userId);
            }
            else if (id.toUpperCase() == 'LAMS') {
                performSsoRedirectLAMS(userId);
            }
            else if (id.toUpperCase() == 'L4B') {
                performSsoRedirectL4B(userId);
            }
        }
    };
    useEffect(() => {
        performSsoRedirect();
    });
    return <PageLoader id='portal-route-spinner'/>;
};
export default SsoService;
