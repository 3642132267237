import './../node_modules/@digitools/honest-react/dist/global.css';
import React from 'react';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { isDev } from './utils/envUtils';
import Sawmill from './utils/Sawmill';
import ReactDOMClient from 'react-dom/client';
import 'i18next';
import './i18n';

Sawmill.configure({ dev: isDev() });

// eslint-disable-next-line no-undef
if (newrelic && !isDev()) {
  // eslint-disable-next-line no-undef
  newrelic.addRelease('customer-ui', VERSION);
}

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
