import React from 'react';
import { Col, Row, TextInput } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateOtherRelationship } from 'src/packages/cli/utils/validationUtils';
const OtherRelationship = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    return (<>
      <Row className='mb-3'>
        <Col xs={12}>
          <TextInput field={'otherRelationship'} data-testid='otherRelationship' label={t(formLabels.otherRelationship)} 
    //@ts-ignore FIXME: type error
    ariaLabeltext={t(formLabels.otherRelationship)} validateOnBlur={true} validateOnChange={true} validate={validateOtherRelationship} required={true} initialValue={absenceData && absenceData.otherRelationship}/>
        </Col>
      </Row>
    </>);
};
export default OtherRelationship;
