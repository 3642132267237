import React from 'react';
import { Radio } from 'informed';
// @ts-ignore
import styles from './RadioButton.module.scss';
const RadioButton = (props) => {
    return (<label className={styles['radio-label']}>
      <Radio value={props.value}/>
      <span className={styles['checkmark']}/>
      <span className={styles['radio-text']}>{props.labelText}</span>
    </label>);
};
export default RadioButton;
