import { Button, Modal } from '@digitools/honest-react';
import React from 'react';
import useExperience from 'src/hooks/useExperience';
import { Language } from 'src/types/Language';
const ModalCancelButton = (text) => ({ hideModal }) => {
    const hideNavigationModal = () => {
        hideModal();
    };
    return (<Button name='NavigationModal-cancel' onClick={hideNavigationModal} type='button' buttonType='text'>
        {text}
      </Button>);
};
const ModalOkButton = (text, navigationCallback) => ({ hideModal }) => {
    const allowNavigation = () => {
        hideModal();
        navigationCallback && navigationCallback();
    };
    return (<Button name='NavigationModal-ok' onClick={allowNavigation} type='button' buttonType='primary'>
        {text}
      </Button>);
};
const NavigationModal = (props) => {
    const id = props.id || 'NavigationModal';
    const { language } = useExperience();
    const headerText = language === Language.ENGLISH ? 'Are you sure?' : '¿Está Seguro?';
    const bodyText = language === Language.ENGLISH
        ? 'If you navigate away you will lose your changes.'
        : 'Si usted navega fuera de esta página, perderá sus cambios.';
    const cancelButtonText = language === Language.ENGLISH ? 'Cancel' : 'Cancelar';
    const okButtonText = language === Language.ENGLISH ? 'Ok' : 'Ok';
    return (<Modal id={id} headerText={headerText} footerPrimaryRenderButton={ModalOkButton(okButtonText, props.navigationCallback)} footerSecondaryRenderButton={ModalCancelButton(cancelButtonText)} getControl={props.setControl}>
      <p>{bodyText}</p>
    </Modal>);
};
export default NavigationModal;
