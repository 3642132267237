const isAlphaNumeric = (input) => {
    return input.match(/^[a-z0-9]+$/i) !== null;
};
const isNumeric = (input) => {
    return input.match(/^[0-9]+$/i) !== null;
};
export const validateRequestor = (input) => {
    if (!input) {
        return 'Error';
    }
    return undefined;
};
export const validateNotEmpty = (input) => {
    if (!input) {
        return 'Error';
    }
    return undefined;
};
export const validateEmpID = (input) => {
    if (!input) {
        return 'Please enter a 5 character Employer ID';
    }
    else if (input.length > 5) {
        return 'Your Employee ID is too long - please enter exactly 5 characters';
    }
    else if (input.length < 5) {
        return 'Your Employee ID is too short - please enter exactly 5 characters';
    }
    else if (!isAlphaNumeric(input)) {
        return 'Your Employee ID must be Alphanumeric';
    }
    return undefined;
};
export const validateSelectGeneric = (input) => {
    if (!input) {
        return 'Please make a selection';
    }
    return undefined;
};
export const validateDate = (input) => {
    if (!input) {
        return 'Please enter a Date';
    }
    else if (input === '0') {
        return 'Date must be between 1 and 31';
    }
    else if (input.length > 2) {
        return 'Date is too long';
    }
    else if (!isNumeric(input)) {
        return 'Date must be Numeric';
    }
    return undefined;
};
export const validateFields = (values) => {
    return {
        requesterType: validateSelectGeneric(values.requesterType),
        employeeId: validateEmpID(values.employeeId),
        birthMonth: validateSelectGeneric(values.birthMonth),
        birthDay: validateDate(values.birthDay),
    };
};
export const validateTwoTimes = (expectedEarlierTimeValue, expectedEarlierTimeLabel, expectedLaterTimeValue, expectedLaterTimeLabel) => {
    if (expectedEarlierTimeValue >= expectedLaterTimeValue) {
        return `The ${expectedLaterTimeLabel} cannot be equal to or earlier than the ${expectedEarlierTimeLabel}. Please correct the ${expectedLaterTimeLabel} or ${expectedEarlierTimeLabel}.`;
    }
    return undefined;
};
export const timeOffFormValidate = (values) => {
    if (values.estimatedArrival || values.regularEndTime || values.estimatedReturn) {
        return {
            estimatedArrival: validateTwoTimes(values.normalStartTime, 'Normal Start Time', values.estimatedArrival, 'Estimated Arrival'),
            regularEndTime: validateTwoTimes(values.departureTime, 'Departure Time', values.regularEndTime, 'Regular End Time'),
            estimatedReturn: validateTwoTimes(values.departureTime, 'Departure Time', values.estimatedReturn, 'Estimated Return'),
        };
    }
    return undefined;
};
export const clearAndRelyOnFormForComposite = (value, values) => {
    return undefined;
};
