import React from 'react';
import { Row, Col, Link, Icon } from '@digitools/honest-react';
const ClearAllButton = (props) => {
    return (<Row style={{ marginBottom: '5px' }}>
      <Col sm={6} md={6} lg={6}/>
      <Col sm={6} md={6} lg={6}>
        <Link style={{ display: 'flex', justifyContent: 'flex-end' }} id={'clearAllSelection'} data-testid='clearAllSelection' onClick={() => {
            props.clearAllSelection(props.context);
        }}>
          <b>
            <Icon name={'undo'} color={'accent1Light'} aria-label='Clear All Selections' id='icon-remove-user' size={'xs'}/>{' '}
            Clear All Selections
          </b>
        </Link>
      </Col>
    </Row>);
};
export default ClearAllButton;
