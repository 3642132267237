import React from 'react';
import { TextInput } from '@digitools/honest-react';
import useTranslate from '../../../../../../hooks/useTranslate';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import { isNotEmpty } from '../../../../../../utils/validationUtils';
import styled from 'styled-components';
const StyledDiv = styled('div') `
    margin-top: 0.8rem;
    width: 8.625rem;
    display: flex;
    text-indent: 0.3rem;
`;
const Initials = () => {
    const { t } = useTranslate();
    return (<StyledDiv id="TextInputDiv">
        <TextInput data-testid='applicantInitials' ariaLabelText={t(ReviewAndSignText.applicantInitials)} field={'applicantInitials'} label={t(ReviewAndSignText.applicantInitials)} validateOnBlur={true} validateOnChange={true} validate={isNotEmpty} name='applicantInitials' type={'text'} style={{ marginLeft: '-0.282rem' }}/>
        </StyledDiv>);
};
export default Initials;
