import { Box, Button, Col, Heading, Icon, Row, themes, Logo as LFGLogo } from "@digitools/honest-react";
import React, { useEffect } from "react";
import styled from 'styled-components';
import { ReviewFieldForRequest } from "../review/ReviewField";
import { useNavigate } from "react-router-dom";
import useTranslate from "src/hooks/useTranslate";
import { ViewSubmissionText } from "../../constants/viewSubmissionText";
import useAsDataContext from "../../hooks/useAsDataContext";
import useExperience from "src/hooks/useExperience";
import { TEALIUM_EVENT } from "src/constants/tealiumConstants";
import useTealium from "src/hooks/useTealium";
import ReviewAboutYou from "../review/ReviewAboutYou";
import AccommodationDetails from "../review/AccommodationDetails";
const PrintableBody = styled.div `
  @media (max-width: 1024px) {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  margin-right: 8rem;
  margin-left: 8rem;
  @media print {
    @page {
        size: 300mm 307mm;
        margin-top: 20mm !important;
        margin-bottom: 20mm !important;
        margin-right: -40mm !important;
        margin-left: -40mm !important;
    }
    -webkit-print-color-adjust: exact; 
    color-adjust: exact; 
    button {
        display :none;
    }
  }
`;
const ViewPageHeading = styled(Heading) `
    color: rgba(51, 51, 51, 0.87) !important;
    font-size: 42px !important;
    font-weight: 200 !important;
`;
const PrintButton = styled(Button) `
  @media (max-width: 1024px) {
   margin-top:20px;
  }
`;
const ViewSubmission = () => {
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { claimantData, accommodationResponse } = useAsDataContext();
    const { experience } = useExperience();
    const { trackView } = useTealium();
    window.scrollTo(0, 0);
    const printPdf = () => {
        window.print();
    };
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            page_l5: 'view submission'
        });
    }, []);
    const routeToConfirmationPage = () => {
        window.scrollTo(0, 0);
        navigate(`/as/${experience.toLowerCase()}/confirmation`);
    };
    const getFullName = () => {
        if (claimantData?.empMiName) {
            return claimantData?.empFirstName + " " + claimantData?.empMiName + " " + claimantData?.empLastName;
        }
        else {
            return claimantData?.empFirstName + " " + claimantData?.empLastName;
        }
    };
    const printButtonStyle = {
        float: 'right',
        width: '172px',
        height: '45px'
    };
    const closeButtonStyles = {
        float: 'right',
        position: 'relative',
        left: '20px'
    };
    const closeButtonTextStyle = {
        position: 'relative',
        left: '5px',
        bottom: '5px'
    };
    const addressStyle = {
        fontSize: '12px',
        textAlign: 'right',
        color: '#585757',
        lineHeight: 'normal'
    };
    const footerTextStyle = {
        fontSize: '12px',
        color: '#585757',
        lineHeight: 'normal'
    };
    const PrintButtonColStyle = {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto'
    };
    return (<>
            <PrintableBody>
                <Row>
                    <Col>
                        <Button buttonType={'text'} style={closeButtonStyles} onClick={routeToConfirmationPage} data-testid="backButtonTestId">
                            <Icon color={'secondary'} name={'close-circle'} size={'medium'}/>
                            <span style={closeButtonTextStyle}>{t(ViewSubmissionText.closeButtonLabel)}</span>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        <img alt={`Lincoln Financial Group`} src={LFGLogo} width='165px' height='53px'/>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        <p style={addressStyle}>{t(ViewSubmissionText.printPageLfgAddress)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                        <ViewPageHeading>{t(ViewSubmissionText.viewSubmissionPageHeading)}</ViewPageHeading>
                    </Col>
                    <Col style={PrintButtonColStyle} lg={6} md={6} sm={6} xs={6}>
                        <PrintButton theme={themes.lincoln} style={printButtonStyle} onClick={printPdf} buttonType={'primary'}>{t(ViewSubmissionText.printLabel)}</PrintButton>
                    </Col>
                </Row>

                <Box className={'mt-4'}>
                    <Heading elemType={'h3'} color={'primary'} theme={themes.secureSite}>
                        {t(ViewSubmissionText.requestIdLabel)}{accommodationResponse?.eventNum}
                    </Heading>
                    <Row>
                        <Col lg={8} md={8} sm={8} xs={8}>
                            <ReviewFieldForRequest label={t(ViewSubmissionText.nameLabel)} value={getFullName()}/>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={4}>
                            <ReviewFieldForRequest label={t(ViewSubmissionText.todayDateLabel)} value={accommodationResponse?.submittedDateTime}/>
                        </Col>
                    </Row>
                </Box>
                <ReviewAboutYou displayButton={false}/>
                <AccommodationDetails displayButton={false}/>
                <p style={footerTextStyle}>{t(ViewSubmissionText.printPageFooterTxt)}</p>
                <p style={footerTextStyle}>{t(ViewSubmissionText.lcnNo)}</p>
            </PrintableBody>
        </>);
};
export default ViewSubmission;
