import React from 'react';
import { Row, Col, Box } from '@digitools/honest-react';
const PublicContainer = ({ children }) => (<Row className={'mt-3'}>
    <Col lg={7} className={'position-relative ml-auto mr-auto'}>
      <Box color={'grayLightest'} className={'p-4'}>
        {children}
      </Box>
    </Col>
  </Row>);
export default PublicContainer;
