//Nav Menu Bar
import { Container, Icon, Logo, Spinner, themes } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useExperience from '../../hooks/useExperience';
import { Experience } from '../../types/Experience';
import { getHomeUrl } from '../../utils/NavigationUtils';
import NavMenu from './NavMenu/NavMenu';
import useAuthentication from '../../hooks/useAuthentication';
import { getEnvVariable } from 'src/utils/envUtils';
import useCustomerConfig from 'src/hooks/useCustomerConfig';
/**
 * NavMenuBar
 *
 * Renders a menu bar for navigation links.
 * Calls Navigation API
 */
const NavMenuBar = () => {
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { navigationMenu, loadingNavMenu } = useCustomerConfig();
    const location = useLocation();
    useEffect(() => {
        //FIXME: find a better way to do this - this broke lots of tests due to lack of null check (now fixed) Has to be a cleaner way to do this
        setInterval(() => {
            const sessionIframe = document.getElementById('ssoKeepAliveIframe');
            const keepAliveURL = getEnvVariable('lfgAuthUrl') +
                '/authorize?response_type=id_token token&prompt=none&client_id=' +
                getEnvVariable('lfgAuthClientId') +
                '&&redirect_uri=' +
                getEnvVariable('keepAliveGifUrl') +
                '&nonce=' +
                Math.random();
            if (sessionIframe) {
                //FIXME: there is no src value on an HTML element. not sure if/when/why/how this 'src' is being used
                //@ts-ignore
                sessionIframe.src = keepAliveURL;
            }
        }, 300000);
    }, [experience, navigationMenu]);
    const redirectLink = getHomeUrl(experience, user?.roles);
    return (<header>
      <Container data-testid='mlp-nav' data-public={true}>
        <div style={{
            display: 'flex',
            minHeight: '88px',
            alignItems: 'center',
            padding: '1rem',
            borderBottom: `1px solid ${themes.lincoln.palette.grayLight}`,
        }}>
          <Link data-testid='lfg-logo' to={redirectLink}>
            <img alt={`Lincoln Financial Group`} src={Logo} style={{ width: '143px', height: '49px', paddingRight: '1rem' }}/>
          </Link>
          {loadingNavMenu ? (location?.pathname === '/customer-search' ? (<></>) : (<Spinner size={'small'} style={{ margin: '0px auto 0px auto' }}/>)) : (<>
              {experience != Experience.PUBLIC && location?.pathname !== '/customer-search' && (<div style={{ display: 'flex', marginLeft: 'auto' }}>
                  <Link aria-label={`Navigate to home`} data-testid='nav-home' data-public={true} to={getHomeUrl(experience)} style={{ padding: '1rem', display: 'flex' }}>
                    <Icon style={{ alignSelf: 'center' }} aria-hidden={true} name='home' color='secondary' size='small'/>
                  </Link>
                  {/* Can iterate here over returned navLinks from back-end */}
                  {navigationMenu &&
                    navigationMenu.map((navMenu, index) => (<NavMenu {...navMenu} key={`NavMenu-${index}`}/>))}
                  {/* End configurable links */}
                </div>)}
            </>)}
        </div>
      </Container>
    </header>);
};
export default NavMenuBar;
