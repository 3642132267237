import axios from "axios";
import useGet from "../../../hooks/useGet";
export const getLeaves = () => {
    return useGet({
        noInitial: true,
        url: `/ria-service/available-leaves`
    });
};
export const getAbsencesForLeave = (leaveNumber) => {
    return useGet({
        noInitial: true,
        url: `/ria-service/absences/searchByLeaveNum/${leaveNumber}`
    });
};
export const getRiaConfig = () => {
    return useGet({
        url: `/ria-service/config`
    });
};
export const SubmitAbsenceData = async (absenceSubmitData, authHeader) => {
    return await axios.post(`/ria-service/absence/submit`, absenceSubmitData, { headers: { Authorization: authHeader } });
};
export const setCorrespondencePreference = async (correspondenceSubmitData, authHeader) => {
    return await axios.post(`/ria-service/correspondence/submit`, correspondenceSubmitData, { headers: { Authorization: authHeader } });
};
export const getCorrespondencePreference = () => {
    return useGet({
        url: `/ria-service/employee/correspondence/type`
    });
};
