import { Icon, themes } from "@digitools/honest-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { slideHandler } from "./HeaderUtils";
import { MenuButton } from "./MobileHeader";
const SlideoutParentContainer = styled('div') `
width: 100%;
transition: margin-left .25s ease-in-out, margin-top .25s ease-in-out;
margin-left: 0;
`;
const SlideoutChildrenContainer = styled('div') `
position: relative;
left: 100%;
/* top: -52.1px; //TODO: revisit calculating height of button (which is now variable) and set top to equal that negative of value (will avoid diagonal animation)*/
width: 100%; 
transition: margin-left .25s ease-in-out, margin-top .25s ease-in-out;
max-height:0;
visibility: hidden;
`;
const SlideoutContainer = styled(`div`) `
display: flex;
width: 100%;
transition: margin-left .25s ease-in-out, margin-top .25s ease-in-out;
`;
// TODO: props & interface -> move to honest
const SlideoutMenu = ({ id, iconName, label, parentResize, 'data-testid': dataTestId, children, style, rest, isMobileMenuOpen, nav = false, //TODO: rename this better 
 }) => {
    const [isOpen, setIsOpen] = useState(false);
    //TODO: make this two separate methods open & close
    const openCloseMenu = (closeMode) => {
        /// @ts-ignore
        const parent = document.getElementById(id).parentNode.id;
        if (isOpen) {
            /** CLOSE - slide things back to 0, zero out children height & make invisible **/
            document.getElementById(`slideMenu-${id}`).style.marginLeft = '0';
            document.getElementById(`slideMenuChildren-${id}`).style.marginTop = '0';
            // TODO: rename to parentClosing or something
            if (!closeMode) {
                // This delay is needed because of children's margin-Top's .25second animation that throws off height calculation if it's not done transitioning before we trigger resizing
                // Actually now I'm not sure why the delay is needed... but w/o it the slide animations get janky
                // setTimeout(()=>{
                // Trigger parent slideoutHandler (moves siblings off to the left)
                slideHandler(parent, id, false);
                // Zero out Height of Children
                document.getElementById(`slideMenuChildren-${id}`).style.maxHeight = '0';
                // Trigger Parent Menu Resize calculation
                parentResize(id, false);
                // Hide the children now that animations are complete
                setTimeout(() => {
                    document.getElementById(`slideMenuChildren-${id}`).style.visibility = 'hidden';
                }, 250);
                // }, 250c);
            }
            else {
                slideHandler(parent, id, false);
                document.getElementById(`slideMenuChildren-${id}`).style.maxHeight = '0';
                document.getElementById(`slideMenuChildren-${id}`).style.visibility = 'hidden';
            }
        }
        else {
            /** OPEN - slide things off -100% left margin, reset children height & make visible **/
            // Get the height of the triggering slideButton so we can offset the slideChildren's position
            var child = document.getElementById(`${id}`);
            var offsetHeightTotal = 0;
            // @ts-ignore
            while ((child) != null) {
                offsetHeightTotal += child.offsetHeight;
                // @ts-ignore
                child = child.previousSibling;
            }
            document.getElementById(`slideMenuChildren-${id}`).style.top = `-${offsetHeightTotal}px`;
            document.getElementById(`slideMenu-${id}`).style.marginLeft = '-100%';
            document.getElementById(`slideMenuChildren-${id}`).style.visibility = 'visible';
            document.getElementById(`slideMenuChildren-${id}`).style.maxHeight = '10000000px';
            slideHandler(parent, id, true);
            parentResize(id, true);
            // TODO - dig again into this older/other method
            // Animations won't work on height:auto - so we have to calculate all the children's height and set it exact
            // const element = document!.getElementById('menu')!;
            // element.style.height = Array.prototype.reduce.call(element.childNodes, function(p, c) {return p + (c.offsetHeight || 0);}, 0) + 'px';
            // more verbose but easier to read below --
            // let newHeight = 0;
            // for (const child of element.childNodes) {
            //     newHeight += child.offsetHeight || 0;
            // }
            // element.style.height = `${newHeight}px`; 
        }
        //TODO: may be able to get rid of menuOpen state - can just look at height
        setIsOpen(!isOpen);
    };
    // Auto Closes the SlideoutMenu if the Parent Mobile menu has been closed
    useEffect(() => {
        if (!isMobileMenuOpen && isOpen) {
            // console.log('detected parent close')
            openCloseMenu(true);
        }
    }, [isMobileMenuOpen]);
    return (<>
            <SlideoutContainer id={id} data-testid={dataTestId} style={{ style }} {...rest}>
                <SlideoutParentContainer id={`slideMenu-${id}`}>
                    <MenuButton id={`slideMenuButton-${id}`} data-testid={dataTestId + '-open'} onClick={() => openCloseMenu()} style={{ color: nav ? themes.lincoln.palette.secondary : undefined, backgroundColor: nav ? themes.lincoln.palette.white : undefined }}>
                        {iconName && <Icon theme={themes.lincoln} color={nav ? 'secondary' : 'grayDark'} size='xs' name={iconName} style={{ marginRight: '.5rem' }}/>}
                        <span style={{ display: 'inline-block', marginRight: 'auto' }}>
                            {label}
                        </span>
                        <Icon theme={themes.lincoln} color={nav ? 'secondary' : 'grayDark'} name={'chevron-right-solid'} style={{ justifySelf: 'right' }}/>
                    </MenuButton>
                    <SlideoutChildrenContainer id={`slideMenuChildren-${id}`}>
                        <MenuButton onClick={() => openCloseMenu()} id={id + '-close'} data-testid={id + '-close'} style={{ color: nav ? themes.lincoln.palette.secondary : undefined, backgroundColor: nav ? themes.lincoln.palette.white : undefined }}>
                            <Icon theme={themes.lincoln} color={nav ? 'secondary' : 'grayDark'} name={'chevron-left-solid'} style={{ marginRight: '.5rem' }}/>
                            <span style={{ display: 'inline-block', marginRight: 'auto' }}>
                                {iconName && <Icon theme={themes.lincoln} color={'grayDark'} size='xs' name={iconName} style={{ marginRight: '.5rem' }}/>}
                                {label}
                            </span>
                        </MenuButton>
                        {children}
                    </SlideoutChildrenContainer>
                </SlideoutParentContainer>
            </SlideoutContainer>
        </>);
};
export default SlideoutMenu;
