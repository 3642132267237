import React, { useContext, useRef, useState, useEffect } from 'react';
import { Heading, StepperContext, Form, Alert } from '@digitools/honest-react';
import { AboutYourAbsenceText } from './../../constants/AboutYourAbsenceText';
import StepperButtons from '../../components/StepperButtons';
import useCliDataContext from '../../hooks/useCliDataContext';
import LeaveTypeAndCategory from './flows/LeaveTypeAndCategory';
import useTranslate from 'src/hooks/useTranslate';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import LeaveReason from './components/LeaveReason';
import ClaimantCondition from './components/ClaimantCondition';
import PregnancyFlow from './flows/PregnancyFlow';
import IllnessInjuryFlow from './flows/IllnessInjuryFlow';
import OtherFlow from './flows/OtherFlow';
import FamilyParentalFlow from './flows/FamilyParentalFlow';
import MilitaryFlow from './flows/MilitaryFlow';
import { Experience } from 'src/types/Experience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { LeaveType } from '../../providers/CliConfigProvider';
import useExperience from 'src/hooks/useExperience';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import RouterPrompt from '../../components/RouterPrompt';
import GettingStartedText from '../start/GettingStartedText';
import useOnScreen from 'src/hooks/visibilityChecker';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import useAuthentication from 'src/hooks/useAuthentication';
import useCliContext from '../../hooks/useCliContext';
import styled from 'styled-components';
const StyledAlert = styled(Alert) `
  border : 0px ;
`;
const AboutYourAbsence = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { trackEvent } = useTealium();
    const { claimantData, setAbsenceData, setContactData, saveAndUpdateInterview } = useCliDataContext();
    const cliConfigContext = useCliCustomerConfigContext();
    const { isNYDICustomer } = useCliContext();
    const [showNYDIText, setShowNYDIText] = useState();
    const stepper = useContext(StepperContext);
    useEffect(() => {
        if (isNYDICustomer && user?.customerInContext?.smallMid && claimantData?.selectedEmpStateID === "NY") {
            return setShowNYDIText(true);
        }
        else {
            return setShowNYDIText(false);
        }
    });
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const submit = (values) => {
        //@ts-ignore
        const leaveReason = values?.absenceData?.leaveReason;
        //@ts-ignore
        const leaveCategory = values?.absenceData?.leaveCategory;
        //@ts-ignore
        const leaveType = values?.absenceData?.leaveType;
        //@ts-ignore
        const claimantCondition = values?.absenceData?.claimantCondition;
        //@ts-ignore
        const submitAbsenceDataObject = { ...values?.absenceData };
        //@ts-ignore
        if (leaveType === LeaveType.OTHER && (leaveReason !== 'SC' && leaveReason !== 'WC'))
            submitAbsenceDataObject.relationship = 'SE';
        //@ts-ignore
        if (leaveType === LeaveType.OWN_ILLNESS && leaveCategory === 'I')
            submitAbsenceDataObject.relationship = 'SE';
        //@ts-ignore
        if (leaveReason === 'MD' || leaveReason === 'EX')
            submitAbsenceDataObject.relationship = 'SE';
        //@ts-ignore
        if (leaveCategory === 'C' && leaveReason === 'EX') {
            //@ts-ignore
            submitAbsenceDataObject.claimantCondition = null;
        }
        //@ts-ignore
        if (leaveType === LeaveType.PREGNANT && claimantCondition === 'P')
            submitAbsenceDataObject.leaveCategory = 'C';
        //@ts-ignore - typing doesn't know about the scopes
        setAbsenceData(submitAbsenceDataObject);
        //@ts-ignore - typing doesn't know about the scopes
        setContactData(values?.contactData);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: experience === Experience.EMPLOYEE && cliConfigContext && cliConfigContext?.hasSaveForLater ? 'save and continue' : 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_version: (experience === Experience.EMPLOYEE ? t(AboutYourAbsenceText.pageTitleEE) : t(AboutYourAbsenceText.pageTitleER)),
            event_status: 'step 3',
        });
        saveAndUpdateInterview(values);
        stepper.next();
        window.scrollTo(0, 0);
    };
    const mainSubHeadingLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ?
        t(AboutYourAbsenceText.mainSubHeading) :
        `${t(AboutYourAbsenceText.mainSubHeadingER)}`);
    return (<div ref={ref}>
      {stepper.currentStep !== t(GettingStartedText.pageTitle) && isVisible && <RouterPrompt />}
      <Heading elemType='h2' color='primary'>
        {experience === Experience.EMPLOYEE ? t(AboutYourAbsenceText.pageTitleEE) : t(AboutYourAbsenceText.pageTitleER)}
      </Heading>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>{mainSubHeadingLabel}</Heading>
      </BorderLessGrayBox>
      {(showNYDIText) &&
            <StyledAlert type={'warning'}>
          <strong>{t(AboutYourAbsenceText.NYDIText)}</strong>
        </StyledAlert>}
      <Form onSubmit={submit}>
        <div className={'mr-3 ml-3'}>
          {/* --- Common Flow --- */}
          <LeaveTypeAndCategory />
          <LeaveReason />
          {/* --- Claim Flow --- */}
          <ClaimantCondition />
          {/* --- Pregnancy Flow --- */}
          <PregnancyFlow />
          {/* --- Illness/Injury Flow --- */}
          <IllnessInjuryFlow />

          {/* --- Parental & Family Flow --- */}
          <FamilyParentalFlow />
          
          {/* --- Military Flow --- */}
          <MilitaryFlow />

          {/* --- Other Flow --- */}
          <OtherFlow />
        </div>
        <StepperButtons suppressNext analyticsPageName='about absence'/>
      </Form>
    </div>);
};
export default AboutYourAbsence;
