import React from 'react';
import { CliConfirmationTextClaim } from './CliConfirmationTextClaim';
import { Link } from 'react-router-dom';
export const CliConfirmationTextLeave = {
    pageHeading: {
        english: (<>
        <strong>Thank you!</strong> You have successfully submitted a new leave.
      </>),
        spanish: (<>
        <strong>Gracias!</strong> Ha enviado con éxito una nueva licencia.
      </>), // <- for claim only, 'una nueva ausencia' - this should conjugate different because of masculine/feminine nouns
    },
    subHeading: {
        english: 'Please keep your Leave ID handy for future reference.',
        spanish: 'Tenga a mano su identificación de licencia para referencia futura',
    },
    videoMessage: {
        english: (<>
        For more information on next steps,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open('https://players.brightcove.net/1134849171001/default_default/index.html?videoId=6257960955001', '_blank', 'height=400px, width=600px')}>
            watch our step by step video of the leave process.
          </a>
        </strong>
      </>),
        spanish: (<>
        Para obtener más información sobre los próximos pasos,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open('https://players.brightcove.net/1134849171001/default_default/index.html?videoId=6257960955001', '_blank', 'height=400px, width=600px')}>
            vea nuestro video paso a paso del proceso de licencia.
          </a>
        </strong>
      </>),
    },
    eventNumber: {
        english: 'LEAVE ID: ',
        spanish: 'ID DE BAJA: ',
    },
    ourNextStepsHeading: {
        english: 'Our Next Steps',
        spanish: 'Nuestros Próximos Pasos',
    },
    ourNextStepsBody: {
        english: 'Your Leave Specialist will review your request and send you related correspondence within 5 business days via your preferred contact method, which you indicated during intake.',
        spanish: 'Su especialista en licencias revisará su solicitud y le enviará la correspondencia relacionada dentro de los 5 días hábiles a través de su método de contacto preferido, que indicó durante la admisión.',
    },
    nextStepsHeading: {
        english: 'Your Next Steps',
        spanish: 'Tus Siguientes Pasos',
    },
    nextSteps1Heading: {
        english: 'Contact your manager',
        spanish: 'Comuníquese con su gerente',
    },
    nextSteps1Body: {
        english: 'If you haven’t already done so, please contact your manager to inform them you’ll be out of work.',
        spanish: 'Si aún no lo ha hecho, comuníquese con su gerente para informarle que no tendrá trabajo.',
    },
    nextSteps2Heading: {
        english: 'Upload leave related documents to My Lincoln Portal®',
        spanish: 'Cargar documentos relacionados con la licencia en My Lincoln Portal®',
    },
    nextSteps2BodyEE: {
        english: (<>
        You can upload any leave related documents and medical records via our secure portal, by selecting the link
        below. You can also upload at any time in the future, by selecting the{' '}
        <Link data-testid='view-status-link-1' to='/status/employee' target='_blank'>
          <strong>View Status</strong>
        </Link>{' '}
        button from the home page. These documents will be viewable by your Leave Specialist and may expedite the
        processing of your leave.
      </>),
        spanish: (<>
        Puede cargar todos los documentos y registros médicos relacionados con la licencia a través de nuestro portal
        seguro, seleccionando el enlace a continuación. También puede cargar en cualquier momento en el futuro,
        seleccionando el botón{' '}
        <Link data-testid='view-status-link-1' to='/status/employee' target='_blank'>
          <strong>Ver el Estado de</strong>
        </Link>{' '}
        en la página de inicio. Estos documentos serán visibles para su especialista en permisos y pueden acelerar el
        procesamiento de su permiso.
      </>),
    },
    nextSteps2BodyER: {
        english: (<>
        You can upload any leave related documents and medical records via our secure portal, by selecting the link
        below. You can also upload at any time in the future, by selecting the{' '}
        <Link data-testid='view-status-link-1' to='/status' target='_blank'>
          <strong>View Status</strong>
        </Link>{' '}
        button from the home page. These documents will be viewable by your Leave Specialist and may expedite the
        processing of your leave.
      </>),
        spanish: (<>
        Puede cargar todos los documentos y registros médicos relacionados con la licencia a través de nuestro portal
        seguro, seleccionando el enlace a continuación. También puede cargar en cualquier momento en el futuro,
        seleccionando el botón{' '}
        <Link data-testid='view-status-link-1' to='/status' target='_blank'>
          <strong>Ver el Estado de</strong>
        </Link>{' '}
        en la página de inicio. Estos documentos serán visibles para su especialista en permisos y pueden acelerar el
        procesamiento de su permiso.
      </>),
    },
    nextSteps3Heading: {
        english: 'Stay updated about your leave',
        spanish: 'Manténgase actualizado sobre su licencia',
    },
    nextSteps3BodyEE: {
        english: (<>
        You'll be able to view your leave online within 48 hours, simply select the{' '}
        <Link data-testid='view-status-link-3' to='/status/employee' target='_blank'>
          <strong>View Status</strong>
        </Link>{' '}
        from the home page. Here you'll also find your Leave Specialist contact information should you have any
        questions.
      </>),
        spanish: (<>
        Podrá ver su licencia en línea dentro de las 48 horas, simplemente seleccione{' '}
        <Link data-testid='view-status-link-3' to='/status/employee' target='_blank'>
          <strong>Ver el Estado de</strong>
        </Link>{' '}
        en la página de inicio. Aquí también encontrará la información de contacto de su especialista en licencias si
        tiene alguna pregunta.
      </>),
    },
    nextSteps3BodyER: {
        english: (<>
        You'll be able to view your leave online within 48 hours, simply select the{' '}
        <Link data-testid='view-status-link-3' to='/status' target='_blank'>
          <strong>View Status</strong>
        </Link>{' '}
        from the home page. Here you'll also find your Leave Specialist contact information should you have any
        questions.
      </>),
        spanish: (<>
        Podrá ver su licencia en línea dentro de las 48 horas, simplemente seleccione{' '}
        <Link data-testid='view-status-link-3' to='/status' target='_blank'>
          <strong>Ver el Estado de</strong>
        </Link>{' '}
        en la página de inicio. Aquí también encontrará la información de contacto de su especialista en licencias si
        tiene alguna pregunta.
      </>),
    },
    nextSteps3Link: {
        english: CliConfirmationTextClaim.nextSteps4Link.english,
        spanish: CliConfirmationTextClaim.nextSteps4Link.spanish,
    },
    automatedMessageText: {
        english: (<>
        <br />
        <strong>Interested in getting automated leave status updates by text?</strong>
        <br />
        Click the link below to update your Alerts & notifications in your portal profile. All communication will be
        sent in English.
      </>),
        spanish: (<>
        <br />
        <strong>
          ¿Está interesado en recibir actualizaciones automáticas del estado de la licencia por mensaje de texto?
        </strong>
        <br />
        Haga clic en el enlace a continuación para actualizar sus alertas y notificaciones en su perfil del portal. Toda
        la comunicación se enviará en inglés.
      </>),
    },
    questions: {
        english: 'My Lincoln Portal® is your one-stop shop for everything you need to manage your leave. You can access the portal 24 hours a day, 7 days a week, from your desktop, tablet, or mobile device.',
        spanish: 'My Lincoln Portal® es su ventanilla única para todo lo que necesita para administrar su licencia. Puede acceder al portal las 24 horas del día, los 7 días de la semana, desde su computadora de escritorio, tableta o dispositivo móvil.',
    },
    videoUrl: 'https://players.brightcove.net/1134849171001/default_default/index.html?videoId=6257960955001',
    videoId: '6257960955001',
    videoHeading: {
        english: 'Have questions about your next steps in the leave process?',
        spanish: '¿Tiene preguntas sobre sus próximos pasos en el proceso de licencia?',
    },
    videoSubheading: {
        english: CliConfirmationTextClaim.videoSubheading.english,
        spanish: CliConfirmationTextClaim.videoSubheading.spanish,
    },
};
