import { themes } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../../hooks/useTranslate';
const StyledMessage = styled('div') `
    border: 1px solid ${themes.lincoln.palette.grayLighter};
    ${props => props.borderColor ? `border-left: 8px solid ${props.borderColor};` : `1px solid ${themes.lincoln.palette.grayLighter}`};

    width: 90%;
    margin: auto;
    margin-bottom: 2%;
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    white-space: break-spaces;
`;
const messageColors = [
    themes.lincoln.palette.primary,
    themes.lincoln.palette.primaryDarker,
    themes.lincoln.palette.warning
];
const Message = ({ ...props }) => {
    const { t } = useTranslate();
    const { index, message } = props;
    return (<StyledMessage data-testid='employer-message' borderColor={index ? messageColors[index % 3] : messageColors[0]}>
            {message.title.english !== "" &&
            <> 
                    <h3 data-testid="message-title">{t(message.title)}</h3>
                    <br />
                </>}
            <div data-testid="message-body" dangerouslySetInnerHTML={{ __html: t(message.body) }}/>
        </StyledMessage>);
};
export default Message;
