import React from 'react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../../hooks/useTranslate';
import { GraySpacerDiv } from '../../../../../../components/Formatting/StyledComponents';
const ReviewAndSignField = (props) => {
    const { t } = useTranslate();
    return (<div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 500, fontSize: '0.875rem', marginBottom: '0.5rem' }}>
      {t(props.label)}
      <div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 300, fontSize: '1.125rem', textTransform: props.capitalize ? 'capitalize' : undefined }}>
        {props.value}
      </div>
      <GraySpacerDiv style={{ marginTop: props.value ? undefined : '1.3rem' }}/> 
    </div>);
};
export default ReviewAndSignField;
