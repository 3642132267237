const formatPhone = str => {
  if (str && str.trim() != '') {
    const strng = str.replace(/-|\(|\)/g, '');
    const area = strng.slice(0, 3);
    const prefix = strng.slice(3, 6);
    const suffix = strng.slice(6, 10);
    return `(${area}) ${prefix}-${suffix}`;
  } else {
    return ``;
  }
};

export default formatPhone;
