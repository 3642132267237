import React, { useEffect, useMemo } from 'react';
import { CompositeTable } from '@digitools/honest-react';
import checkDate from '../../../utilities/checkDate';
import titleCase from '../../../utilities/titleCase';
import { Link, useNavigate } from 'react-router-dom';
import { encode } from '../../../utilities/hash';
import { compareAsc, parse } from 'date-fns';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
const EmployeeNameCell = ({ data }) => {
    return (<Link to={`/status/employer/${encode(data._original.empNum)}/coveragedetail?from=search&search=${data._original.searchString}&searchCrit=${data._original.searchCrit}`}>
      {titleCase(data.empLastName)}
    </Link>);
};
// @ts-ignore
const EmployeeSearchResults = ({ results, searchMeta, searchValues }) => {
    if (!results) {
        return null;
    }
    const location = useLocation();
    const navigate = useNavigate();
    const searchString = searchValues.search;
    const searchCrit = searchValues.searchCriteria;
    const parsedSearch = queryString.parse(location.search);
    // We found a single result so redirect to the employee landing page
    if (results?._embedded?.employees?.length === 1 && parsedSearch.from !== 'detailPage') {
        navigate(`/status/employer/${encode(results?._embedded?.employees[0]?.empNum)}/coveragedetail?from=search&search=${parsedSearch.search || searchValues.search}&searchCrit=${parsedSearch.searchCrit || searchValues.searchCriteria}`);
    }
    useEffect(() => {
        // We found a claim so just redirect
        if (results?.clmNum) {
            navigate(`/status/employer/${encode(results?.empNum)}/claims/${encode(results?.clmNum)}/details?from=search`);
        }
        // We found a leave so just redirect
        if (results?.lveNum) {
            navigate(`/status/employer/${encode(results?.empNum)}/leaves/${encode(results?.lveNum)}/details?from=search`);
        }
        // We found a accommodation so just redirect to accommodation details page
        if (results?.asEventDetail) {
            navigate(`/status/employer/${encode(results?.asEventDetail?.empNum.toString())}/accommodations/${encode(results?.asEventDetail?.evtNum.toString())}/details?from=search`);
        }
    }, [results?.clmNum, results?.lveNum, results?.asEventDetail]);
    const searchResultIds = results && !results?.clmNum && !results?.lveNum && !results?.asEventDetail;
    const formattedData = useMemo(() => {
        if (searchResultIds) {
            return results._embedded.employees.map((emp) => {
                return {
                    ...emp,
                    empLastName: titleCase(emp.empLastName),
                    empFirstName: titleCase(emp.empFirstName),
                    empBthDte: checkDate(emp.empBthDte),
                    empHireDte: checkDate(emp.empHireDte),
                    searchString,
                    searchCrit,
                };
            });
        }
    }, [results]);
    let columns = [];
    if (results && !results.clmNum && !results.lveNum) {
        columns = [
            {
                Cell: EmployeeNameCell,
                Header: 'Last Name',
                accessor: 'empLastName',
                id: 'empLastName',
            },
            {
                Header: 'First Name',
                accessor: 'empFirstName',
                id: 'empFirstName',
            },
            {
                Header: searchMeta.employeeIDText_en,
                accessor: 'empId',
                id: 'empId',
            },
            {
                Header: 'Date of Birth',
                accessor: 'empBthDte',
                id: 'empBthDte',
                sortMethod: (a, b) => compareAsc(parse(a, 'yyyy-MM-dd', new Date()), parse(b, 'yyyy-MM-dd', new Date())),
            },
            {
                Header: 'Hire Date',
                accessor: 'empHireDte',
                id: 'empHireDte',
                sortMethod: (a, b) => compareAsc(parse(a, 'yyyy-MM-dd', new Date()), parse(b, 'yyyy-MM-dd', new Date())),
            },
        ];
    }
    return (<div>
      {searchResultIds ?
            <CompositeTable data-testid={"searchResults_compositeTable"} data-private={true} data={formattedData} columns={columns} paginated={formattedData?.length > 10}/>
            :
                null}
    </div>);
};
export default EmployeeSearchResults;
