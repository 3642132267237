import { Box, } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import { Heading } from '@digitools/honest-react/dist/lib/components/Heading';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { CliConfirmationText } from '../i18n/CliConfirmationText';
import useTranslate from '../../../hooks/useTranslate';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const PrettyBox = styled(Box) `
  border: none;
  background-color: ${({ theme }) => theme.palette.grayLightest};
  transition: none;
  margin-top: 50px ;
`;
const FormattedLink = styled.a `
  margin: 15px;
  text-align: center;
  display: block;
`;
const PlayerWrapper = styled.div `
  padding: 15px;
  .brightcove-react-player-loader {
    .video-js {
      width: auto;
    }
  }
`;
const VideoBox = ({ submissionType, onFailure }) => {
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const onError = function () {
        console.log("Failed to load video");
        onFailure();
    };
    const openVideoInWindow = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE_SIDE_PANEL,
            event_name: `${submissionType} video link clicked`,
        });
        window.open(CliConfirmationText[submissionType].videoUrl, "_blank", "height=400px, width=600px");
    };
    return (<PrettyBox>
            <Heading style={{ textAlign: 'center', margin: '12px' }} elemType='h4' elemStyle='h4'>{t(CliConfirmationText[submissionType].videoHeading)}</Heading>
            <FormattedLink target='_blank' href="#" onClick={openVideoInWindow}><strong>{t(CliConfirmationText[submissionType].videoSubheading)}</strong></FormattedLink>
          
          <PlayerWrapper>
            <ReactPlayerLoader accountId="1134849171001" playerId='default' videoId={CliConfirmationText[submissionType].videoId} embedId='default' onFailure={onError}/>
          </PlayerWrapper>
            
        </PrettyBox>);
};
export default VideoBox;
