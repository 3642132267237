import React, { createContext, useState } from 'react';
import AccomodationRequest from '../pages/aboutAccommodation/AccommodationRequest';
export const AsDataContext = createContext({
    claimantData: undefined,
    setClaimantData: () => { },
    conditionData: undefined,
    setConditionData: () => { },
    isSflModalOpen: undefined,
    setIsSflModalOpen: () => { },
    documents: [],
    setDocuments: () => { },
    submitErrors: false,
    setSubmitErrors: () => { },
    isLoading: false,
    isPrePopLoading: false,
    setIsLoading: () => { },
    setIsPrePopLoading: () => { },
    accommodationRequestDataArray: [],
    setAccommodationRequestDataArray: () => { },
    accommodationResponse: undefined,
    setAccommodationResponse: () => { },
    isDisclaimerAccepted: undefined,
    setIsDisclaimerAccepted: () => { },
    receivedDate: undefined,
    setReceivedDate: () => { },
    receivedRequestDate: undefined,
    setReceivedRequestDate: () => { },
    requestGroup: undefined,
    setRequestGroup: () => { },
    requestCount: 1,
    setRequestCount: () => { },
});
/**
 * Provides AS Interview data & functions to set this data to all children
 */
export const AsDataProvider = ({ children }) => {
    const [claimantData, setClaimantData] = useState();
    const [conditionData, setConditionData] = useState();
    const [isSflModalOpen, setIsSflModalOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [submitErrors, setSubmitErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false); //TODO: FIXME: vague loading states - we should consolidate and name properly
    const [isPrePopLoading, setIsPrePopLoading] = useState(false);
    const [accommodationRequestDataArray, setAccommodationRequestDataArray] = useState([]);
    const [accommodationResponse, setAccommodationResponse] = useState();
    const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState();
    const [receivedDate, setReceivedDate] = useState();
    const [receivedRequestDate, setReceivedRequestDate] = useState();
    const [requestCount, setRequestCount] = useState(1);
    const [requestGroup, setRequestGroup] = useState([<AccomodationRequest count={requestCount} key={requestCount}/>]);
    const asDataContext = {
        claimantData,
        setClaimantData,
        conditionData,
        setConditionData,
        isSflModalOpen,
        setIsSflModalOpen,
        documents,
        setDocuments,
        submitErrors,
        setSubmitErrors,
        isLoading,
        setIsLoading,
        isPrePopLoading,
        setIsPrePopLoading,
        accommodationRequestDataArray,
        setAccommodationRequestDataArray,
        accommodationResponse,
        setAccommodationResponse,
        isDisclaimerAccepted,
        setIsDisclaimerAccepted,
        receivedDate,
        setReceivedDate,
        receivedRequestDate,
        setReceivedRequestDate,
        requestCount,
        setRequestCount,
        requestGroup,
        setRequestGroup,
    };
    return <AsDataContext.Provider value={asDataContext}>
    {children}
  </AsDataContext.Provider>;
};
