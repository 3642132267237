import { Box, Button, Heading, Link } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import { MigrateDisplayText } from 'src/packages/userMigration/i18n/MigrateDisplayText';
import useTranslate from 'src/hooks/useTranslate';
import { getEnvVariable, windowReplace } from 'src/utils/envUtils';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { StyledLCNText } from 'src/components/styledComponents';
const StyledBox = styled(Box) `
  {
    padding: 3rem;
  }
`;
const MigrationSuccessComponent = () => {
    const { t } = useTranslate();
    const { trackEvent, trackView } = useTealium();
    const { state } = useLocation();
    const trackLogin = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: TEALIUM_EVENT.EVENT_NAME.LOGIN,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: state?.hasUsernameReset ? TEALIUM_EVENT.EVENT_VERSION.USERNAME_CONFIRMATION : TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS_CONFIRMATION,
        });
        windowReplace(`${getEnvVariable('lfgLoginUrl')}`);
    };
    useEffect(() => {
        setTimeout(() => {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MIGRATION,
                page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE,
                page_l5: (state?.hasUsernameReset ? TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.USERNAME_CONFIRMATION : TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.UPDATE_DETAILS_CONFIRMATION),
                user_audience: TEALIUM_PAGE_VIEW.USER_AUDIENCE.EMPLOYEE,
            });
        }, 1000);
    }, [state?.hasUsernameReset]);
    return (<>
      <Row className={'mt-3'}>
        <Col xl={5} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledBox>
            <Heading elemType={'h1'} elemStyle={'h2'} color={'primary'} className={'mb-2'} data-testid={'migrate-success-heading'}>
              {t(MigrateDisplayText.success.heading)}
            </Heading>
            <p>{t(MigrateDisplayText.success.infoParagraph)} </p>
            <p>
              <strong>{t(MigrateDisplayText.success.infoParagraph2)}</strong>{' '}
              <Link href={`${getEnvVariable('lfgLoginUrl')}`} style={{ textDecoration: 'none' }}>
                {t(MigrateDisplayText.success.infoLink)}
              </Link>{' '}
              {t(MigrateDisplayText.success.infoParagraph3)}
            </p>
            <Button data-public={true} buttonType={'primary'} className={'mt-3 d-block w-100'} onClick={trackLogin} data-testid={'migrate-success-login-to-lfg-button'}>
              {t(MigrateDisplayText.success.loginButton)}
            </Button>
          </StyledBox>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col xl={5} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledLCNText>{t(MigrateDisplayText.LCN)}</StyledLCNText>
        </Col>
      </Row>
    </>);
};
export default MigrationSuccessComponent;
