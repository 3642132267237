import { Alert, Col, Heading, Radio, RadioGroup, Row, StepperContext } from '@digitools/honest-react';
import { useFormApi } from 'informed';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useExperience from 'src/hooks/useExperience';
import useTealium from 'src/hooks/useTealium';
import useTranslate from 'src/hooks/useTranslate';
import LeaveCard from 'src/packages/ria/components/LeaveCard';
import useRiaData from 'src/packages/ria/hooks/useRiaData';
import { BackButton, ContinueButton, SlimButton, StepsDiv, StickyDiv } from '../../components/LayoutComponents';
import { RIA_TEXT } from '../../constants/RiaConstants';
// Ignore this -- playing with some scrolling behavior, WIP
/*
const smoothScroll = function(target) {
  var scrollContainer = target;
  do { //find scroll container
      scrollContainer = scrollContainer.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
  } while (scrollContainer.scrollTop == 0);

  var targetY = 0;
  do { //find the top of target relatively to the container
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
  } while (target = target.offsetParent);

  const scroll = function(c:any, a:any, b:any, i:any) {
      i++; if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      setTimeout(function(){ scroll(c, a, b, i); }, 20);
  }
  // start scrolling
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
}
*/
const SelectLeave = () => {
    const formApi = useFormApi();
    const stepper = useContext(StepperContext);
    const { experience } = useExperience();
    const { leaves, setCurrentLeave, currentLeave, setNewAbsenceDateTimes } = useRiaData();
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const onClickLeave = (leave, i) => {
        setError(false);
        // smoothScroll(document.getElementById('leave-'+i))
        if (leave?.lveNum === currentLeave?.lveNum) {
            setCurrentLeave(undefined);
            setNewAbsenceDateTimes([]);
            formApi.setValue('leaveCardSelection', '');
        }
        else {
            setCurrentLeave(leave);
            formApi.setValue('leaveCardSelection', leave.lveNum);
        }
    };
    const onClickNext = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'select leave',
            transaction_id: currentLeave?.lveNum,
            event_status: TEALIUM_EVENT.EVENT_STATUS.START,
        });
        if (currentLeave) {
            stepper.next();
            // eslint-disable-next-line no-restricted-globals
            scrollTo(0, 0);
        }
        else {
            setError(true);
        }
    };
    const onClickBack = () => {
        navigate(`/${experience.toLowerCase()}/home`);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'cancel',
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'select leave',
        });
        navigate(`/${experience.toLowerCase()}/home`);
    };
    useEffect(() => {
        if (currentLeave && location?.state?.skipToLeave) {
            stepper.next();
        }
    }, [location]);
    return (<div>
      <Heading elemType='h2' color='grayDarkest'>
        {t(RIA_TEXT.selectLeave)}
      </Heading>
      <p>{t(RIA_TEXT.selectLeaveSubHeading)}</p>
      <div style={{ paddingBottom: '.5rem' }}>
        <RadioGroup field={'leaveCardSelection'} label='' data-testid='leaveCardRadioGroup'>
          {leaves &&
            leaves.map((leave, i) => (<>
                <Row>
                  <Col lg={9} md={12}>
                    <div style={{ display: 'flex' }}>
                      <Radio onClick={() => onClickLeave(leave, i)} value={leave.lveNum} name='leaveCardSelection' labelStyle={{
                    position: 'absolute',
                    height: '1px',
                    width: '1px',
                    overflow: 'hidden',
                    clip: 'rect(1px, 1px, 1px, 1px)',
                }} label={`Leave ${leave?.lveNum}`} data-testid={`leaveRadio-${i}`} aria-hidden={true} tabIndex={-1} ariaHiddenLabel={true} containerStyle={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '.5rem' }}/>
                      <SlimButton style={{ marginLeft: '1.5rem' }} id={`leave-${i}`} data-testid={`leave-${i}`} onClick={() => {
                    onClickLeave(leave, i);
                }} aria-label={`Leave number ${leave?.lveNum}. Leave type ${t(leave?.lveTypeTranslatable)}. Leave Begin date ${leave?.lveBeginDte}. Leave end date ${leave?.lveEndDte}. Leave reason ${t(leave?.lveReasTranslatable)}.`}>
                        <LeaveCard leave={leave} isSelected={leave.lveNum === currentLeave?.lveNum}/>
                      </SlimButton>
                    </div>
                  </Col>
                </Row>
              </>))}
        </RadioGroup>
      </div>
      <StickyDiv style={{ zIndex: 1 }}>
        {error && <Alert type='error'>{t(RIA_TEXT.selectLeaveError)}</Alert>}
        <StepsDiv>
          <BackButton data-testid='back' buttonType='secondary' onClick={onClickBack}>
            {t(RIA_TEXT.cancelButton)}
          </BackButton>
          <ContinueButton data-testid='continue' buttonType='primary' onClick={onClickNext}>
            {t(RIA_TEXT.continueButton)}
          </ContinueButton>
        </StepsDiv>
      </StickyDiv>
    </div>);
};
export default SelectLeave;
