import React from 'react';
import ProveComponent from './ProveComponent';
import ProveDataProvider from './ProveDataProvider';
/**
 * Prove
 *
 * Top level component wrapping all prove verification components,
 * wraps them with the data provider
 * broken out this way to more easily tests all prove components with access to the provider
 *
 * @returns
 */
const Prove = ({ altDisplay = false }) => {
    return (<ProveDataProvider>
      <ProveComponent altDisplay={altDisplay}/>
    </ProveDataProvider>);
};
export default Prove;
