import React from 'react';
import { Row, Col, themes, Heading, Label } from '@digitools/honest-react';
import SubLocModal from './subLocModal/SubLocModal';
const SelectSubsAndLocs = ({ selectedSubLocs, subLocData, getSelected, reportFilterCategory, }) => {
    return (<div style={{ marginTop: '1.5rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
        <Heading elemType={'h2'} elemStyle={'h3'} color={'grayDarker'}>
          {'Subsidiaries/locations'}
        </Heading>
        <Row>
          <Col>
            <Label style={{ marginLeft: '1rem' }}>
              {selectedSubLocs.length === subLocData.length && subLocData.length > 0 ? 'All' : selectedSubLocs.length}{' '}
              selected
            </Label>
          </Col>
        </Row>
        <SubLocModal data={subLocData} getSelected={getSelected} selectedSubLocs={selectedSubLocs} reportFilterCategory={reportFilterCategory}/>
      </div>
      <hr />
    </div>);
};
export default SelectSubsAndLocs;
