import React from 'react';
export const ApplicantCoverageText = {
    coverageElectionHeading: {
        english: 'Who is applying for coverage?​',
        spanish: '¿Quién solicita la cobertura?',
    },
    coverageElectionSubHeading: {
        english: 'Please select all that apply:',
        spanish: 'Por favor seleccione todas las respuestas válidas:',
    },
    employeeOption: {
        english: 'Me',
        spanish: 'Yo',
    },
    spouseOption: {
        english: 'My <SPOUSE>',
        spanish: 'Mi <SPOUSE>',
    },
    dependentOption: {
        english: 'My dependents or children',
        spanish: 'Mis dependientes o hijos',
    },
    employeeCoverage: {
        english: 'My coverage',
        spanish: 'Mi cobertura',
    },
    spouseCoverage: {
        english: '<SPOUSE> coverage',
        spanish: 'La cobertura de su <SPOUSE>',
    },
    dependentCoverage: {
        english: 'Dependent(s) coverage',
        spanish: 'Dependiente(s) cobertura',
    },
    dollarAmount: {
        english: 'Dollar Amount',
        spanish: 'Monto en dólares',
    },
    percentage: {
        english: 'Percentage',
        spanish: 'Porcentaje',
    },
    salaryMultiple: {
        english: 'Salary Multiple',
        spanish: 'Salario Múltiple',
    },
    applicantElectionError: {
        english: 'Please select at least one applicant.',
        spanish: 'Seleccione al menos un solicitante.',
    },
    coverageElectionError: {
        english: 'Please select at least one coverage.',
        spanish: 'Seleccione al menos una cobertura.',
    },
    currentSelectionsCannotBeChanged: {
        english: 'Current selections cannot be changed at this time.',
        spanish: 'Las selecciones actuales no se pueden cambiar en este momento.',
    },
    duplicateApplicationModalText: {
        english: <>We are unable to process this application as <strong>our records indicate you have applied for this reason and coverage type within the last 120 days.</strong> Please contact your Benefits Administrator to confirm if another application is needed and if required, obtain a paper application.</>,
        spanish: <>No podemos procesar esta solicitud, <strong>ya que nuestros registros indican que la solicitó por este motivo y tipo de cobertura en los últimos 120 días.</strong> Comuníquese con su Administrador de Beneficios para confirmar si se necesita otra solicitud y, si es necesario, obtenga una solicitud en papel.</>,
    },
    duplicateApplicationModalTextEoiStatus: {
        english: <>We are unable to process this application as <strong>our records indicate you have applied for this reason and coverage type within the last 120 days.</strong> Please contact your Benefits Administrator to confirm if another application is needed and if required, obtain a paper application.  If you would like to view the status of your application, please allow up to 24 hours from the time of submission for status to update in My Lincoln Portal.</>,
        spanish: <>No podemos procesar esta solicitud, <strong>ya que nuestros registros indican que la solicitó por este motivo y tipo de cobertura en los últimos 120 días.</strong> Comuníquese con su Administrador de Beneficios para confirmar si se necesita otra solicitud y, si es necesario, obtenga una solicitud en papel. Si desea ver el estado de su solicitud, espere hasta 24 horas desde el momento de la presentación para que el estado se actualice en My Lincoln Portal.</>,
    },
    duplicateApplication: {
        english: 'Duplicate application',
        spanish: 'Solicitud duplicada',
    }
};
