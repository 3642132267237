import React, { createContext, useCallback } from 'react';
import useAuthentication from '../hooks/useAuthentication';
import useExperience from '../hooks/useExperience';
import LogRocket from 'logrocket';
import { getEnvironment, getEnvVariable } from '../utils/envUtils';
export const TealiumContext = createContext({ trackView: () => { }, trackEvent: () => { } });
const TealiumProvider = ({ children, }) => {
    const { user } = useAuthentication();
    const { experience } = useExperience();
    const environment = getEnvironment();
    const trackView = useCallback((pageViewParams) => {
        //@ts-ignore
        window.utag_data = {
            page_application: experience,
            page_env: environment,
            page_l1: pageViewParams.page_l1 || 'MLP',
            page_l2: pageViewParams.page_l2 || 'My Lincoln Portal',
            page_l3: pageViewParams.page_l3,
            page_l4: pageViewParams.page_l4 || `${experience} view`,
            page_l5: pageViewParams.page_l5,
            page_l6: pageViewParams.page_l6,
            page_name: pageViewParams.page_name || `${pageViewParams.page_l2 || 'My Lincoln Portal'} - ${pageViewParams.page_l4}`,
            page_plan_code: user?.customerId,
            user_audience: pageViewParams.user_audience || `My Lincoln Portal ${experience}`,
            user_id: pageViewParams.user_id || user?.webGUID,
            user_role: pageViewParams.user_role || user?.roles,
            source_system: pageViewParams.hasOwnProperty('source_system')
                ? pageViewParams.source_system
                : user?.customerInContext?.smallMid
                    ? 'Docsnet'
                    : 'S1',
            company_name: user?.customerDisplayName || user?.customerInternalName,
            transaction_id: pageViewParams.transaction_id,
            event_results: pageViewParams.event_results,
            utm_source: user?.isLfgUser,
        };
        // @ts-ignore
        if (!!window.utag) {
            // @ts-ignore
            window.utag.view(window.utag_data);
            //@ts-ignore
            const tealiumSessionId = window && window.utag_data && window.utag_data.tealium_session_id;
            if (!!tealiumSessionId && !!getEnvVariable('LOGROCKET_APP_ID')) {
                LogRocket.track(`Tealium Session ID: ${tealiumSessionId}`);
            }
        }
    }, [user, experience]);
    const trackEvent = useCallback((clickParams) => {
        // @ts-ignore
        if (!!window.utag) {
            // @ts-ignore
            window.utag.link({
                event_action: clickParams.event_action,
                event_name: clickParams.event_name,
                event_results: clickParams.event_results,
                event_search_terms: clickParams.event_search_terms,
                event_status: clickParams.event_status,
                event_type: clickParams.event_type,
                event_version: clickParams.event_version,
                page_application: experience,
                page_env: environment,
                page_plan_code: user?.customerId,
                user_id: clickParams.user_id || user?.webGUID,
                user_audience: `My Lincoln Portal ${experience}`,
                user_role: user?.roles,
                user_contact_method: clickParams.user_contact_method,
                event_result: clickParams.event_result,
                transaction_id: clickParams.transaction_id,
                // Should be on all events as well as page views
                page_l1: 'MLP',
                page_l2: 'My Lincoln Portal',
                page_l3: clickParams.page_l3,
                page_l4: clickParams.page_l4 || `${experience} view`,
                source_system: user?.customerInContext?.smallMid ? 'Docsnet' : 'S1',
                company_name: user?.customerDisplayName,
                form_result: clickParams.form_result,
            });
        }
    }, [user]);
    return <TealiumContext.Provider value={{ trackView, trackEvent }}>{children}</TealiumContext.Provider>;
};
export default TealiumProvider;
