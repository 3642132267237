import { phoneInvalidMessageMap, nonApprovedCountryMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidPhoneNumber, phoneIsNotBlacklisted } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidPhone(isRequired = false, fieldName = 'Phone number') {
    const errorMessageMap = [nonApprovedCountryMap, phoneInvalidMessageMap];
    const validators = [phoneIsNotBlacklisted, isValidPhoneNumber];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: fieldName, [Language.SPANISH]: 'Número de teléfono' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidPhone;
