import UserMigrationContainer from 'src/packages/userMigration/container/UserMigrationContainer';
import { Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import React from 'react';
import MigrationSuccessComponent from './components/MigrationSuccessComponent';
const UserMigration = () => {
    const [queryParams] = useSearchParams();
    return (<>
      <Routes>
        <Route path={`*`} element={<Outlet />}>
          <Route path={`*`} element={<UserMigrationContainer migrationTokenLookupKey={queryParams.get('token') || undefined} hasErrorInit={!!queryParams.get('error')} hasUsernameClash={!!queryParams.get('new-username')} hasEmailClash={!!queryParams.get('email-clash')}/>}/>
          <Route path={'success'} element={<MigrationSuccessComponent />}/>
        </Route>
      </Routes>
    </>);
};
export default UserMigration;
