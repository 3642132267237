export const PreferredContactInformationText = {
    contactInfoHeading: {
        english: 'Contact information',
        spanish: 'Información de contacto',
    },
    preferredPhoneQuestionLabel: {
        english: 'Phone number',
        spanish: 'Número de teléfono',
    },
    preferredEmailAddressQuestionLabel: {
        english: 'Email',
        spanish: 'Correo electrónico',
    }
};
