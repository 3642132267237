export const IMfaDisplayTextEs = {
    authy: 'Authy',
    authyHelpText: 'Nota: Para vincular su cuenta de My Lincoln Portal a la aplicación Authy, deberá asegurarse de configurar Authy con el mismo número de teléfono que ingresó aquí.',
    authyConfirmationText: 'Ingrese el código de verificación de su aplicación Authy.',
    step: 'Paso 3 de 3',
    mfaPreference: 'Preferencia de Autenticación Multifactorial',
    setMethodText: 'Lincoln Financial Group está comprometido con su seguridad. Cada vez que inicie sesión en el futuro, necesitará ingresar un código de seguridad para ingresar en su cuenta. Establezca el método por defecto abajo.',
    enrolledSetMethodText: 'Lincoln Financial Group está comprometido con su seguridad. Seleccione el método para recibir su código de seguridad.',
    email: 'Correo Electrónico',
    emailAddress: 'Dirección de correo electrónico',
    textMessage: 'Mensaje de Texto',
    phoneCall: 'Llamada Telefónica',
    phoneNumber: 'Número de Teléfono',
    thankYou: '¡Gracias!',
    phoneConfirmationText: 'Ingrese el código de verificación que se envió al <>.',
    submit: 'Enviar',
    continue: 'Continuar',
    tryAnotherMethod: 'Intente con otro método',
    selectAnOption: 'Seleccione un método',
    securityCode: 'Introduzca el código',
    genericError: 'Lo sentimos. Estamos experimentando dificultades técnicas en este momento. Si el problema persiste, póngase en contacto con el servicio técnico.',
    mfaRetreivalError: 'Lo sentimos. No podemos recuperar sus opciones de autenticación de dos factores en este momento. Si el problema persiste, póngase en contacto con el servicio técnico.',
    mfaSaveError: 'Lo sentimos. No podemos guardar la opción seleccionada en este momento. Si el problema persiste, póngase en contacto con el servicio técnico.',
    invalidCodeError: 'El código que ha escrito no es válido. Inténtelo de nuevo. Si hace más de 5 intentos incorrectos para escribir un código, se bloqueará su cuenta.',
    accountLockedError: 'Escribió demasiados códigos no válidos. Su cuenta estará bloqueada durante 30 minutos.',
    didntReceiveCode: '¿No recibió su código? ',
    havingTrouble: '¿Teniendo Problemas?',
    sendCode: 'Solicitar otro código',
    invalidEmailAlert: 'La dirección de correo electrónico que tenemos registrada para usted no está autorizada para fines de autenticación. Seleccione otro método de autenticación de dos factores para iniciar sesión. Para habilitar el correo electrónico como opción para el futuro, actualice la dirección de correo electrónico en su perfil de My Lincoln Portal con la dirección de correo electrónico que le dio su empleador.',
    dataAndMessagingDisclaimer: 'Se aplican cargos estándar por mensajes y consumo de datos.',
    updatePhoneNumberNote: 'Nota: Se actualizará su perfil con su nuevo número de teléfono.',
    rememberMyDevice: 'Recuerda mi dispositivo',
    nonApprovedCountryError: 'Este país no está aprobado para la autenticación de dos factores. Por favor contacte a asistencia técnica.',
    invalidPhoneError: '¡Uy! Este número de teléfono no es válido.',
};
