import React from 'react';
import { withRouter } from 'src/utils/withRouter';
import Sawmill from '../utils/Sawmill';
export const AuthenticationContext = React.createContext({
    isAuthenticated: false,
    loading: false,
    logout: () => { },
    user: undefined,
    error: undefined,
    setUser: () => { },
    register: () => { },
    deregister: () => { },
    authHeader: () => '',
    setCustomerInContext: (customerId, callbackUrl) => { },
    login: () => { },
    auth0State: undefined,
    getUserContext: () => Promise.resolve({}),
});
class AuthenticationProvider extends React.Component {
    static defaultProps = {
        getApi: () => { },
    };
    state = {
        isAuthenticated: false,
        loading: true,
        user: undefined,
        authStrategy: undefined,
        isPublic: true,
        auth0State: undefined,
        error: undefined,
    };
    async componentDidUpdate(prevProps, prevState) {
        const { authStrategy, user } = this.state;
        //Qualtrics VOC needs the isLfgUser field in session storage
        window.sessionStorage.setItem('isLfgUser', !!user?.isLfgUser + '');
        if (user?.isLfgUser && !!authStrategy) {
            await authStrategy.hasValidSession().catch(err => {
                console.log(`NO VALID SESSION - ${JSON.stringify(err)}`);
                authStrategy.logout();
            });
        }
        if (authStrategy !== prevState.authStrategy && !!authStrategy) {
            this.props.getApi({ logout: authStrategy.logout });
        }
        if (!!user) {
            Sawmill.configure({
                userInfo: {
                    username: user.userName,
                    customerId: user.customerId,
                    emailAddress: user.emailAddress,
                    roles: user.roles,
                    customerInContextId: user.customerInContext && user.customerInContext.customerId,
                },
            });
        }
    }
    setCustomerInContext = async (customerId, callbackUrl) => {
        const { authStrategy } = this.state;
        if (authStrategy) {
            const user = await authStrategy.setCustomerInContext(customerId);
            this.setState({ user }, () => {
                if (callbackUrl) {
                    if (callbackUrl.indexOf('http') !== -1) {
                        window.location.assign(callbackUrl);
                    }
                    else {
                        this.props.navigate(callbackUrl);
                    }
                }
            });
        }
    };
    render() {
        const { children } = this.props;
        const { authStrategy, loading } = this.state;
        const context = {
            ...this.state,
            setUser: (user) => this.setState({ user }),
            loading: !authStrategy || loading,
            logout: authStrategy ? authStrategy.logout : () => { },
            register: this.handleRegistration,
            deregister: this.handleDeregistration,
            setCustomerInContext: this.setCustomerInContext,
            login: authStrategy ? authStrategy.login : () => { },
            refreshToken: authStrategy ? authStrategy.refreshToken : () => Promise.resolve({}),
            getUserContext: authStrategy ? authStrategy.getUserContext : () => Promise.resolve({}),
            authHeader: () => `Bearer ${sessionStorage.getItem('mlpBearerToken') || ''}`,
        };
        return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>;
    }
    handleRegistration = async (authStrategy) => {
        if (!authStrategy.refreshToken) {
            this.setState({ loading: true });
        }
        try {
            if (!!authStrategy.init) {
                await authStrategy.init();
            }
            const authenticated = await authStrategy.getIsAuthenticated();
            if (!authStrategy.isPublic || (authStrategy.isPublic && authenticated)) {
                const profile = await authStrategy.getUserContext();
                this.setState({ user: profile });
                const isAuthenticated = await authStrategy.getIsAuthenticated();
                this.setState({ isAuthenticated });
            }
            if (authStrategy.getAuth0State) {
                const auth0State = await authStrategy.getAuth0State();
                this.setState({ auth0State });
            }
        }
        catch (e) {
            this.setState({ error: e });
            this.props.authorizeUserErrorCallback(e);
        }
        finally {
            this.setState({ authStrategy, loading: false });
        }
    };
    handleDeregistration = () => {
        this.setState({ authStrategy: undefined });
    };
}
export default withRouter(AuthenticationProvider);
