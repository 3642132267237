import { Col, Heading, Row, SelectInput, TextInput } from '@digitools/honest-react';
import { useFormApi } from 'informed';
import React, { useEffect, useState } from 'react';
import { formatZipCode, isNotEmpty, validatePostalCode, validateStreet, validateAddressCity, } from '../../../../utils/validationUtils';
import { LCI_EMPLOYER_COUNTRIES, LCI_EMPLOYER_PROVINCES, LCI_EMPLOYER_STATES, MAX_LENGTH_CITY, } from '../../constants/LCIConstant';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
const Address = labelProps => {
    const { city = 'City', country = 'Country', field, postalcode = 'Postal Code', province = 'Province', required = false, state = 'State', street1 = 'Residential Street Address 1', street2 = 'Residential Street Address 2', title, isStateRequired = required, } = labelProps;
    const [showState, setShowState] = useState(true);
    const postalCodeLength = showState ? 5 : 35;
    const noValidation = (value) => {
        return undefined;
    };
    const formApi = useFormApi();
    const State = showState ? (<SelectInput ariaLabelText={state} field={field + '_state'} label={isStateRequired ? (state) : (`${state} (optional)`)} placeholderText={isStateRequired ? 'Select a state' : 'Select a state (optional)'} options={LCI_EMPLOYER_STATES} validate={isStateRequired ? isNotEmpty : noValidation} validateOnBlur={isStateRequired} type={'text'}/>) : null;
    const Province = !showState ? (<SelectInput ariaLabelText={province} field={field + '_province'} label={isStateRequired ? (province) : (`${province} (optional)`)} options={LCI_EMPLOYER_PROVINCES} placeholderText={isStateRequired ? 'Select a province' : 'Select a province (optional)'} validate={isStateRequired ? isNotEmpty : noValidation} validateOnBlur={isStateRequired}/>) : null;
    const noFormatting = (value) => {
        return value;
    };
    const handleCountryChange = (value, field) => {
        if (value === 'USA') {
            setShowState(true);
        }
        else if (value === 'CAN') {
            setShowState(false);
        }
    };
    useEffect(() => {
        const postCode = field + '_postalcode';
        formApi.setValue(postCode, '');
    }, [postalCodeLength]);
    const postalCodeUsa = showState ? (<TextInput ariaLabelText={postalcode} field={field + '_postalcode'} label={required ? (postalcode) : (`${postalcode} (optional)`)} mask={formatZipCode} maxLength={postalCodeLength} type={'text'} validate={validatePostalCode(true, required)} validateOnBlur={required}/>) : null;
    const postalCodeCanada = !showState ? (<TextInput ariaLabelText={postalcode} field={field + '_postalcode'} label={required ? (postalcode) : (`${postalcode} (optional)`)} mask={noFormatting} maxLength={postalCodeLength} type={'text'} validate={validatePostalCode(false, required)} validateOnBlur={required}/>) : null;
    return (<>
      <Row style={{ marginTop: 0 }}>
        <Col>
          <BorderLessGrayBox>
            <Heading elemType='h3' elemStyle='h4' id={field + '_headingTitle'}>
              {title}
            </Heading>
          </BorderLessGrayBox>
        </Col>
      </Row>
      <Row>
      <Col md={6} xs={12}>
          <TextInput ariaLabelText={street1} field={field + '_street1'} label={required ? (street1) : (`${street1} (optional)`)} type={'text'} validate={(value) => validateStreet(value, 'Address Line 1', required)} validateOnBlur={true}/>
        </Col>
      <Col md={6} xs={12}>
        <TextInput ariaLabelText={street2} field={field + '_street2'} label={`${street2} (optional)`} type={'text'} validate={(value) => validateStreet(value, 'Address Line 2', false)} validateOnBlur={true}/>
        </Col>
        <Col md={6}>
          <TextInput ariaLabelText={city} field={field + '_city'} label={required ? (city) : (`${city} (optional)`)} maxLength={MAX_LENGTH_CITY} type={'text'} validate={(value) => validateAddressCity(value, required)} validateOnBlur={true}/>
        </Col>
        <Col md={6} xs={12}>
          {State} {Province}
        </Col>
        <Col md={6}>
          {postalCodeUsa}
          {postalCodeCanada}
        </Col>
        <Col md={6} xs={12}>
          <SelectInput ariaLabelText={country} field={field + '_country'} label={required ? (country) : (`${country} (optional)`)} placeholderText={required ? 'Select a country' : 'Select a country (optional)'} options={LCI_EMPLOYER_COUNTRIES} validate={required ? isNotEmpty : noValidation} onValueChange={(value) => handleCountryChange(value, field)} validateOnBlur={required} validateOnChange={true}/>
        </Col>
      </Row>
    </>);
};
export default Address;
