import React, { useEffect, useState } from 'react';
import { ICoverageType } from '../../providers/EoiProvider';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import CheckboxGroup from '@digitools/honest-react/dist/lib/components/Checkbox/CheckboxGroup';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
// @ts-ignore
import { Scope, useFormApi, useFormState } from 'informed';
import { BorderLessGrayBox } from '../../../../components/Formatting/StyledComponents';
import useTranslate from '../../../../hooks/useTranslate';
import { ApplicantCoverageText } from '../../i18n/ApplicantCoverageText';
import { coverageAmountValidation, coverageTypeValidation, validateCoverageElection, } from '../../utils/FormFieldValidations';
import { CoveragePageText } from '../../i18n/EoiCoverageText';
import { colorOrder, displayProductNote, getCoverageIcon, sortProducts } from '../../utils/HelperFunctions';
import useEoiContext from '../../hooks/useEoiContext';
const InnerContent = ({ identifier, applicant }) => {
    const formState = useFormState();
    const { t } = useTranslate();
    const { interviewAnswers, initialInformation } = useEoiContext();
    const [displayCoverageContent, setDisplayCoverageContent] = useState(true);
    const [enterPrePopCoverageAmounts, setEnterPrePopCoverageAmounts] = useState(false);
    useEffect(() => {
        if (initialInformation &&
            initialInformation.employerConfiguration.preference.displayProductAmountsIndicator === false) {
            setDisplayCoverageContent(false);
        }
        else {
            if (interviewAnswers.coverageElections.prePopCoverage) {
                // When no prepop amounts are included and Display Amounts is True, user should be required to enter amounts
                // @ts-ignore
                const prePopCoverage = interviewAnswers.coverageElections[`${applicant}Coverage`] &&
                    interviewAnswers
                        .coverageElections[`${applicant}Coverage`].filter((cov) => cov.coverageCode === identifier)
                        .shift();
                // @ts-ignore
                setEnterPrePopCoverageAmounts(!prePopCoverage.currentCoverageAmount && !prePopCoverage.requestedCoverageAmount);
            }
        }
    }, []);
    // This wasn't working w/ useEffect and useState.
    // @ts-ignore
    const coverage = interviewAnswers.coverageElections[`${applicant}Coverage`] &&
        interviewAnswers
            .coverageElections[`${applicant}Coverage`].filter((cov) => cov.coverageCode === identifier)
            .shift();
    const requestedAmountType = coverage && coverage.requestedCoverageType;
    const requestedAmount = coverage && coverage.requestedCoverageAmount;
    const currentAmountType = coverage && coverage.currentCoverageType;
    const currentAmount = coverage && coverage.currentCoverageAmount;
    const coverageTypes = [
        {
            label: t(ApplicantCoverageText.dollarAmount),
            value: ICoverageType.AMOUNT,
        },
        {
            label: t(ApplicantCoverageText.percentage),
            value: ICoverageType.PERCENTAGE,
        },
        {
            label: t(ApplicantCoverageText.salaryMultiple),
            value: ICoverageType.MULTIPLE,
        },
    ];
    // @ts-ignore
    const currentCoverageTypeValue = formState.values[`${applicant}Coverage`] &&
        formState.values[`${applicant}Coverage`][identifier] &&
        formState.values[`${applicant}Coverage`][identifier]['currentCoverageType'];
    // @ts-ignore
    const requestedCoverageTypeValue = formState.values[`${applicant}Coverage`] &&
        formState.values[`${applicant}Coverage`][identifier] &&
        formState.values[`${applicant}Coverage`][identifier]['requestedCoverageType'];
    return displayCoverageContent ? (
    // @ts-ignore
    <Scope scope={identifier}>
      <div className={'ml-0 ml-md-5'} style={{ marginBottom: '.75rem' }} data-testid={`${applicant}-${identifier}-inner-content`}>
        <span style={{ fontSize: '.875rem' }}>{t(CoveragePageText.currentSelections)}</span>
        <div className={'d-block d-md-flex flex-column flex-md-row'} style={{ marginTop: '.75rem' }}>
          {/* TODO: fill these with actual data coming from... somewhere (SSO/FILE feed stuff wherever that ends up in the provider) */}
          <div className={'w-100 col-md-4 mr-md-3'}>
            <SelectInput data-testid={`${applicant}-${identifier}-inner-content-current-type`} field={'currentCoverageType'} label={t(CoveragePageText.coverageTypeLabel)} aria-label={t(CoveragePageText.coverageTypeLabel)} options={coverageTypes} theme={themes.lincoln} validateOnChange={true} validateOnBlur={true} placeholderText={t(CoveragePageText.coverageTypePlaceholder)} 
    // Do not validate if the coverages are pre populated.
    validate={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts
            ? () => { }
            : coverageTypeValidation} disabled={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts} initialValue={currentAmountType}/>
          </div>
          <div className={'w-100 col-md-4 mr-md-3'}>
            <TextInput data-testid={`${applicant}-${identifier}-inner-content-current-amount`} field={'currentCoverageAmount'} label={t(CoveragePageText.coverageAmountLabel)} aria-label={t(CoveragePageText.coverageAmountLabel)} theme={themes.lincoln} 
    //@ts-ignore //FIXME: typing error
    placeholderText={t(CoveragePageText.coverageAmountPlaceholder)} disabled={(interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts)} validateOnChange={true} validateOnBlur={true} 
    // Do not validate if the coverages are pre populated.
    validate={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts
            ? () => { }
            : (value) => coverageAmountValidation(value, currentCoverageTypeValue)} initialValue={currentAmount !== undefined ? Number(currentAmount).toFixed(2) : undefined}/>
          </div>
        </div>



        <span style={{ fontSize: '.875rem' }}>{t(CoveragePageText.requestingNew)}</span>
        <div className={'d-block d-md-flex flex-column flex-md-row'} style={{ marginTop: '.75rem' }}>
          {/* TODO: fill these with actual data coming from... somewhere (SSO/FILE feed stuff wherever that ends up in the provider) */}
          <div className={'w-100 col-md-4 mr-md-3'}>
            <SelectInput data-testid={`${applicant}-${identifier}-inner-content-requested-type`} field={'requestedCoverageType'} label={t(CoveragePageText.coverageTypeLabel)} aria-label={t(CoveragePageText.coverageTypeLabel)} options={coverageTypes} theme={themes.lincoln} placeholderText={t(CoveragePageText.coverageTypePlaceholder)} validateOnChange={true} validateOnBlur={true} validate={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts
            ? () => { }
            : coverageTypeValidation} disabled={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts} initialValue={requestedAmountType}/>
          </div>
          <div className={'w-100 col-md-4 mr-md-3'}>
            <TextInput data-testid={`${applicant}-${identifier}-inner-content-requested-amount`} field={'requestedCoverageAmount'} label={t(CoveragePageText.coverageAmountLabel)} aria-label={t(CoveragePageText.coverageAmountLabel)} theme={themes.lincoln} placeholder={t(CoveragePageText.coverageAmountPlaceholder)} disabled={(interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts)} validateOnChange={true} validateOnBlur={true} validate={interviewAnswers.coverageElections.prePopCoverage && !enterPrePopCoverageAmounts
            ? () => { }
            : (value) => coverageAmountValidation(value, requestedCoverageTypeValue)} initialValue={(!requestedAmount ? requestedAmount : Number(requestedAmount)?.toFixed(2))}/>
          </div>
        </div>
      </div>
    </Scope>) : (<></>);
};
const CoverageSelect = ({ applicant }) => {
    const { t } = useTranslate();
    const formState = useFormState();
    const formApi = useFormApi();
    const { initialInformation, interviewAnswers } = useEoiContext();
    const [displayNote, setDisplayNote] = useState(false);
    useEffect(() => {
        const employeeCoverages = initialInformation &&
            initialInformation.employerConfiguration &&
            initialInformation.employerConfiguration.employeeProducts &&
            initialInformation.employerConfiguration.employeeProducts.map(product => ({ coverageCode: product.productCode }));
        setDisplayNote(applicant === 'employee' && displayProductNote(employeeCoverages ? employeeCoverages : [], initialInformation));
    }, []);
    if (initialInformation && initialInformation.employerConfiguration) {
        let products = [];
        // @ts-ignore
        const prePopProducts = interviewAnswers.coverageElections[`${applicant}Coverage`].map(coverage => `${coverage.coverageCode}Checkbox`);
        switch (applicant) {
            case 'employee':
                products = sortProducts(initialInformation.employerConfiguration.employeeProducts);
                break;
            case 'spouse':
                products = sortProducts(initialInformation.employerConfiguration.spouseProducts);
                break;
            case 'family':
                products = sortProducts(initialInformation.employerConfiguration.familyProducts);
                break;
            case 'dependent':
                products = sortProducts(initialInformation.employerConfiguration.dependentProducts);
                break;
        }
        return (<>
        <CheckboxGroup label={t(ApplicantCoverageText.coverageElectionSubHeading)} aria-label={t(ApplicantCoverageText.coverageElectionSubHeading)} field={`${applicant}CoverageSelections`} validateOnBlur={true} validateOnChange={true} hideLabel={true} 
        // Do not validate if the coverages are pre populated.
        validate={interviewAnswers.coverageElections.prePopCoverage
                ? () => { }
                : (values) => validateCoverageElection(values, applicant, formState, formApi)} style={{ marginBottom: '1.5rem' }} initialValue={prePopProducts}>
          {products.map((product, i) => {
                const preFilled = prePopProducts.indexOf(`${product.productCode}Checkbox`) > -1;
                // @ts-ignore
                const color = colorOrder[i % 3];
                return (<div style={{ margin: '1rem 0 1rem 0' }} key={`${applicant}-${product.productCode}`}>
                <BorderLessGrayBox style={{
                        marginRight: '1.5rem',
                        backgroundColor: 'white',
                        // @ts-ignore
                        borderLeft: `3px solid ${themes.lincoln.palette[color]}`,
                    }}>
                  <div>
                    <Checkbox data-testid={`${applicant}-${product.productCode}`} theme={themes.lincoln} label={<>
                          {getCoverageIcon(product.productCode, color)} {t(product.text)}
                        </>} aria-label={t(product.text)} field={`${product.productCode}Checkbox`} disabled={interviewAnswers.coverageElections.prePopCoverage} initialValue={preFilled}/>
                    {(product.productCode === 'CIE' || product.productCode === 'CIS') && (<div style={{ fontSize: '80%', marginLeft: '1.5rem', color: 'gray' }}>
                        <em>{t(CoveragePageText.criticalIllnessDisclaimer)}</em>
                      </div>)}
                  </div>
                  {preFilled && interviewAnswers.coverageElections.prePopCoverage && (<div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0 }}>
                      <em>{t(ApplicantCoverageText.currentSelectionsCannotBeChanged)}</em>
                      <Icon name={'lock'} color={'grayDarker'} style={{ marginLeft: '0.5rem' }}/>
                    </div>)}
                </BorderLessGrayBox>
                {
                    // @ts-ignore
                    formState.values[`${applicant}Coverage`] &&
                        formState.values[`${applicant}Coverage`][`${product.productCode}Checkbox`] && (<InnerContent identifier={product.productCode} applicant={applicant}/>)}
              </div>);
            })}
        </CheckboxGroup>
        {displayNote && (<span style={{ marginLeft: '1rem' }} data-testid={'product-note'}>
            <strong>
              <em>{t(initialInformation.employerConfiguration.productNote)}</em>
            </strong>
          </span>)}
      </>);
    }
    else {
        return <>Some kind of error?</>;
    }
};
export default CoverageSelect;
