export const findMyInformationDisplayTextEn = {
    dateOfBirthLabel: 'Date of birth',
    dateOfBirthPlaceholder: 'mm/dd/yyyy',
    emailLabel: 'Preferred email address',
    employeeIdLabel: 'Employee ID',
    fieldRequired: 'This field is required.',
    findButton: 'Continue',
    firstNameLabel: 'First name',
    FmiHeading: 'Verify my identity',
    FmiText: 'In order to ensure a secure experience for our customers, you are required to complete a one-time identity verification by completing all fields below.',
    invalidSSN: 'Social security number is not valid.',
    lastNameLabel: 'Last name',
    ManageBensHeading: 'My beneficiaries',
    postalCodeLabel: 'Postal code',
    ssnLabel: 'Social security number',
    infoMessage: 'Something went wrong. Please try again.',
    noIdFoundErrorMessage: 'No ID was found matching your request. Please check your information and try again.'
};
