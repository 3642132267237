import React from 'react';
import { Row, Col, themes, Heading, Label } from '@digitools/honest-react';
import WorkLocationModalView from './work-location-model-view/workLocationModalView';
import { workStatesModel, workProvincesModel, } from '../../filter-my-report/work-location-model/initialStateAndProvinces';
import { totalStatesAndLocations } from '../../filter-my-report/FilterMyReportValidation';
import EditButton from './EditButton';
const StatesAndProvincesDetails = ({ history, employeeWorkLocationLabel, workStates, workProvinces, }) => {
    const totalSelectedStatesAndProvinces = totalStatesAndLocations(workStates, workProvinces);
    const workStatesModalCount = workStatesModel.filter((workStates) => workStates.stateCode !== 'AWS')
        .length;
    const workProvincesModalCount = workProvincesModel.filter((workLocation) => workLocation.provinceCode !== 'AWL').length;
    const countOfStatesAndProvinces = workStatesModalCount + workProvincesModalCount;
    return (<div style={{ marginTop: '1.5rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }} data-testid={'section-div'}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {`${employeeWorkLocationLabel} work location`}
        </Heading>
        <Row>
          <Col>
            <Label style={{ marginLeft: '1rem' }}>
              {' '}
              {totalSelectedStatesAndProvinces === countOfStatesAndProvinces
            ? 'All'
            : totalSelectedStatesAndProvinces}{' '}
              selected{' '}
            </Label>
          </Col>
        </Row>
        <WorkLocationModalView prevSelectedStates={workStates} prevSelectedProvinces={workProvinces}/>
        <EditButton history={history} path='/create-my-report'/>
      </div>
      <hr />
    </div>);
};
export default StatesAndProvincesDetails;
