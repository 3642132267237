export const fieldsInitialState = {
    personalInfoFields: [
        { id: '1', fieldLabel: 'All', field: 'AllPersonalInfo', status: false, hoverText: '' },
        {
            id: 'employeeBirthDate',
            fieldLabel: '{EmpCustomContent} Birth Date',
            field: 'Employee Birth Date',
            status: false,
            hoverText: 'The date of birth of the {EmpCustomContent}',
        },
        {
            id: 'employeeFirstName',
            fieldLabel: '{EmpCustomContent} First Name',
            field: 'First Name',
            status: false,
            hoverText: 'The first name of the {EmpCustomContent}',
        },
        {
            id: 'employeeId',
            fieldLabel: '{EmpIdCustomContent}',
            field: 'Employee ID',
            status: false,
            hoverText: 'A unique identifier defined by the employer for each {EmpCustomContent}',
        },
        {
            id: 'employeeLastName',
            fieldLabel: '{EmpCustomContent} Last Name',
            field: 'Last Name',
            status: false,
            hoverText: 'The last name of the {EmpCustomContent}',
        },
        {
            id: 'employeeSsn',
            fieldLabel: '{EmpCustomContent} SSN',
            field: 'Employee SSN',
            status: false,
            hoverText: 'The Social Security number of the {EmpCustomContent}',
        },
        {
            id: 'employeeGender',
            fieldLabel: 'Sex Assigned at Birth',
            field: 'Gender',
            status: false,
            hoverText: 'The sex assigned at birth of the {EmpCustomContent}',
        },
    ],
    workInfoFields: [
        { id: '1', fieldLabel: 'All', field: 'AllWorkInfo', status: false, hoverText: '' },
        {
            id: 'employeeHireDate',
            fieldLabel: '{EmpCustomContent} Hire Date',
            field: 'Hire Date',
            status: false,
            hoverText: 'The date the {EmpCustomContent} was hired',
        },
        {
            id: 'workLocation',
            fieldLabel: '{EmpCustomContent} Work Location',
            field: 'Employee Work Location',
            status: false,
            hoverText: '{EmpCustomContent}’s primary work location.',
        },
        {
            id: 'supervisorFirstName',
            fieldLabel: 'Supervisor First Name',
            field: 'Supervisor First Name',
            status: false,
            hoverText: "First name of the {EmpCustomContent}'s supervisor",
        },
        {
            id: 'supervisorLastName',
            fieldLabel: 'Supervisor Last Name',
            field: 'Supervisor Last Name',
            status: false,
            hoverText: "Last name of the {EmpCustomContent}'s supervisor",
        },
        {
            id: 'supervisorPhoneNumber',
            fieldLabel: 'Supervisor Phone Number',
            field: 'Supervisor Phone Number',
            status: false,
            hoverText: "Phone number of the {EmpCustomContent}'s supervisor",
        },
    ],
    claimDatesFields: [
        { id: '1', fieldLabel: 'All', field: 'AllClaimDates', status: false, hoverText: '' },
        {
            id: 'benefitBeginDate',
            fieldLabel: 'Benefit Begin Date',
            field: 'Benefit Begin Date',
            status: false,
            hoverText: 'The date the benefit begins',
        },
        {
            id: 'claimClosedDate',
            fieldLabel: 'Closed Date',
            field: 'Closed Date',
            status: false,
            hoverText: 'The date the claim or leave is closed',
        },
        {
            id: 'completeClaimDate',
            fieldLabel: 'Complete Claim Date',
            field: 'Complete Claim Date',
            status: false,
            hoverText: 'The date employer, employee and medical information (including clinical reviews) have all been received to make the initial decision.',
        },
        {
            id: 'claimDeterminationDate',
            fieldLabel: 'Determination Date',
            field: 'Determination Date',
            status: false,
            hoverText: 'The date that a determination was made for status of approved, closed, or denied',
        },
        {
            id: 'disabilityApprovedThroughDate',
            fieldLabel: 'Disability Approved Through Date',
            field: 'Disability Approved Through Date',
            status: false,
            hoverText: 'The end date of the approved period for the disability claim',
        },
        {
            id: 'disabilityDate',
            fieldLabel: 'Disability Date',
            field: 'Disability Date',
            status: false,
            hoverText: 'The date the Claimant became disabled',
        },
        {
            id: 'claimLastDayWorked',
            fieldLabel: 'Last Day Worked',
            field: 'Last Day Worked',
            status: false,
            hoverText: 'The last day the {EmpCustomContent} worked before going out on claim or leave',
        },
        {
            id: 'maxBenefitDate',
            fieldLabel: 'Max Benefit Date',
            field: 'Max Benefit Date',
            status: false,
            hoverText: 'The date the benefit ends',
        },
        {
            id: 'claimReceivedDate',
            fieldLabel: 'Received Date',
            field: 'Received Date',
            status: false,
            hoverText: 'The date the claim or leave is received by Lincoln Financial Group',
        },
        {
            id: 'reopenDate',
            fieldLabel: 'Reopen Date',
            field: 'Reopen Date',
            status: false,
            hoverText: 'The date the claim reopened',
        },
        {
            id: 'returnToWorkDate',
            fieldLabel: 'Return to Work Date',
            field: 'Return to Work Date',
            status: false,
            hoverText: 'Date the {EmpCustomContent} returned to work',
        },
    ],
    claimDetailFields: [
        { id: '1', fieldLabel: 'All', field: 'AllClaimDetails', status: false, hoverText: '' },
        {
            id: 'ageAtOnset',
            fieldLabel: 'Age at Onset',
            field: 'Age at Onset',
            status: false,
            hoverText: 'The age of the claimant at the onset of the disability',
        },
        {
            id: 'claimCaseManager',
            fieldLabel: 'Case Manager',
            field: 'Case Manager',
            status: false,
            hoverText: 'The Lincoln Financial Group case manager assigned to the claim',
        },
        {
            id: 'claimNumber',
            fieldLabel: 'Claim Number',
            field: 'Claim Number',
            status: true,
            hoverText: 'A unique number defined by Lincoln Financial Group assigned to a claim',
        },
        {
            id: 'classCode',
            fieldLabel: 'Class Code',
            field: 'Class Code',
            status: false,
            hoverText: 'A unique code created by Lincoln Financial Group for each customer class of benefits',
        },
        {
            id: 'classDescription',
            fieldLabel: 'Class Description',
            field: 'Class Description',
            status: false,
            hoverText: 'The eligible {EmpCustomContent}s class description',
        },
        {
            id: 'closedClaimReason',
            fieldLabel: 'Closed Claim Reason',
            field: 'Closed Claim Reason',
            status: false,
            hoverText: 'The reason the claim or leave was closed',
        },
        {
            id: 'locationCode',
            fieldLabel: 'Location Code',
            field: 'Location Code',
            status: true,
            hoverText: 'A unique identifier assigned to each location defined by the customer',
        },
        {
            id: 'locationName',
            fieldLabel: 'Location Name',
            field: 'Location Name',
            status: true,
            hoverText: 'A name defined by the customer for each location identified for the purposes of claim and leave loss reporting',
        },
        {
            id: 'pendedClaimReason',
            fieldLabel: 'Pended Claim Reason',
            field: 'Pended Claim Reason',
            status: false,
            hoverText: 'The reason the claim or leave is pended',
        },
        {
            id: 'claimProduct',
            fieldLabel: 'Product',
            field: 'Product',
            status: true,
            hoverText: 'The product(s) covered within the customer contract or customer plan document(s) established between Lincoln Financial Group and the customer',
        },
        {
            id: 'reopenClaimReason',
            fieldLabel: 'Reopen Claim Reason',
            field: 'Reopen Claim Reason',
            status: false,
            hoverText: 'The reason that the claim reopened',
        },
        { id: 'claimStatus', fieldLabel: 'Status', field: 'Status', status: true, hoverText: 'The status of the claim' },
        {
            id: 'subsidiaryCode',
            fieldLabel: 'Subsidiary Code',
            field: 'Subsidiary Code',
            status: true,
            hoverText: 'A unique identifier assigned to each subsidiary defined by the customer',
        },
        {
            id: 'subsidiaryName',
            fieldLabel: 'Subsidiary Name',
            field: 'Subsidiary Name',
            status: true,
            hoverText: 'A name defined by the customer for each subsidiary identified for the purposes of claim and leave loss reporting',
        },
        {
            id: 'workRelatedInjury',
            fieldLabel: 'Work Related Injury',
            field: 'Work Related Injury',
            status: false,
            hoverText: 'Indicates if the injury occurred at work',
        },
    ],
    leaveDatesFields: [
        { id: '1', fieldLabel: 'All', field: 'AllLeaveDates', status: false, hoverText: '' },
        {
            id: 'certifiedThroughDate',
            fieldLabel: 'Certified Through Date',
            field: 'Certified Through Date',
            status: false,
            hoverText: 'The date that certification of the leave will end.',
        },
        {
            id: 'leaveClosedDate',
            fieldLabel: 'Closed Date',
            field: 'Closed Date',
            status: false,
            hoverText: 'The date the claim or leave is closed',
        },
        {
            id: 'leaveDeterminationDate',
            fieldLabel: 'Determination Date',
            field: 'Determination Date',
            status: false,
            hoverText: 'Date that a determination for DE, CL, AP was made.',
        },
        {
            id: 'leaveLastDayWorked',
            fieldLabel: 'Last Day Worked',
            field: 'Last Day Worked',
            status: false,
            hoverText: 'The last day the employee worked before going out on claim or leave',
        },
        {
            id: 'leaveBeginDate',
            fieldLabel: 'Leave Begin Date',
            field: 'Leave Begin Date',
            status: false,
            hoverText: 'The date the leave began',
        },
        {
            id: 'leaveEndDate',
            fieldLabel: 'Leave End Date',
            field: 'Leave End Date',
            status: false,
            hoverText: 'Date the leave ended',
        },
        {
            id: 'leaveReceivedDate',
            fieldLabel: 'Received Date',
            field: 'Received Date',
            status: false,
            hoverText: 'Customer-defined claim loss reporting identifier.',
        },
    ],
    leaveDetailFields: [
        { id: '1', fieldLabel: 'All', field: 'AllLeaveDetails', status: false, hoverText: '' },
        {
            id: 'coordClaimNumber',
            fieldLabel: 'Coordinated Claim Number',
            field: 'Coordinated Claim Number',
            status: false,
            hoverText: 'Displays the STD claim number for a coordinated Leave/STD claim',
        },
        {
            id: 'leaveCategory',
            fieldLabel: 'Leave Category',
            field: 'Leave Category',
            status: false,
            hoverText: 'Indicates whether the leave is intermittent or continuous. Admin value indicates whether that leave type/reason permits only continuous leaves or both (intermittent and continuous)',
        },
        {
            id: 'leaveNumber',
            fieldLabel: 'Leave Number',
            field: 'Leave Number',
            status: true,
            hoverText: 'The system-assigned leave number',
        },
        {
            id: 'leaveReason',
            fieldLabel: 'Leave Reason',
            field: 'Leave Reason',
            status: false,
            hoverText: 'The reason that the leave was taken',
        },
        {
            id: 'leaveSpecialist',
            fieldLabel: 'Leave Specialist',
            field: 'Leave Specialist',
            status: false,
            hoverText: 'The leave specialist assigned to the leave',
        },
        {
            id: 'leaveStatusReason',
            fieldLabel: 'Leave Status Reason',
            field: 'Leave Status Reason',
            status: false,
            hoverText: 'Provides additional description of the leave status. Primarily used for closed or denied leaves',
        },
        {
            id: 'leaveType',
            fieldLabel: 'Leave Type',
            field: 'Leave Type',
            status: false,
            hoverText: "Indicates whether the leave is for the {EmpCustomContent}'s own disability, care of a seriously ill family member, bonding with a child, or other",
        },
        {
            id: 'locationCode',
            fieldLabel: 'Location Code',
            field: 'Location Code',
            status: true,
            hoverText: 'A unique identifier assigned to each location defined by the customer',
        },
        {
            id: 'locationName',
            fieldLabel: 'Location Name',
            field: 'Location Name',
            status: true,
            hoverText: 'A name defined by the customer for each location identified for the purposes of claim and leave loss reporting',
        },
        {
            id: 'leaveProduct',
            fieldLabel: 'Product',
            field: 'Product',
            status: true,
            hoverText: 'The product(s) covered within the customer contract or customer plan document(s) established between Lincoln Financial Group and the customer',
        },
        {
            id: 'leaveStatus',
            fieldLabel: 'Status',
            field: 'Status',
            status: true,
            hoverText: 'The status of the Leave:  In Process, Pended, Approved, Closed or Denied',
        },
        {
            id: 'subsidiaryCode',
            fieldLabel: 'Subsidiary Code',
            field: 'Subsidiary Code',
            status: true,
            hoverText: 'A unique identifier assigned to each subsidiary defined by the customer',
        },
        {
            id: 'subsidiaryName',
            fieldLabel: 'Subsidiary Name',
            field: 'Subsidiary Name',
            status: true,
            hoverText: 'A name defined by the customer for each subsidiary identified for the purposes of claim and leave loss reporting',
        },
    ],
    accommodationDatesFields: [
        { id: '1', fieldLabel: 'All', field: 'AllASDates', status: false, hoverText: '' },
        {
            id: 'completeInfoDate',
            fieldLabel: 'Complete Info Date',
            field: 'Complete Info Date',
            status: false,
            hoverText: 'The date information is complete',
        },
        {
            id: 'erDecisionDate',
            fieldLabel: 'ER Decision Date',
            field: 'ER Decision Date',
            status: false,
            hoverText: 'The date that a decision was made for an accommodation',
        },
        {
            id: 'eventReceivedDate',
            fieldLabel: 'Event Received Date',
            field: 'Event Received Date',
            status: false,
            hoverText: 'The date the event was received',
        },
        {
            id: 'fullDutyRtwDate',
            fieldLabel: 'Full Duty RTW Date',
            field: 'Full Duty RTW Date',
            status: false,
            hoverText: 'The date of full time return to work',
        },
        {
            id: 'interactiveBeginDate',
            fieldLabel: 'Interactive Begin Date',
            field: 'Interactive Begin Date',
            status: false,
            hoverText: 'The begin date of the interactive process',
        },
        {
            id: 'providedEndDate',
            fieldLabel: 'Provided End Date',
            field: 'Provided End Date',
            status: false,
            hoverText: 'The provided end date for the accommodation',
        },
        {
            id: 'providedStartDate',
            fieldLabel: 'Provided Start Date',
            field: 'Provided Start Date',
            status: false,
            hoverText: 'The provided start date for the accommodation',
        },
        {
            id: 'requestedEndDate',
            fieldLabel: 'Requested End Date',
            field: 'Requested End Date',
            status: false,
            hoverText: 'The request end date for the accommodation',
        },
        {
            id: 'requestedStartDate',
            fieldLabel: 'Requested Start Date',
            field: 'Requested Start Date',
            status: false,
            hoverText: 'The request start date for the accommodation',
        },
    ],
    accommodationDetailFields: [
        { id: '1', fieldLabel: 'All', field: 'AllASDetails', status: false, hoverText: '' },
        {
            id: 'accommodationType',
            fieldLabel: 'Accommodation Type',
            field: 'Accommodation Type',
            status: false,
            hoverText: 'The type of accommodation requested',
        },
        {
            id: 'approvedAccommodationCost',
            fieldLabel: 'Approved Accommodation Cost',
            field: 'Approved Accommodation Cost',
            status: false,
            hoverText: 'Actual cost associated with the accommodation that was provided',
        },
        {
            id: 'asCaseManager',
            fieldLabel: 'Case Manager',
            field: 'Case Manager',
            status: false,
            hoverText: 'The Lincoln Financial Group case manager assigned to the Accommodation',
        },
        {
            id: 'eventNumber',
            fieldLabel: 'Event Number',
            field: 'Event Number',
            status: true,
            hoverText: 'A unique number defined by Lincoln Financial Group assigned to an accommodation event',
        },
        {
            id: 'linkedClaims',
            fieldLabel: 'Linked Claims',
            field: 'Linked Claims',
            status: true,
            hoverText: 'Indicates when the accommodation is linked with a claim',
        },
        {
            id: 'permanent',
            fieldLabel: 'Permanent (y/n)',
            field: 'Permanent (y/n)',
            status: false,
            hoverText: 'Indicates when the requested accommodation is permanent',
        },
        {
            id: 'asProduct',
            fieldLabel: 'Product',
            field: 'Product',
            status: true,
            hoverText: 'The product(s) covered within the customer contract or customer plan document(s) established between Lincoln Financial Group and the customer',
        },
        {
            id: 'requestNumber',
            fieldLabel: 'Request Number',
            field: 'Request Number',
            status: true,
            hoverText: 'A number defined by Lincoln Financial Group assigned to an accommodation request',
        },
        {
            id: 'requestStatus',
            fieldLabel: 'Request Status',
            field: 'Request Status',
            status: true,
            hoverText: 'The status of the accommodation',
        },
        {
            id: 'rtwSaw',
            fieldLabel: 'RTW or SAW',
            field: 'RTW or SAW',
            status: false,
            hoverText: 'Indicates return to work or stay at work',
        },
        {
            id: 'submitterEmail',
            fieldLabel: 'Submitter Email',
            field: 'Submitter Email',
            status: false,
            hoverText: 'Email of the submitter',
        },
        {
            id: 'submitterFirstName',
            fieldLabel: 'Submitter First Name',
            field: 'Submitter First Name',
            status: false,
            hoverText: 'First name of the submitter',
        },
        {
            id: 'submitterLastName',
            fieldLabel: 'Submitter Last Name',
            field: 'Submitter Last Name',
            status: false,
            hoverText: 'Last name of the submitter',
        },
    ],
};
export const initialState = {
    isLoading: false,
    callCustomerConfig: true,
    employeeTextCustomContent: '',
    reportId: '0',
    reportCountCallSuccess: false,
    showRunReportSpinner: false,
    filterReportLabels: [],
    products: [
        { code: 'LTD', label: 'Long Term Disability', checked: false },
        { code: 'LTDCP', label: 'Long Term Disability Companion Product', checked: false },
        { code: 'SALCN', label: 'Salary Contribution', checked: false },
        { code: 'STAT', label: 'State Required Contribtion Plan', checked: false },
        { code: 'STD', label: 'Short Term Disability', checked: false },
        { code: 'STDCP', label: 'Short Term Disability Companion Product', checked: false },
        { code: 'PL', label: 'Paid Leave', checked: false },
    ],
    filterStatusCodes: [
        { code: 'APPROVED', label: 'Approved', checked: false },
        { code: 'CLOSED', label: 'Closed', checked: false },
        { code: 'DENIED', label: 'Denied', checked: false },
        { code: 'IN_PROCESS', label: 'In Process', checked: false },
        { code: 'PENDING', label: 'Pending', checked: false },
    ],
    reportDateRange: { dateSearchBy: '', fromDate: '', toDate: '', searchBy: '' },
    reportFilterCategory: '',
    workStates: [],
    workProvinces: [],
    subLocs: [],
    selectedSubLocs: [],
    fieldPageInfo: JSON.parse(JSON.stringify(fieldsInitialState)),
};
