export const CLI_COUNTRIES = [
    { label: 'Afghanistan', value: 'AFG' },
    { label: 'Albania', value: 'ALB' },
    { label: 'Algeria', value: 'DZA' },
    { label: 'American Samoa', value: 'ASM' },
    { label: 'Andorra', value: 'AND' },
    { label: 'Angola', value: 'AGO' },
    { label: 'Anguilla', value: 'AIA' },
    { label: 'Antarctica', value: 'ATA' },
    { label: 'Antigua and Barbuda', value: 'ATG' },
    { label: 'Argentina', value: 'ARG' },
    { label: 'Armenia', value: 'ARM' },
    { label: 'Aruba', value: 'ABW' },
    { label: 'Australia', value: 'AUS' },
    { label: 'Austria', value: 'AUT' },
    { label: 'Azerbaijan', value: 'AZE' },
    { label: 'Bahamas', value: 'BHS' },
    { label: 'Bahrain', value: 'BHR' },
    { label: 'Bangladesh', value: 'BDG' },
    { label: 'Barbados', value: 'BRB' },
    { label: 'Belarus', value: 'BLR' },
    { label: 'Belgium', value: 'BEL' },
    { label: 'Belize', value: 'BLZ' },
    { label: 'Benin', value: 'BEN' },
    { label: 'Bermuda', value: 'BMU' },
    { label: 'Bhutan', value: 'BTN' },
    { label: 'Bolivia', value: 'BOL' },
    { label: 'Bosnia and Herzegovina', value: 'BIH' },
    { label: 'Botswana', value: 'BWA' },
    { label: 'Bouvet Island', value: 'BVT' },
    { label: 'Brazil', value: 'BRA' },
    { label: 'British Ind. Ocean Terr.', value: 'IOT' },
    { label: 'British Virgin Islands', value: 'VGB' },
    { label: 'Brunei Darussalam', value: 'BRN' },
    { label: 'Bulgaria', value: 'BGR' },
    { label: 'Burkina Faso', value: 'BFA' },
    { label: 'Burundi', value: 'BDI' },
    { label: 'Cambodia', value: 'KHM' },
    { label: 'Cameroon', value: 'CMR' },
    { label: 'Canada', value: 'CAN' },
    { label: 'Cape Verde', value: 'CPV' },
    { label: 'Cayman Islands', value: 'CYM' },
    { label: 'Central African Republic', value: 'CAF' },
    { label: 'Chad', value: 'TCD' },
    { label: 'Chile', value: 'CHL' },
    { label: 'China', value: 'CHN' },
    { label: 'Christmas Island', value: 'CXR' },
    { label: 'Cocos (Keeling) Islands', value: 'CCK' },
    { label: 'Colombia', value: 'COL' },
    { label: 'Comoros', value: 'COM' },
    { label: 'Congo', value: 'COG' },
    { label: 'Cook Islands', value: 'COK' },
    { label: 'Costa Rica', value: 'CRI' },
    { label: 'Cote D\'Ivoire', value: 'CIV' },
    { label: 'Croatia (Hrvatska)', value: 'HRV' },
    { label: 'Cuba', value: 'CUB' },
    { label: 'Cyprus', value: 'CYP' },
    { label: 'Czech Republic', value: 'CZE' },
    { label: 'Denmark', value: 'DNK' },
    { label: 'Djibouti', value: 'DJI' },
    { label: 'Dominica', value: 'DMA' },
    { label: 'Dominican Republic', value: 'DOM' },
    { label: 'East Timor', value: 'TMP' },
    { label: 'Ecuador', value: 'ECU' },
    { label: 'Egypt', value: 'EGY' },
    { label: 'El Salvador', value: 'SLV' },
    { label: 'Equatorial Guinea', value: 'GNQ' },
    { label: 'Eritrea', value: 'ERI' },
    { label: 'Estonia', value: 'EST' },
    { label: 'Ethiopia', value: 'ETH' },
    { label: 'Falkland Islands (Malvinas)', value: 'FLK' },
    { label: 'Faroe Islands', value: 'FRO' },
    { label: 'Fiji', value: 'FJI' },
    { label: 'Finland', value: 'FIN' },
    { label: 'France, Metropolitan', value: 'FXX' },
    { label: 'France', value: 'FRA' },
    { label: 'French Guiana', value: 'GUF' },
    { label: 'French Polynesia', value: 'PYF' },
    { label: 'French Southern Terr', value: 'ATF' },
    { label: 'Gabon', value: 'GAB' },
    { label: 'Gambia', value: 'GMB' },
    { label: 'Georgia', value: 'GEO' },
    { label: 'Germany', value: 'DEU' },
    { label: 'Ghana', value: 'GHA' },
    { label: 'Gibraltar', value: 'GIB' },
    { label: 'Greece', value: 'GRC' },
    { label: 'Greenland', value: 'GRL' },
    { label: 'Grenada', value: 'GRD' },
    { label: 'Guadeloupe', value: 'GLP' },
    { label: 'Guam', value: 'GUM' },
    { label: 'Guatemala', value: 'GTM' },
    { label: 'Guinea', value: 'GIN' },
    { label: 'Guinea-Bissau', value: 'GNB' },
    { label: 'Guyana', value: 'GUY' },
    { label: 'Haiti', value: 'HTI' },
    { label: 'Heard and McDonald Islands', value: 'HMD' },
    { label: 'Honduras', value: 'HND' },
    { label: 'Hong Kong', value: 'HKG' },
    { label: 'Hungary', value: 'HUN' },
    { label: 'Iceland', value: 'ISL' },
    { label: 'India', value: 'IND' },
    { label: 'Indonesia', value: 'IDN' },
    { label: 'Iran (Islamic Republic)', value: 'IRN' },
    { label: 'Iraq', value: 'IRQ' },
    { label: 'Ireland', value: 'IRL' },
    { label: 'Israel', value: 'ISR' },
    { label: 'Italy', value: 'ITA' },
    { label: 'Jamaica', value: 'JAM' },
    { label: 'Japan', value: 'JPN' },
    { label: 'Kazakhstan', value: 'KAZ' },
    { label: 'Kenya', value: 'KEN' },
    { label: 'Kiribati', value: 'KIR' },
    { label: 'Korea, Republic of', value: 'KOR' },
    { label: 'Korea, Democratic People\'s Republic', value: 'PRK' },
    { label: 'Kuwait', value: 'KWT' },
    { label: 'Kyrgyzstan', value: 'KGZ' },
    { label: 'Lao People\'s Democratic Republic', value: 'LAD' },
    { label: 'Latvia', value: 'LVA' },
    { label: 'Lebanon', value: 'LBN' },
    { label: 'Lesotho', value: 'LSO' },
    { label: 'Liberia', value: 'LBR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LBY' },
    { label: 'Liechtenstein', value: 'LIE' },
    { label: 'Lithuania', value: 'LTU' },
    { label: 'Luxembourg', value: 'LUX' },
    { label: 'Macau', value: 'MAC' },
    { label: 'Macedonia', value: 'MKD' },
    { label: 'Madagascar', value: 'MDG' },
    { label: 'Malawi', value: 'MWI' },
    { label: 'Malaysia', value: 'MYS' },
    { label: 'Maldives', value: 'MDV' },
    { label: 'Mali', value: 'MLI' },
    { label: 'Malta', value: 'MLT' },
    { label: 'Marshall Islands', value: 'MHL' },
    { label: 'Martinique', value: 'MTQ' },
    { label: 'Mauritania', value: 'MRT' },
    { label: 'Mauritius', value: 'MUS' },
    { label: 'Mayotte', value: 'MYT' },
    { label: 'Mexico', value: 'MEX' },
    { label: 'Micronesia, Federated States of', value: 'FSM' },
    { label: 'Moldova, Republic of', value: 'MDA' },
    { label: 'Monaco', value: 'MCO' },
    { label: 'Mongolia', value: 'MNG' },
    { label: 'Montserrat', value: 'MSR' },
    { label: 'Morocco', value: 'MAR' },
    { label: 'Mozambique', value: 'MOZ' },
    { label: 'Myanmar', value: 'MMR' },
    { label: 'Namibia', value: 'NAM' },
    { label: 'Nauru', value: 'NRU' },
    { label: 'Nepal', value: 'NPL' },
    { label: 'Netherlands', value: 'NLD' },
    { label: 'Netherlands/Antilles', value: 'ANT' },
    { label: 'New Caledonia', value: 'NCL' },
    { label: 'New Zealand', value: 'NZL' },
    { label: 'Nicaragua', value: 'NIC' },
    { label: 'Niger', value: 'NER' },
    { label: 'Nigeria', value: 'NGA' },
    { label: 'Niue', value: 'NIU' },
    { label: 'Non Admitted Foreign', value: 'NFR' },
    { label: 'Norfolk Island', value: 'NFK' },
    { label: 'Northern Mariana Islands', value: 'MNP' },
    { label: 'Norway', value: 'NOR' },
    { label: 'Oman', value: 'OMN' },
    { label: 'Pakistan', value: 'PAK' },
    { label: 'Palau', value: 'PLW' },
    { label: 'Panama', value: 'PAN' },
    { label: 'Papua New Guinea', value: 'PNG' },
    { label: 'Paraguay', value: 'PRY' },
    { label: 'PER', value: 'PER' },
    { label: 'Philippines', value: 'PHL' },
    { label: 'Pitcairn', value: 'PCN' },
    { label: 'Poland', value: 'POL' },
    { label: 'Portugal', value: 'PRT' },
    { label: 'Puerto Rico', value: 'PRI' },
    { label: 'Qatar', value: 'QAT' },
    { label: 'Reunion', value: 'REU' },
    { label: 'Romania', value: 'ROM' },
    { label: 'Russia', value: 'RUS' },
    { label: 'Rwanda', value: 'RWA' },
    { label: 'Saint Kitts and Nevis', value: 'KHA' },
    { label: 'Saint Lucia', value: 'LCA' },
    { label: 'Saint Vincent and the Grenadines', value: 'VCT' },
    { label: 'Samoa', value: 'WSM' },
    { label: 'San Marino', value: 'SMR' },
    { label: 'Sao Tome and Principe', value: 'STP' },
    { label: 'Saudi Arabia', value: 'SAU' },
    { label: 'Senegal', value: 'SEN' },
    { label: 'Seychelles', value: 'SYC' },
    { label: 'Sierra Leone', value: 'SLE' },
    { label: 'Singapore', value: 'SGP' },
    { label: 'Slovakia', value: 'SVK' },
    { label: 'Slovenia', value: 'SVN' },
    { label: 'Solomon Islands', value: 'SLB' },
    { label: 'Somalia', value: 'SOM' },
    { label: 'South Africa', value: 'ZAF' },
    { label: 'South Georgia & South Sandwich Isld', value: 'SGS' },
    { label: 'Spain', value: 'ESP' },
    { label: 'Sri Lanka', value: 'LKA' },
    { label: 'St. Helena', value: 'SHN' },
    { label: 'St. Pierre and Miquelon', value: 'SPM' },
    { label: 'Sudan', value: 'SDN' },
    { label: 'Suriname', value: 'SUR' },
    { label: 'Svalbard and Jan Mayen Islands', value: 'SJM' },
    { label: 'Swaziland', value: 'SWZ' },
    { label: 'Sweden', value: 'SWE' },
    { label: 'Switzerland', value: 'CHE' },
    { label: 'Syria', value: 'SYR' },
    { label: 'Taiwan', value: 'TWN' },
    { label: 'Tajikistan', value: 'TJK' },
    { label: 'Tanzania', value: 'TZA' },
    { label: 'Thailand', value: 'THA' },
    { label: 'Togo', value: 'TGO' },
    { label: 'Tokelau', value: 'TKL' },
    { label: 'Tonga', value: 'TON' },
    { label: 'Trinidad and Tobago', value: 'TTO' },
    { label: 'Tunisia', value: 'TUN' },
    { label: 'Turkey', value: 'TUR' },
    { label: 'Turkmenistan', value: 'TKM' },
    { label: 'Turks and Caico Islands', value: 'TCA' },
    { label: 'Tuvalu', value: 'TUV' },
    { label: 'U.S. Minor Outlying Islands', value: 'UMI' },
    { label: 'U.S. Virgin Islands', value: 'VIR' },
    { label: 'Uganda', value: 'UGA' },
    { label: 'Ukraine', value: 'UKR' },
    { label: 'United Arab Emirates', value: 'ARE' },
    { label: 'United Kingdom', value: 'GBR' },
    { label: 'United States', value: 'USA' },
    { label: 'Uruguay', value: 'URY' },
    { label: 'Uzbekistan', value: 'UZB' },
    { label: 'Vanuatu', value: 'VUT' },
    { label: 'Vatican City State', value: 'VAT' },
    { label: 'Venezuela', value: 'VEN' },
    { label: 'Vietnam', value: 'VMN' },
    { label: 'Wallis and Futuna Islands', value: 'WLF' },
    { label: 'Western Sahara', value: 'ESH' },
    { label: 'Yemen', value: 'YEM' },
    { label: 'Yugoslavia', value: 'YUG' },
    { label: 'Zaire', value: 'ZAR' },
    { label: 'Zambia', value: 'ZMB' },
    { label: 'Zimbabwe', value: 'ZWE' },
];
export const CLI_STATES = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];
export const CLI_PROVINCES = [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland-Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
];
export const CLI_PROVINCES_VALUES = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
];
export const CLI_EMPLOYEE_TYPE = [
    { label: 'Full-Time', value: 'F' },
    { label: 'Part-Time', value: 'P' },
    { label: 'Terminated', value: 'T' },
];
export const CLI_EMPLOYEE_PHYSICAL_DEMANDS = [
    { label: 'Sedentary', value: 'S' },
    { label: 'Light', value: 'L' },
    { label: 'Medium', value: 'M' },
    { label: 'Heavy', value: 'H' },
    { label: 'Very Heavy', value: 'V' }
];
export const CLI_EMPLOYEE_WORK_STATUS = [
    { label: 'Active', value: 'A' },
    { label: 'Retired', value: 'R' },
    { label: 'Terminated', value: 'T' },
    { label: 'Laid Off', value: 'L' },
    { label: 'On Strike', value: 'S' },
    { label: 'Paid Leave of Absence', value: 'P' },
    { label: 'Unpaid Leave of Absence', value: 'U' },
    { label: 'Unknown', value: 'N' }
];
export const INCOME_TYPE_OPTIONS = [
    { label: 'Wages, Salary or Holiday Pay', value: 'WG' },
    { label: 'Vacation', value: 'VA' },
    { label: 'Sick Leave', value: 'SL' },
    { label: 'Separation Pay', value: 'SP' },
    { label: 'Disability Pension', value: 'DP' },
    { label: 'Retirement Pension', value: 'RP' },
    { label: 'Unemployment Insurance', value: 'UI' },
    { label: 'Social Security', value: 'SS' },
    { label: "Worker's Compensation (This Condition)", value: 'WC' },
    { label: 'LTD, STD Benefits', value: 'DB' }
];
export const supportedFiles = [
    'gif',
    'jpg',
    'pdf',
    'pptx',
    'tif',
    'tiff',
    'xls',
    'xlsx',
    'doc',
    'docx',
];
