import React from 'react';
import { Col, DatePickerV2, Label, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { Scope, useFormState } from 'informed';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
import { Experience } from 'src/types/Experience';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { DeliveryIndicator, LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import { addMonths, isAfter, parse } from 'date-fns';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import LastDayWorked from '../components/LastDayWorked';
import SurgeryComplication from '../components/SurgeryComplication';
import AdmissionDate from '../components/AdmissionDate';
import ReleaseDate from '../components/ReleaseDate';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
const PregnancyFlow = () => {
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { deliveryIndicators } = useCliCustomerConfigContext();
    const { absenceData } = useCliDataContext();
    const { experience, language } = useExperience();
    const { values, errors, invalid } = useFormState();
    const deliveryTypeLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.deliveryTypeEE : formLabels.deliveryTypeER));
    const deliveryComplicationsLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.deliveryComplicationsEE : formLabels.deliveryComplicationsER));
    // --- Delivery Date label, error & validate helpers ---
    let deliveryDateLabel = '';
    let deliveryDateShortLabel = '';
    let deliveryDateError = '';
    let hasDelivered = false;
    //@ts-ignore
    const deliveryIndicator = values?.absenceData?.deliveryIndicator;
    if (deliveryIndicator === DeliveryIndicator.VAGINAL || deliveryIndicator === DeliveryIndicator.CSECTION) {
        deliveryDateLabel =
            experience === Experience.EMPLOYEE ? t(formLabels.deliveryDateEE) : t(formLabels.deliveryDateER);
        deliveryDateShortLabel = t(formLabels.deliveryDateShortLabel);
        deliveryDateError = t(AboutYourAbsenceText.formLabels.deliveryDateError);
        hasDelivered = true;
    }
    else if (deliveryIndicator === DeliveryIndicator.NO) {
        deliveryDateLabel =
            experience === Experience.EMPLOYEE ? t(formLabels.deliveryDateExpectedEE) : t(formLabels.deliveryDateExpectedER);
        deliveryDateShortLabel = t(formLabels.deliveryDateExpectedShortLabel);
        deliveryDateError = t(AboutYourAbsenceText.formLabels.deliveryDateExpectedError);
    }
    //  Display when formState leaveType is PREGNANT and leaveCategory defined -- Happens for both claim and leave because of defaults
    const shouldDisplayDeliveryIndicator = values?.absenceData?.claimantCondition === 'P' ||
        (values?.absenceData?.leaveType === LeaveType.PREGNANT && values?.absenceData?.leaveCategory === 'C');
    //  Display after deliveryIndicator is answered
    const shouldDisplayDeliveryDate = shouldDisplayDeliveryIndicator && values?.absenceData?.deliveryIndicator;
    const shouldDisplayYesFlow = deliveryIndicator === DeliveryIndicator.VAGINAL || deliveryIndicator === DeliveryIndicator.CSECTION;
    const shouldDisplayPregnancyComplications = deliveryIndicator === DeliveryIndicator.NO;
    const validateDeliveryDate = (input) => {
        if (input && values && values.absenceData.deliveryIndicator === DeliveryIndicator.NO) {
            if (isAfter(parse(input, 'P', new Date()), addMonths(new Date(), 9))) {
                return t({
                    english: 'Expected delivery date cannot be more than 9 months in the future',
                    spanish: 'La fecha de entrega esperada no puede ser más de 9 meses en el futuro',
                });
            }
        }
        else
            return validateDateIsOnOrBeforeToday(input, t, true);
    };
    return (<div data-testid='pregnancy-flow'>
      {/* @ts-ignore */}
      <Scope scope='absenceData'>
        {/* Delivery Indicator */}
        {shouldDisplayDeliveryIndicator && (<Row className='mb-3'>
            <Col xs={12}>
              <RadioGroup label={deliveryTypeLabel} field={'deliveryIndicator'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.deliveryIndicator}>
                {deliveryIndicators.map((deliveryIndicator) => {
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'di-' + deliveryIndicator.code} key={'di-' + deliveryIndicator.code} name='deliveryIndicator' label={t(deliveryIndicator)} value={deliveryIndicator.code}/>);
            })}
              </RadioGroup>
            </Col>
          </Row>)}

        {/* Delivery Date */}
        {shouldDisplayDeliveryDate && (<Row className='mb-3'>
            <Col size={12}>
              <Label className='mb-2'>{deliveryDateLabel}</Label>
            </Col>
            <Col size={12} sm={6} xl={4}>
              <DatePickerV2 data-testid='deliveryDate' theme={themes.lincoln} field={'deliveryDate'} lang={language} label={deliveryDateShortLabel} placeholder={'mm/dd/yyyy'} ariaLabelText={deliveryDateLabel} mask={dateMask} validateOnBlur={true} validate={validateDeliveryDate} initialValue={absenceData && absenceData.deliveryDate}/>
            </Col>
          </Row>)}

        {/* Last Day Worked */}
        {shouldDisplayDeliveryDate && <LastDayWorked />}

        {shouldDisplayYesFlow && (<>
            {/* AdmissionDate */}
            <AdmissionDate />
            {/* Release Date */}
            <ReleaseDate />
          </>)}

        {/* DeliveryComplicationIndicator - CX-163 */}
        {shouldDisplayPregnancyComplications && (<Row className='mb-3'>
            <Col xs={12}>
              <RadioGroup label={deliveryComplicationsLabel} field={'deliveryComplicationIndicator'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.deliveryComplicationIndicator}>
                <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'dci-yes'} key={'dci-yes'} name='deliveryComplicationIndicator' label={t(formLabels.yes)} value={'Y'}/>
                <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'dci-yes'} key={'dci-no'} name='deliveryComplicationIndicator' label={'No'} value={'N'}/>
                {experience === Experience.EMPLOYER && (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'dci-unknown'} key={'dci-unknown'} name='deliveryComplicationIndicator' label={'Unknown'} value={'U'}/>)}
              </RadioGroup>
            </Col>
          </Row>)}

        {/* SurgeryComplicationText */}
        {shouldDisplayDeliveryDate && <SurgeryComplication />}
      </Scope>
    </div>);
};
export default PregnancyFlow;
