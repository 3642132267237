import React from 'react';
import PartialCoverageDetails from './PartialCoverageDetails';
import useGet from '../../../../../hooks/useGet';
import { encode } from 'status/utilities/hash';
import { withTranslation } from 'react-i18next';
import { Spinner } from '@digitools/honest-react';
import { ErrorPage } from 'status/components/pages';
import { useNavigate } from 'react-router-dom';
/**
 * This component is used when Employers ( ER ) attempt to
 * route to an employees coveragedetails page with only
 * an empId.
 *
 * Backstory: Sometimes, employees will only have an empId
 * because they don't exits in S1. An employer might want to
 * view details on this employee but all they have to go on is
 * the empId. Therefore, we need a way for employers to view
 * this data ( coverage details ) without an empNum. This
 * is where <PartialCoverageDetails> comes into play. Its
 * a component that will render details based on the empId.
 *
 * However sometimes these employees actually do have an EmpNum.
 * Therefore, we need a way to, when an employer routes to
 * employer/{emp-id} ---> lookup if they have empNum ---> route accordingly
 */
const EmployerDetailRedirect = ({ empId, fromTeamView }) => {
  const { t } = withTranslation();
  const navigate = useNavigate();
  const { error, loading, data } = useGet({
    url: '/status-service/employee/search/searchByEmpId',
    options: {
      params: {
        empId,
      },
    },
  });

  if (loading) {
    return <Spinner id='er-detail-redirect-spinner' center={true} />;
  }

  // We got a 404 so we just send them to the partial details page
  if (error && error.response.status === 404) {
    return <PartialCoverageDetails fromTeamView={fromTeamView} />;
  }

  // Something went wrong when looking emp the employee
  if (error) {
    return <ErrorPage message={t('genericError')} />;
  }

  // Assume one employee is returned for empId
  const employee = data._embedded.employees[0];

  const to = fromTeamView
    ? `/status/employer/${encode(employee.empNum)}/coveragedetail?from=teamView`
    : `/status/employer/${encode(employee.empNum)}/coveragedetail`;

  // We got a result back
  return navigate(to);
};

export default EmployerDetailRedirect;
