// ***** MOBILE HEADER ANIMATION HELPER FUNCTIONS *****
/** Resize - Helper method to resize a container element
 *  (In this case, the container element is hardcoded to be the mobile 'menu' )
 *  the resulting height is based on the height of all the triggering element's siblings,
 *  or just the height of the element itself, depending on if the slideMenu's are opening
 *  Allows for the menu to stay the same height as the current relevant nav Items selected.
 *
 *   @param idToIgnore - aka the id of the triggering slidemenu FIXME: could rename this better?
 *   @param slideOpen - controls whether to push siblings of the idToIgnore off to the left or reset to center
*/
// TODO: This is super ugly - clean up for legibility
export const resize = (idToIgnore, slideOpen) => {
    // console.log("*** in resize function ***")
    // console.log("id to ignore (Trigger): "+idToIgnore);
    // console.log("Slide Open?: "+slideOpen)
    const menu = document.getElementById('menu');
    const parentOfTrigger = document.getElementById(idToIgnore).parentNode;
    // calculate height menu should be - iterate over all siblings of the triggering element
    let heightCalc = Array.prototype.reduce.call(parentOfTrigger.childNodes, function (p, c) {
        // console.log(`Element to Ignore: ${idToIgnore} | Slide Open?: ${slideOpen}`);
        // console.log(`ID ${c.id} Height: ${c.offsetHeight}, scroll: ${c.scrollHeight}`);
        if (c.id != idToIgnore) {
            if (slideOpen) {
                // console.log(`slide menu opening - this is different id, don't add height of ${c.id}`);
                return p;
            }
            else {
                // console.log(`slide menu closing - add ${c.id} to height calc`)
                return p + (c.offsetHeight || 0);
            }
        }
        else {
            if (slideOpen) {
                // console.log(`slide menu opening - On triggering slideMenuButton so add height of ${c.id}`);
                let slideMenuButtonHeightOffset = document.getElementById(`slideMenuButton-${c.id}`).offsetHeight;
                // console.log('Triggering slideMenuButton Height: ' + slideMenuButtonHeightOffset);
                return p + (c.offsetHeight || 0) - slideMenuButtonHeightOffset; //TODO: hack to remove height of opening button (that adds to height of children calculation to create gap w/o this)
            }
            else {
                return p + (c.offsetHeight || 0);
            }
        }
    }, 0) + 'px';
    // console.log('Done Resize - Resetting height of <'+menu+'> to...');
    // console.log(`HeightCalc: ` + heightCalc);
    menu.style.height = heightCalc;
};
// TODO: could move parent calculation out of SlideoutMenu and into this function
/** SlideHandler - Helper method to slide off siblings of a slideoutMenu. Also calculates
 *  and resets MargitTop to place slideoutMenu along the top of the mobile Nav Menu
 *  @param parentId - The parent container element of the slideMenu.
 *  @param idToIgnore - the id of the triggering slideoutmenU component
 *  @param slideOpen - if SlideOpen is true, it this will push the siblings off to the left,
 *                      if false, it will restore the parent & siblings to the center
*/
export const slideHandler = (parentId, idToIgnore, slideOpen) => {
    // console.log("*** in slide handler function ***")
    // console.log("triggering element - ignore"+idToIgnore);
    // console.log("Parent element"+parentId);
    // Begin evaluation with parent of the slideoutMenu
    const element = document.getElementById(parentId);
    let indexOffset = 0;
    Array.from(element.children).forEach(c => {
        // console.log('iterating with',c);
        // Handle animating siblings of menu off to left or back
        if (c.id !== idToIgnore) {
            // console.log('slidehandler evaluating element ['+c.id+'] outside of slideMenu, slide left' )
            if (slideOpen) {
                // console.log('slideOpen the menu -100% margin left ')
                // console.log('document!.getElementById(c.id):',document!.getElementById(c.id));
                document.getElementById(c.id).style.marginLeft = '-100%';
            }
            else {
                // console.log('Slide closed, - resetting marginLeft to 0')
                // console.log('document!.getElementById(c.id):',document!.getElementById(c.id));
                document.getElementById(c.id).style.marginLeft = '0';
            }
        }
        else {
            // console.log('slidehandler evaluating the controlling id.'+idToIgnore)
            if (slideOpen) {
                // console.log('setting negative margin top to pull item up')
                element.style.marginTop = `${(-1 * indexOffset) * (51.6)}`; // move menu upwards by # of items above it - creates slide up/left effect to current menu item
            }
            else {
                // console.log('resetting marginTop to 0 to put it back')
                element.style.marginTop = `0`;
            }
        }
        indexOffset++;
    });
};
