import React from 'react';
import { CompositeTable, Heading, Link, Row } from '@digitools/honest-react';
import { compareAsc, format, parse } from 'date-fns';
import { FileType } from '@digitools/honest-react/dist/lib/types/FileType';
import { renderIcon } from '../../utils/FileUtils';
import withGoogleAnalytics from '../../hocs/WithGoogleAnalytics';
const EmployerResourceDetailTable = ({ directory, downloadFile, googleAnalyticsContext, }) => {
    const mapEmployerResourceToTableColumnData = (employerResource) => {
        return {
            modifiedTimestamp: employerResource?.modifiedTimestamp && format(new Date(employerResource?.modifiedTimestamp), 'P hh:mm a'),
            id: employerResource.id,
            label: employerResource.label,
            type: employerResource.type,
            recordType: employerResource.recordType,
            description: employerResource.description,
        };
    };
    const files = directory.children
        .filter(employerResource => employerResource.type !== FileType.DIR)
        .map(resource => mapEmployerResourceToTableColumnData(resource));
    const columnsWithDate = [
        {
            Cell: ({ data }) => {
                const handleDownload = () => {
                    googleAnalyticsContext.trackClick('employerResourcesReportDownload');
                    // @ts-ignore
                    downloadFile(data._original.id, data._original.recordType);
                };
                // @ts-ignore
                return (<>
            {renderIcon(data?._original?.type)} <Link onClick={handleDownload}>{data?._original?.label}</Link>
          </>);
            },
            Header: 'File Name',
            accessor: 'label',
            id: 'label',
        },
        {
            Header: 'Modified Date',
            accessor: 'modifiedTimestamp',
            id: 'modifiedTimestamp',
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
    ];
    const columnsWithSummary = [
        {
            Cell: ({ data }) => {
                const handleDownload = () => {
                    googleAnalyticsContext.trackClick('employerResourcesReportDownload');
                    // @ts-ignore
                    downloadFile(data._original.id, data._original.recordType);
                };
                // @ts-ignore
                return (<>
            {renderIcon(data?._original.type)} <Link onClick={handleDownload}>{data._original.label}</Link>
          </>);
            },
            Header: 'File Name',
            accessor: 'label',
            id: 'label',
        },
        {
            Header: 'Summary',
            accessor: 'description',
            id: 'description',
        },
    ];
    const noDocumentsSpan = () => {
        const noDocsStyle = { display: 'flex', justifyContent: 'center' };
        if (files.length === 0) {
            return (<Row className={'noDocumentsSpan pl-2'}>
          <span style={noDocsStyle}>There are no documents in this folder</span>
        </Row>);
        }
    };
    const tableColumns = files[0]?.recordType?.includes('employee') ? columnsWithSummary : columnsWithDate;
    const table = files.length > 0 ? (<CompositeTable defaultSorted={[{ id: 'modifiedTimestamp', desc: true }]} columns={tableColumns} data={files} filterable={false}/>) : (<></>);
    return (<>
      <Heading elemType='h3'>{directory.label}</Heading>
      {noDocumentsSpan()}
      {table}
    </>);
};
export default withGoogleAnalytics(EmployerResourceDetailTable);
