import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Heading, Icon, Link, Loader, Modal, Row, SelectInput, } from '@digitools/honest-react';
import { Language } from '../../types/Language';
import { DocumentUploadDisplayTextEs } from './language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from './language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import styled from 'styled-components';
import useAuthentication from '../../hooks/useAuthentication';
import useExperience from '../../hooks/useExperience';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import SelectCategory from './SelectCategory';
import { Experience } from 'src/types/Experience';
import { docMeta } from 'src/packages/status/components/pages/ClaimDetails/docCategoryMapper';
import { TealiumUtils } from '../../utils/TealiumUtils';
import useTranslate from 'src/hooks/useTranslate';
import { StyledButton } from '../Formatting/StyledComponents';
const StyledCol = styled(Col) `
  border: 0.5px solid lightgrey;
  padding: 0.6rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`;
const StyledLink = styled(Link) `
  font-weight: bold;
`;
export const StyledSerial = styled(Col) `
  background-color: #5b5d5e;
  color: white;
  display: flex;
  padding: 1em !important;
  height: 3em;
  align-items: center;
  justify-content: center;
  @media (min-width: 440px) {
    margin-right: 1em;
  }
`;
export const CategoryCol = styled(Col) `
  @media (min-width: 440px) {
    justify-content: space-between;
    display: flex;
  }
`;
const supportedFiles = ['gif', 'jpg', 'pdf', 'pptx', 'tif', 'tiff', 'xls', 'xlsx', 'doc', 'docx'];
const CategorizationDocumentUpload = ({ eventNum, productName, displayLabel, renderAsLink, page, isNewCard, }) => {
    const { authHeader, user } = useAuthentication();
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedDocumentsCount, setUploadedDocumentsCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [documentCategory, setDocumentCategory] = useState('');
    const [productType, setProductType] = useState([]);
    const [apiFailureMessage, setApiFailureMessage] = useState(false);
    const { t } = useTranslate();
    // handle opening the modal when navigating here clicking an upload button
    const openOnLoad = window.sessionStorage.getItem('statusNav') === 'upload';
    if (openOnLoad) {
        //reset this nav token so stray nav's won't re-trigger
        window.sessionStorage.setItem('statusNav', '');
    }
    useEffect(() => {
        if (user?.customerInContext?.smallMid) {
            const rtwSmallMid = experience === Experience.EMPLOYEE ? t(docMeta.docsNet.claimsEE) : docMeta.docsNet.claimsER;
            productName == 'LEAVE' ? [] : setProductType(rtwSmallMid);
        }
        else {
            productName.trim().toUpperCase() === 'LEAVE'
                ? setProductType(t(docMeta.s1.leaves))
                : setProductType(t(docMeta.s1.claimsEE));
        }
    }, [language]);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<>
        {renderAsLink && (<div style={{ whiteSpace: 'nowrap' }} onClick={handleOnClick}>
            <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
            <Link style={{ fontSize: '12px' }} data-testid='openModal' onClick={handleOnClick}>
              {languageFile.uploadLabels}
            </Link>
          </div>)}

        {!renderAsLink && ((isNewCard ? (<StyledButton buttonType={displayLabel ? 'secondary' : 'text'} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + eventNum} aria-label={languageFile.uploadLabel} style={{ textTransform: 'inherit' }}>
              <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
              {displayLabel && languageFile.uploadLabel}
            </StyledButton>) : (<Button buttonType={displayLabel ? 'secondary' : 'text'} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + eventNum} aria-label={languageFile.uploadLabel} style={{ textTransform: 'inherit' }}>
              <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
              {displayLabel && languageFile.uploadLabel}
            </Button>)))}
      </>);
    };
    const FooterPrimaryRenderButton = () => {
        const handleDisable = () => {
            return uploadedFiles.length < 1 || submitting || uploading;
        };
        return (<Button buttonType={'primary'} disabled={handleDisable()} type={'button'} onClick={submitUploadedDocuments} data-testid={'submit'}>
        {languageFile.submitText}
      </Button>);
    };
    const FooterSecondaryRenderButton = ({ hideModal }) => {
        const handleCancel = () => {
            clearStates();
            hideModal();
        };
        return (<Button buttonType={'secondary'} type={'button'} onClick={handleCancel} data-testid={'cancel'}>
        {uploadedDocumentsCount > 0 ? languageFile.closeText : languageFile.cancelText}
      </Button>);
    };
    const getProduct = () => {
        if (productName === 'Leave' || productName === 'LEAVE') {
            return languageFile.leave;
        }
        else if (productName === 'AS') {
            return languageFile.asEvent;
        }
        else {
            return languageFile.claim;
        }
    };
    const updateCategory = (docDetails, val) => {
        let updatedCategory = uploadedFiles.map(e => {
            let metaDoc = getDocMeta(val);
            if (e.docId == docDetails.docId) {
                return { ...e, label: metaDoc.label, title: metaDoc.title, type: metaDoc.type };
            }
            return e;
        });
        setUploadedFiles(updatedCategory);
    };
    const getDocMeta = (val) => {
        const metaObj = productType.filter((e) => e.label == val);
        return metaObj[0];
    };
    const onUpload = (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6291456 && selectedFile.size !== 0) {
                        uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
            }
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(`/mlp-ui-service/api/documents/upload`, getFormData(file, productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            const docMeta = documentCategory ? getDocMeta(documentCategory) : productType[0];
            setUploadedFiles(prev => [
                ...prev,
                {
                    documentName: file.name,
                    docId: response.data,
                    label: docMeta.label,
                    documentTypeTitle: docMeta.title,
                    documentType: docMeta.type,
                },
            ]);
            setUploadedDocumentsCount(0);
            setErrorMessage('');
            setDocumentCategory('');
            setUploading(false);
            setApiFailureMessage(false);
        }
        catch (error) {
            setUploading(false);
            setDocumentCategory('');
            setApiFailureMessage(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: languageFile.apiFailureMessage + languageFile.contactUs,
                event_version: 'upload document',
            });
        }
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', interviewType);
        formData.append('experience', experience);
        return formData;
    };
    const renderColumns = () => {
        return (<>
        {uploadedFiles?.map((documentDetail, index) => {
                return (<>
              <CategoryCol md={5}>
                <StyledSerial>{index + 1}</StyledSerial>
                <SelectInput key={documentDetail.docId} field={'doc-category'} options={productType} data-testid={'doc-category'} initialValue={documentDetail?.label} onChange={(e) => updateCategory(documentDetail, e.target.value)}/>
              </CategoryCol>

              <Col md={7}> {getSelectedDocuments(documentDetail)}</Col>
            </>);
            })}
      </>);
    };
    const getSelectedDocuments = (documentDetail) => {
        const handleDelete = () => {
            const newDocumentList = uploadedFiles.filter(file => file.docId !== documentDetail.docId);
            setUploadedFiles(newDocumentList);
        };
        return (<Row key={documentDetail.docId}>
        <StyledCol md={8}>
          <Row data-testid={'submitted-doc'} aria-labelledby={documentDetail.documentName}>
            <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {documentDetail.documentName}
          </Row>
        </StyledCol>
        <StyledCol md={3}>
          <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
            <Icon color='secondary' name='trash'/> {languageFile.delete}
          </StyledLink>
        </StyledCol>
      </Row>);
    };
    const clearStates = () => {
        setUploadedFiles([]);
        setErrorMessage('');
        setSubmitting(false);
        setUploading(false);
        setApiFailureMessage(false);
    };
    const submitUploadedDocuments = async () => {
        setSubmitting(true);
        let docDetails = { docData: { submissionId: eventNum, documentData: uploadedFiles } };
        let url = '/mlp-ui-service/api/documents/forward-gp';
        try {
            let response = await axios.post(url, docDetails, { headers: { Authorization: authHeader() } });
            if (response.status === 200) {
                setUploadedDocumentsCount(uploadedFiles.length);
                clearStates();
                let dataTypeString = TealiumUtils(uploadedFiles);
                page == 'details'
                    ? trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                        event_name: 'submit',
                        event_results: uploadedFiles.length.toString(),
                        form_result: dataTypeString,
                        event_version: 'details',
                    })
                    : trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                        event_name: 'submit',
                        event_results: uploadedFiles.length.toString(),
                        form_result: dataTypeString,
                    });
            }
            else {
                setSubmitting(false);
                setApiFailureMessage(true);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: languageFile.apiFailureMessage + languageFile.contactUs,
                    event_version: 'upload document'
                });
            }
        }
        catch (error) {
            setSubmitting(false);
            setApiFailureMessage(true);
            page == 'details' ? trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: languageFile.apiFailureMessage + languageFile.contactUs,
                event_version: 'upload doc - details'
            }) :
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: languageFile.apiFailureMessage + languageFile.contactUs,
                    event_version: 'upload doc'
                });
        }
    };
    return (<Modal data-testid={'uploadModal'} style={{ margin: '2rem', overflowX: 'scroll' }} modalContainerStyle={{ maxWidth: '70rem' }} headerComponent={<Heading elemStyle={'h1'} elemType={'h1'} style={{ lineHeight: 'normal' }}>
          {`${languageFile.headerText} ${getProduct()} #${eventNum}`}
        </Heading>} modalOpenRenderButton={RenderOpenButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} footerSecondaryRenderButton={FooterSecondaryRenderButton} size={'large'} disabledBackdropClick={true} autoOpen={openOnLoad}>
      <Loader id='document-upload-spinner' isShown={submitting || uploading}>
        <Container>
          <Row>
            {uploadedDocumentsCount > 0 && (<Alert data-testid={'success-alert'} type={'success'} aria-labelledby='success-alert'>
                {uploadedDocumentsCount} {languageFile.successMessage}
              </Alert>)}
            {errorMessage && (<Alert data-testid={'error-alert'} type={'error'} aria-labelledby='error-alert'>
                {errorMessage}
              </Alert>)}
            {apiFailureMessage && (<Alert data-testid={'api-error-alert'} type={'error'} aria-labelledby='api-error-alert'>
                {languageFile.apiFailureMessage}
                <a href='/customer/home/contacts'>{languageFile.contactUs}</a>
              </Alert>)}
          </Row>
          <Row data-testid={'informationText'}>
            <p style={{ fontSize: 'larger' }}>{languageFile.informationalMessage1}</p>
          </Row>
          <Row data-testid={'acceptableDocumentsText'}>
            {languageFile.informationalMessage2}
          </Row>
          <Row>
            <p style={{ fontWeight: 'bold', fontSize: 'larger' }}>{languageFile.uploadCategory}</p>
          </Row>
          <Row>{renderColumns()}</Row>
          <Row>
            <SelectCategory onUpload={onUpload} eventNum={eventNum} uploadedFiles={uploadedFiles} documentCategory={documentCategory} uploading={uploading} productType={productType} setDocumentCategory={setDocumentCategory}/>
          </Row>
        </Container>
      </Loader>
    </Modal>);
};
export default CategorizationDocumentUpload;
