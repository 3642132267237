import { Col, Row } from '@digitools/honest-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Payment.scss';

const DataRow = ({ label, value }) => {
  return (
    <Row data-test-id='taxRow' isHoverable={false} className={'mt-2'}>
      <Col md={6}>{label}</Col>
      <Col md={6} className={styles.alignRight}>
        {value}
      </Col>
    </Row>
  );
};

const TaxDetails = ({ claimPayment }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row style={{ border: 'none' }} isHoverable={false} className={'pb-2'}>
        <Col data-testid={'taxHeader'} md={4}>
          <b>{t('taxesText')}</b>
        </Col>
        <Col data-testid={'withholdingHeader'} md={8} className={styles.alignRight}>
          <b>{t('withholdingAmountText')}</b>
        </Col>
      </Row>
      <div data-testid={'taxDetailsGrid'}>
        <DataRow label={t('checkFederalTaxWithheldAmountText')} value={claimPayment.totalFederalTaxWithheld} />
        <DataRow label={t('checkStateTaxWithheldAmountText')} value={claimPayment.totalStateTaxWithheld} />
        <DataRow label={t('checkLoclTaxWithheldAmountText')} value={claimPayment.localTaxWithheld} />
        <DataRow label={<strong>{t('checkFicaTaxWithheldAmountText')}</strong>} value={claimPayment.ficaTaxWithheld} />
        <DataRow
          label={<div className={styles.ficaTab}>{t('checkSsoaiTaxWithheldAmountText')}</div>}
          value={claimPayment.ssoaiTaxWithheld}
        />
        <DataRow
          label={<div className={styles.ficaTab}>{t('checkMedicareTaxWithheldAmountText')}</div>}
          value={claimPayment.medicareTaxWithheld}
        />
      </div>
      <DataRow label={<b>{t('totalText')}</b>} value={<b>{claimPayment.totalTaxWithheld}</b>} />
    </>
  );
};

export default TaxDetails;
