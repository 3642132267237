import React, { useEffect } from 'react';
import LanguageToggle from '../../../utils/LanguageToggle';
import AccessibilityTextEs from './i18n/AccessibilityTextEs';
import AccessibilityTextEn from './i18n/AccessibilityTextEn';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
function Accessibility() {
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.ACCESSIBILITY,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.ACCESSIBILITY.VIEW
        });
    }, []);
    return (<>
      <h1>
        <LanguageToggle es={AccessibilityTextEs.heading} en={AccessibilityTextEn.heading}/>
      </h1>

      <p>
        <LanguageToggle es={AccessibilityTextEs.paragraph1} en={AccessibilityTextEn.paragraph1}/>
      </p>

      <p>
        <LanguageToggle es={AccessibilityTextEs.paragraph2} en={AccessibilityTextEn.paragraph2}/>
      </p>
    </>);
}
export default Accessibility;
