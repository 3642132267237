import React from 'react';
import useCustomerConfig from '../hooks/useCustomerConfig';
import useExperience from '../hooks/useExperience';
import useTranslate from '../hooks/useTranslate';
import { Experience } from '../types/Experience';
import { Language } from '../types/Language';
const employeeTranslatable = {
    english: 'Employee',
    spanish: 'Empleado',
};
const employeeIdTranslatable = {
    english: 'Employee ID',
    spanish: 'ID de empleado',
};
const rtwTranslatable = {
    english: '',
    spanish: '',
};
/* gives the configured custom employee ID text (translated) or replaces Employee within a passed in ID text */
export const CustomEmployeeIdText = (label) => {
    const { mlpConfigs } = useCustomerConfig();
    const { language } = useExperience();
    const { t } = useTranslate();
    const defaultEmployeeIdLabel = t(employeeIdTranslatable);
    let customEmployeeIdText;
    if (mlpConfigs) {
        const nameConfig = mlpConfigs.customContents
            .filter(customContent => customContent.title === 'EmployeeIDText')
            .pop();
        if ((nameConfig.value_EN && language === Language.ENGLISH) ||
            (nameConfig.value_EN && !nameConfig.value_ES && language === Language.SPANISH)) {
            customEmployeeIdText = nameConfig.value_EN;
        }
        else if (nameConfig.value_ES && language === Language.SPANISH) {
            customEmployeeIdText = nameConfig.value_ES;
        }
        else {
            customEmployeeIdText = defaultEmployeeIdLabel;
        }
    }
    else {
        customEmployeeIdText = defaultEmployeeIdLabel;
    }
    /* Find Employee token and replace in given string, or return it unchanged */
    if (label) {
        return label.indexOf(defaultEmployeeIdLabel) > -1
            ? label.replace(defaultEmployeeIdLabel, customEmployeeIdText)
            : label;
    } /* Return only the customExperience Text, no existing label given */
    else {
        return customEmployeeIdText;
    }
};
/* gives the configured custom employee label (translated) or replaces Employee within a passed in label */
export const CustomEmployeeText = (label) => {
    const { mlpConfigs } = useCustomerConfig();
    const { language } = useExperience();
    const { t } = useTranslate();
    const defaultEmployeeLabel = t(employeeTranslatable);
    let customEmployeeText = '';
    if (mlpConfigs) {
        const nameConfig = mlpConfigs.customContents.filter(customContent => customContent.title === 'EmployeeText').pop();
        if ((nameConfig.value_EN && language === Language.ENGLISH) ||
            (nameConfig.value_EN && !nameConfig.value_ES && language === Language.SPANISH)) {
            customEmployeeText = nameConfig.value_EN;
        }
        else if (nameConfig.value_ES && language === Language.SPANISH) {
            customEmployeeText = nameConfig.value_ES;
        }
        else {
            customEmployeeText = defaultEmployeeLabel;
        }
    }
    else {
        customEmployeeText = defaultEmployeeLabel;
    }
    /* Find Employee token and replace in given string, or return it unchanged */
    if (label) {
        const regex2 = new RegExp(`${defaultEmployeeLabel}`, 'g');
        return label.replace(regex2, customEmployeeText);
    } /* No label given return only the customExperience Text, no existing label given */
    else {
        return customEmployeeText;
    }
};
export const CustomExperienceText = ({ keyword }) => {
    const { mlpConfigs } = useCustomerConfig();
    const { experience, language } = useExperience();
    let experienceText;
    // TODO: Should eventually move the custom contents filtering logic to Service?
    switch (experience) {
        case Experience.EMPLOYEE:
            if (mlpConfigs) {
                const nameConfig = mlpConfigs.customContents
                    .filter(customContent => customContent.title === 'EmployeeText')
                    .pop();
                if ((nameConfig.value_EN && language === Language.ENGLISH) ||
                    (nameConfig.value_EN && !nameConfig.value_ES && language === Language.SPANISH)) {
                    experienceText = `${nameConfig.value_EN} ${keyword}`;
                }
                else if (nameConfig.value_ES && language === Language.SPANISH) {
                    experienceText = `${nameConfig.value_ES} ${keyword}`;
                }
                else {
                    experienceText = `Employee ${keyword}`;
                }
            }
            else {
                experienceText = `Employee ${keyword}`;
            }
            break;
        case Experience.EMPLOYER:
            experienceText = `Employer ${keyword}`;
            break;
        case Experience.PUBLIC:
            experienceText = (<>
          My Lincoln Portal<sup>®</sup>
        </>);
            break;
    }
    return <>{experienceText}</>;
};
export const CustomRtwText = () => {
    const { mlpConfigs } = useCustomerConfig();
    const { language } = useExperience();
    const { t } = useTranslate();
    const defaultRtwLabel = t(rtwTranslatable);
    let customRtwText = '';
    if (mlpConfigs) {
        const nameConfig = mlpConfigs.customContents.filter(customContent => customContent.title === 'RtwText').pop();
        if ((nameConfig?.value_EN && language === Language.ENGLISH) ||
            (nameConfig?.value_EN && !nameConfig?.value_ES && language === Language.SPANISH)) {
            customRtwText = nameConfig?.value_EN;
        }
        else if (nameConfig?.value_ES && language === Language.SPANISH) {
            customRtwText = nameConfig?.value_ES;
        }
        else {
            customRtwText = defaultRtwLabel;
        }
    }
    else {
        customRtwText = defaultRtwLabel;
    }
    //FIXME: this is html not a label - this should not be done this way and it currently is breaking ADA
    return customRtwText;
};
