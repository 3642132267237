import React, { createContext, useState } from 'react';
import axios from 'axios';
import usePortalMessenger from '../hooks/usePortalMessenger';
import { getEnvironment } from '../utils/envUtils';
import { Environment } from '../types/Environment';
import PageLoader from '../components/PageLoader/PageLoader';
const nonProd = getEnvironment() === Environment.DEV || getEnvironment() === Environment.NON_PROD;
export const PortalStatusContext = createContext({
    maintenanceModeEnabled: false,
    maintenanceModeBackdoorAccessEnabled: false,
    isStatusLoading: false,
});
export const portalDownMessage_en = 'My Lincoln Portal is currently unavailable. We are sorry for the inconvenience. Please call us for additional support during this time.';
export const portalDownMessage_es = 'My Lincoln Portal no está disponible actualmente. Lamentamos los inconvenientes. Por favor llámenos para soporte adicional durante este tiempo.';
const getPortalStatus = () => axios.get('/portal-status');
const PortalStatusProvider = ({ children, }) => {
    const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState(false);
    const [maintenanceModeBackdoorAccessEnabled, setMaintenanceModeBackdoorAccessEnabled] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const { addMessage } = usePortalMessenger();
    const resolvePortalStatus = async () => {
        setIsStatusLoading(true);
        try {
            const { data: { maintenanceMode, maintenanceModeBackdoorAccess }, } = await getPortalStatus();
            setMaintenanceModeEnabled(maintenanceMode);
            setMaintenanceModeBackdoorAccessEnabled(maintenanceModeBackdoorAccess);
        }
        catch (_) {
            // Fail silently...
        }
        finally {
            setIsStatusLoading(false);
        }
    };
    useState(() => {
        resolvePortalStatus();
    });
    // useEffect(() => {
    //   if (maintenanceModeEnabled || maintenanceModeBackdoorAccessEnabled) {
    //     addMessage({ type: 'info', message: [portalDownMessage_en, portalDownMessage_es] });
    //   }
    // }, [maintenanceModeEnabled]);
    const value = {
        maintenanceModeEnabled,
        maintenanceModeBackdoorAccessEnabled,
        isStatusLoading,
    };
    return (<PortalStatusContext.Provider value={value}>
      {isStatusLoading ? (<div style={{ textAlign: 'center' }}>{<PageLoader id='portal-status-provider-spinner'/>}</div>) : (children)}
    </PortalStatusContext.Provider>);
};
export default PortalStatusProvider;
