import axios from 'axios';
import { UnauthenticatedError } from '../types/UnauthenticatedError';
import { UnauthorizedError } from '../types/UnauthorizedError';
export const getFeatureSplashByFunctionalArea = async (functionalArea, authHeader) => {
    const response = await axios.get(`/mlp-ui-service/services/v1/featureSplash?functionalArea=${functionalArea}`, {
        headers: {
            Authorization: authHeader,
        },
    });
    const status = response.status;
    if (status === 200)
        return response.data;
    else if (status === 401)
        throw new UnauthenticatedError('Invalid Access Token');
    else if (status === 403)
        throw new UnauthorizedError('User Unauthorized');
    else
        throw new Error('Unable to get Feature Splash');
};
