import React from 'react';
import { Modal, Button, Heading, Row, Col, Box, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { RabConfirmation } from '../components/RabReview';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from 'src/hooks/useAuthentication';
const CloseButton = ({ hideModal }) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const handleOnClick = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: 'close',
            event_version: TEALIUM_EVENT.EVENT_VERSION.CONFIRMATION,
            user_id: user?.webGUID
        });
        hideModal();
    };
    return (<Button buttonType={'secondary'} type={'button'} onClick={handleOnClick} data-testid={'closeButton'} aria-label={translation('closeText').toString()}>
      {translation('closeText').toString()}
    </Button>);
};
const PrintButton = ({ print }) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const handleOnClick = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: 'print',
            event_version: TEALIUM_EVENT.EVENT_VERSION.CONFIRMATION,
            user_id: user?.webGUID
        });
        window.print();
    };
    return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick} data-testid={'print'} aria-label={`${translation('printText')}`}>
      {`${translation('printText')}`}
    </Button>);
};
const ViewConfirmationButton = ({ showModal }) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const handleOnClick = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: `${translation('confirmationViewPrintText').toString()}`,
            event_version: TEALIUM_EVENT.EVENT_VERSION.CONFIRMATION,
            user_id: user?.webGUID
        });
        showModal();
    };
    return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick} data-testid='viewConfirmationButton' aria-label={`${translation('confirmationViewPrintText')}`}>
      {`${translation('confirmationViewPrintText').toString()}`}
    </Button>);
};
const RabViewConfirmationModal = (props) => {
    const { t: translation } = useTranslation('rabTranslation');
    const headerComponent = () => {
        return (<Heading elemType={'h1'} theme={themes.lincoln}>
        {translation('viewSubmissionHeaderText').toString()}
      </Heading>);
    };
    return (<Modal theme={themes.lincoln} style={{ margin: '2rem', overflowX: 'scroll' }} headerComponent={headerComponent()} modalOpenRenderButton={prop => <ViewConfirmationButton {...prop}/>} footerPrimaryRenderButton={PrintButton} footerSecondaryRenderButton={CloseButton} size={'large'} disabledBackdropClick={true}>

      <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'} data-testid='viewConfirmation'>
        <Row style={{ marginBottom: '5px' }}>
          <Col sm={6} md={6} lg={6}>
            {translation('reportABirthHeaderText').toString()}
          </Col>
        </Row>
      </Heading>
      <Box>
        <Row>
          <Col xs={12} sm={12}>
            <RabConfirmation review={false} claimNumber={props.claimNumber} report={props.report}/>
          </Col>
        </Row>
      </Box>
    </Modal>);
};
export default RabViewConfirmationModal;
