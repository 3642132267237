// @ts-nocheck
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Heading } from '@digitools/honest-react';
import FilterMyReport from 'src/packages/createMyReport/components/filter-my-report/FilterMyReport';
import ReportFieldPage from 'src/packages/createMyReport/components/select-field-report/ReportFieldPage';
import ReportFieldDetailPage from 'src/packages/createMyReport/components/run-my-report/report-preview/ReportFieldDetailPage';
import MyReportFilterDetail from 'src/packages/createMyReport/components/run-my-report/report-preview/MyReportFilterDetail';
const Wrapper = ({ children }) => {
    return (<>
      <Heading style={{ marginTop: '20px' }}> Create my report </Heading>
      {children}
    </>);
};
const CreateMyReportRouter = () => {
    return (<>
      <Wrapper>
        <Routes>
          <Route path={`report-field`} element={<ReportFieldPage />}/>
          <Route path={`run-my-report`} element={<Outlet />}>
            <Route index element={<>
                  <MyReportFilterDetail />
                  <ReportFieldDetailPage />
                </>}/>
          </Route>
          <Route path={`*`} element={<FilterMyReport />}/>
        </Routes>
      </Wrapper>
    </>);
};
export default CreateMyReportRouter;
