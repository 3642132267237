import React from 'react';
import { Modal, CompositeTable as Table, Button, themes } from '@digitools/honest-react';
import SubsLocsColumnModal from '../../../../Constants/SubsLocsColumns';
const SubsAndLocsViewModal = ({ subsAndLocs }) => {
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} data-testid={'openModal'}>
        View subsidiaries/locations
      </Button>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        return (<Button buttonType={'primary'} type={'button'} onClick={() => { hideModal(); }} data-testid={'closeSubsAndLocs'}>
        Close
      </Button>);
    };
    return (<Modal style={{ margin: '2rem', overflowX: 'scroll' }} headerText={'View subsidiaries/locations'} modalOpenRenderButton={RenderOpenButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} size={'large'} theme={themes.lincoln} disabledBackdropClick={true}>
      <Table data={subsAndLocs} defaultPageSize={10} columns={SubsLocsColumnModal} paginated={true} theme={themes.lincoln} filterable={true} isSelectable={false} rowIdentifier={'locationId'} data-testid={'subLocTable'}/>
    </Modal>);
};
export default SubsAndLocsViewModal;
