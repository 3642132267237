import { Button, Col, Icon, Row, TextInput, ToolTip } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import useTranslate from 'src/hooks/useTranslate';
import DisplayField from './DisplayField';
import { Text, useFormState } from 'informed';
import { RIA_TEXT } from '../constants/RiaConstants';
import { absenceConfigDateValidation, validateDuration } from '../utils/validationUtils';
import styled from 'styled-components';
export const deriveDurationField = (hours, minutes) => {
    let newHours = hours;
    let newMinutes = minutes;
    if (hours.length === 1) {
        newHours = '0' + hours;
    }
    if (minutes.length === 1) {
        newMinutes = '0' + minutes;
    }
    return `${newHours}:${newMinutes}`;
};
const StyledScreenReaderOnlyText = styled.span `
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;
// parameter of an absence object
// creates display fields if not editing
// button to turn on edit state
// form fields will apply to <Form> that surrounds this component
const PreviousAbsence = ({ absence, index, durationInterval, policyItem, }) => {
    const { t } = useTranslate();
    const [isEditing, setIsEditing] = useState(false);
    const { values, errors } = useFormState();
    const [initialValue, setInitialValue] = useState(deriveDurationField(absence.absenceHours, absence.absenceMinutes));
    // update initial value if the absence changes (happens when user clicks to 'report more time off')
    useEffect(() => {
        setInitialValue(deriveDurationField(absence.absenceHours, absence.absenceMinutes));
    }, [absence]);
    /* Show Edit check */
    let showEditSuccess = false;
    // @ts-ignore
    if (isEditing && values?.previousAbsences && values.previousAbsences[index]) {
        // @ts-ignore
        let val = values.previousAbsences[index].absenceDuration;
        let validation = validateDuration(val, durationInterval, t);
        //@ts-ignore
        if (val && initialValue !== val && val.length === 5 && !validation) {
            showEditSuccess = true;
        }
    }
    const formatDuration = (value) => {
        if (value[1] === ':') {
            return [/[0-9]/, /:/, /[0-5]/, /[0-9]/];
        }
        else {
            return [/\d/, /\d/, ':', /[0-5]/, /[0-9]/];
        }
    };
    const editButtonShow = () => {
        const editBlocked = !absenceConfigDateValidation(absence.absenceDate, policyItem, true);
        return editBlocked;
    };
    return (<>
      <Row style={{ minHeight: '50px' }} role='region' aria-label={`Information for absence number ${index + 1}`}>
        <Col size={12}>
          <Row>
            <Col md={5} size={4} className='px-0'>
              <DisplayField label={t(RIA_TEXT.dateLabel)} value={absence.absenceDate}/>
            </Col>
            <Col size={5} className='px-0'>
              {isEditing ? (<TextInput data-testid={`edit-duration-input-${index}`} style={{ marginBottom: 0 }} field={'absenceDuration'} label={t(RIA_TEXT.durationLabel)} placeholder={t(RIA_TEXT.durationLabel)} validateOnBlur={true} validate={(value) => validateDuration(value, durationInterval, t)} initialValue={initialValue} success={showEditSuccess} 
        //@ts-ignore FIXME: typing error
        formatter={formatDuration}/>) : (<DisplayField label={t(RIA_TEXT.durationLabel)} value={initialValue}/>)}
            </Col>

            {showEditSuccess && (<>
                <Icon className='pt-3' name={'success'} color={'success'} size={'sm'} aria-hidden={true}/>
                <StyledScreenReaderOnlyText>
                  <a href='#riaSubmitButton'>
                    {`You have successfully edited absence number ${index + 1}. Press enter to jump to submit button.`}
                  </a>
                </StyledScreenReaderOnlyText>
              </>)}

            {editButtonShow() ? (<Col style={{ display: 'flex' }}>
                {isEditing ? (<Button data-testid={`cancel-${index}`} aria-label={`Revert changes for absence number ${index + 1}`} className='pl-1 pr-2' buttonType='text' type='button' onClick={() => setIsEditing(false)}>
                    <Icon className='mr-1 d-md-inline d-none' name='close' size='xs' color='secondary'/>
                    {t(RIA_TEXT.cancelButton)}
                  </Button>) : (<Button data-testid={`edit-${index}`} aria-label={`Edit absense number ${index + 1}`} className='pl-1 pr-1' buttonType='text' type='button' onClick={() => setIsEditing(true)}>
                    <Icon className='mr-1' name='edit' color='secondary'/>
                    {t(RIA_TEXT.editButton)}
                  </Button>)}
              </Col>) : (<Col style={{ margin: 'auto 1.25rem auto 1.5rem' }}>
                <ToolTip iconColor={'secondary'} iconSize={'small'} toolText={t(RIA_TEXT.editToolTip)} style={{ marginTop: 'auto', marginBottom: 'auto', overflowWrap: 'break-word' }}/>
              </Col>)}
          </Row>
        </Col>
      </Row>
      {/* Hidden fields -- Gets data to the final submit without extra state vars */}
      {isEditing && <Text field='absenceDate' initialValue={absence.absenceDate} style={{ display: 'none' }}/>}
    </>);
};
export default PreviousAbsence;
