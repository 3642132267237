import React from 'react';
import { Container, Heading } from '@digitools/honest-react';
import { Language } from './../../types/Language';
import useExperience from '../../hooks/useExperience';
import { Experience } from '../../types/Experience';
const ServiceUnavailable = props => {
    const { language, experience } = useExperience();
    const errorHeaderEnglish = "We're sorry! Something went wrong on our end.";
    const errorHeaderSpanish = '¡Lo sentimos! Algo salió mal en nuestro extremo.';
    const errorTextEnglish = 'Please go back and try again or go to your home page. If the problem persists, contact 1-800-431-2958 for more information.';
    const errorTextSpanish = 'Por favor, vuelva e intente de nuevo o vaya a su página de inicio. Si el problema persiste, comuníquese al 1-800-431-2958 para obtener más información.';
    const imageStyle = {
        display: 'flex',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const errorHeaderGenerator = (traceId) => {
        const messages = [errorHeaderEnglish, errorHeaderSpanish];
        if (traceId) {
            // Apparently Error is Error in spanish.
            messages[0] = messages[0].concat(` (Error: ${traceId})`);
            messages[1] = messages[1].concat(` (Error: ${traceId})`);
        }
        return messages;
    };
    return (<Container data-testid='service-unavailable' style={{ paddingTop: '2rem' }} data-public={true}>
      <div style={{ alignItems: 'center' }}>
        <Heading elemType={'h6'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} id='errorHeader'>
          {language === Language.SPANISH
            ? errorHeaderGenerator(props.traceId)[1]
            : errorHeaderGenerator(props.traceId)[0]}
        </Heading>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            marginBottom: '2rem',
        }} id='errorText'>
          {language === Language.SPANISH ? errorTextSpanish : errorTextEnglish}
        </div>
      </div>
      {experience === Experience.EMPLOYEE ? (<div style={imageStyle}>
          <img data-testid='ee-image' src='/assets/images/landing-page-ee.png'/>
        </div>) : (<div style={imageStyle}>
          <img data-testid='er-image' src='/assets/images/landing-page-er.png'/>
        </div>)}
    </Container>);
};
export default ServiceUnavailable;
