import React from 'react';
import { Button, Modal, themes } from '@digitools/honest-react';
import { useFormApi } from 'informed';
import { workStatesModel, workProvincesModel } from './initialStateAndProvinces';
import WorkLocations from './workLocations';
import { getSelectedClaimProductsFromFormApi } from '../FilterMyReportValidation';
const WorkLocationModal = ({ onSave, prevSelectedStates, prevSelectedProvinces, reportFilterCategory, }) => {
    const formApi = useFormApi();
    const handleStates = (state) => {
        const isStateSelected = formApi.getValue(state.stateCode);
        if (state.stateCode === 'AWS') {
            workStatesModel.forEach((workState) => {
                formApi.setValue(workState.stateCode, isStateSelected);
            });
        }
        else {
            const countOfSelectedStates = workStatesModel
                .filter((workState) => workState.stateCode !== 'AWS')
                .map((workState) => formApi.getValue(workState.stateCode))
                .filter(status => status === true).length;
            formApi.setValue('AWS', countOfSelectedStates === workStatesModel.length - 1);
        }
    };
    const handleProvinces = (province) => {
        const isLocationSelected = formApi.getValue(province.provinceCode);
        if (province.provinceCode === 'AWL') {
            workProvincesModel.forEach((workProvince) => {
                formApi.setValue(workProvince.provinceCode, isLocationSelected);
            });
        }
        else {
            const countOfSelectedLocations = workProvincesModel
                .filter((workProvince) => workProvince.provinceCode !== 'AWL')
                .map((workProvince) => formApi.getValue(workProvince.provinceCode))
                .filter(status => status === true).length;
            formApi.setValue('AWL', countOfSelectedLocations === workProvincesModel.length - 1);
        }
    };
    const clearAllStates = () => {
        workStatesModel.forEach((workState) => {
            formApi.setValue(workState.stateCode, false);
        });
    };
    const clearAllProvinces = () => {
        workProvincesModel.forEach((workProvince) => {
            formApi.setValue(workProvince.provinceCode, false);
        });
    };
    const RenderOpenModalButton = ({ showModal, reportFilterCategory }) => {
        const selectedProducts = (typeof formApi.getValue === 'function' && getSelectedClaimProductsFromFormApi(formApi)) || [];
        const handleOnClick = () => {
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} disabled={reportFilterCategory === '' || (reportFilterCategory === 'Claim' && !selectedProducts.length)}>
        Modify work locations
      </Button>);
    };
    const FooterSecondaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            workStatesModel.forEach((workStateModel) => {
                prevSelectedStates.indexOf(workStateModel) >= 0
                    ? formApi.setValue(workStateModel.stateCode, true)
                    : formApi.setValue(workStateModel.stateCode, false);
            });
            workProvincesModel.forEach((workProvinceModel) => {
                prevSelectedProvinces.indexOf(workProvinceModel) >= 0
                    ? formApi.setValue(workProvinceModel.provinceCode, true)
                    : formApi.setValue(workProvinceModel.provinceCode, false);
            });
            hideModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick}>
        Cancel
      </Button>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            // Read states and locations that user selected.
            const countOfSelectedStates = workStatesModel
                .filter((workState) => workState.stateCode !== 'AWS')
                .map((workState) => formApi.getValue(workState.stateCode))
                .filter(status => status === true).length;
            const countOfSelectedLocations = workProvincesModel
                .filter((workLocation) => workLocation.provinceCode !== 'AWL')
                .map((workLocation) => formApi.getValue(workLocation.provinceCode))
                .filter(status => status === true).length;
            const userSelectedStateAndLocationsCount = countOfSelectedStates + countOfSelectedLocations;
            if (userSelectedStateAndLocationsCount > 0) {
                onSave(userSelectedStateAndLocationsCount);
            }
            else {
                // Updating form to have all states and locations selected when user is not selected any states or locations.
                const selectAllStates = workStatesModel
                    .map((workState) => {
                    formApi.setValue(workState.stateCode, true);
                    return workState;
                })
                    .filter((workState) => workState.stateCode !== 'AWS').length;
                const selectAllLocations = workProvincesModel
                    .map((workLocation) => {
                    formApi.setValue(workLocation.provinceCode, true);
                    return workLocation;
                })
                    .filter((workLocation) => workLocation.provinceCode !== 'AWL').length;
                onSave(selectAllStates + selectAllLocations);
            }
            hideModal();
        };
        return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick}>
        Save Changes
      </Button>);
    };
    return (<Modal hasCloseIcon={false} headerText='Employee work location' modalOpenRenderButton={props => <RenderOpenModalButton reportFilterCategory={reportFilterCategory} {...props}/>} footerPrimaryRenderButton={FooterSecondaryRenderButton} footerSecondaryRenderButton={FooterPrimaryRenderButton} size={'large'} theme={themes.lincoln} disabledBackdropClick={true}>
      <WorkLocations onStateSelect={handleStates} onProvinceSelect={handleProvinces} onClearAllState={clearAllStates} onClearAllProvince={clearAllProvinces} workStates={workStatesModel} workProvinces={workProvincesModel} disableForm={false}/>
    </Modal>);
};
export default WorkLocationModal;
