import axios from 'axios';
function ResetPasswordApi() {
    const resetPassword = async (loginId, authHeader) => {
        return axios.post(`/auth-service/services/v1/profile/${loginId}/password`, null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
        });
    };
    return { resetPassword };
}
export default ResetPasswordApi;
