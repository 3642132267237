import React from 'react';
import { ConfirmationText } from '../../../i18n/ConfirmationText';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../hooks/useTranslate';
import { RedBulletList } from '../../../../../components/Formatting/StyledComponents';
const OurNextSteps = (props) => {
    const { t } = useTranslate();
    if (props.approvalMessage === ConfirmationText.fullyApproved) {
        return (<>
        <Heading theme={themes.lincoln} elemType={'h3'} color={'grayDark'} style={{ marginBottom: '0.5rem', marginTop: '2.5rem' }} data-testid={'our-next-steps-heading'}>
          {t(ConfirmationText.ourNextSteps)}
        </Heading>
        <RedBulletList>
          <li data-testid={'notify-employer'}><span>{t(ConfirmationText.notifyEmployer)}</span></li>
          <li data-testid={'mail-letter'}><span>{t(ConfirmationText.mailALetter)}</span></li>
        </RedBulletList>
      </>);
    }
    else if (props.approvalMessage === ConfirmationText.partiallyApproved) {
        return (<>
        <Heading theme={themes.lincoln} elemType={'h3'} color={'grayDark'} style={{ marginBottom: '0.5rem', marginTop: '2.5rem' }} data-testid={'our-next-steps-heading'}>
          {t(ConfirmationText.ourNextSteps)}
        </Heading>
        <RedBulletList>
          <li data-testid={'notify-employer'}><span>{t(ConfirmationText.notifyEmployer)}</span></li>
          <li data-testid={'mail-letter'}><span>{t(ConfirmationText.mailALetter)}</span></li>
          <li data-testid={'review-dependents'}><span>{t(ConfirmationText.reviewDependents)}</span></li>
          <li data-testid={'necessary-info-dependents'}><span>{t(ConfirmationText.necessaryInformationDependents)}</span></li>
        </RedBulletList>
      </>);
    }
    else {
        return (<>
        <Heading theme={themes.lincoln} elemType={'h3'} color={'grayDark'} style={{ marginBottom: '0.5rem', marginTop: '2.5rem' }} data-testid={'our-next-steps-heading'}>
          {t(ConfirmationText.ourNextSteps)}
        </Heading>
        <RedBulletList>
          <li data-testid={'review'}><span>{t(ConfirmationText.review)}</span></li>
          <li data-testid={'necessary-info'}><span>{t(ConfirmationText.necessaryInformation)}</span></li>
        </RedBulletList>
      </>);
    }
};
export default OurNextSteps;
