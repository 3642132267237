import { Form, Loader, Spinner } from '@digitools/honest-react';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from '../../../../hooks/useExperience';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import useTranslate from '../../../../hooks/useTranslate';
import { downloadNoticeOfInformationPractices, SubmitApplication } from '../../api/EoiApiCalls';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import StepperButtons from '../../components/StepperButtons';
import useEoiContext from '../../hooks/useEoiContext';
import ReviewAndSignText from '../../i18n/ReviewAndSignText';
import ESignature from './components/sign/ESignature';
import LegalText from './components/sign/LegalText';
import SignaturePageHeader from './components/sign/SignaturePageHeader';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import LegalFormNumbers from '../../components/LegalFormNumbers';
import { isSitusStateNY } from '../../utils/HelperFunctions';
// Need this function to make sure that the interviewAnswers on the provider actually updated with the signatures
// before we make the call to eoi/muw.
const checkIfApplicationSigned = (interviewAnswers) => {
    if (interviewAnswers && interviewAnswers.applicants) {
        if (interviewAnswers.applicantTypes.employee && interviewAnswers.applicants.employee) {
            if (!interviewAnswers.applicants.employee.signature) {
                return false;
            }
        }
        if (interviewAnswers.applicantTypes.spouse && interviewAnswers.applicants.spouse) {
            if (!interviewAnswers.applicants.spouse.signature) {
                return false;
            }
        }
        if (interviewAnswers.applicantTypes.dependent && interviewAnswers.applicants.dependents) {
            interviewAnswers.applicants.dependents.forEach(dependent => {
                if (!dependent.signature) {
                    return false;
                }
            });
        }
        return true;
    }
    else {
        return false;
    }
};
export const deriveEoiEmpIdEventType = (isDisableEmployeeId, employeeId) => {
    if (isDisableEmployeeId) {
        return TEALIUM_EVENT.EVENT_TYPE.EOI_EMP_ID_PRE_POPULATED;
    }
    else if (employeeId) {
        return TEALIUM_EVENT.EVENT_TYPE.EOI_EMP_ID_ENTERED;
    }
    else {
        return TEALIUM_EVENT.EVENT_TYPE.EOI_EMP_ID_EMPTY;
    }
};
const Sign = (props) => {
    const { t } = useTranslate();
    const { authHeader, user } = useAuthentication();
    const { language } = useExperience();
    const { trackEvent, trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EOI,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EOI.SIGN,
        });
    }, []);
    const { initialInformation, interviewAnswers, setApplicants, setQuestionSubmissionResponse, customContent, questionSubmissionResponse, legalText, reflexiveQuestions, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, } = useEoiContext();
    const [downloadError, setDownloadError] = useState();
    const [isSigned, setIsSigned] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [applicationNotAccepted, setApplicationNotAccepted] = useState(false);
    const { trackClickWithPage } = useGoogleAnalytics();
    useEffect(() => {
        if (isSigned &&
            initialInformation &&
            interviewAnswers &&
            !questionSubmissionResponse &&
            !isSubmitting &&
            legalText) {
            if (checkIfApplicationSigned(interviewAnswers)) {
                const submission = {
                    ...interviewAnswers,
                    initialInformation,
                    customContent,
                    reflexiveQuestions,
                    legalText,
                };
                setIsSubmitting(true);
                SubmitApplication(submission, authHeader(), language)
                    .then((data) => {
                    setIsSubmitting(false);
                    setQuestionSubmissionResponse(data.data);
                    setIsSigned(false);
                })
                    .catch((error) => {
                    // HACK -- for S1/MUW services sucking and timing out
                    if (error.status === 504) {
                        setSubmissionError(false); // dont set the error in this case, let pass into review page
                        setIsSubmitting(false); // no longer submitting (technically timed out)
                        props.setIsSubmitted(true); // have to flip this to true (normally happens when response comes back)
                    }
                    else {
                        setSubmissionError(true);
                        setIsSubmitting(false);
                        setIsSigned(false);
                    }
                });
            }
        }
    }, [isSigned, interviewAnswers]); // isSigned gets set to true on form submit.
    useEffect(() => {
        if (questionSubmissionResponse) {
            if (questionSubmissionResponse.applicationAccepted) {
                props.setIsSubmitted(true);
            }
            else {
                setApplicationNotAccepted(true);
            }
        }
    }, [questionSubmissionResponse]);
    const submitForm = (values) => {
        trackClickWithPage('submit', '/customer/eoi/sign');
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        const date = format(new Date(), 'yyyy-MM-dd');
        // Go through values
        // Add signature to corresponding applicant
        if (interviewAnswers && interviewAnswers.applicants) {
            const employee = interviewAnswers.applicants.employee;
            const spouse = interviewAnswers.applicants.spouse;
            const dependents = interviewAnswers.applicants.dependents;
            let agentSignature = undefined;
            let payrollDeductionSignature = undefined;
            const isDisableEmployeeId = employee?.employmentInformation?.disableEmployeeId;
            const employeeId = employee?.employmentInformation?.employeeId;
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: deriveEoiEmpIdEventType(isDisableEmployeeId, employeeId),
                event_name: 'Submit',
            });
            if (employee) {
                if (values.employee) {
                    employee.signature = {
                        date,
                        signeeName: values.employee,
                    };
                }
            }
            if (interviewAnswers.applicantTypes.spouse && spouse) {
                if (values.spouse) {
                    spouse.signature = {
                        date,
                        signeeName: values.spouse,
                    };
                }
            }
            if (values.agentSignature) {
                agentSignature = {
                    date,
                    signeeName: values.agentSignature,
                };
            }
            if (values.payrollDeductionSignature) {
                payrollDeductionSignature = {
                    date,
                    signeeName: values.payrollDeductionSignature,
                };
            }
            if (interviewAnswers.applicantTypes.dependent && dependents) {
                if (values.dependents) {
                    // Loop through all dependents on the provider and add the corresponding signature.
                    // Object on the form state is using dependent id as the keys,
                    // ex.
                    // {
                    //   'id0': 'signature0',
                    //   'id1': 'signature1',
                    // }
                    dependents.forEach(dependent => {
                        dependent.signature = {
                            date,
                            signeeName: values.dependents[dependent.dependentId], // dependent SHOULD have a dependent id.
                        };
                    });
                }
            }
            setApplicants({
                employee,
                spouse,
                dependents,
                agentSignature,
                payrollDeductionSignature,
                applicantInitials: values.applicantInitials,
                disableAddingDependents: interviewAnswers.applicants.disableAddingDependents,
            });
            setIsSigned(true);
        }
    };
    const DownloadNoipButton = () => {
        return (<Button theme={themes.lincoln} type={'button'} buttonType={'text'} onClick={() => {
                trackClickWithPage('downloadNoip', '/customer/eoi/sign');
                downloadNoticeOfInformationPractices(authHeader(), language, t(ReviewAndSignText.noticeOfInformationPractices), t(ReviewAndSignText.downloadError), setDownloadError, initialInformation.employerConfiguration.situsStateCode);
            }} style={{ marginLeft: '1.5rem', padding: 0 }} data-testid={'download-noip-button'}>
        <Icon color='inherit' name='pdf' style={{ marginRight: '.25rem' }}/>
        <strong>{t(ReviewAndSignText.noticeOfInformationPractices)}</strong>
      </Button>);
    };
    const back = () => {
        trackClickWithPage('back', '/customer/eoi/sign');
        props.back();
    };
    const fraudLanguageTextIndexes = [1, 2];
    const fraudTextNY = legalText?.applicationLegalText?.filter((text, index) => fraudLanguageTextIndexes.includes(index));
    return interviewAnswers && interviewAnswers.applicants && legalText ? (<Form onSubmit={submitForm} data-testid={'sign'} data-public={true}>
      <Loader id='sign-form-spinner' isShown={isSubmitting} loadingText={t(ReviewAndSignText.submittingApplication)} data-testid={'loader'}>
        {downloadError && (<Alert style={{ marginTop: '0.5rem' }} type='error' closeable={true} onClose={() => setDownloadError(undefined)} data-testid={'download-noip-alert'}>
            {downloadError}
          </Alert>)}
        {submissionError && (<Alert style={{ marginTop: '0.5rem' }} type='error' closeable={true} onClose={() => setSubmissionError(false)} data-testid={'submission-alert'}>
            {t(ReviewAndSignText.errorSubmittingApplication)}
          </Alert>)}
        <SignaturePageHeader />
        <LegalText />
        <hr />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Checkbox theme={themes.lincoln} field={'legalConditionsAccepted'} required={true} label={t(ReviewAndSignText.checkboxText)} checkboxButtonStyles={{ top: '0.6rem' }} data-testid={'legal-conditions-checkbox'}/>
          {initialInformation && <DownloadNoipButton />}
        </div>
        {isSitusStateNY(initialInformation) && (<div style={{ margin: '2em 0px 1em 1.5em' }} data-testid="fraud-warning-ny">
            <strong>{t(ReviewAndSignText?.eSignatureWarningNY)}</strong>
            <br />
            {fraudTextNY?.map(applicationLegalText => {
                return (<div key={applicationLegalText.seq}>
                  <strong data-testid={`application-legal-text-${applicationLegalText.seq}-ny`} key={applicationLegalText.seq}>{t(applicationLegalText.text)}</strong>
                  <br />
                </div>);
            })}
          </div>)}

        <ESignature employee={interviewAnswers.applicants.employee} spouse={interviewAnswers.applicants.spouse}/>
        {interviewAnswers && interviewAnswers.applicantTypes.dependent && (<ESignature dependents={interviewAnswers.applicants.dependents}/>)}
        
        {initialInformation && initialInformation.employerConfiguration.situsStateCode === 'NC' && (<ESignature agentSignature={true}/>)}

        {initialInformation && initialInformation.employerConfiguration.situsStateCode === 'CA' && (<ESignature payrollSignature={true}/>)}

        {applicationNotAccepted && (<Alert style={{ marginTop: '0.5rem' }} type='error' closeable={false} data-testid={'application-not-accepted-alert'}>
            {t(ReviewAndSignText.applicationNotAccepted)}
          </Alert>)}
        <SaveForLaterErrorAlert />
        <LegalFormNumbers />
        <StepperButtons continueButtonType={'submit'} backButtonOnClick={back} page={'/customer/eoi/sign'}/>
      </Loader>
    </Form>) : (<Spinner id='sign-spinner'/>);
};
export default Sign;
