import { Button, Icon, Modal, Link } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useTranslate from 'src/hooks/useTranslate';
const StyledPelement = styled('p') `
color: black;
font-weight:normal
margin:2rem 1rem;
`;
export const RemoveUserModalText = {
    headerText: {
        english: 'Confirm',
        spanish: 'Confirmar',
    },
    modalText: {
        english: 'If you cancel now, the user you selected will not be removed. By clicking OK, the selected user will permanently lose access to the Employer functions of the portal.',
        spanish: 'Si cancelas ahora, el usuario que seleccionaste no será eliminado. Al hacer clic en Aceptar, el usuario seleccionado perderá permanentemente el acceso a las funciones de Empleador del portal.',
    },
};
const RemoveUserModal = ({ removeSelectUser, user }) => {
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_USER_MODAL,
                event_name: 'Remove Employer Access',
                event_status: TEALIUM_EVENT.EVENT_STATUS.OPEN,
            });
            showModal();
        };
        return (<Link id={'remove-user-openmodal'} data-testid='openModal' aria-label='Remove Employer Access' onClick={handleOnClick}>
        <b>
          <Icon name={'trash'} color={'accent1Light'} id='icon-remove-user' size={'xs'}/> Remove Employer Access
        </b>
      </Link>);
    };
    const RenderCloseButton = ({ hideModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_USER_MODAL,
                event_name: 'Remove Employer Access',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL,
            });
            hideModal();
        };
        return (<Button id={'remove-user-closemodal'} type='button' buttonType='text' data-testid='hideModal' onClick={handleOnClick} aria-label='Cancel' style={{ float: 'left' }}>
        Cancel
      </Button>);
    };
    const RenderOkButton = ({ hideModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_USER_MODAL,
                event_name: 'Remove Employer Access',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CONFIRM,
            });
            removeSelectUser(user);
            hideModal();
        };
        return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick} aria-label='Ok' data-testid={'saveSubLocs'}>
        OK
      </Button>);
    };
    return (<Modal headerText={t(RemoveUserModalText.headerText)} hasCloseIcon={true} modalOpenRenderButton={RenderOpenButton} footerPrimaryRenderButton={RenderOkButton} footerSecondaryRenderButton={RenderCloseButton} size='large' disabledBackdropClick={true}>
        <StyledPelement id='Remove Employer Access Message'>
          {t(RemoveUserModalText.modalText)}
        </StyledPelement>
      </Modal>);
};
export default RemoveUserModal;
