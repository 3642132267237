import { useEffect } from 'react';
import { decode } from 'status/utilities/hash';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { useParams } from 'react-router-dom';
import { GetDeductibles } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
const UseSetCurrentMember = () => {
    const { subscriber, setCurrentMember, setInfoLoading, setSuccess, setErrorMessage } = useDentalDataContext();
    const { memberId } = useParams();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    useEffect(() => {
        if (subscriber && (!memberId || subscriber.id === decode(memberId))) {
            setCurrentMember(subscriber);
        }
        else if (memberId && subscriber?.dependents) {
            const dependent = subscriber.dependents.find((dep) => dep.id === decode(memberId));
            if (dependent) {
                //check we got a match
                setCurrentMember(dependent);
                setInfoLoading(true);
                GetDeductibles(authHeader(), language === 'es' ? 'SPANISH' : 'ENGLISH', dependent.memberType, dependent.id).then(response => {
                    const data = response?.data?.data;
                    if (data?.errors) {
                        setSuccess(false);
                        setErrorMessage(data.errors.message);
                    }
                    else {
                        dependent.deductiblesAndCoinsurance = data?.deductiblesAndCoinsurance;
                    }
                    setInfoLoading(false);
                });
            }
        }
    }, [authHeader, language, subscriber, memberId]);
};
export default UseSetCurrentMember;
