import { themes } from "@digitools/honest-react";
import styled from "styled-components";
// TODO: transition this component to honest
// TODO: can make this use the passed in theme
// TODO: can make this configurable for backgroundColor & textColor (from DropdownMenu)
// TODO: clean up some of the leftover css
export const DropdownMenuContainer = styled('div') `
  position: absolute;
  margin-top: .5rem; // How far away from the button the dropdown renders - lets the 11px caret overlap some
  left: ${({ dropdownPosition }) => dropdownPosition === 'center' ? '-50%' : dropdownPosition === 'left' ? '0' : '50%'};
  z-index: 2000; // temporary until old header is gone (sits at 999)
  border: 1px solid rgba(0, 0, 0, 0.14);
  /* box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */ //add this back in? 
  /* background-color: ${themes.lincoln.palette.gray}; */
  background-color: ${themes.lincoln.palette.white};
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: initial;
  transition: transform .2s ease-in-out;
  transform-origin: top center;
  transform: scaleY(${({ isOpen = false }) => isOpen ? '1' : '0'});
  a { // auto-margin & styling on link elements within the dropdown
    width: 100%;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-clip: padding-box;
    &:hover, &:focus {
      text-decoration: none;
      background-color: ${themes.lincoln.palette.grayLighter};
      color: ${themes.lincoln.palette.primaryDark};
    }
  }
  a:first-child { // prevents the first link from overlapping with the absolutely positioned close icon
    padding-right: 2rem;  
  }
  &:before { // Caret border
    position: absolute;
    /// put the caret in the right position depending on the dropdownPosition */
    left: calc(${({ dropdownPosition }) => dropdownPosition === 'center' ? '50%' : dropdownPosition === 'left' ? '25%' : '75%'} - 11px); 
    top: -11px;
    z-index: 20;
    width: 0;
    height: 0;
    // Triangle Time 🔺
    content: '';
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    /* border-bottom: 11px solid ${themes.lincoln.palette.gray}; */
    border-bottom: 11px solid rgba(0, 0, 0, 0.14);
    /* border-bottom: 11px solid ${themes.lincoln.palette.white}; */
  }
  &:after { // Caret fill
    position: absolute;
    // put the caret in the right position depending on the dropdownPosition */
    left: calc(${({ dropdownPosition }) => dropdownPosition === 'center' ? '50%' : dropdownPosition === 'left' ? '25%' : '75%'} - 10px); // not perfect with variable width dropdowns (%of height of parent) + (width of caret)
    top: -10px;
    z-index: 20;
    width: 0;
    height: 0;
    // Triangle Time 🔺
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    /* border-bottom: 10px solid ${themes.lincoln.palette.gray}; */
    border-bottom: 10px solid ${themes.lincoln.palette.white}; 
  }
`;
export const DropdownCloseButton = styled('button') `
  cursor: pointer;
  position: absolute;
  border: none;
  background-color: rgb(0,0,0,0);
  top: 0;
  right: 0;
  padding: .5rem; // for increased clickable area
  padding-top: 1.25rem;
  padding-right: 1rem;
`;
