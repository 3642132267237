import axios from 'axios';
function RegisterUserApi() {
    const registerUser = async (body, captcha) => {
        return axios.post(`/auth-service/services/v1/register?g-recaptcha-response=${captcha}`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };
    const validateCompanyCode = async (companyCode) => {
        return axios.get(`/auth-service/services/v1/register/validate-code/${companyCode}`);
    };
    return { registerUser, validateCompanyCode };
}
export default RegisterUserApi;
