import React from 'react';
import { Experience, ExperienceCode } from '../types/Experience';
import { Product } from '../types/Product';
import { Language } from '../types/Language';
// @ts-ignore - types dependency breaks IE
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
export const ExperienceContext = React.createContext({
    experience: Experience.PUBLIC,
    experienceCode: ExperienceCode.PUBLIC,
    product: Product.NONE,
    language: Language.ENGLISH,
    changeLanguage: language => { },
    toggleLanguage: () => { },
    setExperience: experience => { },
    getExperience: () => Experience.PUBLIC,
    getExperienceCode: () => ExperienceCode.PUBLIC,
    setExperienceCode: experienceCode => { },
});
export class ExperienceProvider extends React.Component {
    state = {
        experience: Experience.PUBLIC,
        experienceCode: ExperienceCode.PUBLIC,
        product: Product.NONE,
        language: Language.ENGLISH,
    };
    currentExperience = Experience.PUBLIC;
    currentExperienceCode = ExperienceCode.PUBLIC;
    componentDidMount() {
        this.updateExperience();
        this.updateProduct();
        this.updateLanguage();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.updateProduct();
            this.updateExperience();
        }
        if (this.props.location.search !== prevProps.location.search) {
            this.updateLanguage();
        }
    }
    updateExperience() {
        let currentExperienceState = this.state.experience;
        let currentExperienceCode = this.state.experienceCode;
        if (window.location.pathname.includes('employer')) {
            currentExperienceState = Experience.EMPLOYER;
            currentExperienceCode = ExperienceCode.EMPLOYER;
        }
        if (window.location.pathname.includes('employee')) {
            currentExperienceState = Experience.EMPLOYEE;
            currentExperienceCode = ExperienceCode.EMPLOYEE;
        }
        if (window.location.pathname.includes('ria') || window.location.pathname.includes('eoi')) {
            currentExperienceState = Experience.EMPLOYEE;
            currentExperienceCode = ExperienceCode.EMPLOYEE;
        }
        this.currentExperience = currentExperienceState;
        this.currentExperienceCode = currentExperienceCode;
        this.setState({ experience: currentExperienceState, experienceCode: currentExperienceCode });
    }
    updateProduct() {
        const { pathname } = this.props.location;
        const product = pathname.indexOf('/status') > -1 ? Product.STATUS : Product.NONE;
        this.setState({ product });
    }
    updateLanguage() {
        const parsed = queryString.parse(this.props.location.search);
        // default language to English if there's no param, or if parsing doesn't match
        let language = Language.ENGLISH;
        if (parsed.lang) {
            // chance for this to be array, make typescript happy by forcing it to string
            let lang = parsed.lang.toString();
            lang = lang.toLowerCase();
            if (lang === 'en-us' || lang === 'en') {
                language = Language.ENGLISH;
            }
            else if (lang === 'es') {
                language = Language.SPANISH;
            }
        }
        this.setState({ language });
    }
    // TODO: using setState to change language - determine of ok if there's a lang string on url before we evoke this or if they conflict
    changeLanguage = (language) => {
        this.setState({ language });
    };
    toggleLanguage = () => {
        this.state.language === Language.ENGLISH
            ? this.changeLanguage(Language.SPANISH)
            : this.changeLanguage(Language.ENGLISH);
    };
    determineExperienceCode(experience) {
        if (experience === 'Employer')
            return ExperienceCode.EMPLOYER;
        if (experience === 'Employee')
            return ExperienceCode.EMPLOYEE;
        return ExperienceCode.PUBLIC;
    }
    setExperience = (experience, callback) => {
        // Some things cant wait for state to get set so we set current exp here
        this.currentExperience = experience;
        let experienceCode = this.determineExperienceCode(experience);
        // @ts-ignore
        this.currentExperienceCode = this.determineExperienceCode(experience);
        // Set the experience in the state
        this.setState({ experience, experienceCode }, callback);
    };
    setExperienceCode = (experienceCode, callback) => {
        // Some things cant wait for state to get set so we set current exp here
        this.currentExperienceCode = experienceCode;
        // Set the experience in the state
        this.setState({ experienceCode }, callback);
    };
    getExperience = () => {
        return this.currentExperience;
    };
    getExperienceCode = () => {
        return this.currentExperienceCode;
    };
    render() {
        const context = {
            ...this.state,
            changeLanguage: this.changeLanguage,
            toggleLanguage: this.toggleLanguage,
            setExperience: this.setExperience,
            getExperience: this.getExperience,
            getExperienceCode: this.getExperienceCode,
            setExperienceCode: this.setExperienceCode,
        };
        //Qualtrics VOC needs the role of the user and we are setting it here
        window.sessionStorage.setItem('userRole', this.state.experience);
        return <ExperienceContext.Provider value={context}>{this.props.children}</ExperienceContext.Provider>;
    }
}
export default withCookies(ExperienceProvider);
