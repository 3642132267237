import React from 'react';
import { Icon } from '@digitools/honest-react';
import useReportDownload from './useReportDownload';

const DownloadNoteReportCell =
  noteBasicDetails =>
  ({ data }) => {
    const { sendRequest } = useReportDownload();
    const onDownloadReport = () => {
      const noteDetails = {
        noteType: data._original.noteType,
        topic: data._original.topic,
        subTopic: data._original.subTopic,
        noteText: data._original.noteText,
        createDate: data._original.createDate,
        lastModified: data._original.lastModified,
      };
      const payload = { ...noteBasicDetails, notesResourceList: [noteDetails] };
      const url = `/status-service/report/notes/download`;
      sendRequest(url, payload);
    };
    return (
      <a onClick={onDownloadReport}>
        <Icon name='download' alt={'Download Note'} color={'secondary'} />
      </a>
    );
  };
export default DownloadNoteReportCell;
