import { LoginAlertType } from './reducer';
export const accessDenied = () => ({ type: LoginAlertType.ACCESS_DENIED });
export const tooManyAttempts = () => ({ type: LoginAlertType.TOO_MANY_ATTEMPTS });
export const emailVerified = () => ({ type: LoginAlertType.EMAIL_VERIFIED });
export const userProfileNotFound = () => ({ type: LoginAlertType.USER_PROFILE_NOT_FOUND });
export const emailVerificationExpired = () => ({ type: LoginAlertType.EMAIL_VERIFICATION_EXPIRED });
export const accountUnblocked = () => ({ type: LoginAlertType.ACCOUNT_UNBLOCKED });
export const unexpected = () => ({ type: LoginAlertType.UNEXPECTED });
export const clear = () => ({ type: LoginAlertType.CLEAR });
export const passwordEmailExpired = () => ({ type: LoginAlertType.PASSWORD_VERIFICATION_EXPIRED });
