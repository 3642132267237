import React from "react";
import useExperience from "../../hooks/useExperience";
import useTranslate from "../../hooks/useTranslate";
import { Experience } from "../../types/Experience";
import { Language } from "../../types/Language";
import { isProd } from "../../utils/envUtils";
import { CustomEmployeeText } from "../../utils/CustomExperienceText";
import { HeaderText } from "./HeaderText";
import { resize } from "./HeaderUtils";
import { MenuLink } from "./MobileHeader";
import SlideoutMenu from "./SlideoutMenu";
import useCustomerConfig from '../../hooks/useCustomerConfig';
const MobileExperienceMenu = ({ user, isMenuOpen, openCloseMenu, trackPageEvent, }) => {
    const { changeLanguage, setExperience } = useExperience();
    const { setIsNewNav } = useCustomerConfig();
    const { t } = useTranslate();
    let roles = 0, isEmployee, isEmployer, isMultiCustomer, isInternal, showNewNavMenu;
    if (user.roles.indexOf('employee') > -1) {
        isEmployee = true;
        roles = 1;
    }
    if (user.roles.indexOf('employer') > -1) {
        isEmployer = true;
        roles++;
    }
    if (user.roles.indexOf('internal') > -1) {
        isInternal = true;
        showNewNavMenu = true;
        roles++;
    }
    if (user.accessAllCustomers || (user.customers && user.customers.length > 1)) {
        isMultiCustomer = true;
        roles++;
    }
    const redirectToAdminHome = () => {
        let adminUrl = isProd() ? 'https://admin.mylincolnportal.com' : 'https://admin.np-mylincolnportal.com';
        window.location.assign(adminUrl);
    };
    const getEEDashboardText = showNewNavMenu ? `${CustomEmployeeText()} Dashboard - Legacy` : `${CustomEmployeeText()} Dashboard`;
    return (<>
            {roles > 1
            && <SlideoutMenu isMobileMenuOpen={isMenuOpen} data-public={true} id={'experience-menu'} data-testid={'experience-menu'} iconName='exchange-alt-solid' parentResize={resize} label={<>
                        <strong>{t(HeaderText.viewing)}</strong><span style={{ fontWeight: 'normal', textTransform: 'capitalize' }}>{getEEDashboardText}</span>
                    </>}>
                    {(isEmployee || isInternal) &&
                    <MenuLink aria-label={'Switch to employee experience'} id='experienceEE' to='/employee/home' data-testid={'experienceEE'} onClick={() => { openCloseMenu(); setExperience(Experience.EMPLOYEE); trackPageEvent('Experience', Experience.EMPLOYEE); setIsNewNav(false); }}>
                            {getEEDashboardText}
                            </MenuLink>}
                    {showNewNavMenu &&
                    <MenuLink aria-label={'Switch to employee experience'} id='newNavEE' to='/employee/home' data-testid={'newNavEE'} onClick={() => { openCloseMenu(); setExperience(Experience.EMPLOYEE); trackPageEvent('Experience', Experience.EMPLOYEE); setIsNewNav(true); }}> 
                            {`${CustomEmployeeText()} Dashboard - New`}                               
                        </MenuLink>}
                    {(isEmployer || isInternal) &&
                    <MenuLink aria-label={'Switch to employer experience'} id='experienceER' to='/employer/home' data-testid={'experienceER'} onClick={() => { openCloseMenu(); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); trackPageEvent('Experience', Experience.EMPLOYER); setIsNewNav(false); }}>
                            Employer Dashboard
                            </MenuLink>}
                    {isInternal &&
                    <MenuLink aria-label={'Switch to admin experience'} id='experienceAdmin' to='#' data-testid={'adminHomeLink'} onClick={() => { redirectToAdminHome(); setIsNewNav(false); }}>
                            Admin Home
                            </MenuLink>}
                    {isMultiCustomer &&
                    <MenuLink id='customerSearch' aria-label={'Change Customer'} to='/customer-search' data-testid={'changeCustomer'} onClick={() => { setIsNewNav(false); }}>
                            Change Customer
                            </MenuLink>}
                </SlideoutMenu>}
            {roles === 1 && (isEmployee || isInternal)
            && <MenuLink id='experienceEE' to='/employee/home' data-testid={'experienceEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(false); }}>
                    {getEEDashboardText}
                </MenuLink>}
            {roles === 1 && showNewNavMenu
            && <MenuLink id='experienceEENew' to='/employee/home' data-testid={'newNavEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(true); }}>
                    {`${CustomEmployeeText()} Dashboard - New`}
                </MenuLink>}
            {roles === 1 && (isEmployer || isInternal)
            && <MenuLink to='/employer/home' data-testid={'experienceER'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                    Employer Dashboard
                </MenuLink>}
            {roles === 1 && isMultiCustomer
            && <MenuLink id='changeCustomer' to={'/customer-search'} data-testid={'changeCustomer'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                    Change Customer
                </MenuLink>}
        </>);
};
export default MobileExperienceMenu;
