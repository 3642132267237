import React from 'react';
import App from 'status/components/App';
import { Auth0Provider, ExperienceProvider, LanguageProvider, ReturnToWorkProvider } from 'status/providers';

const StatusApp = () => {
  return (
    <LanguageProvider>
      <ExperienceProvider>
        <Auth0Provider>
          <ReturnToWorkProvider>
            {/* <ClaimLeaveProvider> */}
            <App />
            {/* </ClaimLeaveProvider> */}
          </ReturnToWorkProvider>
        </Auth0Provider>
      </ExperienceProvider>
    </LanguageProvider>
  );
};

export default StatusApp;
