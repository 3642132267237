import React from 'react';
import { QuestionsText } from '../../../../i18n/QuestionsText';
import useEoiContext from '../../../../hooks/useEoiContext';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import MedicalQuestionAnswerSection from './MedicalQuestionAnswerSection';
import { Label, themes } from '@digitools/honest-react';
import ReviewAndSignHeader from './ReviewAndSignHeader';
import useTranslate from '../../../../../../hooks/useTranslate';
import { hasCIEmployeeCoverageSelected, hasCISpouseCoverageSelected, isApplicantCICoverageOnly, isSitusStateNY } from '../../../../utils/HelperFunctions';
const getMedicalQuestionAndAnswer = (questionCode, applicantType, interviewAnswers, medicalQuestion, dependentId) => {
    const questionAnswer = interviewAnswers.medicalInformationAnswers.filter((question) => {
        if (applicantType === 'dependent' && dependentId) {
            // don't check dependent Id's for DISCL since it applies to all dependents.
            if (questionCode.trim() === 'DISCL') {
                return question.role === applicantType && questionCode.trim() === question.questionCode.trim();
            }
            return (question.dependentIds &&
                question.dependentIds.indexOf(dependentId) > -1 &&
                question.role === applicantType &&
                question.questionCode.trim() === questionCode.trim());
        }
        else {
            return question.role === applicantType && question.questionCode.trim() === questionCode.trim();
        }
    }).shift();
    return {
        text: medicalQuestion.text,
        answer: questionAnswer && questionAnswer.value ? EoiGeneralText.yes : EoiGeneralText.no,
    };
};
const MedicalQuestions = (props) => {
    const { initialInformation, interviewAnswers } = useEoiContext();
    const { t } = useTranslate();
    const onlyCICoverageSelected = props.applicantType === 'employee'
        ? isApplicantCICoverageOnly('employee', interviewAnswers)
        : props.applicantType === 'spouse'
            ? isApplicantCICoverageOnly('spouse', interviewAnswers)
            : false;
    const displayMedicalQuestions = interviewAnswers && interviewAnswers.medicalInformationAnswers && interviewAnswers.medicalInformationAnswers.length > 0;
    let disclosureQuestion;
    let tobaccoOrNicotineQuestion;
    let medicalInformationSubHeader;
    const medicalQuestions = [];
    const criticalIllnessQuestions = [];
    let displayCriticalIllnessQuestions;
    if (props.applicantType === 'employee') {
        displayCriticalIllnessQuestions = interviewAnswers && hasCIEmployeeCoverageSelected(interviewAnswers) && interviewAnswers.criticalIllnessAnswers && interviewAnswers.criticalIllnessAnswers.length > 0;
    }
    else if (props.applicantType === 'spouse') {
        displayCriticalIllnessQuestions = interviewAnswers && hasCISpouseCoverageSelected(interviewAnswers) && interviewAnswers.criticalIllnessAnswers && interviewAnswers.criticalIllnessAnswers.length > 0;
    }
    if (initialInformation && displayMedicalQuestions) {
        initialInformation.medicalQuestions.forEach((question) => {
            if (question.questionCode.trim() === 'DISCL') { // Get disclosure question text and answer.
                disclosureQuestion = getMedicalQuestionAndAnswer(question.questionCode.trim(), props.applicantType, interviewAnswers, question, props.dependentId);
            }
            else if (question.questionCode.indexOf('NWSMOK') > -1) { // Get smoking question text and answer.
                tobaccoOrNicotineQuestion = getMedicalQuestionAndAnswer(question.questionCode.trim(), props.applicantType, interviewAnswers, question, props.dependentId);
            }
            else {
                if (question.questionCode.trim() !== 'HELTH') { // Get medical questions texts and answers. Filtering out HELTH since it is a disclaimer.
                    medicalQuestions.push(getMedicalQuestionAndAnswer(question.questionCode.trim(), props.applicantType, interviewAnswers, question, props.dependentId));
                }
                else {
                    medicalInformationSubHeader = question.text; // HELTH
                }
            }
        });
    }
    if (initialInformation && displayCriticalIllnessQuestions) {
        initialInformation.criticalIllnessQuestions.forEach((question) => {
            const text = question.text;
            const interviewAnswer = interviewAnswers.criticalIllnessAnswers.filter((criticalIllnessAnswer) => {
                return question.questionCode === criticalIllnessAnswer.questionCode && props.applicantType === criticalIllnessAnswer.role;
            }).shift();
            const answer = interviewAnswer && interviewAnswer.value ? EoiGeneralText.yes : EoiGeneralText.no;
            criticalIllnessQuestions.push({ text, answer });
        });
    }
    // agreement of terms
    // tobacco or nicotine products
    // medical information
    return (<>
    {displayMedicalQuestions &&
            <>
        <ReviewAndSignHeader title={isSitusStateNY(initialInformation) ? t(EoiGeneralText.statementOfHealthStep) : t(EoiGeneralText.qualifyingQuestionsStep)} step={2}/>
        {disclosureQuestion &&
                    <MedicalQuestionAnswerSection dataTestid={`${props.applicantType}-DISCL`} label={QuestionsText.agreementOfTerms} questionText={disclosureQuestion.text} questionAnswer={disclosureQuestion.answer}/>}
        {tobaccoOrNicotineQuestion &&
                    <MedicalQuestionAnswerSection dataTestid={`${props.applicantType}-NWSMOK`} label={QuestionsText.tobaccoOrNicotine} questionText={tobaccoOrNicotineQuestion.text} questionAnswer={tobaccoOrNicotineQuestion.answer}/>}
        {!onlyCICoverageSelected && medicalQuestions.length > 0 &&
                    <>
            <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem', marginLeft: '1rem' }}>{t(QuestionsText.medicalInformation)}</Label>
            <MedicalQuestionAnswerSection italicize={true} hideBorder={true} questionText={medicalInformationSubHeader}/>
            {medicalQuestions.map((medicalQuestion, i) => {
                            return (<MedicalQuestionAnswerSection dataTestid={`${props.applicantType}-medical`} key={i} questionText={medicalQuestion.text} questionAnswer={medicalQuestion.answer}/>);
                        })}
          </>}
      </>}
      {displayCriticalIllnessQuestions && criticalIllnessQuestions.length > 0 &&
            <>
        <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem', marginLeft: '1rem' }}>{t(QuestionsText.criticalIllness)}</Label>
        <MedicalQuestionAnswerSection hideBorder={true} italicize={true} questionText={!isSitusStateNY(initialInformation) ? QuestionsText.criticalIllnessDescription : QuestionsText.criticalIllnessDescriptionNY} dataTestid={!isSitusStateNY(initialInformation) ? 'criticalIllnessDescription' : 'criticalIllnessDescriptionNY'}/>
        {initialInformation && initialInformation.criticalIllnessHeaderNote && <MedicalQuestionAnswerSection hideBorder={true} italicize={true} questionText={initialInformation.criticalIllnessHeaderNote}/>}
        <MedicalQuestionAnswerSection hideBorder={true} italicize={true} questionText={QuestionsText.criticalIllnessHeading}/>
        {criticalIllnessQuestions.map((question, i) => {
                    return (<MedicalQuestionAnswerSection dataTestid={`${props.applicantType}-critical`} key={i} questionText={question.text} questionAnswer={question.answer}/>);
                })}
        {initialInformation && initialInformation.criticalIllnessFooterNote && <MedicalQuestionAnswerSection hideBorder={true} questionText={initialInformation.criticalIllnessFooterNote}/>}
      </>}
    </>);
};
export default MedicalQuestions;
