export const PROVE_PROMPT_TEXT = {
    HEADING: {
        english: 'Let\'s verify your identity',
        spanish: 'Verifiquemos su identidad'
    },
    P1: {
        english: 'It looks like you successfully created an account but did not finish verifying who you are.',
        spanish: '¡Bienvenido! Ha creado con éxito una cuenta, pero no ha terminado de verificar su identidad.',
    },
    P2: {
        english: 'For your security, to access secure account information we need additional information from you to validate your identity.',
        spanish: 'Por su seguridad y para acceder a datos de su cuenta segura, se necesita más información.',
    },
    COMPLETE_BUTTON: {
        english: 'Complete identity verification',
        spanish: 'Complete la verificación de identidad'
    },
    SKIP_BUTTON: {
        english: 'Skip for now',
        spanish: 'Omitir por ahora'
    }
};
const ProveText = {
    verifyHeading: {
        english: 'Let’s verify your identity',
        spanish: 'Verifiquemos tu identidad',
    },
    verifyHeadingAlt: {
        english: 'Account setup & access',
        spanish: 'Configuración y acceso a la cuenta',
    },
    verifySub1: {
        english: 'It looks like you successfully created an account, but did not finish verifying who you are.',
        spanish: 'Parece que creaste una cuenta con éxito, pero no terminaste de verificar quién eres.',
    },
    verifySub2: {
        english: 'For your security, to access secure account information we need additional information from you to validate your identity.',
        spanish: 'Por su seguridad, para acceder a la información segura de la cuenta, necesitamos información adicional de usted para validar su identidad.',
    },
    last4SsnLabel: {
        english: 'Last 4-digit SSN',
        spanish: 'Último SSN de 4 dígitos',
    },
    zipCodeLabel: {
        english: 'Zip or postal code',
        spanish: 'Código postal',
    },
    phoneLabel: {
        english: 'Personal phone',
        spanish: 'Telefono personal',
    },
    attestationLabel: {
        english: 'I have verified the information provided is correct.',
        spanish: 'He verificado que la información proporcionada es correcta.',
    },
    submitLabel: {
        english: 'CONTINUE',
        spanish: 'CONTINUAR',
    },
    continueLabel: {
        english: 'CONTINUE',
        spanish: 'CONTINUAR',
    },
    codeLabel: {
        english: 'Authentication code',
        spanish: 'Código de autenticación',
    },
    verifyError1: {
        english: 'Unable to verify your identity. Please re-enter the requested fields and confirm your information is correct.',
        spanish: 'No se pudo verificar su identidad. Vuelva a ingresar los campos solicitados y confirme que su información es correcta.',
    },
    verifyError2: {
        english: 'Unable to verify identity, please contact customer support.',
        spanish: 'No se puede verificar la identidad, comuníquese con atención al cliente.',
    },
    authVerifyError: {
        english: 'Unable to verify identity, please try again in 24 hours or contact customer support.',
        spanish: 'No se puede verificar su identidad. Inténtalo de nuevo en 24 horas o ponte en contacto con el servicio de atención al cliente.'
    },
    proveCodeFormError: {
        english: 'Invalid code. Please enter a valid 6-digit code',
        spanish: 'Codigo invalido. Ingrese un código válido de 6 dígitos',
    },
    proveCodeInvalidError: {
        english: 'Incorrect authentication code. Please try again.',
        spanish: 'Código de autenticación incorrecto. Inténtalo de nuevo.',
    },
    proveCodeExpiredError: {
        english: "Code expired. click 'Resend code' below to recive a new code.",
        spanish: "Código caducado. haga clic en 'Reenviar código' a continuación para recibir un nuevo código.",
    },
    proveCodeUnexpectedError: {
        english: 'An unexpected error occurred, please try again. If the problem persists, please contact customer support.',
        spanish: 'Ocurrió un error inesperado, inténtalo de nuevo. Si el problema persiste, póngase en contacto con el servicio de atención al cliente.',
    },
    proveCodeResendMaxError: {
        english: 'You have reached the maximum resend attempts allowed.',
        spanish: 'Has alcanzado el máximo de intentos de reenvío permitidos.',
    },
    proveCodeResentAlert: {
        english: 'Authentication code has been resent',
        spanish: 'El código de autenticación ha sido reenviado',
    },
    proveUserLockout: {
        english: 'Unable to verify your identity. Please try again in 24-hours or contact customer support.',
        spanish: 'No se puede verificar su identidad. Inténtalo de nuevo en 24 horas o ponte en contacto con el servicio de atención al cliente.',
    },
    viaText: {
        english: ' via text',
        spanish: ' por mensaje de texto',
    },
    viaPhone: {
        english: ' via phone',
        spanish: ' por teléfono',
    },
    proveCodeSuccessAlert: {
        english: "Your account has been successfully updated. Access to all your Lincoln portals should display within the next 48 hours, after successful login.",
        spanish: 'Tu cuenta ha sido actualizada exitosamente. El acceso a todos sus portales de Lincoln debería mostrarse dentro de las próximas 48 horas, después de iniciar sesión correctamente.',
    },
    proveCodePreviousSuccessAlert: {
        english: 'Your account has already been updated. Access to all your Lincoln portals should display within the next 48 hours, after successful login.',
        spanish: 'Su cuenta ya ha sido actualizada. El acceso a todos sus portales de Lincoln debería mostrarse dentro de las próximas 48 horas, después de iniciar sesión correctamente.'
    },
    resendCodeLabel: {
        english: "Didn't receive a code?",
        spanish: '¿No recibiste un código?',
    },
    resendCodeButton: {
        english: 'Resend code',
        spanish: 'Reenviar codigo',
    },
    getACallButton: {
        english: 'Get a call',
        spanish: 'TODO',
    },
    getATextButton: {
        english: 'Get a text',
        spanish: 'TODO',
    },
    resending: {
        english: 'Resending...',
        spanish: 'Reenviar...',
    },
    phoneHomeText: (phoneNumber) => {
        return {
            english: `We've called you at ${phoneNumber}. Please answer to recieve your code.`,
            spanish: `Te hemos llamado al ${phoneNumber}. Por favor responda para recibir su código.`,
        };
    },
    phoneMobileText: (phoneNumber) => {
        return {
            english: `We've texted you at ${phoneNumber}.`,
            spanish: `Te hemos enviado un mensaje de texto al ${phoneNumber}`,
        };
    },
};
export default ProveText;
