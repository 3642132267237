import { Container, Heading, themes } from '@digitools/honest-react';
import axios from 'axios';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'src/utils/withRouter';
import styled from 'styled-components';
import ServiceUnavailable from '../../../../components/ServiceUnavailable/ServiceUnavailable';
import { TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import useAuthentication from '../../../../hooks/useAuthentication';
import useTealium from '../../../../hooks/useTealium';
import { ExperienceContext } from '../../../../providers/ExperienceProvider';
import { Language } from '../../../../types/Language';
import EmployeeContactsText from './EmployeeContactsText';
const EmployeeContacts = ({ navigate }) => {
    const { authHeader, user } = useAuthentication();
    const [contactsData, setContactsData] = useState(null);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [hasError, setHasError] = useState(false);
    const Experience = useContext(ExperienceContext);
    const { trackEvent, trackView } = useTealium();
    const language = Experience.language;
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EMPLOYEE_CONTACTS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EMPLOYEE_CONTACTS.VIEW,
        });
    }, []);
    useEffect(() => {
        getContactsData();
    }, []);
    const modifyContactData = (response) => {
        const docsNetContact = '800-423-2765';
        const replaceClaimConstant = '{ClaimPhoneNumber}';
        const replaceLeaveConstant = '{LeavePhoneNumber}';
        const setCustomData = (value) => {
            if (value.match(replaceClaimConstant)) {
                return value.replace(replaceClaimConstant, docsNetContact);
            }
            return value;
        };
        let updatedRes = response.map((res) => {
            if (!res.valueEnglish.match(replaceLeaveConstant)) {
                return {
                    ...res,
                    valueEnglish: setCustomData(res.valueEnglish) || '',
                    valueSpanish: setCustomData(res.valueSpanish) || '',
                };
            }
        });
        let finalResponse = updatedRes.filter(e => e);
        return finalResponse;
    };
    const getContactsData = async () => {
        const response = await callApi();
        if (response) {
            if (user?.customerInContext.smallMid) {
                const docsnetSupportConstant = {
                    titleEnglish: EmployeeContactsText.docsnetTitleEnglishConstant,
                    titleSpanish: EmployeeContactsText.docsnetTitleSpanishConstant,
                    valueEnglish: EmployeeContactsText.docsnetValueEnglishConstant,
                    valueSpanish: EmployeeContactsText.docsnetValueSpanishConstant,
                };
                response.unshift(docsnetSupportConstant);
            }
            let updatedResponse = modifyContactData(response);
            user?.customerInContext.smallMid ? setContactsData(updatedResponse) : setContactsData(response);
        }
        setIsFetchingData(false);
    };
    const callApi = async () => {
        try {
            const api = `/mlp-ui-service/services/v1/learn-more/contact-info`;
            const response = await axios.get(api, {
                headers: {
                    Authorization: authHeader(),
                },
            });
            return response.data.contactList;
        }
        catch (error) {
            return setHasError(true);
        }
    };
    //not used, not sure if i should remove   
    // const handleBackClick = () => {
    //   trackEvent({
    //     event_type: TEALIUM_EVENT.EVENT_TYPE.HOME,
    //     event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
    //     event_name: 'Back',
    //   });
    //   navigate('/employee/home');
    // };
    const StyledContactContainer = styled.div `
    border-left: 3px solid rgb(173, 17, 43);
    padding-left: 1rem;
    margin-bottom: 1rem;
    position: relative;
    a[href^='tel'] {
      color: #244f8b;
    }
  `;
    return (<>
      <Container fluid={true} theme={themes.lincoln} style={{ padding: '2rem' }}>
        <Heading data-public={true} elemType={'h1'} style={{ marginBottom: '2.5rem', fontWeight: 400 }} data-testid='heading-ee-contacts'>
          {language === Language.SPANISH ? 'Contáctenos' : 'Contact us'}
        </Heading>
        <hr />
        {!isFetchingData &&
            !hasError &&
            contactsData &&
            contactsData.map((item, i) => (<>
              <StyledContactContainer data-public={true} data-testid={`contactContainer-${i}`} key={i}>
                <Heading data-public={true} data-testid={`heading-${i}`} elemType={'h2'} theme={themes.lincoln} color={'primary'}>
                  {language === Language.ENGLISH ? parse(item.titleEnglish) : parse(item.titleSpanish)}
                </Heading>
                {language === Language.ENGLISH ? parse(item.valueEnglish) : parse(item.valueSpanish)}
              </StyledContactContainer>
              <hr />
            </>))}
      </Container>
      {hasError && <ServiceUnavailable />}
    </>);
};
export default withRouter(EmployeeContacts);
