import React, { useCallback, useContext } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import moment from 'moment';
import { AuthenticationContext } from '../../../../../providers/AuthenticationProvider';
const useReportDownload = () => {
  const { authHeader } = useContext(AuthenticationContext);
  const currentDate = moment().format('mm/dd/yyyy hh:mm:ss A z');
  const sendRequest = async (url, request) => {
    const printedDate = { printedDate: currentDate };
    const payload = { ...request, ...printedDate };
    const { clmLveNumber, employeeName } = payload;
    const reportName = `${clmLveNumber}-${employeeName}-Notes.pdf`;
    try {
      const response = await axios
        .post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader(),
          },
          responseType: 'blob',
        })
        .then(res => res);
      const blob = new Blob([response.data]);
      FileSaver.saveAs(blob, reportName);
    } catch (error) {
      console.log(`Download report error ${error}`);
    }
  };
  return {
    sendRequest,
  };
};
export default useReportDownload;
