import { Col, Icon, Label, Link } from '@digitools/honest-react';
import React from 'react';
import useExperience from 'src/hooks/useExperience';
import { Language } from 'src/types/Language';
import styled from 'styled-components';
import { DocumentUploadDisplayTextEn } from './language/DocumentUploadDisplayTextEn';
import { DocumentUploadDisplayTextEs } from './language/DocumentUploadDisplayTextEs';
const StyledFileInput = styled('input') `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const UploadButton = ({ onUpload, eventNum }) => {
    const { language } = useExperience();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    return (<Col md={4}>
      <Label htmlFor={'file-upload' + eventNum}>
        <Icon color={'secondary'} name={'upload'} size={'medium'}/>
        <Link style={{ marginLeft: '.5rem', fontSize: '16px', fontWeight: 'bold' }} data-testid={'uploadButton'}>
          {languageFile.uploadButtonText}
        </Link>
      </Label>
      <StyledFileInput data-testid={'fileUpload' + eventNum} type={'file'} id={'file-upload' + eventNum} onChange={onUpload} accept={'.gif, .jpg, .pdf, .pptx, .tif, .tiff, .xls, .xlsx, .doc, .docx'}/>
    </Col>);
};
export default UploadButton;
