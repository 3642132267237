// Constants for RIA - for static unchanging values shared amonst components
// name all CONSTANTS_LIKE_THIS or face the wrath of izaak
import React from 'react';
export const RIA_BASE_URL = '/services/v1/ria';
export const RIA_TEXT = {
    editShowMore: {
        english: 'SHOW MORE',
        spanish: 'MOSTRAR MÁS'
    },
    editToolTip: {
        english: 'You cannot edit this absence',
        spanish: 'No puedes editar esta ausencia'
    },
    addNewAdditionalAbsence: {
        english: 'Add additional absence',
        spanish: 'Agregar ausencia adicional',
    },
    dateReported: {
        english: 'Date Reported',
        spanish: 'Fecha de reporte'
    },
    selectLeave: {
        english: 'Select leave',
        spanish: 'Seleccione la baja'
    },
    reportAbsence: {
        english: 'Add or edit existing absences',
        spanish: 'Agregar o editar ausencias existentes',
    },
    selectLeaveSubHeading: {
        english: 'Choose a leave to report or update an absence.',
        spanish: 'Elija una licencia para informar o actualizar una ausencia.'
    },
    selectLeaveError: {
        english: 'Please select a leave to continue.',
        spanish: 'Seleccione una licencia para continuar.'
    },
    approvedFrequency: {
        english: 'Approved Frequency',
        spanish: 'Frecuencia aprobada'
    },
    type: {
        english: 'Type',
        spanish: 'Tipo'
    },
    reason: {
        english: 'Reason',
        spanish: 'Motivo'
    },
    beginDate: {
        english: 'Begin date',
        spanish: 'Fecha de inicio'
    },
    endDate: {
        english: 'End date',
        spanish: 'Fecha de finalización'
    },
    leave: {
        english: 'Leave',
        spanish: 'Baja'
    },
    riaPageTitle: {
        english: 'Report Intermittent Absence',
        spanish: 'Reportar una ausencia intermitente'
    },
    correspondencePrompt: {
        english: 'Save a tree. Go paperless and receive email for non-confidential correspondence.',
        spanish: 'Salva un árbol. Deje de usar papel y reciba correos electrónicos para correspondencia no confidencial.'
    },
    correspondenceToolTip: {
        english: "You are currently receiving your document correspondence through fax or U.S. Mail. You now have the option to receive all non-confidential correspondence by email. If you decide to opt-in for email today, you may manage your document preferences in the future by calling your leave specialist.",
        spanish: "Actualmente está recibiendo la correspondencia de su documento por fax o correo postal de EE. UU. Ahora tiene la opción de recibir toda la correspondencia no confidencial por correo electrónico. Si decide optar por el correo electrónico hoy, puede administrar sus preferencias de documentos en el futuro llamando a su especialista en abandonar. "
    },
    correspondenceTitle: {
        english: "Paperless Communications",
        spanish: "Comunicaciones sin usar papel"
    },
    correspondenceThankYou: {
        english: "Thanks! You're all set. To update your correspondence preference settings, please contact your leave specialist.",
        spanish: "¡Gracias! Estás listo. Para actualizar la configuración de sus preferencias de correspondencia, comuníquese con su especialista en abandonar."
    },
    cancelButton: {
        english: 'Cancel',
        spanish: 'Cancelar'
    },
    backButton: {
        english: 'Back',
        spanish: 'atrás'
    },
    continueButton: {
        english: 'Continue',
        spanish: 'Continuar'
    },
    submitButton: {
        english: 'Submit',
        spanish: 'Enviar'
    },
    editButton: {
        english: 'Edit',
        spanish: 'Editar',
    },
    confirmationHeading: {
        english: 'Absence confirmation',
        spanish: 'Confirmación de ausencia'
    },
    reportAbsencesHeading: {
        english: 'Add or edit existing absences',
        spanish: 'Agregar o editar ausencias existentes',
    },
    leaveDetailsConfirmation: {
        english: 'Please confirm your absence information is correct. Date cannot be changed after selecting \"Submit\".',
        spanish: 'Confirme que la información de su ausencia es correcta. La fecha no se puede cambiar después de seleccionar \"Enviar\".'
    },
    absenceDate: {
        english: 'Absence date:',
        spanish: 'Fecha de la ausencia:',
    },
    absenceDuration: {
        english: 'Absence duration:',
        spanish: 'Duración de la ausencia:',
    },
    absenceStatus: {
        english: 'Status:',
        spanish: 'Estado:',
    },
    reportMoreTimeButton: {
        english: 'Report more time off',
        spanish: 'Reportar más tiempo libre',
    },
    returnToSelectLeave: {
        english: 'Return to select leave',
        spanish: 'Volver a seleccionar abandonar'
    },
    submitSuccess: {
        english: 'Successfully submitted',
        spanish: 'Enviado con éxito'
    },
    submitFailure: {
        english: 'Unsuccessfully submitted',
        spanish: 'Envío fallido'
    },
    updateSuccess: {
        english: 'Successfully updated',
        spanish: 'Actualizado exitosamente'
    },
    updateFailure: {
        english: 'Unsuccessfully updated',
        spanish: 'Actualizado sin éxito'
    },
    resubmit: {
        english: 'Resubmit',
        spanish: 'Reenviar'
    },
    submitConfirmationHeader: {
        english: 'Submit Confirmation',
        spanish: 'Enviar confirmación'
    },
    confirmationThankyou: {
        english: <><strong>Thank you!</strong> Your absence was successfully submitted. You can view all previous absences in the table below.</>,
        spanish: <><strong>¡Gracias!</strong> Su ausencia se envió con éxito. Puede ver todas las ausencias anteriores en la siguiente tabla.</>,
    },
    confirmationErrorPartial: {
        english: 'We\'re sorry. An error has occurred and some of your absences were unable to be submitted. Please review your absences below and resubmit any absences if needed.',
        spanish: 'Lo sentimos. Hubo un error y algunas de sus ausencias no se pudieron enviar. Revise sus ausencias abajo y vuelva a enviar cualquier ausencia si es necesario.'
    },
    confirmationErrorFull: {
        english: <><strong>Attention required:</strong> Your absences were not submitted. Please review your absences below and resubmit. If resubmission is still unsuccessful please <a href='/customer/home/contacts'>contact us</a>.</>,
        spanish: <><strong>Se necesita su atención:</strong> Tus ausencias no fueron enviadas. Por favor revise sus ausencias a continuación y vuelva a enviar. Si el reenvío sigue sin tener éxito, <a href='/customer/home/contacts'>contáctenos</a>.</>,
    },
    confirmationCheck: {
        english: 'Your absence was successfully submitted. You can view all previous absences in the table below.',
        spanish: 'Tu ausencia fue enviada con éxito. Puede ver todas las ausencias anteriores en la siguiente tabla.'
    },
    confirmationReview: {
        english: 'Review your absence details.',
        spanish: 'Revise la información de su ausencia.'
    },
    pending: {
        english: 'Pending...',
        spanish: 'En trámite...',
    },
    submitSingleSuccess: {
        english: 'Your absence was successfully submitted.',
        spanish: 'Su ausencia se envió con éxito.',
    },
    submitMultiSuccess: {
        english: 'All absences were successfully submitted.',
        spanish: 'Todas las ausencias se enviaron correctamente.',
    },
    submitUnsuccess: {
        english: <> We’re sorry. An error has occurred and some of your absences were unable to be submitted. Please review your absences below and <a href='#resubmit'> resubmit any absences if needed </a>.</>,
        spanish: <> Lo lamentamos. Se ha producido un error y no se han podido enviar algunas de sus ausencias. Por favor revise sus ausencias a continuación y <a href='#resubmit'> volver a enviar cualquier ausencia si es necesario </a>.</>,
    },
    submitError: {
        english: <>We're sorry, we were unable to submit your request at this time. Please wait a moment and try again try. If issues persist please <a href='/customer/home/contacts'>contact us</a>.</>,
        spanish: <>Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si los problemas persisten, <a href='/customer/home/contacts'>contáctenos</a>.</>
    },
    dateLabel: {
        english: 'Date',
        spanish: 'Fetcha',
    },
    durationLabel: {
        english: 'Duration (HH:MM)',
        spanish: 'Duración (HH:MM)',
    },
    dateValidationHelpText: {
        english: 'Absences must also be within the leave begin and end date. ',
        spanish: 'Las ausencias también deben estar dentro de la fecha de inicio y finalización de la licencia.',
    },
    dateEditValidationHelpText: {
        english: '',
        spanish: '',
    },
    durationValidationHelpText: {
        english: (props) => `Duration must be entered in increments of ${props} minutes.`,
        spanish: (props) => `La duración de la ausencia debe estar en incrementos de ${props} minutos.`,
    },
    durationHourHelpText: {
        english: 'Duration must be entered in increments of 1 hour.',
        spanish: 'La duración debe ingresarse en incrementos de 1 hora.'
    },
    editAbsences: {
        english: 'Manage previous absences',
        spanish: 'Gestionar ausencias anteriores'
    },
    calendar: {
        english: 'calendar',
        spanish: 'calendario'
    },
    business: {
        english: 'business',
        spanish: 'hábiles'
    },
    addAbsencesHeader: {
        english: 'Add new absences',
        spanish: 'Añadir nuevas ausencias'
    },
    noLeavesError: {
        english: 'There are no leaves yet available to report absences on. If you recently submitted a new leave, there may be a processing delay before it becomes available.',
        spanish: 'Todavía no hay hojas disponibles para informar ausencias. Si recientemente envió una nueva licencia, puede haber un retraso en el procesamiento antes de que esté disponible.'
    },
    riaErrorMessage: {
        english: 'You are not authorized to view this page.',
        spanish: 'No tiene autorización para ver esta página.',
    },
    riaEmptyAlert: {
        english: 'Please finish entering your absence details. Once completed, you may submit your information.',
        spanish: 'Por favor, termine de ingresar los detalles de su ausencia. Una vez completado, puede enviar su información.'
    },
    removeAbsence: {
        english: 'Remove absence',
        spanish: 'Exención del informe'
    }
};
