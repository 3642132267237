import { Col, Heading, Row, TextInput } from '@digitools/honest-react';
import React from 'react';
import { formatCurrency, validateCurrencyOptional, decimalDollar } from '../../../../../utils/validationUtils';
import { useFormApi } from 'informed';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
const DependentCoverageAmount = ({ claimant, event }) => {
    const formApi = useFormApi();
    const handleBlur = (field) => (e) => {
        const val = formApi.getValue(field);
        formApi.setValue(field, decimalDollar(val));
    };
    return (<>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>Dependent Coverage Amounts</Heading>
      </BorderLessGrayBox>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Dependent Basic'} field={'policyInfo_dependentBasicAmount'} icon={'usd'} label={'Dependent Basic (optional)'} mask={formatCurrency} onBlur={handleBlur('policyInfo_dependentBasicAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Dependent Basic AD&D'} field={'policyInfo_dependentBasicADandDAmount'} icon={'usd'} label={'Dependent Basic AD&D (optional)'} mask={formatCurrency} onBlur={handleBlur('policyInfo_dependentBasicADandDAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Dependent Optional'} field={'policyInfo_dependentOptionalAmount'} icon={'usd'} label={'Dependent Optional (optional)'} mask={formatCurrency} onBlur={handleBlur('policyInfo_dependentOptionalAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
      </Row>
      <Row className='mt-0'>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Dependent Optional AD&D'} field={'policyInfo_dependentOptionalADandDAmount'} icon={'usd'} label={'Dependent Optional AD&D (optional)'} mask={formatCurrency} onBlur={handleBlur('policyInfo_dependentOptionalADandDAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
      </Row>
    </>);
};
export default DependentCoverageAmount;
