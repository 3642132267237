const formatZip = zip => {
  if (zip != null && zip != '') {
    const trimmed = zip.trim();
    if (trimmed.length === 9) {
      return `${trimmed.slice(0, 5)}-${trimmed.slice(5, 9)}`;
    }
    if (trimmed.length === 5) {
      return trimmed;
    }
  }
};

export default formatZip;
