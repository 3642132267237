import { invalidEmailErrorMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidEmail } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidEmail(isRequired = false) {
    const errorMessageMap = [invalidEmailErrorMap];
    const validators = [isValidEmail];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: 'Email address', [Language.SPANISH]: 'Dirección de correo electrónico' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidEmail;
