import React, { useContext, useEffect } from 'react';
import { Button, Heading } from '@digitools/honest-react';
import ResetPasswordApi from '../../../api/ResetPasswordApi';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { getTraceId } from '../../../utils/axiosUtils';
import { SecurityAndSettingsText } from '../i18n/SecurityAndSettingsText';
import useAuthentication from '../../../hooks/useAuthentication';
import usePortalMessenger from '../../../hooks/usePortalMessenger';
import useTranslate from '../../../hooks/useTranslate';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const ResetPassword = (props) => {
    const { user, authHeader, logout } = useAuthentication();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const { addMessage } = usePortalMessenger();
    const email = user ? user.emailAddress : '';
    const { api = ResetPasswordApi } = props;
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    useEffect(() => setIsMessagingDisabled(false), []);
    const handleClick = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RESET_PASSWORD,
            event_name: SecurityAndSettingsText.btnResetPassword.english,
        });
        const loginId = user ? user.userName : '';
        try {
            const response = await api().resetPassword(loginId, authHeader());
            if (response.status === 200) {
                logout();
            }
        }
        catch (error) {
            if (error.response.status >= 400 && error.response.status < 500) {
                let traceId = getTraceId(error);
                const i = traceId.indexOf(',');
                traceId = traceId.substring(0, i);
                addMessage({
                    type: 'error',
                    message: [
                        SecurityAndSettingsText.requestError.english + '(Error: ' + traceId + ').',
                        SecurityAndSettingsText.requestError.english + '(Error: ' + traceId + ').',
                    ],
                    closeable: true,
                });
            }
        }
    };
    return (<>
      <Heading data-public={true} elemType={'h2'} elemStyle={'h3'} color={'black'} id='resetPasswordHeading'>
        {t(SecurityAndSettingsText.resetPWHeading)}
      </Heading>
      <p data-public={true}>
        {t(SecurityAndSettingsText.resetPWText)}
        <span>{email}.</span>
      </p>
      <Button data-public={true} id='reset-password' onClick={handleClick} buttonType={'primary'}>
        {t(SecurityAndSettingsText.btnResetPassword)}
      </Button>
    </>);
};
export default ResetPassword;
