import React, { useMemo } from 'react';
import { Alert, CompositeTable as DataTable } from '@digitools/honest-react';
import checkDate from 'status/utilities/checkDate';
import EOISpecialist from './EOISpecialist';
import { useTranslation } from 'react-i18next';
import styles from './EOI.scss';
import LanguageToggle from '../../../../../utils/LanguageToggle';

const Error = () => (
  <Alert type={'info'}>
    <LanguageToggle 
      en={"No active evidence of insurability applications found.  Please ensure you log in with the same username and password that was used when completing the application."} 
      es={"No se encontraron solicitudes activas de evidencia de asegurabilidad. Asegúrese de iniciar sesión con el mismo nombre de usuario y contraseña que usó al completar la solicitud."} 
    />
  </Alert>
);

const EoiInfo = ({ eoi, error }) => {
  const { t } = useTranslation();

  // EOI endpoint returns 204 when there is none so we cant just rely on error
  // thats why we have !eoi check :)
  if (error || !eoi) {
    return <Error />;
  }

  const formattedData = useMemo(() => {
    return eoi.map(e => {
      return {
        ...e,
        determinationDate: checkDate(e.determinationDate),
        receivedDate: checkDate(e.receivedDate),
      };
    });
  }, [eoi]);

  const columns = useMemo(() => {
    return [
      {
        Header: t('receivedDateText'),
        accessor: 'receivedDate',
        id: 'receivedDate',
      },
      {
        Header: t('coverageText'),
        accessor: 'coverage',
        id: 'coverage',
      },
      {
        Header: t('statusText'),
        accessor: 'status',
        id: 'status',
      },
      {
        Header: t('statusDefinitionText'),
        accessor: 'statusDefinition',
        id: 'statusDefinition',
      },
      {
        Header: 'Determination Date',
        accessor: 'determinationDate',
        id: 'determinationDate',
      },
    ];
  }, [eoi]);

  return (
    <>
      <div className={`mb-3 ${styles.tableContainer}`}>
        <DataTable data={formattedData} columns={columns} paginated={formattedData.length > 10} />
      </div>
      <div className='mb-3'>
        <EOISpecialist />
      </div>
    </>
  );
};

export default EoiInfo;
