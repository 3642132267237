export const ApplicantInformationText = {
    headerText: {
        english: 'Please provide information for all applicants applying for coverage',
        spanish: 'Proporcione información para todos los solicitantes que soliciten cobertura',
    },
    addDependent: {
        english: 'Add new dependent',
        spanish: 'Agregar nuevo dependiente',
    },
    employeeInformation: {
        english: 'My information',
        spanish: 'Mi informacion',
    },
    spouseInformation: {
        english: '<SPOUSE> information',
        spanish: 'Informacion de <SPOUSE>',
    },
    dependentInformation: {
        english: 'Dependent(s) information',
        spanish: 'Información de dependiente(s)',
    },
    employmentInformation: {
        english: 'EMPLOYMENT INFORMATION',
        spanish: 'INFORMACION DE EMPLEO',
    },
    personalInformation: {
        english: 'PERSONAL INFORMATION',
        spanish: 'INFORMACION PERSONAL',
    },
    contactInformation: {
        english: 'CONTACT INFORMATION',
        spanish: 'INFORMACIÓN DEL CONTACTO',
    },
    removeDependent: {
        english: 'Remove dependent',
        spanish: 'Eliminar dependiente',
    }
};
export const EmploymentInformationText = {
    activeEmployee: {
        english: 'As an <EMPLOYEE>, are you actively at work?',
        spanish: 'Como <EMPLOYEE>, ¿estás activamente trabajando?',
    },
    employmentType: {
        english: 'Are you a full-time or part-time <EMPLOYEE>? ',
        spanish: '¿Es usted un <EMPLOYEE> a tiempo completo o parcial?',
    },
    fullTime: {
        english: 'Full-time',
        spanish: 'Tiempo completo'
    },
    partTime: {
        english: 'Part-time',
        spanish: 'Medio tiempo',
    },
    salary: {
        english: 'Annual salary',
        spanish: 'Salario anual',
    },
    enterSalary: {
        english: 'Enter salary',
        spanish: 'Ingresar salario',
    },
    whatIsYourAnnualSalary: {
        english: 'What is your current annual salary?',
        spanish: '¿Cuál es su salario anual actual?',
    },
    pleaseProvideTheFollowing: {
        english: 'Please provide the following:',
        spanish: 'Proporcione lo siguiente:',
    },
    occupation: {
        english: '<EMPLOYEE> occupation',
        spanish: 'Ocupación del <EMPLOYEE>',
    },
    dateOfHire: {
        english: 'Date of hire',
        spanish: 'Fecha de contratación:',
    },
    dateOfRehire: {
        english: 'Date of rehire',
        spanish: 'Fecha de recontratación:',
    },
    officeLocation: {
        english: 'Employment office location',
        spanish: 'Ubicación de la oficina de empleo:',
    },
};
export const PersonalInformationText = {
    firstName: {
        english: 'First name',
        spanish: 'Nombre de pila'
    },
    middleInitial: {
        english: 'Middle initial',
        spanish: 'Inicial del segundo nombre'
    },
    lastName: {
        english: 'Last name',
        spanish: 'Apellido'
    },
    socialSecurityNumber: {
        english: 'Social security number',
        spanish: 'Número de seguridad social'
    },
    dateOfBirth: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento'
    },
    sexAtBirth: {
        english: 'Sex at birth',
        spanish: 'Sexo al nacer'
    },
    gender: {
        english: 'Gender',
        spanish: 'Género'
    },
    male: {
        english: 'Male',
        spanish: 'Masculino'
    },
    female: {
        english: 'Female',
        spanish: 'Hembra'
    },
    birthState: {
        english: 'Birth state',
        spanish: 'Estado de nacimiento'
    },
    maritalStatus: {
        english: 'Marital status:',
        spanish: 'Estado civil:'
    },
    single: {
        english: 'Single',
        spanish: 'Soltero'
    },
    married: {
        english: 'Married',
        spanish: 'Casado'
    },
    domesticPartner: {
        english: 'Domestic partnership',
        spanish: 'Asociación doméstica'
    },
    civilUnion: {
        english: 'Civil Union',
        spanish: 'Unión civil'
    },
    height: {
        english: 'Height:',
        spanish: 'Altura:'
    },
    feet: {
        english: 'Feet',
        spanish: 'Pies'
    },
    inches: {
        english: 'Inches',
        spanish: 'Pulgadas'
    },
    weight: {
        english: 'Weight:',
        spanish: 'Peso:'
    },
    weightPlaceholder: {
        english: 'lbs.',
        spanish: 'libras'
    },
    weightErrorMessage: {
        english: 'Enter a valid weight',
        spanish: 'Ingrese un peso válido'
    }
};
export const ContactInformationText = {
    phoneNumberType: {
        english: 'Phone type',
        spanish: 'Tipo de telefono',
    },
    phoneNumberTypePlaceholder: {
        english: 'Select phone type',
        spanish: 'Seleccione el tipo de teléfono'
    },
    typeHome: {
        english: 'Home',
        spanish: 'Hogar',
    },
    typeWork: {
        english: 'Work',
        spanish: 'Trabajo',
    },
    typeMobile: {
        english: 'Mobile',
        spanish: 'Móvil',
    },
    phoneNumber: {
        english: 'Phone number',
        spanish: 'Número de teléfono',
    },
    communicationPreference: {
        english: 'Preferred method of communication',
        spanish: 'Método preferido de comunicación',
    },
    residentialAddress: {
        english: 'Residential address',
        spanish: 'Dirección residencial',
    },
    residentialAddress1: {
        english: 'Residential address 1',
        spanish: 'Dirección residencial 1',
    },
    residentialAddress2: {
        english: 'Residential address 2',
        spanish: 'Dirección residencial 2',
    },
    residentialCity: {
        english: 'Residential city',
        spanish: 'Ciudad de residencia',
    },
    mailingAddress: {
        english: 'Mailing address',
        spanish: 'Dirección para correspondencia',
    },
    mailingAddress1: {
        english: 'Mailing address 1',
        spanish: 'Dirección para correspondencia 1',
    },
    mailingAddress2: {
        english: 'Mailing address 2',
        spanish: 'Dirección para correspondencia 2',
    },
    mailingCity: {
        english: 'Mailing city',
        spanish: 'Ciudad de envío postal',
    },
    state: {
        english: 'State',
        spanish: 'Estado',
    },
    province: {
        english: 'Province',
        spanish: 'Provincia',
    },
    zipCode: {
        english: 'Zip code',
        spanish: 'Código zip',
    },
    postalCode: {
        english: 'Postal code',
        spanish: 'Código postal',
    },
    useAsMyMailingAddress: {
        english: 'Use my residential address',
        spanish: 'Usar mi dirección residencial',
    },
    country: {
        english: 'Country',
        spanish: 'País',
    },
    selectCountry: {
        english: 'Select country',
        spanish: 'Seleccionar país',
    },
    selectState: {
        english: 'Select State',
        spanish: 'Seleccionar estado'
    },
    selectProvince: {
        english: 'Select province',
        spanish: 'Seleccionar provincia',
    },
};
