import { Col, Row } from '@digitools/honest-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DataRow = ({ label, value }) => {
  return (
    <Row className={'mr-0'} style={{ marginBottom: '.5rem', marginTop: '0rem' }}>
      <Col>{label}</Col>
      <Col style={{ textAlign: 'right' }}>{value}</Col>
    </Row>
  );
};

const WagesDetails = ({ claimPayment }) => {
  const { t } = useTranslation();
  return (
    <>
      <DataRow label={<strong>Wages</strong>} value={<strong>Taxable</strong>} />
      <DataRow label={t('checkFederalTaxWithheldAmountText')} value={claimPayment.federalWage} />
      <DataRow label={t('checkMedicareTaxWithheldAmountText')} value={claimPayment.medicareWage} />
      <DataRow label={t('checkSsoaiTaxWithheldAmountText')} value={claimPayment.ssoiWage} />
      <DataRow label={t('checkStateTaxWithheldAmountText')} value={claimPayment.stateWage} />
      <DataRow label={''} value={<strong>Non-Taxable</strong>} />
      <DataRow label={t('checkFederalTaxWithheldAmountText')} value={claimPayment.nonTaxableFederal} />
    </>
  );
};

export default WagesDetails;
