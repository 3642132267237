import React from 'react';
import { Row, Col, TextInput, SelectInput, themes, Box, Icon } from '@digitools/honest-react';
import useTranslate from '../../../hooks/useTranslate';
import useAsDataContext from '../hooks/useAsDataContext';
import { fieldRequired, validateAddress, validateCity, validatePostalCode } from '../utils/validationUtils';
import { Scope, useFormState } from 'informed';
import { ResidentialInformationText } from '../constants/ResidentialInformationText';
import { AS_COUNTRIES, AS_STATES, AS_PROVINCES, AS_PROVINCES_VALUES } from '../constants/Constants';
import { StyledDiv, SectionHeading, DivBlock, StyledRow } from './LayoutComponents';
import styles from '../pages/aboutYou/aboutYou.scss';
import styled from 'styled-components';
import { AsText } from '../constants/Text';
const StyledIcon = styled(Icon) `
  font-size: 2.5rem; 
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 2rem;
    margin-top: -1.5rem;
  }
  `;
/**
 * Section of fields for residential information, for use within a <Form>
 */
const ResidentialInformation = () => {
    const { t } = useTranslate();
    const { claimantData } = useAsDataContext();
    const { values } = useFormState();
    let currentCountry = 'USA';
    // @ts-ignore
    const isCountryUS = values && values.claimantData && values.claimantData['empCtryCde'] === 'USA' ? true : false;
    // @ts-ignore
    if (values && values.claimantData && values.claimantData['empCtryCde'] === 'CAN') {
        currentCountry = 'CAN';
    }
    // Eligibility coming back with province ID but not country, this sets default country accordingly
    // @ts-ignore
    if ((!claimantData?.empCtryCde) && AS_PROVINCES_VALUES.includes(claimantData?.empState)) {
        currentCountry = 'CAN';
    }
    return (<StyledDiv data-public={true} data-testid='residential-information'>
      <Box className={'mt-4'}>
        <StyledRow>
          <DivBlock className={'d-flex align-items-center'}>
            <StyledIcon name={'residence-info'} size='large' color={'primary'}/>
            <SectionHeading elemType={'h3'} color={'black'} theme={themes.lincoln}>
            {t(ResidentialInformationText.residentialInfoHeading)}
            </SectionHeading>
          </DivBlock>
        </StyledRow>
      {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} xs={12}>
            <TextInput data-testid='residential-add-1' field={'empLine1Addr'} label={t(ResidentialInformationText.address1) + t(AsText.optionalText)} ariaLabelText={t(ResidentialInformationText.address1) + t(AsText.optionalText)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateAddress} initialValue={claimantData && claimantData.empLine1Addr}/>
          </Col>
          <Col md={6} xs={12}>
            <TextInput data-testid='residential-add-2' field={'empLine2Addr'} label={t(ResidentialInformationText.address2) + t(AsText.optionalText)} ariaLabelText={t(ResidentialInformationText.address2) + t(AsText.optionalText)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateAddress} initialValue={claimantData && claimantData.empLine2Addr}/>
          </Col>
          <Col md={3} xs={12}>
            <SelectInput data-testid='residential-country' ariaLabelText={t(ResidentialInformationText.countryCodeQuestionLabel)} field={'empCtryCde'} label={t(ResidentialInformationText.countryCodeQuestionLabel)} validateOnBlur={true} validate={fieldRequired} options={AS_COUNTRIES} initialValue={currentCountry || claimantData && claimantData.empCtryCde}/>
          </Col>
          <Col md={3} xs={12}>
          {isCountryUS && (<SelectInput data-testid='residential-state' ariaLabelText={t(ResidentialInformationText.stateQuestionLabel)} field={'empState'} label={t(ResidentialInformationText.stateQuestionLabel)} placeholderText={t(ResidentialInformationText.stateQuestionLabel)} validateOnBlur={true} validate={fieldRequired} options={AS_STATES} initialValue={claimantData && claimantData?.empState?.trim()}/>)}
            {currentCountry === 'CAN' && (<SelectInput data-testid='residential-province' ariaLabelText={t(ResidentialInformationText.provinceQuestionLabel)} field={'empState'} label={t(ResidentialInformationText.provinceQuestionLabel)} placeholderText={t(ResidentialInformationText.provinceQuestionLabel)} validateOnBlur={true} validate={fieldRequired} options={AS_PROVINCES} initialValue={claimantData && claimantData?.empState?.trim()}/>)}
          </Col>
          <Col md={3} className={styles.leftWidthStyling}>
            <TextInput data-testid='residential-city' field={'empCity'} label={t(ResidentialInformationText.cityQuestionLabel) + t(AsText.optionalText)} ariaLabelText={t(ResidentialInformationText.cityQuestionLabel) + t(AsText.optionalText)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateCity} initialValue={claimantData && claimantData.empCity}/>
          </Col>
          <Col md={3} className={styles.rightWidthStyling}>
            <TextInput maxLength={10} data-testid='residential-postal-code' field={'empZip'} label={t(ResidentialInformationText.zipQuestionLabel) + t(AsText.optionalText)} ariaLabelText={t(ResidentialInformationText.zipQuestionLabel) + t(AsText.optionalText)} theme={themes.lincoln} type={'tel'} validateOnBlur={true} validate={validatePostalCode} initialValue={claimantData && claimantData.empZip?.trim()}/>
          </Col>
        </Row>
      </Scope>
      </Box>
    </StyledDiv>);
};
export default ResidentialInformation;
