import React from 'react';
import { CompositeTable as DataTable, Spinner, Alert, Button, Modal, Icon, themes } from '@digitools/honest-react';
import { useLanguage } from 'status/hooks';
import useGet from '../../../../hooks/useGet';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';

const Error = ({ error }) => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong>&nbsp;Unable to load glossary data.
    </span>
  </Alert>
);

const OpenButton = ({ showModal }) => {
  const { trackEvent } = useTealium();

  const handleOnClick = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
      event_type: TEALIUM_EVENT.EVENT_TYPE.SHOW_GLOSSARY,
      event_name: 'Glossary',
    });
    showModal();
  };
  return (
    <Button onClick={handleOnClick} style={{ marginLeft: '0', paddingLeft: 0 }}>
      <Icon name='info' color={'secondary'} style={{ paddingRight: '.25rem' }} />
      Glossary
    </Button>
  );
};

const ModalContent = ({ loading, error, glossaryList }) => {
  const { language } = useLanguage();

  const lang = language.toUpperCase();

  if (loading) {
    return <Spinner id='glossarySpinner' />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const columns = [
    {
      Cell: ({ data }) => {
        return <strong>{data[`fieldValueTerm_${lang}`]}</strong>;
      },
      Header: 'Value',
      accessor: `fieldValueTerm_${lang}`,
      id: `fieldValueTerm_${lang}`,
    },
    {
      Header: 'Description',
      accessor: `fieldValueDescription_${lang}`,
      id: `fieldValueDescription_${lang}`,
    },
  ];

  return (
    <div style={{ minWidth: '70vw' }}>
      <DataTable filterable={true} data={glossaryList} columns={columns} paginated={true} />
    </div>
  );
};

const Glossary = () => {
  const {
    error,
    loading,
    data: glossaryList,
  } = useGet({
    url: '/status-service/customer/statusGlossary',
    options: {
      params: {
        applicationInternalName: 'STATUS_ER',
      },
    },
  });

  return (
    <Modal
      hasCloseIcon={true}
      headerText='Glossary'
      size='large'
      footerPrimaryButtonText='Close'
      modalOpenRenderButton={OpenButton}
      containerMaxHeight={'90%'}
      modalContainerStyle={{
        height: '90vh',
        width: '90vw',
        maxWidth: '90vw',
      }}>
      <ModalContent loading={loading} glossaryList={glossaryList} error={error} />
    </Modal>
  );
};

export default Glossary;
