import { CliConfirmationTextClaim } from './CliConfirmationTextClaim';
import { CliConfirmationTextCoord } from './CliConfirmationTextCoord';
import { CliConfirmationTextLeave } from './CliConfirmationTextLeave';
import { CliConfirmationTextClaimDocs } from './CliConfirmationTextClaimDocs';
// TODO: Claim and leave could both be CLIGeneralText in the future
// TODO: VideoText could be moved here during it's related story - bit funky since it's split up with submissionType
// TODO: spanish translations are still rough google translate
export const CliConfirmationText = {
    claim: CliConfirmationTextClaim,
    claimdocs: CliConfirmationTextClaimDocs,
    leave: CliConfirmationTextLeave,
    claimleave: CliConfirmationTextCoord
};
