// @ts-nocheck
import { Spinner } from '@digitools/honest-react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'src/utils/withRouter';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import useTealium from '../../../../../hooks/useTealium';
import { AuthenticationContext } from '../../../../../providers/AuthenticationProvider';
import { runReportId, showRunReportSpinner, startLoading, stopLoading } from '../../../crsActions';
import ReportDetailFooter from './ReportDetailFooter';
import { DateInformation, DetailInformation, EmployeePersonalInformation, EmployeeWorkInformation, } from './ReportFieldDetailPageBody';
import { RunReportDetails } from './RunReportDetails';
import { generateReportParam, runReportParam, selectedDateTypeSearchOn } from './RunReportParamMapping';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
{
    /* eslint-disable react/jsx-no-undef */
}
const ReportFieldDetailPage = props => {
    const [reportCount, setReportCount] = useState('');
    const [asOfDate, setAsOfDate] = useState('');
    const [runReportError, setRunReportError] = useState(false);
    const [reportCountError, setReportCountError] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState();
    const authContext = useContext(AuthenticationContext);
    const { trackEvent, trackView } = useTealium();
    const navigate = useNavigate();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CRS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.CRS.FIELD_DETAIL_PAGE,
        });
    }, []);
    useEffect(() => {
        if (props.callCustomerConfig) {
            navigate('/create-my-report');
        }
        window.scrollTo(0, 0);
        setReportParams();
    }, []);
    const setReportParams = async () => {
        const url = `/crs-service/set-report-params`;
        try {
            const setReportParamsRequestResource = runReportParam(props.reportFilterCategory, props.reportDateRange, props.products, props.filterStatusCodes, props.fieldPageInfo, props.reportId, props.workProvinces, props.workStates, props.subsLocs, props.selectedSubsLocs);
            props.startLoading();
            const response = await axios.post(url, setReportParamsRequestResource, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authContext.authHeader(),
                },
            });
            const result = response.data;
            props.updateRunReportId(result.reportId);
            setReportCount(result.reportCount);
            console.log('result.reportAsOfDate', result.reportAsOfDate);
            setAsOfDate(format(new Date(result.reportAsOfDate), 'Ppp'));
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                event_type: TEALIUM_EVENT.EVENT_TYPE.CREATE_MY_REPORT_FIELD_DETAIL,
                event_name: `${props.reportFilterCategory} Report`,
            });
            const dateTypeSelected = selectedDateTypeSearchOn(props.reportDateRange, props.reportFilterCategory);
            if (dateTypeSelected.value !== '') {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CREATE_MY_REPORT_FIELD_DETAIL,
                    event_name: dateTypeSelected.value,
                });
            }
        }
        catch (error) {
            props.updateRunReportId(0);
            setErrorStatusCode(error.response.status);
            setReportCountError(true);
        }
        finally {
            props.stopLoading();
        }
    };
    const runReport = async () => {
        const url = `/crs-service/run-my-report`;
        const { displayRunReportSpinner } = props;
        displayRunReportSpinner(true);
        try {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.RUN_REPORT,
                event_name: 'Run Report',
            });
            setRunReportError(false);
            const payload = generateReportParam(props.fieldPageInfo, props.reportFilterCategory, props.reportId, props.reportDateRange, props.filterStatusCodes, asOfDate);
            const response = await axios
                .post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authContext.authHeader(),
                },
                responseType: 'blob',
            })
                .then(res => {
                return res;
            });
            const blob = new Blob([response.data]);
            FileSaver.saveAs(blob, 'report.xls');
        }
        catch (error) {
            setErrorStatusCode(error.response.status);
            setRunReportError(true);
        }
        finally {
            displayRunReportSpinner(false);
        }
    };
    const { reportCountCallSuccess, history, fieldPageInfo, reportFilterCategory, employeeTextCustomContent, isLoading, store, } = props;
    // Show Spinner till the service call to get report count gets completed
    if (isLoading) {
        return <Spinner id='report-field-detail-page-spinner1'/>;
    }
    return (<>
      <div data-public={true}>
        <If condition={reportCountCallSuccess}>
          <EmployeePersonalInformation store={store} history={history} fieldPageInfo={fieldPageInfo} reportFilterCategory={reportFilterCategory} employeeTextCustomContent={employeeTextCustomContent}/>
          <EmployeeWorkInformation store={store} history={history} fieldPageInfo={fieldPageInfo} reportFilterCategory={reportFilterCategory} employeeTextCustomContent={employeeTextCustomContent}/>
          <DateInformation store={store} history={history} fieldPageInfo={fieldPageInfo} reportFilterCategory={reportFilterCategory} employeeTextCustomContent={employeeTextCustomContent}/>
          <DetailInformation store={store} history={history} fieldPageInfo={fieldPageInfo} reportFilterCategory={reportFilterCategory} employeeTextCustomContent={employeeTextCustomContent}/>
          <RunReportDetails reportCount={reportCount} asOfDate={asOfDate} reportCountError={reportCountError} runReportError={runReportError} errorStatusCode={errorStatusCode}/>
        </If>

        {props.showRunReportSpinner && <Spinner id='report-field-detail-page-spinner2'/>}
        {!props.showRunReportSpinner && (<ReportDetailFooter store={store} history={history} fieldPageInfo={fieldPageInfo} reportFilterCategory={reportFilterCategory} employeeTextCustomContent={employeeTextCustomContent} runReport={runReport} reportCount={reportCount} reportCountError={reportCountError}/>)}
      </div>
    </>);
};
export const mapStateToProps = ({ crsReducer }) => ({
    isLoading: crsReducer.isLoading,
    fieldPageInfo: crsReducer.fieldPageInfo,
    reportFilterCategory: crsReducer.reportFilterCategory,
    callCustomerConfig: crsReducer.callCustomerConfig,
    employeeTextCustomContent: crsReducer.employeeTextCustomContent,
    filterStatusCodes: crsReducer.filterStatusCodes,
    products: crsReducer.products,
    reportDateRange: crsReducer.reportDateRange,
    workStates: crsReducer.workStates,
    workProvinces: crsReducer.workProvinces,
    reportId: crsReducer.reportId,
    reportCountCallSuccess: crsReducer.reportCountCallSuccess,
    showRunReportSpinner: crsReducer.showRunReportSpinner,
    subsLocs: crsReducer.subLocs,
    selectedSubsLocs: crsReducer.selectedSubLocs,
});
const mapDispatchToProps = (dispatch) => ({
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    updateRunReportId: (payload) => dispatch(runReportId(payload)),
    displayRunReportSpinner: (payload) => dispatch(showRunReportSpinner(payload)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportFieldDetailPage));
