export const SaveForLaterText = {
    cancelApplication: {
        english: 'Cancel application',
        spanish: ''
    },
    cancelApplicatinModalText: {
        english: 'If you cancel this application, all information will be removed and you will return to the intake page. ',
        spanish: ''
    },
    deleteApplication: {
        english: 'Delete application',
        spanish: 'Borrar solicitud'
    },
    saveForLater: {
        english: 'Save for later',
        spanish: 'Guardar para mas tarde'
    },
    exitApplication: {
        english: 'Save and exit',
        spanish: 'guardar y Salir'
    },
    deleteApplicationModal: {
        english: 'Yes, delete application',
        spanish: 'Sí, eliminar aplicación'
    },
    returnToApplication: {
        english: 'Return to application',
        spanish: 'Regresar a la solicitud'
    },
    savedApplicationHeaderSuccess: {
        english: 'Your progress has been saved.',
        spanish: 'Se guardó la solicitud.'
    },
    paymentSavedApplicationHeaderSuccess: {
        english: 'Your progress has been saved.',
        spanish: 'Tu progreso ha sido guardado.'
    },
    savedApplicationHeaderError: {
        english: 'Error saving application.  Your application has not been saved.',
        spanish: 'Error al guardar la aplicación. Su aplicación no se ha guardado.'
    },
    savedApplicationTextSuccess: {
        english: 'The application will expire and be removed from our records on ',
        spanish: 'La solicitud caducará y será eliminada de nuestros registros el '
    },
    paymentSavedApplicationTextSuccess: {
        english: 'To ensure account information is secure, payment method entries were not saved. Please click "Exit Application" to exit the application and "Return to Application" to return to where you were. The application will expire and be removed from our records on ',
        spanish: 'Para garantizar que la información de la cuenta sea segura, no se guardaron las entradas del método de pago. Haga clic en "Salir de la aplicación" para salir de la aplicación y en "Volver a la aplicación" para volver a donde estaba. La solicitud caducará y será eliminada de nuestros registros el '
    },
    savedApplicationTextError: {
        english: 'Please try again.',
        spanish: 'Inténtalo de nuevo.'
    },
    deleteApplicationHeaderSuccess: {
        english: 'Are you sure you wish to exit at this time?',
        spanish: '¿Estás seguro de que deseas salir en este momento?'
    },
    deleteApplicationTextSuccess: {
        english: 'Any information provided will be lost and your application will be removed from our records.',
        spanish: 'Cualquier información proporcionada se perderá y su solicitud será eliminada de nuestros registros.'
    },
    saveForLaterDeleteErrorMessage: {
        english: 'There was an error deleting your application. Please try again.',
        spanish: 'Hubo un error al eliminar tu aplicación. Inténtalo de nuevo.',
    },
    loadSavedInterviewHeader: {
        english: 'Pick up where you left off!',
        spanish: '¡Continúe donde lo dejó!',
    },
    loadSavedInterviewBody: {
        english: 'Your application was saved on ',
        spanish: 'Su solicitud se guardó el ',
    },
    at: {
        english: ' at ',
        spanish: ' a las ',
    },
    loadInterviewButtonText: {
        english: 'VIEW MY APPLICATION',
        spanish: 'VER MI APLICACIÓN',
    },
};
