import React from 'react';
import { TextInput, SelectInput, Button, Row, Col } from '@digitools/honest-react';
// @ts-ignore
import Spinner from '../../../components/Spinner/Spinner';
import useTealium from '../../../../../hooks/useTealium';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { useFormState } from 'informed';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
const validateSelect = (input) => {
    if (!input) {
        return 'Employee search criteria is required';
    }
};
export const validateEmployeeName = (input) => {
    if (!input || input.length < 2) {
        return 'Please enter at least two letters of the last name.';
    }
};
export const validateContent = (input) => {
    if (!input) {
        return 'Please enter search criteria.';
    }
};
export const validateNumbersOnly = (input) => {
    if (!input) {
        return 'Please enter a number';
    }
    else if (isNaN(Number(input))) {
        return 'Please enter numbers only';
    }
    else if (input.length < 2) {
        return 'Please enter at least two digits';
    }
};
export const getSearchValidator = (searchCriteria) => {
    switch (searchCriteria) {
        case 'empLastName':
            return validateEmployeeName;
        case 'empId':
            return validateNum;
        case 'claimNum':
        case 'accommodationEventId':
            return validateNumbersOnly;
        default:
            return validateContent;
    }
};
export const validateNum = (input) => {
    if (!input || input.length < 2) {
        return 'Please enter at least two digits.';
    }
};
export const getPlaceholder = (searchCriteria) => {
    switch (searchCriteria) {
        case 'empLastName':
            return 'Enter last name, first name';
        case 'empId':
            return 'Enter employee ID';
        case 'claimNum':
            return 'Enter claim number or leave ID';
        case 'accommodationEventId':
            return 'Enter accommodation event ID';
        default:
            return 'Select search criteria';
    }
};
export const getSearchLabel = (searchCriteria) => {
    switch (searchCriteria) {
        case 'empLastName':
            return 'Employee name';
        case 'empId':
            return 'Employee ID';
        case 'claimNum':
            return 'Claim number';
        case 'accommodationEventId':
            return 'Accommodation event ID';
        default:
            return 'Search';
    }
};
const EmployeeSearchForm = ({ loading, searchMeta }) => {
    const { trackEvent } = useTealium();
    const formState = useFormState();
    const { user } = useAuthentication();
    const placeholder = getPlaceholder(formState.values.searchCriteria);
    const label = getSearchLabel(formState?.values?.searchCriteria);
    const validateSearch = getSearchValidator(formState.values.searchCriteria);
    const options = [
        { label: `${searchMeta.employeeText_en} name`, value: 'empLastName' },
        { label: searchMeta.employeeIDText_en, value: 'empId' },
        { label: 'Claim or leave number', value: 'claimNum' },
    ];
    if (user && user.applicationPermissions &&
        (user.applicationPermissions.indexOf('statusEE.viewAccommodationStatus.viewBasicAsRequest') > -1 ||
            user.applicationPermissions.indexOf('statusER.viewAccommodationStatus.viewBasicAsRequest') > -1)) {
        options.push({ label: 'Accommodation event ID', value: 'accommodationEventId' });
    }
    return (<>
      <Row className='mb-2'>
        <Col lg={3}>
          <SelectInput field='searchCriteria' label='Search criteria' placeholderText='Search criteria' validateOnBlur={true} options={options} validate={validateSelect} data-testid='search-criteria'/>
        </Col>
        <Col lg={7}>
          <TextInput data-private={true} field='search' label={label} validate={validateSearch} placeholder={placeholder} type={'text'} icon={'search'} data-testid='search-text'/>
        </Col>
        <Col lg={2}>
        
          <Button type='submit' data-testid='search-submit' onClick={() => trackEvent({ event_action: TEALIUM_EVENT.EVENT_ACTION.SEARCH, event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS, event_name: label })} buttonType='primary'>
            {loading ? (<Spinner id='ee-search-form-spinner' data-testid='search-loading' size='small' style={{ margin: 0 }}/>) : 'Search'}
          </Button>
        </Col>
      </Row>
    </>);
};
export default EmployeeSearchForm;
