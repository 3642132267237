//Let us help on login -> to 1st guided experience page
// back on login -> to 3 card employee page (page 2)
export const VanityPageText = {
    alreadyRegistered: {
        english: 'Have you already registered to access your benefits?',
        spanish: '¿Ya se registró para acceder a sus beneficios?'
    },
    currentLogIn: {
        english: 'Where do you currently log in?',
        spanish: '¿Dónde inicia sesión actualmente?'
    },
    migrateAccount: {
        english: 'Your MyLincolnPortal.com account is moving to LincolnFinancial.com. Have you already moved your account?',
        spanish: 'Su cuenta de MyLincolnPortal.com se moverá a LincolnFinancial.com. ¿Ya movió su cuenta?'
    },
    yesBenefits: {
        english: 'Yes, I have an online account.',
        spanish: 'Sí, tengo una cuenta en línea.'
    },
    noBenefits: {
        english: 'No, I do not have an online account.',
        spanish: 'No, no tengo una cuenta en línea'
    },
    unknown: {
        english: 'I\'m not sure.',
        spanish: 'No estoy seguro.'
    },
    migrateToLfg: {
        english: 'Since you have not created an account with us, register for an account through the LincolnFinancial.com log in page.',
        spanish: 'Dado que no ha creado una cuenta con nosotros, regístrese para obtener una cuenta mediante la página de inicio de sesión de LincolnFinancial.com.'
    },
    haveLfgAccount: {
        english: 'Access your benefits by logging into LincolnFinancial.com or the mobile app.',
        spanish: 'Acceda a sus beneficios iniciando sesión en LincolnFinancial.com o en la aplicación móvil.'
    },
    startAtMlp: {
        english: 'Start your move to LincolnFinancial.com by logging into your MyLincolnPortal.com account.',
        spanish: 'Comience el cambio a LincolnFinancial.com iniciando sesión en su cuenta de MyLincolnPortal.com.'
    },
    alreadyMigrated: {
        english: 'Yes, I already moved my account.',
        spanish: 'Sí, ya moví mi cuenta.'
    },
    notMigrated: {
        english: 'No, I have not moved my account, yet.',
        spanish: 'No, no he movido mi cuenta.'
    },
    unsureIfMigrated: {
        english: 'I\'m not sure if I moved my account.',
        spanish: 'No estoy segura si mudé mi cuenta.'
    },
    continue: {
        english: 'Continue',
        spanish: 'Seguir'
    },
    back: {
        english: 'Back',
        spanish: 'Atrás',
    },
    register: {
        english: 'Register',
        spanish: 'Regístrese'
    },
    login: {
        english: 'Log in',
        spanish: 'Inicio de sesión'
    },
    startMove: {
        english: 'You can start your move to LincolnFinancial.com by signing into your account on MyLincolnPortal.',
        spanish: 'Puede comenzar su migración a LincolnFinancial.com iniciando sesión en su cuenta en MyLincolnPortal.'
    },
    contiueToMlp: {
        english: 'Continue to your MyLincolnPortal to start move.',
        spanish: 'Continúe en MyLincolnPortal para comenzar a moverse.'
    },
    fieldRequired: {
        english: 'This field is required.',
        spanish: 'Este campo es requerido.',
    },
    backToLogin: {
        english: <>My Lincoln Portal<sup><small>®</small></sup></>,
        spanish: <>My Lincoln Portal<sup><small>®</small></sup></>
    },
    hereToHelp: {
        english: 'We are here to help.',
        spanish: 'Estamos aquí para ayudar.'
    },
    LCN: {
        english: 'LCN-6596292-050224',
        spanish: 'LCN-6596345-050224'
    },
    //TODO: move to constants/text file
    imEmployerOrBroker: {
        english: 'I\'m an employer or broker.',
        spanish: 'Soy empleador o corredor.',
    },
    imEmployee: {
        english: 'I\'m an employee or claimant.',
        spanish: 'Soy empleado o reclamante.',
    },
    employeeSignInMoving: {
        english: 'Employees, where you sign in is moving.',
        spanish: 'Empleados, el lugar donde inician sesión cambiará.'
    },
    welcome: {
        english: 'Welcome, we are moving a few things around.',
        spanish: 'Bienvenido, cambiaremos de lugar algunas cosas.'
    },
    letsHelp: {
        english: 'Let\'s help you get to the right place.',
        spanish: 'Déjenos ayudarlo a llegar al lugar correcto.'
    },
    logInToMoveHeader: {
        english: <>Log in to move your account to <strong>LincolnFinancial.com </strong> </>,
        spanish: <>Inicie sesión para mover su cuenta a <strong>LincolnFinancial.com.</strong> </>,
    },
    logInToMove: {
        english: 'Log in to begin the move',
        spanish: 'Inicie sesión para comenzar el cambio',
    },
    notSure: {
        english: 'Not sure where to go? Let us help.',
        spanish: '¿No está seguro de adónde ir? Podemos ayudarlo.',
    },
    registerLFG: {
        english: 'New user? Register on LincolnFinancial.com',
        spanish: '¿Es un usuario nuevo? Regístrese en LincolnFinancial.com',
    },
    learnMore: {
        english: `Learn more`,
        spanish: 'Obtenga más información.'
    },
};
