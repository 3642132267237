import React from 'react';
import { Link, CompositeTable, Heading, Container, } from '@digitools/honest-react';
import { getFormattedEmpName } from '../api';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const EmployeeSearchResultSection = (props) => {
    const { trackEvent } = useTealium();
    const columns = [
        {
            Header: 'Employee Name',
            accessor: 'employeeName',
            id: 'employeeName',
            Cell: ({ data }) => {
                return (<Link tabIndex={0} onClick={() => {
                        trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORTEE,
                            event_name: 'Reportee Link'
                        });
                        props.getHierarchy(data._original.empId);
                    }} data-testid={`employee-${data._index}-link`} aria-label={`${getFormattedEmpName(data._original)} press enter to view the employee's heirarky below`}>
          {getFormattedEmpName(data._original)}
        </Link>);
            },
        },
        {
            Header: 'Employee Id',
            accessor: 'empId',
            id: 'empId',
        },
        {
            Header: 'Supervisor Name',
            accessor: 'supervisorName',
            id: 'supervisorName',
        },
    ];
    return (<Container style={{ marginRight: '.3rem', marginBottom: '2rem' }}>
      <Heading aria-label={'Search result'} elemType={'h3'} color={'primary'} data-testid={'searchResult'}>Search result</Heading>
      <CompositeTable data={props.searchResult} columns={columns} paginated={true}/>
    </Container>);
};
export default EmployeeSearchResultSection;
