import React, { useEffect } from 'react';
import useExperience from '../../../../hooks/useExperience';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
const CommHelpStaticPage = () => {
    const { language } = useExperience();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.COMM_HELP,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.COMM_HELP.VIEW,
        });
    }, []);
    if (language === 'en') {
        return (<span>
          <header style={{ border: 'solid 2px black' }}>
             <div>
             <div style={{ backgroundColor: '#C9DAED', borderBottom: 'solid 2px black' }}>
                <div style={{ padding: '.5rem', textAlign: 'center' }}>
                  <b>
                     PROGRAM OVERVIEW: Lincoln Financial Group Customer Alerts
                  </b>
                </div>
             </div>
             <div style={{ padding: '.5rem' }}>
                  Lincoln Financial Group ** Customer Alerts is a web-based SMS and e-mail messaging service. The service enables normal (non-premium) SMS messaging services as well as e-mail services. There are several pre-built SMS and e-mail applications that can help Lincoln Financial Group manage recipient (end-user) permissions and opt-in/opt-out lists. It includes    several vertical applications that can be deployed either individually or bundled together. The result is a service that can address a wide variety of messaging services across the organization that comply with Mobile Marketing Association (MMA) and other regulations that govern the use of SMS and e-mail. 
            </div>
            </div>
         </header>
         <p>
            <b><br />TERMS AND CONDITIONS FOR THE Lincoln Financial Group Customer Alerts<br /></b>
         </p>
         <p> 
            The mobile operators participating in this campaign are AT&amp;T, Verizon Wireless, Sprint, T-Mobile©, Boost Mobile, Cricket, Virgin Mobile USA, MedivoPCS, U.S. Cellular©, Ntelos, Cellular South, Cincinnati Bell, Centennial, Bluegrass, Appalachian Wireless, Revol, Illinois Valley, United Wireless, Inland Cellular, West Cendival Cellular, ECIT, Immix, Nex-Tech, Pocket Com USA, Pioneer Wireless USA, Simmerty US, Union Wireless, Cellcom. 
         </p>
   
         <ol>
            <li>For help text <b>"HELP"</b> to 78897 or <a href='https://www.mylincolnportal.com'><b>visit our website</b></a>.</li>
            <li>To unsubscribe, text <b>"STOP"</b> to 78897 at any time.</li>
   
            <li><b>Message and Data rates may apply.</b></li>
            <li>Msg Frequency based on user activity.</li>
            <li>SMS text messages are intended to be a best effort communication and represent just one component of Lincoln Financial Group communication systems.</li>
            <li>SMS text message notifications may be delayed or not received during regional emergencies or other periods of high messaging traffic.</li>
            <li>Third parties can send SMS text messages disguised as alerts from Lincoln Financial Group and therefore you are urged to independently  verify the authenticity of any message that you receive. 
            </li>
            <li>SMS text message order delivery is not always predictive. 
            </li>
            <li>SMS text message notifications from Lincoln Financial Group will be presented on your mobile device in the same manner as any other SMS text messages. 
            </li>
            <li>MMS (Multimedia Messaging Service) messages may be sent by this service to deliver relevant images.</li>
            <li>If your phone does not support MMS, the user will receive SMS instead.</li>
         </ol>
   
       </span>);
    }
    else {
        return (<span>
        <div style={{ border: 'solid 2px black' }}>
           <div>
           <div style={{ backgroundColor: '#C9DAED', borderBottom: 'solid 2px black' }}>
              <div style={{ padding: '.5rem', textAlign: 'center' }}>
                <b>
                  RESUMEN DEL PROGRAMA: Alertas de clientes de Lincoln Financial Group
                </b>
              </div>
           </div>
           <div style={{ padding: '.5rem' }}>
             Lincoln Financial Group ** Customer Alerts es un servicio de correo elecdivónico y mensajería SMS basado en la web. El servicio habilita servicios de mensajería SMS normales (no premium), así como servicios de correo elecdivónico. Existen varias aplicaciones de correo elecdivónico y SMS preconsdivuidas que pueden ayudar a Lincoln Financial Group a adminisdivar los permisos de los destinatarios (usuarios finales) y las listas de inclusión / exclusión. Incluye varias aplicaciones verticales que pueden implementarse individualmente o agrupadas. El resultado es un servicio que puede abordar una amplia variedad de servicios de mensajería en toda la organización que cumplen con la Mobile Marketing Association (MMA) y odivas regulaciones que rigen el uso de SMS y correo elecdivónico.
          </div>
          </div>
       </div>
       <p>
          <b><br />TÉRMINOS Y CONDICIONES PARA LAS ALERTAS DE CLIENTES DE Lincoln Financial Group<br /></b>
       </p>
       <p> 
       Los operadores móviles que participan en esta campaña son AT&amp;T, Verizon Wireless, Sprint, T-Mobile©, Boost Mobile, Cricket, Virgin Mobile USA, MedivoPCS, U.S. Cellular©, Ntelos, Cellular South, Cincinnati Bell, Centennial, Bluegrass, Appalachian Wireless, Revol, Illinois Valley, United Wireless, Inland Cellular, West Cendival Cellular, ECIT, Immix, Nex-Tech, Pocket Com USA, Pioneer Wireless USA, Simmerty US, Union Wireless, Cellcom. 
       </p>
 
       <ol>
          <li>Para texto de ayuda <b>"AYUDA"</b> to 78897 o <a href='https://www.mylincolnportal.com'><b>Visita nuesdivo sitio web</b></a>.</li>
          <li>Para darse de baja, envíe un mensaje de texto <b>"PARAR"</b> a 78897 en cualquier momento.</li>
 
          <li><b>Se pueden aplicar tarifas por mensajes y datos.</b></li>
          <li>Frecuencia de mensajes basada en la actividad del usuario.</li>
          <li>Los mensajes de texto SMS están destinados a ser el mejor esfuerzo de comunicación y representan solo un componente de los sistemas de comunicación del Lincoln Financial Group.</li>
          <li>Las notificaciones de mensajes de texto SMS pueden redivasarse o no recibirse durante emergencias regionales u odivos períodos de alto diváfico de mensajes.</li>
          <li>Los terceros pueden enviar mensajes de texto SMS disfrazados como alertas de Lincoln Financial Group y, por lo tanto, se recomienda que verifique independientemente la autenticidad de cualquier mensaje que reciba.
          </li>
          <li>La endivega de pedidos de mensajes de texto SMS no siempre es predecible.
          </li>
          <li>Las notificaciones de mensajes de texto SMS de Lincoln Financial Group se presentarán en su dispositivo móvil de la misma manera que cualquier odivo mensaje de texto SMS.
          </li>
          <li>Este servicio puede enviar mensajes MMS (Servicio de mensajería multimedia) para endivegar imágenes relevantes.</li>
          <li>Si su teléfono no es compatible con MMS, el usuario recibirá SMS en su lugar.</li>
       </ol>
 
     </span>);
    }
};
export default CommHelpStaticPage;
