import React from 'react';
import { Link, Box, Heading, themes } from '@digitools/honest-react';
import { getFormattedEmpName, getFormattedNameEmpId } from '../api';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const Manager = (props) => {
    const { trackEvent } = useTealium();
    return (<>
      <Heading data-testid='manager-heading' elemType={'h3'} color={'primary'} aria-label={'Manager'}>Manager</Heading>
      <Box data-testid='manager-box' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }} aria-live="polite">
        {props.managerData !== null && props.managerData !== undefined ? (<Link tabIndex={0} onClick={() => {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.MANAGER,
                    event_name: 'Manager Link'
                });
                props.getHierarchy(props.managerData.empId);
            }} data-private={true} data-testid={`manager-link`} aria-label={`Press enter to view ${getFormattedEmpName(props.managerData)}'s employee heirarchy`}>
            {getFormattedNameEmpId(props.managerData)}
          </Link>) : ('No manager found')}
      </Box>
    </>);
};
export default Manager;
