import useAuthentication from 'src/hooks/useAuthentication';
import React, { createContext, useEffect, useState } from 'react';
import { GetSubscriber } from '../api/DentalApiCalls';
import useExperience from 'src/hooks/useExperience';
export var MemberType;
(function (MemberType) {
    MemberType["SUBSCRIBER"] = "SUBSCRIBER";
    MemberType["SPOUSEorPARTNER"] = "SPOUSE or PARTNER";
    MemberType["DEPENDENTCHILD"] = "DEPENDENT CHILD";
})(MemberType || (MemberType = {}));
export const DentalDataContext = createContext({
    subscriber: undefined,
    setSubscriber: () => { },
    currentMember: undefined,
    setCurrentMember: () => { },
    success: true,
    setSuccess: () => { },
    errorMessage: undefined,
    setErrorMessage: () => { },
    loading: false,
    setLoading: () => { },
    infoLoading: false,
    setInfoLoading: () => { },
});
export const DentalDataProvider = ({ children, }) => {
    const { authHeader } = useAuthentication();
    const [loading, setLoading] = useState(false);
    const [infoLoading, setInfoLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const [subscriber, setSubscriber] = useState();
    const [currentMember, setCurrentMember] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const { language } = useExperience();
    useEffect(() => {
        setLoading(true);
        GetSubscriber(authHeader(), language === 'es' ? 'SPANISH' : 'ENGLISH').then(response => {
            const data = response?.data?.data;
            if (data?.errors) {
                setSuccess(false);
                setErrorMessage(data.errors.message);
            }
            else {
                setSubscriber(data.subscriber);
                setCurrentMember(data.subscriber);
            }
            setLoading(false);
        });
    }, [language]);
    const dentalDataContext = {
        subscriber,
        setSubscriber,
        currentMember,
        setCurrentMember,
        success,
        setSuccess,
        errorMessage,
        setErrorMessage,
        loading,
        setLoading,
        infoLoading,
        setInfoLoading,
    };
    return <DentalDataContext.Provider value={dentalDataContext}>{children}</DentalDataContext.Provider>;
};
