import { Button, Modal } from '@digitools/honest-react';
import React from 'react';
import { camelize } from '../../utils/LCIUtils';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';
import { useNavigate } from 'react-router-dom';
const OpenModalButton = (step, pageTitle) => ({ showModal }) => {
    const { trackEvent } = useTealium();
    const showConfirmationModal = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_status: step,
            event_name: 'cancel',
            event_version: pageTitle,
        });
        showModal();
    };
    return (<Button className={'mr-3'} name='cancel-lci' onClick={showConfirmationModal} type='button' buttonType='secondary'>
      Cancel
    </Button>);
};
const ModalCancelButton = (sectionInfo) => ({ hideModal }) => {
    const hideConfirmationModal = () => {
        hideModal();
    };
    return (<Button name='cancelModal-cancel' onClick={hideConfirmationModal} type='button' buttonType='text'>
      Cancel
    </Button>);
};
const ModalOkButton = (sectionInfo, history) => () => {
    const navigate = useNavigate();
    const { trackEvent } = useTealium();
    const goToHome = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_name: 'Cancel',
            event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL
        });
        navigate(`/employer/home`);
    };
    return (<Button data-testid='cancelModal-ok' onClick={goToHome} type='button' buttonType='primary'>
      Ok
    </Button>);
};
const CancelLci = (props) => {
    const sectionInfo = '/lci/' + camelize(props.sectionInfo);
    return (<Modal id={'cancelModal'} headerText='Cancel Claim' modalOpenRenderButton={OpenModalButton(props.step, props.pageTitle)} footerPrimaryRenderButton={ModalOkButton(sectionInfo, props.history)} footerSecondaryRenderButton={ModalCancelButton(sectionInfo)}>
      <p>If you leave this page, data entered will be lost.</p>
    </Modal>);
};
export default withRouter((props) => (<CancelLci {...props}/>));
