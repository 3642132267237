import { Button, Col, Icon, Row, themes } from '@digitools/honest-react';
import { compareDesc, format, parse } from 'date-fns';
import React, { useState } from 'react';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import { SubmitAbsenceData } from '../../api/RiaApi';
import DisplayField from '../../components/DisplayField';
import { RIA_TEXT } from '../../constants/RiaConstants';
import useRiaConfig from '../../hooks/useRiaConfig';
import useRiaData from '../../hooks/useRiaData';
import { absenceDurationFormat } from '../../utils/AbsenceDurationUtils';
/**
 * Display component for displaying submitted absence date times and allowing resbmission
 * renders them differently based on the absenceDateTime data (isUpdated etc.)
 * @param param0
 * @returns
 */
const SubmittedAbsences = ({ absences, setAbsenceDateTimes }) => {
    const { authHeader } = useAuthentication();
    const [resubmitLoadingIndex, setResubmitLoadingIndex] = useState();
    const { t } = useTranslate();
    const { language } = useExperience();
    const { riaConfig } = useRiaConfig();
    const { currentLeave } = useRiaData();
    const resubmitAbsence = async (index, absence) => {
        setResubmitLoadingIndex(index);
        //@ts-ignore
        const parseDate = new Date(absence?.absenceDate);
        const formatDate = format(parseDate, 'P');
        const submitObj = {
            absenceResource: {
                absenceDate: formatDate,
                absenceHours: absence?.absenceHours,
                absenceMinutes: absence?.absenceMinutes,
                leaveNumber: currentLeave?.lveNum,
            },
            lang: language,
            dateEnteredTimeStamp: new Date().toString(),
            emailActive: riaConfig?.reportingPolicyItems[0]?.emailActive,
            emailAddress: riaConfig?.reportingPolicyItems[0]?.emailAddresses,
            locCde: currentLeave?.locCde,
            ssdCde: currentLeave?.ssdCde,
        };
        try {
            //@ts-ignore
            const { status } = await SubmitAbsenceData(submitObj, authHeader());
            if (status === 200) {
                if (absences && setAbsenceDateTimes) {
                    const absencesCopy = absences;
                    absencesCopy[index].success = true;
                    setAbsenceDateTimes([]);
                    setAbsenceDateTimes(absences);
                }
            }
            setResubmitLoadingIndex(undefined);
        }
        catch (error) {
            setResubmitLoadingIndex(undefined);
        }
    };
    const getResultText = (absence) => {
        if (absence.success) {
            return absence.isUpdate ? RIA_TEXT.updateSuccess : RIA_TEXT.submitSuccess;
        }
        else {
            return absence.isUpdate ? RIA_TEXT.updateFailure : RIA_TEXT.submitFailure;
        }
    };
    absences &&
        absences.sort((a, b) => {
            return compareDesc(parse(a.absenceDate ? a.absenceDate : '', 'yyyy-MM-dd', new Date()), parse(b.absenceDate ? b.absenceDate : '', 'yyyy-MM-dd', new Date()));
        });
    return (<>
      {absences &&
            absences.map((absence, index) => {
                return (<Row>
              <Col style={{ display: 'flex', alignItems: 'end' }} md={3} sm={6}>
                <DisplayField style={{ width: '100%' }} label={<div className='d-block d-md-none'> {t(RIA_TEXT.absenceDate)}</div>} value={format(new Date(absence.absenceDate), 'MM/dd/yyyy')}/>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'end' }} md={3} sm={6}>
                <DisplayField style={{ width: '100%' }} label={<div className='d-block d-md-none'> {t(RIA_TEXT.absenceDuration)}</div>} value={absenceDurationFormat(absence.absenceHours, absence.absenceMinutes) + ' (Hrs/Min)'}/>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'center' }} lg={5} md={5} sm={6}>
                <DisplayField style={{ width: '100%' }} label={<div className='d-block d-md-none'> {t(RIA_TEXT.absenceStatus)}</div>} value={t(getResultText(absence))}/>
                <Icon className={'mt-0 mb-1'} aria-hidden={true} data-testid='confirmation-icon' name={absence.success ? 'success' : 'sync-solid'} color={absence.success ? 'success' : 'secondary'} size={'small'}/>
                {resubmitLoadingIndex === index ? (<span className={'mt-3 mt-md-0'} style={{
                            marginLeft: '1rem',
                            padding: 0,
                            color: `${themes.secureSite.palette.secondary}`,
                        }}>
                    {t(RIA_TEXT.pending)}
                  </span>) : (<Button className={'mb-2 mb-sm-1'} aria-label={`${absence.success && 'Success'}`} id='resubmit' onClick={() => resubmitAbsence(index, absence)} buttonType={'text'} style={{
                            marginLeft: '1rem',
                            padding: 0,
                            color: `${themes.secureSite.palette.secondary}`,
                        }}>
                    {' '}
                    {absence.success ? '' : t(RIA_TEXT.resubmit)}
                  </Button>)}
              </Col>
            </Row>);
            })}
    </>);
};
export default SubmittedAbsences;
