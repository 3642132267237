import { Alert } from '@digitools/honest-react';
import React from 'react';
import { Language } from '../../types/Language';
import useExperience from '../../hooks/useExperience';
import { v4 as uuidv4 } from 'uuid';
import Link from '@digitools/honest-react/dist/lib/components/Link';
const parseMessageForLinks = (rawMessage) => {
    const regex = /<(.*?)\[.*?\]>/;
    if (rawMessage.search(regex) < 0) {
        return rawMessage;
    }
    else {
        const linkToken = rawMessage.substring(rawMessage.indexOf('<') + 1, rawMessage.indexOf('>'));
        const textBefore = rawMessage.substring(0, rawMessage.indexOf('<'));
        let textAfter = rawMessage.substring(rawMessage.indexOf('>') + 1);
        const url = linkToken.substring(0, linkToken.indexOf('['));
        const title = linkToken.substring(linkToken.indexOf('[') + 1, linkToken.indexOf(']'));
        if (textAfter) {
            textAfter = parseMessageForLinks(textAfter);
        }
        return (<>{textBefore}<Link data-testid='notification-link' href={url}>{title}</Link>{textAfter}</>);
    }
};
const FeatureSplashNotification = ({ notification }) => {
    const { language } = useExperience();
    return (<Alert data-testid="splash-notification" data-public={true} type={'warning'} closeable={false} id={uuidv4()} key={uuidv4()}>
        <strong> {language === Language.SPANISH ? notification.titleES : notification.titleEN}</strong>{' '}
        {language === Language.SPANISH ? parseMessageForLinks(notification.msgES) : parseMessageForLinks(notification.msgEN)}
        </Alert>);
};
export default FeatureSplashNotification;
