import React from 'react';
import { BDM_BENEFICIARY_TYPES } from '../constants/BdmConstants';
import BdmModalContentCharityOrg from './BdmModalContentCharityOrg';
import BdmModalContentEstate from './BdmModalContentEstate';
import BdmModalContentPerson from './BdmModalContentPerson';
import BdmModalContentSharedFields from './BdmModalContentSharedFields';
import BdmModalContentTrust from './BdmModalContentTrust';
const benPerson = BDM_BENEFICIARY_TYPES[0].value;
const benEstate = BDM_BENEFICIARY_TYPES[1].value;
const benCharity = BDM_BENEFICIARY_TYPES[2].value;
const benTrust = BDM_BENEFICIARY_TYPES[3].value;
const BdmModalContent = ({ beneficiary, beneficiaryCategory, beneficiaryIndex, beneficiaryType, setSsnOrTaxId }) => {
    return (<>
      {beneficiaryType === benPerson && (<BdmModalContentPerson beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={beneficiaryIndex} beneficiary={beneficiary}/>)}

      {beneficiaryType === benEstate && (<BdmModalContentEstate beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={beneficiaryIndex} beneficiary={beneficiary}/>)}

      {beneficiaryType === benCharity && (<BdmModalContentCharityOrg beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={beneficiaryIndex} beneficiary={beneficiary}/>)}

      {beneficiaryType === benTrust && (<BdmModalContentTrust beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={beneficiaryIndex} beneficiary={beneficiary}/>)}

      {beneficiaryType && (<BdmModalContentSharedFields beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={beneficiaryIndex} beneficiary={beneficiary}/>)}
    </>);
};
export default BdmModalContent;
