export const IMfaTroubleshootDisplayTextEs = {
    linkText: 'Ayuda con problemas',
    heading: '¿No recibe códigos por SMS?',
    body: 'El SMS es un protocolo de mejor esfuerzo. Eso significa que nuestros socios de telefonía harán todo lo posible para entregar el mensaje a los teléfonos, pero puede haber errores. A veces, debido al alto tráfico de red o a los filtros de mensajes no deseados, los usuarios no reciben el mensaje de texto.',
    stepsTitle: 'Por eso, pueden tomar algunas medidas para aumentar las posibilidades de recibir los códigos:',
    step1Title: 'Pruebe con una llamada telefónica',
    step1Text: 'En general, las llamadas son mucho más rápidas. Si no recibe el SMS, intente recibir el token mediante una llamada telefónica. Tenga en cuenta que necesitará una línea activa y directa, que no sea una extensión y que no envíe la llamada directamente a un buzón de voz, ya que deberá ingresar los códigos en el teléfono.',
    step2Title: 'Pruebe con la aplicación móvil de Authy',
    step2Text: 'La aplicación le permite generar un código a pedido en cualquier momento, siempre y cuando tenga acceso a Internet. ¡No necesita esperar un SMS ni una llamada!',
    step3Title: 'Consulte a su compañía telefónica',
    step3Text: 'Algunos operadores de telefonía celular pueden ser bastante útiles para diagnosticar el problema si lo pide. Por ejemplo, algunos clientes de Sprint en EE. UU. no saben que su plan de telefonía rechaza por defecto los SMS de códigos cortos que Authy puede usar. Puede enviar “Allow 78156” (Autorizar 78156) al 9999 para ver si su teléfono recibe los mensajes de 78156, el código corto principal de Authy en EE. UU., luego de aprobarlos. De manera similar, los usuarios de T-Mobile pueden haber dado de baja este tipo de mensajes sin saberlo, por lo que los mensajes se bloquean.',
};
