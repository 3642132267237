import { Alert } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthenticationContext } from '../../../providers/AuthenticationProvider';
import { getProducts, getSensitiveData, getStatusSensitiveData, setFormAPIOnClearAll, setUserPermissionState, updateEmployerUser, } from '../api';
import { AdminPermissionContext } from '../context/AdminPermissionContext';
import ClearAllButton from './ClearAllButton';
import Crs from './Crs';
import EmployerSubsAndLocs from './EmployerSubsAndLocs';
import Products from './Products';
import SaveUndoButton from './SaveUndoButton';
import ViewStatus from './ViewStatus';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const UnSavedData = ({ unSavedData }) => {
    return (<>
      {unSavedData ? (<Alert type={'warning'} className={'mt-3'}>
          <strong>Attention!</strong>&nbsp;You have unsaved changes.
        </Alert>) : null}
    </>);
};
const EmployerPermissions = (props) => {
    const authContext = useContext(AuthenticationContext);
    const [productsList, setProductsList] = useState([]);
    const [crsSensitiveData, setCrsSensitiveData] = useState([]);
    const [statusSensitiveData, setStatusSensitiveData] = useState([]);
    const [userInformation, setUserInformation] = useState(props.userInfo);
    const [errorMessage, setErrorMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [statusChange, setStatusChange] = useState(props.userInfo?.viewStatus ? props.userInfo.viewStatus : false);
    const [clearAll, setClearAll] = useState(false);
    const [changed, setChanged] = useState(false);
    const [unSavedData, setUnSavedData] = useState(false);
    const { trackEvent } = useTealium();
    const formApiRef = useRef();
    const setFormApi = (formApis) => {
        formApiRef.current = formApis;
    };
    useEffect(() => {
        if (props.userInfo && props.customerConfig) {
            setCrsSensitiveData(getSensitiveData(props.userInfo, props.customerConfig));
            setProductsList(getProducts(props.userInfo, props.customerConfig));
            setStatusSensitiveData(getStatusSensitiveData(props.userInfo, props.customerConfig));
        }
    }, [statusChange]);
    useEffect(() => {
        if (formApiRef.current.getValue('createMyReport')) {
            formApiRef.current.setValue('createMyReport', props.userInfo.customerReportingSolution);
        }
        if (formApiRef.current.getValue('viewCorrespondence')) {
            formApiRef.current.setValue('viewCorrespondence', props.userInfo.viewCorrespondence);
        }
        if (formApiRef.current.getValue('viewPayments')) {
            formApiRef.current.setValue('viewPayments', props.userInfo.viewPayments);
        }
        if (formApiRef.current.getValue('viewStatus')) {
            formApiRef.current.setValue('viewStatus', props.userInfo.viewStatus);
        }
        if (formApiRef.current.getValue('viewClaimNotes')) {
            formApiRef.current.setValue('viewClaimNotes', props.userInfo.viewClaimNotes);
        }
        if (formApiRef.current.getValue('viewLeaveNotes')) {
            formApiRef.current.setValue('viewLeaveNotes', props.userInfo.viewLeaveNotes);
        }
        if (formApiRef.current.getValue('viewEmployeeDetails')) {
            formApiRef.current.setValue('viewEmployeeDetails', props.userInfo.viewEmployeeDetails);
        }
        if (formApiRef.current.getValue('viewBasicAsRequest')) {
            formApiRef.current.setValue('viewBasicAsRequest', props.userInfo.viewBasicAsRequest);
        }
        if (formApiRef.current.getValue('viewFullAsRequest')) {
            formApiRef.current.setValue('viewFullAsRequest', props.userInfo.viewFullAsRequest);
        }
        if (formApiRef.current.getValue('viewAsCorrespondence')) {
            formApiRef.current.setValue('viewAsCorrespondence', props.userInfo.viewAsCorrespondence);
        }
        if (formApiRef.current.getValue('asNotes')) {
            formApiRef.current.setValue('asNotes', props.userInfo.asNotes);
        }
    }, []);
    const getProductList = () => {
        const newProductList = [];
        props.customerConfig.sharedProducts.map(product => {
            newProductList.push(product.productName);
        });
        return newProductList;
    };
    const onViewStatusOptionChange = (event) => {
        userInformation.viewStatus = event.currentTarget.checked;
        if (event.currentTarget.checked === false) {
            userInformation.viewCorrespondence = false;
            userInformation.viewPayments = false;
            userInformation.viewClaimNotes = false;
            userInformation.viewLeaveNotes = false;
            userInformation.viewEmployeeDetails = false;
            userInformation.viewBasicAsRequest = false;
            userInformation.viewFullAsRequest = false;
            userInformation.viewAsCorrespondence = false;
            userInformation.asNotes = false;
            setStatusChange(false);
        }
        else {
            setStatusChange(true);
        }
    };
    const handleChange = (formData) => {
        if (formApiRef.current.getState().touched !== undefined &&
            JSON.stringify(formApiRef.current.getState().touched) !== undefined &&
            JSON.stringify(formApiRef.current.getState().touched) !== '{}') {
            setUnSavedData(true);
        }
        else {
            setUnSavedData(false);
        }
    };
    const saveUserPermissions = async () => {
        try {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_PERMISSIONS_FORM,
                event_name: 'Save Employer Permissions',
            });
            const requestBody = {
                userId: userInformation.userId,
                products: userInformation.products,
                customerReportingSolution: userInformation.customerReportingSolution,
                sensitiveData: userInformation.sensitiveData,
                viewStatus: userInformation.viewStatus,
                viewCorrespondence: userInformation.viewCorrespondence,
                viewPayments: userInformation.viewPayments,
                viewClaimNotes: userInformation.viewClaimNotes,
                viewLeaveNotes: userInformation.viewLeaveNotes,
                viewEmployeeDetails: userInformation.viewEmployeeDetails,
                viewBasicAsRequest: userInformation.viewBasicAsRequest,
                viewFullAsRequest: userInformation.viewFullAsRequest,
                viewAsCorrespondence: userInformation.viewAsCorrespondence,
                asNotes: userInformation.asNotes,
            };
            if (clearAll) {
                requestBody.subsidiariesAndLocations = userInformation.subsidiariesAndLocations;
            }
            await updateEmployerUser(authContext.authHeader(), requestBody);
        }
        catch (error) {
            setErrorMessage('Error saving user permissions');
        }
        setClearAll(false);
        setChanged(false);
        setUnSavedData(false);
        setShowAlert(true);
    };
    const undoChanges = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_PERMISSIONS_FORM,
            event_name: 'Undo Employer Permissions',
        });
        await props.getEmployerData();
        setClearAll(false);
        setChanged(false);
        setUnSavedData(false);
    };
    const closeAlert = () => {
        setErrorMessage('');
        setShowAlert(false);
    };
    const clearAllSelection = async (context) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYER_PERMISSIONS_FORM,
            event_name: 'Clear All Employer Permissions',
        });
        if (formApiRef !== undefined && formApiRef.current !== undefined) {
            setFormAPIOnClearAll(formApiRef.current, props);
        }
        setUserPermissionState(userInformation);
        setClearAll(true);
        setUnSavedData(true);
    };
    return (<>
      <AdminPermissionContext.Consumer>
        {context => (<Form getApi={setFormApi} onChange={handleChange}>
            <UnSavedData unSavedData={unSavedData}/>
            <ClearAllButton clearAllSelection={clearAllSelection} context={context}/>
            <Products products={productsList} userInfo={userInformation} customerConfig={props.customerConfig}/>
            {userInformation && props.customerConfig && <>
              <EmployerSubsAndLocs userInfo={props.userInfo} products={getProductList()} clearAll={clearAll} subsidiariesAndLocations={userInformation.subsidiariesAndLocations}/>
                <Crs products={productsList} sensitiveData={crsSensitiveData} userInfo={userInformation} customerConfig={props.customerConfig} permissions={props.permissions}/>
                <ViewStatus onViewStatusOptionChange={onViewStatusOptionChange} statusChange={statusChange} statusSensitiveData={statusSensitiveData} userInformation={userInformation} customerConfig={props.customerConfig} permissions={props.permissions}/>
              </>}
            <SaveUndoButton saveUserPermissions={saveUserPermissions} undoChanges={undoChanges} changed={changed} closeAlert={closeAlert} errorMessage={errorMessage} showAlert={showAlert}/>
          </Form>)}
      </AdminPermissionContext.Consumer>
    </>);
};
export default EmployerPermissions;
