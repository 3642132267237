import useGet from '../../../hooks/useGet';
import checkDate from '../../../packages/status/utilities/checkDate';
import usePost from '../../../hooks/usePost';
import { BASE_URL } from '../constants/UAConstants';
import axios from 'axios';
import LogRocket from 'logrocket';
import { format } from 'date-fns';
import { formatToUtc } from 'src/utils/dateUtils';
var recordingURL;
LogRocket.getSessionURL(sessionURL => {
    recordingURL = sessionURL;
});
export var AbsenceReason;
(function (AbsenceReason) {
    AbsenceReason["FAMILY"] = "Sick Family Member";
    AbsenceReason["SELF"] = "Personal Sickness";
    AbsenceReason["BEREAV"] = "Death in the Family";
    AbsenceReason["TRANSP"] = "Transportation Problem";
    AbsenceReason["HOUSE"] = "House Maintenance Problem";
    AbsenceReason["CIVIC"] = "Civic Duty";
    AbsenceReason["OTHER"] = "Other";
    AbsenceReason["NA"] = "N/A";
})(AbsenceReason || (AbsenceReason = {}));
export var AbsenceType;
(function (AbsenceType) {
    AbsenceType["OUT"] = "Absent for Full Shift";
    AbsenceType["EARLYNORTN"] = "Leaving Early (Not Returning)";
    AbsenceType["EARLYRTN"] = "Leaving Early (Returning)";
    AbsenceType["LATE"] = "Arriving Late";
    AbsenceType["NA"] = "N/A";
})(AbsenceType || (AbsenceType = {}));
export const GetInitialAbsences = () => {
    return useGet({
        url: `/ua-service/absences/getrecent`,
        options: {
            headers: {
                Accept: 'application/hal+json',
            },
        },
    });
};
export const GetAssociatedLeaves = () => {
    return useGet({
        url: `${BASE_URL}/leaves/search`,
        noInitial: true,
        options: {
            headers: {
                Accept: 'application/hal+json',
            },
        },
    });
};
export const SubmitUnplannedAbsence = (onSuccess, onFailure) => {
    return usePost({
        url: `${BASE_URL}/submit`,
        success: onSuccess,
        failure: onFailure,
    });
};
export const getAbsenceType = (type) => {
    if (type === 'EARLYNORTN') {
        return AbsenceType.EARLYNORTN;
    }
    if (type === 'EARLYRTN') {
        return AbsenceType.EARLYRTN;
    }
    if (type === 'LATE') {
        return AbsenceType.LATE;
    }
    if (type === 'OUT') {
        return AbsenceType.OUT;
    }
};
export const getAbsenceReason = (reason) => {
    switch (reason) {
        case 'FAMILY':
            return AbsenceReason.FAMILY;
        case 'SELF':
            return AbsenceReason.SELF;
        case 'BEREAV':
            return AbsenceReason.BEREAV;
        case 'TRANSP':
            return AbsenceReason.TRANSP;
        case 'HOUSE':
            return AbsenceReason.HOUSE;
        case 'CIVIC':
            return AbsenceReason.CIVIC;
        case 'OTHER':
            return AbsenceReason.OTHER;
    }
};
// format(parseISO(date), new Date(date).getTimezoneOffset()), 'MM/dd/yyyy')
export const formatDate = (date) => {
    if (date) {
        const formattedDate = checkDate(formatToUtc(date, 'MM/dd/yyyy'));
        if (formattedDate !== '') {
            return formattedDate;
        }
    }
    return 'N/A';
};
export const formatDuration = (duration) => {
    if (duration && duration !== '00:00') {
        return duration;
    }
    return 'N/A';
};
//TODO apparently this needs to take in a number for UA functionality
export const formatTime = (time) => {
    if (time && formatToUtc(time, 'P') === '01/01/1900') {
        return 'N/A';
    }
    if (time || time === 0) {
        if (time === 0) {
            return formatToUtc(time, 'p');
        }
        if (format(new Date(time), 'MM/dd/yyyy') !== '') {
            return formatToUtc(time, 'p');
        }
    }
    return 'N/A';
};
export const downloadUnplannedAbsence = (absence, employeeName, authHeader) => {
    const payload = {
        employeeName,
        absence: {
            date: formatDate(absence.uaStartDate),
            startTime: formatTime(absence.uaStartTime),
            endTime: formatTime(absence.uaEndTime),
            duration: formatDuration(absence.uaDuration),
            type: getAbsenceType(absence.absType),
            reason: getAbsenceReason(absence.absReason),
            relatedLeave: absence.associatedLeave?.trim() || 'N/A',
            submissionDate: `${formatDate(absence.uaSubmitDate)},  ${formatTime(absence.uaSubmitTime)}`,
            transactionID: absence.transNum,
        },
    };
    return axios.post(`/ua-service/absence/download`, payload, {
        responseType: 'blob',
        headers: { authorization: authHeader, 'X-LogRocket-URL': recordingURL },
    });
};
