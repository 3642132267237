export const ConfirmationText = {
    evidenceOfConfirmation: {
        english: 'Evidence of insurability confirmation',
        spanish: 'Evidencia de confirmación de asegurabilidad',
    },
    evidenceOfSubmission: {
        english: 'Evidence of insurability submission',
        spanish: 'Evidencia de presentación de asegurabilidad',
    },
    success: {
        english: 'You have successfully submitted your information.',
        spanish: 'Ha enviado correctamente su información.',
    },
    yourEventIdIs: {
        english: 'Your evidence of insurability event number is ',
        spanish: 'Su número de evento de evidencia de asegurabilidad es ',
    },
    fullyApproved: {
        english: 'The application you submitted on DATE at TIME has been APPROVED.',
        spanish: 'La solicitud que envió el DATE a las TIME se ha APROBADO.',
    },
    partiallyApproved: {
        english: 'The application you submitted for yourself on DATE at TIME has been APPROVED. We are currently reviewing the application you submitted on behalf of your dependent(s).',
        spanish: 'La solicitud que envió para sí mismo el DATE a las TIME se ha APROBADO. En este momento estamos revisando la solicitud que envió a nombre de su(s) dependiente(s).',
    },
    otherApproval: {
        english: 'You successfully submitted your application on DATE at TIME.',
        spanish: 'Envió su solicitud con éxito el DATE a las TIME.',
    },
    unknownApproval: {
        english: 'You submitted your application on DATE at TIME.',
        spanish: 'Envió su solicitud el DATE a las TIME.',
    },
    yourNextSteps: {
        english: 'Your next steps',
        spanish: 'Tus próximos pasos',
    },
    pleaseTakeAMoment: {
        english: 'Please take a moment to print or save a copy of your application for your records.',
        spanish: 'Tómese unos minutos para imprimir o guardar una copia de su solicitud para sus registros.',
    },
    eoiStatus: {
        english: 'To view your Evidence of Insurability status log into My Lincoln Portal and navigate to \'Requests & status\' > \'Evidence of insurability\'. Your status will be available for viewing 24 hours after your submission.',
        spanish: 'Para ver el estado de su Evidencia de asegurabilidad, inicie sesión en My Lincoln Portal y vaya a \'Solicitudes y estado\' > \'Evidencia de asegurabilidad\'. Su estado estará disponible para su visualización 24 horas después de su envío.',
    },
    ourNextSteps: {
        english: 'Our next steps',
        spanish: 'Nuestros próximos pasos',
    },
    notifyEmployer: {
        english: 'We will notify your employer that your application has been approved.',
        spanish: 'Informaremos a su empleador que se ha aprobado su solicitud.',
    },
    mailALetter: {
        english: 'We will mail a letter to you regarding your coverage effective date.',
        spanish: 'Mediante correo postal le enviaremos una carta para informarle la fecha en que comienza la vigencia de la cobertura.',
    },
    review: {
        english: 'We will review your application and request additional information if needed.',
        spanish: 'Revisaremos su solicitud y le solicitaremos información adicional, de ser necesario.',
    },
    reviewDependents: {
        english: 'We will review the application submitted on behalf of your dependent(s) and request additional information if needed.',
        spanish: 'Revisaremos la solicitud enviada a nombre de su(s) dependiente(s) y solicitaremos información adicional, de ser necesario.',
    },
    necessaryInformation: {
        english: 'Once we receive all necessary information, we will evaluate your application and notify you and your employer of our decision.',
        spanish: 'Una vez que recibamos toda la información necesaria, evaluaremos su solicitud y le notificaremos a usted y a su empleador acerca de nuestra decisión.',
    },
    necessaryInformationDependents: {
        english: 'Once we receive all necessary information, we will evaluate their application and notify you and your employer of our decision.',
        spanish: 'Una vez que recibamos toda la información necesaria, evaluaremos la solicitud del(de los) dependiente(s) y le notificaremos a usted y a su empleador acerca de nuestra decisión.',
    },
    approvedConfirmationMessage: {
        english: 'Approved insurance coverage will be administered in accordance with your employer\'s benefit plan.',
        spanish: 'La cobertura del seguro aprobada se administrará de acuerdo con el plan de beneficios de su empleador.',
    },
    otherConfirmationMessage: {
        english: 'If the requested insurance coverage is approved, it will be administered in accordance with your employer\'s benefit plan.',
        spanish: 'Si se aprueba la cobertura del seguro solicitada, se administrará de acuerdo con el plan de beneficios de su empleador.',
    },
    viewAndPrint: {
        english: 'View & Print My Application',
        spanish: 'Ver e imprimir mi solicitud',
    },
    downloadError: {
        english: 'There was an error downloading your confirmation report.',
        spanish: 'Hubo un error al descargar su informe de confirmación',
    },
    unknownSubmissionStatusHeader: {
        english: 'Your application is pending. We are currently experiencing a high volume of submissions.',
        spanish: 'Tu solicitud está pendiente. Actualmente estamos experimentando un gran volumen de envíos.',
    },
    unknownSubmissionStatusMessage: {
        english: 'Please allow time for us to process your application information. You should see your application status in your account within one business day of submitting your information (navigate to \'Requests & status\' > \'Evidence of insurability\'). Thank you for your patience.',
        spanish: 'Deje tiempo para que procesemos la información de su solicitud. Debería ver el estado de su solicitud en su cuenta dentro de un día hábil después de enviar su información (navegue a \'Solicitudes y estado\'> \'Evidencia de asegurabilidad\'). Gracias por su paciencia.',
    }
};
