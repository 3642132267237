import React, { useState, useContext } from 'react';
import { Row, Col, TextInput, SelectInput } from '@digitools/honest-react';
import { BDM_COUNTRIES, BDM_STATES, BDM_PROVINCES, BDM_COUNTRIES_ES } from '../constants/BdmConstants';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { numbersOnly, validateSSN, formatCity, isNotEmpty, validateAddress1, validateAddress2, validateCity, } from '../../../utils/validationUtils';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import useExperience from '../../../hooks/useExperience';
import { useFormApi } from 'informed';
const BdmModalContentSharedFields = ({ beneficiary, beneficiaryCategory, beneficiaryIndex, }) => {
    const isLci = useIsLci();
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const { language } = useExperience();
    const formApi = useFormApi();
    const [countrySelected, setCountrySelected] = useState(beneficiary ? beneficiary.country : 'USA');
    const validateSSNOrTaxId = (value, errorMessage) => validateSSN(value, 'taxId');
    const handleCountrySelection = async (value) => {
        const countryChanged = value !== countrySelected;
        setCountrySelected(value);
        if (countryChanged && value !== 'OTHERCOUNTRIES') {
            resetPostalCode();
        }
    };
    const resetPostalCode = () => {
        const postalCode = formApi.getValue(`${idPrefix}postalCode`);
        if (!!postalCode) {
            formApi.setValue(`${idPrefix}postalCode`, '');
        }
    };
    return (<>
      <Row>
        <Col sm={'12'} lg={'4'}>
          <TextInput ariaLabelText={'Social Security Number or tax ID'} field={`${idPrefix}ssnOrTaxID`} label={displayText.ssnOrTaxID} type={'textmask'} autoComplete={'new-password'} maxLength={9} mask={numbersOnly} validateOnBlur={true} validate={validateSSNOrTaxId} initialValue={beneficiary ? beneficiary.ssnOrTaxID : undefined} data-private={true}/>
        </Col>
        <Col sm={'0'} lg={'4'}/>
        <Col sm={'0'} lg={'4'}/>
      </Row>
      <Row>
        <Col>
          <TextInput ariaLabelText={'Street Address 1'} field={`${idPrefix}address1`} label={isLci ? displayText.address1LCI : displayText.address1} type={'text'} validateOnBlur={true} validate={isLci ? undefined : validateAddress1} initialValue={beneficiary ? beneficiary.address1 : undefined}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInput ariaLabelText={'Street Address 2'} field={`${idPrefix}address2`} label={displayText.address2} type={'text'} validateOnBlur={true} validate={validateAddress2} initialValue={beneficiary ? beneficiary.address2 : undefined}/>
        </Col>
      </Row>
      <Row>
        <Col lg={'3'} md={'6'} sm={'6'}>
          <SelectInput ariaLabelText={'Country'} field={`${idPrefix}country`} label={isLci ? displayText.countryLCI : displayText.country} validateOnBlur={true} validate={isLci ? undefined : isNotEmpty} options={language === Language.SPANISH ? BDM_COUNTRIES_ES : BDM_COUNTRIES} initialValue={countrySelected} onValueChange={handleCountrySelection}/>
        </Col>
        <Col lg={'3'} md={'6'} sm={'6'}>
          {countrySelected === 'USA' && (<SelectInput ariaLabelText={'State'} field={`${idPrefix}state`} label={isLci ? displayText.stateLCI : displayText.state} placeholderText={isLci ? displayText.stateLCI : displayText.state} validateOnBlur={true} validate={isLci ? undefined : isNotEmpty} options={BDM_STATES} initialValue={beneficiary ? beneficiary.state : undefined}/>)}
          {countrySelected === 'CANADA' && (<SelectInput ariaLabelText={'Province'} field={`${idPrefix}province`} label={isLci ? displayText.provinceLCI : displayText.province} placeholderText={isLci ? displayText.provinceLCI : displayText.province} validateOnBlur={true} validate={isLci ? undefined : isNotEmpty} options={BDM_PROVINCES} initialValue={beneficiary ? (beneficiary.province ? beneficiary.province : beneficiary.state) : undefined}/>)}
          {countrySelected === 'OTHERCOUNTRIES' && (<TextInput ariaLabelText={'Other Address'} field={`${idPrefix}otherAddress`} label={displayText.otherAddress} textarea={true} resize={'vertical'} mask={BdmBeneficiaryUtils.formatOtherAddress} maxLength={255} rows={1} validateOnBlur={true} validate={isLci ? undefined : isNotEmpty} initialValue={beneficiary ? beneficiary.otherAddress : undefined}/>)}
        </Col>
        <Col lg={'3'} md={'6'} sm={'6'}>
          <TextInput ariaLabelText={'City'} field={`${idPrefix}city`} label={isLci ? displayText.cityLCI : displayText.city} type={'text'} mask={formatCity} validateOnChange={true} validateOnBlur={true} validate={isLci ? undefined : validateCity} initialValue={beneficiary ? beneficiary.city : undefined}/>
        </Col>
        <Col lg={'3'} md={'6'} sm={'6'}>
          {countrySelected !== 'OTHERCOUNTRIES' && (<TextInput ariaLabelText={'Postal Code'} field={`${idPrefix}postalCode`} label={isLci ? displayText.postalCodeLCI : displayText.postalCode} type={'text'} validateOnChange={true} validateOnBlur={true} mask={countrySelected === 'USA'
                ? BdmBeneficiaryUtils.formatBdmUSAPostalCodes
                : BdmBeneficiaryUtils.formatBdmCANPostalCodes} validate={isLci
                ? undefined
                : countrySelected === 'USA'
                    ? BdmBeneficiaryUtils.validateBdmUSAPostalCodes
                    : BdmBeneficiaryUtils.validateBdmCANPostalCodes} maxLength={countrySelected === 'USA' ? 10 : 7} initialValue={beneficiary ? beneficiary.postalCode : undefined}/>)}
        </Col>
      </Row>
    </>);
};
export default BdmModalContentSharedFields;
