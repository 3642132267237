import { Col, Row } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { VanityPageText } from '../constants/VanityPageText';
import useStepperContext from './useStepperContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { StyledBox, StyledHeading, StepperButton, BackButton } from './StyledComponents';
const VanityPageQuestions1 = () => {
    const { t } = useTranslate();
    const { next, back, goToStep, setIsLfg, setIsMigrated } = useStepperContext();
    const { trackEvent, trackView } = useTealium();
    const handleOnClickNo = () => {
        setIsLfg(true);
        setIsMigrated(false);
        goToStep(5);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.noBenefits.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickUnknown = () => {
        setIsLfg(true);
        setIsMigrated(false);
        goToStep(5);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.unknown.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickYes = () => {
        next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: VanityPageText.yesBenefits.english,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get('from');
    return (<div>
      <StyledBox>
        <Row>
          <Col>
            <StyledHeading elemType='h2'>
              {t(VanityPageText.alreadyRegistered)}
            </StyledHeading>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} className='mx-auto'>
            <StepperButton className='d-block w-100 mb-2' buttonType='primary' data-testid='stepper-next' onClick={handleOnClickYes}>
              {t(VanityPageText.yesBenefits)}
            </StepperButton>
            <StepperButton className='d-block w-100 ml-0 mb-2' buttonType='primary' data-testid='noBenefits' onClick={handleOnClickNo}>
              {t(VanityPageText.noBenefits)}
            </StepperButton>
            <StepperButton onClick={handleOnClickUnknown} className='d-block w-100 ml-0' data-testid='unknown' buttonType='primary'>
              {t(VanityPageText.unknown)}
            </StepperButton>
          </Col>
        </Row>
      </StyledBox>
      {from !== ('ee-login-help') && <BackButton onClick={() => back()}/>}
    </div>);
};
export default VanityPageQuestions1;
