import useGet from '../../../hooks/useGet';
import axios from 'axios';
import saveAs from 'file-saver';
export const GetInitialInformation = () => {
    return useGet({ url: `/eoi-service/questions/initial-information` });
};
export const GetCustomContent = () => {
    return useGet({ url: `/eoi-service/config/custom-content` });
};
export const GetCustomerConfig = () => {
    return useGet({ url: '/eoi-service/config' });
};
export const GetLegalText = () => {
    return useGet({ url: `/eoi-service/questions/legal-conditions` });
};
export const GetPrePopData = async (data, authHeader) => {
    const response = await axios.post(`/eoi-service/prepop`, data, {
        headers: { Authorization: authHeader },
    });
    return response.data;
};
export const GetInterviewData = () => {
    return useGet({ url: `/eoi-service/save-for-later` });
};
export const SaveInterviewData = async (interviewData, authHeader, language) => {
    await axios.post(`/eoi-service/save-for-later?lang=${language}`, interviewData, {
        headers: { Authorization: authHeader },
    });
};
export const DeleteInterviewData = async (authHeader) => {
    await axios.delete(`/eoi-service/save-for-later`, { headers: { Authorization: authHeader } });
};
export const downloadEsignAuthForm = async (authHeader, language, fileName, errorMessage, setDownloadError, situsState) => {
    try {
        const response = await axios.get(`/eoi-service/document/esign-auth?lang=${language}&situsState=${situsState}`, {
            responseType: 'blob',
            headers: { Authorization: authHeader },
        });
        const filename = `${fileName}.pdf`;
        const blob = new Blob([response.data], { type: response.headers['content-disposition'] });
        saveAs(blob, filename);
        setDownloadError(undefined);
    }
    catch (error) {
        setDownloadError(errorMessage);
    }
};
export const downloadNoticeOfInformationPractices = async (authHeader, language, fileName, errorMessage, setDownloadError, situsState) => {
    try {
        const response = await axios.get(`/eoi-service/document/notice-of-information-practices?lang=${language}&situsState=${situsState}`, {
            responseType: 'blob',
            headers: { Authorization: authHeader },
        });
        const filename = `${fileName}.pdf`;
        const blob = new Blob([response.data], { type: response.headers['content-disposition'] });
        // @-ts-ignore
        saveAs(blob, filename);
        setDownloadError(undefined);
    }
    catch (error) {
        setDownloadError(errorMessage);
    }
};
// TODO: language will eventually be used when we have to support a spanish version of the PDF.
export const downloadConfirmationPdf = async (authHeader, language, submission, applicationId, setDownloadError, errorMessage) => {
    try {
        const response = await axios.post(`/eoi-service/document/confirmation?lang=${language}`, submission, {
            responseType: 'blob',
            headers: { Authorization: authHeader },
        });
        const filename = `${applicationId}.pdf`;
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // @ts-ignore
        saveAs(blob, filename);
    }
    catch (error) {
        setDownloadError(errorMessage);
    }
};
export const requestReflexiveQuestions = (medicalInformationAnswers, authHeader) => {
    return axios.post(`/eoi-service/questions/reflexive-questions`, medicalInformationAnswers, {
        headers: { Authorization: authHeader },
    });
};
export const SubmitApplication = (submission, authHeader, language) => {
    return axios.post(`/eoi-service/questions/submit?lang=${language}`, submission, {
        headers: { Authorization: authHeader },
    });
};
export const CheckDuplicateApplication = (duplicateApplicationResource, authHeader) => {
    return axios.post('/eoi-service/prepop/duplicate-application', duplicateApplicationResource, {
        headers: { Authorization: authHeader },
    });
};
