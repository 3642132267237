import { Button, Col, Form, Heading, Row, StepperContext, } from '@digitools/honest-react';
import React, { useContext } from 'react';
import Address from '../address/Address';
import CancelLci from '../cancelLCI/CancelLci';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import ReviewField from 'src/packages/cli/pages/review/ReviewField';
import { StyledDiv } from 'src/components/Formatting/StyledComponents';
const EmployerInfo = ({ onComplete, employerName, policyNumber, goBack }) => {
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    function handleContinue(values) {
        const employerInfo = {
            employerName,
            policyNumber,
            streetAddress1: values.employerInfo_street1,
            streetAddress2: values.employerInfo_street2,
            city: values.employerInfo_city,
            postalcode: values.employerInfo_postalcode,
            state: values.employerInfo_state,
            province: values.employerInfo_province,
            country: values.employerInfo_country,
        };
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'employer info',
            event_status: 'step 2'
        });
        onComplete(employerInfo);
        stepper.next();
    }
    const handleBack = () => {
        goBack();
        stepper.back();
    };
    return (<Form onSubmit={handleContinue}>
      <Heading elemType='h2' color='primary' style={{ marginBottom: '2rem' }}>
        Employer Info
      </Heading>
      <Row className='mb-3'>
        <Col md={6} xs={12}>
          <ReviewField data-private={true} data-testid='employerName' label={'Employer Name'} value={employerName}/>
        </Col>
        <Col md={6} xs={12}>
          <ReviewField data-private={true} data-testid='policyNumber' label={'Employer Policy Number'} value={policyNumber}/>
        </Col>
      </Row>
      <Address field='employerInfo' title='Employer Address' required={true}/>
      <StyledDiv>
        <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='employerInfo_backbutton' type='button' onClick={handleBack}>
          Back
        </Button>
        <CancelLci step={'step 2'} pageTitle={'employer info'} sectionInfo={'Employer info'}/>
        <Button aria-label={'Continue'} buttonType='primary' role='button' id='contintue_employer_info' type='submit'>
          Continue
        </Button>
      </StyledDiv>
    </Form>);
};
export default EmployerInfo;
