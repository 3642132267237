import { createContext } from "react";
export const ProveDataContext = createContext({
    proveFieldsRequired: undefined,
    verificationData: undefined,
    trustScore: undefined,
    isVerified: false,
    isProveSuccess: false,
    isUserLockedOut: false,
    setVerificationData: () => { },
    setTrustScore: () => { },
    setIsProveSuccess: () => { },
});
export const getMockProveDataContext = (overrides = {}) => {
    return {
        proveFieldsRequired: undefined,
        verificationData: undefined,
        trustScore: undefined,
        isVerified: false,
        isProveSuccess: false,
        isUserLockedOut: false,
        setVerificationData: jest.fn(),
        setTrustScore: jest.fn(),
        setIsProveSuccess: jest.fn(),
        ...overrides,
    };
};
