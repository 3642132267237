import React, { useContext } from 'react';
import styled from 'styled-components';
import { ExperienceContext } from '../../../../providers/ExperienceProvider';
import { MultiCustomerDisplayTextEn } from './i18n/MultiCustomerDisplayTextEn';
import { MultiCustomerDisplayTextEs } from './i18n/MultiCustomerDisplayTextEs';
import getDisplayText from '../../../../utils/languageDisplay';
const StyledUninitializedMessage = styled('div') `
  mt-2;
`;
const UnassociatedMessage = () => {
    const { language } = useContext(ExperienceContext);
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, MultiCustomerDisplayTextEn, MultiCustomerDisplayTextEs, language]);
    return (<StyledUninitializedMessage data-public={true} id={'nocustomer-message'} data-testid={'unitialized'}>
      {getLocalDisplayText('unassociatedMessage')}
    </StyledUninitializedMessage>);
};
export default UnassociatedMessage;
