import React from 'react';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import { IMfaDisplayTextEs } from './i18n/IMfaDisplayTextEs';
import { IMfaDisplayTextEn } from './i18n/IMfaDisplayTextEn';
import getDisplayText from '../../utils/languageDisplay';
import MfaHelpTextModal from './modal/MfaHelpTextModal';
import { IMfaOption } from './MfaPreference';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
class MfaPhoneConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: undefined,
            rememberDevice: false,
            resendCodeLinkDisabled: false,
            submitButtonDisabled: false,
        };
    }
    componentDidMount() {
        this.props.trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MFA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.MFA.CONFIRMATION.VIEW,
        });
    }
    validateOtp = (value) => {
        if (value && value.length === 6 && /^\d+$/.test(value)) {
            this.setState({
                otp: value,
            });
        }
    };
    submitOtp = async () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
            event_name: 'mfa Submit Otp',
        });
        this.setState({
            submitButtonDisabled: true,
        });
        setTimeout(() => {
            this.setState({
                submitButtonDisabled: false,
            });
        }, 5000);
        await this.props.submitOtp(this.state.otp, this.state.rememberDevice);
    };
    sendOtp = async () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
            event_name: 'mfa Resend Code',
        });
        this.setState({
            resendCodeLinkDisabled: true,
        });
        setTimeout(() => {
            this.setState({
                resendCodeLinkDisabled: false,
            });
        }, 5000);
        await this.props.sendOtp();
    };
    rememberMyDevice = async (value) => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
            event_name: 'mfa Remember My Device Checkbox',
        });
        await this.setState({
            rememberDevice: value,
        });
    };
    showMfaHelpTextModal = () => { };
    render() {
        const buttonDisabled = this.state.otp ? false : true;
        const thankYouText = getDisplayText('thankYou', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const phoneConfirmationText = getDisplayText('phoneConfirmationText', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const securityCodeText = getDisplayText('securityCode', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const submitButtonText = getDisplayText('submit', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const tryAnotherMethodText = getDisplayText('tryAnotherMethod', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const didntReceiveCodeText = getDisplayText('didntReceiveCode', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const havingTroubleText = getDisplayText('havingTrouble', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const sendCodeText = getDisplayText('sendCode', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const rememberMyDevice = getDisplayText('rememberMyDevice', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const resendCodeLink = this.state.resendCodeLinkDisabled ? (<></>) : (<Button buttonType={'text'} onClick={this.sendOtp} className={'p-0'} data-testid='send-code-btn'>
        {sendCodeText}
      </Button>);
        return (<>
        <div data-public={true} data-testid='mfa-phone-confirmation'>
          <Row className={'ml-1 mr-1 mb-0 mt-0'}>
            <Heading data-testid='mfa-phone-header'>{securityCodeText}</Heading>
          </Row>
          <Row className={'ml-1 mr-1 mb-0'}>
            <p data-testid='phone-confirm-text'>
              {this.props.mfaPreference === IMfaOption.AUTHY
                ? getDisplayText('authyConfirmationText', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)
                : phoneConfirmationText.replace('<>', this.props.phoneNumber)}
            </p>
          </Row>
          <Row className={'ml-1 mr-1 mt-0'} style={{ width: '10rem' }}>
            <TextInput data-testid='otp-input' hideLabel={true} field={'securityCode'} label={'Enter Verification Code here'} validateOnChange={true} validate={this.validateOtp} maxLength={6} style={{ fontSize: '32px', textAlign: 'center' }} 
        // autocomplete={'one-time-code'}
        inputMode={'numeric'}/>
          </Row>
          <Row className={'ml-1 mr-1'}>
            <Checkbox field={'saveMyDevice'} label={rememberMyDevice} onValueChange={this.rememberMyDevice} style={{ margin: 'auto' }}/>
          </Row>
          <Row className={'ml-1 mr-1'}>
          {!buttonDisabled && (<Button data-testid='submit-otp-btn' buttonType={'primary'} disabled={buttonDisabled || this.state.submitButtonDisabled} onClick={this.submitOtp} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              {submitButtonText}
            </Button>)}
          </Row>
          <Row className={'ml-1 mr-1 mb-1'}>
            <Heading>{this.props.mfaPreference !== IMfaOption.AUTHY ? didntReceiveCodeText : havingTroubleText}</Heading>
          </Row>
          {this.props.mfaPreference !== IMfaOption.AUTHY && <Row className={'ml-1 mr-1 mt-0 mb-0'}>{resendCodeLink}</Row>}
          <Row className={'ml-1 mr-1 mt-0 mb-0'}>
            <Button buttonType={'text'} onClick={this.props.restartMfa} className={'p-0'} data-testid='another-method-btn'>
              {tryAnotherMethodText}
            </Button>
          </Row>
          <Row className={'ml-1 mr-1 mt-0 mb-0'}>
            <MfaHelpTextModal language={this.props.language}/>
          </Row>
        </div>
      </>);
    }
}
export default MfaPhoneConfirmation;
