export const AdditionalInfoText = {
    pageTitle: {
        english: 'Employment details (optional)',
        spanish: 'Employment details (optional)'
    },
    dateOfHireLabel: {
        english: 'Date of hire',
        spanish: 'Date of hire'
    },
    employeeIDLabel: {
        english: 'Employee ID',
        spanish: 'Employee ID'
    },
    workStateLabel: {
        english: 'Work state',
        spanish: 'Work state'
    },
    subsidaryLabel: {
        english: 'Subsidary',
        spanish: 'Subsidary'
    },
    locationBranchLabel: {
        english: 'Location branch',
        spanish: 'Location branch'
    },
    employeeTypeLabel: {
        english: 'Employee type',
        spanish: 'Employee type'
    },
    jobReqsLabel: {
        english: 'Job requirements description',
        spanish: 'Job requirements description'
    },
    physicalDemandsLabel: {
        english: 'Physical demands',
        spanish: 'Physical demands'
    },
    payTypeLabel: {
        english: 'Pay type',
        spanish: 'Pay type'
    },
    earningsLabel: {
        english: 'Earnings',
        spanish: 'Earnings'
    },
    incomeClassLabel: {
        english: 'Income class',
        spanish: 'Income class'
    },
    benefitPercentLabel: {
        english: 'Benefit percent',
        spanish: 'Benefit percent'
    },
    EEPremConLabel: {
        english: 'Employee premium contribution',
        spanish: 'Employee premium contribution'
    },
    ERPremConLabel: {
        english: 'Employer premium contribution',
        spanish: 'Employer premium contribution'
    },
    DaysPerWeek: {
        english: 'Days per week',
        spanish: 'Days per week'
    },
    HoursPerDay: {
        english: 'Hours per day',
        spanish: 'Hours per day'
    },
    WorkStatus: {
        english: 'Work status',
        spanish: 'Work status'
    },
    ScheduledWorkDays: {
        english: 'Scheduled work days',
        spanish: 'Scheduled work days'
    },
};
