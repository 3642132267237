import axios from 'axios';
export const updateUserProfile = (authHeader, userId, requestBody) => {
    requestBody.userId = userId;
    return axios.put(encodeURI(`/auth-service/services/v1/profile/updateUserProfile`), requestBody, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
};
export const updateUserLastLogin = (authHeader, userId, requestBody) => {
    requestBody.userId = userId;
    return axios.patch(encodeURI(`/auth-service/services/v1/profile/updateLastLogin`), requestBody, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
};
export function updateUserTermsAndConditions(termsAndConditions, authHeader) {
    return axios.patch(`/auth-service/services/v1/profile/updateTermsAndConditions`, termsAndConditions, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
}
