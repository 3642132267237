import { Table, TableBody, TableData, TableRow } from '@digitools/honest-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckDetails from './CheckDetails';
import OffsetDetails from './OffsetDetails';
import TaxDetails from './TaxDetails';
import WagesDetails from './WagesDetails';

const StyledTableData = styled(TableData)`
  border-right: 1px solid gray;
  padding: 1rem;
  vertical-align: baseline;
`;

const PaymentListDetails = ({ claimPayment }) => {
  const [activeTab, setActiveTab] = useState('offset');

  let sideWidth = '33%';
  let centralWidth = '33%';
  if (activeTab === 'offset' || activeTab === 'other') {
    centralWidth = '50%';
    sideWidth = '25%';
  }

  return (
    <Table style={{ width: '100%' }}>
      <TableBody>
        <TableRow isHoverable={false} isFilled={true}>
          <StyledTableData style={{ width: sideWidth }}>
            <CheckDetails
              data-testid={'checkDetails'}
              claimPayment={claimPayment}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </StyledTableData>

          <StyledTableData style={{ width: centralWidth }}>
            {activeTab === 'offset' && <OffsetDetails type='offset' claimPayment={claimPayment} />}
            {activeTab === 'taxes' && <TaxDetails claimPayment={claimPayment} />}
            {activeTab === 'other' && <OffsetDetails type='other' claimPayment={claimPayment} />}
          </StyledTableData>

          <TableData style={{ width: sideWidth }}>
            {claimPayment && <WagesDetails data-testid={'wagesDetails'} claimPayment={claimPayment} />}
          </TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default PaymentListDetails;
