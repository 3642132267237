export const AboutYouText = {
    aboutClaimantEE: {
        english: 'About you',
        spanish: 'Sobre usted',
    },
    aboutClaimantER: {
        english: 'About your Employee',
        spanish: 'Información de su empleado',
    },
    pageSubTitle1EE: {
        english: 'For your convenience we have populated the information we have on file for you. ',
        spanish: 'Para su comodidad, hemos completado la información que tenemos archivada para usted. ',
    },
    pageSubTitle1ER: {
        english: 'For your convenience we have populated the information we have on file for your Employee. ',
        spanish: 'Para su comodidad, hemos completado la información que tenemos archivada para usted. ',
    },
    pageSubTitle2: {
        english: 'Please confirm that the information below is correct.',
        spanish: 'Por favor, confirme que la información a continuación es correcta.',
    },
    nameError: {
        english: "Allowed characters are: A-Z a-z space . - ' with no trailing spaces",
        spanish: "Los caracteres permitidos son: A-Z a-z. - ' sin espacios finales",
    }
};
