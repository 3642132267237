import { Row, Col, Icon, Alert, Spinner, themes } from "@digitools/honest-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TEALIUM_EVENT } from "../../../../../constants/tealiumConstants";
import useTealium from "../../../../../hooks/useTealium";
import ReviewField from "../../../../cli/pages/review/ReviewField";
import checkDate from "../../../utilities/checkDate";
import titleCase from "../../../utilities/titleCase";
import { Link } from "react-router-dom";
import useExperience from '../../../hooks/useExperience';
import { ExperienceCode } from '../../../../../types/Experience';
import formatClassDescription from '../../../utilities/formatClassDescription';
import getDisabilityCause from '../../../utilities/getDisabilityCause';
import getDiagnosticCategory from '../../../utilities/getDiagnosticCategory';
import styled from "styled-components";
import Card from "src/components/Cards/Card";
import StatusBadge, { getStatusColor } from "src/components/Cards/StatusBadge";
import WatchlistCheckBox from "../../WatchlistCheckBox/WatchlistCheckBox";
import CategorizationDocumentUpload from "src/components/documentUpload/CategorizationDocumentUpload";
import DocumentUpload from "src/components/documentUpload/DocumentUpload";
import { getEnvVariable } from "src/utils/envUtils";
import useAuthentication from "src/hooks/useAuthentication";
import axios from "axios";
import FileSaver from "file-saver";
import { StyledButton } from "src/components/Formatting/StyledComponents";
const StatusMessage = styled('div') `
    font-size: .9rem;
    margin-left: .5rem;
    margin-right: .5rem;
`;
const StyledCol = styled(Col) `
    @media (max-width: 480px){
        display: flex;
        justify-content: space-around;
        flex-direction: column-reverse;
    }
`;
const ClaimInfoCard = ({ claim, associatedLeave, productAttribute, statusDef, claimId, empNum, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useTealium();
    const { experience } = useExperience();
    const [isDownloadError, setIsDownloadError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { authHeader, user } = useAuthentication();
    const downloadClaimDetails = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
            event_name: 'download claim details',
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_version: 'details'
        });
        try {
            setIsDownloading(true);
            const response = await axios.get(`/status-service/report/${empNum}/${claimId}/claimSummary`, {
                responseType: 'blob',
                headers: { Authorization: authHeader() },
            });
            const reportName = `${claimId}-ClaimDetails.pdf`;
            const blob = new Blob([response.data]);
            setIsDownloadError(false);
            setIsDownloading(false);
            FileSaver.saveAs(blob, reportName);
        }
        catch (error) {
            setIsDownloadError(true);
            setIsDownloading(false);
        }
    };
    const DownloadClaimSummaryError = () => (<Alert data-testid={'download-claim-details-alert'} type={'error'} closeable={true} onClose={() => setIsDownloadError(false)}>
            <span>
                <strong>Error!</strong> There was a problem downloading the claim details. Please try again later.
            </span>
        </Alert>);
    const category = (claimDiagnosticCategory) => {
        const diagnosticCategory = getDiagnosticCategory(claimDiagnosticCategory);
        if (claimDiagnosticCategory === '35') {
            return diagnosticCategory?.slice(0, 17) + " " + diagnosticCategory?.slice(17);
        }
        else {
            return diagnosticCategory;
        }
    };
    return (<div data-testid={'claimInfoCard'} className="mb-2">
            <Card heading={`${t('claimDetailsText')} #${claim.clmNum}`} secondaryHeading={<StatusBadge status={productAttribute}>{productAttribute}</StatusBadge>} selectable={false} fontSize='large' elemType='h2' borderColor={getStatusColor(productAttribute)}>
                {statusDef &&
            <Row className="mb-2">
                        <StatusMessage>
                            <Icon size='sm' name='important-message' color='secondary' className='ml-2 mr-2'/>
                                {statusDef}
                        </StatusMessage>
                    </Row>}
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('statusText').toString())} value={productAttribute}/>
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('coverageText').toString())} value={claim.prodType}/>
                    {experience === ExperienceCode.EMPLOYER && claim.classCde &&
            <ReviewField style={{ flexGrow: '1', maxWidth: '33%' }} small label={titleCase(t('benefitClassText').toString())} value={claim.classCde.trim()}/>}
                    {claim.clmCloseDte &&
            <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('closedDateText').toString())} value={checkDate(claim.clmCloseDte)}/>}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('disabilityCauseText').toString())} value={getDisabilityCause(claim.clmDisabCause)}/>
                    {claim.claimDiagnosticCategory && (<ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('diagnosticCategoryText').toString())} value={category(claim.claimDiagnosticCategory)}/>)}
                    <ReviewField style={{ flexGrow: '1' }} small label={titleCase(t('associatedLeaveText').toString())} value={associatedLeave ? (<Link data-testid='associatedLeaveLink' to={associatedLeave.to} onClick={() => trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: associatedLeave.lveNum,
                event_results: 'leave',
                event_version: 'details',
            })}>
                                {associatedLeave.lveNum}
                            </Link>) : null}/>
                </div>
                {experience === ExperienceCode.EMPLOYER && claim.prodType && claim.prodType.trim() === 'PLNY' && (<div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <Row className="mt-2" style={{ marginLeft: 0, width: '100%' }}>
                            <ReviewField style={{ flexGrow: '1' }} small label={titleCase(t('classDescriptionText').toString())} value={claim.clsDescription && formatClassDescription(claim.clsDescription)}/>
                        </Row>
                    </div>)}
                <Row className="mt-3">
                    {(getEnvVariable('enableCategorizationForS1') && experience === 'EE') || user?.customerInContext?.smallMid ? (<StyledCol md={6} size={12} sm={12} className={'mb-3'}>
                            <CategorizationDocumentUpload eventNum={claim.clmNum} productName={claim.prodType} displayLabel={true} renderAsLink={false} key={'CategorizationDocumentUpload' + claim.clmNum} data-testid="CategorizationDocumentUpload" page='details' isNewCard={true}/>
                        </StyledCol>) : (<StyledCol md={6} size={12} sm={12} className={'mb-3'}>
                            <DocumentUpload eventNum={claim.clmNum} productName={claim.prodType} displayLabel={true} theme={themes.lincoln} isNewCard={true}/>
                        </StyledCol>)}
                    {experience === 'ER' && (<>
                            <StyledCol md={6} size={12} sm={12} className={'mb-3'}>
                                <StyledButton data-testid={'downloadClaimDetails'} buttonType={'secondary'} size={'medium'} type={'button'} onClick={downloadClaimDetails} disabled={isDownloading} aria-label='Download Claim Details' style={{ textTransform: 'inherit' }}>
                                    {isDownloading ? (<Spinner id='claim-info-spinner' size='small' style={{ float: 'left', margin: '0px', marginTop: '-.25rem', marginRight: '.5rem' }}/>) : (<Icon name='download' color={'secondary'} style={{ paddingRight: '.25rem' }}/>)}
                                    Download Claim Details
                                </StyledButton>
                            </StyledCol>
                            <StyledCol md={6} size={12} sm={12} className={'mb-3'}>
                                <WatchlistCheckBox eventId={claimId} eventType={'CLAIM'} isNewCard={true}/>
                            </StyledCol>
                        </>)}
                </Row>
                {isDownloadError && <DownloadClaimSummaryError />}
            </Card>
        </div>);
};
export default ClaimInfoCard;
