import { Col, Container, Heading, Row } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import { getBeneficiaries } from '../../../api/BdmApi';
import PageLoader from '../../../components/PageLoader/PageLoader';
import ServiceUnavailable from '../../../components/ServiceUnavailable/ServiceUnavailable';
import useAuthentication from '../../../hooks/useAuthentication';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import useRouteCheck from '../../../hooks/useRouteCheck';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import { getTraceId } from '../../../utils/axiosUtils';
import { CONTINGENT, PRIMARY } from '../constants/BdmConstants';
import { BdmDisplayTextEn as LanguageEn } from '../language/BdmDisplayTextEn';
import { BdmDisplayTextEs as LanguageEs } from '../language/BdmDisplayTextEs';
import BdmModalGetHelp from '../modals/BdmModalGetHelp';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import BdmBeneficiary from './BdmBeneficiary';
import BdmConfirmation from './BdmConfirmation';
import { BdmSignature } from './BdmSignature';
const INITIAL_SIGNATURE_DATA = {
    associateSignature: '',
    city: '',
    country: '',
    isAgreed: false,
    state: '',
};
export const BdmContainer = ({ ssn, employeeId, dob, zip }) => {
    const { user, authHeader } = useAuthentication();
    const [showConfirmationPage, setShowConfirmationPage] = useState(false);
    const [signatureData, setSignatureData] = useState(INITIAL_SIGNATURE_DATA);
    const [contingentBeneficiaries, setContingentBeneficiaries] = useState([]);
    const [contingentEqualShares, setContingentEqualShares] = useState();
    const [primaryBeneficiaries, setPrimaryBeneficiaries] = useState([]);
    const [primaryEqualShares, setPrimaryEqualShares] = useState();
    const [id, setId] = useState();
    const [empId, setEmpId] = useState();
    const [empSsn, setEmpSsn] = useState();
    const [lastSubmitted, setLastSubmitted] = useState('');
    const [isFetchingBeneficiaries, setIsFetchingBeneficiaries] = useState(true);
    const config = useBdmLciConfig();
    const { setBdmStarted, setBdmCompleted } = useRouteCheck();
    const [didServiceFail, setDidServiceFail] = useState(false);
    const [traceId, setTraceId] = useState('');
    const beneficiariesRequest = {
        ssoManualSupportInd: config.bdmApplication.ssoManualSupport,
        customerDiv: config.custDiv,
        customerSerial: config.custSeral,
        empSsn: ssn ? ssn : null,
        empId: !!employeeId
            ? employeeId
            : !!user && !!user.ssoCargo && !!user.ssoCargo.employeeId
                ? user.ssoCargo.employeeId
                : null,
        dateOfBirth: dob,
        zipCode: zip
    };
    const beneficiariesCount = primaryBeneficiaries.length + contingentBeneficiaries.length;
    const experienceContext = useContext(ExperienceContext);
    const replaceWith = experienceContext.language === Language.ENGLISH ? ' at' : ' a las';
    useEffect(() => {
        if (isFetchingBeneficiaries && !showConfirmationPage) {
            loadBeneficiaries();
        }
        setBdmStarted(true);
        return () => {
            setBdmStarted(false);
            setBdmCompleted(false);
        };
    }, []);
    const loadBeneficiaries = async () => {
        try {
            const response = await getBeneficiaries(authHeader(), beneficiariesRequest);
            setPrimaryBeneficiaries(response.primaryBeneficiaries);
            setContingentBeneficiaries(response.contingentBeneficiaries);
            setPrimaryEqualShares(response.equalSharesPrimary);
            setContingentEqualShares(response.equalSharesContingent);
            setLastSubmitted(response.lastSubmittedTimestamp);
            setId(response.id);
            /*
            remove usage of empId and empSsn from repsonse after OGPIT-1372 is complete
            */
            setEmpId(beneficiariesRequest.empId || response.empId);
            setEmpSsn(beneficiariesRequest.empSsn || response.empSsn);
        }
        catch (error) {
            setDidServiceFail(true);
            setTraceId(getTraceId(error));
        }
        setIsFetchingBeneficiaries(false);
    };
    const updateBeneficiaries = (beneficiaryCategory, beneficiaries) => {
        if (beneficiaryCategory === PRIMARY) {
            setPrimaryBeneficiaries(beneficiaries);
            setContingentBeneficiaries([...contingentBeneficiaries]);
        }
        if (beneficiaryCategory === CONTINGENT) {
            setContingentBeneficiaries(beneficiaries);
            setPrimaryBeneficiaries([...primaryBeneficiaries]);
        }
    };
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    return isFetchingBeneficiaries ? (<PageLoader id='bdm-container-spinner' data-testid='bdm-spinner' data-public={true}/>) : !didServiceFail ? (<div data-public={true}>
      {!showConfirmationPage ? (<Container>
          <Row className={'mt-5'}>
            <Col md={10} xs={12}>
              <Heading>{displayText.bdmHeading}</Heading>
            </Col>
          </Row>
          <Row>
            <Col md={10} xs={12}>
              <p>{displayText.bdmText}</p>
            </Col>
            <Col md={2} xs={12} style={{ textAlign: 'right' }}>
              <BdmModalGetHelp hasIcon={true} openModalText={displayText.getHelp}/>
            </Col>
          </Row>
          {lastSubmitted && (<Row>
              <Col md={10} xs={12}>
                <p id='last-submitted'>
                  {displayText.lastSubmitted}
                  {BdmBeneficiaryUtils.removeSecondsFromTime(lastSubmitted, true, replaceWith)}
                  {displayText.successMsgEnd}
                </p>
              </Col>
            </Row>)}
          <Row>
            <Col>
              <BdmBeneficiary beneficiaryCategory={PRIMARY} beneficiaries={primaryBeneficiaries} setBeneficiaries={setPrimaryBeneficiaries} updateBeneficiaries={updateBeneficiaries} beneficiariesCount={beneficiariesCount} equalShares={primaryEqualShares} setEqualShares={setPrimaryEqualShares}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <BdmBeneficiary beneficiaryCategory={CONTINGENT} beneficiaries={contingentBeneficiaries} setBeneficiaries={setContingentBeneficiaries} updateBeneficiaries={updateBeneficiaries} beneficiariesCount={beneficiariesCount} equalShares={contingentEqualShares} setEqualShares={setContingentEqualShares}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <BdmSignature contingentBeneficiaries={contingentBeneficiaries} contingentEqualShares={contingentEqualShares} primaryBeneficiaries={primaryBeneficiaries} primaryEqualShares={primaryEqualShares} setShowConfirmationPage={setShowConfirmationPage} setSignatureData={setSignatureData} ssn={empSsn} empId={empId} id={id} dob={dob} zip={zip}/>
            </Col>
          </Row>
        </Container>) : (<Container>
          <BdmConfirmation contingentBeneficiaries={contingentBeneficiaries} contingentEqualShares={contingentEqualShares} primaryBeneficiaries={primaryBeneficiaries} primaryEqualShares={primaryEqualShares} signatureData={signatureData} ssn={empSsn} empId={empId}/>
        </Container>)}
    </div>) : (<ServiceUnavailable traceId={traceId} data-public={true}/>);
};
export default BdmContainer;
