import { Language } from '../../types/Language';
import { validationDisplayTextEn } from '../../utils/language/validationDisplayTextEn';
import { validationDisplayTextEs } from '../../utils/language/validationDisplayTextEs';
export const getSpecificRequiredFieldMap = (fieldNameMap) => {
    return {
        [Language.ENGLISH]: `The "${fieldNameMap[Language.ENGLISH]}" field is required.`,
        [Language.SPANISH]: `El campo "${fieldNameMap[Language.SPANISH]}" es obligatorio.`,
    };
};
export const isRequiredMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.fieldRequired,
    [Language.SPANISH]: validationDisplayTextEs.fieldRequired,
};
export const userNameInvalidMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.usernameInvalidErrormessage,
    [Language.SPANISH]: validationDisplayTextEs.usernameInvalidErrormessage,
};
export const phoneInvalidMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.phoneErrorMessage,
    [Language.SPANISH]: validationDisplayTextEs.phoneErrorMessage,
};
export const nonApprovedCountryMap = {
    [Language.ENGLISH]: validationDisplayTextEn.nonApprovedCountryError,
    [Language.SPANISH]: validationDisplayTextEs.nonApprovedCountryError,
};
export const invalidEmailErrorMap = {
    [Language.ENGLISH]: validationDisplayTextEn.invalidEmail,
    [Language.SPANISH]: validationDisplayTextEs.invalidEmail,
};
export const invalidFirstNameErrorMap = {
    [Language.ENGLISH]: validationDisplayTextEn.invalidFirstName,
    [Language.SPANISH]: validationDisplayTextEs.invalidFirstName,
};
export const invalidLastNameErrorMap = {
    [Language.ENGLISH]: validationDisplayTextEn.invalidLastName,
    [Language.SPANISH]: validationDisplayTextEs.invalidLastName,
};
export const passwordFormatInvalidMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.invalidPassword,
    [Language.SPANISH]: validationDisplayTextEs.invalidPassword,
};
export const insecurePasswordMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.passwordUsernameErrorMessage,
    [Language.SPANISH]: validationDisplayTextEs.passwordUsernameErrorMessage,
};
export const invalidRenteredPasswordMessageMap = {
    [Language.ENGLISH]: validationDisplayTextEn.invalidPasswordMatch,
    [Language.SPANISH]: validationDisplayTextEs.invalidPasswordMatch,
};
