import { invalidFirstNameErrorMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidName } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidFirstName(isRequired = false) {
    const errorMessageMap = [invalidFirstNameErrorMap];
    const validators = [isValidName];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: 'First Name', [Language.SPANISH]: 'Nombre de pila' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidFirstName;
