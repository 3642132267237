import { Language } from '../../../types/Language';
export const UserProfileInformationDisplayTextEn = {
    language: Language.ENGLISH,
    profileHeading: 'Profile information',
    profileHeadingSso: 'Profile information',
    employerNameLabel: 'Employer name',
    firstNameLabel: 'First name',
    firstNamePlaceholder: 'Enter first name',
    lastNameLabel: 'Last name',
    lastNamePlaceholder: 'Enter last name',
    preferredPhoneLabel: 'Preferred phone',
    phoneHelperText: 'This number will be used to receive a security code when logging in.',
    preferredEmailLabel: 'Preferred email',
    reEnterPreferredEmailLabel: 'Re-enter preferred email',
    preferredEmailPlaceholder: 'Enter email',
    saveChangesButton: 'Save Changes',
    formSubmitError: 'Something went wrong. Please try again momentarily or call 1-800-431-2958 (8:00 AM to 8:00 PM EST) for assistance.',
    formSubmitSuccess: 'Success! Your information has been saved.',
    emailInUseError: 'This email address is already in use.',
    emailVerificationHead: 'You still need to confirm your email change!',
    emailVerificationBegin: 'We\'ve sent a confirmation email to ',
    emailVerificationEnd: '. You must click the link in that message before your email change will take effect.',
    emailHelperText: 'If you change your email address, you will be logged out of My Lincoln Portal and an email will be sent to your new email address for verification.',
    ssoUserAlert: 'To update profile information, please go to your Employer\'s website.',
    mlpEmpCustCode: 'To update your email address, change your display name, or to reset your phone number for authentication, please call 866-360-0683'
};
