import { Route, Routes } from 'react-router-dom';
import EmailNotifications from './components/EmailNotifications';
import UserPermissionsContainer from './container/UserPermissionsContainer';
import AdminPermissionState from './context/AdminPermissionState';
const AdminRouter = () => {
    return (<>
      <AdminPermissionState>
        <Routes>
          <Route path={`/emailNotification`} element={<EmailNotifications />}/>
          <Route path={`/permissions`} element={<UserPermissionsContainer />}/>
        </Routes>
      </AdminPermissionState>
    </>);
};
export default AdminRouter;
