import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductDetails from './ProductDetails';
import ReportDetailHeader from './ReportDetailHeader';
import StatesAndProvincesDetails from './StatesAndProvincesDetails';
import StatusAndDatesDetails from './StatusAndDatesDetails';
import SubsAndLocsDetails from './SubsAndLocsDetails';
import { withRouter } from 'src/utils/withRouter';
class MyReportFilterDetail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { filterStatusCodes, products, reportFilterCategory, reportDateRange, isLoading, workStates, workProvinces, history, employeeWorkLocationLabel, runReportCountCall, selectedSubsAndLocs, availableSubsAndLocsCount, } = this.props;
        if (isLoading) {
            return null;
        }
        if (runReportCountCall) {
            return (<>
          <div data-public={true}>
            <ReportDetailHeader filterStatusCodes={filterStatusCodes} products={products} reportFilterCategory={reportFilterCategory} reportDateRange={reportDateRange} workStates={workStates} workProvinces={workProvinces} history={history} employeeWorkLocationLabel={employeeWorkLocationLabel}/>
            <ProductDetails employeeWorkLocationLabel={employeeWorkLocationLabel} reportFilterCategory={reportFilterCategory} filterStatusCodes={filterStatusCodes} products={products} reportDateRange={reportDateRange} workStates={workStates} workProvinces={workProvinces} history={history}/>
            <StatusAndDatesDetails employeeWorkLocationLabel={employeeWorkLocationLabel} reportFilterCategory={reportFilterCategory} filterStatusCodes={filterStatusCodes} products={products} reportDateRange={reportDateRange} workStates={workStates} workProvinces={workProvinces} history={history}/>

            <SubsAndLocsDetails selectedSubsAndLocs={selectedSubsAndLocs} availableSubsAndLocsCount={availableSubsAndLocsCount} history={history}/>
            <StatesAndProvincesDetails employeeWorkLocationLabel={employeeWorkLocationLabel} reportFilterCategory={reportFilterCategory} filterStatusCodes={filterStatusCodes} products={products} reportDateRange={reportDateRange} workStates={workStates} workProvinces={workProvinces} history={history}/>
          </div>
        </>);
        }
        else {
            return <div />;
        }
    }
}
export const mapStateToProps = ({ crsReducer }) => {
    return {
        isLoading: crsReducer.isLoading,
        reportFilterCategory: crsReducer.reportFilterCategory,
        filterStatusCodes: crsReducer.filterStatusCodes,
        products: crsReducer.products,
        reportDateRange: crsReducer.reportDateRange,
        workStates: crsReducer.workStates,
        workProvinces: crsReducer.workProvinces,
        runReportCountCall: crsReducer.reportCountCallSuccess,
        employeeWorkLocationLabel: crsReducer.employeeTextCustomContent,
        selectedSubsAndLocs: crsReducer.selectedSubLocs,
        availableSubsAndLocsCount: crsReducer.subLocs.length,
    };
};
export default withRouter(connect(mapStateToProps)(MyReportFilterDetail));
