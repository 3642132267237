import React from 'react';
import useEoiContext from '../hooks/useEoiContext';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../hooks/useTranslate';
import { EoiGeneralText } from '../i18n/EoiText';
const ValidationErrorAlert = () => {
    const { validationError } = useEoiContext();
    const { t } = useTranslate();
    if (validationError) {
        return <Alert type={'error'} theme={themes.lincoln} data-testid={'validation-error'}>{t(EoiGeneralText.validationError)}</Alert>;
    }
    else {
        return <></>;
    }
};
export default ValidationErrorAlert;
