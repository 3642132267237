import { AboutYouText } from "./AboutYouText";
import { CliVerificationText } from "./CliVerificationText";
import { FraudStatementText } from "./FraudStatementText";
import { PersonalInformationText } from "./PersonalInformationText";
import { PreferredContactInformationText } from "./PreferredContactInformationText";
import { ResidentialInformationText } from "./ResidentialInformationText";
import { AdditionalInfoText } from "./AdditionalInfoText";
export const ReviewText = {
    pageTitle: {
        english: 'Review',
        spanish: 'Revisión'
    },
    ensureRequestEE: {
        english: 'Please review the information below for accuracy before submitting your absence. To make any changes, select “Edit” to return to the appropriate section.',
        spanish: 'Revise la información a continuación para verificar su precisión antes de enviar su ausencia. Para realizar cambios, seleccione "Editar" para volver a la sección correspondiente.'
    },
    ensureRequestER: {
        english: 'Please review the information below for accuracy before submitting your Employee\'s absence. To make any changes, select “Edit” to return to the appropriate section.',
        spanish: 'Revise la información a continuación para verificar su precisión antes de enviar su ausencia. Para realizar cambios, seleccione "Editar" para volver a la sección correspondiente.'
    },
    editButton: {
        english: 'Edit',
        spanish: 'Editar',
    },
    countryUnitedStates: {
        english: 'United States',
        spanish: 'Estados Unidos',
    },
    countryCanada: {
        english: 'Canada',
        spanish: 'Canadá',
    },
    countryOther: {
        english: 'Other Countries',
        spanish: 'Otros Pais',
    },
    submitError: {
        english: "We're sorry, we were unable to submit your request at this time. Please wait a moment and try again. If the issue continues, please ",
        spanish: 'Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si el problema continúa, por favor',
    },
    contactUs: {
        english: 'contact us',
        spanish: 'contáctenos',
    },
    personalInformation: { ...PersonalInformationText },
    contactInformation: { ...PreferredContactInformationText },
    residentialInformation: { ...ResidentialInformationText },
    aboutYou: { ...AboutYouText },
    additionalInfo: { ...AdditionalInfoText },
    verification: { ...CliVerificationText },
    fraudStatement: { ...FraudStatementText },
};
