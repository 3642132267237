import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Heading, ToggleSwitch, Loader } from '@digitools/honest-react';
import { AuthenticationContext } from '../../../providers/AuthenticationProvider';
import axios from 'axios';
import { Form, useFormApi } from 'informed';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { Unauthorized } from '../../status/components/pages';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
const StyledHeading = styled(Heading) `
  margin-top: 20px;
`;
const StyledMessage = styled('p') `
  font-size: 14px;
`;
const StyledDiv = styled('div') `
  font-size: 14px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const EmailNotifications = ({}) => {
    const authContext = useContext(AuthenticationContext);
    const { trackEvent, trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EMAIL_NOTIFICATIONS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EMAIL_NOTIFICATIONS.TOGGLE_NOTIFICATIONS,
        });
    }, []);
    const trackEmailNotifications = () => {
        return (trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.TOGGLE_EMAIL_NOTIFICATIONS,
            event_name: 'Email notifications',
        }));
    };
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [unauthorized, setUnauthorized] = useState(false);
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false);
    const url = '/mlp-ui-service/api/documents/email-notification';
    const headerConfig = { headers: { Authorization: authContext.authHeader() } };
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    useEffect(() => setIsMessagingDisabled(false), []);
    useEffect(() => {
        if (isFetching && isPageLoading) {
            fetchEmailNotificationIndicator();
        }
    }, [isFetching]);
    const handleChange = async (event) => {
        if (!isPageLoading) {
            setIsPageLoading(true);
            try {
                if (event) {
                    await axios.post(url, {}, headerConfig);
                    setEmailNotificationsEnabled(true);
                }
                else {
                    await axios.delete(url, headerConfig);
                    setEmailNotificationsEnabled(false);
                }
            }
            catch (e) { }
            setIsPageLoading(false);
        }
    };
    const fetchEmailNotificationIndicator = async () => {
        await axios
            .get(url, headerConfig)
            .then(() => {
            setEmailNotificationsEnabled(true);
        })
            .catch(error => {
            if (error.response.status === 404) {
                setEmailNotificationsEnabled(false);
            }
            if (error.response.status === 403) {
                setUnauthorized(true);
            }
        });
        setIsFetching(false);
        setIsPageLoading(false);
    };
    return (<>
      <Loader id='email-notification-spinner' isShown={isPageLoading}>
        {!unauthorized &&
            <>
          <StyledHeading elemType={'h1'} aria-labelledby='emailNotificationHeader' data-public={true}>
            E-mail Settings
          </StyledHeading>
          <StyledMessage aria-labelledby='emailNotificationMessage' data-public={true}>
            Enable or disable e-mail notifications for your folders and files by checking or unchecking the box below. The
            change to your preferences is effective immediately. Once e-mail notification for your username is enabled,
            you will receive a message in your regular e-mail anytime a file is uploaded to a folder to which you have
            access.
          </StyledMessage>
          <StyledDiv>
            <Form>
              <EmailNotificationsForm onChange={handleChange} enabled={emailNotificationsEnabled} trackButtonClick={trackEmailNotifications}/>
            </Form>
          </StyledDiv>
        </>}
        {unauthorized && <Unauthorized />}
      </Loader>
    </>);
};
export default EmailNotifications;
const EmailNotificationsForm = ({ ...props }) => {
    const { onChange, enabled, trackButtonClick } = props;
    const formApi = useFormApi();
    useEffect(() => {
        formApi.setValue('emailToggle', enabled);
    }, [enabled]);
    return (<>
      <ToggleSwitch data-testid='email-toggle' field={'emailToggle'} label={'Receive Email Notifications'} toggleSize={'small'} id='receiveEmailNotification' aria-label='receiveEmailNotification' onClick={trackButtonClick} data-public={true} onValueChange={(event) => {
            onChange(event);
        }}/>
      <span style={{ fontSize: '14px', marginLeft: '5px' }} data-public={true}> E-mail notifications {enabled ? 'on' : 'off'}</span>
    </>);
};
