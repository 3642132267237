import React from 'react';
import { CliConfigProvider } from './providers/CliConfigProvider';
import { CliDataProvider } from './providers/CliDataProvider';
import CliRouter from './routes/CliRouter';
import GenericErrorBoundary from 'src/components/errorPages/GenericErrorBoundary';
/**
 * Container to setup Theme, Error & Providers for CLI app, pass route props down to CLIRouter
 * @param props RouteComponentProps
 * @returns
 */
const CliContainer = () => {
    //NOTE: All appwide providers specific to CLI should go here
    return (<GenericErrorBoundary>
      <CliConfigProvider>
        <CliDataProvider>
          <CliRouter />
        </CliDataProvider>
      </CliConfigProvider>
    </GenericErrorBoundary>);
};
export default CliContainer;
