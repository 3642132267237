import { Icon } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import { getCustomerQuickLinks } from '../../../../api/ConfigApi';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useExperience from '../../../../hooks/useExperience';
import useTranslate from '../../../../hooks/useTranslate';
import DropdownMenu from './DropdownMenu/DropdownMenu';
const QuickLinks = ({ trackPageEvent }) => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    /** Call for Configurable NavMenus - only when logged in & experience changes  **/
    const { error, loading, data: quickLinks, get: triggerGetQuickLinks } = getCustomerQuickLinks(experience);
    useEffect(() => {
        triggerGetQuickLinks();
    }, [experience]);
    let dropdownPosition = 'center';
    const x = window.matchMedia("(max-width: 768px)");
    if (x.matches) {
        dropdownPosition = 'left';
    }
    return (<div id='quick-links' data-testid='quick-links'>
      {quickLinks && quickLinks.length > 0 &&
            <DropdownMenu data-testid={'quick-links-dropdown'} buttonStyle={{ fontSize: '.8125rem', padding: '0.25rem' }} menuStyle={{ minWidth: '250px' }} dropdownPosition={dropdownPosition} label={<>
              <Icon name='link' color='secondary' size='sm' style={{ marginRight: '.25rem' }}/>
              {t({ english: 'Quick links', spanish: 'Rapido enlace' })}
            </>}>
          {quickLinks.map((quickLink, i) => <a key={i} data-testid='quick-link' data-public={true} href={quickLink.url} onClick={() => trackPageEvent && trackPageEvent('quick-links', TEALIUM_EVENT.EVENT_ACTION.LINK)} target="_blank">{t(quickLink)}</a>)}
        </DropdownMenu>}
    </div>);
};
export default QuickLinks;
