import React, { createContext, useState } from 'react';
import { Alert } from '@digitools/honest-react';
import { v4 } from 'uuid';
import LanguageToggle from '../utils/LanguageToggle';
export const MessageContext = createContext({
    addMessage: () => { },
    clearMessages: () => { },
});
const MessageComponent = ({ message, removeMessage }) => {
    const handleClose = () => removeMessage(message.id);
    return (<Alert data-testid={'app-message'} type={message.type} closeable={message.closeable} visible={true} setVisible={handleClose}>
      <div className={'mr-1'}>
        {!!message.title && (<strong>
            <LanguageToggle en={message.title[0]} es={message.title[1]}/>{' '}
          </strong>)}
        <LanguageToggle en={message.message[0]} es={message.message[1]}/>
      </div>
    </Alert>);
};
const MessageProvider = ({ children, }) => {
    const [messages, setMessages] = useState([]);
    const addMessage = (message) => setMessages(oldMessages => [...oldMessages, { ...message, id: v4() }]);
    const removeMessage = (id) => setMessages(oldMessages => oldMessages.filter(oldMessage => oldMessage.id !== id));
    const clearMessages = () => setMessages([]);
    const context = { addMessage, clearMessages };
    return (<MessageContext.Provider value={context}>
      <div className={'mt-3'}>
        {messages.map(message => (<MessageComponent message={message} removeMessage={removeMessage}/>))}
      </div>
      {children}
    </MessageContext.Provider>);
};
export default MessageProvider;
