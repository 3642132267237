import React, { useRef } from 'react';
// @ts-ignore
import { Form, Relevant, useFormApi, useFormState } from 'informed';
import { getPlaceholder, getSearchLabel, getSearchValidator } from '../../../status/components/pages/EmployeeSearch/EmployeeSearchForm';
import { Button, SelectInput, TextInput, themes } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useTealium from '../../../../hooks/useTealium';
import useAuthentication from '../../../../hooks/useAuthentication';
const submitEmployeeSearch = (values, navigate) => {
    navigate(`/status/employer/search?search=${values.search}&searchCrit=${values.searchCriteria}`);
};
const validateSearchCriteria = (value) => {
    if (!value)
        return 'This field is required!';
};
const ViewRequestStatusFormFields = () => {
    const formState = useFormState();
    const formApi = useFormApi();
    const searchCriteriaRef = useRef(null);
    const placeholder = getPlaceholder(formState?.values?.searchCriteria);
    const label = getSearchLabel(formState?.values?.searchCriteria);
    const validateSearch = getSearchValidator(formState?.values?.searchCriteria);
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const isStatusERWithAccommodation = user && user?.applicationPermissions && user?.applicationPermissions?.indexOf('statusER.viewAccommodationStatus.viewBasicAsRequest') > -1;
    const fieldFocus = (field) => {
        if (!formApi.getTouched(field.target.id)) {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.FOCUS,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYEE_LANDING_PAGE_SEARCH,
                event_name: field.target.value,
            });
        }
    };
    return (<>
            <SelectInput className={`mb-0`} field={'searchCriteria'} invertedColor={'white'} invertedColorSuccess={'white'} invertedColorError={'white'} data-testid={'searchCriteria'} options={[
            {
                label: 'Employee name',
                value: 'empLastName',
            },
            {
                label: 'Employee ID',
                value: 'empId',
            },
            {
                label: 'Claim or leave number',
                value: 'claimNum',
            },
            ...(isStatusERWithAccommodation ? ([
                {
                    label: 'Accommodation event ID',
                    value: 'accommodationEventId',
                },
            ]) : ([]))
        ]} theme={themes.lincoln} floatingLabelBackgroundColor={'primary'} placeholderText={'Search criteria'} label={'Search criteria'} 
    //@ts-ignore //FIXME: type error
    ref={searchCriteriaRef} validateOnBlur={true} onFocus={fieldFocus} validate={validateSearchCriteria}/>
            <Relevant when={({ values }) => values?.['searchCriteria'] && Object.keys(values?.['searchCriteria']).length}>
                <TextInput data-testid={'searchField'} field={'search'} language={'en'} placeholder={placeholder} theme={themes.lincoln} label={label} type={`text`} invertColors={true} backgroundColor={'primary'} validate={validateSearch} validateOnBlur={true} validateOnChange={true}/>
            </Relevant>
            <Button buttonType={`primaryAlt`} data-testid={'searchButton'}>
                Search
            </Button>
        </>);
};
const ViewRequestStatusForm = () => {
    const navigate = useNavigate();
    const { trackEvent } = useTealium();
    return (<Form onSubmit={(values) => {
            submitEmployeeSearch(values, navigate);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.SEARCH,
                event_type: TEALIUM_EVENT.EVENT_TYPE.HOME_PAGE,
                event_name: values.searchCriteria,
                event_version: TEALIUM_EVENT.EVENT_TYPE.LANDING_PAGE_BANNER,
            });
        }}>
            <ViewRequestStatusFormFields />
        </Form>);
};
export default ViewRequestStatusForm;
