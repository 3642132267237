import React from 'react';
import { useFormState, Scope } from 'informed';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import LeaveDates from '../components/LeaveDates';
import Relationship from '../components/Relationship';
import { Row, Col, Label, DatePickerV2 } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import OtherRelationship from '../components/OtherRelationship';
const MilitaryFlow = () => {
    const { values } = useFormState();
    const { experience, language } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { absenceData } = useCliDataContext();
    /* Show if leaveType === MILITARY and they've answered leave reason */
    const shouldDisplayMilitaryFlow = values?.absenceData?.leaveType === LeaveType.MILITARY && values?.absenceData?.leaveReason;
    /* Show if leaveReason === ML */
    const shouldDisplayRelationshipAndCareRecipientDob = values?.absenceData?.leaveReason === 'ML';
    /* Show other relationship text field if relationship is Other (OT) */
    const shouldDisplayOtherRelationship = values?.absenceData?.relationship === 'OT';
    return (<>

      {shouldDisplayMilitaryFlow && <div data-testid='military-flow'>
        {/* @ts-ignore */}
        <Scope scope='absenceData'>
          {/* relationship */}
          {shouldDisplayRelationshipAndCareRecipientDob && (<Relationship />)}

          {/* IF relationship === Other -  otherRelationship */}
          {shouldDisplayOtherRelationship && (<OtherRelationship />)}

          {shouldDisplayRelationshipAndCareRecipientDob &&
                <Row className='mb-3'>
              <Col size={12}>
                <Label className='mb-2' htmlFor='careRecipientDateOfBirth'>
                  {CustomEmployeeText(t(experience === Experience.EMPLOYEE
                        ? formLabels.careRecipientDateOfBirthEE
                        : formLabels.careRecipientDateOfBirthER))}
                </Label>
                </Col>
                <Col size={12} sm={6} xl={4}>
                <DatePickerV2 id={'careRecipientDateOfBirth'} field={'careRecipientDateOfBirth'} data-testid='careRecipientDateOfBirth' label={t(formLabels.careRecipientDateOfBirthShortLabel)} ariaLabelText={t(formLabels.careRecipientDateOfBirthShortLabel)} validate={(input) => validateDateIsOnOrBeforeToday(input, t)} initialValue={absenceData && absenceData.careRecipientDateOfBirth} placeholder={'mm/dd/yyyy'} lang={language}/>
              </Col>
            </Row>}
        </Scope>
        {/* leaveBeginDate & leaveEndDate */}
        <LeaveDates />

      </div>}

    </>);
};
export default MilitaryFlow;
