import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dental from 'src/packages/dental/Dental';
import DeductibleAndCoinsurance from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import CoveredServices from 'src/packages/dental/pages/CoveredServices';
import Claims from 'src/packages/dental/pages/Claims';
const DentalRouter = () => {
    return (<Routes>
      <Route path={`*`} element={<Dental />}/>
      <Route path={`members/:memberId`} element={<Dental />}/>
      <Route path={`members/:memberId/deductibles`} element={<DeductibleAndCoinsurance />}/>
      <Route path={`members/:memberId/coveredServices`} element={<CoveredServices />}/>
      <Route path={`members/:memberId/claims`} element={<Claims />}/>
    </Routes>);
};
export default DentalRouter;
