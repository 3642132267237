import React, { useState, useEffect } from 'react';
import { Row, Col, TextInput, Heading, themes, RadioGroup, Radio, Button, Icon } from '@digitools/honest-react';
import useTranslate from '../../../hooks/useTranslate';
import { selectInputErrorMessage } from '../../eoi/utils/FormFieldValidations';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import { RadioContainer } from './LayoutComponents';
import useCliDataContext from '../hooks/useCliDataContext';
import '../pages/payment/PaymentMethod.scss';
import { accountrouteMask, validateAccount, validateConfirmAccount, validateNameDD, validateRoute, } from '../utils/validationUtils';
import { DirectDepositInformationText } from '../constants/DirectDepositInformationText';
// @ts-ignore
import DirectDepositImg from 'src/assets/direct-deposit-check.png';
import styled from 'styled-components';
const StyledButton = styled(Button) `
    font-size: 1rem;
    padding: 0.25rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1;
    text-transform: none;
    cursor: pointer;
    color: #AD112B;
    border: 2px solid transparent;
    background: none;
`;
const DirectDepositInformation = ({ handleDisable, setAccountDetails }) => {
    const { paymentMethodData, isDirectDepositSuccess } = useCliDataContext();
    const { t } = useTranslate();
    const [DirectDepositData, setDirectDepositData] = useState({
        accountHolderFirstName: paymentMethodData?.accountHolderFirstName || '',
        accountHolderLastName: paymentMethodData?.accountHolderLastName || '',
        routingNumber: paymentMethodData?.routingNumber || '',
        accountNumber: paymentMethodData?.accountNumber || '',
        confirmAccountNumber: paymentMethodData?.confirmAccountNumber || '',
    });
    const [isExpanded, setExpanded] = useState(false);
    const getCollapseText = () => {
        return isExpanded ? t(DirectDepositInformationText.hideCheckRouting) : t(DirectDepositInformationText.showCheckRouting);
    };
    const handleCollapseClick = () => {
        setExpanded(!isExpanded);
    };
    const checkName = (val) => {
        return val.match(/^[-A-Za-z'. ]*$/gm);
    };
    let successStyle = isDirectDepositSuccess ? 'none' : 'block';
    useEffect(() => {
        if (DirectDepositData?.accountHolderFirstName && checkName(DirectDepositData?.accountHolderFirstName) &&
            DirectDepositData?.accountHolderLastName && checkName(DirectDepositData?.accountHolderLastName) &&
            DirectDepositData?.routingNumber.length == 9 &&
            DirectDepositData?.accountNumber == DirectDepositData.confirmAccountNumber &&
            DirectDepositData?.accountNumber.length >= 4) {
            handleDisable && handleDisable(false);
            setAccountDetails && setAccountDetails(DirectDepositData?.routingNumber, DirectDepositData?.accountNumber);
        }
        else {
            handleDisable && handleDisable(true);
        }
    }, [DirectDepositData]);
    return (<div data-testid='direct-deposit-information' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <div role='group' aria-labelledby='accountinformation'>
        <BorderLessGrayBox>
          <Heading id='accountinformation' elemType='h4'>{t(DirectDepositInformationText.heading)}</Heading>
        </BorderLessGrayBox>
        <Row>
          <Col md={4} sm={6} xs={12}>
            <RadioGroup label={t(DirectDepositInformationText.accountType)} field={'accounttype'} theme={themes.lincoln} legendStyles={{ marginBottom: '.75rem' }} initialValue={paymentMethodData && paymentMethodData.accounttype}>
              <RadioContainer className={'styledRadioContainer'}>
                <Radio disabled={isDirectDepositSuccess} containerStyle={{ zIndex: 0 }} data-testid={'radio-checkings'} name='selectaccounttype' label={t(DirectDepositInformationText.checkings)} value={'checkings'}/>
                <Radio disabled={isDirectDepositSuccess} containerStyle={{ zIndex: 0 }} data-testid={'radio-savings'} name='selectaccounttype' label={t(DirectDepositInformationText.savings)} value={'savings'}/>
              </RadioContainer>
            </RadioGroup>
          </Col>
        </Row>
      </div>
      <div role='group' aria-labelledby='accountdetails' style={{ marginTop: '0.5rem' }}>
        <Row>
          <Col md={12}>
            <Heading id='accountdetails' elemType='h3' elemStyle='h4'>{t(DirectDepositInformationText.accountDetails)}</Heading>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <TextInput data-testid='account-holder-first-name' data-private={true} field={'accountHolderFirstName'} label={t(DirectDepositInformationText.firstName)} ariaLabelText={t(DirectDepositInformationText.firstName)} theme={themes.lincoln} type={'text'} validateOnBlur={true} onChange={(e) => {
            setDirectDepositData({ ...DirectDepositData, accountHolderFirstName: e.target.value });
        }} validate={validateNameDD} 
    //@ts-ignore FIXME: type error
    errorMessage={selectInputErrorMessage} initialValue={paymentMethodData && paymentMethodData.accountHolderFirstName} disabled={isDirectDepositSuccess}/>
          </Col>
          <Col md={6} xs={6}>
            <TextInput data-testid='account-holder-last-name' data-private={true} field={'accountHolderLastName'} label={t(DirectDepositInformationText.lastName)} ariaLabelText={t(DirectDepositInformationText.lastName)} type={'text'} theme={themes.lincoln} validateOnBlur={true} validate={validateNameDD} onChange={(e) => {
            setDirectDepositData({ ...DirectDepositData, accountHolderLastName: e.target.value });
        }} 
    //@ts-ignore FIXME: type error
    errorMessage={selectInputErrorMessage} initialValue={paymentMethodData && paymentMethodData.accountHolderLastName} disabled={isDirectDepositSuccess}/>
          </Col>
          <Col md={6}>
            <TextInput maxLength={9} data-testid='routing-number' data-private={true} field={'routingNumber'} toggleShowPassword={true} label={t(DirectDepositInformationText.routingNumber)} ariaLabelText={t(DirectDepositInformationText.routingNumber)} theme={themes.lincoln} type={'textmask'} validateOnBlur={true} onChange={(e) => {
            setDirectDepositData({ ...DirectDepositData, routingNumber: e.target.value });
        }} validate={validateRoute} mask={accountrouteMask} initialValue={paymentMethodData && paymentMethodData.routingNumber} disabled={isDirectDepositSuccess}/>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput maxLength={17} data-testid='account-number' data-private={true} field={'accountNumber'} toggleShowPassword={true} label={t(DirectDepositInformationText.accountNumber)} ariaLabelText={t(DirectDepositInformationText.accountNumber)} type={'textmask'} validateOnBlur={true} theme={themes.lincoln} onChange={(e) => {
            setDirectDepositData({ ...DirectDepositData, accountNumber: e.target.value });
        }} validate={validateAccount} mask={accountrouteMask} initialValue={paymentMethodData && paymentMethodData.accountNumber} disabled={isDirectDepositSuccess}/>
          </Col>
          <Col md={6}>
            <div style={{ display: successStyle }}>
              <TextInput maxLength={17} data-testid='confirm-account-number' data-private={true} toggleShowPassword={true} field={'confirmAccountNumber'} label={t(DirectDepositInformationText.confirmAccountNumber)} ariaLabelText={t(DirectDepositInformationText.confirmAccountNumber)} theme={themes.lincoln} type={'textmask'} validateOnBlur={true} onChange={(e) => {
            setDirectDepositData({ ...DirectDepositData, confirmAccountNumber: e.target.value });
        }} validate={(e) => validateConfirmAccount(e, DirectDepositData)} mask={accountrouteMask} initialValue={paymentMethodData && paymentMethodData.confirmAccountNumber} disabled={isDirectDepositSuccess}/>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col md={12}>
          <div style={{ display: successStyle }}>
            <StyledButton type="button" onClick={handleCollapseClick} data-testid="sample-check-button">
              {getCollapseText()}
              <Icon name='caret-down' color='primary' size='sm' style={{ marginLeft: '.25rem' }}/>
            </StyledButton>
            {isExpanded &&
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }} data-testid="sample-check-image">
                <img src={DirectDepositImg} style={{ width: '100%', maxWidth: '500px', margin: '1rem 0' }} alt='Sample Check'/>
              </div>}
          </div>
        </Col>
      </Row>
    </div>);
};
export default DirectDepositInformation;
