import { Col, DatePickerV2, Row, TextInput } from '@digitools/honest-react';
import React from 'react';
import { optionalFieldCharacters } from '../../../../../utils/validationUtils';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
/*                       *
**   About the Accident **
*                       */
const AccidentInformation = ({ claimant, event }) => {
    return (<Row>
      <Col xl={4} md={6} sm={12}>
        <DatePickerV2 data-testid='policyInfo_dateOfAccident' field={'policyInfo_dateOfAccident'} label={'Date of Accident (optional)'} ariaLabelText={'Date of Accident'} required={false} mask={dateMask} validateOnBlur={true}/>
      </Col>
      {claimant === 'dependent' && (<Col xl={4} md={6} sm={12}>
          <TextInput data-testid='policyInfo_accidentLocation' field={'policyInfo_accidentLocation'} ariaLabelText={'Location Of Accident'} label={'Location Of Accident (optional)'} mask={optionalFieldCharacters} type={'text'}/>
        </Col>)}
      <Col xl={4} md={6} sm={12}>
        <TextInput data-testid='policyInfo_briefAboutAccident' field={'policyInfo_briefAboutAccident'} ariaLabelText={'Brief Description of Accident'} label={'Brief Description of Accident (optional)'} mask={optionalFieldCharacters} type={'text'}/>
      </Col>
    </Row>);
};
export default AccidentInformation;
