import React from 'react';
export const EoiGeneralText = {
    header: {
        english: 'Evidence of Insurability',
        spanish: 'Evidencia de Asegurabilidad'
    }
};
const EoiVerificationText = {
    standardText: {
        english: 'Based on your <EMPLOYEE> benefit selections during enrollment, we need additional information from you. We use this additional information to gather medical history and evaluate your application for insurance. The insurance you have requested, that requires the completion of this form, will not be effective until we send you a written approval.',
        spanish: 'Según sus selecciones de beneficios de <EMPLOYEE> durante la inscripción, necesitamos su información adicional. Usamos esta información adicional para recopilar historial médico y evaluar su solicitud de seguro. El seguro que ha solicitado, que requiere completar este formulario, no entrará en vigencia hasta que le enviemos una aprobación por escrito.',
    },
    verificationHeading: {
        english: 'First, we need to verify your identity',
        spanish: 'Primero, necesitamos verificar su identidad',
    },
    verificationSubHeading: {
        english: 'How would you like to verify your identity?',
        spanish: '¿Cómo le gustaría verificar su identidad?',
    },
    employeeIdLabel: {
        english: 'Employee ID',
        spanish: 'ID de empleado',
    },
    ssnLabel: {
        english: 'Social security number',
        spanish: 'Número de seguridad social',
    },
    verifyButton: {
        english: 'VERIFY',
        spanish: 'VERIFICAR',
    },
    verifyRequiredError: {
        english: 'This field is required',
        spanish: 'Este campo es requerido',
    },
    invalidEmpId: {
        english: 'Invalid Employee ID',
        spanish: 'ID de empleado no válido',
    },
    invalidSsn: {
        english: 'Invalid Social security number',
        spanish: 'Número de seguridad social no válido',
    },
    verificationNotFound: {
        english: 'We were not able to find your information, please try again',
        spanish: 'No pudimos encontrar tu información, vuelve a intentarlo',
    },
    verifyLimitReached: {
        english: `We're sorry we couldn't find your information using employee ID, please enter your Social security number to proceed`,
        spanish: 'Lamentamos no poder encontrar su información usando la identificación de empleado, ingrese su Número de seguridad social para continuar',
    },
    welcomeHeading: {
        english: 'Welcome, ',
        spanish: 'Bienvenido, ',
    },
    newEmployeeHeadingNY: {
        english: 'Are you a new hire (newly eligible)?',
        spanish: '¿Es usted un nuevo empleado (recién elegible)?',
    },
    newEmployeeHeading: {
        english: 'Are you a new employee electing coverage for the first time?',
        spanish: '¿Es usted un nuevo empleado que elige cobertura por primera vez?',
    },
    newEmployeeSubHeading: {
        english: 'New Hire:',
        spanish: 'Nueva contratación:',
    },
    noApplicationReasonMsg: {
        english: 'We are currently outside of your company\'s open enrollment period and therefore we require a reason for Evidence of Insurability to be submitted from your Employer to Lincoln.  We have not yet received this information from your employer. Please work directly with your benefits administrator and/or employer HR contact to confirm eligibility opportunity and update Lincoln Financial Group\'s information on your behalf.',
        spanish: 'Actualmente estamos fuera del período de inscripción abierta de su empresa y, por lo tanto, requerimos que su empleador envíe una prueba de asegurabilidad a Lincoln. Aún no hemos recibido esta información de su empleador. Trabaje directamente con el administrador de beneficios y / o el contacto de recursos humanos de su empleador para confirmar la oportunidad de elegibilidad y actualizar la información de Lincoln Financial Group en su nombre.',
    },
};
export const ApplicationReasonText = {
    heading: {
        english: 'As an existing employee, please select the reason you are updating your coverage.',
        spanish: 'Como empleado existente, seleccione la razón por la que está actualizando su cobertura.',
    },
    lateEnrollment: {
        english: 'Updating benefits outside enrollment period',
        spanish: 'Actualización de beneficios fuera del período de inscripción',
    },
    lateEntrant: {
        english: 'Late entrant (person requesting benefits after initial eligibility)',
        spanish: 'Entrada tardía (persona que solicita beneficios después de la elegibilidad inicial)',
    },
    familyStatusChange: {
        english: 'Change in family status',
        spanish: 'Cambio en el estado familiar',
    },
    salaryIncrease: {
        english: 'Salary or pay increase',
        spanish: 'Salario o aumento salarial',
    },
    annualEnrollmentIndicator: {
        english: 'Annual enrollment',
        spanish: 'Inscripción anual',
    },
    initialEnrollment: {
        english: 'Initial enrollment',
        spanish: 'Inscripción inicial',
    },
    newHire: {
        english: 'New Hire (newly eligible)',
        spanish: 'Contratación nueva (recién elegible)',
    }
};
export const LicenseAgreementText = {
    heading: {
        english: 'Thank you! Once you have read and agree to our online terms & conditions, you may begin your application.',
        spanish: '¡Gracias! Una vez que haya leído y aceptado nuestros términos y condiciones en línea, puede comenzar su solicitud.',
    },
    body: {
        english: <>By selecting I agree to the <em>Electronic Signature Disclosure agreement</em>, you are agreeing that you have read and accept the terms and wish to continue the electronic application process. If you do not consent to these terms, please follow up with your employer to request a paper application if you do not have one.</>,
        spanish: <>Al seleccionar Acepto el <em>acuerdo de divulgación de firma electrónica</em>, usted acepta que ha leído y acepta los términos y desea continuar con el proceso de solicitud electrónica. Si no está de acuerdo con estos términos, comuníquese con su empleador para solicitar una solicitud impresa si no tiene una.</>
    },
    iAgree: {
        english: 'I agree to the',
        spanish: 'Acepto el'
    },
    documentTitle: {
        english: 'Electronic Signature Disclosure Agreement',
        spanish: 'Acuerdo de Divulgación de Firma Electrónica',
    },
    errorMessage: {
        english: 'Something went wrong downloading the Electronic Signature Disclosure Agreement, please try again later.',
        spanish: 'Se produjo un error al descargar el Acuerdo de Divulgación de Firma Electrónica. Vuelve a intentarlo más tarde.'
    }
};
export default EoiVerificationText;
