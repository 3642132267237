import { useContext } from 'react';
import { RiaDataContext } from '../provider/RiaDataProvider';
/* Ria Data
 *
 * Used to access and store current and user submitted data about leaves and absences
*/
function useRiaData() {
    return useContext(RiaDataContext);
}
export default useRiaData;
