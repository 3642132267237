export const PersonalInformationText = {
    personalInfoHeading: {
        english: 'Personal information',
        spanish: 'Información personal',
    },
    firstNameQuestionLabel: {
        english: 'First name',
        spanish: 'Nombre',
    },
    middleInitialQuestionLabel: {
        english: 'Middle initial',
        spanish: 'Inicial del segundo nombre',
    },
    lastNameQuestionLabel: {
        english: 'Last name',
        spanish: 'Apellido',
    },
    dateOfBirthQuestionLabel: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento',
    }
};
