export const BdmAddBeneficiaryModalDisplayTextEn = {
    addBeneficiary: 'Add Beneficiary',
    address1: 'Address 1',
    address1LCI: 'Address 1 (optional)',
    address2: 'Address 2 (optional)',
    allocation: 'Allocation %',
    beneficiaryCategoryContingent: 'Contingent',
    beneficiaryCategory: 'Beneficiary Category',
    beneficiaryCategoryPrimary: 'Primary',
    beneficiaryTypePlaceholder: 'Choose Type',
    beneficiaryType: 'Beneficiary Type',
    cancelButton: 'Cancel',
    charityOrgName: 'Charity/Organization Name',
    city: 'City',
    cityLCI: 'City (optional)',
    contactName: 'Contact Name',
    country: 'Country',
    countryLCI: 'Country (optional)',
    dateOfBirth: 'Date of Birth',
    dateOfTrust: 'Date of Trust',
    delete: 'Delete',
    deleteMessage: 'Are you sure you want to delete this beneficiary?',
    edit: 'Edit',
    editBeneficiary: 'Edit Beneficiary',
    emailAddress: 'Email Address (optional)',
    equal: 'EQUAL',
    firstName: 'First Name',
    gender: 'Sex Assigned at Birth (optional)',
    genderPlaceholder: 'Select a Sex Assigned at Birth',
    guardianAdvisory: 'Please be advised that if a beneficiary is under the age of 18 and a minor, guardianship of the minor’s estate will need to be established through your local probate court.',
    guardianInformation: 'Guardian Contact Information (optional)',
    guardianName: 'Guardian Name (optional)',
    lastName: 'Last Name',
    middleInitial: 'MI (optional)',
    otherAddress: 'Enter Address and Contact Information',
    phoneNumber: 'Phone Number (optional)',
    postalCode: 'Postal Code',
    postalCodeLCI: 'Postal Code (optional)',
    province: 'Province',
    provinceLCI: 'Province (optional)',
    modalHeader: 'Beneficiary Information',
    relationship: 'Relationship',
    relationshipPlaceholder: 'Choose a Relationship',
    ssnOrTaxID: 'SSN or Tax ID (optional)',
    state: 'State',
    stateLCI: 'State (optional)',
    stateAndProvincePlaceholder: 'Select',
    trustName: 'Trust Name'
};
