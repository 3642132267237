import { Button, Heading, TextInput } from '@digitools/honest-react';
import { Col, Container, Row } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext, useEffect, useState } from 'react';
import useRequiredField from '../../../../../hooks/fieldValidation/useRequiredField';
import { ExperienceContext } from 'src/providers/ExperienceProvider';
import { Language } from 'src/types/Language';
import { useNavigate } from 'react-router-dom';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const CompanyCode = (props) => {
    const { trackEvent } = useTealium();
    const { invalidCompanyCode, employeeRegistrationAllowed, validateCompanyCode, getLocalDisplayText, history } = props;
    const [formApi, setFormApi] = useState();
    const { language } = useContext(ExperienceContext);
    const navigate = useNavigate();
    const validateRequired = useRequiredField({
        [Language.ENGLISH]: 'Company Code',
        [Language.SPANISH]: 'Código de Compañía',
    });
    useEffect(() => {
        if (invalidCompanyCode) {
            formApi.setError('companyCode', getLocalDisplayText('companyCodeError'));
        }
        if (!employeeRegistrationAllowed) {
            formApi.setError('companyCode', getLocalDisplayText('employeeCompanyCodeError'));
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: getLocalDisplayText('employeeCompanyCodeError'),
                event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                event_version: TEALIUM_EVENT.EVENT_VERSION.BLOCK_REGISTRATION,
            });
        }
    }, [invalidCompanyCode, employeeRegistrationAllowed, language]);
    const getFormApi = (api) => {
        setFormApi(api);
    };
    const handleCancelClick = () => {
        navigate('/public/login');
    };
    return (<>
      <Container>
        <Row>
          <Col>
            <Heading elemType={'h1'} elemStyle={'h2'} data-testid={'pageHeading'} id='pageHeading' color='secondaryDarker'>
              {getLocalDisplayText('pageHeading')}
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={'mt-3'} id='registrationInfoText' data-testid={'registrationInfoText'}>
              {getLocalDisplayText('registrationInfoTextCompanyCode')}
            </p>

            <p>{getLocalDisplayText('companyCodeToolTip')}</p>
          </Col>
        </Row>
        <Form onSubmit={validateCompanyCode} getApi={getFormApi} className={'mt-4'}>
          <Row>
            <Col md={'6'}>
              <TextInput field='companyCode' label={getLocalDisplayText('companyCodeLabel')} validate={validateRequired} validateOnBlur={true} data-testid={'companyCode'}/>
            </Col>
          </Row>
          <Button aria-label={'validate company code'} className={'mt-3 mb-2'} data-testid={'validateCodeButton'} buttonType={'primary'} id={'validateButton'} type={'submit'}>
            {getLocalDisplayText('validateButton')}
          </Button>
          <Button aria-label={'return to login'} buttonType={'secondary'} className={'mt-3 mb-2'} data-testid={'returnToLoginButton'} onClick={handleCancelClick} type={'button'}>
            {getLocalDisplayText('cancelButtonLabel')}
          </Button>
        </Form>
      </Container>
    </>);
};
export default CompanyCode;
