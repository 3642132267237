export const ConditionCircumstanceText = {
    accommTitle: {
        english: 'Condition or circumstances',
        spanish: 'Condición o circunstancias',
    },
    formLabels: {
        receivedDate: {
            english: 'What is the date the first accommodation request related to this condition was submitted by the Employee?',
            spanish: '¿Cuál es la fecha en que el empleado envió por primera vez la solicitud de adaptación relacionada con esta condición?'
        },
        conditions: {
            english: 'What is the condition that necessitates this accommodation request?',
            spanish: '¿Cuál es la condición que hace necesaria esta solicitud de adaptación?',
        },
        workRelated: {
            english: 'Did this condition occur as a result of a work-related injury/illness?',
            spanish: '¿Ocurrió esta condición como resultado de una lesión o una enfermedad relacionadas con el trabajo?',
        },
        pleaseNote: {
            english: 'Please note: ',
            spanish: 'Nota: ',
        },
        workRelatedMessage: {
            english: `a work-related injury or illness needs to be reported to your employer.If this has not been completed, please follow your employer's work-related claim reporting policy to report the incident.`,
            spanish: `debe reportar a su empleador las lesiones o enfermedades relacionadas con el trabajo. Si no lo ha hecho, siga la política de su empleador sobre reporte de reclamos relacionados con el trabajo para reportar el incidente.`,
        },
        reportedRestrictonOrLimitationEE: {
            english: 'How does your condition limit and/or restrict your ability to perform the essential and non-essential functions/responsibilities of your job?',
            spanish: '¿Cómo limita o restringe la condición su capacidad para cumplir las funciones o responsabilidades esenciales y no esenciales de su trabajo?',
        },
        reportedRestrictonOrLimitationER: {
            english: `How does your Employee's condition limit and/or restrict their ability to perform the essential and non-essential functions/responsibilities of their job?`,
            spanish: '¿Cómo limita o restringe su condición su capacidad para hacer las funciones/responsabilidades esenciales y no esenciales de su trabajo?',
        },
        availableDocumentation: {
            english: 'Do you have any available documentation you can provide?',
            spanish: '¿Tiene alguna documentación que pueda presentar?',
        },
    },
    documentUploadExample: {
        english: `Please upload any available documentation stating the condition requiring the need for this accommodation request and the specific accommodation being requested. An example could be a doctor's note indicating specific restrictions and limitations requiring the need for the accommodation request.`,
        spanish: `Cargue cualquier documentación en la que se mencione la condición que crea la necesidad de esta solicitud de adaptación y la adaptación específica que se solicita. Un ejemplo podría ser una nota del médico que donde se mencionen las restricciones y limitaciones específicas que hacen necesaria la solicitud de adaptación.`,
    },
    documentUploadOverview: {
        english: `When uploading documents, you are responsible for maintaining a copy of all documents provided to Lincoln Financial Group. A maximum of ten documents may be uploaded at a time. Supported file types that may be uploaded include: gif, jpg, pdf, pptx, tif, tiff, xIs, xlsx, doc, docx.`,
        spanish: `Cuando cargue documentos, usted será responsable de conservar una copia de todos los documentos entregados a Lincoln Financial Group. Se puede cargar un máximo de diez documentos a la vez. Se admite la carga de estos tipos de archivos: gif, jpg, pdf, pptx, tif, tiff, xIs, xlsx, doc, docx.`,
    }
};
