import React from 'react';
import { AuthenticationContext } from '../providers/AuthenticationProvider';
import { getDisplayName } from '../utils/reactUtils';
const withAuthentication = (WrappedComponent) => {
    class WithAuthentication extends React.Component {
        render() {
            return (<AuthenticationContext.Consumer>
          {(contexts) => <WrappedComponent {...this.props} authenticationContext={contexts}/>}
        </AuthenticationContext.Consumer>);
        }
    }
    // @ts-ignore
    WithAuthentication.displayName = `WithAuthentication(${getDisplayName(WrappedComponent)})`;
    return WithAuthentication;
};
export default withAuthentication;
