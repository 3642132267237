export const ElectionConstants = {
    heading: {
        english: 'Add New Payment Method',
        spanish: 'Agregar nuevo método de pago',
    },
    routingNumber: {
        english: 'Routing Number',
        spanish: 'Número de ruta',
    },
    routingErrorMessage: {
        english: 'Please enter 9 digits for the Routing Number',
        spanish: 'Ingrese 9 dígitos para el número de ruta',
    },
    accountNumber: {
        english: 'Accont Number',
        spanish: 'Número de cuenta',
    },
    accountErrorMessage: {
        english: 'Please enter Account Number between 4-17 digits',
        spanish: 'Ingrese el número de cuenta entre 4 y 17 dígitos',
    },
    confirmAccountNumber: {
        english: 'Confirm Account Number',
        spanish: 'Confirmar número de cuenta',
    },
    confirmAccountError: {
        english: 'Account Number does not match. Please enter again',
        spanish: 'El número de cuenta no coincide. Por favor ingrese de nuevo',
    },
    requiredError: {
        english: 'This field is required.',
        spanish: 'Este campo es requerido.',
    },
    firstName: {
        english: 'First name',
        spanish: 'Nombre de pila',
    },
    lastName: {
        english: 'Last name',
        spanish: 'Apellido',
    },
    name: {
        english: "Allowed characters are: A-Z a-z . - ' and space",
        spanish: "Los caracteres permitidos son: A-Z a-z. - ' y espacio",
    },
};
