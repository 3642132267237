///customer/{customerId}/landingPageData
import axios from 'axios';
import useGet from '../hooks/useGet';
import { Experience, ExperienceCode } from '../types/Experience';
import { NotFoundError } from '../types/NotFoundError';
import { UnauthenticatedError } from '../types/UnauthenticatedError';
import { UnauthorizedError } from '../types/UnauthorizedError';
export const getCustomerMlpConfig = (experience, authHeader) => {
    return axios.get(`/config-service/services/v1/customer/mlpConfiguration`, {
        headers: { Authorization: authHeader },
        params: { experience: experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER },
    });
};
export const getCustomerQuickLinks = (experienceCode) => {
    // const experienceCode = experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER;
    return useGet({
        url: `/mlp-ui-service/services/v1/custom-buttons?experience=${experienceCode}`,
        noInitial: true,
    });
};
export const searchCustomers = async (searchString, authHeader) => {
    const response = await axios.get(`/config-service/services/v1/customer/search/${searchString}`, {
        headers: {
            Authorization: authHeader,
        },
    });
    const status = response.status;
    if (status === 200) {
        return response.data;
    }
    else if (status === 401) {
        throw new UnauthenticatedError('Invalid Access Token');
    }
    else if (status === 403) {
        throw new UnauthorizedError('User Unauthorized');
    }
    else if (status === 404) {
        throw new NotFoundError('Customer Account Not Found');
    }
    else {
        throw new Error('Unable to retrieve customers');
    }
};
export default {
    getCustomerMlpConfig,
};
