import React from 'react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { GrayTextDiv } from 'src/components/Formatting/StyledComponents';
import styled from 'styled-components';
const StyledButton = styled('button') `
  outline: none;
  border: none;
  font-size: 1rem;
  background: inherit;
  color: #23669a;
  flex: 1;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;
// export const EditDisplayField: FC<{onClick?: (e: SyntheticEvent) => void}> = ({onClick, ...rest}) => {
//   return (
//     <StyledButton onClick={onClick} {...rest}>
//       <Icon color="secondary" className="mr-2" name="edit" />
//       Edit
//     </StyledButton>
//   )
// }
const DisplayField = ({ label, value, style, ...rest }) => {
    return (<GrayTextDiv style={style} {...rest}>
      {label}
      <div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 350, fontSize: '1.125rem', borderBottom: `2px solid ${themes.secureSite.palette.grayLightest}` }}>
        {/* empty string in case of empty value helps keep vertical height for the field */}
        {value}
        &nbsp;
      </div>
      {/* <GraySpacerDiv style={{ marginTop: value ? undefined : '1.3rem' }} /> */}
    </GrayTextDiv>);
};
export default DisplayField;
