import React from 'react';
const ContactUsText = {
    heading: {
        english: 'Contact Us',
        spanish: 'Contactos'
    },
    subHeading: {
        english: 'We\'re here to help with your plan needs.',
        spanish: 'Estamos aquí para ayudarlo con las necesidades de su plan'
    },
    requestHeading: {
        english: 'Looking for information about an existing request?',
        spanish: '¿Busca información sobre una solicitud existente?'
    },
    requestText: {
        english: 'Whether evidence of insurability, an accommodation,  leave of absence, or  disability claim, you can find up-to-date information on our secure portal.  Once registered simply log in and view the status of your claim for up to date information. The contact information for your dedicated absence management team can also be found there.',
        spanish: 'Ya sea evidencia de asegurabilidad, un ajuste, una licencia o un reclamo por discapacidad, puede encontrar información actualizada en nuestro portal seguro.  Una vez registrado, simplemente inicie sesión y vea el estado de su reclamo para obtener información actualizada. La información de contacto de su equipo dedicado a la gestión de ausencias también se puede encontrar allí.',
    },
    claimHeading: {
        english: 'Are you a claimant looking to speak with a member of your absence management team?',
        spanish: '¿Es usted un reclamante que busca hablar con un miembro de su equipo de gestión de ausencias?'
    },
    stdText: {
        english: <><strong>Short term disability:</strong> Your Claim Specialist will contact you to gather additional details and answer any questions you may have.
            This time will give them the opportunity to review the information provided during intake.</>,
        spanish: <><strong>Discapacidad a corto plazo:</strong> Su especialista en reclamos se comunicará con usted para recopilar detalles adicionales y responder cualquier pregunta que pueda tener.</>
    },
    ltdText: {
        english: <><strong>Leave of absence:</strong> Your Leave Specialist will review your request and send you related correspondence within 5 business days via your preferred contact method, which you indicated during intake.</>,
        spanish: <><strong>Excedencia:</strong> Su especialista en licencias revisará su solicitud y le enviará la correspondencia relacionada dentro de los 5 días hábiles a través de su método de contacto preferido, que indicó durante la admisión.</>,
    },
    registrationHelpHeader: {
        english: 'Need help with portal login or registration?',
        spanish: '¿Necesita ayuda para iniciar sesión o registrarse en el portal?'
    },
    registrationHelpText: {
        english: 'Technical support for this website is available by phone or email.',
        spanish: 'El soporte técnico para este sitio web está disponible por teléfono o correo electrónico.',
    },
    callUs: {
        english: 'Call us at ',
        spanish: 'Llámanos al '
    },
    messageUs: {
        english: 'Message us at ',
        spanish: 'Envíanos un mensaje a '
    },
    phone: {
        english: 'Phone',
        spanish: 'Telephono'
    },
    monday: {
        english: 'Monday',
        spanish: 'Lunes'
    },
    thursday: {
        english: 'Thursday',
        spanish: 'Jueves'
    },
    friday: {
        english: 'Friday',
        spanish: 'Viernes'
    }
};
export default ContactUsText;
