import React from 'react';
import { Alert, Row, Col, Box, Heading, Spinner } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from 'status/hooks';
import useGet from '../../../../../hooks/useGet';
import titleCase from 'status/utilities/titleCase';
import formatDateMMDDYYY from 'status/utilities/formatDateMMDDYYY';
import formatPhone from 'status/utilities/formatPhone';
import titleCaseAll from 'status/utilities/titleCaseAll';
import { isUSAA } from './../../../../../packages/public/usaa/utils/CustomerUtils';

const cleanDateFormat = date => {
  if (!date) {
    return date;
  }
  return date.replace(/\+0000/, '');
};

const Error = () => (
  <Alert type='error'>
    <span>
      <strong>Error!</strong>&nbsp;Unable to load employee information
    </span>
  </Alert>
);

const CurrentCoverage = ({ emp }) => {
  return (
    <div className='mb-3'>
      <Box type='header'>Current Coverage</Box>
      <Box>
        <div>{emp.hasSTD ? 'Short Term Disability' : null}</div>
        <div>{emp.hasLTD ? 'Long Term Disability' : null}</div>
        <div>{emp.hasFML ? 'Family Medical Leave' : null}</div>
      </Box>
    </div>
  );
};

const EmployeeEligibilityDetails = ({ empId, empNum }) => {
  const { t } = useTranslation();
  const { userMeta } = useAuth0();
  const isUSAACustomer = isUSAA(userMeta.customerInContext.customerId);

  const { error, loading, data } = useGet({
    url: `/mlp-ui-service/api/employees/employee-overview/${empId}`,
    noInitial: !isUSAACustomer,
  });

  if (!isUSAACustomer) {
    return null;
  }

  if (loading) {
    return <Spinner id='ee-eligibility-details-spinner' />;
  }

  if (error && error.response && error.response.status !== 404) {
    return <Error />;
  }

  if (error && error.response && error.response.status === 404) {
    return null;
  }

  return (
    <>
      {!empNum ? (
        <>
          <div className='mb-4'>
            <Heading color={'accent1'}>
              {titleCase(data.firstName)} {titleCase(data.lastName)}
            </Heading>
          </div>
          <div className='mb-4'>
            <Heading sub={true}>
              <strong>Employee ID:</strong> {empId}
            </Heading>
          </div>
        </>
      ) : null}

      <div className='mb-4'>
        <Heading color={'accent1'} elemType={'h2'}>
          Employee Overview
        </Heading>
      </div>
      <Row className='mb-4'>
        <Col md={8}>
          <Row>
            <Col md={3}>
              <strong>{t('mailingAddressHeaderText')}:</strong>
            </Col>
            <Col md={9}>
              {data.line1Addr &&
                `${titleCaseAll(data.line1Addr)}, ${titleCaseAll(data.city)} ${data.state} ${data.zip}`}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{t('workEmailText')}:</strong>
            </Col>
            <Col md={9}>{data.workEmailAddress && data.workEmailAddress}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{t('phoneText')}:</strong>
            </Col>
            <Col md={9}>{data.phoneNum && formatPhone(data.phoneNum)}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{t('supervisorText')}:</strong>
            </Col>
            <Col md={9}>{titleCaseAll(data.supervisorName)}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{t('hoursWorkedInText')}:</strong>
            </Col>
            <Col md={9}>{data.hoursWorkedLast12Months && data.hoursWorkedLast12Months.toFixed()}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{t('serviceMonthsText')}:</strong>
            </Col>
            <Col md={9}>{data.serviceMonths && data.serviceMonths}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <strong>{titleCase(t('asOfText'))}:</strong>
            </Col>
            <Col md={9}>{data.asOfDate && formatDateMMDDYYY(new Date(cleanDateFormat(data.asOfDate)))}</Col>
          </Row>
        </Col>
        <Col md={4}>
          <CurrentCoverage emp={data} />
        </Col>
      </Row>
    </>
  );
};

export default EmployeeEligibilityDetails;
