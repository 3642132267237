import React, { useState } from "react";
import { Alert, Button, themes, ToolTip } from '@digitools/honest-react';
import { RIA_TEXT } from "../constants/RiaConstants";
import useTranslate from "src/hooks/useTranslate";
import { setCorrespondencePreference } from "../api/RiaApi";
import useAuthentication from "src/hooks/useAuthentication";
const CorrespondenceAlert = () => {
    const { authHeader } = useAuthentication();
    const { t } = useTranslate();
    const [togglePromp, setTogglePromp] = useState(false);
    const ToolTipText = (<>
            <strong>{t(RIA_TEXT.correspondenceTitle)}</strong>
            <p>
                <span>{t(RIA_TEXT.correspondenceToolTip)}</span>
            </p>
        </>);
    const toggleOnClick = () => {
        if (togglePromp === false) {
            const corrsInfo = {
                emailCorrespondence: true,
                suppressPopup: false,
            };
            setCorrespondencePreference(corrsInfo, authHeader());
            setTogglePromp(true);
        }
    };
    return (<Alert data-testid={'correspondence-preference'} type={'info'} aria-labelledby='correspondence-preference' alignIcon="flex-start" closeable={true}>
            {togglePromp ?
            <div style={{ marginRight: '0.25rem', marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {t(RIA_TEXT.correspondenceThankYou)}
                </div>
            :
                <div>
                    <div style={{ marginRight: '0.25rem', marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ marginLeft: 'auto', marginRight: '0.25rem', marginTop: 'auto', marginBottom: 'auto' }}>{t(RIA_TEXT.correspondencePrompt)}</span>
                            <div className='d-none d-md-flex'>
                                <div style={{ marginTop: 'auto', marginRight: '0.25rem', marginBottom: 'auto' }}>
                                    <ToolTip iconName={'question'} iconColor={'secondary'} showOnHover={false} toolText={ToolTipText} position={'bottom'} theme={themes.lincoln} style={{ pointerEvents: 'auto', width: 'auto', minWidth: '20rem' }}/>
                                </div>
                                <Button data-testid={"toggle-button"} buttonType={'primary'} size={"small"} onClick={toggleOnClick} style={{ marginLeft: '10rem', maxHeight: '50px', minWidth: '10rem' }}>Go Paperless</Button>
                            </div>
                    </div>
                    <div className='d-flex d-md-none' style={{ textAlign: 'center', flexDirection: 'column' }}>
                        <ToolTip iconName={'question'} iconColor={'secondary'} showOnHover={false} toolText={ToolTipText} position={'bottom'} theme={themes.lincoln} style={{ pointerEvents: 'auto', width: 'auto', minWidth: '20rem' }}/>
                        <Button data-testid={"toggle-button"} buttonType={'primary'} size={"small"} onClick={toggleOnClick} style={{ maxHeight: '50px', width: "100%", marginTop: '1rem' }}>Go Paperless</Button>
                    </div>
                </div>}
        </Alert>);
};
export default CorrespondenceAlert;
