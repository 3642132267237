export function getUserContactMethod(claimantData) {
    const userContactMethod = claimantData?.selectedpreferredmethod;
    switch (userContactMethod) {
        case 'E':
            return 'email';
        case 'F':
            return 'fax';
        case 'M':
            return 'mail';
        default:
            return '';
    }
}
