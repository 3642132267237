export const BDM_COUNTRIES = [
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'CANADA' },
    { label: 'Other Countries', value: 'OTHERCOUNTRIES' },
];
export const BDM_COUNTRIES_ES = [
    { label: 'Estados Unidos', value: 'USA' },
    { label: 'Canadá', value: 'CANADA' },
    { label: 'Otros País', value: 'OTHERCOUNTRIES' },
];
export const BDM_STATES = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];
export const BDM_PROVINCES = [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland-Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
];
export const BDM_BENEFICIARY_TYPES = [
    { label: 'Person', value: 'PERSON' },
    { label: 'Estate', value: 'ESTATE' },
    { label: 'Charity/Organization', value: 'CHARITY' },
    { label: 'Trust', value: 'TRUST' },
];
export const BDM_BENEFICIARY_TYPES_ES = [
    { label: 'Persona', value: 'PERSON' },
    { label: 'Patrimonio', value: 'ESTATE' },
    { label: 'Caridad/Organización', value: 'CHARITY' },
    { label: 'Fideicomiso', value: 'TRUST' },
];
export const SSN = 'SSN';
export const BOTH = 'Both';
export const EMP_ID = 'EMP ID';
export const EMPLOYEE_ID_TEXT = 'EmployeeIDText';
export const EMPLOYEE_TEXT = 'EmployeeText';
export const PRIMARY = 'PRIMARY';
export const CONTINGENT = 'CONTINGENT';
export const BDM_BENEFICIARIES_GA_ROUTE = 'bdm/beneficiaries';
export const BDM_BENEFICIARIES_ADD_GA_ROUTE = 'bdm/beneficiaries/addmodal';
export const BDM_CONFIRMATION_GA_ROUTE = 'bdm/beneficiaries/confirmation';
export const BDM_FMI_GA_ROUTE = 'bdm/fmi';
