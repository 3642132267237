export const SAVE_USER_PERMISSION = 'SAVE_USER_PERMISSION';
export const adminPermissionReducer = (state, action) => {
    switch (action.type) {
        case SAVE_USER_PERMISSION:
            return saveUserPermissions(action.user, action.clearAll, state);
        default:
            return state;
    }
};
const saveUserPermissions = (user, clearAll, state) => { };
