import axios from 'axios';
const getHeaderFooterInfos = (authHeader) => (experience, language, isV2) => axios.get('/mlp-ui-service/services/v1/header-footer-infos/default', {
    params: { experience, language, v2: isV2 },
    headers: {
        Authorization: authHeader,
    },
});
export const postCopyPermisssions = async (copyFromUserId, copyToUserId, authHeader) => {
    const data = {
        copyFromUserId: copyFromUserId,
        copyToUserId: copyToUserId,
    };
    return axios.post('/mlp-ui-service/api/employers/copy-permissions', data, {
        headers: {
            Authorization: authHeader,
        },
    });
};
export const getCustomerLogo = async (authHeader, customerId) => {
    const response = await axios.get(`/config-service/services/v1/customer/logo/${customerId}`, {
        responseType: 'blob',
        headers: {
            Authorization: authHeader,
        }
    });
    return response;
};
export default (authHeader) => ({
    getHeaderFooterInfos: getHeaderFooterInfos(authHeader),
});
