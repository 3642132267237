import React from 'react';
import getDisplayText from '../../../../utils/languageDisplay';
import { LoginDisplayTextEn } from '../i18n/LoginDisplayTextEn';
import { LoginDisplayTextEs } from '../i18n/LoginDisplayTextEs';
import useExperience from '../../../../hooks/useExperience';
import { Button, Row, Col, Modal, Heading } from '@digitools/honest-react';
import './EmailVerificationModal.scss';
import { EEMigrationLoginText } from '../i18n/EEMigrationLoginText';
import useTranslate from 'src/hooks/useTranslate';
const EmailVerificationHelperModal = (props) => {
    const { trackEmailVerificationHelp, enableEmployeeMigration } = props;
    const { language } = useExperience();
    const { t } = useTranslate();
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, LoginDisplayTextEn, LoginDisplayTextEs, language]);
    const RenderOpenModalButton = ({ showModal }) => {
        const handleOnClick = (e) => {
            e.preventDefault();
            trackEmailVerificationHelp();
            showModal();
        };
        return (<div data-public={true}>
        {t(EEMigrationLoginText.emailVerificationHelp)}{' '}
        <Button aria-label={'help with email verification'} buttonType={'text'} className={'p-0'} onClick={handleOnClick} data-testid={'email-verification-btn'} style={{ fontWeight: 'normal', border: 'none' }}>
          {t(EEMigrationLoginText.emailVerificationLink)}
        </Button>
      </div>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            hideModal();
        };
        return (<Button aria-label={'close modal'} data-testid={'modal-close-btn'} buttonType={'primary'} onClick={handleOnClick}>
        {getLocalDisplayText('close')}
      </Button>);
    };
    return (<Modal data-public={true} hasCloseIcon={true} headerText={getLocalDisplayText('emailVerificationModalTitle')} modalOpenRenderButton={RenderOpenModalButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} footerContentPosition={'center'}>
      <div data-public={true} auto-focus={'true'} aria-modal={true} aria-labelledby={'troubleshooting email verification issues'} aria-describedby={'modal-heading'} role={'document'}>
        <Row data-testid={'email-verification-modal'}>
          <Col className={'d-flex ml-4'}>
            <p style={{ marginTop: '0rem' }}>{getLocalDisplayText('emailVerificationHeader')}</p>
          </Col>
        </Row>
        <Row>
          <Col className={'d-flex ml-4'}>
            <div className={'numberCircle'}>1</div>
          </Col>
          <Col>
            <Heading id={'modal-heading'} elemType={'h2'} elemStyle={'h2'} className={'mb-0 d-flex align-middle'} style={{ color: '#822433' }}>
              <div>{getLocalDisplayText('emailVerificationSection1Title')}</div>
            </Heading>
            <p style={{ marginTop: '0rem' }}>{getLocalDisplayText('emailVerificationSection1Body')}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className={'d-flex ml-4'}>
            <div className={'numberCircle'}>2</div>
          </Col>
          <Col>
            <Heading elemType={'h2'} elemStyle={'h2'} className={'mb-0 d-flex align-middle'} style={{ color: '#822433' }}>
              <div> {getLocalDisplayText('emailVerificationSection2Title')}</div>
            </Heading>
            <p style={{ marginTop: '0rem' }}>{getLocalDisplayText('emailVerificationSection2Body')}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className={'d-flex ml-4'}>
            <div className={'numberCircle'}>3</div>
          </Col>
          <Col>
            <Heading elemType={'h2'} elemStyle={'h2'} className={'mb-0 d-flex align-middle'} style={{ color: '#822433' }}>
              <div>{getLocalDisplayText('emailVerificationSection3Title')}</div>
            </Heading>
            <p style={{ marginTop: '0rem' }}>{getLocalDisplayText('emailVerificationSection3Body')}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className={'d-flex ml-4'}>
            <div className={'numberCircle'}>4</div>
          </Col>
          <Col>
            <Heading elemType={'h2'} elemStyle={'h2'} className={'mb-0 d-flex align-middle'} style={{ color: '#822433' }}>
              <div>{getLocalDisplayText('emailVerificationSection4Title')}</div>
            </Heading>
            <p style={{ marginTop: '0rem' }}>{getLocalDisplayText('emailVerificationSection4Body')}</p>
          </Col>
        </Row>
      </div>
    </Modal>);
};
export default EmailVerificationHelperModal;
