import { Button, Col, Row } from '@digitools/honest-react';
import React from 'react';
import { useFormApi } from 'informed';
import CancelButton from '../CancelButton';
import { useNavigate } from 'react-router-dom';
const ReportFieldFooter = ({ onFieldSelection, fieldPageInfo, reportFilterCategory, }) => {
    const navigate = useNavigate();
    const reportFieldSectionLabels = [
        'Employee Personal Information',
        'Employee Work Information',
        'Claim Dates',
        'Claim Details',
        'Leave Dates',
        'Leave Details',
        'Accommodation Dates',
        'Accommodation Details',
    ];
    const formApi = useFormApi();
    const updateStore = (url) => () => {
        const panelDateAndDetail = selectFilterCategory(reportFilterCategory, fieldPageInfo);
        const personalInfoPayload = createPayload(fieldPageInfo.personalInfoFields);
        const workInfoPayload = createPayload(fieldPageInfo.workInfoFields);
        const datePayload = createPayload(panelDateAndDetail[0]);
        const detailPayload = createPayload(panelDateAndDetail[1]);
        const payload = {
            reportFilterCategory,
            personalInfoFields: personalInfoPayload,
            workInfoFields: workInfoPayload,
            dateInfoFields: datePayload,
            detailInfoFields: detailPayload,
        };
        onFieldSelection(payload);
        navigate(url);
    };
    const createPayload = (infoField) => {
        return infoField.map(fields => ({
            field: fields.field,
            status: formApi.getValue(fields.field),
        }));
    };
    const selectFilterCategory = (reportFilterCategorySelected, fieldPageInfoSelected) => {
        if (reportFilterCategorySelected === 'Claim') {
            return [
                fieldPageInfoSelected.claimDatesFields,
                fieldPageInfoSelected.claimDetailFields,
                reportFieldSectionLabels[2],
                reportFieldSectionLabels[3],
            ];
        }
        if (reportFilterCategorySelected === 'Leave') {
            return [
                fieldPageInfoSelected.leaveDatesFields,
                fieldPageInfoSelected.leaveDetailFields,
                reportFieldSectionLabels[4],
                reportFieldSectionLabels[5],
            ];
        }
        if (reportFilterCategorySelected === 'Accommodation Services') {
            return [
                fieldPageInfoSelected.accommodationDatesFields,
                fieldPageInfoSelected.accommodationDetailFields,
                reportFieldSectionLabels[6],
                reportFieldSectionLabels[7],
            ];
        }
    };
    return (<div style={{ marginTop: '20px', marginBottom: '1.5rem' }}>
      <Row>
        <Col>
          <Button onClick={updateStore('/create-my-report')} buttonType={'secondary'}>
            &larr;Back
          </Button>
          <Button buttonType={'primary'} style={{ float: 'right' }} onClick={updateStore('/create-my-report/run-my-report')}>
            Continue
          </Button>
          <div style={{ float: 'right' }}>
            <CancelButton url={'/create-my-report'}/>
          </div>
        </Col>
      </Row>
    </div>);
};
export default ReportFieldFooter;
