import React from 'react';

import { Icon } from '@digitools/honest-react';
import { useExperience, useAuth0 } from 'status/hooks';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { isTeamView } from './../../../../../packages/public/usaa/utils/CustomerUtils';

const ReturnToViewTeamMember = ({ empId }) => {
  const { userMeta } = useAuth0();
  const { experience } = useExperience();
  const teamView = isTeamView(userMeta);
  const { t } = useTranslation();
  const to = '/teamview';
  return experience === 'ER' && teamView && empId ? (
    <>
      <div className='d-flex align-items-center' style={{ marginBottom: '2rem', marginTop: '1rem' }}>
        <Icon name='long-arrow-left-regular' size='small' className='mr-2' />
        <ReactRouterLink to={to}>{`${t('returnToViewTeamMember')}`}</ReactRouterLink>
      </div>
    </>
  ) : null;
};

export default ReturnToViewTeamMember;
