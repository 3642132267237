import { passwordFormatInvalidMessageMap, insecurePasswordMessageMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isPasswordSecure, isPasswordFormatValid, isLfgPasswordFormatValid } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidPassword(isRequired = false, isLfg = false) {
    const errorMessageMap = [passwordFormatInvalidMessageMap, insecurePasswordMessageMap];
    const validators = isLfg ? [isLfgPasswordFormatValid, isPasswordSecure] : [isPasswordFormatValid, isPasswordSecure];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: 'Password', [Language.SPANISH]: 'Contraseña' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidPassword;
