const leaveProgramType = leaveDate => {
  const programCode = leaveDate.program && leaveDate.program.trim();
  const leaveType = leaveDate.leaveType && leaveDate.leaveType.trim();

  if (programCode == 'USFMLA') {
    return 'Federal';
  } else if (leaveType == 'OTH') {
    return 'Company Defined';
  } else if (programCode == null) {
    return 'N/A';
  } else {
    return 'State';
  }
};

export default leaveProgramType;
