import React, { useEffect, useState } from 'react';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import { BorderLessGrayBox, FlexRow } from '../../../../../../components/Formatting/StyledComponents';
import useTranslate from '../../../../../../hooks/useTranslate';
import ESignatureContent from './ESignatureContent';
import { Scope, useFormState } from 'informed';
import useEoiContext from '../../../../hooks/useEoiContext';
import ESignatureContentGeneric from './ESignatureContentGeneric';
import { trim } from 'lodash';
import { Heading } from '@digitools/honest-react';
const ESignature = (props) => {
    const { t } = useTranslate();
    const formState = useFormState();
    const [dependentSignatures, setDependentSignatures] = useState(false);
    const [employeeSignature, setEmployeeSignature] = useState(false);
    const [spouseSignature, setSpouseSignature] = useState(false);
    const [agentSignature, setAgentSignature] = useState(false);
    const [payrollSignature, setPayrollSignature] = useState(false);
    const { interviewAnswers } = useEoiContext();
    useEffect(() => {
        if (formState && formState.values) {
            if (formState.values.employee) {
                setEmployeeSignature(true);
            }
            if (formState.values.spouse) {
                setSpouseSignature(true);
            }
            if (formState.values.dependents) {
                setDependentSignatures(true);
            }
            if (formState.values.agent) {
                setAgentSignature(true);
            }
            if (formState.values.payrollSignature) {
                setPayrollSignature(true);
            }
        }
    }, [formState]);
    const payrollLabel = interviewAnswers && interviewAnswers.applicants && interviewAnswers.applicants.employee ? `${trim(interviewAnswers.applicants.employee.firstName)} ${trim(interviewAnswers.applicants.employee.lastName)}` : '';
    return (<div data-testid={'esignature'}>
    {(props.employee || props.spouse) &&
            <Box theme={themes.lincoln} style={{ borderColor: themes.lincoln.palette.grayLight, marginTop: '1rem' }}>
        <BorderLessGrayBox style={{ marginLeft: 0 }}>
          <Heading elemType={'h3'}>{t(ReviewAndSignText.eSignature)}</Heading>
        </BorderLessGrayBox>
        <p style={{ marginLeft: '1.0rem' }}>{t(ReviewAndSignText.eSignatureConsent)}</p>
        <FlexRow>
          {props.employee && <ESignatureContent type={'employee'} applicant={props.employee} valid={employeeSignature}/>}
          {props.spouse && <ESignatureContent type={'spouse'} applicant={props.spouse} valid={spouseSignature}/>}
        </FlexRow>
      </Box>}
    {props.dependents &&
            // @ts-ignore
            <Scope scope={'dependents'}>
        <Box theme={themes.lincoln} style={{ borderColor: themes.lincoln.palette.grayLight, marginTop: '1rem' }}>
          <BorderLessGrayBox style={{ marginLeft: 0 }}>
          <h4>{t(ReviewAndSignText.dependentsESignature)}</h4>
        </BorderLessGrayBox>
        <p style={{ marginLeft: '1.0rem' }}>{t(ReviewAndSignText.dependentESignatureDisclaimer)}</p>
        <FlexRow>
          {props.dependents.map((dependent, i) => {
                    return (<ESignatureContent key={i} type={'dependent'} applicant={dependent} 
                    // @ts-ignore
                    valid={dependentSignatures && formState.values && formState.values.dependents && formState.values.dependents[dependent.dependentId]}/>);
                })}
        </FlexRow>
        </Box>
      </Scope>}
    {props.payrollSignature &&
            <Box theme={themes.lincoln} style={{ borderColor: themes.lincoln.palette.grayLight, marginTop: '1rem' }}>
        <BorderLessGrayBox style={{ marginLeft: 0 }}>
          <h4>{t(ReviewAndSignText.eSignature)}</h4>
        </BorderLessGrayBox>
        <p style={{ marginLeft: '1.0rem' }}><strong>{t(ReviewAndSignText.payrollDeductionAuthConsent1)}</strong></p>
        <p style={{ marginLeft: '1.0rem' }}>{t(ReviewAndSignText.payrollDeductionAuthConsent2)}</p>
        <FlexRow>
          <ESignatureContentGeneric field='payrollDeductionSignature' label={payrollLabel} valid={payrollSignature} required={true}/>
        </FlexRow>
      </Box>}
    {props.agentSignature &&
            <Box theme={themes.lincoln} style={{ borderColor: themes.lincoln.palette.grayLight, marginTop: '1rem' }}>
        <p style={{ marginLeft: '1.0rem' }}>{t(ReviewAndSignText.eAgentSignatureConsent1)}</p>
        <BorderLessGrayBox style={{ marginLeft: 0 }}>
          <h4>{t(ReviewAndSignText.eAgentSignature)}</h4>
        </BorderLessGrayBox>
        <p style={{ marginLeft: '1.0rem' }}>{t(ReviewAndSignText.eAgentSignatureConsent2)}</p>
        <FlexRow>
          <ESignatureContentGeneric field='agentSignature' label={t(ReviewAndSignText.agentSignature)} valid={agentSignature} required={false}/>
        </FlexRow>
      </Box>}
    </div>);
};
export default ESignature;
