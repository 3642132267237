import { Language } from '../../../../types/Language';
import React from 'react';
const usernameHelperText = (<ul className={'mt-0'}>
    <li className={'mt-0'}>Usernames must be at least five characters in length</li>
    <li className={'mt-0'}>
      The username field accepts the following characters:
      <ul style={{
        listStyleType: 'circle',
        marginLeft: '.5rem',
        marginTop: '0px',
    }}>
        <li className={'mt-0'}>Alphanumeric characters (without accent marks) </li>
        <li className={'mt-0'}>The underscore (_) character </li>
        <li className={'mt-0'}>The plus (+) character </li>
        <li className={'mt-0'}>The minus (-) character</li>
        <li className={'mt-0'}>The period (.) character. A username cannot end with a period.</li>
        <li className={'mt-0'}>No other characters/symbols are allowed</li>
      </ul>
    </li>
  </ul>);
export const RegistrationDisplayTextEn = {
    language: Language.ENGLISH,
    pageHeading: 'Account Registration',
    registrationInfoTextCompanyCode: 'The one-time registration process is easy. Enter your company code and validate to continue registration.',
    registrationInfoTextRegistrationForm: 'Once you have registered, you will be able to use all available features of the secure Lincoln Financial Group Web portal, based on your employer’s configuration.',
    companyCodeError: 'Invalid company access code.',
    companyCodeInstructions: 'Enter your company code and validate to continue registration',
    companyCodeLabel: 'Company Code',
    companyCodeToolTip: 'For company code assistance, contact your HR or benefits representative. For further assistance call 1-800-431-2958.',
    employeeCompanyCodeError: 'Employees use your company code to register on www.LincolnFinancial.com',
    fieldRequired: 'This field is required.',
    validateButton: 'Validate',
    validationServiceError: 'The system has experienced an unexpected error. Your Company Code cannot be validated at this time. Please try again.',
    agreeToPrivacyStatement: 'I have read and agree to the following:',
    cancelButtonLabel: 'Cancel',
    cancelModalBodyText: 'If you cancel now, the information you entered will be lost and your user profile will not be created. As a result, you will not be able to login to the secured site. If you would like to leave this page, please select OK. If you wish to return to creating your user profile, please select Cancel.',
    cancelModalHeading: 'Are you sure?',
    closeButtonText: 'Close',
    duplicateEmailError: 'Email address already in use.',
    duplicateUserNameError: 'Username is unavailable.',
    emailErrorMessage: 'Email address is not valid.',
    emailToolTip: 'Please enter an email address you can access when you are away from work, so we may send you information about your account. If you need an email address, please visit www.yahoo.com or www.gmail.com. Your email address will not be shared or used for commercial purposes.',
    employerNameLabel: 'Employer Name',
    firstName: 'First Name',
    firstNameErrormessage: 'First name is not valid.',
    hide: 'Hide',
    lastName: 'Last Name',
    lastnameErrorMessage: 'Last name is not valid.',
    loginInfoHeading: 'Login Information',
    nonApprovedCountryError: 'This country is not approved for Two Factor Authentication. Please contact technical assistance.',
    okButton: 'OK',
    passwordEqualMessage: 'Passwords must match.',
    passwordErrorMessage: 'Enter a valid password',
    personalInfoHeading: 'Personal Information',
    phoneErrorMessage: 'This phone number is not valid.',
    phoneNumber: 'Preferred Phone Number',
    preferredEmailAddress: 'Preferred Email Address',
    privacyStatementAgreeRequired: 'You must agree to the Privacy Statement and Web Agreement to continue.',
    pwdLabel: 'Password',
    pwdusernameErrorMessage: 'Username and Password cannot be the same.',
    reenterPwdLabel: 'Re-enter Password',
    registerButtonLabel: 'Register',
    registerServiceError: 'The system has experienced an unexpected error. Your registration cannot be processed at this time. Please try again.',
    show: 'Show',
    termsAndConditionsHeader: 'View Privacy Statement and Web Agreement',
    usernameErrormessage: 'Enter a valid username',
    usernameEmailErrorMessage: 'The username or email you have entered is already associated to an account.',
    usernameHelperText,
    usernameLabel: 'Username',
    usernameToolTip: [
        'The username field accepts the following characters',
        '•Alphanumeric characters (without accent marks)',
        '•The underscore (_) character',
        '•The minus (-) character',
        '•The period (.) character. A username cannot end with a period.',
        '•No other characters/symbols are allowed',
    ],
    pwHelperText: [
        `Passwords must be at least eight characters in length`,
        `Passwords must contain characters from 3 or more of the following categories:`,
        `Uppercase characters (A to Z)`,
        `Lowercase characters (a to z)`,
        `Numeric digits (0 to 9)`,
        `A special character (such as !@#$%^&*)`,
        `Passwords must not contain a space`,
    ],
    pwHelperTextMigration: [
        `8-40 characters in length`,
        `At least 3 of the following:`,
        `Lower case letters (a-z)`,
        `Upper case letters (A-Z)`,
        `Numbers (0-9)`,
        `Special characters (ex.!@#)`,
        `Cannot contain your username`,
        `Cannot contain a space`,
    ],
    usernameHelperTextMigration: [
        `5-35 characters in length`,
        `At least 1 letter`,
        `Cannot contain special characters or spaces`,
        `Cannot be 9 consecutive numbers`,
    ],
    serviceError: 'The system has experienced an unexpected error. Your registration cannot be processed at this time.',
    weakPasswordErrorMessage: 'Please choose a stronger password.',
};
