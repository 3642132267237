export const currencyMask = (value) => {
    const formatNumber = (num) => {
        return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };
    if (value) {
        const numbers = value.replace(/[^\d.]/g, '');
        const decimalIndex = numbers.indexOf('.');
        if (decimalIndex > -1) {
            const decimals = numbers.substring(decimalIndex);
            if (decimals.length < 3) {
                return formatNumber(numbers);
            }
            else {
                return formatNumber(numbers.substring(0, decimalIndex + 3));
            }
        }
        else {
            return formatNumber(numbers);
        }
    }
    else {
        return '';
    }
};
export const dateMask = (value) => {
    if (value) {
        const numbers = value.replace(/[^\d]/g, '');
        if (numbers.length <= 2) {
            return numbers;
        }
        else if (numbers.length > 2 && numbers.length <= 4) {
            return numbers.substring(0, 2) + '/' + numbers.substring(2);
        }
        else if (numbers.length > 4 && numbers.length <= 12) {
            return numbers.substring(0, 2) + '/' + numbers.substring(2, 4) + '/' + numbers.substring(4);
        }
        else {
            return numbers.substring(0, 2) + '/' + numbers.substring(3, 5) + '/' + numbers.substring(5);
        }
    }
    else {
        return '';
    }
};
export const phoneNumberMask = (value) => {
    if (value) {
        const numbers = value.replace(/[^\d]/g, '');
        if (numbers.length <= 3) {
            return numbers;
        }
        else if (numbers.length <= 7) {
            return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
        }
        else if (numbers.length <= 10) {
            return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
        }
        else if (numbers.length === 11) {
            return `+${numbers.slice(0, 1)} (${numbers.slice(1, 4)}) ${numbers.slice(4, 7)}-${numbers.slice(7)}`;
        }
        else if (numbers.length === 12) {
            return `+${numbers.slice(0, 2)} ${numbers.slice(2)}`;
        }
        else if (numbers.length === 13) {
            return `+${numbers.slice(0, 3)} ${numbers.slice(3)}`;
        }
        else {
            return `+${numbers.slice(0, 3)} ${numbers.slice(3, 15)}`;
        }
    }
    else {
        return '';
    }
};
export const middleInitialMask = (value) => {
    if (value) {
        const text = value.replace(/[^[A-Za-z]/g, '');
        return text.substring(0, 1).toUpperCase();
    }
    else {
        return '';
    }
};
export const numberOnlyMask = (value) => {
    if (value) {
        const numbers = value.toString().replace(/[^\d]/g, '');
        return numbers.substring(0, 3);
    }
    else {
        return '';
    }
};
export const zipCodeMask = (value) => {
    if (value) {
        const numbers = value.replace(/[^\d]/g, '');
        if (numbers.length < 6) {
            return numbers;
        }
        else if (numbers.length < 9) {
            return `${numbers.substring(0, 5)}-${numbers.substring(5)}`;
        }
        else {
            return `${numbers.substring(0, 5)}-${numbers.substring(5, 9)}`;
        }
    }
    else {
        return '';
    }
};
export const canadianZipCodeMask = (value) => {
    if (value) {
        const zip = value.replace('-', '').toUpperCase();
        if (zip.length < 4) {
            return zip;
        }
        else if (zip.length < 6) {
            return `${zip.substring(0, 3)}-${zip.substring(3)}`;
        }
        else {
            return `${zip.substring(0, 3)}-${zip.substring(3, 6)}`;
        }
    }
    else {
        return '';
    }
};
export const occupationMask = (value) => {
    if (value) {
        const allowedCharacters = value.replace(/[^a-zA-Z0-9 ]/g, '');
        if (allowedCharacters.length <= 50) {
            return allowedCharacters;
        }
        else {
            return allowedCharacters.substring(0, 50);
        }
    }
    else {
        return '';
    }
};
export const employeeIdMask = (value) => {
    if (value) {
        const allowedCharacters = value.replace(/[^a-zA-Z0-9- ]/g, '');
        if (allowedCharacters.length <= 20) {
            return allowedCharacters;
        }
        else {
            return allowedCharacters.substring(0, 20);
        }
    }
    else {
        return '';
    }
};
