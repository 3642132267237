import React from 'react';
import { Button, Modal, themes } from '@digitools/honest-react';
//TODO: this icon below should not live in customer-ui and should be moved into honest
import ExternalLinkIcon from 'src/assets/external-link-alt-solid.svg';
import styles from './ExitModal.scss';
import useTranslate from '../../hooks/useTranslate';
export const ExitModalText = {
    headerText: {
        english: 'Exiting our site ',
        spanish: ' Saliendo de nuestro sitio',
    },
    text: {
        english: 'You are now leaving the Lincoln Financial Group',
        spanish: 'Está saliendo del sitio web de Lincoln Financial Group',
    },
    textForThankyou: {
        english: 'Thank you for visiting the Lincoln Financial Group website.Come back and visit us often.',
        spanish: 'Gracias por visitar el sitio web de Lincoln Financial Group. Vuelva y visítenos con frecuencia.',
    },
    textForRequest: {
        english: 'You have requested the website of another organization not associated with Lincoln Financial Group.',
        spanish: 'Ha solicitado el sitio web de otra organización no asociada con Lincoln Financial Group.',
    },
    bodyText: {
        english: 'Lincoln Financial Group is not responsible for the content of the site you are about to visit.',
        spanish: 'Lincoln Financial Group no es responsable del contenido del sitio que está a punto de visitar.',
    },
    cancelButtonText: {
        english: 'Cancel',
        spanish: 'Cancelar',
    },
    countinueButtonText: {
        english: 'Continue',
        spanish: 'Continuar',
    },
    website: {
        english: ' website.',
        spanish: '.',
    },
};
const ExitModal = ({ setControl, navigationCallback }) => {
    const { t } = useTranslate();
    const RenderCloseButton = ({ hideModal }) => {
        const handleCancel = () => {
            hideModal();
        };
        return (<Button type='button' data-testid='hideModal' onClick={handleCancel} className={styles.cancelBtn}>
        {t(ExitModalText.cancelButtonText)}
      </Button>);
    };
    const RenderContinueButton = ({ hideModal }) => {
        const handleContinue = () => {
            /* Call the navigation Callback from RouteCheckProvider */
            navigationCallback();
            hideModal();
        };
        return (<div className={styles.wrapper}>
        <Button type='button' buttonType={'primary'} data-testid='hideModal' onClick={handleContinue} className={styles.continueBtn}>
          {t(ExitModalText.countinueButtonText)}
          <span className={styles.wrapper}>
            <ExternalLinkIcon className={styles.externalIcon}/>
          </span>
        </Button>
      </div>);
    };
    //FIXME: headerText can be left undefined and if no headerComponent is passed it will create an empty H1. change modal to have a multi-type header prop that cannot be undefined
    return (<Modal data-testid={'exitModal'} getControl={setControl} theme={themes.secureSite} footerPrimaryRenderButton={RenderContinueButton} footerSecondaryRenderButton={RenderCloseButton} headerComponent={<>
          {/* //FIXME: violates header order - need to redo this modal its out of date with company names
            <h4 className={styles.modalheaderText}>
              <b>d{t(ExitModalText.headerText)}</b>
            </h4> */}
          <h1 className={styles.modalheaderTitle}>
            {t(ExitModalText.text)}
            <span className={styles.supersetText}>&#174;</span>
            {t(ExitModalText.website)}
          </h1>
        </>}> 
      <div className={styles.wrapperContainer}>
        <p className={styles.modalcontent}>
          {' '}
          {t(ExitModalText.textForThankyou)}
          <br />
          <br />
          {t(ExitModalText.textForRequest)}
          <br />
          {t(ExitModalText.bodyText)}
        </p>
      </div>
    </Modal>);
};
export default ExitModal;
