import { Box, Button, Col, Loader, Form, Heading, Icon, Row, Link } from '@digitools/honest-react';
import React, { useEffect, useReducer, useState } from 'react';
import useExperience from '../../../hooks/useExperience';
import getDisplayText from 'src/utils/languageDisplay';
import * as actions from 'src/packages/userMigration/actions';
import reducer, { initialState } from 'src/packages/userMigration/reducer';
import { MigrateDisplayText } from 'src/packages/userMigration/i18n/MigrateDisplayText';
import { migrateUser } from 'src/api/AuthServiceApi';
import Password from 'src/packages/public/registration/components/password/Password';
import useTranslate from 'src/hooks/useTranslate';
import { RegistrationDisplayTextEn } from 'src/packages/public/registration/language/RegistrationDisplayTextEn';
import { RegistrationDisplayTextEs } from 'src/packages/public/registration/language/RegistrationDisplayTextEs';
import TermsAndConditions from 'src/packages/public/registration/components/termsAndConditions/TermsAndConditions';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import styled from 'styled-components';
import Username from 'src/packages/userMigration/components/Username';
import { Alert } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
import { StyledLCNText } from 'src/components/styledComponents';
const StyledBox = styled(Box) `
  {
    padding: 3rem;
  }
`;
const UserMigrationContainer = ({ setHasMigrationError, hasMigrationError, setHasMigrationResponseCode, setHasUsernameReset, hasUsernameReset, initialUsername, setInitialUsername, migrationTokenLookupKey, }) => {
    const { t } = useTranslate();
    const { language } = useExperience();
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [fieldError, dispatch] = useReducer(reducer, initialState);
    const [newUsernameClashed, setNewUsernameClashed] = useState(false);
    const [passwordFailText, setPasswordFailText] = useState('');
    const pwdLabel = t(MigrateDisplayText.newPassword);
    const reenterPwdLabel = t(MigrateDisplayText.newPasswordRe);
    const { trackEvent, trackView } = useTealium();
    const navigate = useNavigate();
    const getLocalDisplayText = (key) => {
        return getDisplayText.apply({}, [key, RegistrationDisplayTextEn, RegistrationDisplayTextEs, language]);
    };
    const clearError = () => {
        dispatch(actions.clear());
    };
    const trackLearnMoreEvent = (eventName) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: TEALIUM_EVENT.EVENT_NAME.LEARN_MORE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: hasUsernameReset ? TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.USERNAME : TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.UPDATE_DETAILS,
        });
    };
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MIGRATION,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE,
            page_l5: (hasUsernameReset ? TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.USERNAME : TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.UPDATE_DETAILS),
            user_audience: TEALIUM_PAGE_VIEW.USER_AUDIENCE.EMPLOYEE,
        });
    }, [hasUsernameReset]);
    const handleForm = async ({ pwd, idmUsername }) => {
        setIsPageLoading(true);
        setNewUsernameClashed(false);
        setPasswordFailText('');
        if (hasUsernameReset) {
            setInitialUsername(idmUsername);
        }
        migrateUser(migrationTokenLookupKey, pwd, idmUsername)
            .then(response => {
            window.scrollTo(0, 0);
            navigate(`/migrate/success`, { state: { hasUsernameReset } });
            setHasMigrationResponseCode(response.data.resultCode);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: TEALIUM_EVENT.EVENT_NAME.MIGRATE_MY_ACCOUNT,
                event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                event_version: (hasUsernameReset ? TEALIUM_EVENT.EVENT_VERSION.USERNAME : TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS),
            });
        })
            .catch(error => {
            const errorCode = error?.response?.data?.errorCode || '';
            const errorMsg = error?.response?.data?.message || '';
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: errorCode,
                event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                event_version: TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS,
            });
            let passwordError = false;
            switch (errorCode.toUpperCase()) {
                case 'PASSWORD_DICTIONARY_ERROR':
                    setPasswordFailText(t(MigrateDisplayText.updatePassword.passwordDictionaryError));
                    passwordError = true;
                    break;
                case 'MIGRATION_INVALID_PASSWORD':
                case 'PASSWORD_NO_USER_INFO_ERROR':
                    setPasswordFailText(t(MigrateDisplayText.updatePassword.passwordNoUserInfoError));
                    passwordError = true;
                    break;
                case 'PASSWORD_STRENGTH_ERROR':
                    setPasswordFailText(t(MigrateDisplayText.updatePassword.passwordStrengthError));
                    passwordError = true;
                    break;
                default:
                    break;
            }
            if (errorCode.toUpperCase() === 'USERNAME_CLASH') {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: 'username not available. Please try again',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                    event_version: TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS,
                });
                setNewUsernameClashed(true);
                setHasUsernameReset(true);
                setHasMigrationError(false);
                setHasMigrationResponseCode(error?.response?.data?.errorCode.toUpperCase());
            }
            else if (errorCode.toUpperCase() === 'EMAIL_CLASH') {
                setHasMigrationError(false);
                const emailClashMigrationScreen = window.location.href + '&email-clash=true';
                window.location.replace(emailClashMigrationScreen);
            }
            else {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: errorMsg,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
                    event_version: TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS,
                });
                setHasMigrationError(!passwordError);
                setHasMigrationResponseCode(error.errorCode);
            }
        })
            .finally(() => {
            setIsPageLoading(false);
        });
    };
    return (<Loader id='user-migration-container-spinner' data-testid={'loading-indicator'} isShown={isPageLoading} loadingText={t(MigrateDisplayText.loadingText)}>
      <Row className={'mt-3'}>
        <Col xl={5} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledBox>
            <Heading elemType={'h1'} elemStyle={'h2'} color={'primary'} className={'mb-2'}>
              {t(MigrateDisplayText.heading)}
            </Heading>
            <p>
              {t(MigrateDisplayText.infoParagraph)}
              <Link href='public/learn-more' target='_blank' onClick={() => trackLearnMoreEvent('Learn More Link')}>
                {t(MigrateDisplayText.learnMore)}
                <Icon size='xs' style={{ marginLeft: '.5rem' }} color='secondary' name='chevron-right-solid'/>
              </Link>
            </p>
            <p>{t(MigrateDisplayText.infoSubParagraph)}</p>
            {newUsernameClashed && initialUsername && (<Alert data-testid='migrate-username-clash-error' type={'error'}>
                <strong>Error!</strong>&nbsp;{t(MigrateDisplayText.updateUsername.invalidUsername)}
              </Alert>)}
            {passwordFailText && (<Alert data-testid='migrate-password-error' type={'error'}>
                <strong>Error!</strong>&nbsp;{passwordFailText}
              </Alert>)}
            <Form autoComplete='off' className={'mt-4'} onSubmit={handleForm}>
              {hasUsernameReset ? (<Username hasMigrationError={hasMigrationError} setHasMigrationError={setHasMigrationError} initialUsername={initialUsername} getLocalDisplayText={getLocalDisplayText}/>) : (<Row className={'d-none'}/>)}
              <Password marginNone={true} enableEmployeeMigration={'true'} language={language} getLocalDisplayText={getLocalDisplayText} isLfg={true} clearError={clearError} colSize={12} pwdLabel={pwdLabel} reenterPwdLabel={reenterPwdLabel} data-testid={'migrate-password-component'}/>
              <TermsAndConditions language={language} getLocalDisplayText={getLocalDisplayText} isMigrateModal={true}/>{' '}
              <Button data-public={true} buttonType={'primary'} type={'submit'} className={'mt-3 d-block w-100'} data-testid={'migrate-submit-button'} style={{ lineHeight: '20px' }}>
                {t(MigrateDisplayText.migrateButton)}
              </Button>
            </Form>
          </StyledBox>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col xl={5} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledLCNText>{t(MigrateDisplayText.LCN)}</StyledLCNText>
        </Col>
      </Row>
    </Loader>);
};
export default UserMigrationContainer;
