import React from 'react';
import { Heading, Row, Col } from '@digitools/honest-react';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import { DocumentUploadDisplayTextEn } from '../../../../components/documentUpload/language/DocumentUploadDisplayTextEn';
import { DocumentUploadDisplayTextEs } from '../../../../components/documentUpload/language/DocumentUploadDisplayTextEs';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
import CliDocumentUpload from '../../components/CliDocumentUpload';
import { Experience } from 'src/types/Experience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
const ReviewDocumentUpload = () => {
    const { language, experience } = useExperience();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const documentUploadLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? `${languageFile.headerText} ${languageFile.yourAbsenceEE}` : `${languageFile.headerText} ${languageFile.yourAbsenceER}`);
    const { absenceData } = useCliDataContext();
    const leaveType = absenceData?.leaveType;
    const leaveCategory = absenceData?.leaveCategory;
    let cliProductName = null;
    if ((leaveType === "OWN" || leaveType === "MAT") && leaveCategory === "C") {
        cliProductName = "STD";
    }
    else {
        cliProductName = "LEAVE";
    }
    return (<div data-testid="DocumentUploadReview">
            <BorderLessGrayBox>
                <Heading elemType='h3' elemStyle='h4'>{documentUploadLabel}</Heading>
            </BorderLessGrayBox>
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <CliDocumentUpload productName={cliProductName} displayLabel={true} pageTitle={'Claim Leave Intake'}/>
                </Col>
            </Row>
        </div>);
};
export default ReviewDocumentUpload;
