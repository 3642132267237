import React, { useEffect, useState } from 'react';
import useAuthentication from '../hooks/useAuthentication';
import { INITIAL_CONFIG_DATA } from '../types/BdmLciConfigContextTypes';
import ServiceUnavailable from '../components/ServiceUnavailable/ServiceUnavailable';
import { connect } from 'react-redux';
import { fetchBdmConfig, fetchLciConfig } from '../actions';
import PageLoader from '../components/PageLoader/PageLoader';
export const BdmLciConfigContext = React.createContext(INITIAL_CONFIG_DATA);
export const BdmLciConfigProvider = ({ children, isLciConfigRequired = true, isBdmConfigRequired = true, lciConfig, lciError, lciLoading, bdmConfig, bdmError, bdmLoading, dispatch, }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const { authHeader } = useAuthentication();
    useEffect(() => {
        if (isLciConfigRequired && !lciConfig && !lciLoading) {
            fetchLciConfig(authHeader())(dispatch);
        }
        if (isBdmConfigRequired && !bdmConfig && !bdmLoading) {
            fetchBdmConfig(authHeader())(dispatch);
        }
    }, []);
    useEffect(() => {
        const error = (lciError && isLciConfigRequired) || (bdmError && isBdmConfigRequired);
        const loading = lciLoading || bdmLoading;
        setError(error);
        setLoaded(!loading);
    }, [lciLoading, lciError, bdmLoading, bdmError, isBdmConfigRequired, isLciConfigRequired]);
    let config;
    if (isLciConfigRequired && isBdmConfigRequired && lciConfig && bdmConfig) {
        config = {
            ...bdmConfig,
            ...lciConfig,
        };
    }
    else if (isLciConfigRequired && lciConfig) {
        config = {
            ...lciConfig,
        };
    }
    else if (isBdmConfigRequired && bdmConfig) {
        config = {
            ...bdmConfig,
        };
    }
    else {
        config = INITIAL_CONFIG_DATA;
    }
    return (<BdmLciConfigContext.Provider value={config}>
      {error ? (<ServiceUnavailable data-public={true}/>) : loaded ? (children) : (<PageLoader id='bdm-lci-config-provider-spinner'/>)}
    </BdmLciConfigContext.Provider>);
};
function mapStateToProps(state) {
    return {
        lciConfig: state.LciBdmConfig.lciConfig,
        lciError: state.LciBdmConfig.lciError,
        lciLoading: state.LciBdmConfig.lciLoading,
        bdmConfig: state.LciBdmConfig.bdmConfig,
        bdmError: state.LciBdmConfig.bdmError,
        bdmLoading: state.LciBdmConfig.bdmLoading,
    };
}
export default connect(mapStateToProps)(BdmLciConfigProvider);
