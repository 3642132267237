import { Alert, Button, Form, Icon, Link, Modal, Spinner } from '@digitools/honest-react';
import React, { useRef, useState } from 'react';
import { postCopyPermisssions } from '../../../api/UiServiceApi';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useTealium from '../../../hooks/useTealium';
import UserSearch from './UserSearch';
const CopyUserModal = ({ allUsers, selectedUser, showAllUsers, setCopyPermissionsState }) => {
    const { trackEvent } = useTealium();
    const formApiRef = useRef();
    const setFormApi = (formApi) => {
        formApiRef.current = formApi;
    };
    const { authHeader, user } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const setIsModalOpenRef = useRef(() => { });
    const setModalControl = (setIsOpen) => {
        setIsModalOpenRef.current = setIsOpen;
    };
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.MODAL,
                event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                event_name: 'copy permissions',
                event_status: TEALIUM_EVENT.EVENT_STATUS.OPEN,
            });
            formApiRef.current.reset();
            showModal();
        };
        return (<Link id={'copy-user-openmodal'} data-testid='openModal' aria-label='Copy Employer Permissions' onClick={handleOnClick}>
        <b>
          <Icon name={'clone'} color={'accent1Light'} id='icon-copy-user' size={'xs'}/> Copy permissions
        </b>
      </Link>);
    };
    const RenderCloseButton = ({ hideModal }) => {
        const handleOnClick = () => {
            hideModal();
            setDisplayError(false);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                event_name: 'CANCEL',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL,
            });
        };
        return (<Button id={'copy-user-closemodal'} type='button' buttonType='secondary' data-testid='hideModal' onClick={handleOnClick} aria-label='Cancel' style={{ float: 'left' }}>
        CANCEL
      </Button>);
    };
    const keepModalOpen = (isOpen) => {
        if (setIsModalOpenRef.current) {
            setIsModalOpenRef.current(isOpen);
        }
    };
    const handleFormSubmit = async () => {
        if (formApiRef.current?.getValue("userId")) {
            const childUser = formApiRef.current?.getValue("userId");
            setIsLoading(true);
            await postCopyPermisssions(selectedUser.userId, childUser.userId, authHeader()).then((res) => {
                setIsLoading(false);
                setDisplayError(false);
                keepModalOpen(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                    event_name: 'SAVE ',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.CONFIRM,
                });
                setCopyPermissionsState(true, selectedUser.firstName, selectedUser.lastName, childUser);
            }).catch(() => {
                setIsLoading(false);
                setDisplayError(true);
                keepModalOpen(true);
            });
        }
        return;
    };
    const RenderSaveButton = () => {
        return (<Button buttonType={'primary'} type={'submit'} aria-label='Ok' data-testid={'saveSubLocs'}>
        SAVE
      </Button>);
    };
    const isLoadingButton = () => {
        return (<></>);
    };
    return (<Form onSubmit={handleFormSubmit} getApi={setFormApi}>
      <Modal headerText={'Copy user permissions'} hasCloseIcon={true} modalContainerStyle={{ maxWidth: '40vw', width: '40vw', minWidth: '9.75rem' }} modalOpenRenderButton={RenderOpenButton} footerPrimaryRenderButton={isLoading ? isLoadingButton : RenderSaveButton} footerSecondaryRenderButton={isLoading ? isLoadingButton : RenderCloseButton} size='large' footerContentPosition={"flex-start"} disabledBackdropClick={true} getControl={setModalControl}>
        {isLoading ?
            <Spinner /> : <>
            {selectedUser && <>
              <p data-testid={'modal-content'}>Both permissions and folder settings will be saved from <b>{selectedUser.firstName} {selectedUser.lastName} </b> to the selected user below.</p>
            </>}
            <hr />
            {displayError && <Alert type={'error'}>
              <strong>Error!</strong>&nbsp;Permissions and folders from <b>{selectedUser.firstName} {selectedUser.lastName} </b>could not be saved.
            </Alert>}
            <p style={{ marginBottom: '4px' }}><b>Apply to: </b></p>
            {"\n"}
            <UserSearch selectedUser={selectedUser} users={allUsers} showAllIndicator={true} showAllUsers={showAllUsers} showCopyPermissionsTable={true}/> </>}
      </Modal>
    </Form>);
};
export default CopyUserModal;
