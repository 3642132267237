import React from 'react';
import { Spinner, themes } from '@digitools/honest-react';
import { getEnvironment } from '../../utils/envUtils';
import { Environment } from '../../types/Environment';
const nonProd = (getEnvironment() === Environment.DEV || getEnvironment() === Environment.NON_PROD);
const PageLoader = ({ id = 'string' }) => {
    return (<div style={{
            minHeight: `calc(100vh - 500px)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }} data-testid={'loading'} data-public={true}>
      <Spinner id={id} isShown={true} theme={themes.lincoln}/>
    </div>);
};
export default PageLoader;
