import React from 'react';
const helpMessage = (<>
    Si tiene alguna pregunta sobre sus cuentas, por favor{' '}
    <a href='mailto:CCS_Product_Portal_Services@lfg.com'>envíenos un correo electrónico.</a>
  </>);
const errorMessage = (<>
    No podemos recuperar la lista de clientes en este momento. Por favor, vuelva de nuevo en 20 minutos. Si este
    problema persiste, <a href='mailto:CCS_Product_Portal_Services@lfg.com'>envíenos un correo electrónico.</a>
  </>);
const unassociatedMessage = (<>
    <p>
      Gracias por registrarte en <strong>My Lincoln Portal</strong>. Estamos trabajando activamente para asociar su
      libro de negocios. ¡Por favor, revise luego!
    </p>
    <p>
      Si tiene alguna pregunta, por favor{' '}
      <a href='mailto:CCS_Product_Portal_Services@lfg.com'>envíenos un correo electrónico.</a>
    </p>
  </>);
export const MultiCustomerDisplayTextEs = {
    pageHeading: 'Mis cuentas de cliente(s)',
    subHeading: 'Para ver información de una cuenta específica, busque por nombre de cuenta o div-serial.',
    customerHeading: 'Resultados de búsqueda de clientes:',
    searchPlaceholder: 'Buscar clientes',
    helpMessage,
    noResults: 'No se han encontrado resultados.',
    errorMessage,
    unassociatedMessage,
};
