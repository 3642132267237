import React from 'react';
import { Alert, Col, Row } from '@digitools/honest-react';
import { UNAUTHORIZED_ALERT } from '../../../Constants/CrsConstants';
export const RunReportDetails = ({ reportCount, asOfDate, reportCountError, runReportError, errorStatusCode, }) => {
    const reportErrorAlert = (<Alert type={'info'}>The system is unable to generate your report, please try again later.</Alert>);
    const errorAlert = errorStatusCode == 403 ? UNAUTHORIZED_ALERT : reportErrorAlert;
    if (reportCountError) {
        return <div style={{ marginTop: '20px' }}>{errorAlert}</div>;
    }
    return (<div style={{ marginTop: '20px' }}>
      <Row>
        <Col>
          <strong>Data current as of: </strong>
          {asOfDate !== 'Invalid date' ? asOfDate : ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Record Count: </strong>
          {reportCount}
        </Col>
      </Row>
      {runReportError && (<Row>
          <Col>{errorAlert}</Col>
        </Row>)}
    </div>);
};
