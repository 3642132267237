import React, { useContext } from 'react';
import { Button, Icon, Modal } from '@digitools/honest-react';
import styled from 'styled-components';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
const StyledIcon = styled(Icon) `
  color: #23669A;
  margin: .5rem .625rem;
  font-size: 1.2rem;
`;
const StyledDiv = styled('div') `
  color: #23669A;
  cursor: pointer;
`;
const BdmModalDeleteBeneficiary = ({ beneficiaryCategory, beneficiaryIndex, displayEditDeleteIconsFlag, handleDeleteBeneficiary }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const displayIconsValue = displayEditDeleteIconsFlag ? 'inline-block' : 'none';
    const RenderOpenModalButton = ({ showModal }) => {
        const handleClick = () => {
            showModal();
        };
        return (<StyledDiv id={`div-open-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`} style={{ display: displayIconsValue }} onClick={handleClick}>
        <StyledIcon id={`btn-open-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`} name={'trash'} size={'medium'} onClick={handleClick} style={{ display: displayIconsValue }}/>
        <span>{displayText.delete}</span>
      </StyledDiv>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleClick = () => {
            hideModal();
            handleDeleteBeneficiary(beneficiaryIndex);
        };
        return (<Button id={`btn-confirm-delete-${beneficiaryCategory}${beneficiaryIndex}`} buttonType={'primary'} onClick={handleClick}>
        {displayText.delete}
      </Button>);
    };
    const FooterSecondaryRenderButton = ({ hideModal }) => {
        const handleClick = () => {
            hideModal();
        };
        return (<Button id={`btn-hide-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`} type='button' onClick={handleClick} buttonType={'text'}>
        {displayText.cancelButton}
      </Button>);
    };
    return (<Modal hasCloseIcon={true} headerText={displayText.delete} modalOpenRenderButton={RenderOpenModalButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} footerSecondaryRenderButton={FooterSecondaryRenderButton} disabledBackdropClick={true}>
      <p>
        {displayText.deleteMessage}
      </p>
    </Modal>);
};
export default BdmModalDeleteBeneficiary;
