import React, { useState, useEffect } from 'react';
import EEFindMyInformation from '../EEFindMyInformation';
import CommPref from './CommPref';
import useAuthentication from '../../../../hooks/useAuthentication';
import { getClaimLeaveNumber } from '../../../../api/CommPrefApi';
const CommPrefContainer = () => {
    const [fmiCompleted, setFmiCompleted] = useState(false);
    const [claimLeaveNo, setClaimLeaveNo] = useState();
    const { user, authHeader } = useAuthentication();
    const getClaimLeaveNo = async () => {
        try {
            const response = await getClaimLeaveNumber(authHeader(), user?.userName);
            const statusCode = response.status;
            if (statusCode === 200) {
                const parsedCargo = JSON.parse(response?.data?.cargo);
                setClaimLeaveNo(parsedCargo.claimLeaveNumber || '');
            }
        }
        catch (error) {
            console.log(error);
            // 500 errors still will report out to UnexpectedErrorProvider
            // any 400 errors can be swallowed here?
        }
    };
    useEffect(() => {
        if (user?.empNumS1 || claimLeaveNo) {
            setFmiCompleted(true);
        }
        else if (user && !user?.empNumS1 && !claimLeaveNo) {
            getClaimLeaveNo();
        }
    }, [claimLeaveNo, user]);
    const Fmi = !fmiCompleted ? <EEFindMyInformation onComplete={setFmiCompleted}/> : null;
    const Commpref = fmiCompleted ? <CommPref data-public={true}/> : null;
    return (<>
      {Fmi}
      {Commpref}
    </>);
};
export default CommPrefContainer;
