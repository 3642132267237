import { Box, Button, Col, CompositeTable as Table, Form, Heading, Link, Row, SelectInput, Spinner, TextInput, themes, } from '@digitools/honest-react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { loadDefaultClaims, loadProdTypes, searchClaimsLeaves } from '../../api/RscApi';
import useAuthentication from '../../hooks/useAuthentication';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import usePortalMessenger from '../../hooks/usePortalMessenger';
import { formatName, validateClaimOrLeaveId } from '../../utils/validationUtils';
import ClaimOrLeaveDetail from './ClaimOrLeaveDetail';
const RSC_SEARCH_PAGE = '/rsc/search';
export const DateStyle = styled('span') `
  font-size: 1rem;
  float: right;
  margin-top: -20px;
`;
const SpinnerContainer = styled('div') `
  text-align: center;
`;
const Rsc = () => {
    const getColumns = (clmLveHeader) => [
        {
            Cell: ({ data }) => {
                return <>{new Date(data.date).toLocaleDateString()}</>;
            },
            Header: 'Date',
            accessor: 'date',
            id: 'date',
        },
        {
            Cell: ({ data }) => {
                return <span data-private={true}>{data.lastName}</span>;
            },
            Header: 'Last Name',
            accessor: 'lastName',
            id: 'lastName',
        },
        {
            Cell: ({ data }) => {
                return <span data-private={true}>{data.firstName}</span>;
            },
            Header: 'First Name',
            accessor: 'firstName',
            id: 'firstName',
        },
        {
            Cell: ({ data }) => {
                return (
                // @ts-ignore
                <Link data-testid={'claim-leave-id'} onClick={selectClaimOrLeaveDetails(data._original)}>{data.clmLveId}</Link>);
            },
            Header: clmLveHeader,
            accessor: 'clmLveId',
            id: 'clmLveId',
        },
        {
            Header: 'Product',
            accessor: 'product',
            id: 'product',
        },
        {
            Header: 'Status',
            accessor: 'status',
            id: 'status',
        },
    ];
    const APPROVED = { value: 'AP', label: 'Approved' };
    const CLOSED = { value: 'CL', label: 'Closed' };
    const DENIED = { value: 'DE', label: 'Denied' };
    const IN_PROCESS = { value: 'IP', label: 'In Process' };
    const PENDED = { value: 'PE', label: 'Pended' };
    const VOIDED = { value: 'VD', label: 'Voided' };
    const STD_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const LTD_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const LTDCP_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const STAT_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const LEAVE_STATUSES = [APPROVED, CLOSED, DENIED, PENDED, VOIDED];
    const STDCP_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const SALCN_STATUSES = [APPROVED, CLOSED, DENIED, IN_PROCESS, PENDED, VOIDED];
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clmLveHeader, setClmLveHeader] = useState('Claim/Leave ID');
    const [productOptions, setProductOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedClaimOrLeaveDetails, setSelectedClaimOrLeaveDetails] = useState();
    const { authHeader, user } = useAuthentication();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { addMessage, clearMessages } = usePortalMessenger();
    const custDiv = user ? user.customerDiv || '' : '';
    const custSerial = user ? user.customerSerial || '' : '';
    const fixHeader = (rows) => {
        let claim = false;
        let leave = false;
        (rows || []).map((row) => {
            if (row.product.toLowerCase() === 'leave') {
                leave = true;
            }
            else {
                claim = true;
            }
        });
        if (claim && leave) {
            setClmLveHeader('Claim/Leave ID');
        }
        else if (claim) {
            setClmLveHeader('Claim ID');
        }
        else if (leave) {
            setClmLveHeader('Leave ID');
        }
    };
    useEffect(() => {
        const getClaims = async () => {
            setLoading(true);
            const loadClaimLeaveRequest = {
                custDiv,
                custSeral: custSerial,
            };
            try {
                const response = await loadDefaultClaims(authHeader(), loadClaimLeaveRequest);
                setTableData(response.data);
                fixHeader(response.data);
            }
            catch (e) {
                addMessage({ type: 'error', message: ['Unable to find claims/leaves.', 'Unable to find claims/leaves.'] });
            }
            finally {
                setLoading(false);
            }
        };
        const getProdType = async () => {
            const res = await loadProdTypes(authHeader(), custDiv, custSerial);
            const productList = res.data;
            const optionsList = [];
            if (productList) {
                productList.map((val) => {
                    const myProduct = {};
                    myProduct.label = val.prod_type;
                    myProduct.value = val.prod_type;
                    optionsList.push(myProduct);
                });
                setProductOptions(optionsList);
            }
        };
        getClaims();
        getProdType();
        return () => {
            clearMessages();
        };
    }, []);
    const searchClaims = (values) => {
        trackClickWithPage('Search', RSC_SEARCH_PAGE);
        setLoading(true);
        clearMessages();
        const searchQuery = {
            custDiv,
            custSeral: custSerial,
            firstName: values.firstNameFilter,
            lastName: values.lastNameFilter,
            claimLeaveId: parseInt(values.claimLeaveIdFilter),
            productType: values.productFilter,
            claimLeaveStatus: values.statusFilter,
        };
        searchClaimsLeaves(authHeader(), searchQuery)
            .then((response) => {
            setTableData(response.data);
            fixHeader(response.data);
        })
            .catch((e) => {
            addMessage({ type: 'error', message: ['Unable to find claims/leaves.', 'Unable to find claims/leaves.'] });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const clearFilters = () => {
        trackClickWithPage('Clear', RSC_SEARCH_PAGE);
        if (formApiRef && formApiRef.current) {
            formApiRef.current.reset();
        }
    };
    const selectClaimOrLeaveDetails = (data) => () => {
        setSelectedClaimOrLeaveDetails(data);
    };
    const formApiRef = useRef();
    const setFormApi = (formApis) => {
        formApiRef.current = formApis;
    };
    const getStatusOptions = (product) => {
        switch (product) {
            case 'STD':
                return STD_STATUSES;
            case 'LTD':
                return LTD_STATUSES;
            case 'LTDCP':
                return LTDCP_STATUSES;
            case 'STAT':
                return STAT_STATUSES;
            case 'LEAVE':
                return LEAVE_STATUSES;
            case 'STDCP':
                return STDCP_STATUSES;
            case 'SALCN':
                return SALCN_STATUSES;
            default:
                return [];
        }
    };
    const enableStatus = (values) => {
        setStatusOptions(getStatusOptions(values.productFilter));
    };
    const setDataDate = () => {
        if (tableData.length > 0) {
            return getDateTimeString(new Date(tableData[0].date));
        }
        else {
            return '';
        }
    };
    const getDateTimeString = (date) => {
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };
    const resetStatus = () => {
        if (formApiRef && formApiRef.current) {
            formApiRef.current.setValue('statusFilter', '');
        }
    };
    return (<div data-public={true}>
      {selectedClaimOrLeaveDetails && (<ClaimOrLeaveDetail searchData={selectedClaimOrLeaveDetails} clearDetails={setSelectedClaimOrLeaveDetails}/>)}
      {loading && (<SpinnerContainer>
          <Spinner data-testid={'rsc-spinner'} id='rsc-spinner' isShown={true}/>
        </SpinnerContainer>)}
      {!selectedClaimOrLeaveDetails && (<div style={{ visibility: loading ? 'hidden' : 'visible' }} data-testid={'rsc-form'}>
          <Heading elemType={'h1'} theme={themes.lincoln} className='mb-5 mt-5'>
            View recent status changes
            <DateStyle>
              Data is current as of: <p>{setDataDate()}</p>
            </DateStyle>
          </Heading>
          <Box className='mb-5 mt-5'>
            <div className='mb-2 mt-2'>
              <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'} className='mb-1'>
                Search
              </Heading>
              <Form getApi={setFormApi} onSubmit={searchClaims} onValueChange={enableStatus}>
                <Row>
                  <Col sm={12} md={4}>
                    <TextInput data-private={true} field={'lastNameFilter'} label={'Last Name'} validateOnBlur={true} type={'text'} mask={formatName} theme={themes.lincoln}/>
                  </Col>
                  <Col sm={12} md={4}>
                    <TextInput data-private={true} field={'firstNameFilter'} label={'First Name'} validateOnBlur={true} type={'text'} mask={formatName} theme={themes.lincoln}/>
                  </Col>
                  <Col sm={12} md={4}>
                    <TextInput field={'claimLeaveIdFilter'} label={'Claim/Leave ID'} validateOnBlur={true} type={'text'} mask={validateClaimOrLeaveId} theme={themes.lincoln}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <SelectInput field={'productFilter'} data-testid={'productFilter'} label={'Product'} validateOnBlur={true} options={productOptions} onValueChange={resetStatus} theme={themes.lincoln}/>
                  </Col>
                  <Col sm={12} md={4}>
                    <SelectInput field={'statusFilter'} data-testid={'statusFilter'} label={'Status'} validateOnBlur={true} options={statusOptions} disabled={!statusOptions} theme={themes.lincoln}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button buttonType='primary' id='search' theme={themes.lincoln}>
                      Search
                    </Button>
                    <Button buttonType='secondary' type='button' id='clear' onClick={clearFilters} className='mr-3' theme={themes.lincoln}>
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Box>
          <Row style={{ marginTop: '1rem' }}>
            The most recent 150 rows are displayed. Use the Search Filter to refine the display.
          </Row>
          <Row>
            <Col>
              <Table columns={getColumns(clmLveHeader)} data-testid={'rsc-table'} data={tableData} paginated={true} sortable={true} defaultSorted={[{ id: 'date', desc: true }]} theme={themes.lincoln}/>
            </Col>
          </Row>
        </div>)}
    </div>);
};
export default Rsc;
