import React from 'react';
import { withRouter } from 'src/utils/withRouter';
import withExperience from '../hocs/withExperience';
import AuthenticationProvider from './AuthenticationProvider';
import CustomerConfigProvider from './CustomerConfigProvider';
import ExperienceProvider from './ExperienceProvider';
import RouteCheckProvider from './RouteCheckProvider';
import TranslateProvider from './TranslateProvider';
const AuthenticationProviderWithExperience = withExperience(AuthenticationProvider);
const ExperienceProviderWithRouter = withRouter(ExperienceProvider);
/**
 * Provides context to all of the key functionality in the MLC external
 * facing portal.  Place this at the root of your application, beneath the
 * router.
 * * authorizeUserErrorCallback: callback function involked if error occurs authorizing user
 */
const CoreProvider = ({ authorizeUserErrorCallback, children, getAuthApi = () => { }, }) => (<ExperienceProviderWithRouter>
    <RouteCheckProvider>
      <AuthenticationProviderWithExperience authorizeUserErrorCallback={authorizeUserErrorCallback} getApi={getAuthApi}>
        {/* <GoogleAnalyticsProviderWithExperience> */}
        <TranslateProvider>
          <CustomerConfigProvider>{children}</CustomerConfigProvider>
        </TranslateProvider>
        {/* </GoogleAnalyticsProviderWithExperience> */}
      </AuthenticationProviderWithExperience>
    </RouteCheckProvider>
  </ExperienceProviderWithRouter>);
export default CoreProvider;
