export const BdmDisplayTextEn = {
    allocation: 'Allocation',
    name: 'Name',
    type: 'Type',
    toggleLabel: 'Split shares equally',
    total: 'Total',
    bdmHeading: 'Manage My Beneficiaries',
    bdmText: 'Add or change your beneficiary information. A maximum of 15 beneficiaries is allowed.',
    primaryBeneficiariesTitle: 'Primary Beneficiaries',
    contingentBeneficiariesTitle: 'Contingent Beneficiaries',
    isAgreedError: 'Please check box to submit',
    signatureText: 'By selecting "I agree", I am affirming that the individuals shown above are my designated beneficiary(ies). I hereby certify that I have read and understand the instructions for this online form and that all the information I have entered is true and complete. I understand that the submission of this online form revokes any prior designations that I have made.',
    agreeCheckbox: 'I agree',
    signatureTitle: 'Signature',
    signature: ' Signature (Typed)',
    signaturePlaceholder: ' Name',
    dateSigned: 'Date Signed',
    country: 'Country',
    state: 'State',
    province: 'Province',
    otherAddress: 'Enter Address and Contact Information',
    city: 'City',
    submitChangesButton: 'Submit',
    select: 'Select',
    thankYou: 'Thank You!',
    viewSubmissionButton: 'View Submission',
    successMsgStart: 'You have successfully submitted your beneficiary designation information on ',
    successMsgEnd: ' EST.',
    confirmEmailMsgStart: 'A confirmation email has been sent to ',
    confirmEmailMsgEnd: ` Please take a moment to save a copy of your application
          for your records.`,
    lastSubmitted: 'Beneficiary information last updated on ',
    getHelp: 'Get Help',
    getHelpModalText: 'For assistance, please refer to the User Guide found in the Resource Center.',
    closeBtnText: 'Close',
    noPrimaryBenError: 'Please name a primary beneficiary to continue',
    totalNotHundredPercentError: 'Please ensure that Equal Shares checkbox is selected OR that each beneficiary is allocated a percentage that total 100%.',
    allocationCannotBezeroError: 'Allocation cannot be blank or zero (0)',
    timeZone: 'EST.',
};
