import { Alert, Col, Directory, Heading, Loader, Row, themes } from '@digitools/honest-react';
import { FileType } from '@digitools/honest-react/dist/lib/types/FileType';
import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import React from 'react';
import withAuthentication from '../../hocs/withAuthentication';
import withExperience from '../../hocs/withExperience';
import withTealium from '../../hocs/withTealium';
import FileDetailsTable from './FileDetailsTable';
import HelpInformation from './HelpInformation';
export class StandardReportsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadError: false,
            getFileItemsError: false,
            fileItems: [],
            selectedDirectory: undefined,
            loading: false,
        };
    }
    async componentDidMount() {
        this.setState({
            loading: true,
        });
        await this.getFileItems(true);
        this.setState({ loading: false });
    }
    onSelect = (fileItem) => {
        this.setState({
            selectedDirectory: fileItem,
        });
    };
    getFileItems = async (filterByDate) => {
        try {
            let url = '/document-service/reports/all';
            const response = await axios.get(url, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            await this.setState({
                selectedDirectory: response.data[0],
                fileItems: response.data,
                getFileItemsError: false,
            });
        }
        catch (error) {
            this.setState({
                getFileItemsError: true,
            });
        }
    };
    downloadFile = async (id) => {
        try {
            const response = await axios.get(`/document-service/reports/${id}`, {
                responseType: 'arraybuffer',
                headers: { Authorization: this.props.authenticationContext.authHeader() },
            });
            const contentDispositionHeader = response.headers['content-disposition'];
            const contentType = response.headers['content-disposition'];
            const filename = contentDispositionHeader?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim();
            const blob = new Blob([response.data], { type: contentType });
            saveAs(blob, filename);
        }
        catch (error) {
            this.setState({
                downloadError: true,
            });
        }
    };
    renderGetFileItemsAlert = () => {
        if (this.state.getFileItemsError) {
            return (<Alert type='error'>
          <strong>
            {"We're sorry. We are unable to retrieve your folders and reports at this time. If this problem persists, please contact technical assistance."}
          </strong>
        </Alert>);
        }
        else {
            return <></>;
        }
        // TODO: need to change Alert to not return null in the non-visible state here, or it breaks enzyme
        // once the above TODO is done, can replace with just this
        // return (
        //   <Alert type='error' visible={this.state.getFileItemsError} >
        //     <strong>{'We\'re sorry. We are unable to retrieve your folders and reports at this time. If this problem persists, please contact technical assistance.'}</strong>
        //   </Alert>
        // );
    };
    renderDownloadErrorAlert = () => {
        if (this.state.downloadError) {
            return (<Alert type='error' closeable={true} onClose={this.closeDownloadErrorAlert}>
          <strong>
            {"We're sorry. We are unable to download your document at this time. If this problem persists, please contact technical assistance."}
          </strong>
        </Alert>);
        }
        else {
            return <></>;
        }
        // TODO: similar change to other alert, once non-visible state stops returning null can remove this if and replace with visible flag
    };
    closeDownloadErrorAlert = () => {
        this.setState({
            downloadError: false,
        });
    };
    renderDirectoryDetail = (fileDetailsTable) => {
        if (this.state.fileItems.length === 0) {
            return <></>;
        }
        else {
            return (<>
          <Col lg={'3'} md={'4'} sm={'5'}>
            <Directory title='ALL DOCUMENTS' directoryItems={this.state.fileItems} foldersOnly={true} onItemSelect={this.onSelect} defaultOpen={true} theme={themes.lincoln}/>
          </Col>
          <Col lg={'9'} md={'8'} sm={'7'}>
            {fileDetailsTable}
          </Col>
        </>);
        }
    };
    getSelectedDirectory = async (id, fileItems) => {
        fileItems.forEach(fileItem => {
            if (fileItem.id === id) {
                this.setState({
                    selectedDirectory: fileItem,
                });
                return;
            }
            this.getSelectedDirectory(id, fileItem.children);
        });
    };
    showAllDocuments = async () => {
        if (this.state.selectedDirectory) {
            const selectedDirectoryId = this.state.selectedDirectory.id;
            await this.getSelectedDirectory(selectedDirectoryId, this.state.fileItems);
        }
    };
    formatStartDate = (date) => {
        return format(date, 'yyyy-MM-dd hh:mm:ss');
    };
    formatEndDate = (date) => {
        const formatted = format(date, 'yyyy-MM-dd');
        return `${formatted} 23:59:59`;
    };
    onDateSelect = async (startDate, endDate) => {
        try {
            let url = '/document-service/reports/all';
            if (startDate && endDate) {
                url += `?startDate=${this.formatStartDate(startDate)}`;
                url += `&endDate=${this.formatEndDate(endDate)}`;
            }
            else if (startDate) {
                url += `?startDate=${this.formatStartDate(startDate)}`;
            }
            else if (endDate) {
                url += `?endDate=${this.formatEndDate(endDate)}`;
            }
            const selectedDirectory = this.state.selectedDirectory.id;
            const response = await axios.get(url, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            this.setState({
                fileItems: response.data,
                getFileItemsError: false,
            });
            await this.getSelectedDirectory(selectedDirectory, this.state.fileItems);
        }
        catch (error) {
            this.setState({
                getFileItemsError: true,
            });
        }
    };
    renderReportsTable = (files) => {
        const filesToDisplay = this.state.selectedDirectory ? files : [];
        const label = this.state.selectedDirectory ? this.state.selectedDirectory.label : 'Select a Directory';
        return (<FileDetailsTable downloadFile={this.downloadFile} folderName={label} files={filesToDisplay} filterDocuments={this.showAllDocuments} trackEvent={this.props.trackEvent} trackView={this.props.trackView} onDateSelect={this.onDateSelect}/>);
    };
    render() {
        // may be able to combine these two statements into one block - i dunno
        const files = this.state.selectedDirectory
            ? this.state.selectedDirectory.children.filter(fileItem => fileItem.type !== FileType.DIR)
            : [];
        return (<div data-public={true}>
        <br />
        <Heading elemType='h1'>Standard reports</Heading>
        {this.state.loading ? (<div className={'mt-6'}>
            <Loader isShown={true}/>
          </div>) : (<>
            {this.renderGetFileItemsAlert()}
            {this.renderDownloadErrorAlert()}
            <br />
            <Row>{this.renderDirectoryDetail(this.renderReportsTable(files))}</Row>
            <Row>
              <Col lg={'3'} md={'4'} sm={'5'}/>
              <Col lg={'9'} md={'8'} sm={'7'}>
                <HelpInformation trackEvent={this.props.trackEvent} trackView={this.props.trackView}/>
              </Col>
            </Row>
          </>)}
      </div>);
    }
}
export default withAuthentication(withExperience(withTealium(StandardReportsContainer)));
