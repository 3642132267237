import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { TextInput, TableRow, TableData } from '@digitools/honest-react';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import BdmModalDeleteBeneficiary from '../modals/BdmModalDeleteBeneficiary';
import BdmModalEditBeneficiary from '../modals/BdmModalEditBeneficiary';
import { BDM_BENEFICIARY_TYPES, BDM_BENEFICIARY_TYPES_ES } from '../constants/BdmConstants';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
const benPerson = BDM_BENEFICIARY_TYPES[0].value;
const benEstate = BDM_BENEFICIARY_TYPES[1].value;
const benCharity = BDM_BENEFICIARY_TYPES[2].value;
const benTrust = BDM_BENEFICIARY_TYPES[3].value;
const StyledTextInput = styled(TextInput) `
  width: 3.125rem;
  height: 2.5rem;
  min-width: 2.5rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
`;
const StyledTextInputEqual = styled(TextInput) `
  width: 5.75rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-weight: bold;
  color: #002663;
  background-color: #e2edf6;
  padding: 0;

  ::placeholder {
    color: #002663;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #002663;
  }
`;
const StyledDiv = styled('div') `
  align-items: center;
`;
const StyledSpan = styled('span') `
  margin-right: 1rem;
`;
export const BeneficiaryRow = ({ index, beneficiary, beneficiaries, onValueChange, toggleButtonState, handleDeleteBeneficiary, updateBeneficiaries, }) => {
    const [displayEditDeleteIconsFlag, setDisplayEditDeleteIconsFlag] = useState(false);
    const [rerenderAllocation, setRerenderAllocation] = useState(true);
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const { firstName, middleInitial, lastName, charityName, trustName, beneficiaryCategory, beneficiaryType, allocation, } = beneficiary;
    const onMouseEnter = () => {
        setDisplayEditDeleteIconsFlag(true);
    };
    const onMouseLeave = () => {
        setDisplayEditDeleteIconsFlag(false);
    };
    const getBeneficiaryTypeLabel = (benType) => {
        const labels = experienceContext.language === 'es' ? BDM_BENEFICIARY_TYPES_ES : BDM_BENEFICIARY_TYPES;
        let label = benType;
        labels.map(ben => {
            if (ben.value === benType) {
                label = ben.label;
            }
        });
        return label;
    };
    return (<TableRow onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <TableData style={{ verticalAlign: 'middle' }}>
        <StyledDiv className='d-flex'>
          <StyledSpan>
            {beneficiaryType === benPerson || beneficiaryType === benEstate
            ? <span data-private={true}>{`${firstName} ${middleInitial || ''} ${lastName}`}</span>
            : null}
            {beneficiaryType === benCharity ? <span data-private={true}>{charityName}</span> : null}
            {beneficiaryType === benTrust ? <span data-private={true}>{trustName}</span> : null}
          </StyledSpan>
          <BdmModalEditBeneficiary beneficiaryCategory={beneficiaryCategory} beneficiaries={beneficiaries} beneficiaryIndex={index} updateBeneficiaries={updateBeneficiaries} toggleButtonState={toggleButtonState} setRerenderAllocation={setRerenderAllocation} displayEditDeleteIconsFlag={displayEditDeleteIconsFlag}/>
          <BdmModalDeleteBeneficiary beneficiaryCategory={beneficiaryCategory} beneficiaryIndex={index} handleDeleteBeneficiary={handleDeleteBeneficiary} displayEditDeleteIconsFlag={displayEditDeleteIconsFlag}/>
        </StyledDiv>
      </TableData>
      <TableData style={{ verticalAlign: 'middle' }}>{getBeneficiaryTypeLabel(beneficiaryType)}</TableData>

      {!toggleButtonState && rerenderAllocation ? (<TableData>
          <div className='mx-auto'>
            <StyledTextInput initialValue={allocation} field={`allocationPercentage-${beneficiaryCategory}${index}`} hideLabel={true} label={'Allocation Percentage'} type={'text'} onValueChange={onValueChange} maxLength={3} mask={BdmBeneficiaryUtils.validateAllocationInForm}/>
          </div>
        </TableData>) : (<TableData className='d-flex'>
          <div>
            <StyledTextInputEqual field={`equalAllocation-${beneficiaryCategory}${index}`} label={'Allocation %'} type={'text'} hideLabel={true} readOnly={true} placeholder={displayText.equal}/>
          </div>
        </TableData>)}
    </TableRow>);
};
