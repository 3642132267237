import axios from 'axios';
const baseUrl = '/ua-service/mobile/';
export const getEmployeeName = async (empId, empBthDte) => {
    return axios.get(`${baseUrl}verify?empId=${empId}&empBthDte=${empBthDte}`);
};
export const getMaintenanceStatus = async () => {
    return axios.get(`portal-status`);
};
export const submit = async (type = '', date, spansMidnight, startTime, endTime, normalStartTime, estimatedArrivalTime, departureTime, regularEndTime, estimatedReturnTime, durationHours, durationMinutes, absReason, hasLeave, associatedLeave = '', immediateFamily, transactionNumber, employeeId, enteredBy) => {
    return axios.post('/ua-service/mobile/submit-absences', [{
            absType: type,
            date,
            spansMidnight,
            startTime,
            endTime,
            normalStartTime,
            estimatedArrivalTime,
            departureTime,
            regularEndTime,
            estimatedReturnTime,
            duration: `${durationHours}:${durationMinutes}`,
            absReason,
            hasLeave,
            associatedLeave,
            immediateFamily,
            transactionNumber,
            employeeId,
            enteredBy
        }], {
        headers: {
            'Content-type': 'application/json'
        }
    });
};
const exportFunctions = {
    getEmployeeName,
    submit,
    getMaintenanceStatus
};
export default exportFunctions;
