import React from 'react';
import { Button, Icon, themes } from "@digitools/honest-react";
import useAuthentication from "src/hooks/useAuthentication";
import { downloadAsConfirmationPdf } from "../../api/ApiCalls";
import useTranslate from 'src/hooks/useTranslate';
import useExperience from 'src/hooks/useExperience';
import useAsCustomerConfigContext from '../../hooks/useAsConfigContext';
import useAsDataContext from '../../hooks/useAsDataContext';
import { getAsRequest } from '../../utils/AsDataUtils';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import ConfirmationText from './confirmationText';
const DownloadAndPrint = (props) => {
    const { setErrorMessage } = props;
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const { language, experienceCode } = useExperience();
    const { claimantData, conditionData, accommodationRequestDataArray, documents, accommodationResponse } = useAsDataContext();
    const { asConfig } = useAsCustomerConfigContext();
    const { trackEvent } = useTealium();
    const pdfObj = {
        accommodationResponse,
        claimantData,
        accommodationRequestDataArray: accommodationRequestDataArray && getAsRequest(accommodationRequestDataArray),
        conditionData,
        documents: documents,
        language: language,
        experience: experienceCode,
        asConfig: asConfig?.config,
    };
    const trackPageEvent = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
            event_name: 'view and print submission',
            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            event_status: TEALIUM_EVENT.EVENT_STATUS.START,
        });
    };
    return (<Button theme={themes.lincoln} type={'button'} className='p-0' onClick={() => {
            trackPageEvent();
            downloadAsConfirmationPdf(authHeader(), pdfObj, setErrorMessage, t(ConfirmationText.downloadErrorText));
        }} data-testid={'download-button'}>
            <Icon name={'print-solid'} size='medium' color={'secondary'} style={{ marginRight: '0.5rem' }}/>
            {t(ConfirmationText.printOption)}
        </Button>);
};
export default DownloadAndPrint;
