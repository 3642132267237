export const AsText = {
    intakeHeadingAccommodationCustomer: {
        english: 'Accommodation request',
        spanish: 'Solicitud de adaptación',
    },
    nextButton: {
        english: 'Continue',
        spanish: 'Seguir',
    },
    backButton: {
        english: 'BACK',
        spanish: 'ATRÁS',
    },
    saveForLaterButton: {
        english: 'Save for later',
        spanish: 'Guardar para más adelante',
    },
    saveAndContinue: {
        english: 'SAVE AND CONTINUE',
        spanish: 'GUARDAR Y CONTINUAR',
    },
    submit: {
        english: 'SUBMIT REQUEST',
        spanish: 'ENVIAR PETICION',
    },
    continue: {
        english: 'CONTINUE',
        spanish: 'CONTINUAR',
    },
    submitErrorMessage: {
        english: 'We’re sorry, we were unable to submit your request at this time. Please wait a moment and try again. If issues persist, please',
        spanish: 'Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si los problemas persisten, por favor',
    },
    submitErrorMessageContactusLinkLabel: {
        english: 'contact us.',
        spanish: 'Contacta con nosotras.',
    },
    optionalText: {
        english: ' (optional)',
        spanish: ' (opcional)'
    }
};
