import React, { useContext, useEffect, useState } from 'react';
import { Heading, Row, Col, StepperContext, RadioGroup, themes, Radio } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
// @ts-ignore
import { Form, Scope } from 'informed';
import PaymentMethodText from './PaymentMethod.Text';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import { RadioContainer } from '../../components/LayoutComponents';
// @ts-ignore
// @ts-ignore
import styles from './PaymentMethod.scss';
import DirectDepositInformation from '../../components/DirectDepositInformation';
import useCliDataContext from '../../hooks/useCliDataContext';
import DirectDepositValidateButton from './DirectDepositValidateButton';
import DisplayAlert from './DisplayAlert';
const PaymentMethod = ({ paymentStepNumber }) => {
    const { t } = useTranslate();
    const [disable, setDisable] = useState(true);
    const { paymentMethodData, setPaymentMethodData, isDirectDepositSuccess, setIsDirectDepositSuccess } = useCliDataContext();
    const [validateData, setValidateData] = useState({ paymentType: '' });
    const stepper = useContext(StepperContext);
    useEffect(() => {
        if (validateData.alertType == "success" && isDirectDepositSuccess == false) {
            setValidateData((e) => {
                return { ...e, alertType: '' };
            });
        }
    }, [isDirectDepositSuccess]);
    const setAlert = (val) => {
        setValidateData((e) => {
            return {
                ...e,
                alertType: val,
            };
        });
    };
    const handleChange = (e) => {
        const val = e.target.value;
        const paymentMethodDataObj = {
            preferredpaymentmethod: val
        };
        setValidateData((e) => {
            return { ...e, paymentType: val };
        });
        if (val === "bankCheck") {
            setPaymentMethodData({});
            setValidateData((e) => {
                return { ...e, alertType: '' };
            });
        }
        setPaymentMethodData(paymentMethodDataObj);
    };
    const setAccountDetails = (routingNum, accountNum) => {
        setValidateData((e) => {
            return { ...e, routingNumber: routingNum, accountNumber: accountNum };
        });
    };
    const submit = (values) => {
        const paymentMethodDataObj = { ...paymentMethodData, ...values?.paymentMethodData };
        setPaymentMethodData(paymentMethodDataObj);
        // @ts-ignore 
        stepper.next();
        window.scrollTo(0, 0);
    };
    const handleDisable = (val) => {
        setDisable(val);
    };
    return (<div data-testid='paymentMethod'>
      <Form onSubmit={submit} data-testid="payment-method-form">
        {validateData.alertType && <DisplayAlert type={validateData.alertType}/>}
        <Heading elemType='h2' color='primary'>
          {t(PaymentMethodText.pageTitle)}
        </Heading>
        <p className={`my-3`} data-testid='payment-page-info'>{t(PaymentMethodText.pageInfo)}</p>
        {/* @ts-ignore */}
        <Scope scope='paymentMethodData'>
          <Row role='group' aria-labelledby='preferredpaymentmethod'>
            <Col md={12}>
              <BorderLessGrayBox>
                <Heading id='preferredpaymentmethod' elemType='h3' elemStyle='h4'>{t(PaymentMethodText.subHeading)}</Heading>
              </BorderLessGrayBox>
            </Col>
            <Col md={12}>
              <RadioGroup label={''} field={'preferredpaymentmethod'} theme={themes.lincoln} legendStyles={{ marginBottom: '.75rem' }} onChange={handleChange} initialValue={paymentMethodData && paymentMethodData.preferredpaymentmethod}>
                <RadioContainer className={styles.styledRadioContainer}>
                  <Radio containerStyle={{ zIndex: 0 }} data-testid={'directDeposit'} key={'directDeposit'} name='preferredpaymentmethod' label={t(PaymentMethodText.directDeposit)} value={'directDeposit'} disabled={isDirectDepositSuccess}/>
                  <Radio containerStyle={{ zIndex: 0 }} data-testid={'bankCheck'} key={'bankCheck'} name='preferredpaymentmethod' label={t(PaymentMethodText.bankCheck)} value={'bankCheck'} disabled={isDirectDepositSuccess}/>
                </RadioContainer>
              </RadioGroup>
            </Col>
          </Row>
          {validateData.paymentType === "directDeposit" && <DirectDepositInformation handleDisable={handleDisable} setAccountDetails={setAccountDetails}/>}
        </Scope>
        <StepperButtons suppressNext analyticsPageName='payment method' last lastButton={<DirectDepositValidateButton disable={disable} validateData={validateData} setAlert={setAlert} paymentStepNumber={paymentStepNumber}/>}/>
      </Form>
    </div>);
};
export default PaymentMethod;
