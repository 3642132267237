import { Alert, Button, Col, Form, Loader, Row, TextInput } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { resendCode, validateProveCode } from 'src/api/AuthServiceApi';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import { globalPhoneNumberFormat, numbersOnly } from 'src/utils/validationUtils';
import ProveText from './ProveText';
import useProveData from './useProveData';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
const validateCode = (value, t) => {
    if (!value || value?.length !== 6) {
        return t(ProveText.proveCodeFormError);
    }
};
const ProveCodeEntryForm = ({ altDisplay = false }) => {
    const { trustScore, verificationData, isProveSuccess, setIsProveSuccess } = useProveData();
    const { authHeader } = useAuthentication();
    const { t } = useTranslate();
    const [loading, setLoading] = useState(false);
    const [resending, setResending] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState();
    const [resendCount, setResendCount] = useState(0);
    const { trackEvent, trackView } = useTealium();
    const { experience } = useExperience();
    // This starts out as the MFA type returned on the trust score but can be changed by the user
    const [mfaType, setMfaType] = useState(trustScore?.mfaType ? trustScore.mfaType : 'SMS');
    const pageL4 = CustomEmployeeText(experience === Experience.EMPLOYEE ? 'employee view' : 'employer view');
    useEffect(() => {
        if (!loading && error === false) {
            trackView({
                page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                page_l4: pageL4,
                page_l5: 'pending prove',
            });
        }
    }, [loading, error]);
    const resend = async (mfaType, changedType = false) => {
        setResending(true);
        setError(false);
        setMessage(undefined);
        setMfaType(mfaType);
        try {
            if (resendCount >= 3) {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(ProveText.proveCodeResendMaxError),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'authentication code',
                    event_results: t(ProveText.proveCodeResendMaxError)
                });
                setError(true);
                setResending(false);
                setMessage('RESEND_MAX');
                return;
            }
            if (trustScore?.wsuid) {
                const resendResponse = await resendCode(mfaType, trustScore?.wsuid, authHeader());
                setResendCount(resendCount + 1);
                setMessage('RESENT');
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                    event_name: (!changedType ? 'resend code' : mfaType === 'SMS' ? 'get a text ' : 'get a call'),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'authentication code',
                    user_contact_method: trustScore?.mfaType
                });
                if (!resendResponse.data.success) {
                    throw new Error();
                }
            }
        }
        catch (error) {
            console.error('There was an error while resending the code');
            setError(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: t(ProveText.proveCodeUnexpectedError),
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                event_version: 'authentication code',
                event_results: t(ProveText.proveCodeUnexpectedError)
            });
            setMessage('ERROR');
        }
        setResending(false);
    };
    const submit = async (values) => {
        try {
            setLoading(true);
            setError(false);
            setMessage(undefined);
            /* Call Prove Validate API and save the valid flag to Context */
            const validateCodeData = {
                ...verificationData,
                trustString: trustScore?.trustString,
                wsuid: trustScore?.wsuid,
                token: values.proveCode,
            };
            const response = await validateProveCode(validateCodeData, authHeader());
            if (response.data.valid && response.data.message === 'SUCCESS') {
                setIsProveSuccess(true);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'authentication code',
                    event_status: 'complete',
                    event_result: 'prove'
                });
            }
            else {
                setError(true);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(ProveText.proveCodeInvalidError),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'authentication code',
                });
            }
            setMessage(response.data.message);
            if (message === 'TOKEN_EXPIRED') {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(ProveText.proveCodeExpiredError),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'authentication code',
                    event_results: t(ProveText.proveCodeExpiredError)
                });
            }
            setLoading(false);
        }
        catch (error) {
            /* TEST SECTION */
            // setIsProveSuccess(true)
            // setError(false)
            /* ***** END TEST SECTION _ UNCOMMENT BELOW */
            setError(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: t(ProveText.proveCodeUnexpectedError),
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                event_version: 'authentication code',
                event_results: t(ProveText.proveCodeUnexpectedError)
            });
            setMessage('ERROR');
            setLoading(false);
            console.log('error:', error);
            console.log('error during code entry occurred');
        }
    };
    const phoneNumber = globalPhoneNumberFormat(`${trustScore?.phoneNumber}`);
    return (<>
      <div>
        {error && (<Alert type='error' closeable={false}>
            {message === 'TOKEN_EXPIRED'
                ? t(ProveText.proveCodeExpiredError)
                : message === 'RESEND_MAX'
                    ? t(ProveText.proveCodeResendMaxError)
                    : message === 'ERROR'
                        ? t(ProveText.proveCodeUnexpectedError)
                        : t(ProveText.proveCodeInvalidError)}
          </Alert>)}
        {!error && message === 'RESENT' && (<Alert type='info' closeable={true}>
            {`${t(ProveText.proveCodeResentAlert)}${(mfaType === 'SMS' ? t(ProveText.viaText) : t(ProveText.viaPhone))}`}
          </Alert>)}
        {!error && isProveSuccess && <Alert type='success'>{t(ProveText.proveCodeSuccessAlert)}</Alert>}
        {!isProveSuccess && (<>
            <p>
              {mfaType === 'SMS'
                ? t(ProveText.phoneMobileText(phoneNumber))
                : t(ProveText.phoneHomeText(phoneNumber))}
            </p>

            <Form style={{ maxWidth: '33rem' }} data-testid='code-entry-form' onSubmit={submit}>
              <Loader data-testid='code-entry-loader' isShown={loading}>
                <Row>
                  <Col>
                    <TextInput data-testid={'proveCode'} field={'proveCode'} label={t(ProveText.codeLabel)} mask={numbersOnly} placeholder={'XXXXXX'} type={'text'} validate={(val) => validateCode(val, t)} validateOnBlur={true}/>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button data-testid='submit' style={{ width: '100%' }} type='submit' buttonType='primary' disabled={loading}>
                      {t(ProveText.submitLabel)}
                    </Button>
                  </Col>
                </Row>
                {mfaType && (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button type='button' buttonType='text' color={'primaryDark'} onClick={() => resend(mfaType)} disabled={resending}>
                      {t(ProveText.resendCodeButton)}
                    </Button>
                    <Button type='button' buttonType='text' color={'primaryDark'} onClick={() => {
                    resend(mfaType === 'SMS' ? 'VOICE' : 'SMS', true);
                }} disabled={resending}>
                      {mfaType === 'SMS' ? t(ProveText.getACallButton) : t(ProveText.getATextButton)}
                      {/* TODO: needs to be come get a Call or getAText based on MFAtype off prove response */}
                    </Button>
                  </div>)}
              </Loader>
            </Form>
          </>)}
      </div>
    </>);
};
export default ProveCodeEntryForm;
