import { combineReducers } from 'redux';
import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  CANCEL_CUSTOMERS_LOADING,
  GET_LCI_CONFIG_SUCCESS,
  GET_BDM_CONFIG_SUCCESS,
  LCI_LOADING,
  LCI_ERROR,
  BDM_ERROR,
  BDM_LOADING,
} from './actions.js';
import crsReducer from './packages/createMyReport/crsReducer';

// TODO: - break this reducer out from reducers.js into it's own section & import
const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  customers: [],
};

function Customers(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        error: null,
      });
    case GET_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        customers: action.customers,
      });
    case GET_CUSTOMERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        customers: [],
        error: action.error,
      });
    case CANCEL_CUSTOMERS_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export const initialConifg = {
  lciConfig: null,
  lciError: false,
  lciLoading: false,
  bdmConfig: null,
  bdmError: false,
  bdmLoading: false,
};

function LciBdmConfig(state = initialConifg, action) {
  switch (action.type) {
    case GET_LCI_CONFIG_SUCCESS:
      return {
        ...state,
        lciError: false,
        lciLoading: false,
        lciConfig: action.payload,
      };
    case GET_BDM_CONFIG_SUCCESS:
      return {
        ...state,
        bdmError: false,
        bdmLoading: false,
        bdmConfig: action.payload,
      };
    case LCI_LOADING:
      return {
        ...state,
        lciLoading: true,
        lciError: false,
      };
    case BDM_LOADING:
      return {
        ...state,
        bdmLoading: true,
        bdmError: false,
      };
    case LCI_ERROR:
      return {
        ...state,
        lciError: true,
        lciLoading: false,
      };
    case BDM_ERROR:
      return {
        ...state,
        bdmError: true,
        bdmLoading: false,
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  Customers,
  LciBdmConfig,
  crsReducer,
});

export default rootReducer;
