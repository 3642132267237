import React, { useState } from 'react';
import MigrationFormComponent from 'src/packages/userMigration/components/MigrationFormComponent';
import MigrationErrorComponent from 'src/packages/userMigration/components/MigrationErrorComponent';
import MigrationEmailClashComponent from 'src/packages/userMigration/components/MigrationEmailClashComponent';
const UserMigrationContainer = ({ migrationTokenLookupKey, hasErrorInit, hasUsernameClash, hasEmailClash, ...props }) => {
    const [migrationResponseCode, setMigrationResponseCode] = useState('');
    const [hasMigrationError, setHasMigrationError] = useState(hasErrorInit);
    const [hasUsernameReset, setHasUsernameReset] = useState(hasUsernameClash);
    const [initialUsername, setInitialUsername] = useState('');
    return (<>
      {hasEmailClash && <MigrationEmailClashComponent />}
      {(hasMigrationError || !migrationTokenLookupKey) && <MigrationErrorComponent migrationResponseCode={migrationResponseCode}/>}
      {!hasEmailClash && !hasMigrationError && migrationTokenLookupKey && (<MigrationFormComponent setHasMigrationError={setHasMigrationError} hasMigrationError={hasMigrationError} setHasMigrationResponseCode={setMigrationResponseCode} setHasUsernameReset={setHasUsernameReset} hasUsernameReset={hasUsernameReset} initialUsername={initialUsername} setInitialUsername={setInitialUsername} migrationTokenLookupKey={migrationTokenLookupKey}/>)}
    </>);
};
export default UserMigrationContainer;
