import { useNavigate } from 'react-router-dom';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTealium from 'src/hooks/useTealium';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useSetCurrentMember from 'src/packages/dental/hooks/useSetCurrentMember';
import { Button, Spinner } from '@digitools/honest-react';
import { encode } from 'status/utilities/hash';
import DentalText from 'src/packages/dental/constants/DentalText';
import MemberDropdown from 'src/packages/dental/components/MemberDropdown';
import React from 'react';
//TODO This is where the content will go in the subsequent stories, this is a placeholder
export const ClaimsInfo = () => {
    return <div>Claims stuff</div>;
};
const Claims = () => {
    const navigate = useNavigate();
    const { currentMember, subscriber, loading, success, errorMessage } = useDentalDataContext();
    const { trackEvent, trackView } = useTealium();
    const { t } = useTranslate();
    const trackDropdownEvent = (member) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
            event_name: TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: member,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: TEALIUM_EVENT.EVENT_TYPE.DENTAL_CLAIMS,
            user_role: 'employee',
        });
    };
    useSetCurrentMember();
    if (loading) {
        return <Spinner data-testid='claims-spinner'/>;
    }
    return (<>
      {success ? (<>
          {currentMember && subscriber && (<>
              <Button style={{ padding: '0' }} data-testid={'claimsBackButton'} onClick={() => {
                    navigate(`/employee/dental/members/${encode(currentMember.id)}`);
                }}>
                &lt; {t(DentalText.backText)}
              </Button>
              <>
                <MemberDropdown url={'claims'} trackDropdownEvent={trackDropdownEvent}/>
                <p>{t(DentalText.disclaimerText)}</p>
                <ClaimsInfo />
              </>
            </>)}
        </>) : (<>{errorMessage}</>)}
    </>);
};
export default Claims;
