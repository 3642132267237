export const CoveragePageText = {
    currentSelections: {
        english: 'Current selections:',
        spanish: 'Selecciones actuales:',
    },
    requestingNew: {
        english: 'Requesting new:',
        spanish: 'Solicitando nuevo:',
    },
    coverageTypeLabel: {
        english: 'Coverage type',
        spanish: 'Tipo de cobertura',
    },
    coverageTypePlaceholder: {
        english: 'Select coverage type',
        spanish: 'Seleccionar tipo de cobertura',
    },
    coverageAmountLabel: {
        english: 'Coverage amount',
        spanish: 'Cantidad de cobertura',
    },
    coverageAmountPlaceholder: {
        english: 'Select coverage amount',
        spanish: 'Seleccionar monto de cobertura',
    },
    criticalIllnessDisclaimer: {
        english: 'This coverage is only available to the employee and/or spouse',
        spanish: 'Esta cobertura solo está disponible para el empleado y / o cónyuge',
    }
};
export const EoiCoverageValidations = {
    coverageType: {
        english: 'Select a Coverage Type.',
        spanish: 'Seleccione un tipo de cobertura.',
    },
    coverageAmountRequired: {
        english: 'Enter a coverage amount.',
        spanish: 'Ingrese un monto de cobertura.',
    },
    validDollarAmount: {
        english: 'Enter a valid dollar amount (e.g., 50,000).',
        spanish: 'Ingrese una cantidad válida en dólares (e.g., 50,000).',
    },
    validPercent: {
        english: 'Enter a valid percent (e.g., 60).',
        spanish: 'Ingrese un porcentaje válido (e.g., 60).',
    },
    salaryMultiple: {
        english: 'Enter a valid multiple of salary (e.g., 2)',
        spanish: 'Ingrese un múltiplo válido de salario (e.g., 2)',
    },
    zipCode: {
        english: 'Enter a valid zip code (e.g., 12345-1234)',
        spanish: 'Ingrese un código zip válido (e.g., 12345-1234)',
    },
    postalCode: {
        english: 'Enter a valid postal code (e.g., A1A-1A1)',
        spanish: 'Ingrese un código postal válido (e.g., A1A-1A1)',
    },
    salary: {
        english: 'Salary must be between $0 and $99,999,999.',
        spanish: 'El salario debe estar entre $0 y $99,999,999.'
    },
    name: {
        english: 'Allowed characters are: A-Z a-z . - \' and space',
        spanish: 'Los caracteres permitidos son: A-Z a-z. - \' y espacio',
    },
};
export default EoiCoverageValidations;
