import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, themes } from '@digitools/honest-react';
import useStepperContext from './useStepperContext';
import { VanityPageText } from '../constants/VanityPageText';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { StyledBox, StyledButtonDiv, StyledDiv, StyledHeading } from './StyledComponents';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
const ReRouteInfoPage = () => {
    const { back, isLfg, isMigrated } = useStepperContext();
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const { language } = useExperience();
    const handleOnClick = () => {
        {
            isLfg ? window.location.assign(`http://lincolnfinancial.com`) : navigate(`/public/login?employee=true${language === Language.SPANISH ? '&lang=es' : ''}`);
        }
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: isLfg && !isMigrated ? t(VanityPageText.register) : t(VanityPageText.login),
        });
    };
    return (<>
      <StyledBox>
        <Row>
          <Col>
            <StyledHeading elemType={`h2`}>
              {isLfg && !isMigrated
            ? t(VanityPageText.migrateToLfg)
            : isMigrated
                ? t(VanityPageText.haveLfgAccount)
                : t(VanityPageText.startAtMlp)}
            </StyledHeading>
          </Col>
        </Row>
        <StyledDiv>
          <StyledButtonDiv>
            {/* FIXME: not in line with other back buttons */}
            <Button style={{ width: '100%' }} type='button' theme={themes.lincoln} buttonType='secondary' onClick={() => back && back()}>
              {t(VanityPageText.back)}
            </Button>
          </StyledButtonDiv>
          <StyledButtonDiv>
            <Button style={{ width: '100%' }} type='button' className='ml-0' theme={themes.lincoln} buttonType='primary' onClick={handleOnClick}>
              {isLfg && !isMigrated ? t(VanityPageText.register) : t(VanityPageText.login)}
            </Button>
          </StyledButtonDiv>
        </StyledDiv>
      </StyledBox>
    </>);
};
export default ReRouteInfoPage;
