import { Box, Col, Icon, Row, StepperContext } from '@digitools/honest-react';
import React, { useContext } from 'react';
import useAsDataContext from '../../hooks/useAsDataContext';
import useTranslate from 'src/hooks/useTranslate';
import { reviewGettingStartedText } from '../../constants/reviewGettingStartedText';
import { EditReviewField, GrayBox } from '../review/ReviewField';
import styled from 'styled-components';
import styles from './review.scss';
import { GrayTextDiv, GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import { DivBlock, SectionHeading, StyledRow } from '../../components/LayoutComponents';
import { CustomEmployeeIdText } from 'src/utils/CustomExperienceText';
const HeadingCol = styled(Col) `
  display: flex;
`;
const EditCol = styled(Col) `
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize !important;
  @media only screen and (max-width: 500px) {
    margin-top: -2.5rem;
}
`;
const StyledIcon = styled(Icon) `
    font-size: 3rem; 
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    margin-botton: -0.5rem;
    @media only screen and (max-width: 500px) {
      font-size: 2.5rem; 
      margin-top: -1rem;
  }
  `;
const ReviewGettingStarted = () => {
    const { claimantData } = useAsDataContext();
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const formatSsn = (ssn) => {
        return '*****' + ssn.substring(5);
    };
    const pStyle = {
        lineHeight: '2.25rem',
    };
    const spacerDiv = {
        marginTop: '-0.2rem',
    };
    return (<>
      <Box className={'mt-4'} data-testid='gettingStartedReview'>
        <StyledRow>
          <HeadingCol lg={10} md={10} sm={10} xs={10} className={styles.heading_col}>
            <DivBlock className={'d-flex align-items-center'}>
              <StyledIcon name={'verify-identity'} size='large' color={'primary'}/> 
              <SectionHeading elemType={'h3'}>{t(reviewGettingStartedText.reviewGettingStartPageHeading)}</SectionHeading>
            </DivBlock>
          </HeadingCol>
          <EditCol lg={2} md={2} sm={2} xs={2} className={styles.edit_col}>
            <EditReviewField data-testid='review-edit-field' onClick={() => { stepper.edit(0); }}/>
          </EditCol>
        </StyledRow>
        <GrayBox>{t(reviewGettingStartedText.reviewGettingStartSubHeading)}</GrayBox>
        <Row>
          {claimantData && claimantData?.ssn && (<Col lg={6} md={6} sm={6} xs={6} data-testid='ssnField'>
              <GrayTextDiv>
                {t(reviewGettingStartedText.ssnLabel)}
                <p style={pStyle}>{formatSsn(claimantData && claimantData?.ssn)}</p>
                <GraySpacerDiv style={spacerDiv}/>
              </GrayTextDiv>
            </Col>)}
          {claimantData && claimantData?.empId && (<Col lg={6} md={6} sm={6} xs={6} data-testid='empIdField'>
              <GrayTextDiv>
                {CustomEmployeeIdText(t(reviewGettingStartedText.employeeIdLabel))}
                <p style={pStyle}>{claimantData && claimantData?.empId}</p>
                <GraySpacerDiv style={spacerDiv}/>
              </GrayTextDiv>
            </Col>)}
        </Row>
      </Box>
    </>);
};
export default ReviewGettingStarted;
