import { Box, Heading, Icon, TabContainer, Table, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
const CoinsuranceRows = (props) => {
    const { t } = useTranslate();
    const headerpiece = {
        serviceCategory: '',
        categoryDesc: '',
        covered: true,
        inNetwork: {
            networkCoinsurance: t(DentalText.youPayText).toUpperCase(),
            deductibleApplies: t(DentalText.deductibleAppliesText).toUpperCase(),
        },
        outNetwork: {
            networkCoinsurance: t(DentalText.youPayText).toUpperCase(),
            deductibleApplies: t(DentalText.deductibleAppliesText).toUpperCase(),
        },
    };
    const coinsurances = [headerpiece, ...props.coinsurances];
    return (<>
      {coinsurances.map((coinsurance, index) => (<Table className={'border-0'}>
          {props.isInNetwork ? (<TableRow theme={themes.lincoln} isHoverable={false} className={'border-bottom'}>
              <TableData className={'w-50 border-0'}>
                <Heading elemType={'h4'}>{coinsurance.serviceCategory?.toUpperCase()}</Heading>
              </TableData>
              {index === 0 ? (<>
                  <TableData className={'w-25 border-0 text-left'}>
                    <Heading elemType={'h4'}>{coinsurance.inNetwork.networkCoinsurance}</Heading>
                  </TableData>
                  <TableData className={'w-25 border-0 text-left font-weight-bold'}>
                    <Heading elemType={'h4'}>{coinsurance.inNetwork.deductibleApplies}</Heading>
                  </TableData>
                </>) : (<>
                  <TableData className={'w-25 border-0 text-left font-weight-light'}>
                    {coinsurance.inNetwork.networkCoinsurance}%
                  </TableData>
                  <TableData className={'w-25 border-0 text-left font-weight-light'}>
                    {coinsurance.inNetwork.deductibleApplies}
                  </TableData>{' '}
                </>)}
            </TableRow>) : (<TableRow theme={themes.lincoln} isHoverable={false} className={'border-bottom'}>
              <TableData className={'w-50 border-0'}>
                <Heading elemType={'h4'}>{coinsurance.serviceCategory?.toUpperCase()}</Heading>
              </TableData>
              {index === 0 ? (<>
                  <TableData className={'w-25 border-0 text-left'}>
                    <Heading elemType={'h4'}>{coinsurance.outNetwork.networkCoinsurance}</Heading>
                  </TableData>
                  <TableData className={'w-25 border-0 text-left font-weight-bold'}>
                    <Heading elemType={'h4'}>{coinsurance.outNetwork.deductibleApplies}</Heading>
                  </TableData>
                </>) : (<>
                  <TableData className={'w-25 border-0 text-left font-weight-light'}>
                    {coinsurance.outNetwork.networkCoinsurance}%
                  </TableData>
                  <TableData className={'w-25 border-0 text-left font-weight-light'}>
                    {coinsurance.outNetwork.deductibleApplies}
                  </TableData>{' '}
                </>)}
            </TableRow>)}
        </Table>))}
    </>);
};
const Coinsurance = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    let memberEvent = 'subscriber';
    if (currentMember && subscriber?.dependents?.indexOf(currentMember) !== -1) {
        memberEvent = 'member' + (subscriber?.dependents?.indexOf(currentMember) + 1);
    }
    const trackDropdownEvent = (tab) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_name: tab,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            event_version: memberEvent,
        });
    };
    return (<>
      {currentMember && (<>
          {currentMember.deductiblesAndCoinsurance && (<Box theme={themes.lincoln}>
              <Table>
                <TableRow className={'border-0'} isHoverable={false}>
                  <TableHeaderCol className={'border-0 mr-0 mb-2'}>
                    <Heading data-testid='coinsurance' elemType={'h2'}>
                      {t(DentalText.coinsuranceTitleText)}
                      <Icon className={'ml-3 align-middle'} size='xs' name='info' color={'secondary'} title={'coinsurance info'}/>
                    </Heading>
                  </TableHeaderCol>
                </TableRow>
              </Table>
              <TabContainer>
                {currentMember.deductiblesAndCoinsurance.inNetworkLabel.replace(/\s/g, '').length && (<TabSection onClick={() => {
                        trackDropdownEvent(currentMember?.deductiblesAndCoinsurance?.inNetworkLabel + ' ' + t(DentalText.coinsuranceText));
                    }} name={currentMember.deductiblesAndCoinsurance.inNetworkLabel}>
                    <CoinsuranceRows coinsurances={currentMember.deductiblesAndCoinsurance.coinsurances} isInNetwork={true}/>
                  </TabSection>)}
                {currentMember.deductiblesAndCoinsurance.outNetworkLabel.replace(/\s/g, '').length && (<TabSection onClick={() => {
                        trackDropdownEvent(currentMember?.deductiblesAndCoinsurance?.outNetworkLabel + ' ' + t(DentalText.coinsuranceText));
                    }} name={currentMember.deductiblesAndCoinsurance.outNetworkLabel}>
                    <CoinsuranceRows coinsurances={currentMember.deductiblesAndCoinsurance.coinsurances} isInNetwork={false}/>
                  </TabSection>)}
              </TabContainer>
            </Box>)}
        </>)}
    </>);
};
export default Coinsurance;
