export const EEfindMyInformationTextEs = {
    heading: 'Verificar mi identidad',
    body: 'Para garantizar un medio seguro y confidencial para que pueda ver su información, debe escribir la información de abajo una vez.',
    number: 'Número',
    dateOfBirth: 'Fecha de nacimiento',
    lastName: 'Apellido',
    button: 'Buscar',
    toolTip: 'El número o ID está en el reporte de confirmación que recibió en línea o, si hizo el reporte telefónicamente, le dieron dicha ID por teléfono. También la podrá encontrar en la correspondencia que recibió por email.',
    successMessage: 'Éxito! Su información ha sido guardada.',
    warningMessage: 'No deje o de ID de reclamación Número se coincida con solicitud. Usted puede verificar su número e intėntelo de nuevo. Tiempo de procesamiento para la presentación de nuevas reclamaciones o las hojas pueden causar un retraso, por favor, intėntelo de nuevo más tarde.',
};
export const EEfindMyInformationTextEn = {
    heading: 'Verify my identity',
    body: 'To ensure a secure and confidential means for you to view your information, you are required to enter the below information one time.',
    number: 'Number',
    dateOfBirth: 'Date of birth',
    lastName: 'Last name',
    button: 'Find',
    toolTip: 'The number or ID is located on the confirmation report you received online, or if you reported telephonically, this was provided via phone. This would also be noted on correspondence you received via email.',
    successMessage: 'Success! Your information has been saved.',
    warningMessage: 'No leave ID or claim number was found matching your request. You may check your number and try again. Processing time for new claims or leaves may cause a delay, please try again later.',
};
