import { Heading, Loader, Stepper, themes } from '@digitools/honest-react';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { AboutYourAbsenceText } from 'src/packages/cli/constants/AboutYourAbsenceText';
import { AboutYouText } from 'src/packages/cli/constants/AboutYouText';
import { ReviewText } from 'src/packages/cli/constants/ReviewText';
import { Experience } from 'src/types/Experience';
import useTranslate from '../../../../hooks/useTranslate';
import { TaggedCustomerName } from '../../components/UtilityComponents';
import { CliText } from '../../constants/CliText';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import useCliDataContext from '../../hooks/useCliDataContext';
import AboutYou from '../aboutYou/AboutYou';
import AboutYourAbsence from '../aboutYourAbsence/AboutYourAbsence';
import AdditionalInfo from '../additionalInfo/AdditionalInfo';
import Notifications, { FunctionalArea } from 'src/components/Notifications/Notifications';
import Review from '../review/Review';
import GettingStarted from '../start/GettingStarted';
import GettingStartedText from '../start/GettingStartedText';
import React, { useEffect } from 'react';
import { LeaveType } from '../../providers/CliConfigProvider';
import MedicalContacts from '../aboutYourAbsence/MedicalContacts';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import PaymentMethod from '../payment/PaymentMethod';
import PaymentMethodText from '../payment/PaymentMethod.Text';
import useAuthentication from 'src/hooks/useAuthentication';
import PageLoader from 'src/components/PageLoader/PageLoader';
const Intake = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { cliConfigLoading, configLoadingV2, hasAdditionalInfo, isClaimCustomer, isClaimLeaveCustomer, isPaidLeaveCustomer, isLeaveCustomer, } = useCliCustomerConfigContext();
    const { absenceData, isLoading, isPrePopLoading } = useCliDataContext();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            page_l4: experience === Experience.EMPLOYEE ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER,
        });
    }, []);
    const showPaymentMethod = experience === Experience.EMPLOYEE && user?.customerInContext?.smallMid;
    const stepTitles = [
        t(GettingStartedText.pageTitle),
        CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutYouText.pageTitleEE) : t(AboutYouText.pageTitleER)),
        experience === Experience.EMPLOYEE ? t(AboutYourAbsenceText.pageTitleEE) : t(AboutYourAbsenceText.pageTitleER),
        ...(experience === Experience.EMPLOYEE &&
            (absenceData?.leaveType === LeaveType.OWN_ILLNESS ||
                absenceData?.leaveType === LeaveType.OTHER ||
                absenceData?.leaveType === LeaveType.PREGNANT)
            ? [t(AboutYourAbsenceText.contactTitle)]
            : []),
        ...(experience === Experience.EMPLOYER && hasAdditionalInfo ? ['Additional info'] : []),
        ...(showPaymentMethod ? [t(PaymentMethodText.pageTitle)] : []),
        t(ReviewText.pageTitle),
    ];
    const paymentStepNumber = stepTitles.indexOf(t(PaymentMethodText.pageTitle));
    const claimCustomer = isClaimCustomer && !isLeaveCustomer && !isClaimLeaveCustomer;
    const leaveCustomer = isLeaveCustomer && !isClaimCustomer && !isClaimLeaveCustomer;
    const isBothCustomer = isClaimLeaveCustomer || (isClaimCustomer && isPaidLeaveCustomer);
    const appLoading = cliConfigLoading || configLoadingV2;
    return (<>
      {appLoading ? (<PageLoader />) : (<Loader viewportCentered={false} id='cli-intake-spinner' isShown={isLoading} data-testid={'cli-intake-spinner'}>
          <TaggedCustomerName />
          <div data-testid='cli-intake-box' data-public={true} style={{ marginTop: '1.875rem' }}>
            <Notifications data-testid='intake-notification-banner' functionalArea={FunctionalArea.CLI}/>
            <Heading data-testid='intake-title-heading' theme={themes.lincoln} style={{ marginBottom: '1.5rem' }}>
              {claimCustomer && !isBothCustomer && (<span data-testid='intake-title-heading-claim'>{t(CliText.intakeHeadingClaimCustomer)}</span>)}
              {leaveCustomer && !isBothCustomer && (<span data-testid='intake-title-heading-leave'>{t(CliText.intakeHeadingLeaveCustomer)}</span>)}
              {isBothCustomer && (<span data-testid='intake-title-heading-claim-leave'>{t(CliText.intakeHeadingClaimLeaveCustomer)}</span>)}
            </Heading>
            {/*  @ts-ignore */}
            <Stepper stepTitles={stepTitles} theme={themes.secureSite} data-testid={'cli-stepper'}>
              <GettingStarted />
              {!isPrePopLoading && <AboutYou />}
              {!isPrePopLoading && <AboutYourAbsence />}
              {experience === Experience.EMPLOYEE &&
                (absenceData?.leaveType === LeaveType.OWN_ILLNESS ||
                    absenceData?.leaveType === LeaveType.OTHER ||
                    absenceData?.leaveType === LeaveType.PREGNANT) && <MedicalContacts />}
              {experience === Experience.EMPLOYER && hasAdditionalInfo && <AdditionalInfo />}
              {showPaymentMethod && <PaymentMethod paymentStepNumber={paymentStepNumber}/>}
              <Review />
            </Stepper>
          </div>
        </Loader>)}
    </>);
};
export default Intake;
