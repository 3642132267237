export const LoginDisplayTextEn = {
    auth0error: ' Please try clearing your browsers cookies and submitting your information again, if you continue to experience any problems, contact us for further assistance.',
    accessDeniedBody: 'Invalid username or password. Your account will be blocked after 5 invalid attempts.',
    accessDeniedTitle: 'Log in failed!',
    accountUnblockedBody: 'Your account has been unblocked.',
    accountUnblockedTitle: 'Success!',
    brandingMessageBody: 'Effective September 1, 2019, Liberty Life Assurance Company of Boston has been renamed to The Lincoln National Life Insurance Company. You may continue to see references to both names during the transition.',
    brandingMessageTitle: 'Brand Transition Update',
    close: 'Close',
    emailExpiredBody: 'Log In to resend verification email.',
    emailExpiredTitle: 'Email link expired!',
    emailVerifiedBody: 'Your email has been verified.',
    emailVerifiedTitle: 'Success!',
    // Email Verification Modal Text
    emailVerificationHelp: 'Never received a verification email?',
    emailVerificationLink: 'Learn more',
    emailVerificationModalTitle: 'Steps to verify your email',
    emailVerificationHeader: "If you can't find the email after we send it, these steps can help.",
    emailVerificationSection1Title: 'Check your spam and junk folders',
    emailVerificationSection1Body: "A spam filter or email rule might have moved the email. Search for an email with the subject of 'Confirm your My Lincoln Portal email' in the Spam, Junk, Trash, Deleted Items or Archive folder.",
    emailVerificationSection2Title: 'Add us to your trusted sender list',
    emailVerificationSection2Body: 'Add donotreply@mail.mylincolnportal.com to your trusted sender list.',
    emailVerificationSection3Title: 'Resend the email',
    emailVerificationSection3Body: 'To resend the verification email, log in to your account and a new verification email will be sent automatically.',
    emailVerificationSection4Title: 'Wait up to 24 hours',
    emailVerificationSection4Body: 'The verification email to complete setting up your account may take up to 24 hours to arrive. If after 24 hours you have still not received the verification email please contact us.',
    //
    emailUs: 'Email us ',
    passwordResetExpiredBody: 'Please use the Forgot Username & Password link below or login to reset your password in your profile.',
    passwordResetExpiredTitle: 'Link invalid or expired!',
    floodingMessageBody: 'Our thoughts are with those impacted by the flooding in the Midwest. If an employee has a claim, Lincoln Financial is here to help. Please call your assigned claims office for assistance.',
    floodingMessageTitle: 'Flooding in the Midwest',
    newExperienceBody: 'We have rolled out a more secure My Lincoln Portal experience. All users will be prompted to review and update their login credentials to meet Lincoln’s security standards and register for two-factor authentication.',
    newExperienceTitle: 'New Login Experience',
    invalidAttemptBody: 'Invalid username or password. Your account will be blocked after 5 invalid attempts.',
    invalidAttemptTitle: 'Log in failed!',
    invalidAttemptSiteMinderBody: 'Invalid username or password. After 5 invalid log in attempts, your account will be locked for 15 minutes.',
    invalidAttemptSiteMinderTitle: 'Log in failed!',
    userProfileNotFoundBody: 'This username is not associated with an account.',
    userProfileNotFoundTitle: 'Log in failed!',
    login: 'Log in',
    password: 'Password',
    passwordPlaceholder: 'Enter password',
    tooManyAttemptsBody: 'Click the link in the email that was sent to you to unblock your account.',
    tooManyAttemptsTitle: 'Too Many Attempts, Account Blocked!',
    unexpectedBody: 'Please try submitting your information again, if you continue to experience any problems, contact us for further assistance.',
    unexpectedTitle: 'Something went wrong!',
    username: 'Username',
    usernamePlaceholder: 'Enter username',
    registerForAccount: 'Register for an account ',
    newUser: 'New user?',
    forgotUserNamePassword: 'Forgot username or password?',
    scheduledMaintenanceBodySection1: ` During this time:`,
    scheduledMaintenanceBodySection2: ` if you need to complete an evidence of insurability application, please return to the portal on Wednesday, October 16`,
    scheduledMaintenanceBodySection3: ` if you need to file a new claim or leave, please contact your telephonic intake line for assistance`,
    scheduledMaintenanceBodySection4: ` We apologize for the inconvenience and appreciate your patience. Thank you for using My Lincoln Portal!`,
    //
    // Error messages
    //
    error_usernameRequired: 'Username is required.',
    error_passwordRequired: 'Password is required.',
    performanceDegradationBody: 'Please be advised that My Lincoln Portal is currently experiencing intermittent performance issues. We are actively working to resolve the problem as quickly as possible.',
    notice: 'Notice: ',
    thirdPartyCookies: 'This website requires third-party cookies. Please enable third-party cookies in your browser or contact your administrator for support. If you continue to experience issues please call us.',
    wildFireMessageTitle: 'California Wildfires:',
    wildFireMessageEmployeeBody: 'Our thoughts are with those being impacted by wildfires in California. If you have a related disability claim, Lincoln Financial Group is here to help. Please click the Contacts link below.',
    wildFireMessageEmployerBody: 'Our thoughts are with those being impacted by wildfires in California. If you have a related disability claim, Lincoln Financial Group is here to help.',
    // Intermittent Issue Alert Message Title / Body
    intermittentIssueTitle: 'ATTENTION:',
    intermittentIssueBody: 'Please be advised that we are experiencing intermittent issues with new user registration. We are actively working to resolve the problem as quickly as possible.',
    // Portal down Issue Alert Message Title / Body
    portalDownTitle: 'Planned System Maintenance:',
    portalDownBody: 'The Lincoln Financial Group website is currently undergoing maintenance. It is temporarily unavailable. We apologize for any inconvenience.',
    usernameRequiredMessage: 'Username is required for login. Please enter your username.',
    passwordRequiredMessage: 'Password is required for login. Please enter your password.',
    skipMainContent: 'Skip to main content',
};
