import { decode } from '../packages/status/utilities/hash';
export const gaLabelFactory = (featurePrefix) => (gaLabel) => `${featurePrefix}__${gaLabel}`;
/**
 *  Remove encoded values like employee Id or event Id from URL
 */
export const urlCheckAndReplaceEncodedValues = (url) => {
    return url.split('/').reduce((prev, curr) => {
        return !!decode(curr) ? prev.replace(`/${curr}`, '') : prev;
    }, url);
};
