import React, { useEffect, useRef, useState } from 'react';
import ToolTip from '@digitools/honest-react/dist/lib/components/ToolTip';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import ToggleSwitch from '@digitools/honest-react/dist/lib/components/ToggleSwitch';
import Slideout from '@digitools/honest-react/dist/lib/components/Slideout';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import { Form } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import axios from 'axios';
import useAuthentication from '../../../../hooks/useAuthentication';
import { formatPhone, isNotEmpty, validatePhone } from '../../../../utils/validationUtils';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import useTealium from "../../../../hooks/useTealium";
import { TEALIUM_EVENT } from "../../../../constants/tealiumConstants";
// TODO: add the real spanish here when we receive the spanish from the powers that be.
const CommunicationPreferencesText = {
    toggleLabel: {
        english: 'Receive text notifications about your absence ',
        spanish: 'Recibir notificaciones por mensaje de texto sobre su ausencia ',
    },
    stayInTheKnow: {
        english: 'Stay in the know',
        spanish: '¡Manténgase informado!',
    },
    receiveNotification: {
        english: 'Opt-in to receive text notifications.  To learn more, or change your settings, visit Alerts & notifications in your ',
        spanish: 'Suscríbase para recibir notificaciones por mensaje de texto. Para obtener más información o cambiar su configuración, ingrese en su perfil y ',
    },
    yourProfile: {
        english: 'Profile',
        spanish: 'diríjase a Alertas y notificaciones',
    },
    success: {
        english: 'Success',
        spanish: '¡Excelente!',
    },
    optedIn: {
        english: 'You have opted in to receive text notifications about your absence. To change your preferences at any time, visit Alerts & notifications in your ',
        spanish: 'Se ha suscrito para recibir notificaciones por mensaje de texto sobre su ausencia. Para cambiar sus preferencias en cualquier momento, ingrese en su perfil ',
    },
    provideMobileNumber: {
        english: 'Provide your mobile number to receive text notifications when there is a status change or return to work communication.',
        spanish: 'Proporcione su número de teléfono móvil para recibir notificaciones por mensaje de texto cuando haya un cambio de estado o una comunicación de reincorporación al trabajo.',
    },
    save: {
        english: 'Save',
        spanish: 'Guardar',
    },
    changePreferences: {
        english: 'You may change your preferences at anytime by visiting Alerts & notifications in your ',
        spanish: 'Puede cambiar sus preferencias en cualquier momento; para hacerlo, ingrese en su perfil y  ',
    },
    error: {
        english: 'Error saving notification preferences.',
        spanish: 'Error al guardar las preferencias de notificación.',
    },
    mobileNumber: {
        english: 'Mobile number',
        spanish: 'Número de teléfono móvil',
    },
    timeZone: {
        english: 'Time zone',
        spanish: 'Zona horaria',
    },
};
const CommunicationPreferences = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const setValueRef = useRef();
    const { trackEvent, trackView } = useTealium();
    useEffect(() => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.ONLOAD,
            event_name: TEALIUM_EVENT.EVENT_TYPE.TOGGLE_TEXT_NOTIFICATIONS,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_version: 'details'
        });
    }, []);
    const onSubmit = async (values) => {
        setButtonDisabled(true);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_ALERTS_NOTIFICATIONS,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_version: 'details'
        });
        const notification = {
            method: 'TXT',
            phoneNumber: values.mobileNumber.replace(/-/g, ''),
            timeZone: values.timeZone,
        };
        const payload = {
            confirmation: props.confirmation ? props.confirmation : notification,
            notification,
            claimLeaveNumber: '',
        };
        try {
            await axios.put('/mlp-ui-service/services/v1/communicationPreferences', payload, {
                headers: { Authorization: authHeader() },
            });
            setIsError(false);
            setIsOpen(false);
            setShowSuccess(true);
        }
        catch (error) {
            setIsError(true);
        }
        setButtonDisabled(false);
    };
    const timeZones = [
        {
            label: t({ english: 'Eastern', spanish: 'Este' }),
            value: 'EST',
        },
        {
            label: t({ english: 'Central', spanish: 'Centro' }),
            value: 'CST',
        },
        {
            label: t({ english: 'Mountain', spanish: 'Montaña' }),
            value: 'MST',
        },
        {
            label: t({ english: 'Pacific', spanish: 'Pacifico' }),
            value: 'PST',
        },
        {
            label: t({ english: 'Alaska', spanish: 'Alaska' }),
            value: 'AKST',
        },
        {
            label: t({ english: 'Hawaii-Aleutian', spanish: 'Hawái y Aleutiana' }),
            value: 'HAST',
        },
    ];
    const setIsOpenOverride = (open) => {
        if (setValueRef && setValueRef.current) {
            setValueRef.current(open);
        }
        setIsOpen(open);
    };
    const redirectToCommunicationPreferences = () => {
        window.location.assign('/customer/profile/alertsNotifications');
    };
    const YourProfileButton = (<Button type={'button'} theme={themes.lincoln} style={{ whiteSpace: 'normal', padding: 0, textAlign: 'left', height: 'auto' }} onClick={() => redirectToCommunicationPreferences()}>
      {t(CommunicationPreferencesText.yourProfile)}
    </Button>);
    const ToolTipText = (<>
      <strong>{t(CommunicationPreferencesText.stayInTheKnow)}</strong>
      <p>
        <span>{t(CommunicationPreferencesText.receiveNotification)}</span>
        {YourProfileButton}
      </p>
    </>);
    const toggleOnClick = () => {
        setIsOpen(!isOpen);
        setIsError(false);
    };
    return showSuccess ? (<Alert theme={themes.lincoln} type={'success'} style={{ marginTop: '1rem', fontSize: '14px' }} data-testid={'success-alert'}>
      <strong>{t(CommunicationPreferencesText.success)}! </strong>
      <span>
        {t(CommunicationPreferencesText.optedIn)} {YourProfileButton}
      </span>
    </Alert>) : (<>
      <div style={{
            marginRight: '0.25rem',
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
        <span style={{
            marginLeft: 'auto',
            marginRight: '0.25rem',
            marginTop: 'auto',
            marginBottom: 'auto',
        }}>
          {t(CommunicationPreferencesText.toggleLabel)}
        </span>
        <div style={{ marginTop: 'auto', marginRight: '0.25rem', marginBottom: 'auto' }}>
          <ToolTip iconName={'question'} iconColor={'secondary'} showOnHover={false} toolText={ToolTipText} position={'bottom'} theme={themes.lincoln} style={{ pointerEvents: 'auto', width: 'auto' }}/>
        </div>
        <ToggleSwitch field={'notificationsToggle'} initialValue={false} theme={themes.lincoln} color={'secondary'} onClick={toggleOnClick} 
    //@ts-ignore FIXME: type error
    setValueRef={setValueRef} data-testid={'notifications-toggle'}/>
      </div>
      <div style={{ marginTop: '3rem' }}>
        {/* @ts-ignore */}
        <Slideout theme={themes.lincoln} heading={`${t(CommunicationPreferencesText.stayInTheKnow)}!`} openButton={''} inline={true} hideButton={true} isOpenOverride={isOpen} setIsOpenOverride={setIsOpenOverride}>
          <Form onSubmit={onSubmit}>
            <div style={{ fontSize: '1rem' }}>
              <p>{t(CommunicationPreferencesText.provideMobileNumber)}</p>
              <TextInput aria-label={t(CommunicationPreferencesText.mobileNumber)} data-testid={'phone'} field={'mobileNumber'} label={t(CommunicationPreferencesText.mobileNumber)} mask={formatPhone} placeholder={'XXX-XXX-XXXX'} style={{ height: 'auto' }} theme={themes.lincoln} validate={validatePhone} validateOnBlur={true}/>
              <SelectInput aria-label={t(CommunicationPreferencesText.timeZone)} data-testid={'timezone'} field={'timeZone'} label={t(CommunicationPreferencesText.timeZone)} options={timeZones} style={{ height: 'auto' }} theme={themes.lincoln} validate={isNotEmpty}/>
              <Button buttonType={'primary'} type={'submit'} theme={themes.lincoln} style={{ width: '100%' }} disabled={buttonDisabled} data-testid={'notification-save-button'}>
                {t(CommunicationPreferencesText.save)}
              </Button>
              <div style={{ marginTop: '0.5rem' }}>
                {t(CommunicationPreferencesText.changePreferences)} {YourProfileButton}
              </div>
              {isError && (<span data-testid={'notification-error'}>
                  <Icon name={'error'} size={'xs'} theme={themes.lincoln} color={'primary'}/>{' '}
                  {t(CommunicationPreferencesText.error)}
                </span>)}
            </div>
          </Form>
        </Slideout>
      </div>
    </>);
};
export default CommunicationPreferences;
