import { Alert } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import PaymentMethodText from './PaymentMethod.Text';
const DisplayAlert = ({ type }) => {
    const { t } = useTranslate();
    return (<Alert data-testid={`${type}-msg-alert`} type={type == 'warning' ? 'error' : type} aria-labelledby={`${type}-msg-alert`}>
      {type == 'error' && (<>
          {t(PaymentMethodText.errorMessage)}
          <a href='/customer/home/contacts'> {t(PaymentMethodText.contactUs)}</a>.
        </>)}

      {type == 'success' && t(PaymentMethodText.successMessage)}
      {type == 'warning' && t(PaymentMethodText.warningMessage)}
    </Alert>);
};
export default DisplayAlert;
