import { CrsActionEnum } from './crsActions';
import { fieldsInitialState, initialState } from './utils/InitialState';
// List of sensitive fields
const sensitiveFieldsList = ['Employee SSN', 'Gender', 'Employee ID', 'Employee Birth Date'];
// List of claim product
const claimProductsNameList = ['LTD', 'LTDCP', 'SALCN', 'STAT', 'STD', 'STDCP', 'PL'];
const crsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CrsActionEnum.START_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CrsActionEnum.STOP_LOADING: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CrsActionEnum.FILTER_PAGE_ACTION: {
            const { products, status, dateSearchBy, fromDate, toDate, workStates, workProvinces, selectedSubLocs, subLocs, } = action.payload;
            const updatedProducts = handleProductsSelection([...initialState.products], products);
            const updateStatusCodes = handleStatusCodeSelection([...initialState.filterStatusCodes], status);
            const reportDateRange = { dateSearchBy, fromDate, toDate };
            return {
                ...state,
                updatedProducts,
                updateStatusCodes,
                reportDateRange,
                workStates,
                workProvinces,
                selectedSubLocs,
                subLocs,
            };
        }
        case CrsActionEnum.REPORT_FILTER_BY_CATEGORY: {
            let fieldsForReset = JSON.parse(JSON.stringify(fieldsInitialState));
            state.fieldPageInfo.personalInfoFields.forEach((field) => (field.status = false));
            fieldsForReset.personalInfoFields = state.fieldPageInfo.personalInfoFields;
            state.fieldPageInfo = fieldsForReset;
            fieldsForReset = updateCustomContent(state, state.employeeTextCustomContent, '');
            return { ...state, reportFilterCategory: action.payload, fieldPageInfo: fieldsForReset };
        }
        case CrsActionEnum.FIELD_SELECTION: {
            return fieldReducer(state, action);
        }
        case CrsActionEnum.CONFIG_DATA_UPDATE: {
            state.callCustomerConfig = false;
            state.employeeTextCustomContent = action.payload.employeeText ? action.payload.employeeText : '';
            const filterReportLabels = updateFilterReportLabels(action.payload);
            state.fieldPageInfo.personalInfoFields = updateFieldsFromConfig(state.fieldPageInfo.personalInfoFields, action.payload);
            const fieldPageInfo = updateCustomContent(state, action.payload.employeeText, action.payload.employeeIdText);
            state.products = updateProductsFromConfig(state.products, action.payload);
            return {
                ...state,
                fieldPageInfo,
                filterReportLabels,
                isLoading: false,
            };
        }
        case CrsActionEnum.UPDATE_RUN_REPORT_ID: {
            return {
                ...state,
                isLoading: false,
                reportId: action.payload,
                reportCountCallSuccess: true,
            };
        }
        case CrsActionEnum.RUN_REPORT_SPINNER: {
            return {
                ...state,
                showRunReportSpinner: action.payload,
            };
        }
        default:
            return state;
    }
};
export default crsReducer;
const fieldReducer = (state, action) => {
    let fieldPageInfo = {
        ...state.fieldPageInfo,
    };
    const personalInfoFields = statusUpdate(fieldPageInfo.personalInfoFields, action.payload.personalInfoFields);
    fieldPageInfo = { ...state.fieldPageInfo, personalInfoFields };
    const workInfoFields = statusUpdate(fieldPageInfo.workInfoFields, action.payload.workInfoFields);
    fieldPageInfo = { ...state.fieldPageInfo, workInfoFields };
    switch (action.payload.reportFilterCategory) {
        case 'Claim': {
            const claimDatesFields = statusUpdate(fieldPageInfo.claimDatesFields, action.payload.dateInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, claimDatesFields };
            const claimDetailFields = statusUpdate(fieldPageInfo.claimDetailFields, action.payload.detailInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, claimDetailFields };
            break;
        }
        case 'Leave': {
            const leaveDatesFields = statusUpdate(fieldPageInfo.leaveDatesFields, action.payload.dateInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, leaveDatesFields };
            const leaveDetailFields = statusUpdate(fieldPageInfo.leaveDetailFields, action.payload.detailInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, leaveDetailFields };
            break;
        }
        case 'Accommodation Services': {
            const accommodationDatesFields = statusUpdate(fieldPageInfo.accommodationDatesFields, action.payload.dateInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, accommodationDatesFields };
            const accommodationDetailFields = statusUpdate(fieldPageInfo.accommodationDetailFields, action.payload.detailInfoFields);
            fieldPageInfo = { ...state.fieldPageInfo, accommodationDetailFields };
            break;
        }
        default:
            return state;
    }
    return { ...state, fieldPageInfo, reportCountCallSuccess: false };
};
const statusUpdate = (infoFields, payload) => {
    for (const infoField of infoFields) {
        const filteredField = payload.filter(field => field.field === infoField.field);
        infoField.status = filteredField && filteredField[0] ? filteredField[0].status : infoField.status;
    }
    return infoFields;
};
const updateProductsFromConfig = (products, payload) => {
    const sharedProducts = payload.sharedProducts;
    const isProductAvailable = (product) => sharedProducts && sharedProducts.findIndex(shareProduct => shareProduct.productName === product.code) > -1;
    const grantedProducts = products
        .filter(product => isProductAvailable(product))
        .map(product => {
        const matchingProduct = sharedProducts.find(sharedProduct => sharedProduct.productName === product.code);
        product.label = matchingProduct && matchingProduct.productText ? matchingProduct.productText : product.label;
        return product;
    });
    return grantedProducts;
};
const updateFilterReportLabels = (payload) => {
    const { sharedProducts } = payload;
    const filterReportLabels = [];
    const claimEnabled = sharedProducts.some((product) => claimProductsNameList.includes(product.productName.toUpperCase()));
    const leaveEnabled = sharedProducts.some((product) => product.productName.toUpperCase() === 'LEAVE');
    const accommodationEnabled = sharedProducts.some((product) => product.productName.toUpperCase() === 'AS');
    if (claimEnabled) {
        filterReportLabels.push({
            label: 'Claim',
            value: 'Claim',
        });
    }
    if (leaveEnabled) {
        filterReportLabels.push({
            label: 'Leave',
            value: 'Leave',
        });
    }
    if (accommodationEnabled) {
        filterReportLabels.push({
            label: 'Accommodation Services',
            value: 'Accommodation Services',
        });
    }
    return filterReportLabels;
};
const updateFieldsFromConfig = (infoFields, payload) => {
    // Divide senstive and non-sensitve fields from infoFields
    const nonSensitiveInfoFields = infoFields.filter(infoField => !sensitiveFieldsList.includes(infoField.field));
    const sensitiveFields = infoFields.filter(infoField => sensitiveFieldsList.includes(infoField.field));
    // fields enabled for user (extracted from auth0 token)
    const sharedFields = payload.sharedFields;
    // Filter above sensitive fileds based on sharedFields
    const grantedSensitiveFields = sensitiveFields.filter(field => sharedFields && sharedFields.findIndex(sharedField => sharedField.fieldText === field.field) > -1);
    // unify sensitive and non-sensitive fields
    const filteredFields = [...nonSensitiveInfoFields, ...grantedSensitiveFields];
    // Ordering to display alphabetically top down in UI
    const sortedFilteredFields = infoFields.filter(field => filteredFields.includes(field));
    return sortedFilteredFields;
};
const updateCustomContent = (state, employeeText, employeeIdText) => {
    let fieldPageInfo = {
        ...state.fieldPageInfo,
    };
    const personalInfoFields = updateFieldCustomContent(fieldPageInfo.personalInfoFields, employeeText, employeeIdText);
    fieldPageInfo = { ...state.fieldPageInfo, personalInfoFields };
    const workInfoFields = updateFieldCustomContent(fieldPageInfo.workInfoFields, employeeText, employeeIdText);
    fieldPageInfo = { ...state.fieldPageInfo, workInfoFields };
    if (state.reportFilterCategory === 'Claim') {
        const claimDatesFields = updateFieldCustomContent(fieldPageInfo.claimDatesFields, employeeText, employeeIdText);
        fieldPageInfo = { ...state.fieldPageInfo, claimDatesFields };
        const claimDetailFields = updateFieldCustomContent(fieldPageInfo.claimDetailFields, employeeText, employeeIdText);
        fieldPageInfo = { ...state.fieldPageInfo, claimDetailFields };
    }
    else if (state.reportFilterCategory === 'Leave') {
        const leaveDetailFields = updateFieldCustomContent(fieldPageInfo.leaveDetailFields, employeeText, employeeIdText);
        fieldPageInfo = { ...state.fieldPageInfo, leaveDetailFields };
    }
    return fieldPageInfo;
};
const updateFieldCustomContent = (infoFields, employeeText, employeeIdText) => {
    employeeText = employeeText ? employeeText : '';
    employeeIdText = employeeIdText ? employeeIdText : 'ID';
    infoFields
        .filter(field => field.hoverText.search('CustomContent}') > -1 || field.fieldLabel.search('CustomContent}') > -1)
        .map(field => {
        field.hoverText = field.hoverText.replace('{EmpCustomContent}', employeeText);
        field.fieldLabel =
            field.fieldLabel.search('{EmpCustomContent}') > -1
                ? field.fieldLabel.replace('{EmpCustomContent}', employeeText)
                : field.fieldLabel.replace('{EmpIdCustomContent}', employeeIdText);
    });
    return infoFields;
};
const handleProductsSelection = (initialProducts, selectedProducts) => {
    let updatedProducts = [];
    if (selectedProducts.includes('All')) {
        updatedProducts = initialProducts.map((product) => {
            product.checked = true;
            return product;
        });
    }
    else {
        updatedProducts = initialProducts
            .map((product) => {
            product.checked = false;
            return product;
        })
            .filter((product) => selectedProducts.includes(product.code))
            .map((product) => {
            product.checked = true;
            return product;
        });
    }
    return updatedProducts;
};
const handleStatusCodeSelection = (initialStatusCodes, status) => {
    let updateStatusCodes = [];
    if (status.includes('AL')) {
        updateStatusCodes = initialStatusCodes.filter((statusCode) => (statusCode.checked = true));
    }
    else {
        updateStatusCodes = initialStatusCodes
            .map((statusCode) => {
            statusCode.checked = false;
            return statusCode;
        })
            .filter((statusCode) => status.includes(statusCode.code))
            .map((statusCode) => {
            statusCode.checked = true;
            return statusCode;
        });
    }
    return updateStatusCodes;
};
