import Cookies from 'js-cookie';
import React from 'react';
import ReactGA from 'react-ga';
import { Experience } from '../types/Experience';
import withAuthentication from '../hocs/withAuthentication';
import { withRouter } from 'src/utils/withRouter';
// ga category and action consts
export var EventCategory;
(function (EventCategory) {
    EventCategory["ACCORDION"] = "accordion";
    EventCategory["BUTTON"] = "button";
    EventCategory["CHECKBOX"] = "checkbox";
    EventCategory["DOWNLOAD"] = "download";
    EventCategory["LINK"] = "link";
    EventCategory["POPUP"] = "popup";
    EventCategory["PRINT"] = "print";
    EventCategory["TAB"] = "tab";
    EventCategory["TEXTBOX"] = "textbox";
    EventCategory["ERROR"] = "error";
    EventCategory["INFO"] = "info";
    EventCategory["DROPDOWN"] = "dropdown";
    EventCategory["PREFERENCES"] = "preferences";
    EventCategory["VIDEO"] = "video";
})(EventCategory || (EventCategory = {}));
export var EventAction;
(function (EventAction) {
    EventAction["CLICK"] = "click";
    EventAction["ENTERVALUE"] = "enter value";
    EventAction["SUCCESS"] = "success";
    EventAction["PAGE_LOAD"] = "page load";
    EventAction["SELECT"] = "select";
    EventAction["VIDEO_ENGAGEMENT"] = "video engagement";
})(EventAction || (EventAction = {}));
export const GoogleAnalyticsContext = React.createContext({
    trackClick: () => { },
    trackError: () => { },
    trackEvent: () => { },
    trackTextInput: () => { },
    trackClickWithPage: () => { },
    trackPageView: () => { },
    trackWithLabel: () => { },
    gaCode: 'test',
});
/*
 * GoogleAnalyticsProvider - Automatically provides google analytics functions to all
 * wrapped components when used along with WithGoogleAnalytics.
 */
export class GoogleAnalyticsProvider extends React.Component {
    state = {
        gaCode: '',
    };
    urlChanged = false;
    isPublicPage = false;
    /**
     *  Trigger GA pageviews on update to path
     *  Leaving this commented out as might need to revert back if analytics starts missing pageviews - IM 7.27.22
     */
    // public componentDidUpdate(prevProps: IGoogleAnalyticsProviderPropsWithRouter) {
    //   if (this.props.location.pathname !== prevProps.location.pathname) {
    //     this.urlChanged = true;
    //   }
    //   this.isPublicPage = this.props.location.pathname.indexOf('/public') > -1;
    //   if (
    //     this.urlChanged &&
    //     !this.props.authenticationContext.loading &&
    //     (this.isPublicPage || (!this.isPublicPage && this.props.experienceContext.experience !== 'Public'))
    //   ) {
    //     // ReactGA.pageview(urlCheckAndReplaceEncodedValues(this.props.location.pathname));
    //     ReactGA.ga(
    //       'send',
    //       'pageview',
    //       this.getCustomDimensions(urlCheckAndReplaceEncodedValues(this.props.location.pathname)),
    //     );
    //     this.urlChanged = false;
    //   }
    // }
    /**
     * Initialize GA with correct code & trigger first pageview based on path
     */
    componentDidMount() {
        const gaCode = this.getGoogleAnalyticsCode();
        ReactGA.initialize(gaCode);
        // ReactGA.pageview(urlCheckAndReplaceEncodedValues(this.props.location.pathname));
        // ReactGA.ga('send', 'pageview', this.getCustomDimensions(urlCheckAndReplaceEncodedValues(this.props.location.pathname)));
        this.setState({
            gaCode,
        });
    }
    /**
     * Selects the appropriate GA code based on the host, defaults to the 'local' ga code
     */
    getGoogleAnalyticsCode() {
        let gaCode = 'UA-136687272-21';
        return gaCode;
    }
    getRole() {
        let role = 'Public';
        if (this.props.authenticationContext.user) {
            if (this.props.authenticationContext.user.roles.includes('internal')) {
                if (this.props.authenticationContext.user.isClientServiceRep) {
                    role = 'Client Service Rep';
                }
                else {
                    role = 'Internal';
                }
            }
            else if (this.props.authenticationContext.user.roles.includes('broker')) {
                role = 'Broker';
            }
            else if (this.props.authenticationContext.user.roles.includes('vendor')) {
                role = 'Vendor';
            }
            else if (this.props.authenticationContext.user.roles.includes('employeradmin')) {
                role = 'EmployerAdmin';
            }
            else if (this.props.authenticationContext.user.roles.includes('employer')) {
                role = 'Employer';
            }
            else if (this.props.authenticationContext.user.roles.includes('employee')) {
                role = 'Employee';
            }
        }
        return role;
    }
    getCustomerName() {
        let customerName = Cookies.get('loginHint');
        if (!customerName) {
            customerName = '';
        }
        return customerName;
    }
    getExperience() {
        let { experience } = this.props.experienceContext;
        // FIXME: Odd error case - unsure if correct, assume PUBLIC if experience context fails to load/undefined? should we throw an error here?
        if (!experience) {
            experience = Experience.PUBLIC;
        }
        return experience;
    }
    getCustomDimensions = (page, experience, role) => {
        const dimensions = {
            page,
            dimension1: this.getCustomerName(),
            dimension2: !experience ? this.getExperience() : experience,
            dimension3: !role ? this.getRole() : role,
        };
        return dimensions;
    };
    trackEvent = (category, action, label) => {
        this.trackEventWithPage(category, action, label, window.location.pathname);
    };
    trackEventWithPage = (category, action, label, page) => {
        if (!category) {
            category = 'Event';
        }
        // console.log(`send event of categeory ${category}, action ${action}, with label ${label}, 1, customDimensions: {${this.getCustomDimensions()}}`)
        // @ts-ignore
        // need to use library's raw passthrough to the original ga() function - unsure how else to make sure it works the exact same way
        ReactGA.ga('send', 'event', category, action, label, 1, this.getCustomDimensions(page));
    };
    trackClick = (buttonName) => {
        this.trackEvent(EventCategory.BUTTON, EventAction.CLICK, buttonName);
    };
    trackClickWithPage = (buttonName, page) => {
        this.trackEventWithPage(EventCategory.BUTTON, EventAction.CLICK, buttonName, page);
    };
    trackTextInput = (inputName) => {
        this.trackEvent(EventCategory.TEXTBOX, EventAction.ENTERVALUE, inputName);
    };
    trackError = (errorAction, errorName) => {
        this.trackEvent(EventCategory.ERROR, errorAction, errorName);
    };
    trackPageView = (page, experience, role) => {
        ReactGA.ga('send', 'pageview', this.getCustomDimensions(page, experience, role));
    };
    trackWithLabel = (eventAction, eventCategory, eventLabel, eventValue) => {
        ReactGA.ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue, this.getCustomDimensions(window.location.pathname));
    };
    render() {
        const context = {
            trackClick: this.trackClick,
            trackError: this.trackError,
            trackEvent: this.trackEvent,
            trackTextInput: this.trackTextInput,
            trackClickWithPage: this.trackClickWithPage,
            trackPageView: this.trackPageView,
            trackWithLabel: this.trackWithLabel,
            ...this.state,
        };
        return <GoogleAnalyticsContext.Provider value={context}>{this.props.children}</GoogleAnalyticsContext.Provider>;
    }
}
export default withRouter(withAuthentication(GoogleAnalyticsProvider));
