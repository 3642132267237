import { Alert, Col, Directory, FileType, Heading, Row, Spinner, themes } from '@digitools/honest-react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import withAuthentication from '../../hocs/withAuthentication';
import useTealium from '../../hooks/useTealium';
import { RecordType } from '../../types/IEmployerResource';
import EmployerResourceDetailTable from './EmployerResourceDetailTable';
const EmployerResourceContainer = ({ authenticationContext }) => {
    const [employerResources, setEmployerResources] = useState();
    const [selectedDirectory, setSelectedDirectory] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EMPLOYER_RESOURCES,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EMPLOYER_RESOURCES.VIEW,
        });
    }, []);
    useEffect(() => {
        if (isFetching) {
            getAllEmployerResourcesByCustomerId();
        }
    }, [isFetching, selectedDirectory]);
    const getAllEmployerResourcesByCustomerId = async () => {
        try {
            let url = '/document-service/employer-resources/all';
            const response = await axios.get(url, {
                headers: {
                    Authorization: authenticationContext.authHeader(),
                },
            });
            //Add EE forms
            const employeeForms = employeeFormsToIDirectoryItems((await getEmployeeForms())?.data);
            const resources = [
                ...response.data,
                {
                    checked: false,
                    id: 'eeForms',
                    label: 'Employee Forms',
                    type: FileType.DIR,
                    children: employeeForms,
                },
            ];
            setEmployerResources(resources);
            setSelectedDirectory(resources[0]);
            setIsFetching(false);
        }
        catch (error) {
            setIsFetching(false);
            setErrorMessage("We're sorry. We are unable to retrieve your folders and documents at this time. If this problem persists, please contact technical assistance.");
        }
    };
    const getEmployeeForms = async () => {
        const customerId = encodeURIComponent(authenticationContext.user.customerId);
        return axios.get(`/mlp-ui-service/api/documents/employee-forms?customerId=${customerId}`, {
            headers: { Authorization: authenticationContext.authHeader() },
        });
    };
    const alphabetizeEmployeeForms = (employeeForms) => {
        employeeForms.sort((form1, form2) => {
            let formA = form1.englishDisplayName.toLowerCase();
            let formB = form2.englishDisplayName.toLowerCase();
            if (formA < formB) {
                // sort string ascending
                return -1;
            }
            else if (formA > formB) {
                return 1;
            }
            else {
                return 0; // default return value (no sorting)
            }
        });
        return employeeForms;
    };
    const employeeFormsToIDirectoryItems = (forms) => {
        const alphabetizedForms = alphabetizeEmployeeForms(forms);
        return alphabetizedForms?.map(form => {
            return {
                checked: false,
                id: form.id,
                label: form.englishDisplayName,
                type: form.type,
                recordType: form.recordType,
                children: [],
                description: form.englishDescription,
            };
        });
    };
    const onSelect = (employerResource) => {
        setSelectedDirectory(employerResource);
    };
    const renderEmployerDirectories = () => {
        if (employerResources && employerResources.length > 0) {
            return (<Directory title='Folders and Files' directoryItems={employerResources} foldersOnly={true} onItemSelect={onSelect} defaultOpen={true} data-testid={'directory'} theme={themes.lincoln}/>);
        }
    };
    const downloadFile = async (id, recordType) => {
        try {
            const newRecordType = recordType === RecordType.MASTER || recordType === RecordType.CUSTOMER ? RecordType.CUSTOMER : recordType;
            let url = `/document-service/employer-resources/${id}/download?recordType=${newRecordType}`;
            if (newRecordType.includes('employee')) {
                url = `/document-service/employee-forms/${id}?recordType=${newRecordType}`;
            }
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
                headers: { Authorization: authenticationContext.authHeader() },
            });
            const contentDispositionHeader = response.headers['content-disposition'];
            const contentType = response.headers['content-disposition'];
            const filename = contentDispositionHeader?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim();
            const blob = new Blob([response.data], { type: contentType });
            saveAs(blob, filename);
        }
        catch (error) {
            setErrorMessage("We're sorry. We are unable to download your document at this time. If this problem persists, please contact technical assistance.");
        }
    };
    const closeErrorAlert = () => {
        setErrorMessage(undefined);
    };
    if (isFetching) {
        return (<div style={{ height: '150px' }}>
        <Spinner id='ee-resource-container-spinner' data-testid={'spinner'}/>
      </div>);
    }
    if (errorMessage) {
        return (<Alert type='error' closeable={true} onClose={closeErrorAlert}>
        <strong>{errorMessage}</strong>
      </Alert>);
    }
    return (<div data-public={true}>
      <Heading elemType='h1' className='mb-4'>
        Forms & documents
      </Heading>
      <Row>
        <Col lg={'3'} md={'4'} sm={'5'}>
          {renderEmployerDirectories()}
        </Col>
        <Col lg={'9'} md={'8'} sm={'7'}>
          {selectedDirectory && (<EmployerResourceDetailTable directory={selectedDirectory} downloadFile={downloadFile}/>)}
        </Col>
      </Row>
    </div>);
};
export default withAuthentication(EmployerResourceContainer);
