import { Heading, themes, withTheme } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from '../../../../hooks/useExperience';
import useTealium from '../../../../hooks/useTealium';
import { Experience } from '../../../../types/Experience';
import { getCustomerLogo } from './../../../../api/UiServiceApi';
import MessagesContainer from './Messages/MessagesContainer';
import QuickLinks from './QuickLinks';
const CustomerLogo = styled('img') `
  max-height: 2rem;
  margin-top: 0;
  padding-left: 2rem;
`;
const CustomerBarContainer = styled('div') `
  font-size: 0.8125rem;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  flex-wrap: wrap;
`;
const CustomerBar = ({ theme, landingPageResource }) => {
    const [customerLogo, setCustomerLogo] = useState('');
    const { experience, language } = useExperience();
    const { authHeader, user } = useAuthentication();
    const { trackEvent } = useTealium();
    const trackPageEvent = (eventName, eventAction) => {
        trackEvent({
            event_action: eventAction || TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CUSTOMER_ROLLUP_BAR,
        });
    };
    // TODO: rip out logo from baaaad old header implementation, should be added to config data in Config provider
    useEffect(() => {
        const fetchCustomerLogo = async () => {
            const results = await getCustomerLogo(authHeader(), user?.customerId);
            if (results && results.data && results.data.size > 0) {
                setCustomerLogo(URL.createObjectURL(results.data));
            }
        };
        fetchCustomerLogo();
    }, []);
    return (<CustomerBarContainer data-testid='customer-bar'>
      <Media query='(max-width: 768px)'>
        {matches => matches ? (<>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Heading data-testid='customer-name' elemType={'h1'} elemStyle={'h4'} color={'grayDark'} theme={themes.lincoln}>
                  {user && (user.customerDisplayName ? user.customerDisplayName : user.customerInternalName)}
                </Heading>
                {customerLogo && (<CustomerLogo src={customerLogo} data-testid='customer-logo' id='customerLogo' alt='customerLogo' aria-label='Customer company logo'/>)}
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                {experience === Experience.EMPLOYEE && (<MessagesContainer trackPageEvent={trackPageEvent} landingPageResource={landingPageResource} logoExists={customerLogo ? true : false}/>)}
                <div style={{ display: 'flex' }}>
                  <QuickLinks trackPageEvent={trackPageEvent}/>
                </div>
              </div>
            </>) : (<>
              <div style={{ display: 'flex', flexGrow: 3, alignItems: 'flex-end', marginBottom: '.5rem' }}>
                <Heading data-testid='customer-name' elemType={'h1'} elemStyle={'h4'} color={'grayDark'} theme={themes.lincoln}>
                  {user && (user.customerDisplayName ? user.customerDisplayName : user.customerInternalName)}
                </Heading>
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end', marginBottom: '.5rem' }}>
                <div style={{ paddingLeft: '1rem', paddingRight: '1rem', marginLeft: '1rem', marginRight: '1rem' }}>
                  <QuickLinks trackPageEvent={trackPageEvent}/>
                </div>

                {experience === Experience.EMPLOYEE && (<MessagesContainer trackPageEvent={trackPageEvent} landingPageResource={landingPageResource} logoExists={customerLogo ? true : false}/>)}

                {customerLogo && (<CustomerLogo src={customerLogo} data-testid='customer-logo' id='customerLogo' alt='customerLogo' aria-label='Customer company logo'/>)}
              </div>
            </>)}
      </Media>
    </CustomerBarContainer>);
};
export default withTheme(CustomerBar);
