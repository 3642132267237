import { Button, Col, Heading, Icon, Label, Row, StepperContext, Table, TableBody, TableData, TableHeader, TableRow, TextInput, Alert, } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LCIApi from '../../../../api/LCIApi';
import useAuthentication from '../../../../hooks/useAuthentication';
import { UnexpectedErrorContext } from '../../../../providers/UnexpectedErrorProvider';
import { validateNotes } from '../../../../utils/validationUtils';
import { MAX_FILE_COUNT } from '../../constants/LCIConstant';
import CancelLci from '../cancelLCI/CancelLci';
// @ts-ignore loaders not recognized by tslint
import styles from './LCI.scss';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
const StyledFileInput = styled('input') `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const StyledUploadDiv = styled('div') ``;
const StyledListItem = styled('li') `
  & + & {
    margin-top: 0.5rem;
  }
`;
const DeleteFileButton = styled('button') `
  outline: none;
  border: none;
  font-size: 1rem;
  background: inherit;
  color: #23669a;

  &:hover {
    cursor: pointer;
  }
`;
const FileSize = ({ size }) => {
    const KB = 1024;
    const MB = 1024 * KB;
    const round = (num) => Math.round(num * 10) / 10;
    return <>{size > MB ? `${round(size / MB)}MB` : `${round(size / KB)}KB`}</>;
};
const DOCS_INITIAL_STATE = [];
const AdditionalInfo = ({ onComplete, claimant, event }) => {
    const stepper = useContext(StepperContext);
    const [docs, setDocs] = useState(DOCS_INITIAL_STATE);
    const [submitting, setSubmitting] = useState(false);
    const { trackEvent } = useTealium();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    useEffect(() => setIsMessagingDisabled(false), []);
    const { authHeader } = useAuthentication();
    const supportedFiles = [
        'gif',
        'jpg',
        'pdf',
        'pptx',
        'tif',
        'tiff',
        'xls',
        'xlsx',
        'doc',
        'docx',
    ];
    const invalidFileNameError = 'The document that you are attempting to upload has invalid characters in the document name. Please remove invalid characters from the document name and try again. (Invalid characters are: !@#\\$%^&*()+{}[];\':\\",/?<>)';
    const invalidFileTypeError = 'Accepted file types are as follows: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx';
    const invalidFileSizeError = 'Uploaded files cannot exceed 6 MB each.';
    const validateFile = (selectedFile) => {
        const newFileType = selectedFile.name;
        const trimmedFileType = newFileType.substring(newFileType.lastIndexOf('.') + 1, newFileType.length);
        if (supportedFiles.indexOf(trimmedFileType.toLocaleLowerCase()) < 0) {
            setFileErrorMessage(invalidFileTypeError);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_name: invalidFileTypeError,
                event_version: 'upload document',
            });
            return false;
        }
        else if (selectedFile.size > 6291456) {
            setFileErrorMessage(invalidFileSizeError);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_name: invalidFileSizeError,
                event_version: 'upload document',
            });
            return false;
        }
        else if (!(newFileType.match(/^[a-zA-Z0-9_.\s-]*$/))) {
            setFileErrorMessage(invalidFileNameError);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_name: invalidFileNameError,
                event_version: 'upload document',
            });
            return false;
        }
        else {
            setFileErrorMessage('');
            return true;
        }
    };
    const handleFileUpload = async (event) => {
        event.preventDefault();
        try {
            const newFile = event.target.files[0];
            const isValidFile = validateFile(newFile);
            if (isValidFile && docs.length < MAX_FILE_COUNT) {
                const response = await uploadFile(newFile);
                const newDocumentInfo = {
                    file: newFile,
                    docId: response.data,
                };
                const docsDuplicateRemoved = (docs || []).filter((doc) => doc.file.name !== newFile.name);
                setDocs([...(docsDuplicateRemoved || []), newDocumentInfo]);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                    event_name: 'open',
                    event_version: 'additional info',
                    event_results: '1', //This will always be 1, you only can 'upload' one at a time, even if submitting ends up attaching all docs to one claim
                });
                setSubmitting(true);
            }
        }
        finally {
            setSubmitting(false);
        }
    };
    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('interviewType', 'LIFE');
        formData.append('experience', 'Employer');
        return LCIApi().uploadFile(formData, authHeader());
    };
    const handleFileDelete = (i) => () => {
        const updatedDocs = docs.filter((doc, index) => index !== i);
        setDocs(updatedDocs);
    };
    const eventStatus = (claimant, event) => {
        if (event === 'death') {
            return 'step 7';
        }
        else if (((event === 'accelerated death' || event === `dismemberment / loss of sight`) && claimant !== 'dependent') || event === 'total disability') {
            return 'step 5';
        }
        else {
            return 'step 6';
        }
    };
    function handleContinue(values) {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'additional info',
            event_status: `${eventStatus(claimant, event)}`,
        });
        setFileErrorMessage('');
        const documents = docs;
        const additionalInfo = documents.length > 0 || (values && values.notes) ? { ...values, documents } : {};
        onComplete({ ...additionalInfo });
        stepper.next();
    }
    const handleBack = () => {
        stepper.back();
    };
    return (<div data-public={true}>
      <Form onSubmit={handleContinue}>
        <Heading elemType='h2' color='primary'>
          Additional Info (Optional)
        </Heading>
        <Row>
          <Col>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                Upload Documents
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Please submit two full years of Optional Life (if applicable) plus current benefit year and Beneficiary Designation.  
              Please submit supporting documentation if the employee is on an approved leave that is not managed by Lincoln Financial Group.  
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Heading elemType='h3'>Please Note: </Heading>
            <ul>
              <StyledListItem>You can upload up to 10 files.</StyledListItem>
              <StyledListItem>Uploaded files cannot exceed 6MB each.</StyledListItem>
              <StyledListItem>
                Uploaded files must be uniquely named to avoid overwriting a previously uploaded file.
              </StyledListItem>
              <StyledListItem>
                Accepted file types are as follows: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx.
              </StyledListItem>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledUploadDiv className={`mt-4 mb-4 ${docs.length >= MAX_FILE_COUNT ? styles.uploadDisabledButton : styles.uploadButton}`} id='uploadFileButton' style={{ border: 'none', boxShadow: 'none' }}>
              <Icon className='mr-2' name='upload'/>
              <span>Upload Document</span>
              {!submitting ? (<Label style={{ opacity: 0, height: 0 }}>
                  File Upload
                  <StyledFileInput id='fileUpload' onChange={handleFileUpload} type='file' disabled={docs.length >= MAX_FILE_COUNT}/>
                </Label>) : null}
            </StyledUploadDiv>
            {fileErrorMessage != '' && (<Alert data-testid={'error-alert'} type={'error'} aria-labelledby='error-alert'>
                {fileErrorMessage}
              </Alert>)}
            <Table>
              <TableHeader>
                <TableRow isFilled={true}>
                  <TableData>Document Name</TableData>
                  <TableData>Size</TableData>
                </TableRow>
              </TableHeader>
              <TableBody id='filesGrid'>
                {docs.map(({ file: doc }, index) => (<TableRow data-name='rowGrid' key={index}>
                    <TableData>
                      <Icon color='error' className='mr-2' name='file-regular'/>
                      {doc.name}
                    </TableData>
                    <TableData>
                      <FileSize size={doc.size}/>
                      <DeleteFileButton type='button' name='deleteButton' onClick={handleFileDelete(index)} className='ml-4'>
                        <Icon color='secondary' className='mr-2' name='trash'/>
                        Delete
                      </DeleteFileButton>
                    </TableData>
                  </TableRow>))}
              </TableBody>
            </Table>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                Notes
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <TextInput label='Enter any other relevant information to your claim.' field='notes' textarea={true} validateOnChange={true} validate={validateNotes}/>
          </Col>
        </Row>
        <StyledDiv>
          <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='back-additional-info' type='button' onClick={handleBack}>
            Back
          </Button>

          <CancelLci step={eventStatus(claimant, event)} pageTitle={'additional info'} sectionInfo={'Additional info'}/>
          <Button aria-label={'Continue'} buttonType='primary' role='button' id='continue-additional-info'>
            Continue
          </Button>
        </StyledDiv>
      </Form>
    </div>);
};
export default AdditionalInfo;
