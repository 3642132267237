import { Environment } from '../types/Environment';
import ENVIRONMENT_VARIABLES from '../constants/envVariables';
export const getEnvironment = () => {
    const ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
    const hostname = window.location.hostname;
    // if hostname is an ip address assume it is dev.
    // this is so we can connect from a VM and have the dev environment variables.
    // could update in the future to check for private IPs since that is all we really care about
    if (hostname.indexOf('localhost') > -1 || ipRegex.test(hostname)) {
        return Environment.DEV;
    }
    if (hostname.indexOf('-np') > -1 || hostname.indexOf('np-') > -1 || hostname.indexOf('-np-') > -1) {
        return Environment.NON_PROD;
    }
    // TODO: FIX for migration - np no longer in host name, using presence of `dev` or `test` instead
    if (hostname.indexOf('-test') > -1 || hostname.indexOf('-dev') > -1) {
        return Environment.NON_PROD;
    }
    return Environment.PROD;
};
export const isProd = () => getEnvironment() === Environment.PROD;
export const isNonProd = () => getEnvironment() === Environment.NON_PROD;
export const isDev = () => getEnvironment() === Environment.DEV;
export const getEnvVariable = (key) => {
    const variablesMap = ENVIRONMENT_VARIABLES[key];
    switch (getEnvironment()) {
        case Environment.DEV:
            return variablesMap.dev;
        case Environment.NON_PROD:
            return variablesMap.nonProd;
        default:
            return variablesMap.prod;
    }
};
export const wideMode = () => {
    if (window.location.pathname) {
        return window.location.pathname.indexOf('customer/eoi') !== -1;
    }
    return false;
};
export const windowReplace = url => {
    window.location.replace(url);
};
