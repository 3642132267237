import { Button, Col, Heading, Row, StepperContext } from '@digitools/honest-react';
import React, { useContext, useState } from 'react';
import BdmBeneficiary from '../../../bdm/components/BdmBeneficiary';
import CancelLci from '../cancelLCI/CancelLci';
import IsLciContext from '../../../../providers/IsLciProvider';
import { PRIMARY, CONTINGENT } from '../../../bdm/constants/BdmConstants';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { StyledDiv } from 'src/components/Formatting/StyledComponents';
const PolicyBeneficiaries = ({ onComplete }) => {
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    const [primaryBeneficiaries, setPrimaryBeneficiaries] = useState([]);
    const [contingentBeneficiaries, setContingentBeneficiaries] = useState([]);
    const [primaryEqualShares, setPrimaryEqualShares] = useState(false);
    const [contingentEqualShares, setContingentEqualShares] = useState(false);
    const beneficiariesCount = primaryBeneficiaries.length + contingentBeneficiaries.length;
    const updateBeneficiaries = (beneficiaryCategory, beneficiaries) => {
        if (beneficiaryCategory === PRIMARY) {
            setPrimaryBeneficiaries(beneficiaries);
            setContingentBeneficiaries([...(contingentBeneficiaries)]);
        }
        else if (beneficiaryCategory === CONTINGENT) {
            setContingentBeneficiaries(beneficiaries);
            setPrimaryBeneficiaries([...(primaryBeneficiaries)]);
        }
    };
    const handleContinue = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'policy beneficiaries',
            event_status: 'step 5',
            event_results: `${beneficiariesCount}`,
        });
        onComplete({ primary: primaryBeneficiaries, contingent: contingentBeneficiaries, primaryEqual: primaryEqualShares, contingentEqual: contingentEqualShares });
        stepper.next();
    };
    const handleBack = () => {
        stepper.back();
    };
    return (<IsLciContext.Provider value={true}>
      <Heading elemType='h2' color='primary'>
          Policy Beneficiaries
        </Heading>
      <Row>
        <Col md={12} xs={12}>
          <p>Add or change your beneficiary information. A maximum of 15 beneficiaries is allowed.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <BdmBeneficiary beneficiaryCategory={PRIMARY} beneficiaries={primaryBeneficiaries} setBeneficiaries={setPrimaryBeneficiaries} updateBeneficiaries={updateBeneficiaries} beneficiariesCount={beneficiariesCount} equalShares={primaryEqualShares} setEqualShares={setPrimaryEqualShares}/>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <BdmBeneficiary beneficiaryCategory={CONTINGENT} beneficiaries={contingentBeneficiaries} setBeneficiaries={setContingentBeneficiaries} updateBeneficiaries={updateBeneficiaries} beneficiariesCount={beneficiariesCount} equalShares={contingentEqualShares} setEqualShares={setContingentEqualShares}/>
        </Col>
      </Row>
      <StyledDiv>
          <Button buttonType={'secondary'} id='back-beneficiaries' onClick={handleBack}>
            Back
          </Button>
          <CancelLci step={'step 5'} pageTitle={'policy beneficiaries'} sectionInfo={'Beneficiaries info'}/>
          <Button buttonType='primary' id='continue-beneficiaries' onClick={handleContinue}>
            Continue
          </Button>
      </StyledDiv>
    </IsLciContext.Provider>);
};
export default PolicyBeneficiaries;
