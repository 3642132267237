import React, { useEffect, useState } from 'react';
import { Stepper } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import RabReport from './RabReport';
import RabReview from './RabReview';
import RabSuccess from './RabSuccess';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import useAuthentication from 'src/hooks/useAuthentication';
const RABREPORT_INITIAL_STATE = {
    numberOfBirth: '1',
    deliveryType: '',
    dateOfBirth: '',
    hours: '',
    minutes: '',
    ampm: 'am',
    lastDayWorked: '',
    hospitalAdmissionDate: '',
    bondingQuestionType: '',
};
const RabHome = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rabReport, setRabReport] = useState(RABREPORT_INITIAL_STATE);
    const { t: translation } = useTranslation('rabTranslation');
    const { trackView } = useTealium();
    const { user } = useAuthentication();
    useEffect(() => {
        if (currentPage === 0) {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RAB,
                user_id: user?.webGUID
            });
        }
        if (currentPage === 2) {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RAB,
                page_l5: 'confirmation',
                user_id: user?.webGUID
            });
        }
    }, [currentPage]);
    return (<div data-testid='rabHome'>
      {/* @ts-ignore */}
      <Stepper stepTitles={[translation('stepLabelReport'), translation('stepLabelReview'), translation('stepLabelSuccess')]} onStepChange={setCurrentPage}>
        <RabReport onComplete={setRabReport} rabCustomerConfData={props.rabCustomerConfData}/>
        <RabReview review={true} report={rabReport} claimNumber={props.claimNumber}/>
        <RabSuccess claimNumber={props.claimNumber} rabCustomerConfData={props.rabCustomerConfData} report={rabReport}/>
      </Stepper>
    </div>);
};
export default RabHome;
