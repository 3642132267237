import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Alert, Spinner } from '@digitools/honest-react';
import styles from './CoverageDetails.scss';
import { useAuth0, useLanguage, useExperience } from 'status/hooks';
import useGet from '../../../../../hooks/useGet';
import CoverageTabs from './CoverageTabs';
import titleCase from 'status/utilities/titleCase';
import { decode } from 'status/utilities/hash';
import TeamViewEmployeeActions from './TeamViewEmployeeActions';
import EmployeeEligibilityDetails from './EmployeeEligibilityDetails';
import { isTeamView } from './../../../../../packages/public/usaa/utils/CustomerUtils';
import ReturnToViewTeamMember from './ReturnToViewTeamMember';
import Notifications, { FunctionalArea } from '../../../../../components/Notifications/Notifications';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';

const Error = () => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong>&nbsp;Something went wrong when trying to load the employee.
    </span>
  </Alert>
);

// Helper function to determine if we can show EOI
const showEOI = userMeta => {
  const applicationPermissions = userMeta.applicationPermissions;
  return applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
};

/**
 * Backstory: Sometimes, employees will only have an empId
 * because they don't exits in S1. An user might want to
 * view details on this employee but all they have to go on is
 * the empId. Therefore, we need a way for users to view
 * this data ( coverage details ) without an empNum. This
 * is where PartialCoverageDetails comes into play. Its
 * a component that will render details based on the empId.
 */
const PartialCoverageDetails = ({ fromTeamView }) => {
  const { userMeta } = useAuth0();
  const { language } = useLanguage();
  const { experience, getExperience } = useExperience();
  const { trackView } = useTealium();

  const { empNum } = useParams();

  useEffect(() => {
    trackView({
      page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
      page_l4:
        experience === 'ER'
          ? TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYER_VIEW
          : TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYEE_VIEW,
      page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.STATUS.COVERAGE_DETAILS
    });
  }, []);

  // When routing to a page based on empId its encoded as "id-${theid}"
  const empId = decode(empNum).split('-')[1];

  const {
    error: employeeError,
    loading: loadingEmployee,
    data: employeeData,
  } = useGet({
    url: `/mlp-ui-service/api/employees/hierarchy/${empId}`,
    // Currently we CANT hit this endpoint as employee that has no empnum
    // Why? because its restricted to allow ERs and EE's whos empNum in token match
    // Emp num of the employee being retrieved
    //
    // Scenario: SSO EE user wants to view data via emp ID ( Unplanned absences, EOI )
    //
    // So, we must prevent this call for EE users that do not have empNum
    // Because this page will only be rendered for EE users that dont have emp num
    // we can simply check to see if the experience is EE and not make the call
    //
    // Why do we make this call in thef first place?
    // Because we need to be able to show employee data on this screen for ER users
    noInitial: getExperience() === 'EE',
    options: {
      headers: {
        Accept: 'application/hal+json',
      },
    },
  });

  const showEOITab = showEOI(userMeta);
  const {
    error: eoiError,
    loading: loadingEoi,
    data: eoiData,
  } = useGet(
    {
      url: '/status-service/eoi/status',
      options: {
        params: {
          languageCode: language,
        },
      },
      noInitial: !showEOITab,
    },
    [language],
  );

  const loading = loadingEoi || loadingEmployee;

  if (loading) {
    return <Spinner id='partial-coverage-details-spinner' center={true} />;
  }

  if (employeeError) {
    return <Error />;
  }

  let name;

  // IF we are ER we need to make sure we got employee
  if (experience === 'ER') {
    const employee = employeeData.emp;
    if (!employee) {
      return <Error />;
    }
    name = `${titleCase(employee.firstName)} ${titleCase(employee.lastName)}`;
  } else {
    name = `${titleCase(userMeta.firstName)} ${titleCase(userMeta.lastName)}`;
  }

  const teamView = isTeamView(userMeta);

  return (
    <div className={styles.container}>
      <Notifications functionalArea={FunctionalArea.COVERAGE_DETAILS} />
      {!teamView ? (
        <div className='mb-4'>
          <Heading color={'accent1'}>{name}</Heading>
        </div>
      ) : null}
      {fromTeamView ? <ReturnToViewTeamMember empId={empId} /> : null}
      <EmployeeEligibilityDetails empId={empId} />
      {fromTeamView ? <TeamViewEmployeeActions empId={empId} /> : null}
      <CoverageTabs
        eoiData={eoiData}
        eoiError={eoiError}
        showEOITab={showEOITab}
        empId={empId}
        fromTeamView={fromTeamView}
      />
    </div>
  );
};

export default withRouter(PartialCoverageDetails);
