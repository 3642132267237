import { AccessLevelEnum } from '../types/permissionTypes';
import axios from 'axios';
import React from 'react';
import { Checkbox, Col } from '@digitools/honest-react';
import * as _ from 'lodash';
export async function updateEmployerUser(authHeader, requestBody) {
    return axios.patch(`/user-service/services/v1/users/employers`, requestBody, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export function handleProductAllSelection(selectedOption, checkboxOptions, option, userInformation, customerConfig) {
    if (selectedOption.label === 'All') {
        if (selectedOption.checked === true) {
            checkboxOptions.forEach(opt => {
                opt.checked = true;
            });
            if (option === 'products') {
                userInformation.products.attributes = [];
                userInformation.products.accessLevel = AccessLevelEnum.FULL;
                getProducts(userInformation, customerConfig);
            }
            else if (option === 'crsSensitive') {
                userInformation.sensitiveData.CRS.attributes = [];
                userInformation.sensitiveData.CRS.accessLevel = AccessLevelEnum.FULL;
                getSensitiveData(userInformation, customerConfig);
            }
            else if (option === 'statusSensitive') {
                userInformation.sensitiveData.STATUS.attributes = [];
                userInformation.sensitiveData.STATUS.accessLevel = AccessLevelEnum.FULL;
                getStatusSensitiveData(userInformation, customerConfig);
            }
        }
        else {
            checkboxOptions.forEach(opt => {
                opt.checked = false;
            });
            if (option === 'products') {
                userInformation.products.attributes = [];
                userInformation.products.accessLevel = AccessLevelEnum.NO_ACCESS;
                getProducts(userInformation, customerConfig);
            }
            else if (option === 'crsSensitive') {
                userInformation.sensitiveData.CRS.attributes = [];
                userInformation.sensitiveData.CRS.accessLevel = AccessLevelEnum.NO_ACCESS;
                getSensitiveData(userInformation, customerConfig);
            }
            else if (option === 'statusSensitive') {
                userInformation.sensitiveData.STATUS.attributes = [];
                userInformation.sensitiveData.STATUS.accessLevel = AccessLevelEnum.NO_ACCESS;
                getStatusSensitiveData(userInformation, customerConfig);
            }
        }
    }
    else {
        if (option === 'products') {
            const selectedProducts = checkboxOptions.filter(prod => prod.checked === true).map(prod => prod.code);
            selectedProducts.length > 0
                ? selectedProducts.length === checkboxOptions.length
                    ? (userInformation.products.accessLevel = AccessLevelEnum.FULL)
                    : (userInformation.products.accessLevel = AccessLevelEnum.LIMITED)
                : (userInformation.products.accessLevel = AccessLevelEnum.NO_ACCESS);
            userInformation.products.attributes =
                userInformation.products.accessLevel === AccessLevelEnum.FULL ? [] : selectedProducts;
        }
        else if (option === 'crsSensitive') {
            const selectedSensitive = checkboxOptions
                .filter(crsSensitive => crsSensitive.checked === true)
                .map(crsSensitive => crsSensitive.code);
            selectedSensitive.length > 0
                ? selectedSensitive.length === checkboxOptions.length
                    ? (userInformation.sensitiveData.CRS.accessLevel = AccessLevelEnum.FULL)
                    : (userInformation.sensitiveData.CRS.accessLevel = AccessLevelEnum.LIMITED)
                : (userInformation.sensitiveData.CRS.accessLevel = AccessLevelEnum.NO_ACCESS);
            userInformation.sensitiveData.CRS.attributes =
                userInformation.sensitiveData.CRS.accessLevel === AccessLevelEnum.FULL ? [] : selectedSensitive;
        }
        else if (option === 'statusSensitive') {
            const selectedStatusSensitive = checkboxOptions
                .filter(statusSensitive => statusSensitive.checked === true)
                .map(statusSensitive => statusSensitive.code);
            selectedStatusSensitive.length > 0
                ? selectedStatusSensitive.length === checkboxOptions.length
                    ? (userInformation.sensitiveData.STATUS.accessLevel = AccessLevelEnum.FULL)
                    : (userInformation.sensitiveData.STATUS.accessLevel = AccessLevelEnum.LIMITED)
                : (userInformation.sensitiveData.STATUS.accessLevel = AccessLevelEnum.NO_ACCESS);
            userInformation.sensitiveData.STATUS.attributes =
                userInformation.sensitiveData.STATUS.accessLevel === AccessLevelEnum.FULL ? [] : selectedStatusSensitive;
        }
    }
}
export function getProducts(userInfo, customerConfig) {
    const accessLevelProducts = userInfo && userInfo.products ? userInfo.products.accessLevel : null;
    const validProductList = customerConfig.sharedProducts.map(configProduct => {
        return { checked: false, code: configProduct.productName, label: configProduct.productText };
    });
    if (accessLevelProducts === AccessLevelEnum.FULL) {
        validProductList.forEach(validProduct => {
            validProduct.checked = true;
        });
        return validProductList;
    }
    else {
        userInfo.products.attributes.forEach(selectedProduct => {
            validProductList.forEach(validProduct => {
                if (validProduct.code === selectedProduct) {
                    validProduct.checked = true;
                }
            });
        });
        return validProductList;
    }
}
export function getSensitiveData(userInfo, customerConfig) {
    const crsSensitiveAccessLevel = userInfo && userInfo.sensitiveData && userInfo.sensitiveData.CRS ? userInfo.sensitiveData.CRS.accessLevel : null;
    const validSharedFieldsList = customerConfig.sharedFields
        .filter(fields => fields.application === 'CRS' || fields.application === 'Both')
        .map(configField => {
        return { checked: false, code: configField.fieldText, label: configField.adminFieldText };
    });
    if (crsSensitiveAccessLevel === AccessLevelEnum.FULL) {
        validSharedFieldsList.forEach(validProduct => {
            validProduct.checked = true;
        });
        return validSharedFieldsList;
    }
    else {
        userInfo.sensitiveData.CRS.attributes.forEach(selectedField => {
            validSharedFieldsList.forEach(validSensitiveInfo => {
                if (validSensitiveInfo.code === selectedField) {
                    validSensitiveInfo.checked = true;
                }
            });
        });
        return validSharedFieldsList;
    }
}
export function getStatusSensitiveData(userInfo, customerConfig) {
    const statusSensitiveAccessLevel = userInfo && userInfo.sensitiveData && userInfo.sensitiveData.STATUS
        ? userInfo.sensitiveData.STATUS.accessLevel
        : AccessLevelEnum.NO_ACCESS;
    const validSharedFieldsList = customerConfig.sharedFields
        .filter(fields => fields.application === 'Status' || fields.application === 'Both')
        .map(configField => {
        return { checked: false, code: configField.fieldText, label: configField.adminFieldText };
    });
    if (statusSensitiveAccessLevel === AccessLevelEnum.FULL) {
        validSharedFieldsList.forEach(validProduct => {
            validProduct.checked = true;
        });
        return validSharedFieldsList;
    }
    else {
        userInfo.sensitiveData.STATUS.attributes.forEach(selectedField => {
            validSharedFieldsList.forEach(validSensitiveInfo => {
                if (validSensitiveInfo.code === selectedField) {
                    validSensitiveInfo.checked = true;
                }
            });
        });
        return validSharedFieldsList;
    }
}
export function setUserPermissionState(userInformation) {
    const user = userInformation;
    user.customerReportingSolution = false;
    user.subsidiariesAndLocations = AccessLevelEnum.NO_ACCESS;
    user.viewCorrespondence = false;
    user.viewStatus = false;
    user.viewPayments = false;
    user.viewClaimNotes = false;
    user.viewLeaveNotes = false;
    user.viewEmployeeDetails = false;
    user.viewAsCorrespondence = false;
    user.viewBasicAsRequest = false;
    user.viewFullAsRequest = false;
    user.asNotes = false;
    user.products.accessLevel = AccessLevelEnum.NO_ACCESS;
    user.products.attributes = [];
    if (user.sensitiveData.CRS !== undefined) {
        user.sensitiveData.CRS.accessLevel = AccessLevelEnum.NO_ACCESS;
        user.sensitiveData.CRS.attributes = [];
    }
    if (user.sensitiveData.STATUS !== undefined) {
        user.sensitiveData.STATUS.accessLevel = AccessLevelEnum.NO_ACCESS;
        user.sensitiveData.STATUS.attributes = [];
    }
}
export function setFormAPIOnClearAll(formApiRef, props) {
    if (formApiRef.getValue('productAll')) {
        formApiRef.setValue('productAll', false);
    }
    if (formApiRef.getValue('viewCorrespondence')) {
        formApiRef.setValue('viewCorrespondence', false);
    }
    if (formApiRef.getValue('viewStatus')) {
        formApiRef.setValue('viewStatus', false);
    }
    if (formApiRef.getValue('viewPayments')) {
        formApiRef.setValue('viewPayments', false);
    }
    if (formApiRef.getValue('viewClaimNotes')) {
        formApiRef.setValue('viewClaimNotes', false);
    }
    if (formApiRef.getValue('viewLeaveNotes')) {
        formApiRef.setValue('viewLeaveNotes', false);
    }
    if (formApiRef.getValue('viewEmployeeDetails')) {
        formApiRef.setValue('viewEmployeeDetails', false);
    }
    if (formApiRef.getValue('createMyReport')) {
        formApiRef.setValue('createMyReport', false);
    }
    if (formApiRef.getValue('sensitiveAll')) {
        formApiRef.setValue('sensitiveAll', false);
    }
    if (formApiRef.getValue('statusSensitiveAll')) {
        formApiRef.setValue('statusSensitiveAll', false);
    }
    if (formApiRef.getValue('viewBasicAsRequest')) {
        formApiRef.setValue('viewBasicAsRequest', false);
    }
    if (formApiRef.getValue('viewFullAsRequest')) {
        formApiRef.setValue('viewFullAsRequest', false);
    }
    if (formApiRef.getValue('viewAsCorrespondence')) {
        formApiRef.setValue('viewAsCorrespondence', false);
    }
    if (formApiRef.getValue('asNotes')) {
        formApiRef.setValue('asNotes', false);
    }
    props.customerConfig.sharedProducts.map(product => {
        formApiRef.setValue(product.productName, false);
    });
    props.customerConfig.sharedFields.map(fields => {
        formApiRef.setValue(fields.fieldText, false);
    });
}
export function checkBoxSelection(listOptions, name, enabled, userInfo, customerConfig) {
    const checkboxOptions = listOptions;
    const optionsArray = _.chunk(checkboxOptions, Math.ceil(checkboxOptions ? checkboxOptions.length / 3 : 2));
    const optionCheckboxRows = optionsArray.map((subOptions, indexRow) => {
        return (<Col lg={4} key={indexRow}>
        {subOptions.map((option, index) => {
                const onOptionChange = (event) => {
                    option.checked = event.currentTarget.checked;
                    handleProductAllSelection(option, checkboxOptions, name, userInfo, customerConfig);
                };
                return (<Col style={{ marginTop: '.3rem' }} key={name + 'col' + index}>
              {' '}
              <Checkbox key={option.code} field={option.code} disabled={!enabled} initialValue={option.checked} label={option.label} onChange={onOptionChange}/>
            </Col>);
            })}
      </Col>);
    });
    return optionCheckboxRows;
}
