import axios from 'axios';
export const loadDefaultClaims = (authHeader, loadDefault) => {
    return axios.post('/rsc-service/services/v1/claim-leave-search', loadDefault, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });
};
export const getClmLveDetails = (authHeader, clmLveDetails) => {
    return axios.post('/rsc-service/services/v1/claim-leave-details', clmLveDetails, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });
};
export const loadProdTypes = (authHeader, custDiv, custSerial) => {
    return axios.get('/rsc-service/services/v1/products/' + custDiv + '/' + custSerial, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });
};
export const searchClaimsLeaves = (authHeader, searchCriteria) => {
    return axios.post('/rsc-service/services/v1/claim-leave-search', searchCriteria, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });
};
