import { Button, Icon, themes, useOutsideClick } from '@digitools/honest-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useTealium from '../../../hooks/useTealium';
import useTranslate from '../../../hooks/useTranslate';
import { DropdownCloseButton } from '../../../packages/landingPages/components/CustomerBar/DropdownMenu/DropdownMenuContainer';
import { CustomEmployeeText } from '../../../utils/CustomExperienceText';
import NavGroup from './NavGroup';
// @ts-ignore
import styles from './NavMenu.scss';
export const NavDropdownContainer = styled('div') `
  position: absolute;
  z-index: 2000; // temporary until old header is gone (sits at 999)
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-top: none;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: ${themes.lincoln.palette.gray};
  padding-top: .5rem;
  padding-bottom: .5rem;
  transition: transform .2s ease-in-out;
  transform-origin: top center;
  transform: scaleY(${({ isOpen = false }) => isOpen ? '1' : '0'});
  visibility:  ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  width: max-content;
  min-height: 3rem;
  left: ${({ navButtonWidth, dropDownWidth }) => navButtonWidth && dropDownWidth && `${(navButtonWidth - dropDownWidth) / 2}px`}; // may be redundant to whats in useEffect below
  top: ${({ navButtonHeight }) => `${navButtonHeight && navButtonHeight}px`};
`;
const NavItem = styled(Link) `
    borderTop: 2px solid transparent;
    padding: 1rem;
    border-left: 1px solid ${themes.lincoln.palette.grayLight};
    color: ${themes.lincoln.palette.grayDark};
    max-height: none;
    // @TODO from Ryan Munger - Dedupe me?
    font-size: 0.875rem;
    @media(min-width: 991px) {
        & {
            font-size: 1rem;
        }
    }
`;
// @TODO from Ryan Munger - bug 🧹 in styled components 4
// as any is here b/c https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628149946
// tldr - we need to upgrade to styled-components from v4 to v5
const NavDropDownButton = styled(NavItem) `
    position: relative;
    height: 100%;
    ${({ isOpen }) => isOpen && `
        &:before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 20;
            width: 0;
            height: 0;
            // Triangle Time 🔺
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 11px solid ${themes.lincoln.palette.gray};
        }
    `}
    font-size: 0.875rem;
    @media(min-width: 991px) {
        & {
            font-size: 1rem;
        }
    }
`;
// TODO: CSS - fix underline on hover/focus of navItems (find out why Quicklinks works but this has the issue)
/**
 * NavMenu
 *
 * renders either a navigation link, or a dropdown navigation group
 * uses NavLink or NavGroup respectively
 *
 */
const NavMenu = ({ navLabel, navLink, navGroups, }) => {
    const { t } = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [navButtonWidth, setNavButtonWidth] = useState(0);
    const [dropDownWidth, setDropDownWidth] = useState(0);
    const NavMenuRef = useRef(null);
    const NavButtonRef = useRef(null);
    const setButtonFocus = () => { NavButtonRef.current && NavButtonRef.current.focus(); };
    const navButtonHeight = NavButtonRef?.current?.offsetHeight;
    const { trackEvent } = useTealium();
    /* Handle Overflow - On resize, Recalculates left property to avoid overflow */
    useEffect(() => {
        //set width refs
        NavButtonRef.current && setNavButtonWidth(NavButtonRef.current.offsetWidth);
        NavMenuRef.current && setDropDownWidth(NavMenuRef.current.offsetWidth);
        const handleOverflow = () => {
            if (NavButtonRef.current && NavMenuRef.current) {
                const dropDownWidth = NavMenuRef.current.offsetWidth;
                const buttonWidth = NavButtonRef.current.offsetWidth;
                const navMenuOffsetCalc = (buttonWidth - dropDownWidth) / 2;
                //set the initial offset so we can properly calc space
                NavMenuRef.current.style.left = `${navMenuOffsetCalc}px`;
                // Calculate the available space to the right of the nav Menu dropdown
                const bodyRect = document.body.getBoundingClientRect();
                const navMenuRect = NavMenuRef.current?.getBoundingClientRect();
                const availableSpaceRight = bodyRect.right - navMenuRect.right;
                if (availableSpaceRight <= 0) {
                    NavMenuRef.current.style.left = `${(navMenuOffsetCalc + availableSpaceRight)}px`;
                }
            }
        };
        window.addEventListener('resize', handleOverflow);
        handleOverflow();
        return () => window.removeEventListener('resize', handleOverflow);
    }, [NavMenuRef, NavButtonRef]);
    /* Close on resize to avoid overflow while recalculating */
    useEffect(() => {
        const handleResize = () => {
            setIsOpen(false);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const trackPageEvent = (label) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: label,
            event_type: TEALIUM_EVENT.EVENT_TYPE.HOME_PAGE,
            event_version: TEALIUM_EVENT.EVENT_TYPE.HEADER,
        });
    };
    useOutsideClick([NavButtonRef, NavMenuRef], () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });
    const closeParent = (label) => {
        setIsOpen(false);
        trackPageEvent(label);
    };
    return (<>
            {navLink &&
            <NavItem data-testid='navlink' data-public={true} to={navLink.url} className={styles.navItem} onClick={() => trackPageEvent(navLabel.english)}>
                    {CustomEmployeeText(t(navLabel))}
                </NavItem>}
            {!navLink && navGroups &&
            <div style={{ position: 'relative' }}>
                    <NavDropDownButton as={Button} isOpen={isOpen} data-testid='navmenu-button' aria-label='navigation dropdown menu' onClick={(e) => {
                    setNavButtonWidth(e?.target?.offsetWidth);
                    const dropdownElem = e?.target?.nextElementSibling;
                    setDropDownWidth(dropdownElem && dropdownElem.offsetWidth);
                    setIsOpen(!isOpen);
                }} buttonType='text' className={styles.navItem} 
            // @ts-ignore
            ref={NavButtonRef}>
                        {CustomEmployeeText(t(navLabel))}
                        <Icon style={{ color: themes.lincoln.palette.grayLight, marginLeft: '.5rem ' }} name='caret-down' size='small'/>
                    </NavDropDownButton>
                    <NavDropdownContainer dropDownWidth={dropDownWidth} navButtonWidth={navButtonWidth} navButtonHeight={navButtonHeight} id='uniqueID' ref={NavMenuRef} data-testid='navmenu-dropdown' isOpen={isOpen} onClick={(e) => { e.stopPropagation(); }}>
                        <DropdownCloseButton data-testid='dropdown-close-button' aria-label='close' onClick={() => { setIsOpen(false); setButtonFocus(); }}>
                            <Icon style={{ textDecorationLine: 'none' }} color={'white'} theme={themes.lincoln} name='close-thin' aria-hidden={true} size='xs'/>
                        </DropdownCloseButton>
                        <div id='groupContainer' className='d-flex justify-content-center mr-5'>
                            {navGroups.map((navGroup, index) => (<NavGroup key={`NavGroup-${index}`} closeParent={(label) => { closeParent(label); }} {...navGroup}/>))}
                        </div>
                    </NavDropdownContainer>
                </div>}
        </>);
};
export default NavMenu;
