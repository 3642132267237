import React from 'react';
import { GoogleAnalyticsContext } from '../providers/GoogleAnalyticsProvider';
import { getDisplayName } from '../utils/reactUtils';
function withGoogleAnalytics(WrappedComponent) {
    class WithGoogleAnalytics extends React.Component {
        render() {
            return (<GoogleAnalyticsContext.Consumer>
          {contexts => <WrappedComponent {...this.props} googleAnalyticsContext={contexts}/>}
        </GoogleAnalyticsContext.Consumer>);
        }
    }
    // pretty up display name for easier debugging
    // @ts-ignore
    WithGoogleAnalytics.displayName = `WithGoogleAnalytics(${getDisplayName(WrappedComponent)})`;
    return WithGoogleAnalytics;
}
export default withGoogleAnalytics;
