import React from 'react';
const AccessibilityTextEn = {
    heading: 'Accessibility statement',
    paragraph1: 'Lincoln Financial Group is committed to ensuring digital accessibility for people with disabilities and we are continually engaged in efforts to improve our user experience. Accessibility is an integral part of LFG’s overall web strategy. LFG periodically audits each user experience to identify opportunities for accessibility improvement.',
    paragraph2: (<>
      If you use screen reader software or related technology to obtain web content and experience problems obtaining
      the content of this Website, please contact us at <a href='tel:+1-800-431-2958'>1-800-431-2958</a> for assistance.
    </>),
};
export default AccessibilityTextEn;
