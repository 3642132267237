export const DirectDepositInformationText = {
    heading: {
        english: 'Account information',
        spanish: 'Información de la cuenta',
    },
    firstName: {
        english: 'Account Holder First Name',
        spanish: 'Nombre del titular de la cuenta',
    },
    lastName: {
        english: 'Account Holder Last Name',
        spanish: 'Apellido del titular de la cuenta',
    },
    routingNumber: {
        english: 'Routing Number',
        spanish: 'Número de ruta',
    },
    accountNumber: {
        english: 'Account Number',
        spanish: 'Número de cuenta',
    },
    confirmAccountNumber: {
        english: 'Confirm Account Number',
        spanish: 'Confirmar número de cuenta',
    },
    accountType: {
        english: 'Select your account type.',
        spanish: 'Seleccione su tipo de cuenta.',
    },
    accountDetails: {
        english: 'Enter your account details',
        spanish: 'Ingrese los detalles de su cuenta',
    },
    checkings: {
        english: 'Checking',
        spanish: 'Cheque'
    },
    savings: {
        english: 'Savings',
        spanish: 'Ahorros'
    },
    showCheckRouting: {
        english: 'Show where I can find my routing and account numbers on a check',
        spanish: 'Mostrar dónde puedo encontrar mis números de ruta y de cuenta en un cheque'
    },
    hideCheckRouting: {
        english: 'Hide where I can find my routing and account numbers on a check',
        spanish: 'Ocultar dónde puedo encontrar mis números de ruta y de cuenta en un cheque'
    },
    validatebuttonText: {
        english: "VALIDATE",
        spanish: "VALIDAR"
    },
};
