import axios from 'axios';
import { getTraceId } from './axiosUtils';
// TODO - write tests for this... not sure how to trigger this logic
// programatically
export default function (unexpectedErrorApiRef, authApiRef) {
    axios.interceptors.response.use(response => {
        unexpectedErrorApiRef.current.setTraceId('');
        return response;
    }, error => {
        const unexpectedErrorApi = unexpectedErrorApiRef.current;
        const authApi = authApiRef.current;
        // Unexpected API error
        if (!error ||
            !error.response ||
            !error.response.status ||
            // !error.response.status.toString().equals(403) || removing because this equals is not a function
            error.response.status.toString().startsWith('5')) {
            console.warn('Unexpected error occurred, setting traceId...');
            const traceId = getTraceId(error);
            const generateMessage = unexpectedErrorApi.getGenerateMessage();
            unexpectedErrorApi.setTraceId(traceId);
            unexpectedErrorApi.setMessage(generateMessage(traceId));
        }
        // Portal is down for maintenance
        if (error?.response?.status === 418) {
            console.warn('Portal is down for maintenance, logging user out...');
            authApi.logout();
        }
        return Promise.reject(error);
    });
}
