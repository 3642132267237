import { Alert, CompositeTable, Heading, Spinner, themes } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import useExperience from '../../hooks/useExperience';
import useGet from '../../hooks/useGet';
import useTranslate from '../../hooks/useTranslate';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
// Ported over from status & repurposed 
const GlossaryTable = () => {
    const { error, loading, data: glossaryList, } = useGet({
        url: '/status-service/customer/statusGlossary',
        options: {
            params: {
                applicationInternalName: 'STATUS_ER'
            }
        }
    });
    const { language } = useExperience();
    const { t } = useTranslate();
    const lang = language.toUpperCase();
    if (loading) {
        return (<Spinner theme={themes.lincoln} id='glossarySpinner'/>);
    }
    if (error) {
        return (<Alert type={'error'}>
      <span><strong>Error!</strong>&nbsp;Unable to load glossary data.</span>
    </Alert>);
    }
    const columns = [
        {
            Cell: ({ data }) => {
                return <strong>{data[`fieldValueTerm_${lang}`]}</strong>;
            },
            Header: t({ english: 'Value', spanish: 'Valor' }),
            accessor: `fieldValueTerm_${lang}`,
            id: `fieldValueTerm_${lang}`,
        },
        {
            Header: t({ english: 'Description', spanish: 'Descripción' }),
            accessor: `fieldValueDescription_${lang}`,
            id: `fieldValueDescription_${lang}`,
        },
    ];
    return (<CompositeTable theme={themes.lincoln} filterable={true} data={glossaryList} columns={columns} paginated={true}/>);
};
const glossaryTranslatable = {
    english: "Glossary of terms",
    spanish: "Glosario de términos"
};
const Glossary = () => {
    const { t } = useTranslate();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.GLOSSARY,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.GLOSSARY.VIEW_GLOSSARY,
        });
    }, []);
    return (<div id='glossary' data-testid='glossary'>
      <Heading elemType='h1' elemStyle='h1'>{t(glossaryTranslatable)}</Heading>
      <GlossaryTable />
    </div>);
};
export default Glossary;
