const getProductAttributeCode = (language, codes, product, attribute, code, isSmallMidCustomer) => {
  let productAttributeCodeValue = '';
  let productAttributeCodeFilter = [];
  try {
    productAttributeCodeFilter = codes?.filter(function (item) {
      const statusCode = isSmallMidCustomer ? item.normalizedCode : item.code;
      return item.product === product && item.attribute === attribute && statusCode === code;
    });

    if (productAttributeCodeFilter?.length) {
      if (language === 'es') {
        productAttributeCodeValue = productAttributeCodeFilter[0].display_ES;
      } else {
        productAttributeCodeValue = productAttributeCodeFilter[0].display_EN;
      }
    }
  } catch (ex) {
    console.log('Exception occured in getProductAttributeCode : ' + ex.toString());
  }
  return productAttributeCodeValue;
};

export default getProductAttributeCode;
