import React from 'react';
import useGet from './../../../hooks/useGet';
import useLanguage from './useLanguage';
import useExperience from './useExperience';

const useUiData = () => {
  const { language } = useLanguage();
  const { experience } = useExperience();

  const url = `/status-service/user/uiData/${experience}/${language}`;

  const { error, loading, data } = useGet(
    {
      url,
      options: {
        headers: {
          Accept: 'application/json, text/plain, */*',
        },
      },
    },
    [language, experience],
  );

  return {
    uiData: data,
    loading,
    error,
  };
};

export default useUiData;
