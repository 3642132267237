import React, { useContext, useState } from 'react';
import PersonalIdentityVerificationForm from './PersonalIdentityVerificationForm';
import { Form } from 'informed';
import useAuthentication from 'src/hooks/useAuthentication';
import { CheckForPrePopData } from '../../api/ApiCalls';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import useAsDataContext from '../../hooks/useAsDataContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { Environment } from 'src/types/Environment';
import { getEnvironment } from 'src/utils/envUtils';
import { StepperContext } from '@digitools/honest-react';
import useAsContext from '../../hooks/useAsConfigContext';
const formStyle = {
    marginTop: '3rem'
};
const IdentityVerification = () => {
    const { authHeader } = useAuthentication();
    const { asConfig } = useAsContext();
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { claimantData, setClaimantData, setIsPrePopLoading, setIsLoading } = useAsDataContext();
    const { trackEvent } = useTealium();
    const stepper = useContext(StepperContext);
    const [invalidUserEmail, setInvalidUserEmail] = useState(false);
    const onSubmit = async (values) => {
        setIsPrePopLoading(true);
        setIsLoading(true);
        const payload = {
            // @ts-ignore
            lastName: values?.accommodationData?.lastName ? values.accommodationData.lastName.trim() : user?.lastName || null,
            // @ts-ignore
            empId: values?.accommodationData?.empID ? values.accommodationData.empID.trim() : null,
            // @ts-ignore
            ssn: values?.accommodationData?.ssn ? values.accommodationData.ssn.trim() : null,
        };
        const emailRegex = RegExp(/^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([-\_.]*[0-9a-zA-Z]*[0-9a-zA-Z])*.[a-zA-Z]{2,9})$/i);
        if (user?.emailAddress && (!user.emailAddress.match(emailRegex) || user.emailAddress.length >= 140)) {
            setIsLoading(false);
            setIsPrePopLoading(false);
            setInvalidUserEmail(true);
        }
        else if ((getEnvironment() === Environment.DEV || getEnvironment() === Environment.NON_PROD) && user?.isLfgUser) {
            if (user?.isIdentityVerified) {
                const { response } = await CheckForPrePopData(payload, authHeader());
                if (response.code === 200) {
                    if (asConfig?.config?.autoPrePopulate) {
                        if (experience === Experience.EMPLOYEE) {
                            setClaimantData({ ...response.data, ...claimantData, ...payload, });
                        }
                        else {
                            setClaimantData({ ...claimantData, ...response.data, ...payload });
                        }
                    }
                    else {
                        setClaimantData({ ...payload });
                    }
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                        event_name: 'continue',
                        event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                        event_version: 'getting started',
                        event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                    });
                    setIsPrePopLoading(false);
                    setIsLoading(false);
                    stepper.next();
                }
                if (response.code === 404) {
                    setClaimantData({ ...claimantData, ...payload });
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                        event_name: 'continue',
                        event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                        event_version: 'getting started',
                        event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                    });
                    setIsPrePopLoading(false);
                    setIsLoading(false);
                    stepper.next();
                    window.scrollTo(0, 0);
                }
                if (response.code !== 200 && response.code !== 404) {
                    setIsPrePopLoading(false);
                    setIsLoading(false);
                }
            }
            else {
                setClaimantData({ ...payload });
                setIsPrePopLoading(false);
                setIsLoading(false);
                stepper.next();
            }
        }
        else {
            const { response } = await CheckForPrePopData(payload, authHeader());
            if (response.code === 200) {
                if (asConfig?.config?.prePopulateInterviewIndicator) {
                    setClaimantData({ ...response.data, ...claimantData, ...payload });
                }
                else {
                    setClaimantData({ ...payload });
                }
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                    event_version: 'getting started',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                });
                setIsPrePopLoading(false);
                setIsLoading(false);
                stepper.next();
            }
            if (response.code === 404) {
                setClaimantData({ ...claimantData, ...payload });
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'continue',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                    event_version: 'getting started',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.START,
                });
                setIsPrePopLoading(false);
                setIsLoading(false);
                stepper.next();
                window.scrollTo(0, 0);
            }
            if (response.code !== 200 && response.code !== 404) {
                setIsPrePopLoading(false);
                setIsLoading(false);
            }
        }
    };
    return (<div>
            <Form onSubmit={onSubmit} style={formStyle}>
                {({ formState }) => (<PersonalIdentityVerificationForm formState={formState} invalidUserEmail={invalidUserEmail}/>)}
            </Form>
        </div>);
};
export default IdentityVerification;
