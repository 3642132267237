import React from 'react';
import { Row, SelectInput, TextInput, Button, Alert, Heading, Box, Icon } from '@digitools/honest-react';
import { IMfaDisplayTextEs } from './i18n/IMfaDisplayTextEs';
import { IMfaDisplayTextEn } from './i18n/IMfaDisplayTextEn';
import getDisplayText from '../../utils/languageDisplay';
import MfaAuthyInstructionsModal from './modal/MfaAuthyInstructionsModal';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
export var IMfaOption;
(function (IMfaOption) {
    IMfaOption["EMAIL"] = "EMAIL";
    IMfaOption["SMS"] = "SMS";
    IMfaOption["VOICE"] = "VOICE";
    IMfaOption["AUTHY"] = "AUTHY";
})(IMfaOption || (IMfaOption = {}));
class MfaPreference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMfaOption: undefined,
            preferredPhoneNumber: undefined,
            mfaOptions: [],
            saveDevice: false,
            continueButtonDisabled: false,
        };
    }
    componentDidMount() {
        this.props.trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MFA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.MFA.PREFERENCES.VIEW,
        });
        this.getMfaOptions();
        if (this.props.phone) {
            this.setState({
                preferredPhoneNumber: this.props.phone.replace(/[^\d]/g, ''),
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            this.getMfaOptions();
        }
    }
    validatePhoneNumber = (value) => {
        if (value) {
            const numbers = value.replace(/[^\d]/g, '');
            if (numbers.length <= 13) {
                this.setState({
                    preferredPhoneNumber: numbers,
                });
            }
            else {
                this.setState({
                    preferredPhoneNumber: numbers.slice(0, 13),
                });
            }
        }
    };
    formatPhoneNumber = (value) => {
        if (value) {
            const numbers = value.replace(/[^\d]/g, '');
            if (numbers.length <= 3) {
                return numbers;
            }
            else if (numbers.length <= 7) {
                return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
            }
            else if (numbers.length <= 10) {
                return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
            }
            else if (numbers.length === 11) {
                return `+${numbers.slice(0, 1)} (${numbers.slice(1, 4)}) ${numbers.slice(4, 7)}-${numbers.slice(7)}`;
            }
            else if (numbers.length === 12) {
                return `+${numbers.slice(0, 2)} ${numbers.slice(2)}`;
            }
            else if (numbers.length === 13) {
                return `+${numbers.slice(0, 3)} ${numbers.slice(3)}`;
            }
            else {
                return `+${numbers.slice(0, 3)} ${numbers.slice(3, 15)}`;
            }
        }
        else {
            return '';
        }
    };
    renderInputField = () => {
        if (this.state.selectedMfaOption) {
            if (this.state.selectedMfaOption === IMfaOption.EMAIL) {
                const invalidEmailAlertText = getDisplayText('invalidEmailAlert', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
                const emailAlert = this.props.mfaOptions.email.enabledForUser ? (<></>) : (<Alert type={'error'} style={{ marginTop: '1rem' }}>
            {invalidEmailAlertText}
          </Alert>);
                return (<>
            <Box style={{ width: '100%' }}>
              <Icon name={'mail'} color={'black'}/>
              {` ${this.props.email}`}
            </Box>
            {emailAlert}
          </>); // [IMfaOption.SMS, IMfaOption.VOICE, IMfaOption.AUTHY].includes(this.state.selectedMfaOption)
            }
            else if (this.state.selectedMfaOption === IMfaOption.SMS ||
                this.state.selectedMfaOption === IMfaOption.VOICE ||
                this.state.selectedMfaOption === IMfaOption.AUTHY) {
                const phoneNumberLabel = getDisplayText('phoneNumber', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
                if (this.props.enrolledInMfa) {
                    return (<Box style={{ width: '100%' }}>
              <Icon name={'phone'} color={'black'}/>
              {` ${this.props.maskedPhone}`}
            </Box>);
                }
                else {
                    return (<TextInput data-testid='phone-input' field={'phone'} label={phoneNumberLabel} validate={this.validatePhoneNumber} validateOnChange={true} validateOnMount={false} initialValue={this.formatPhoneNumber(this.props.phone)} icon={'phone'} mask={this.formatPhoneNumber}/>);
                }
            }
            else {
                return <></>;
            }
        }
        return <></>;
    };
    validateMfaOption = (value) => {
        if (value) {
            switch (value) {
                case '0': {
                    this.setState({ selectedMfaOption: IMfaOption.SMS });
                    this.props.trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
                        event_name: 'mfa Select Sms',
                    });
                    break;
                }
                case '1': {
                    this.setState({ selectedMfaOption: IMfaOption.VOICE });
                    this.props.trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
                        event_name: 'mfa Select Voice',
                    });
                    break;
                }
                case '2': {
                    this.setState({ selectedMfaOption: IMfaOption.EMAIL });
                    this.props.trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
                        event_name: 'mfa Select Email',
                    });
                    break;
                }
                case '3': {
                    this.setState({ selectedMfaOption: IMfaOption.AUTHY });
                    this.props.trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.SELECT,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
                        event_name: 'mfa Select Email',
                    });
                    break;
                }
                default:
                    this.setState({ selectedMfaOption: undefined });
                    break;
            }
        }
    };
    getMfaOptions = () => {
        const options = [];
        const textMessage = getDisplayText('textMessage', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const phoneCall = getDisplayText('phoneCall', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const email = getDisplayText('email', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        // new
        const authy = getDisplayText('authy', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        // \new
        if (this.props.mfaOptions.sms.enabledForCustomer) {
            options.push({ label: textMessage, value: 0 });
        }
        if (this.props.mfaOptions.voice.enabledForCustomer) {
            options.push({ label: phoneCall, value: 1 });
        }
        if (this.props.mfaOptions.email.enabledForCustomer) {
            options.push({ label: email, value: 2 });
        }
        if (this.props.mfaOptions.authy.enabledForCustomer) {
            options.push({ label: authy, value: 3 });
        }
        this.setState({
            mfaOptions: options,
        });
    };
    submitMfaPreference = async () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_PREFERENCE,
            event_name: 'mfa Submit Preference',
        });
        const mfaPreference = { option: this.state.selectedMfaOption };
        this.setState({
            continueButtonDisabled: true,
        });
        setTimeout(() => {
            this.setState({
                continueButtonDisabled: false,
            });
        }, 10000); // 10 seconds because I bet some people have super slow browsers.
        mfaPreference.phoneNumber = this.state.preferredPhoneNumber;
        await this.props.submitMfaPreference(mfaPreference);
    };
    isSmsOrVoice = (mfaPreference) => {
        return [IMfaOption.SMS, IMfaOption.VOICE].includes(mfaPreference);
    };
    isValidInput = () => {
        if (this.state.selectedMfaOption) {
            if (this.state.selectedMfaOption === IMfaOption.EMAIL) {
                return this.props.mfaOptions.email.enabledForUser;
            }
            else {
                if (this.state.preferredPhoneNumber) {
                    return this.state.preferredPhoneNumber.length >= 10;
                }
            }
        }
        else {
            return false;
        }
        return false;
    };
    render() {
        const buttonDisabled = !this.isValidInput();
        const step3Text = this.props.enrolledInMfa ? (<></>) : (<Heading data-testid='step-3-head'>{getDisplayText('step', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)}</Heading>);
        const mfaPreferenceText = getDisplayText('mfaPreference', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const setMethodText = this.props.enrolledInMfa
            ? getDisplayText('enrolledSetMethodText', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)
            : getDisplayText('setMethodText', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const selectAnOptionText = getDisplayText('selectAnOption', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const continueButtonText = getDisplayText('continue', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language);
        const updatePhoneNumberNoteText = this.state.selectedMfaOption &&
            [IMfaOption.SMS, IMfaOption.VOICE, IMfaOption.AUTHY].includes(this.state.selectedMfaOption) &&
            !this.props.enrolledInMfa
            ? `*${getDisplayText('updatePhoneNumberNote', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)}`
            : undefined;
        const dataAndMessagingDisclaimerText = `**${getDisplayText('dataAndMessagingDisclaimer', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)}`;
        const authyHelpText = `**${getDisplayText('authyHelpText', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.language)}`;
        return (<>
        <div data-public={true}>
          {step3Text}
          <Heading data-testid='two-factor-head'>{mfaPreferenceText}</Heading>
          <p id='selectMethodText' data-testid='select-method-text'>{setMethodText}</p>
          <Row className={'mr-1 ml-1'}>
            <SelectInput data-testid='select-method-input' options={this.state.mfaOptions} field={'mfaOptions'} placeholderText={selectAnOptionText} validate={this.validateMfaOption} validateOnChange={true} hideLabel={true} ariaLabelText={'Select two-factor authentication preference'}/>
          </Row>
          <Row className={'mr-1 ml-1'} data-testid='instructions-modal-link'>
            <MfaAuthyInstructionsModal language={this.props.language}/>
          </Row>
          <Row className={'mr-1 ml-1'}>{this.renderInputField()}</Row>
          <Row className={'mr-1 ml-1'}>
            <small id='update-phone-note'>{updatePhoneNumberNoteText}</small>
          </Row>
          <Row className={'mr-1 ml-1'}>
            {this.state.selectedMfaOption &&
                this.isSmsOrVoice(this.state.selectedMfaOption) &&
                dataAndMessagingDisclaimerText && <small id='help-disclaimer-note'>{dataAndMessagingDisclaimerText}</small>}
            {this.state.selectedMfaOption === IMfaOption.AUTHY && !this.props.enrolledInMfa && (<small id='help-disclaimer-note'>{authyHelpText}</small>)}
          </Row>

          <Row className={'mr-1 ml-1'}>
            {!buttonDisabled && !this.state.continueButtonDisabled && (<Button data-testid='continue-btn' id='continueButton' buttonType={'primary'} onClick={this.submitMfaPreference} style={{ marginLeft: 'auto', marginTop: '2rem' }}>
                {continueButtonText}
              </Button>)}
          </Row>
        </div>
      </>);
    }
}
export default MfaPreference;
