export const LCI_WAIVER_PREMIUM_OPTIONS = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];
export const LCI_EVENTS = [
    { label: 'Death', value: 'death' },
    { label: 'Accelerated Death', value: 'accelerated death' },
    { label: 'Dismemberment / Loss of sight', value: 'dismemberment / loss of sight' },
    { label: 'Total disability', value: 'total disability' },
];
export const LCI_EMPLOYER_STATES = [
    {
        value: 'AK',
        label: 'Alaska',
    },
    {
        value: 'AL',
        label: 'Alabama',
    },
    {
        value: 'AR',
        label: 'Arkansas',
    },
    {
        value: 'AZ',
        label: 'Arizona',
    },
    {
        value: 'CA',
        label: 'California',
    },
    {
        value: 'CO',
        label: 'Colorado',
    },
    {
        value: 'CT',
        label: 'Connecticut',
    },
    {
        value: 'DC',
        label: 'District of Columbia',
    },
    {
        value: 'DE',
        label: 'Delaware',
    },
    {
        value: 'FL',
        label: 'Florida',
    },
    {
        value: 'GA',
        label: 'Georgia',
    },
    {
        value: 'GU',
        label: 'Guam',
    },
    {
        value: 'HI',
        label: 'Hawaii',
    },
    {
        value: 'IA',
        label: 'Iowa',
    },
    {
        value: 'ID',
        label: 'Idaho',
    },
    {
        value: 'IL',
        label: 'Illinois',
    },
    {
        value: 'IN',
        label: 'Indiana',
    },
    {
        value: 'KS',
        label: 'Kansas',
    },
    {
        value: 'KY',
        label: 'Kentucky',
    },
    {
        value: 'LA',
        label: 'Louisiana',
    },
    {
        value: 'MA',
        label: 'Massachusetts',
    },
    {
        value: 'MD',
        label: 'Maryland',
    },
    {
        value: 'ME',
        label: 'Maine',
    },
    {
        value: 'MI',
        label: 'Michigan',
    },
    {
        value: 'MN',
        label: 'Minnesota',
    },
    {
        value: 'MO',
        label: 'Missouri',
    },
    {
        value: 'MS',
        label: 'Mississippi',
    },
    {
        value: 'MT',
        label: 'Montana',
    },
    {
        value: 'NE',
        label: 'Nebraska',
    },
    {
        value: 'NH',
        label: 'New Hampshire',
    },
    {
        value: 'NJ',
        label: 'New Jersey',
    },
    {
        value: 'NM',
        label: 'New Mexico',
    },
    {
        value: 'NY',
        label: 'New York',
    },
    {
        value: 'NV',
        label: 'Nevada',
    },
    {
        value: 'NC',
        label: 'North Carolina',
    },
    {
        value: 'ND',
        label: 'North Dakota',
    },
    {
        value: 'OH',
        label: 'Ohio',
    },
    {
        value: 'OK',
        label: 'Oklahoma',
    },
    {
        value: 'OR',
        label: 'Oregon',
    },
    {
        value: 'PA',
        label: 'Pennsylvania',
    },
    {
        value: 'PR',
        label: 'Puerto Rico',
    },
    {
        value: 'RI',
        label: 'Rhode Island',
    },
    {
        value: 'SC',
        label: 'South Carolina',
    },
    {
        value: 'SD',
        label: 'South Dakota',
    },
    {
        value: 'TN',
        label: 'Tennessee',
    },
    {
        value: 'TX',
        label: 'Texas',
    },
    {
        value: 'UT',
        label: 'Utah',
    },
    {
        value: 'VA',
        label: 'Virginia',
    },
    {
        value: 'VI',
        label: 'Virgin Islands',
    },
    {
        value: 'VT',
        label: 'Vermont',
    },
    {
        value: 'WA',
        label: 'Washington',
    },
    {
        value: 'WI',
        label: 'Wisconsin',
    },
    {
        value: 'WV',
        label: 'West Virginia',
    },
    {
        value: 'WY',
        label: 'Wyoming',
    },
];
export const LCI_EMPLOYER_PROVINCES = [
    {
        value: 'AB',
        label: 'Alberta',
    },
    {
        value: 'BC',
        label: 'British Columbia',
    },
    {
        value: 'MB',
        label: 'Manitoba',
    },
    {
        value: 'NB',
        label: 'New Brunswick',
    },
    {
        value: 'NL',
        label: 'Newfoundland-Labrador',
    },
    {
        value: 'NS',
        label: 'Nova Scotia',
    },
    {
        value: 'NT',
        label: 'Northwest Territories',
    },
    {
        value: 'NU',
        label: 'Nunavut',
    },
    {
        value: 'ON',
        label: 'Ontario',
    },
    {
        value: 'PE',
        label: 'Prince Edward Island',
    },
    {
        value: 'QC',
        label: 'Quebec',
    },
    {
        value: 'SK',
        label: 'Saskatchewan',
    },
    {
        value: 'YT',
        label: 'Yukon',
    },
];
export const LCI_EMPLOYER_COUNTRIES = [
    { label: 'United States of America', value: 'USA' },
    { label: 'Canada', value: 'CAN' },
];
export const LCI_EMPLOYEE_WORK_STATUS = [
    {
        value: 'RETIRED',
        label: 'Retired',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
];
export const LCI_EMPLOYEE_DEPENDENT_RELATION = [
    {
        value: 'SPOUSE',
        label: 'Spouse',
    },
    {
        value: 'CHILD',
        label: 'Child',
    },
    {
        value: 'PARTNER',
        label: 'Partner',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
];
export const LCI_EMPLOYEE_DEPENDENT_YES_NO = [
    {
        value: 'YES',
        label: 'Yes',
    },
    {
        value: 'NO',
        label: 'No',
    },
];
export const LCI_GENDER = [
    {
        value: 'M',
        label: 'Male',
    },
    {
        value: 'F',
        label: 'Female',
    },
];
export const LCI_EMPLOYEE_REASON_FOR_CHANGE = [
    {
        value: 'Salary Increase',
        label: 'Salary Increase',
    },
    {
        value: 'Open Enrollment',
        label: 'Open Enrollment',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];
export const LCI_EMPLOYEE_MARITAL_STATUS = [
    { label: 'Single', value: 'SINGLE' },
    { label: 'Married', value: 'MARRIED' },
    { label: 'Divorced', value: 'DIVORCED' },
    { label: 'Widowed', value: 'WIDOWED' },
    { label: 'Separated', value: 'SEPARATED' },
];
export const getTitles = (employeeLabel) => {
    return {
        gettingStartedTitle: 'Getting Started',
        employerInfoTitle: 'Employer Info',
        employeeInfoTitle: `${employeeLabel} Info`,
        policyInfoTitle: 'Policy Info',
        policyBeneficiariesTitle: 'Policy Beneficiaries',
        dependentInfoTitle: 'Dependent Info',
        callerInfoTitle: 'Caller Info',
        additionalInfoTitle: 'Additional Info',
        reviewSignTitle: 'Review & Sign',
    };
};
export const StepTitle = (employeeLabel) => {
    const { gettingStartedTitle, employerInfoTitle, employeeInfoTitle, policyInfoTitle, policyBeneficiariesTitle, dependentInfoTitle, callerInfoTitle, additionalInfoTitle, reviewSignTitle, } = getTitles(employeeLabel);
    return {
        death: {
            employee: {
                sections: [
                    gettingStartedTitle,
                    employerInfoTitle,
                    employeeInfoTitle,
                    policyInfoTitle,
                    policyBeneficiariesTitle,
                    callerInfoTitle,
                    additionalInfoTitle,
                    reviewSignTitle,
                ],
                sectionTitle: `${employeeLabel} Proof of Death`,
            },
            dependent: {
                sections: [
                    gettingStartedTitle,
                    employerInfoTitle,
                    employeeInfoTitle,
                    policyInfoTitle,
                    dependentInfoTitle,
                    callerInfoTitle,
                    additionalInfoTitle,
                    reviewSignTitle,
                ],
                sectionTitle: 'Dependent Proof of Death',
            },
        },
        'accelerated death': {
            employee: {
                sections: [gettingStartedTitle, employerInfoTitle, employeeInfoTitle, policyInfoTitle, additionalInfoTitle, reviewSignTitle],
                sectionTitle: 'Application for Accelerated Benefits',
            },
            dependent: {
                sections: [
                    gettingStartedTitle,
                    employerInfoTitle,
                    employeeInfoTitle,
                    policyInfoTitle,
                    dependentInfoTitle,
                    additionalInfoTitle,
                    reviewSignTitle,
                ],
                sectionTitle: 'Application for Dependent Accelerated Benefits',
            },
        },
        'dismemberment / loss of sight': {
            employee: {
                sections: [gettingStartedTitle, employerInfoTitle, employeeInfoTitle, policyInfoTitle, additionalInfoTitle, reviewSignTitle],
                sectionTitle: 'Proof of Loss (Dismemberment or Loss of Sight)',
            },
            dependent: {
                sections: [
                    gettingStartedTitle,
                    employerInfoTitle,
                    employeeInfoTitle,
                    policyInfoTitle,
                    dependentInfoTitle,
                    additionalInfoTitle,
                    reviewSignTitle,
                ],
                sectionTitle: 'Dependent Dismemberment Claim',
            },
        },
        'total disability': {
            true: {
                sections: [gettingStartedTitle, employerInfoTitle, employeeInfoTitle, policyInfoTitle, additionalInfoTitle, reviewSignTitle],
                sectionTitle: 'Proof of Total Disability: Waiver of Premium',
            },
            false: {
                sections: [gettingStartedTitle, employerInfoTitle, employeeInfoTitle, policyInfoTitle, additionalInfoTitle, reviewSignTitle],
                sectionTitle: 'Permanent and Total Disability',
            },
        },
    };
};
export const EMPLOYER_INFO_LABEL = {
    employerName: 'Employer Name',
    policyNumber: 'Policy Number',
    streetAddress1: 'Residental Address 1',
    streetAddress2: 'Residental Address 2',
    city: 'City',
    postalcode: 'Postal Code',
    state: 'State',
    province: 'Province',
    country: 'Country',
};
export const EMPLOYEE_INFO_LABEL = (employeeLabel) => {
    return {
        employeeId: `${employeeLabel} ID`,
        ssn: 'SSN',
        firstName: 'First Name',
        lastName: 'Last Name',
        middleInitial: 'Middle Initial',
        dob: 'Date of Birth',
        gender: 'Sex Assigned at Birth',
        phoneNumber: 'Phone Number',
        maritalStatus: 'Marital Status',
        streetAddress1: 'Residental Address 1',
        streetAddress2: 'Residental Address 2',
        city: 'City',
        postalcode: 'Postal Code',
        state: 'State',
        province: 'Province',
        country: 'Country',
    };
};
export const EMPLOYEE_POLICY_INFO_LABEL = (employeeLabel) => {
    return {
        workHours: 'Work Hours',
        earnings: 'Earnings',
        workStatus: 'Work Status',
        classification: 'Classification',
        occupation: 'Occupation',
        terminationInfo: `Is the ${employeeLabel} Terminated?`,
        dateOfTermination: 'Date of Termination',
        dateOfRetired: 'Date of Retired',
        retirementStatusInfo: `Is ${employeeLabel} receiving retirement benefits`,
        retirementAmount: 'Insurance at Retirement',
        dateEmployed: 'Date Employed',
        lastWorked: 'Last Worked',
        lastPaid: 'Last Paid',
        dependentEffectiveDate: `Dependent's Coverage Effective Date`,
        dateOfDeath: 'Date of Death',
        dateBenefitChange: 'Last Increase/Decrease in Benefits',
        reason: 'Reason',
        otherReason: 'Other Reason',
        dateOfDisability: 'Date of Disability',
        reasonForLeaving: 'Reason for Leaving',
        basicAmount: `${employeeLabel} Basic Life Coverage`,
        basicADandDAmount: `${employeeLabel} Basic AD&D`,
        optionalAmount: `${employeeLabel} Optional Life Coverage`,
        optionalADandDAmount: `${employeeLabel} Optional AD&D`,
        basicDependentAmount: 'Dependent Basic Life Coverage',
        basicADandDDependentAmount: 'Dependent Basic AD&D',
        optionalDependentAmount: 'Dependent Optional Life Coverage',
        optionalADandDDependentAmount: 'Dependent Optional AD&D',
        dateOfAccident: 'Date of Accident',
        accidentLocation: 'Accident Location',
        briefAboutAccident: 'Brief About Accident',
    };
};
export const DEPENDENT_INFO_LABEL = (employeeLabel) => {
    return {
        firstName: 'First Name',
        lastName: 'Last Name',
        middleInitial: 'Middle Initial',
        dependentRelationship: `Relationship to ${employeeLabel}`,
        dependentEmployeeToCompany: `Was Dependent an ${employeeLabel} of this Company?`,
        dob: 'Date of Birth',
        gender: 'Sex Assigned at Birth',
        ssn: 'SSN',
        phoneNumber: 'Phone Number',
        streetAddress1: 'Residental Address 1',
        streetAddress2: 'Residental Address 2',
        city: 'City',
        postalcode: 'Postal Code',
        state: 'State',
        province: 'Province',
        country: 'Country',
        dateOfDeath: 'Date of Death'
    };
};
export const CALLER_INFO_LABEL = {
    firstName: 'First Name',
    lastName: 'Last Name',
    middleInitial: 'Middle Initial',
    phoneNumber: 'Phone Number',
    relationshipToDeceased: 'Relationship to Deceased',
    streetAddress1: 'Residental Address 1',
    streetAddress2: 'Residental Address 2',
    city: 'City',
    postalcode: 'Postal Code',
    state: 'State',
    province: 'Province',
    country: 'Country',
};
export const ADDITIONAL_INFO_LABEL = {
    notes: 'Notes',
    documents: 'Documents',
};
export const POLICY_BENEFICIARY_LABEL = {
    beneficiaryCategory: 'Category',
    beneficiaryType: 'Type',
    allocation: 'Allocation',
    firstName: 'First Name',
    lastName: 'Last Name',
    middleInitial: 'Middle Initial',
    dateOfBirth: 'Date of Birth',
    relationship: 'Relationship',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    gender: 'Sex Assigned at Birth',
    charityName: 'Charity Name',
    contactName: 'Contact Name',
    trustName: 'Trust Name',
    dateOfTrust: 'Date of Trust',
    ssnOrTaxID: 'SSN or Tax ID',
    address1: 'Address 1',
    address2: 'Address 2',
    country: 'Country',
    state: 'State',
    city: 'City',
    postalCode: 'Postal Code',
};
export const MAX_FILE_COUNT = 10;
export const MAX_LENGTH_FNAME = 10;
export const MAX_LENGTH_LNAME = 20;
export const MAX_LENGTH_WHOURS = 10;
export const MAX_LENGTH_CITY = 30;
export const GetCurrentDate = () => {
    const date = new Date();
    const today = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    return today;
};
