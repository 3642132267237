import { Alert, Box, Loader, Row } from '@digitools/honest-react';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import React from 'react';
import withAuthentication from '../../hocs/withAuthentication';
import withExperience from '../../hocs/withExperience';
import { getEnvVariable } from '../../utils/envUtils';
import getDisplayText from '../../utils/languageDisplay';
import { IMfaDisplayTextEn } from './i18n/IMfaDisplayTextEn';
import { IMfaDisplayTextEs } from './i18n/IMfaDisplayTextEs';
// @ts-ignore
import Styles from './MfaContainer.scss';
import MfaPhoneConfirmation from './MfaPhoneConfirmation';
import MfaPreference from './MfaPreference';
import withTealium from '../../hocs/withTealium';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';
export class MfaContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mfaOptionSelected: false,
            mfaOptions: undefined,
            mfaPreferenceSubmitted: false,
            otpSent: false,
            errorMessage: undefined,
            isGenericError: false,
            isRetrievalError: false,
            isSaveMfaPreferenceError: false,
            isInvalidCodeError: false,
            isLockedAccountError: false,
            isNonApprovedCountryError: false,
            isInvalidPhoneError: false,
            enrolledInMfa: false,
        };
    }
    async componentDidMount() {
        await this.getMfaOptions();
        this.setState({
            enrolledInMfa: this.checkMfaEnrollmentStatus(),
        });
    }
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.experienceContext.language !== prevProps.experienceContext.language) {
            this.setErrorLanguage();
        }
    }
    checkMfaEnrollmentStatus = () => {
        const decoded = JwtDecode(this.props.authenticationContext.authHeader().split(' ')[1]);
        return decoded.auth0EnrollmentStatus || decoded.authyEnrollmentStatus;
    };
    setErrorLanguage() {
        if (this.state.isGenericError) {
            this.setState({
                errorMessage: getDisplayText('genericError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isRetrievalError) {
            this.setState({
                errorMessage: getDisplayText('mfaRetreivalError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isSaveMfaPreferenceError) {
            this.setState({
                errorMessage: getDisplayText('mfaSaveError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isInvalidCodeError) {
            this.setState({
                errorMessage: getDisplayText('invalidCodeError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isLockedAccountError) {
            this.setState({
                errorMessage: getDisplayText('accountLockedError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isNonApprovedCountryError) {
            this.setState({
                errorMessage: getDisplayText('nonApprovedCountryError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
        else if (this.state.isInvalidPhoneError) {
            this.setState({
                errorMessage: getDisplayText('invalidPhoneError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
            });
        }
    }
    maskEmail = (email) => {
        const parts = email.split('@');
        if (parts[0].length > 3) {
            const unmasked = parts[0].slice(-3);
            return `*****${unmasked}@${parts[1]}`;
        }
        else {
            return `***@${parts[1]}`;
        }
    };
    maskPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            const lineNumber = phoneNumber.slice(-4);
            return `(***) ***-${lineNumber}`;
        }
        return '';
    };
    getMfaOptions = async () => {
        if (this.props.authenticationContext.user) {
            try {
                const config = { headers: { Authorization: this.props.authenticationContext.authHeader() } };
                const response = await axios.get(`/mfa-service/active-options`, config);
                const options = response.data;
                this.setState({
                    mfaOptions: options,
                });
            }
            catch (error) {
                this.setState({
                    errorMessage: getDisplayText('mfaRetreivalError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                    isRetrievalError: true,
                    isGenericError: false,
                    isInvalidCodeError: false,
                    isLockedAccountError: false,
                    isSaveMfaPreferenceError: false,
                });
            }
        }
    };
    submitMfaPreference = async (mfaPreference) => {
        try {
            const config = { headers: { Authorization: this.props.authenticationContext.authHeader() } };
            let response;
            if (this.state.enrolledInMfa) {
                response = await axios.post(`/mfa-service/mfa-selection?option=${mfaPreference.option}`, {}, config);
            }
            else {
                response = await axios.post('/mfa-service/mfa-enrollment', mfaPreference, config);
            }
            const decoded = JwtDecode(response.data.updatedToken);
            if (decoded.status && decoded.status === 'USE_AUTH0_MFA') {
                const updatedToken = response.data.updatedToken;
                const auth0State = this.props.authenticationContext.auth0State;
                const url = `https://${getEnvVariable('auth0Domain')}/continue?token=${updatedToken}&state=${auth0State}`;
                await window.location.replace(url);
                return;
            }
            await this.props.authenticationContext.refreshToken(response.data.updatedToken);
            this.setState({
                mfaPreference: mfaPreference.option,
                mfaPreferenceSubmitted: true,
            }, () => this.sendOtp(response.data.updatedToken));
        }
        catch (error) {
            if (error && error.response && error.response.data.errors && error.response.data.errors) {
                const errors = error.response.data.errors;
                if (errors.length === 2) {
                    this.setState({
                        errorMessage: getDisplayText('invalidPhoneError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                        isSaveMfaPreferenceError: false,
                        isRetrievalError: false,
                        isGenericError: false,
                        isInvalidCodeError: false,
                        isLockedAccountError: false,
                        isInvalidPhoneError: true,
                        isNonApprovedCountryError: false,
                    });
                }
                else {
                    error = errors[0];
                    if (error.defaultMessage === 'Blacklisted Phone Number') {
                        this.setState({
                            errorMessage: getDisplayText('nonApprovedCountryError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                            isSaveMfaPreferenceError: false,
                            isRetrievalError: false,
                            isGenericError: false,
                            isInvalidCodeError: false,
                            isLockedAccountError: false,
                            isInvalidPhoneError: false,
                            isNonApprovedCountryError: true,
                        });
                    }
                    else {
                        this.setState({
                            errorMessage: getDisplayText('invalidPhoneError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                            isSaveMfaPreferenceError: false,
                            isRetrievalError: false,
                            isGenericError: false,
                            isInvalidCodeError: false,
                            isLockedAccountError: false,
                            isInvalidPhoneError: true,
                            isNonApprovedCountryError: false,
                        });
                    }
                }
            }
            else {
                this.setState({
                    errorMessage: getDisplayText('mfaSaveError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                    isSaveMfaPreferenceError: true,
                    isRetrievalError: false,
                    isGenericError: false,
                    isInvalidCodeError: false,
                    isLockedAccountError: false,
                    isInvalidPhoneError: false,
                    isNonApprovedCountryError: false,
                });
            }
        }
    };
    sendOtp = async (token) => {
        try {
            this.setState({
                otpSent: true,
            });
            const authHeader = token ? `Bearer ${token}` : this.props.authenticationContext.authHeader();
            const config = { headers: { Authorization: authHeader }, validateStatus: this.validateStatus };
            if (this.state.mfaPreference === 'VOICE') {
                await axios.get('/mfa-service/voice-otp', config);
            }
            else if (this.state.mfaPreference === 'SMS') {
                await axios.get('/mfa-service/sms-otp', config);
            }
        }
        catch (error) {
            await this.setState({
                errorMessage: getDisplayText('genericError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                isGenericError: true,
                isRetrievalError: false,
                isInvalidCodeError: false,
                isLockedAccountError: false,
                isSaveMfaPreferenceError: false,
                isInvalidPhoneError: false,
                isNonApprovedCountryError: false,
            });
        }
    };
    submitOtp = async (otp, saveDevice) => {
        try {
            const config = {
                headers: { Authorization: this.props.authenticationContext.authHeader() },
                validateStatus: this.validateStatus,
            };
            const response = await axios.get(`/mfa-service/otp/${otp}?saveDevice=${saveDevice}`, config);
            const updatedToken = response.data.updatedToken;
            const auth0State = this.props.authenticationContext.auth0State;
            const url = `https://${getEnvVariable('auth0Domain')}/continue?token=${updatedToken}&state=${auth0State}`;
            window.location.replace(url);
        }
        catch (error) {
            if (error.response.status === 401 &&
                error.response.data.message &&
                error.response.data.message === 'Invalid OTP') {
                this.setState({
                    errorMessage: getDisplayText('invalidCodeError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                    isInvalidCodeError: true,
                    isGenericError: false,
                    isRetrievalError: false,
                    isLockedAccountError: false,
                    isSaveMfaPreferenceError: false,
                    isInvalidPhoneError: false,
                    isNonApprovedCountryError: false,
                });
            }
            else if (error.response.status === 403) {
                this.setState({
                    errorMessage: getDisplayText('accountLockedError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                    isLockedAccountError: true,
                    isInvalidCodeError: false,
                    isGenericError: false,
                    isRetrievalError: false,
                    isSaveMfaPreferenceError: false,
                    isInvalidPhoneError: false,
                    isNonApprovedCountryError: false,
                });
            }
            else {
                this.setState({
                    errorMessage: getDisplayText('genericError', IMfaDisplayTextEn, IMfaDisplayTextEs, this.props.experienceContext.language),
                    isGenericError: true,
                    isRetrievalError: false,
                    isInvalidCodeError: false,
                    isLockedAccountError: false,
                    isSaveMfaPreferenceError: false,
                    isInvalidPhoneError: false,
                    isNonApprovedCountryError: false,
                });
            }
            this.resetAlert();
        }
    };
    restartMfa = () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MFA,
            event_name: 'mfa Select Another Method',
        });
        const token = this.props.authenticationContext.authHeader().split(' ')[1];
        window.location.replace(`https://${window.location.hostname}/customer/mfa/register?token=${token}&state=${this.props.authenticationContext.auth0State}`);
    };
    renderScreen = () => {
        const user = this.props.authenticationContext.user;
        if (user && this.state.mfaPreferenceSubmitted) {
            return (<MfaPhoneConfirmation mfaPreference={this.state.mfaPreference} phoneNumber={this.maskPhoneNumber(this.props.authenticationContext.user.phoneNumber)} submitOtp={this.submitOtp} sendOtp={this.sendOtp} restartMfa={this.restartMfa} language={this.props.experienceContext.language} trackEvent={this.props.trackEvent} trackView={this.props.trackView}/>);
        }
        else {
            return (<MfaPreference phone={this.state.mfaOptions.voice.phoneNumber} maskedPhone={this.maskPhoneNumber(this.state.mfaOptions.voice.phoneNumber)} submitMfaPreference={this.submitMfaPreference} email={this.maskEmail(user.emailAddress)} mfaOptions={this.state.mfaOptions} language={this.props.experienceContext.language} enrolledInMfa={this.state.enrolledInMfa} trackEvent={this.props.trackEvent} trackView={this.props.trackView}/>);
        }
    };
    validateStatus = (status) => {
        if (status === 401) {
            const token = this.props.authenticationContext.authHeader().split(' ')[1];
            const decoded = JwtDecode(token);
            // @ts-ignore
            const exp = decoded.exp * 1000; // Add 3 trailing zeroes to match the Date.now() format.
            const now = Date.now();
            if (exp < now) {
                window.location.replace(`${window.location.hostname}/customer/public/login`);
            }
        }
        return status === 200;
    };
    resetAlert = async () => {
        await setTimeout(() => {
            this.setState({
                errorMessage: undefined,
                isGenericError: false,
                isInvalidCodeError: false,
                isInvalidPhoneError: false,
                isLockedAccountError: false,
                isNonApprovedCountryError: false,
                isRetrievalError: false,
                isSaveMfaPreferenceError: false,
            });
        }, 5000);
    };
    render() {
        const alert = this.state.errorMessage ? (<Alert type={'error'} closeable={true} className={Styles.alertStyle} data-public={true} data-testid='mfa-container-alert'>
        {this.state.errorMessage}
      </Alert>) : (<></>);
        if (this.props.authenticationContext.user && this.state.mfaOptions) {
            return (<>
          <Row className={'mt-3'}>{alert}</Row>
          <Row className={'mt-3'}>
            <Box color={'grayLightest'} className={Styles.containerStyle}>
              {this.renderScreen()}
            </Box>
          </Row>
        </>);
        }
        else if (this.state.errorMessage) {
            return <Row className={'mt-3'}>{alert}</Row>;
        }
        else {
            return <Loader id='mfa-container-spinner'/>;
        }
    }
}
export default withRouter(withAuthentication(withTealium(withExperience(MfaContainer))));
