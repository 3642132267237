import { useRef, useReducer, useState } from 'react';
import axios from 'axios';
import { UnauthorizedError } from 'src/types/UnauthorizedError';
import useAuthentication from './useAuthentication';
const dataPostReducer = (state, action) => {
    switch (action.type) {
        case 'POST_INIT':
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case 'POST_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                data: action.payload,
            };
        case 'POST_FAILURE':
            return {
                data: undefined,
                loading: false,
                error: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
const configureOptions = (opts, authHeader) => {
    const defaultHeaders = {
        Authorization: authHeader,
        'Content-Type': 'application/json',
    };
    const configuredOptions = {
        ...opts,
    };
    if (opts.headers) {
        configuredOptions.headers = {
            ...defaultHeaders,
            ...opts.headers,
        };
    }
    else {
        configuredOptions.headers = defaultHeaders;
    }
    return configuredOptions;
};
const usePost = ({ url, payload, options, handleUnauthorized, success, failure, retry }) => {
    const { authHeader } = useAuthentication();
    const [state, dispatch] = useReducer(dataPostReducer, {
        loading: false,
        error: null,
        data: null,
    });
    const [_, throwError] = useState();
    // Becaues we wrap these up in refetch
    const urlRef = useRef(url);
    const payloadRef = useRef(payload);
    const optionsRef = useRef(options);
    const retryRef = useRef(retry);
    urlRef.current = url;
    optionsRef.current = options;
    retryRef.current = retry;
    payloadRef.current = payload;
    const postData = async (uri, pld, opts = {}, canceler, handlers, postConfig = {}) => {
        // Configure options
        const configuredOptions = configureOptions(opts, authHeader());
        // We are starting to fetch
        dispatch({ type: 'POST_INIT' });
        // Define error handler function
        const handleError = (e) => {
            // By default we throw unauthorized error
            if (!handleUnauthorized && e.response && (e.response.status === 403 || e.response.status === 401)) {
                // So, we need to do some magic to throw up to an error boundary
                // https://github.com/facebook/react/issues/14981
                throwError(() => {
                    throw new UnauthorizedError('Unauthorized Error');
                });
            }
            // Call failure handler if there is one
            if (handlers.failure) {
                handlers.failure(e);
            }
            // Finish up if we were not canceled
            if (!canceler.canceled) {
                dispatch({ type: 'POST_FAILURE', error: e });
            }
        };
        try {
            const result = await axios.post(uri, pld, configuredOptions);
            if (!canceler.canceled) {
                dispatch({ type: 'POST_SUCCESS', payload: result.data });
                // Call success handler if there is one
                if (handlers.success) {
                    handlers.success(result.data);
                }
            }
        }
        catch (error) {
            // If a retry was passed then try again
            if (postConfig.retry) {
                try {
                    // Configure options
                    const configuredOptions = configureOptions(postConfig.retry.options, authHeader());
                    // Make the call
                    const result = await axios.post(postConfig.retry.url, postConfig.retry.payload, configuredOptions);
                    if (!canceler.canceled) {
                        dispatch({ type: 'POST_SUCCESS', payload: result.data });
                        // Call success handler if there is one
                        if (handlers.success) {
                            handlers.success(result.data);
                        }
                    }
                }
                catch (e) {
                    handleError(e);
                }
            }
            else {
                handleError(error);
            }
        }
    };
    const post = ({ url: newUrl, payload: newPayload, options: newOptions, success: newSuccess, failure: newFailure, retry: newRetry, } = {}) => {
        // Create canceler
        const canceler = {
            canceled: false,
        };
        // Create handlers
        const handlers = {
            success: newSuccess || success,
            failure: newFailure || failure,
        };
        const postConfig = {
            retry: newRetry || retryRef.current,
        };
        // Fetch the data
        postData(newUrl || urlRef.current, newPayload || payloadRef.current, newOptions || optionsRef.current, canceler, handlers, postConfig);
        // Return function that will cancel the result
        return () => (canceler.canceled = true);
    };
    return {
        ...state,
        post,
    };
};
export default usePost;
