import { Button } from "@digitools/honest-react";
import styled from "@digitools/honest-react/dist/lib/theming/styled-components";
/* creates flush button around something - without text decoration */
export const SlimButton = styled('button') `
  width: 100%;
  display: flex;
  border: none;
  padding: 0;
  text-decoration: none !important;
  outline: none;
  background-color: white;
  cursor: pointer;
  /* margin: 1rem 0 1rem 0; */  //handled by row now
  &:focus {
    box-shadow: 2px 2px 10px ${({ theme }) => theme.palette.grayLighter};
  }
`;
export const StickyDiv = styled('div') `
  /* ~For sticky bottom overlay~ */
  position: sticky;
  bottom: 0;
  padding-top: .5rem;
  background-color: white;
  /* protect contents above & below from wide objects & shadows peeking through */
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
`;
export const StepsDiv = styled('div') `

  /* ~~ */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media(max-width: 765px) {
      flex-direction: column-reverse;
  }
`;
export const ContinueButton = styled(Button) `
    margin-left: 0 !important;
    @media(max-width: 765px) {
        margin-bottom: 0.5rem;
    }
`;
export const BackButton = styled(Button) `
    @media(max-width: 765px) {
        margin-top: 0.5rem;
    }
`;
export const StyledCollapseHeading = styled('span') `
  line-height: 2rem;
  color: #244f8b;
  font-weight: 400;
  font-size: 1.25rem;
`;
export const InCollapseButton = styled(Button) `
  font-weight: bold; 
  border: 2px solid ${({ theme }) => theme.palette.accent1Dark};
  color: ${({ theme }) => theme.palette.accent1Dark};
  text-transform: uppercase;
  width: 100%;
  :hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.accent1Dark} !important;
    border: 2px solid ${({ theme }) => theme.palette.accent1Dark} !important;
  }
  :focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.accent1Dark} !important;
    border: 2px solid ${({ theme }) => theme.palette.accent1Dark} !important;
  }
`;
