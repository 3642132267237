import React, { useCallback } from 'react';
import { Language } from 'src/types/Language';
import LanguageToggle from '../../utils/LanguageToggle';
import { Icon } from '@digitools/honest-react';
/**
 * Utility hook for updating a "validate" function reference as a side effect of
 * language updating in the experience context. Prefer this, over using the LanguageToggle
 * component for a11y.
 *
 * example:
 * useValidators([requiredFieldErrorMap, userNameFormatErrorMap], [isNotEmpty, isValidUsername])
 *
 * @param errorMessages A list of Maps of supported languages to their corresponding error message. The index of a Map corresponds with the index of a function.
 * @param validationFunctions a list of functions that returns whether an input is valid. The index of a function corresponds with the index of a Map
 */
function useValidators(errorMessages, validationFunctions) {
    return useCallback((inpt, formValues = {}) => {
        for (let i = 0; i < validationFunctions.length; i++) {
            const isValid = validationFunctions[i];
            if (!isValid(inpt, formValues)) {
                return (<>
              <Icon size='xs' style={{ marginRight: '.2rem' }} name='error' color='primary'/>
              <LanguageToggle en={errorMessages[i][Language.ENGLISH]} es={errorMessages[i][Language.SPANISH]}/>
            </>);
            }
        }
        return undefined;
    }, [errorMessages, validationFunctions]);
}
export default useValidators;
