export const LoginDisplayTextEs = {
    auth0error: ' Intente borrar las cookies de su navegador y vuelva a enviar su información; si continúa teniendo problemas, contáctenos para obtener más ayuda.',
    accessDeniedBody: 'Nombre de usuario o contraseña inválidos. Su cuenta será bloqueada después de 5 intentos inválidos',
    accessDeniedTitle: '¡Error de inicio de sesión!',
    accountUnblockedBody: 'Su cuenta ha sido desbloqueada.',
    accountUnblockedTitle: '¡Éxito!',
    brandingMessageBody: 'A partir del 1º de septiembre de 2019, el nombre de Liberty Life Assurance Company of Boston cambió a The Lincoln National Life Insurance Company. Durante la transición, es posible que continúe viendo menciones de ambos nombres.',
    brandingMessageTitle: 'Actualización de la transición de la marca',
    close: 'Cerca',
    emailExpiredBody: 'Inicie sesión para reenviar el correo electrónico de verificación.',
    emailExpiredTitle: 'Enlace de correo electrónico caducado!',
    // Email Verification Modal Text
    emailVerificationHelp: '¿Nunca has recibido un correo electrónico de verificación?',
    emailVerificationLink: 'Aprende más',
    emailVerificationModalTitle: 'Pasos para verificar tu correo electrónico',
    emailVerificationHeader: 'Si no puede encontrar el correo electrónico después de que lo enviamos, estos pasos pueden ayudarlo.',
    emailVerificationSection1Title: 'Revisa tus carpetas de spam y basura',
    emailVerificationSection1Body: "Un filtro de spam o una regla de correo electrónico podría haber movido el correo electrónico. Busque un correo electrónico con el asunto 'Confirme su correo electrónico' desde My Lincoln Portal en la carpeta Spam, Papelera, Papelera, Elementos eliminados o Archivo.",
    emailVerificationSection2Title: 'Agréganos a tu lista de remitentes confiables',
    emailVerificationSection2Body: 'Agregue donotreply@mail.mylincolnportal.com a su lista de remitentes de confianza.',
    emailVerificationSection3Title: 'Reenvíe el correo electrónico',
    emailVerificationSection3Body: 'Para reenviar el correo electrónico de verificación, inicie sesión en su cuenta y se enviará automáticamente un nuevo correo electrónico de verificación.',
    emailVerificationSection4Title: 'Esperar hasta 24 horas',
    emailVerificationSection4Body: 'El correo electrónico de verificación para completar la configuración de su cuenta puede tardar hasta 24 horas en llegar. Si después de 24 horas aún no ha recibido el correo electrónico de verificación, contáctenos.',
    //
    passwordResetExpiredBody: 'Por favor, utilice el enlace Olvidé mi Nombre de Usuario y Contraseña o inicie sesión para restablecer su contraseña.',
    passwordResetExpiredTitle: '¡Enlace no válido o caducado!',
    emailUs: ' Envíenos un correo electrónico ',
    emailVerifiedBody: 'Su correo electrónico ha sido verificado.',
    emailVerifiedTitle: '¡Éxito!',
    floodingMessageBody: 'Nuestros pensamientos están con los afectados por las inundaciones en el Medio oeste. Si un empleado tiene un reclamo, Lincoln Financial Group está aquí para ayudarlo. Por favor llame a su oficina de reclamos asignada para obtener ayuda.',
    floodingMessageTitle: 'Inundaciones en el Medio Oeste.',
    newExperienceBody: 'Hemos implementado una experiencia más segura de My Lincoln Portal. Se solicitará a todos los usuarios que revisen y actualicen sus credenciales de inicio de sesión para cumplir con los estándares de seguridad de Lincoln y registrarse para la autenticación de dos factores.',
    newExperienceTitle: 'Nueva experiencia de inicio de sesión',
    invalidAttemptBody: 'Usuario o contraseña inválidos. Su cuenta será bloqueada después de 5 intentos inválidos.',
    invalidAttemptTitle: 'Error de inicio de sesión!',
    invalidAttemptSiteMinderBody: 'Nombre de usuario o contraseña no válidos. Su cuenta se bloqueará durante 15 minutos después de 5 intentos no válidos',
    invalidAttemptSiteMinderTitle: 'Error de inicio de sesión!',
    userProfileNotFoundBody: 'Este nombre de usuario no está asociado con una cuenta.',
    userProfileNotFoundTitle: '¡Error de inicio de sesion!',
    login: 'Inicio de sesión',
    password: 'Contraseña',
    passwordPlaceholder: 'Introducir la contraseña',
    tooManyAttemptsBody: 'Haga clic en el enlace del correo electrónico que se le envió para desbloquear su cuenta.',
    tooManyAttemptsTitle: 'Demasiados intentos, cuenta bloqueada!',
    unexpectedBody: 'Intente enviar su información nuevamente, si aún tiene problemas, contáctenos para obtener más ayuda.',
    unexpectedTitle: 'Algo salió mal!',
    username: 'Nombre de usuario',
    usernamePlaceholder: 'Introduzca su nombre de usuario',
    registerForAccount: 'Registrarse para una cuenta',
    newUser: '¿Nuevo usuario?',
    forgotUserNamePassword: '¿Ha olvidado su nombre de usuario o contraseña?',
    scheduledMaintenanceBodySection1: ` Durante este tiempo:`,
    scheduledMaintenanceBodySection2: ` si necesita completar una evidencia de solicitud de asegurabilidad, por favor regrese al portal el miércoles 16 de octubre.`,
    scheduledMaintenanceBodySection3: ` si necesita presentar una nueva reclamación o licencia, póngase en contacto con su línea de admisión telefónica para obtener ayuda.`,
    scheduledMaintenanceBodySection4: `Nos disculpamos de antemano por las molestias y agradecemos su paciencia. ¡Gracias por usar My Lincoln Portal!`,
    //
    // Error messages
    //
    error_usernameRequired: 'Se requiere nombre de usuario.',
    error_passwordRequired: 'Se requiere contraseña.',
    performanceDegradationBody: 'Tenga en cuenta que My Lincoln Portal está experimentando problemas de rendimiento intermitentes. Estamos trabajando activamente para resolver el problema lo más rápido posible.',
    notice: 'Aviso:',
    thirdPartyCookies: 'Este sitio web requiere cookies de terceros. Habilite las cookies de terceros en su navegador o póngase en contacto con su administrador para obtener asistencia. Si continúa teniendo problemas, llámenos.',
    wildFireMessageTitle: 'Incendios forestales de California:',
    wildFireMessageEmployeeBody: 'Nuestros pensamientos están con los afectados por los incendios forestales en California. Si tiene un reclamo relacionado por discapacidad, Lincoln Financial Group está aquí para ayudarlo. Haga clic en el enlace de Contactos a continuación.',
    wildFireMessageEmployerBody: 'Our thoughts are with those being impacted by wildfires in California. If you have a related disability claim, Lincoln Financial Group is here to help.',
    // Intermittent Issue Alert Message Title / Body
    intermittentIssueTitle: 'ATENCIÓN:',
    intermittentIssueBody: 'Tenga en cuenta que estamos experimentando problemas intermitentes con el registro de nuevos usuarios. Estamos trabajando activamente para resolver el problema lo más rápido posible.',
    // Portal down Issue Alert Message Title / Body
    portalDownTitle: 'Mantenimiento planificado del sistema',
    portalDownBody: 'El sitio web de Lincoln Financial Group se encuentra actualmente en mantenimiento y no está disponible temporalmente. Nos disculpamos por cualquier inconveniente.',
    usernameRequiredMessage: 'Se requiere nombre de usuario para iniciar sesión. Por favor, ingrese su nombre de usuario.',
    passwordRequiredMessage: 'Se requiere contraseña para iniciar sesión. Por favor, introduzca su contraseña.',
    skipMainContent: 'Saltar al contenido principal',
};
