import React from 'react';
import { Row, Col, TextInput, Label } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateTextArea } from '../../../utils/validationUtils';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import { useFormState } from 'informed';
const SurgeryComplication = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const surgeryComplicationText = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(formLabels.surgeryComplicationTextEE)
        : t(formLabels.surgeryComplicationTextER));
    const surgeryIllnessInjuryComplication = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.surgeryIllnessInjuryComplicationEE) : t(formLabels.surgeryIllnessInjuryComplicationER));
    return (<>
      <Row className='mb-3'>
        <Col xs={12}>
          <Label htmlFor='surgeryComplicationText' optional={true} className='mb-2' language={language}>
            {values?.absenceData?.leaveType === LeaveType.PREGNANT ? surgeryComplicationText : surgeryIllnessInjuryComplication}
          </Label>
          <TextInput id='surgeryComplicationText' data-private={true} data-testid='surgeryComplicationText' label='' ariaLabelText={values?.absenceData?.leaveType === LeaveType.PREGNANT ? surgeryComplicationText : surgeryIllnessInjuryComplication} field={'surgeryComplicationText'} validate={(props) => validateTextArea({ value: props, t, charLimit: 250, required: false })} validateOnBlur={true} validateOnChange={true} initialValue={absenceData && absenceData.surgeryComplicationText} textarea={true} style={{ height: '5rem', minHeight: '5rem', width: '100%' }} resize={'vertical'}/>
        </Col>
      </Row>
    </>);
};
export default SurgeryComplication;
