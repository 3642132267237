export var LoginAlertType;
(function (LoginAlertType) {
    LoginAlertType["ACCESS_DENIED"] = "ACCESS_DENIED";
    LoginAlertType["TOO_MANY_ATTEMPTS"] = "TOO_MANY_ATTEMPTS";
    LoginAlertType["EMAIL_VERIFIED"] = "EMAIL_VERIFIED";
    LoginAlertType["USER_PROFILE_NOT_FOUND"] = "USER_PROFILE_NOT_FOUND";
    LoginAlertType["EMAIL_VERIFICATION_EXPIRED"] = "EMAIL_VERIFICATION_EXPIRED";
    LoginAlertType["PASSWORD_VERIFICATION_EXPIRED"] = "PASSWORD_VERIFICATION_EXPIRED";
    LoginAlertType["ACCOUNT_UNBLOCKED"] = "ACCOUNT_UNBLOCKED";
    LoginAlertType["UNEXPECTED"] = "UNEXPECTED";
    LoginAlertType["CLEAR"] = "CLEAR";
})(LoginAlertType || (LoginAlertType = {}));
export const initialState = null;
export default function reducer(state, action) {
    switch (action.type) {
        case LoginAlertType.ACCESS_DENIED:
            return {
                alertTitle: 'accessDeniedTitle',
                alertBody: 'accessDeniedBody',
                type: 'warning',
            };
        case LoginAlertType.TOO_MANY_ATTEMPTS:
            return {
                alertTitle: 'tooManyAttemptsTitle',
                alertBody: 'tooManyAttemptsBody',
                type: 'error',
            };
        case LoginAlertType.EMAIL_VERIFIED:
            return {
                alertTitle: 'emailVerifiedTitle',
                alertBody: 'emailVerifiedBody',
                type: 'success',
            };
        case LoginAlertType.EMAIL_VERIFICATION_EXPIRED:
            return {
                alertTitle: 'emailExpiredTitle',
                alertBody: 'emailExpiredBody',
                type: 'error',
            };
        case LoginAlertType.PASSWORD_VERIFICATION_EXPIRED:
            return {
                alertTitle: 'passwordResetExpiredTitle',
                alertBody: 'passwordResetExpiredBody',
                type: 'error',
            };
        case LoginAlertType.ACCOUNT_UNBLOCKED:
            return {
                alertTitle: 'accountUnblockedTitle',
                alertBody: 'accountUnblockedBody',
                type: 'success',
            };
        case LoginAlertType.USER_PROFILE_NOT_FOUND:
            return {
                alertTitle: 'userProfileNotFoundTitle',
                alertBody: 'userProfileNotFoundBody',
                type: 'warning',
            };
        case LoginAlertType.CLEAR:
            return null;
        default:
            return {
                alertTitle: 'unexpectedTitle',
                alertBody: 'unexpectedBody',
                type: 'error',
            };
    }
}
