import React, { useContext } from 'react';
import { Row, Col, TextInput } from '@digitools/honest-react';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { formatPhone, validateCharityName, validateCharityContact } from '../../../utils/validationUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
const BdmModalContentCharityOrg = ({ beneficiaryCategory, beneficiaryIndex, beneficiary }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const isLci = useIsLci();
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    return (<>
      <Row data-private={true}>
        <Col>
          <TextInput field={`${idPrefix}charityName`} label={displayText.charityOrgName} type={'text'} validateOnBlur={true} validate={validateCharityName} initialValue={beneficiary ? beneficiary.charityName : undefined} data-private={true}/>
        </Col>
        <Col>
          <TextInput field={`${idPrefix}contactName`} label={displayText.contactName} type={'text'} validateOnBlur={true} validate={validateCharityContact} initialValue={beneficiary ? beneficiary.contactName : undefined} data-private={true}/>
        </Col>
        <Col />
      </Row>
      {isLci && (<Row>
          <Col>
            <TextInput field={`${idPrefix}phoneNumber`} label={displayText.phoneNumber} type={'text'} mask={formatPhone}/>
          </Col>
          <Col>
            <TextInput field={`${idPrefix}emailAddress`} label={'Email Address (optional)'} type={'text'}/>
          </Col>
        </Row>)}
    </>);
};
export default BdmModalContentCharityOrg;
