import { HeaderDivider } from '@digitools/honest-react';
import React from 'react';
import useCustomerConfig from 'src/hooks/useCustomerConfig';
import styled from 'styled-components';
const CustomerNameSpan = styled('span') `
@media(max-width: 1200px) {
  & {
      max-width: 260px;
  }
}
`;
const CustomerDisplay = () => {
    const { mlpConfigs } = useCustomerConfig();
    const displayName = mlpConfigs?.customerDisplayName ? mlpConfigs.customerDisplayName : mlpConfigs?.customerInternalName;
    return (<>
      {displayName && <>
          <HeaderDivider />
          <CustomerNameSpan id='customer-name' data-testid='customer-name' style={{ padding: '1rem' }}>{displayName}</CustomerNameSpan>
      </>}
    </>);
};
export default CustomerDisplay;
