import { Row, Col } from '@digitools/honest-react';
import React from 'react';
import { GraySpacerDiv, GrayTextDiv } from 'src/components/Formatting/StyledComponents';
import useTranslate from 'src/hooks/useTranslate';
import { RIA_TEXT } from '../../constants/RiaConstants';
import useRiaData from '../../hooks/useRiaData';
import LeaveCard from '../../components/LeaveCard';
import SubmittedAbsences from './SubmittedAbsences';
const ConfirmationLeaveCard = ({ leave }) => {
    const { t } = useTranslate();
    const { newAbsenceDateTimes, updatedAbsenceDateTimes, setNewAbsenceDateTimes, setUpdatedAbsenceDateTimes, currentLeave, } = useRiaData();
    return (<LeaveCard leave={currentLeave}>

            <GraySpacerDiv style={{ marginBottom: '0.5rem' }}/>
            {/* I feel like we need some kind of header or spacer here? /shrug */}
            <div role="region" aria-label="Updated absence details">
                <div className='d-none d-md-block'>
                    <Row>
                        <Col md={3} sm={6}>
                            <GrayTextDiv>{t(RIA_TEXT.absenceDate)}</GrayTextDiv>
                        </Col>
                        <Col md={3} sm={6}>
                            <GrayTextDiv>{t(RIA_TEXT.absenceDuration)}</GrayTextDiv>
                        </Col>
                        <Col lg={6} md={4} sm={6}>
                            <GrayTextDiv>{t(RIA_TEXT.absenceStatus)}</GrayTextDiv>
                        </Col>
                    </Row>
                </div>

                {/* TODO:may need to change this to just 1 list for both new and updated and have that sort by most recent date */}
                {/* Display New Submissions */}
                <SubmittedAbsences absences={newAbsenceDateTimes} setAbsenceDateTimes={setNewAbsenceDateTimes}/>
                {/* Display Updated submissions */}
                <SubmittedAbsences absences={updatedAbsenceDateTimes} setAbsenceDateTimes={setUpdatedAbsenceDateTimes}/>
            </div>

            <GraySpacerDiv style={{ marginTop: '0.5rem' }}/>
        </LeaveCard>);
};
export default ConfirmationLeaveCard;
