import React, { useState } from 'react';
import { isAfter, isBefore, parse } from 'date-fns';
import {
  Box,
  Button,
  Col,
  Collapse,
  DateSelect,
  InputMessage,
  Row,
  SelectInput,
  themes,
} from '@digitools/honest-react';
import { Form } from 'informed';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';

const NotesSearch = ({ initialData, setFilteredData, download }) => {
  const [formApi, setFormApi] = useState();
  const { trackEvent } = useTealium();
  if (!initialData) {
    return null;
  }

  const validateDateFilter = values => {
    if (values.fromDate && !values.throughDate) {
      formApi.setError('throughDate', 'Through date required');
    } else if (values.throughDate && !values.fromDate) {
      formApi.setError('fromDate', 'From date required');
    } else if (values.fromDate && values.throughDate) {
      const fromDate = parse(values.fromDate);
      const throughDate = parse(values.throughDate);
      if (isAfter(fromDate, throughDate)) {
        formApi.setError('fromDate', 'From Date must come before Through Date');
      }
    }
  };

  const noteTypes = () => {
    if (!initialData) {
      return [];
    } else {
      const types = new Set(initialData.map(note => note.noteType));
      return [...types].map(type => {
        return {
          label: type,
          value: type,
        };
      });
    }
  };

  const DownloadAllNotes = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button onClick={download} buttonType={'text'} style={{ paddingTop: 0, paddingRight: 0, marginLeft: 'auto' }}>
          Download Notes
        </Button>
      </div>
    );
  };

  const filterNotes = values => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
      event_type: TEALIUM_EVENT.EVENT_TYPE.NOTE_FILTER_APPLY,
      event_name: 'Note Filter Apply',
    });
    let filteredData = initialData;

    if (values && values.noteType) {
      filteredData.filter(data => data.noteType === values.noteType);
    }

    if (values && values.fromDate && values.throughDate) {
      const fromDate = parse(values.fromDate, 'P', new Date());
      const throughDate = parse(values.throughDate, 'P', new Date());
      filteredData = filteredData.filter(data => {
        const date = parse(data.dateCreated, 'P', new Date());
        return isBefore(fromDate, date) && isBefore(date, throughDate);
      });

      setFilteredData(filteredData);
    }
  };

  const resetFilteredData = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
      event_type: TEALIUM_EVENT.EVENT_TYPE.NOTE_FILTER_CLEAR,
      event_name: 'Note Filter Clear',
    });
    formApi.reset();
    setFilteredData(initialData);
  };

  return (
    <>
      <DownloadAllNotes />
      <Box style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <Collapse title={'Filter'} type={'alternate'} theme={themes.lincoln}>
          <Form onSubmit={filterNotes} getApi={setFormApi} validate={validateDateFilter}>
            <Row>
              <Col sm={12} md={6}>
                <DateSelect field={'fromDate'} ariaLabelText={'From Date'} label={'From Date'} />
              </Col>
              <Col sm={12} md={6}>
                <DateSelect field={'throughDate'} ariaLabelText={'Through Date'} label={'Through Date'} />
              </Col>
            </Row>
            {formApi && formApi.getState().error && (
              <InputMessage error={true}>{formApi && formApi.getState().error}</InputMessage>
            )}
            <Row>
              <Col md={4} sm={12}>
                <SelectInput field={'noteType'} label={'Note Type'} options={noteTypes()} />
              </Col>
              <Col md={8} sm={12}>
                <div style={{ justifyContent: 'right', display: 'flex' }}>
                  <Button
                    type={'button'}
                    buttonType={'secondary'}
                    onClick={resetFilteredData}
                    style={{ marginLeft: 'auto' }}>
                    Clear Filter
                  </Button>
                  <Button
                    type={'submit'}
                    buttonType={'primary'}
                    data-testid={'leave-details-filter-apply-button'}>
                    Apply
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Collapse>
      </Box>
    </>
  );
};
export default NotesSearch;
