import React from 'react';
import { formatTitleCase } from '../../../../../../utils/validationUtils';
import { CompositeTable, Button, Row, Col, Logo } from '@digitools/honest-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../../../hooks';
import ReactToPrint from 'react-to-print';
// @ts-ignore
import styles from './Payment.scss';
import EmployeeDetails from '../../../EmployeeDetails/EmployeeDetails';

class PrintablePaymentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.claimPayments = props.claimPayments;
    this.empNum = props.empNum;
    this.columns = props.columns;
    this.userName = formatTitleCase(props.userName);
  }

  render() {
    return (
      <div data-testid='PaymentInfoTablePrint' className={styles.PaymentInfoTablePrint + ' mr-5 ml-5'}>
        <Row className={'mr-0 ml-0 pt-5 pb-2 ' + styles.printInfo}>
          <Col>
            <div className='d-flex'>
              <img src={Logo} className={styles['lfg-logo']} />
            </div>
          </Col>
          <Col className={'col '} style={{ textAlign: 'right' }}>
            <div>Printed by: {this.userName}</div>
            <div>Date Printed: {moment().format('mm/dd/yyyy hh:mm:ss A')}</div>
          </Col>
        </Row>
        <Row className={'mr-0 ml-0'}>
          <Col>
            <EmployeeDetails empNum={this.empNum} />
          </Col>
        </Row>
        <Row className={'mr-0 ml-0'}>
          <Col>
            <CompositeTable data={this.claimPayments.claimCheck} columns={this.columns} />
          </Col>
        </Row>
      </div>
    );
  }
}

const PrintPaymentsButton = ({ empNum, payments }) => {
  const { t } = useTranslation();
  const { userMeta } = useAuth0();
  let paymentSummaryRef;

  const columns = [
    {
      Header: t('checkDateHeaderText'),
      accessor: 'checkDate',
      id: 'checkDate',
    },
    {
      Header: t('beginPaymentDateHeaderText'),
      accessor: 'fromDate',
      id: 'fromDate',
    },
    {
      Header: t('endPaymentDateHeaderText'),
      accessor: 'throughDate',
      id: 'throughDate',
    },
    {
      Header: t('checkStatusHeaderText'),
      accessor: 'statusCode',
      id: 'statusCode',
      Cell: ({ data }) => t(data._original.statusCode),
    },
    {
      Header: t('grossBenefitsHeaderText'),
      accessor: 'benefitGross',
      id: 'benefitGross',
    },
    {
      Header: t('netBenefitsHeaderText'),
      accessor: 'benefitNet',
      id: 'benefitNet',
    },
    {
      Header: t('checkNumberHeaderText'),
      accessor: 'checkNumber',
      id: 'checkNumber',
    },
    {
      Header: t('payeeHeaderText'),
      accessor: 'payeeName',
      id: 'payeeName',
    },
  ];
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'none' }}>
        <PrintablePaymentsTable
          claimPayments={payments}
          columns={columns}
          empNum={empNum}
          userName={userMeta.firstName + ' ' + userMeta.lastName}
          ref={el => {
            paymentSummaryRef = el;
          }}
        />
      </div>
      <ReactToPrint
        trigger={() => (
          <Button type='button' className={`${styles.printInfoButton}`} buttonType={'text'}>
            {t('printSummaryText')}
          </Button>
        )}
        content={() => paymentSummaryRef}
        copyStyles={true}
        removeAfterPrint={true}
      />
    </div>
  );
};

export default PrintPaymentsButton;
