import React from 'react';
import { getDisplayName } from '../utils/reactUtils';
import { UnexpectedErrorContext } from '../providers/UnexpectedErrorProvider';
function withUnexpectedError(WrappedComponent) {
    class WithUnexpectedError extends React.Component {
        render() {
            return (<UnexpectedErrorContext.Consumer>
          {contexts => <WrappedComponent {...this.props} unexpectedErrorContext={contexts}/>}
        </UnexpectedErrorContext.Consumer>);
        }
    }
    // @ts-ignore
    WithUnexpectedError.displayName = `WithUnexpectedError(${getDisplayName(WrappedComponent)})`;
    return WithUnexpectedError;
}
export default withUnexpectedError;
