import React from 'react';
import Confirmation from '../pages/confirmation/confirmation';
import Intake from '../pages/intake/intake';
import ViewSubmission from '../pages/viewSubmission/ViewSubmission';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
const AsRouter = () => {
    return (<>
      <Routes>
        <Route path={`*`} element={<Outlet />}>
          <Route path={`employee/viewSubmission`} element={<ViewSubmission />}/>
          <Route path={`employer/viewSubmission`} element={<ViewSubmission />}/>
          <Route path={`employee/confirmation`} element={<Confirmation />}/>
          <Route path={`employer/confirmation`} element={<Confirmation />}/>
          <Route path={`employee/intake`} element={<Intake />}/>
          <Route path={`employer/intake`} element={<Intake />}/>
          <Route path={`employee`} element={<Navigate to={`/as/employee/intake`} replace/>}/>
          <Route path={`employer`} element={<Navigate to={`/as/employer/intake`} replace/>}/>
        </Route>
      </Routes>
    </>);
};
export default AsRouter;
