import formatDateMMDDYYY from './formatDateMMDDYYY';

const getLeaveBalanceAsOfDate = leave => {
  const todayDate = new Date();

  let lveEndDate;

  if (leave.lveEndDte) {
    const strLveEndDate = leave.lveEndDte.toString().split('/');
    lveEndDate = new Date(strLveEndDate[2], strLveEndDate[0] - 1, strLveEndDate[1]);
  }

  if (
    leave.lveSttusCde &&
    leave.lveSttusCde === 'CL' &&
    leave.lveEndDte &&
    leave.lveEndDte !== '' &&
    lveEndDate < todayDate
  ) {
    return formatDateMMDDYYY(lveEndDate);
  } else {
    if (todayDate.getDay() === 1) {
      return formatDateMMDDYYY(new Date(todayDate.setDate(todayDate.getDate() - 3)));
    } else {
      return formatDateMMDDYYY(new Date(todayDate.setDate(todayDate.getDate() - 1)));
    }
  }
};

export default getLeaveBalanceAsOfDate;
