import React from 'react';
import { Col, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliDataContext from '../../../hooks/useCliDataContext';
import useTranslate from 'src/hooks/useTranslate';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
const WorkRelatedIndicator = (props) => {
    const { absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const workRelatedIndicatorLabel = t(experience === Experience.EMPLOYEE ? formLabels.workRelatedIndicatorEE : formLabels.workRelatedIndicatorER);
    return (<>
      <Row className='mb-3' data-testid='workRelatedIndicator'>
        <Col xs={12}>
          <RadioGroup label={workRelatedIndicatorLabel} field={'workRelatedIndicator'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.workRelatedIndicator}>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-yes'} key={'hi-yes'} name='workRelatedIndicator' label={t(AboutYourAbsenceText.formLabels.yes)} value={'Y'}/>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-no'} key={'hi-no'} name='workRelatedIndicator' label={'No'} value={'N'}/>
          </RadioGroup>
        </Col>
      </Row>
    </>);
};
export default WorkRelatedIndicator;
