import React from 'react';
import { ExperienceContext } from '../providers/ExperienceProvider';
import { getDisplayName } from '../utils/reactUtils';
function withExperience(WrappedComponent) {
    class WithExperience extends React.Component {
        render() {
            return (<ExperienceContext.Consumer>
          {contexts => <WrappedComponent {...this.props} experienceContext={contexts}/>}
        </ExperienceContext.Consumer>);
        }
    }
    // @ts-ignore
    WithExperience.displayName = `WithExperience(${getDisplayName(WrappedComponent)})`;
    return WithExperience;
}
export default withExperience;
