import { Col, Heading, Row, themes } from '@digitools/honest-react';
import * as _ from 'lodash';
import React from 'react';
import { reportFieldSectionLabels } from '../../select-field-report/ReportFieldPageBody';
import EditButton from './EditButton';
import NoFieldSelected from './NoFieldSelected';
{
    /* eslint-disable react/jsx-no-undef */
}
export const EmployeePersonalInformation = ({ fieldPageInfo: { personalInfoFields }, employeeTextCustomContent, history, }) => {
    return commonCollapseSection(personalInfoFields, `${employeeTextCustomContent}${reportFieldSectionLabels[0]}`, history);
};
export const EmployeeWorkInformation = ({ fieldPageInfo: { workInfoFields }, employeeTextCustomContent, history, }) => {
    return commonCollapseSection(workInfoFields, `${employeeTextCustomContent}${reportFieldSectionLabels[1]}`, history);
};
export const DateInformation = ({ fieldPageInfo: { claimDatesFields, leaveDatesFields, accommodationDatesFields }, reportFilterCategory, history, }) => {
    switch (reportFilterCategory) {
        case 'Claim':
            return commonCollapseSection(claimDatesFields, reportFieldSectionLabels[2], history);
        case 'Leave':
            return commonCollapseSection(leaveDatesFields, reportFieldSectionLabels[4], history);
        case 'Accommodation Services':
            return commonCollapseSection(accommodationDatesFields, reportFieldSectionLabels[6], history);
    }
    return <div />;
};
export const DetailInformation = ({ fieldPageInfo: { claimDetailFields, leaveDetailFields, accommodationDetailFields }, reportFilterCategory, history, }) => {
    switch (reportFilterCategory) {
        case 'Claim':
            return commonCollapseSection(claimDetailFields, reportFieldSectionLabels[3], history);
        case 'Leave':
            return commonCollapseSection(leaveDetailFields, reportFieldSectionLabels[5], history);
        case 'Accommodation Services':
            return commonCollapseSection(accommodationDetailFields, reportFieldSectionLabels[7], history);
    }
    return <div />;
};
export function commonCollapseSection(infoFields, reportFieldSectionLabel, history) {
    const checkedField = getCheckedInfoFields(infoFields);
    const subFieldsArray = _.chunk(checkedField, Math.ceil(checkedField ? checkedField.length / 4 : 3));
    return (<div style={{ marginTop: '1rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }} data-testid={'section-div'}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {reportFieldSectionLabel}
        </Heading>
        {/* @ts-ignore */}
        <Choose>
          {/* @ts-ignore */}
          <When condition={subFieldsArray.length}>
            <Row>
              {subFieldsArray.map((subFields, index) => (<Col lg={3} key={reportFieldSectionLabel + index}>
                  {subFields.map((subField) => (<Col key={reportFieldSectionLabel + subField.fieldLabel} style={{ marginTop: '.3rem' }}>
                      {subField.fieldLabel}
                    </Col>))}
                </Col>))}
            </Row>
            {/* @ts-ignore */}
          </When>
          {/* @ts-ignore */}
          <Otherwise>
            <NoFieldSelected />
            {/* @ts-ignore */}
          </Otherwise>
          {/* @ts-ignore */}
        </Choose>
        <EditButton history={history} path='/create-my-report/report-field'/>
      </div>
      <hr />
    </div>);
}
export const getCheckedInfoFields = (infoFields) => {
    return infoFields.filter((infoField) => infoField.status && infoField.fieldLabel !== 'All');
};
