import useGet from '../../../hooks/useGet';
import usePost from '../../../hooks/usePost';
import { Language } from '../../../types/Language';
import axios from 'axios';
import saveAs from 'file-saver';
/** TODO: This enpdoint should be renamed once above refactor is done - should become the CliCustomerConfig */
export const getCliCustomerConfigV2 = (experienceIdentifier) => {
    return useGet({
        url: `/cli-service/customerData/${experienceIdentifier}`,
        options: { headers: { Accept: 'application/hal+json' } },
    });
};
export const getCliSavedInterview = (rowId) => {
    return useGet({
        url: `/cli-service/saveddata/${rowId}`,
        options: { headers: { Accept: 'application/hal+json' } },
        noInitial: true,
    });
};
export const getNotificationsEnabled = (custDiv, custSerial) => {
    return useGet({ url: `/mlp-ui-service/services/v1/notificationsEnable?custDiv=${custDiv}&custSeral=${custSerial}` });
};
export const SaveInterviewData = async (cliInterviewData, authHeader) => {
    const stringifiedSavedData = JSON.stringify(cliInterviewData?.savedData);
    return await axios.post(`/cli-service/saveddata`, { ...cliInterviewData, savedData: stringifiedSavedData }, { headers: { Authorization: authHeader } });
};
export const DeleteInterviewData = async (rowId, authHeader) => {
    return await axios.post(`/cli-service/deletedata/${rowId}`, null, { headers: { Authorization: authHeader } });
};
export const UpdateInterviewData = async (cliInterviewData, authHeader) => {
    const stringifiedSavedData = JSON.stringify(cliInterviewData?.savedData);
    return await axios.post(`/cli-service/updatedata`, { ...cliInterviewData, savedData: stringifiedSavedData }, { headers: { Authorization: authHeader } });
};
export const SubmitInterviewData = async (cliInterviewData, authHeader) => {
    return await axios.post(`/cli-service/submit/v2`, cliInterviewData, { headers: { Authorization: authHeader } });
};
export const CheckForPrePropData = async (prePropData, authHeader) => {
    let response = {};
    try {
        const result = await axios.post(`/cli-service/prepop/v2`, prePropData, { headers: { Authorization: authHeader } });
        response = { data: result.data, code: result.status };
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            response = { data: null, code: e.response.status, message: e.response.statusText };
        }
    }
    return { response };
};
export const PrePopPost = (onFailure) => {
    return usePost({
        url: '/cli-service/prepop/v2',
        failure: onFailure,
    });
};
/* Calls Js-Pdf through cli-service and saves as the claim or leave number - includes pass through for error messaging on calling component */
export const downloadCliConfirmationPdf = async (authHeader, submission, setDownloadError, errorMessage) => {
    try {
        const claimPending = submission.language === Language.ENGLISH ? 'Pending' : 'Pendiente';
        const response = await axios.post(`/cli-service/confirmation`, submission, {
            responseType: 'blob',
            headers: { Authorization: authHeader },
        });
        const claimNumber = submission?.validationResults?.claimOrLeaveNumber && submission?.validationResults?.claimOrLeaveNumber !== 'pending' ? submission?.validationResults?.claimOrLeaveNumber : claimPending;
        const filename = `${claimNumber}.pdf`;
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, filename);
    }
    catch (error) {
        setDownloadError(errorMessage);
    }
};
export const validateAccountDetails = async (cliValidateAccount, authHeader) => {
    return await axios.post(`/cli-service/validatePaymentDetails`, cliValidateAccount, { headers: { Authorization: authHeader } });
};
