import { Button, Col, Form, Heading, Row, SelectInput, StepperContext } from '@digitools/honest-react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import useExperience from '../../../hooks/useExperience';
import { isNotEmpty } from '../../../utils/validationUtils';
import { LCI_EVENTS, LCI_WAIVER_PREMIUM_OPTIONS } from '../constants/LCIConstant';
import { getLabels } from '../utils/LCIUtils';
import CancelLci from './cancelLCI/CancelLci';
import useRouteCheck from '../../../hooks/useRouteCheck';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const StyledListItem = styled('li') `
  & + & {
    margin-top: 0.5rem;
  }
`;
const LCIGettingStarted = ({ onComplete, setEmployeeLabel }) => {
    const [showWaiver, setShowWaiver] = useState(false);
    const { setLciStarted } = useRouteCheck();
    const stepper = useContext(StepperContext);
    const handleEventSelection = (selectedEvent) => {
        setShowWaiver(selectedEvent === 'total disability');
    };
    const { trackEvent } = useTealium();
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    setEmployeeLabel(labelEmployee);
    const LCI_CLAIMAINT_OPTIONS = [
        { label: labelEmployee, value: 'employee' },
        { label: 'Dependent', value: 'dependent' },
    ];
    const waiverOptions = showWaiver ? (<SelectInput aria-label={'Is this a waiver of premium?'} field={'premiumWaiver'} label={'Is this a waiver of premium?'} placeholderText={'Select an option'} validateOnBlur={true} options={LCI_WAIVER_PREMIUM_OPTIONS} validate={isNotEmpty} validateOnChange={true}/>) : null;
    const claimantOptions = !showWaiver ? (<SelectInput aria-label={'Select an claimant option'} field={'claimant'} label={'Who did the event happen to?'} placeholderText={'Select an option'} validateOnBlur={true} options={LCI_CLAIMAINT_OPTIONS} validate={isNotEmpty} validateOnChange={true}/>) : null;
    const handleFormChange = (value) => {
        const started = !!value.event || !!value.claimant || !!value.premiumWaiver;
        if (started) {
            setLciStarted(true);
        }
    };
    const handleOnComplete = (value) => {
        //@ts-ignore
        onComplete(value);
        stepper.next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'getting started',
            event_status: TEALIUM_EVENT.EVENT_STATUS.START
        });
    };
    return (<div data-public={true}>
      <Heading elemType={'h2'} className='mt-3'>
        Getting Started
      </Heading>
      <p>
        Before you begin, make sure you have the following information about the person for whom you're reporting in
        hand:
      </p>
      <ul>
        <StyledListItem>Company Information</StyledListItem>
        <StyledListItem>Claimant / {labelEmployee} Information</StyledListItem>
      </ul>
      <Form onSubmit={handleOnComplete} onValueChange={handleFormChange}>
        <Row className={'mt-3'}>
          <Col>
            <SelectInput aria-label={'Select an event'} field={'event'} label={'What event are you reporting?'} placeholderText={'Select an event option'} validateOnBlur={true} options={LCI_EVENTS} validate={isNotEmpty} onValueChange={(value) => handleEventSelection(value)}/>
          </Col>
        </Row>
        <Row>
          <Col>
            {waiverOptions}
            {claimantOptions}
          </Col>
        </Row>
        <div className='mt-3 text-right'>
          <CancelLci step={'step 1'} pageTitle={'getting started'} sectionInfo={'lci getting started'} aria-label={'cancel'}/>
          <Button aria-label={'Continue'} buttonType='primary' role='button' type='submit'>
            Continue
          </Button>
        </div>
      </Form>
    </div>);
};
export default LCIGettingStarted;
