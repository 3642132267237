import { Form, Heading, Spinner, Stepper } from '@digitools/honest-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import useRiaData from 'src/packages/ria/hooks/useRiaData';
import ReportAbsence from 'src/packages/ria/pages/report/ReportAbsence';
import SelectLeave from 'src/packages/ria/pages/select/SelectLeave';
import EEFindMyInformation from '../employeeProfile/components/EEFindMyInformation';
import { RIA_TEXT } from './constants/RiaConstants';
import CorrespondenceAlert from './components/CorrespondenceAlert';
import useRiaConfig from './hooks/useRiaConfig';
import Notifications, { FunctionalArea } from 'src/components/Notifications/Notifications';
/**
 * Top level container for RIA functionality
 *
 * Renders FMI if not verified with S1, stepper if user has
 * multiple leaves, or just the absence reporting form for a single leave
 *
 * @returns ReportIntermittentAbsence app
 */
const ReportIntermittentAbsence = () => {
    const { user } = useAuthentication();
    const { leaves, loading } = useRiaData();
    const [isVerified, setIsVerified] = useState(!!(user && user.empNumS1));
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { correspondenceType } = useRiaConfig();
    const completeFMI = async (fmiSuccess) => {
        if (fmiSuccess) {
            // FMI doesnt trigger user object refresh, need to refresh browser to get it to update
            navigate(0);
            //setIsVerified(true) // we can uncomment this if we find a way to get auth to refresh properly
        }
    };
    const stepTitles = [t(RIA_TEXT.selectLeave), t(RIA_TEXT.reportAbsence)];
    return (<div data-public={true}>
      <Notifications data-testid='ria-notification-banner' functionalArea={FunctionalArea.RIA}/>
      <Heading elemType='h1' style={{ lineHeight: 'normal' }}>
        {t(RIA_TEXT.riaPageTitle)}
      </Heading>
      <br />
      {!isVerified && <EEFindMyInformation onComplete={completeFMI} noRedirect={true}/>}
      {isVerified && leaves && (<>
          {loading && <Spinner />}
          {/* TODO: need final messaging/story for no leaves available */}
          {!loading && leaves.length < 1 && t(RIA_TEXT.noLeavesError)}
          {!loading && correspondenceType === 'M' && <CorrespondenceAlert />}
          {!loading && leaves.length > 1 && (
            //@ts-ignore
            <Stepper stepTitles={stepTitles}>
              <Form>
                <SelectLeave />
              </Form>
              <ReportAbsence />
            </Stepper>)}
          {!loading && leaves.length === 1 && <ReportAbsence />}
        </>)}
    </div>);
};
export default ReportIntermittentAbsence;
