import React, { useEffect, useRef, useState } from 'react';
import { compareAsc, compareDesc, format, parse } from 'date-fns';
import useTealium from '../../../../../hooks/useTealium';
import styles from './NotesTable.scss';
import checkDate from 'status/utilities/checkDate';
import NotesSearch from './NotesSearch';
import DownloadNoteReportCell from './DownloadNoteReportCell';
import useReportDownload from './useReportDownload';
import { Alert, Button, CompositeTable, Icon, Spinner } from '@digitools/honest-react';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
const NoteDiv = styled('div') `
  background-color: white;
  width: 99%;
  margin-right: 1rem;
  margin-left: -0.8125rem;
  margin-bottom: 1rem;
  padding-left: 3rem;
`;
const ExpandCollapseCell = ({ data }) => {
    const { trackEvent } = useTealium();
    const noteRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.NOTE_TOGGLE_EXPAND,
            event_name: 'Note Toggle Expand',
        });
        setExpanded(!expanded);
    };
    const setNoteHeight = () => {
        setHeight(noteRef?.current?.clientHeight);
    };
    useEffect(() => {
        setHeight(noteRef?.current?.clientHeight);
        window.addEventListener('resize', setNoteHeight);
        return () => window.removeEventListener('resize', setNoteHeight);
    });
    return (<div className={styles.noteWrapper} style={{ paddingBottom: `calc(${height}px + 0.5rem)` }} data-expanded={expanded}>
      <Button onClick={toggleExpand} size={'small'} className={styles.icon}>
        {expanded ? <Icon name='subtract' color={'primary'}/> : <Icon name='add' color={'primary'}/>}
      </Button>
      {data._original.noteType}
      <NoteDiv data-private={true} ref={noteRef} className={styles.noteRow} data-expanded={expanded}>
        <p>{data._original.noteText}</p>
      </NoteDiv>
    </div>);
};
const NotesTable = ({ notes, error, loading, noteBasicDetails }) => {
    const [filteredData, setFilteredData] = useState([]);
    const { sendRequest } = useReportDownload();
    if (loading) {
        return <Spinner id='notes-table-spinner'/>;
    }
    if (error) {
        return <Alert type={'error'}>There was a problem retrieving notes.</Alert>;
    }
    if (!notes || notes.length === 0) {
        return <Alert type={'info'}>No notes found for this employee.</Alert>;
    }
    const formatDataForDisplay = (note) => {
        return {
            noteText: note.noteTxt,
            noteType: note.noteType,
            topic: note.noteTopic,
            subTopic: note.noteSubTopic,
            createDate: checkDate(format(parse(note.dateCreated, 'yyyy-MM-dd', new Date()), 'P')),
            lastModified: checkDate(format(parse(note.lastModified, 'yyyy-MM-dd', new Date()), 'P')),
        };
    };
    let formattedData;
    if (filteredData.length !== 0) {
        formattedData = filteredData.map(data => formatDataForDisplay(data));
    }
    else {
        formattedData = notes.map(note => formatDataForDisplay(note));
    }
    const columns = [
        {
            Cell: ExpandCollapseCell,
            Header: 'Note Type',
            id: 'expandCollapse',
            accessor: 'noteType',
        },
        {
            Header: 'Topic',
            accessor: 'topic',
            id: 'topic',
        },
        {
            Header: 'Sub Topic',
            accessor: 'subTopic',
            id: 'subTopic',
        },
        {
            Header: 'Date Created',
            accessor: 'createDate',
            id: 'createDate',
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Header: 'Last Modified',
            accessor: 'lastModified',
            id: 'lastModified',
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Cell: DownloadNoteReportCell(noteBasicDetails),
            Header: '',
            id: 'download',
        },
    ];
    const defaultSorted = [
        {
            id: 'createDate',
            desc: true,
        },
    ];
    const downloadAllNotes = async () => {
        formattedData.sort((a, b) => compareDesc(parse(a.createDate, 'P', new Date()), parse(b.createDate, 'P', new Date())));
        const payload = { ...noteBasicDetails, notesResourceList: formattedData };
        const url = '/status-service/report/notes/download';
        await sendRequest(url, payload);
    };
    return (<div>
      <NotesSearch initialData={notes} download={downloadAllNotes} setFilteredData={setFilteredData}/>
      {formattedData.length !== 0 && (<CompositeTable data-testid='notes-table' data={formattedData} columns={columns} defaultSorted={defaultSorted} paginated={formattedData.length > 10}/>)}
      {formattedData.length === 0 && <Alert type={'info'}>No notes found.</Alert>}
    </div>);
};
export default NotesTable;
