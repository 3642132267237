import { TabSection, Collapse, themes, Icon, Col, Row } from '@digitools/honest-react';
import React, { useState } from 'react';
import { EMPLOYER_FAQ } from '../constants/EmployerFAQ';
import { LearnMoreText } from '../constants/LearnMoreText';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { StyledLCNText } from 'src/components/styledComponents';
const EmployerTab = () => {
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const [expandedState, setExpandedState] = useState(Array(EMPLOYER_FAQ.length).fill(false));
    const handleOnBlur = (index, questionString) => {
        const tempArray = expandedState;
        if (expandedState[index] === false) {
            trackLearnMoreEvent(questionString);
            tempArray[index] = true;
            setExpandedState(tempArray);
        }
        else if (expandedState[index]) {
            tempArray[index] = false;
            setExpandedState(tempArray);
        }
    };
    const trackLearnMoreEvent = (eventName) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: TEALIUM_EVENT.EVENT_VERSION.FAQ,
            event_status: TEALIUM_EVENT.EVENT_STATUS.EXPAND
        });
    };
    const ExpandedIcon = () => (<Icon name="subtract" size="xs" className="mr-2 mt-1" color='primary'/>);
    const CollapsedIcon = () => (<Icon name="add" size="small" className="mr-2 mt-1" color='primary'/>);
    return (<>
            <TabSection name={t(LearnMoreText.employerQuestions)} key={1}>
                {EMPLOYER_FAQ.map((questions, index) => {
            return (<Collapse isOpen={false} titleComponent={() => t(questions.label)} theme={themes.lincoln} type='box' textColor='black' collapsedIcon={CollapsedIcon} expandedIcon={ExpandedIcon} color='white' onBlur={() => handleOnBlur(index, "ee question, " + questions.label.english)} headerElemType='h2'>
                        {t(questions.value)}
                        </Collapse>);
        })}
                <Row>
                    <Col>
                        <StyledLCNText>
                            {t(LearnMoreText.LCNEmployer)}
                        </StyledLCNText>
                    </Col>
                </Row>
            </TabSection>
        </>);
};
export default EmployerTab;
