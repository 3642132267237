import React from 'react';
import styled from 'styled-components';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import Collapse from '@digitools/honest-react/dist/lib/components/Collapse';
import { RedBulletList } from '../../../components/Formatting/StyledComponents';
import useEoiContext from '../hooks/useEoiContext';
// @ts-ignore
import QuickTipsImg from '../../../assets/quick_tips.svg';
import useTranslate from '../../../hooks/useTranslate';
import QuickTipsText from '../i18n/QuickTipsText';
const BoxContainer = styled('div') `
  @media (max-width: 1024px) {
    display: block;
  }
  display: flex;
  min-height: 8.125rem;
`;
const InlineColorHeaderBox = styled(Box) `
  @media (max-width: 1024px) {
    width: 100%;
    flex: 1;
  }
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  /* align-items: center; */
  padding: 1.5rem;
  min-width: 100px; 
  width: 8.125rem;
`;
const InlineContentBox = styled(Box) `
  @media (min-width: 1025px) {
    borderleft: none;
  }
  display: flex;
  flex: 1;
  margin-top: 0px;
`;
const QuickTipsBox = () => {
    const { t } = useTranslate();
    const { customerConfig } = useEoiContext();
    return (<BoxContainer data-testid='eoi-quick-tips' style={{ marginTop: '1.875rem' }} data-public={true}>
      <InlineColorHeaderBox type='color' theme={themes.lincoln} data-public={true}>
        <QuickTipsImg width='60px' height='66.625px' alt='Document icon with question mark' data-public={true}/>
      </InlineColorHeaderBox>
      <InlineContentBox data-public={true}>
        <div style={{ display: 'block' }}>
          <Heading elemType='h3' theme={themes.lincoln}>{t(QuickTipsText.header)}</Heading>
          <Collapse type='alternate' title={t(QuickTipsText.collapseHeader)} theme={themes.lincoln}>
            <div style={{ marginLeft: '1rem' }}>
              <Heading elemType='h3' elemStyle='h4'>{t(QuickTipsText.applicantCoverageHeader)}</Heading>
              <RedBulletList>
                <li><span>{t(QuickTipsText.name)}</span></li>
                <li><span>{t(QuickTipsText.dob)}</span></li>
                <li><span>{t(QuickTipsText.social)}</span></li>
                <li><span>{t(QuickTipsText.height)}</span></li>
                <li><span>{t(QuickTipsText.weight)}</span></li>
                <li><span>{t(QuickTipsText.diagnosis)}</span></li>
              </RedBulletList>
              <Heading elemType='h3' elemStyle='h4'>{t(QuickTipsText.quickTipsHeader)}</Heading>
              <RedBulletList>
                <li><span>{t(QuickTipsText.answerAll)}</span></li>
                {customerConfig && customerConfig.saveForLater && <li><span>{t(QuickTipsText.saveForLater)}</span></li>}
                <li><span>{t(QuickTipsText.timeOut)}</span></li>
              </RedBulletList>
              <br />
            </div>
          </Collapse>
        </div>
      </InlineContentBox>
    </BoxContainer>);
};
export default QuickTipsBox;
