import { Row, Col, StepperContext, Collapse, themes } from '@digitools/honest-react';
import React, { useContext } from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField, { EditReviewField } from './ReviewField';
import AboutYourAbsenceText from '../../constants/AboutYourAbsenceText';
const ReviewMedicalContact = () => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { contactData } = useCliDataContext();
    const { formLabels, medicalContactSubHeadingEE } = AboutYourAbsenceText;
    return (<>
      <div data-testid='medicalContactId'>
        <div className={`mb-3`}>
          <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonMedicalTestId" onClick={() => { stepper.edit(3); }}/>} isOpen={true} title={t(medicalContactSubHeadingEE)} theme={themes.lincoln} type='alternate'>
            <Row>
              <Col md={4} data-testid='medicalContactFirstNameId'>
                <ReviewField label={t(formLabels.medicalContact.physicianFirstName)} value={contactData && contactData.physicianFirstName}/>
              </Col>
              <Col md={4} data-testid='medicalContactLastNameId'>
                <ReviewField label={t(formLabels.medicalContact.physicianLastName)} value={contactData && contactData.physicianLastName}/>
              </Col>
              <Col md={4} data-testid='medicalContactPhoneId'>
                <ReviewField label={t(formLabels.medicalContact.physicianPhoneNumber)} value={contactData && contactData.physicianPhoneNumber}/>
              </Col>
            </Row>
          </Collapse>
        </div>
      </div>
    </>);
};
export default ReviewMedicalContact;
