import React from 'react';
import useExperience from '../../hooks/useExperience';
import useTranslate from '../../hooks/useTranslate';
import { Experience } from '../../types/Experience';
import ErrorPage from './ErrorPage';
const errorMessage = {
    english: 'You are not authorized to view this page.',
    spanish: 'No tiene autorización para ver esta página.',
};
const homeLinkTitle = {
    english: 'Go to homepage',
    spanish: 'Ir a la página de inicio.',
};
const UnauthorizedErrorPage = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const homeLink = experience === Experience.EMPLOYEE ? '/employee/home' : '/employer/home';
    return (<ErrorPage data-testid="unauth-error-page" message={t(errorMessage)} link={homeLink} linkTitle={t(homeLinkTitle)} theme={''}/>);
};
export default UnauthorizedErrorPage;
