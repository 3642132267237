import React, { useContext } from 'react';
import { StepperContext, Row, Collapse, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useCliDataContext from '../../hooks/useCliDataContext';
import AboutYourAbsenceText from '../../constants/AboutYourAbsenceText';
import { LeaveType } from '../../providers/CliConfigProvider';
import ReviewFamilyParentalFlow from './ReviewAbsenceFlow/ReviewFamilyParentalFlow';
import ReviewPregnancyFlow from './ReviewAbsenceFlow/ReviewPregnancyFlow';
import ReviewIllnessInjuryFlow from './ReviewAbsenceFlow/ReviewIllnessInjuryFlow';
import ReviewCommonFlow from './ReviewAbsenceFlow/ReviewCommonFlow';
import ReviewOtherFlow from './ReviewAbsenceFlow/ReviewOtherFlow';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { EditReviewField } from './ReviewField';
import ReviewMilitaryFlow from './ReviewAbsenceFlow/ReviewMilitaryFlow';
const ReviewAboutAbsence = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { pageTitleEE, pageTitleER } = AboutYourAbsenceText;
    const { experience } = useExperience();
    const absencePageTitle = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(pageTitleEE) : t(pageTitleER));
    const shouldDisplayFamilyParentalFlow = (absenceData?.leaveType === LeaveType.FAMILY_CARE || absenceData?.leaveType === LeaveType.PARENTAL) && absenceData?.leaveReason;
    const shouldDisplayPregnancyFlow = (absenceData?.leaveType === LeaveType.PREGNANT || absenceData?.claimantCondition === 'P');
    const shouldDisplayIllnessInjuryFlow = (absenceData?.leaveType === LeaveType.OWN_ILLNESS);
    const shouldDisplayOtherFlow = (absenceData?.leaveType === LeaveType.OTHER);
    const shouldDisplayMilitaryFlow = (absenceData?.leaveType === LeaveType.MILITARY);
    return (<div data-testid='AboutAbsenceReview'>
      <div className={`mb-3`}>
        <Collapse headerRightDisplay={<EditReviewField data-testid="editAboutAbsence" onClick={() => { stepper.edit(2); }}/>} isOpen={true} title={absencePageTitle} theme={themes.lincoln} type='alternate'>
          <Row>
            <ReviewCommonFlow data-testid='reviewCommonFlow'/>
            {shouldDisplayPregnancyFlow && <ReviewPregnancyFlow data-testid='reviewPregnancyFlow'/>}
            {shouldDisplayFamilyParentalFlow && <ReviewFamilyParentalFlow data-testid='reviewFamilyParentalFlow'/>}
            {shouldDisplayIllnessInjuryFlow && <ReviewIllnessInjuryFlow data-testid='reviewIllnessInjuryFlow'/>}
            {shouldDisplayOtherFlow && <ReviewOtherFlow data-testid='reviewOtherFlow'/>}
            {shouldDisplayMilitaryFlow && <ReviewMilitaryFlow data-testid='reviewMilitaryFlow'/>}
          </Row>
        </Collapse>
      </div>
    </div>);
};
export default ReviewAboutAbsence;
/*


// common
  

    surgeryIndicator?: string; // multiple flows
    surgeryDate?: string; // multiple flows
    surgeryDescription?: string;
    surgeryComplicationText?: string; //C&PREG COMMON

    // pregnancy
    deliveryIndicator?: string;
    deliveryDate?: string;
    lastDayWorked?: string; //C&Preg Common
    deliveryComplicationIndicator?: string;
    admissionDate?: string; // C&PREG COMMON
    releaseDate?: string;

    // medical contact info
    physicianFirstName?: string;
    physicianLastName?: string;
    physicianPhoneNumber?: string;

    //family
    relationship?: string;
    otherRelationship?: string;
    placementDate?: string
    careRecipientDateOfBirth?: string;
*/
