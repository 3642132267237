export const PreferredContactInformationText = {
    heading: {
        english: 'Personal contact information',
        spanish: 'Informacíon contacta personal',
    },
    headingER: {
        english: 'Employee\'s contact information',
        spanish: 'Información de contacto del Empleado',
    },
    phone: {
        english: 'Phone',
        spanish: 'Teléfono',
    },
    phoneOptional: {
        english: 'Phone (optional)',
        spanish: 'Teléfono (opcional)',
    },
    email: {
        english: 'Email',
        spanish: 'Email',
    },
    emailOptional: {
        english: 'Email (optional)',
        spanish: 'Email (opcional)',
    },
    fax: {
        english: 'Fax',
        spanish: 'Fax',
    },
    preferredContact: {
        english: 'Contact preference',
        spanish: 'Preferencia de Contacto',
    },
    preferredContactLabel: {
        english: 'Preferred method for non-confidential correspondence',
        spanish: 'Método preferido para la correspondencia no confidencial',
    },
    phoneSelect: {
        english: 'Phone',
        spanish: 'Teléfono'
    }
};
