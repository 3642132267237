import React, { useContext, useRef } from 'react';
import { Heading, Row, Col, TextInput, StepperContext } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
// @ts-ignore
import { Form, Scope } from 'informed';
import { phoneNumberMask } from '../../../eoi/utils/InputMasks';
import useCliDataContext from '../../hooks/useCliDataContext';
import useValidPhone from '../../../../hooks/fieldValidation/useValidPhone';
import { AboutYourAbsenceText } from '../../constants/AboutYourAbsenceText';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import RouterPrompt from '../../components/RouterPrompt';
import GettingStartedText from '../start/GettingStartedText';
import useOnScreen from 'src/hooks/visibilityChecker';
import { validateCliNameOptional } from '../../utils/validationUtils';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
const MedicalContacts = () => {
    const { t } = useTranslate();
    const validatePhoneOptional = useValidPhone();
    const { formLabels } = AboutYourAbsenceText;
    const { medicalContactSubHeadingEE } = AboutYourAbsenceText;
    const { trackEvent } = useTealium();
    const { experience } = useExperience();
    const cliConfigContext = useCliCustomerConfigContext();
    const { contactData, setContactData, saveAndUpdateInterview } = useCliDataContext();
    const stepper = useContext(StepperContext);
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const submit = (values) => {
        //@ts-ignore - typing doesn't know about the scopes
        const tempObj = {};
        tempObj.physicianFirstName = values?.contactData?.physicianFirstName;
        tempObj.physicianLastName = values?.contactData?.physicianLastName;
        tempObj.physicianPhoneNumber = values?.contactData?.physicianPhoneNumber?.replace(/[^0-9.]/g, '');
        setContactData(tempObj);
        saveAndUpdateInterview(values);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: experience === Experience.EMPLOYEE && cliConfigContext && cliConfigContext?.hasSaveForLater ? 'save and continue' : 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_version: t(AboutYourAbsenceText.contactTitle),
            event_status: 'step 4',
        });
        stepper.next();
        window.scrollTo(0, 0);
    };
    return (<div ref={ref} data-testid='medicalContacts'>
      {stepper.currentStep !== t(GettingStartedText.pageTitle) && isVisible && <RouterPrompt />}
      <Form onSubmit={submit}>
         {/*  @ts-ignore */}
        <Scope scope='contactData'>
        <Heading elemType='h2' color='primary'>
            {t(AboutYourAbsenceText.contactTitle)}
          </Heading>
          <Row>
            <Col md={12}>
              <BorderLessGrayBox>
                <Heading elemType='h3' elemStyle='h4'>{t(medicalContactSubHeadingEE)}</Heading>
              </BorderLessGrayBox>
            </Col>
            <Col md={12}>
              <p className={`my-3`}>{t(AboutYourAbsenceText.medicalContactIntro)}</p>
            </Col>
            <Col md={4}>
              <TextInput data-testid={'physicianFirstNameInput'} label={t(formLabels.medicalContact.physicianFirstName)} aria-label={t(formLabels.medicalContact.physicianFirstName)} field={'physicianFirstName'} validateOnBlur={true} validateOnChange={true} validate={validateCliNameOptional} initialValue={contactData && contactData.physicianFirstName}/>
            </Col>
            <Col md={4}>
              <TextInput data-testid={'physicianLastNameInput'} label={t(formLabels.medicalContact.physicianLastName)} aria-label={t(formLabels.medicalContact.physicianLastName)} field={'physicianLastName'} validateOnBlur={true} validateOnChange={true} validate={validateCliNameOptional} initialValue={contactData && contactData.physicianLastName}/>
            </Col>
            <Col md={4}>
              <TextInput data-testid={'physicianPhoneNumberInput'} label={t(formLabels.medicalContact.physicianPhoneNumber)} aria-label={t(formLabels.medicalContact.physicianPhoneNumber)} field={'physicianPhoneNumber'} validateOnBlur={true} validateOnChange={true} mask={phoneNumberMask} validate={validatePhoneOptional} initialValue={contactData && contactData.physicianPhoneNumber}/>
            </Col>
          </Row>
        </Scope>
        <StepperButtons suppressNext analyticsPageName='medical contacts'/>
      </Form>
    </div>);
};
export default MedicalContacts;
