import { FormButton } from '../../../components/extraFormControls/FormButton';
import React, { useState, useContext } from 'react';
import { Row, Col, TextInput, SelectInput, Modal, Button, Icon, Form } from '@digitools/honest-react';
import styled from 'styled-components';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { BDM_BENEFICIARY_TYPES, BDM_BENEFICIARY_TYPES_ES, } from '../constants/BdmConstants';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import BdmModalContent from './BdmModalContent';
import { Language } from '../../../types/Language';
import { PRIMARY } from '../constants/BdmConstants';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { numbersOnly } from '../../../utils/validationUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
import { uniqueId } from 'lodash';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const StyledTextInput = styled(TextInput) `
  width: 5.75rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-weight: bold;
  color: #002663;
  background-color: #e2edf6;
  padding: 0;

  ::placeholder {
    color: #002663;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #002663;
  }
`;
const StyledCategory = styled(TextInput) `
  ::placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
`;
const StyledRow = styled(Row) `
  display: flex;
  align-items: flex-end;
  margin-top: 0.5rem
`;
const BdmModalContainer = ({ beneficiaries, updateBeneficiaries, beneficiaryCategory, toggleButtonState, beneficiariesCount, }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const [beneficiaryType, setBeneficiaryType] = useState('');
    const [formFilled, setFormFilled] = useState(false);
    const [formApi, setFormApi] = useState();
    const { trackEvent } = useTealium();
    const beneficiariesMaxNumber = 15;
    const handleChange = (value) => {
        setBeneficiaryType(value);
    };
    const RenderOpenModalButton = ({ showModal }) => {
        const handleClick = () => {
            showModal();
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: isLci ? TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE : TEALIUM_EVENT.EVENT_TYPE.ADD_BENEFICIARY,
                event_name: 'Add Beneficiary',
            });
        };
        return (<Button id={`btnOpenModal${beneficiaryCategory}`} type='button' onClick={handleClick} data-testid='open-btn' className={'mb-3'} buttonType={'secondary'} size={'small'} disabled={beneficiariesCount >= beneficiariesMaxNumber}>
        <Icon className={'mr-1'} style={{ verticalAlign: 'bottom' }} color={beneficiariesCount >= beneficiariesMaxNumber ? 'grayLight' : 'secondary'} name={'add-something'}/>
        {displayText.addBeneficiary}
      </Button>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleClick = () => {
            hideModal();
        };
        return (<FormButton idName={beneficiaryCategory} onClick={handleClick} hasChanged={formFilled} displayText={displayText.addBeneficiary}/>);
    };
    const FooterSecondaryRenderButton = ({ hideModal }) => {
        const handleClick = () => {
            hideModal();
            if (formApi) {
                formApi.reset();
            }
        };
        return (<Button type='button' onClick={handleClick} buttonType={'text'}>
        {displayText.cancelButton}
      </Button>);
    };
    const handleSubmit = (values) => {
        const formToDatabase = BdmBeneficiaryUtils.formToBeneficiary(values, beneficiaryCategory);
        formToDatabase.id = uniqueId(beneficiaryCategory);
        formToDatabase.beneficiaryCategory = beneficiaryCategory;
        formToDatabase.allocation = formToDatabase.allocation ? formToDatabase.allocation : '0';
        beneficiaries.push({ ...formToDatabase });
        updateBeneficiaries(beneficiaryCategory, beneficiaries);
        if (formApi) {
            formApi.reset();
        }
    };
    const isLci = useIsLci();
    const formCheck = (values) => {
        BdmBeneficiaryUtils.checkForFormCompletion(values, toggleButtonState, beneficiaryType, beneficiaryCategory, setFormFilled, isLci);
    };
    const getCategoryTitle = () => {
        if (beneficiaryCategory === PRIMARY) {
            return displayText.beneficiaryCategoryPrimary;
        }
        else {
            return displayText.beneficiaryCategoryContingent;
        }
    };
    return (<Form id={beneficiaryCategory + '-form'} onValueChange={formCheck} onSubmit={handleSubmit} getApi={setFormApi}>
      <Modal modalOpenRenderButton={RenderOpenModalButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} footerSecondaryRenderButton={FooterSecondaryRenderButton} headerText={displayText.modalHeader} contentMaxHeight={'31.25rem'} modalContainerStyle={{ maxWidth: '80vw', width: '80vw', minWidth: '18.75rem' }} disabledBackdropClick={true}>
        <StyledRow>
          <Col>
            <StyledCategory field={`${beneficiaryCategory}beneficiaryCategory`} placeholder={getCategoryTitle()} label={displayText.beneficiaryCategory} type={'text'} readOnly={true} initialValue={getCategoryTitle()}/>
          </Col>
          <Col>
            <SelectInput field={`${beneficiaryCategory}beneficiaryType`} data-testid='beneficiary-type-select' label={displayText.beneficiaryType} aria-label={displayText.beneficiaryType} placeholderText={displayText.beneficiaryTypePlaceholder} validateOnBlur={true} options={experienceContext.language === Language.SPANISH ? BDM_BENEFICIARY_TYPES_ES : BDM_BENEFICIARY_TYPES} onValueChange={handleChange}/>
          </Col>
          {beneficiaryType ? (!toggleButtonState ? (<Col>
                <TextInput style={{ width: '3.75rem', minWidth: '2.5rem' }} field={`${beneficiaryCategory}allocation`} label={displayText.allocation} mask={numbersOnly} maxLength={3} validate={BdmBeneficiaryUtils.validateAllocationInModal} validateOnBlur={true} type={'text'}/>
              </Col>) : (<Col>
                <StyledTextInput field={`${beneficiaryCategory}equalAllocation`} label={displayText.allocation} type={'text'} readOnly={true} placeholder={displayText.equal}/>
              </Col>)) : (<Col />)}
        </StyledRow>
        <BdmModalContent beneficiaryType={beneficiaryType} beneficiaryCategory={beneficiaryCategory}/>
      </Modal>
    </Form>);
};
export default BdmModalContainer;
