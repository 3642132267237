import { Form } from 'informed';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Modal from '@digitools/honest-react/dist/lib/components/Modal';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import React, { useEffect, useState } from 'react';
import CommPrefDisplayText from './language/CommPrefDisplayText';
import { getPreferences, savePreferences } from '../../../../api/CommPrefApi';
import CommunicationPreferencesBox from './CommunicationPreferencesBox';
import styled from '@digitools/honest-react/dist/lib/theming/styled-components';
import useAuthentication from '../../../../hooks/useAuthentication';
import useTranslate from '../../../../hooks/useTranslate';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useTealium from '../../../../hooks/useTealium';
const CommPref = () => {
    const { authHeader } = useAuthentication();
    const { t } = useTranslate();
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resentVerification, setResentVerification] = useState(false);
    const [communicationPreferencesData, setCommunicationPreferencesData] = useState(undefined);
    const { trackEvent } = useTealium();
    const fetchData = async () => {
        try {
            const commPreferences = await getPreferences(authHeader());
            setCommunicationPreferencesData(commPreferences);
        }
        catch (error) {
            setIsError(true);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const ModalButton = ({ showModal }) => {
        return (<Button type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={() => showModal()} style={{ height: 'auto', margin: '0 0 0 0.5rem', padding: 0 }} data-testid={'comm-pref-modal-button'}>
        {t(CommPrefDisplayText.howItWorks)}
      </Button>);
    };
    const SaveButton = styled(Button) `
    width: 25%;
    @media (max-width: 500px) {
      width: 100%;
    }
  `;
    const SuccessAlert = () => {
        return (<Alert type={'success'} closeable={true} onClose={() => setIsSuccessful(false)} data-testid={'comm-pref-success'}>
        {t(CommPrefDisplayText.success)}
      </Alert>);
    };
    const ResentVerificationAlert = () => {
        return (<Alert type={'success'} closeable={true} onClose={() => setResentVerification(false)} data-testid={'comm-pref-resent'}>
        {t(CommPrefDisplayText.verificationResent)}
      </Alert>);
    };
    const ErrorAlert = () => {
        return (<Alert type={'error'} closeable={true} onClose={() => setIsError(false)} data-testid={'comm-pref-error'}>
        {t(CommPrefDisplayText.error)}
      </Alert>);
    };
    const onSubmit = async (values) => {
        let notifications;
        let confirmations;
        if (values && communicationPreferencesData) {
            if (values.notificationsToggle) {
                notifications = {
                    method: 'TXT',
                    phoneNumber: values.notificationsPhoneNumber ? values.notificationsPhoneNumber.replace(/-/g, '') : communicationPreferencesData.notification.phoneNumber,
                    timeZone: values.notificationsTimezone ? values.notificationsTimezone : communicationPreferencesData.notification.timeZone,
                };
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_ALERTS_NOTIFICATIONS,
                    event_name: 'claim status on',
                });
            }
            if (values.confirmationsToggle) {
                confirmations = {
                    method: values.deliveryMethod ? (values.deliveryMethod === 'textNotification' ? 'TXT' : 'IVR') : communicationPreferencesData.confirmation.method,
                    phoneNumber: values.confirmationsPhoneNumber ? values.confirmationsPhoneNumber.replace(/-/g, '') : communicationPreferencesData.confirmation.phoneNumber,
                    timeZone: values.confirmationsTimezone ? values.confirmationsTimezone : communicationPreferencesData.confirmation.timeZone,
                };
                const type = values.deliveryMethod === 'textNotification' ? 'text' : 'phone';
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_ALERTS_NOTIFICATIONS,
                    event_name: `return to work ${type} on`,
                });
            }
        }
        if (!notifications && communicationPreferencesData && communicationPreferencesData.notification) {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_ALERTS_NOTIFICATIONS,
                event_name: 'claim status off',
            });
        }
        if (!confirmations && communicationPreferencesData && communicationPreferencesData.confirmation) {
            const type = communicationPreferencesData.confirmation.method === 'IVR' ? 'phone' : 'text';
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.SUBMIT_ALERTS_NOTIFICATIONS,
                event_name: `return to work ${type} off`,
            });
        }
        const payload = {
            confirmation: confirmations,
            notification: notifications,
        };
        try {
            await savePreferences(payload, authHeader());
            await fetchData();
            setIsSuccessful(true);
        }
        catch (error) {
            setIsError(true);
        }
    };
    return (<>
      <Heading theme={themes.lincoln} elemType={'h2'} color={'primary'} data-testid={'comm-pref-header'}>
        {t(CommPrefDisplayText.communicationPreferences)}
      </Heading>
      {isSuccessful && <SuccessAlert />}
      {resentVerification && <ResentVerificationAlert />}
      {isError && <ErrorAlert />}

      {communicationPreferencesData &&
            <>
          <div style={{ display: 'flex', flexDirection: 'row' }} data-testid={'comm-pref-content'} data-public={true}>
            <Heading elemType={'h3'} elemStyle={'h4'} color={'black'} theme={themes.lincoln} data-testid={'notification-settings'} data-public={true}>{t(CommPrefDisplayText.notificationSettings)}</Heading>
            <Modal modalOpenRenderButton={ModalButton} hasCloseIcon={true} theme={themes.lincoln} headerText={t(CommPrefDisplayText.howItWorks)}>
              {t(CommPrefDisplayText.modalGeneralText)}
              <Heading elemType={'h3'} color={'black'} theme={themes.lincoln} data-testid={'modal-confirmations-header'}>{t(CommPrefDisplayText.confirmations)}</Heading>
              <span data-testid={'modal-confirmations-text'} data-public={true}>{t(CommPrefDisplayText.modalConfirmationsText)}</span>
              <Heading elemType={'h3'} color={'black'} theme={themes.lincoln} data-testid={'modal-notifications-header'}>{t(CommPrefDisplayText.notifications)}</Heading>
              <span data-testid={'modal-notifications-text'} data-public={true}>{t(CommPrefDisplayText.modalNotificationsText)}</span>
            </Modal>
          </div>
          <p data-testid={'comm-pref-you-can-choose'}>{t(CommPrefDisplayText.youCanChoose)}</p>
          <p data-testid={'comm-pref-terms-and-conditions'}>{t(CommPrefDisplayText.termsAndConditions)}</p>
          <Form onSubmit={onSubmit} data-public={true}>
            <CommunicationPreferencesBox type={'notifications'} data={communicationPreferencesData.notification} setResentVerification={setResentVerification} setIsError={setIsError}/>
            <CommunicationPreferencesBox type={'confirmations'} data={communicationPreferencesData.confirmation} setResentVerification={setResentVerification} setIsError={setIsError}/>
            <SaveButton buttonType={'primary'} theme={themes.lincoln} data-testid={'comm-pref-save-button'}>{t(CommPrefDisplayText.save)}</SaveButton>
          </Form>
        </>}
    </>);
};
export default CommPref;
