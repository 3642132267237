import React from 'react';
import useExperience from '../hooks/useExperience';
import { Language } from '../types/Language';
export const TranslateContext = React.createContext({
    t: (translatable) => (translatable ? translatable?.english : ''),
});
const TranslateProvider = ({ children, }) => {
    const { language } = useExperience();
    const t = (translatable) => {
        return language === Language.ENGLISH ? translatable?.english : translatable?.spanish;
    };
    return <TranslateContext.Provider value={{ t }}>{children}</TranslateContext.Provider>;
};
export default TranslateProvider;
