import React, { useState, useEffect } from 'react';
import { Container, Heading } from '@digitools/honest-react';
import Manager from './Manager';
import Reportee from './Reportee';
import Employee from './Employee';
const Hierarchy = (props) => {
    const [reporteeList, setReporteeList] = useState([]);
    useEffect(() => {
        setReportee(props, setReporteeList);
    });
    const employeeData = props.hierarchyData !== undefined ? props.hierarchyData.emp : undefined;
    const managerData = props.hierarchyData !== undefined ? props.hierarchyData.empManager : undefined;
    return (<>
      {props.initialLoad && props.internalUser ? null : (<Container>
          {managerData ? (<Manager managerData={managerData} getHierarchy={props.getHierarchy} data-testid='hierarchy-manager'/>) : null}
          <div style={{ marginTop: '1.5rem' }} aria-live={'polite'}>
            {employeeData &&
                <>
                <Heading elemType={'h4'}>Employee</Heading>
                <Employee employeeData={employeeData}/>
              </>}
            </div>
          {employeeData &&
                <>
            <Heading elemType={'h4'} style={{ marginBottom: '.5rem' }}>Reportees</Heading>
            {(reporteeList.length === 0 ? ('No hierarchy found') : (<Reportee reporteeList={reporteeList} getHierarchy={props.getHierarchy}/>))}
          </>}
        </Container>)}
    </>);
};
export default Hierarchy;
function setReportee(props, setReporteeList) {
    if (props.hierarchyData !== undefined) {
        setReporteeList(props.hierarchyData.empReports);
    }
}
