import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GenericErrorBoundary from 'src/components/errorPages/GenericErrorBoundary';
import EoiInterviewContainer from './EoiInterviewContainer';
import { EoiProvider } from './providers/EoiProvider';
const EoiRouter = () => {
    return (<EoiProvider>
      <GenericErrorBoundary>
        <Routes>
          <Route path={`*`} element={<EoiInterviewContainer />}/>
        </Routes>
      </GenericErrorBoundary>
    </EoiProvider>);
};
export default EoiRouter;
