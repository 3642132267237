import React from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../../../hooks/useTranslate';
import useEoiContext from '../../../../hooks/useEoiContext';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { isSitusStateNY } from '../../../../utils/HelperFunctions';
import { isSitusStateCA } from '../../../../utils/HelperFunctions';
import Initials from './ApplicantInitials';
const StyledBulletList = styled('ul') `
  margin-top: 0.25rem;
  list-style: none;
  li::before {
    content: "\\25CF";
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    color: ${themes.lincoln.palette.primary};
}`;
const StyledOrderedList = styled('ol') `
  li::before {
    padding-left: 0;
  }
  li {
    margin-top: 0rem;
  }

`;
const LegalText = () => {
    const { t } = useTranslate();
    const { legalText, initialInformation } = useEoiContext();
    const isCA = isSitusStateCA(initialInformation);
    if (legalText && initialInformation) {
        const fraudLanguageTextIndexes = [1, 2];
        const localLegalText = !isSitusStateNY(initialInformation) ? legalText?.applicationLegalText : legalText?.applicationLegalText?.filter((text, index) => !fraudLanguageTextIndexes.includes(index));
        return (<div style={{ maxHeight: (isCA ? undefined : '30vh'), overflowY: (isCA ? undefined : 'scroll'), fontSize: '0.875rem' }} data-testid={'legal-text'}>
        <strong data-testid={'fraud-disclosure'}>{t(ReviewAndSignText.fraudDisclosure)}</strong>
        <br />
        <br />
        {localLegalText?.map(applicationLegalText => (<>
            <strong data-testid={`application-legal-text-${applicationLegalText.seq}`} key={applicationLegalText.seq}>{t(applicationLegalText.text)}</strong>
            <br />
          </>))}
        <br />
        <br />
        <strong data-testid={'acknowledgments'}>{t(ReviewAndSignText.acknowledgments)}</strong>
        <br />
        <StyledOrderedList style={{ width: '97%', textIndent: '-15px', marginLeft: '15px' }}>
          {legalText.legalConditionText.map((legalCondition, i) => <li key={i} data-testid={`legal-condition-${i}`}>
              {t(legalCondition.text)}
              {isCA && <Initials />}
            </li>)}
        </StyledOrderedList>
        <br />
        <strong data-testid={'authorization'}>{t(ReviewAndSignText.authorization)}</strong>
        <StyledOrderedList>
          {legalText.authorizationText.map((authorization, i) => {
                return (<li key={i}>
                {t(authorization.text)}
                {authorization.bulletText.length > 0 &&
                        <StyledBulletList style={{ listStylePosition: 'inside', paddingLeft: '1rem' }}>
                    {authorization.bulletText.map((bullet, j) => <li key={j}>{t(bullet)}</li>)}
                  </StyledBulletList>}
              </li>);
            })}
        </StyledOrderedList>
        <br />
        <strong data-testid={'authorization-note'}>{t(legalText.authorizationNote)}</strong>
      </div>);
    }
    else {
        return <></>;
    }
};
export default LegalText;
