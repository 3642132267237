import React, { useContext } from 'react';
import { BorderLessGrayBox } from '../../../../../../components/Formatting/StyledComponents';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../../hooks/useTranslate';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
import { Heading } from '@digitools/honest-react';
const ReviewAndSignHeader = (props) => {
    const { t } = useTranslate();
    const applicantType = props.applicantType;
    const stepper = useContext(StepperContext);
    return (<BorderLessGrayBox style={{ alignItems: 'center', paddingTop: 0, paddingBottom: 0, height: '2.8125rem' }}>
      <Heading elemType={`${applicantType === 'employee' ? 'h3' : 'h4'}`} elemStyle={'h4'}>{props.title}</Heading>
      <div style={{ display: 'flex', justifyContent: 'right', marginLeft: 'auto', marginRight: 0, marginTop: 0 }}>
        <Button type={'button'} theme={themes.lincoln} style={{ margin: 0, padding: 0 }} onClick={() => { stepper.edit(props.step); }}>
          <Icon name={'edit'} size={'small'} color={'secondary'}/>
          {t(EoiGeneralText.edit)}
        </Button>
      </div>
    </BorderLessGrayBox>);
};
export default ReviewAndSignHeader;
