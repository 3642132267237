import axios from 'axios';
function LCIApi() {
    const submitClaim = async (data, authHeader) => {
        return axios.post(`/lci-service/services/v1/lci-claims`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
        });
    };
    const downloadPdf = async (data, authHeader) => {
        return axios.post(`/lci-service/services/v1/lci-claims/pdf`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
            responseType: 'blob',
        });
    };
    const prefill = async (data, authHeader) => {
        return axios.post('/lci-service/services/v1/lci-prefill', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
        });
    };
    const getLciEligibility = async (authHeader) => {
        return axios.get('/lci-service/services/v1/lci-prefill', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
        });
    };
    const uploadFile = async (data, authHeader) => {
        return axios.post('/mlp-ui-service/api/documents/upload', data, {
            headers: {
                Authorization: authHeader,
                'Content-Type': 'multipart/form-data',
            },
        });
    };
    return { submitClaim, uploadFile, downloadPdf, prefill, getLciEligibility };
}
export default LCIApi;
