export const ResidentialInformationText = {
    heading: {
        english: 'Residential information',
        spanish: 'Informacion residencial',
    },
    headingER: {
        english: 'Employee\'s residential information',
        spanish: 'Información de residente del Empleado',
    },
    country: {
        english: 'Country',
        spanish: 'País',
    },
    state: {
        english: 'State',
        spanish: 'Estado',
    },
    province: {
        english: 'Province',
        spanish: 'Provincia',
    },
    address1: {
        english: 'Residential address 1',
        spanish: 'Dirección residencial 1',
    },
    address2: {
        english: 'Residential address 2 (optional)',
        spanish: 'Dirección residencial 2 (opcional)',
    },
    city: {
        english: 'Residential city',
        spanish: 'Ciudad residencial',
    },
    postal: {
        english: 'Residential postal code',
        spanish: 'Código postal residencial',
    },
};
