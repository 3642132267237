const ConfirmationText = {
    pageTitle: {
        english: 'Accommodation request confirmation',
        spanish: 'Confirmación de solicitud de adaptación'
    },
    successMsg: {
        english: 'Success: your accommodation request was successfully submitted.',
        spanish: 'Exitosa: su solicitud de adaptación se envió con éxito.'
    },
    requestId: {
        english: 'Request ID',
        spanish: 'de ID de solicitud'
    },
    printOption: {
        english: 'View and print your submission',
        spanish: 'Ver e imprimir su envío'
    },
    name: {
        english: 'NAME',
        spanish: 'NOMBRE'
    },
    date: {
        english: `TODAY'S DATE`,
        spanish: `FECHA ACTUAL`
    },
    firstStepHeading: {
        english: 'View your accommodation status',
        spanish: 'Vea el estado de su adaptación',
    },
    firstStepDesc: {
        english: `Within 24 to 48 hours you will be able to view your accommodation request(s) online by selecting the "View Status" button from the landing page.`,
        spanish: `En un plazo de 24 a 48 horas, podrá ver sus solicitudes de adaptación en línea seleccionando el botón “View Status” (Ver estado) en la página de inicio.`,
    },
    secondStepHeading: {
        english: `Upload related documents`,
        spanish: `Cargue documentos relacionados`,
    },
    secondStepDesc: {
        english: 'Once within “View Status” you may upload documents related to your accommodation request(s).',
        spanish: 'Una vez en “View Status” (Ver estado), puede cargar documentos relacionados con sus solicitudes de adaptación.',
    },
    thirdStepHeading: {
        english: 'Have questions regarding your request(s)?',
        spanish: '¿Tiene preguntas sobre sus solicitudes?'
    },
    thirdStepDesc: {
        english: 'If you have any questions about the status of your accommodation request(s), please contact your HR representative',
        spanish: 'Si tiene alguna pregunta sobre el estado de sus solicitudes de adaptación, comuníquese con su representante de Recursos Humanos.',
    },
    nextStepText: {
        english: 'NEXT STEPS',
        spanish: 'PRÓXIMOS PASOS',
    },
    downloadErrorText: {
        english: 'There was an error downloading your confirmation. If the problem persists please contact customer support.',
        spanish: 'Hubo un error al descargar su confirmación. Si el problema persiste, póngase en contacto con el servicio de atención al cliente.'
    }
};
export default ConfirmationText;
