import React, { useState } from 'react';
import useGet from './../../../../../../hooks/useGet';
import PaymentInformationSummary from './PaymentInformationSummary';
import PaymentFilter from './PaymentFilter';
import PaymentDataTable from './PaymentDataTable';
import { Alert, Spinner } from '@digitools/honest-react';
import PrintPaymentsButton from './PrintPayments';
const NotFound = () => <Alert type='info'>There is no payment information to display at this time.</Alert>;
const Error = () => (<Alert type='error'>
    <span>
      <strong style={{ marginRight: '1rem' }}>Error!</strong>Unable to load payment information.
    </span>
  </Alert>);
const PaymentInformation = ({ claimNum, empNum }) => {
    const [filteredPayments, setFilteredPayments] = useState();
    const { error, loading, data: paymentSummary, } = useGet({
        url: `/status-service/payment/checkSummary/${claimNum}`,
    });
    // should move this be in the component itself
    const checkTotals = paymentSummary
        ? {
            paidThroughDate: paymentSummary.paidThroughDate,
            grossBenefit: paymentSummary.totalGrossBenefit,
            offsets: paymentSummary.totalOffsets,
            adjustedGross: paymentSummary.totalAdjustedGross,
            cola: paymentSummary.totalCola,
            taxes: paymentSummary.totalTaxes,
            otherDeductions: paymentSummary.totalOtherDeductions,
            netBenefit: paymentSummary.totalNetBenefit,
        }
        : {};
    // if we have payment data, prefer filtered data over initial, else undefined
    const dataToDisplay = paymentSummary ? (filteredPayments ? filteredPayments : paymentSummary.claimCheck) : undefined;
    if (loading) {
        return <Spinner id='payment-info-spinner'/>;
    }
    if (error) {
        if (error.response && error.response.status === 404) {
            return <NotFound />;
        }
        return <Error />;
    }
    if (!paymentSummary || (paymentSummary && paymentSummary.claimCheck.length === 0)) {
        return <NotFound />;
    }
    return (<>
      {paymentSummary && <PrintPaymentsButton payments={paymentSummary} empNum={empNum}/>}
      <PaymentInformationSummary checkTotals={checkTotals}/>
      {paymentSummary && (<PaymentFilter initialData={paymentSummary.claimCheck} setFilteredData={setFilteredPayments}/>)}
      {paymentSummary && <PaymentDataTable claimPayments={dataToDisplay} claimNum={claimNum} empNum={empNum}/>}
    </>);
};
export default PaymentInformation;
