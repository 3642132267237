import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getEnvVariable } from '../../../utils/envUtils';
const MobileInternalMenuLink = styled(Link) `
  display: flex;
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayLight};
  text-align: left;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.grayDarker};
  background-color: ${({ theme }) => theme.palette.grayLightest};
  transition: margin-left 0.25s ease-in-out;
`;
const MobileExternalMenuLink = styled('a') `
  display: flex;
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayLight};
  text-align: left;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.grayDarker};
  background-color: ${({ theme }) => theme.palette.grayLightest};
  transition: margin-left 0.25s ease-in-out;
`;
export const MobileMenuLink = ({ to, externalLink, angular, proveRequired, ...rest }) => {
    if (externalLink) {
        return <MobileExternalMenuLink href={to} target='_blank' {...rest}/>;
    }
    else if (angular) {
        return <MobileExternalMenuLink href={getEnvVariable('mlpHomeUrl') + to} {...rest}/>;
    }
    else if (proveRequired) {
        return <MobileInternalMenuLink to={'/prove'} {...rest}/>;
    }
    else {
        return <MobileInternalMenuLink to={to} {...rest}/>;
    }
};
