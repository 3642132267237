import React from 'react';
import { Col, Row } from '@digitools/honest-react';
import { getFormattedNameEmpId } from '../api';
import EmployeeOverviewLink from './EmployeeOverviewLink';
const Employee = (props) => {
    return (<>
      <Row data-testid='employee'>
        <Col md={8} lg={8} style={{ marginBottom: '.5rem' }}>
          <span aria-label='Employee Name' data-private={true}>{getFormattedNameEmpId(props.employeeData)}</span>
        </Col>
        <Col md={4} lg={4}>
          <EmployeeOverviewLink empId={props.employeeData.empId} employee={props.employeeData}/>
        </Col>
      </Row>
    </>);
};
export default Employee;
