export const FraudStatementText = {
    CO: {
        english: "It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory agencies.",
        spanish: "La ley prohíbe suministrar deliberadamente información falsa, incompleta o engañosa a una compañía de seguros con el objetivo de defraudarla o intentar defraudarla. Las sanciones penales que se aplican a estos delitos son, entre otras, prisión, multas, denegación del seguro y pago de daños y perjuicios civiles. Toda compañía de seguros o sus agentes que suministren deliberadamente información falsa, incompleta o engañosa a un titular de póliza o reclamante con el objetivo de defraudarlo o intentar defraudarlo respecto de una liquidación o indemnización pagadera con los fondos del seguro, deberán ser denunciados a la división de seguros del departamento de agencias normativas de Colorado."
    },
    FL: {
        english: "Any person who knowingly and with intent to injure, defraud or deceive any insurer files a statement of claim or an application containing any false, incomplete or misleading information is guilty of a felony of the third degree.",
        spanish: "Toda persona que, con conocimiento de causa y con la intención de dañar, defraudar o engañar a cualquier aseguradora, presente una declaración de reclamo o una solicitud que contenga información falsa, incompleta o engañosa será culpable de un delito grave de tercer grado."
    },
    KY: {
        english: "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.",
        spanish: "Toda persona que, con conocimiento de causa y con la intención de defraudar a cualquier aseguradora o a otra persona, presente una solicitud de seguro que contenga información significativamente falsa o que, con fines engañosos, oculte información referente a algún hecho importante en ésta, cometerá un acto fraudulento de seguros, lo cual es un delito."
    },
    NJ: {
        english: "Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.",
        spanish: "Toda persona que incluya información falsa o engañosa en una solicitud de póliza de seguro estará sujeta a sanciones civiles y penales."
    },
    NY: {
        english: "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each violation.",
        spanish: "Toda aquella persona que deliberadamente y con el fin de defraudar a una compañía de seguros o a otra persona, presente una solicitud de seguro o una declaración de reclamo que contenga información falsa u oculte, con el objeto de engañar, información concerniente a hechos fundamentales, cometerá el delito de acto fraudulento contra el seguro, lo cual es un crimen, y quedará sujeta a sanciones civiles que no podrán exceder los cinco mil dólares y el valor estipulado en el reclamo para cada infracción."
    },
    OH: {
        english: "Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.",
        spanish: "Toda persona que, con intención de cometer fraude o sabiendo que está facilitando un fraude contra una aseguradora, presente una solicitud o un reclamo con declaraciones falsas o engañosas, será culpable de fraude de seguros."
    },
    OK: {
        english: "WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.",
        spanish: "ADVERTENCIA: Toda persona que, con conocimiento de causa y con la intención de dañar, defraudar o engañar a cualquier aseguradora, reclame los beneficios de una póliza de seguro que contenga información falsa, incompleta o engañosa será culpable de un delito grave."
    },
    PA: {
        english: "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.",
        spanish: "Toda aquella persona que deliberadamente y con el fin de defraudar a una compañía de seguros o a otra persona, presente una solicitud de seguro o una declaración de reclamo que contenga información falsa u oculte información concerniente a hechos fundamentales con el objeto de engañar, cometerá un acto fraudulento contra el seguro, lo cual es un crimen, y quedará sujeta a sanciones penales y civiles."
    },
    DC: {
        english: "Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
        spanish: "Toda persona que, con conocimiento de causa, presente un reclamo falso o fraudulento para el pago de una pérdida o un beneficio o que presente, con conocimiento de causa, información falsa en una solicitud de seguro será culpable de un delito y se le podrá aplicar sanciones penales y civiles."
    },
    LA: {
        english: "Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
        spanish: "Toda persona que, con conocimiento de causa, presente un reclamo falso o fraudulento para el pago de una pérdida o un beneficio o que presente, con conocimiento de causa, información falsa en una solicitud de seguro será culpable de un delito y se le podrá aplicar sanciones penales y civiles."
    },
    MD: {
        english: "Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
        spanish: "Toda persona que, con conocimiento de causa o intencionalmente, presente un reclamo falso o fraudulento para el pago de una pérdida o un beneficio o que presente, con conocimiento de causa o intencionalmente, información falsa en una solicitud de seguro será culpable de un delito y se le podrá aplicar sanciones penales y civiles."
    },
    ME: {
        english: "It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.",
        spanish: "Es un delito proporcionar, con conocimiento de causa, información falsa, incompleta o engañosa a una compañía de seguros con el fin de defraudar a dicha compañía. Las sanciones aplicables pueden ser prisión, multas y denegación de los beneficios derivados del seguro."
    },
    TN: {
        english: "It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.",
        spanish: "Es un delito proporcionar, con conocimiento de causa, información falsa, incompleta o engañosa a una compañía de seguros con el fin de defraudar a dicha compañía. Las sanciones aplicables pueden ser prisión, multas y denegación de los beneficios derivados del seguro."
    },
    TX: {
        english: "Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
        spanish: "Toda persona que, con conocimiento de causa, presente un reclamo falso o fraudulento para el pago de una pérdida o un beneficio o que presente, con conocimiento de causa, información falsa en una solicitud de seguro será culpable de un delito y se le podrá aplicar sanciones penales y civiles."
    },
    VA: {
        english: "It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.",
        spanish: "Es un delito proporcionar, con conocimiento de causa, información falsa, incompleta o engañosa a una compañía de seguros con el fin de defraudar a dicha compañía. Las sanciones aplicables pueden ser prisión, multas y denegación de los beneficios derivados del seguro."
    },
    WA: {
        english: "It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.",
        spanish: "Es un delito proporcionar, con conocimiento de causa, información falsa, incompleta o engañosa a una compañía de seguros con el fin de defraudar a dicha compañía. Las sanciones aplicables pueden ser prisión, multas y denegación de los beneficios derivados del seguro."
    },
    otherState: {
        english: "Any person who knowingly, and with intent to injure, defraud or deceive any insurance company, submits an application or files a statement of claim containing any false, incomplete or misleading information commits a fraudulent insurance act and may be subject to criminal or civil penalties.",
        spanish: "Toda persona que, con conocimiento de causa y con la intención de dañar, defraudar o engañar a cualquier aseguradora, presente una declaración de reclamo o una solicitud que contenga información falsa, incompleta o engañosa cometerá un acto fraudulento de seguros y se le podrá aplicar sanciones penales y civiles."
    },
    header: {
        english: "Fraud Statement For Your Jurisdiction",
        spanish: "Declaración de fraude para su jurisdicción"
    },
    footer: {
        english: "Use back button below to review the information in this online form.",
        spanish: "Utilice el botón Atrás para revisar la información de este formulario en línea."
    }
};
