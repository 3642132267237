import React from 'react';
import { Alert, Button } from '@digitools/honest-react';
const SaveUndoButton = (props) => {
    let alert;
    if (props.showAlert) {
        alert = props.errorMessage ? (<Alert type='error' closeable={true} onClose={props.closeAlert}>
        {props.errorMessage}
      </Alert>) : (<Alert type='success' closeable={true} onClose={props.closeAlert}>
        Employer permissions are saved successfully!
      </Alert>);
    }
    else {
        alert = <></>;
    }
    return (<>
      <div style={{ marginTop: '1.5rem' }}>
        {alert}
        <Button disabled={props.changed} buttonType={'primary'} size={'medium'} aria-label='Save Employer Permissions' onClick={props.saveUserPermissions}>
          Save Changes
        </Button>
        <Button disabled={props.changed} buttonType={'secondary'} size={'medium'} aria-label='Undo Employer Permissions' onClick={props.undoChanges}>
          Undo
        </Button>
      </div>
    </>);
};
export default SaveUndoButton;
