export const reasonForTimeOff = [
    { label: 'Personal Sickness', value: 'SELF' },
    { label: 'Sick Family Member', value: 'FAMILY' },
    { label: 'Transportation', value: 'TRANSP' },
    { label: 'House Maintenance', value: 'HOUSE' },
    { label: 'Death in the Family', value: 'BEREAV' },
    { label: 'Civic Duty', value: 'CIVIC' },
    { label: 'Other', value: 'OTHER' },
];
export const returnLabelByValue = (value) => {
    const result = reasonForTimeOff.find(obj => obj.value === value);
    if (result !== undefined) {
        return result.label;
    }
};
