import { themes } from '@digitools/honest-react';
export const getDropDownList = (data, t) => {
    const arrList = [];
    let arrListObj;
    data?.forEach((item) => {
        arrListObj = {
            label: t(item.dropDownText),
            value: item.systemOneValue
        };
        arrList.push(arrListObj);
    });
    return arrList;
};
export const getSelectInputDropDown = (data, t) => {
    const arrList = [];
    let arrListObj;
    let count = 0;
    data?.forEach((item) => {
        count = count + 1;
        arrListObj = {
            label: t(item.dropDownText),
            value: item.systemOneValue,
            id: count
        };
        arrList.push(arrListObj);
    });
    return arrList;
};
export const getAsRequest = (data) => {
    const arrList = [];
    let arrListObj;
    let count = 0;
    data?.forEach((item) => {
        count = count + 1;
        arrListObj = {
            requestedAccommodationType: item.requestedAccommodationType,
            requestedAccommodationDetail: item.requestedAccommodationDetail,
            requestedPeriodType: item.requestedPeriodType,
            requestedStartDate: item.requestedStartDate,
            existingAccommodation: item.existingAccommodation,
            accommodationWorkOrHome: item.accommodationWorkOrHome,
        };
        if (item.requestedPeriodType === 'T') {
            arrListObj.anticipatedEndDate = item.anticipatedEndDate;
            if (item.status === 'CL') {
                arrListObj.rtwWithoutAccomodationDate = item.rtwWithoutAccomodationDate;
            }
        }
        if (item.status) {
            arrListObj.requestReceivedDate = item.requestReceivedDate;
            arrListObj.status = item.status;
            arrListObj.assistanceRequired = item.assistanceRequired;
            arrListObj.restrictionsAndLimits = item.restrictionsAndLimits;
            if (item.restrictionsAndLimits?.includes('15')) {
                arrListObj.suppRestrictionsAndLimitations = item.suppRestrictionsAndLimitations;
            }
            arrListObj.interactiveBeginDate = item.interactiveBeginDate;
            arrListObj.initialMeetingDate = item.initialMeetingDate;
            arrListObj.priorCarrier = item.priorCarrier;
            if (item.status === 'AP' || item.status === 'CL') {
                arrListObj.actualPeriodType = item.requestedPeriodType;
                arrListObj.providedAccomodationTypeCode = item.providedAccomodationTypeCode;
                arrListObj.providedDetailsText = item.providedDetailsText;
                arrListObj.actualEndDate = item.actualEndDate;
                arrListObj.actualBeginDate = item.actualBeginDate;
                arrListObj.determinationInfoReceivedDate = item.determinationInfoReceivedDate;
                arrListObj.determinationDate = item.determinationDate;
                arrListObj.providedCostAmount = item.providedCostAmount;
            }
            if (item.status === 'IP') {
                arrListObj.requestedAccommodationCost = item.requestedAccommodationCost;
            }
            else if (item.status === 'AP') {
                arrListObj.requestedAccommodationCost = item.requestedAccommodationCost;
            }
            else if (item.status === 'CL') {
                arrListObj.closedDate = item.closedDate;
                arrListObj.statusReasonCode = item.statusReasonCode;
            }
        }
        arrList.push(arrListObj);
    });
    return arrList;
};
export const getSelectedDropDownItemsAsString = (systemOneDropDownList, selectedDropDownList, t) => {
    let dropDownText = [];
    let TextValue = '';
    let length = selectedDropDownList ? selectedDropDownList?.length : 0;
    if (!(Array.isArray(selectedDropDownList))) {
        systemOneDropDownList?.map((data) => {
            if (data.systemOneValue === selectedDropDownList) {
                TextValue = t(data.dropDownText);
            }
        });
        return TextValue;
    }
    else {
        for (let i = 0; i < length; i++) {
            for (let j = 0; j < systemOneDropDownList?.length; j++) {
                let stringText = selectedDropDownList && selectedDropDownList[i].toString();
                if (systemOneDropDownList[j].systemOneValue === stringText) {
                    dropDownText.push(t(systemOneDropDownList[j].dropDownText));
                    break;
                }
            }
        }
        return dropDownText.join(', ');
    }
};
export const getColorCode = (count) => {
    const colors = [themes.lincoln.palette.warning, themes.lincoln.palette.primaryDark, themes.lincoln.palette.primaryDarker];
    let borderColor;
    let modValue = count % 3;
    if (modValue >= 0 && modValue < 3) {
        borderColor = colors[modValue];
    }
    return borderColor;
};
export const getDocumentList = (documents) => {
    let uploadingDocumentsArr = [];
    let uploadingDocuments = '';
    if (documents?.length > 0) {
        documents?.map((data) => {
            uploadingDocumentsArr?.push(data.name);
        });
        uploadingDocuments = uploadingDocumentsArr?.join(', ');
    }
    return uploadingDocuments;
};
export const displayField = (fieldValue) => {
    let display = false;
    if (fieldValue) {
        fieldValue.forEach(element => {
            if (element === '15') {
                display = true;
            }
        });
    }
    return display;
};
