import axios from 'axios';
export async function getHierarchyByEmpId(authHeader, empId) {
    return axios.get(`/mlp-ui-service/api/employees/hierarchy/${empId}`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export async function getHierarchyByName(authHeader, lastName, firstName) {
    lastName = lastName === undefined ? '' : lastName.trim();
    firstName = firstName === undefined ? '' : firstName.trim();
    const url = '?lastName=' + lastName + '&firstName=' + firstName;
    return axios.get(`/mlp-ui-service/api/employees/hierarchy${url}`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export function getFormattedNameEmpId(data) {
    if (!data) {
        return null;
    }
    const lastName = data.lastName ? data.lastName.trim() : '';
    const firstName = data.firstName ? data.firstName.trim() : '';
    const mI = data.mI ? data.mI.trim() : '';
    return `${lastName}, ${firstName} ${mI} (${data.empId})`;
}
export function getFormattedEmpName(data) {
    if (!data) {
        return null;
    }
    const lastName = data.lastName ? data.lastName.trim() : '';
    const firstName = data.firstName ? data.firstName.trim() : '';
    // @ts-ignore merging types is messy but cleaner doing this than mucking with all the hierarchy types
    const mI = data.mI ? data.mI.trim() : data.middleName ? data.middleName.trim() : '';
    return data !== undefined ? `${lastName}, ${firstName} ${mI}`.trim() : '';
}
