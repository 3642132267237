import { Button, Heading, StepperContext } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext } from 'react';
import useBdmLciConfig from '../../../../../hooks/useBdmLciConfig';
import useExperience from '../../../../../hooks/useExperience';
import { getLabels } from '../../../utils/LCIUtils';
import CancelLci from '../../cancelLCI/CancelLci';
import AccidentInformation from './AccidentInformation';
import ImportantDateInformation from './ImportantDateInformation';
import PolicyInformation from './PolicyInformation';
import DependentCoverageAmount from './DependentCoverageAmount';
import CoverageAmount from './CoverageAmount';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
const removeComma = (str) => {
    return str.replace(/[, ]+/g, '').trim();
};
const PolicyInfoContainer = ({ claimant, event, onComplete }) => {
    const stepper = useContext(StepperContext);
    const dismemberment = `dismemberment / loss of sight`;
    const { trackEvent } = useTealium();
    const handleContinue = (values) => {
        const info = {
            workHours: values.policyInfo_workHours,
            earnings: parseFloat(removeComma(values.policyInfo_earnings)),
            workStatus: values.policyInfo_workStatus,
            classification: values.policyInfo_classification,
            occupation: values.policyInfo_occupation,
            terminationInfo: values.policyInfo_terminationInfo,
            dateOfTermination: values.policyInfo_dateOfTermination,
            dateOfRetired: values.policyInfo_dateOfRetired,
            retirementStatusInfo: values.policyInfo_retirementStatusInfo,
            retirementAmount: values.policyInfo_retirementAmount
                ? parseFloat(removeComma(values.policyInfo_retirementAmount))
                : undefined,
            dateEmployed: values.policyInfo_dateEmployed,
            lastWorked: values.policyInfo_lastWorked,
            lastPaid: values.policyInfo_lastPaid,
            dependentEffectiveDate: values.policyInfo_dependentEffectiveDate,
            dateOfDeath: values.policyInfo_dateOfDeath,
            dateBenefitChange: values.policyInfo_dateBenefitChange,
            reason: values.policyInfo_reason,
            otherReason: values.policyInfo_otherReason,
            dateOfDisability: values.policyInfo_dateOfDisability,
            reasonForLeaving: values.policyInfo_reasonForLeaving,
            basicAmount: values.policyInfo_basicAmount ? parseFloat(removeComma(values.policyInfo_basicAmount)) : undefined,
            basicADandDAmount: values.policyInfo_basicADandDAmount
                ? parseFloat(removeComma(values.policyInfo_basicADandDAmount))
                : undefined,
            optionalAmount: values.policyInfo_optionalAmount
                ? parseFloat(removeComma(values.policyInfo_optionalAmount))
                : undefined,
            optionalADandDAmount: values.policyInfo_optionalADandDAmount
                ? parseFloat(removeComma(values.policyInfo_optionalADandDAmount))
                : undefined,
            basicDependentAmount: values.policyInfo_dependentBasicAmount
                ? parseFloat(removeComma(values.policyInfo_dependentBasicAmount))
                : undefined,
            basicADandDDependentAmount: values.policyInfo_dependentBasicADandDAmount
                ? parseFloat(removeComma(values.policyInfo_dependentBasicADandDAmount))
                : undefined,
            optionalDependentAmount: values.policyInfo_dependentOptionalAmount
                ? parseFloat(removeComma(values.policyInfo_dependentOptionalAmount))
                : undefined,
            optionalADandDDependentAmount: values.policyInfo_dependentOptionalADandDAmount
                ? parseFloat(removeComma(values.policyInfo_dependentOptionalADandDAmount))
                : undefined,
            dateOfAccident: values.policyInfo_dateOfAccident,
            accidentLocation: values.policyInfo_accidentLocation,
            briefAboutAccident: values.policyInfo_briefAboutAccident,
        };
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'policy info',
            event_status: 'step 4'
        });
        onComplete(info);
        stepper.next();
    };
    const handleBack = () => {
        stepper.back();
    };
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    return (<>
      <Form onSubmit={handleContinue}>
        <Heading elemType='h2' color='primary'>
          Policy Info
        </Heading>
        <BorderLessGrayBox>
          <Heading elemType='h3' elemStyle='h4'>{labelEmployee} Policy Information</Heading>
        </BorderLessGrayBox>
        <PolicyInformation claimant={claimant} event={event}/>

        <BorderLessGrayBox>
          <Heading elemType='h3' elemStyle='h4'>Important Dates</Heading>
        </BorderLessGrayBox>
        <ImportantDateInformation claimant={claimant} event={event}/>

      {/* TODO : this event value will be replaced based on backend response */}
        {event === dismemberment && (<>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>About the Accident</Heading>
            </BorderLessGrayBox>
            <AccidentInformation id={'accidentInfo'} claimant={claimant} event={event}/>
          </>)}

        <BorderLessGrayBox>
          <Heading elemType='h3' elemStyle='h4'>Coverage Amounts</Heading>
        </BorderLessGrayBox>
        <CoverageAmount claimant={claimant} event={event}/>
        {claimant === 'dependent' && (event === 'death' || event === dismemberment) && (<DependentCoverageAmount claimant={claimant} event={event}/>)}
        <StyledDiv>
          <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='back_policy_info' name='back' type='button' onClick={handleBack}>
            Back
          </Button>
          <CancelLci step={'step 4'} pageTitle={'policy info'} sectionInfo={'Policy info'}/>
          <Button aria-label={'Continue'} buttonType='primary' role='button' id='continue_policy_info'>
            Continue
          </Button>
        </StyledDiv>
      </Form>
    </>);
};
export default PolicyInfoContainer;
