import React from 'react';
import useCliDataContext from "../../hooks/useCliDataContext";
import useTranslate from '../../../../hooks/useTranslate';
import { ReviewText } from "../../constants/ReviewText";
import { Heading } from '@digitools/honest-react';
const ReviewFraudStatment = () => {
    const { claimantData, absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const getStateBasedStatement = () => {
        const state = claimantData?.selectedStateID;
        if (state !== null) {
            const statement = ReviewText.fraudStatement[state];
            if (statement) {
                return statement;
            }
        }
        return ReviewText.fraudStatement.otherState;
    };
    const shouldRenderFraudStatement = absenceData?.leaveCategory === 'C' && absenceData?.leaveType === 'OWN' && claimantData?.selectedCountryID === 'USA';
    return (<>
        {shouldRenderFraudStatement &&
            <div style={{ marginTop: '2rem', marginBottom: '1.5rem' }}>
                <Heading elemType="h3">{t(ReviewText.fraudStatement.header)}</Heading>
                <p data-testid="fraudStatement">{t(getStateBasedStatement())}</p>
                <p>{t(ReviewText.fraudStatement.footer)}</p>
            </div>}
        </>);
};
export default ReviewFraudStatment;
