import { addDays, format, startOfToday } from 'date-fns';
import React, { createContext, useEffect, useState } from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import useTranslate from '../../../hooks/useTranslate';
import { SaveForLaterText } from '../i18n/SaveForLaterText';
import { showAnnualEnrollment } from '../pages/verification/AdditionalInfoForm';
import { saveInterview } from '../utils/HelperFunctions';
export const EoiContext = createContext({
    eoiError: undefined,
    isVerificationPage: false,
    setIsVerificationPage: () => { },
    setEoiError: () => { },
    initialInformation: undefined,
    setInitialInformation: () => { },
    employeeInfo: undefined,
    setEmployeeInfo: () => { },
    customContent: undefined,
    setVerificationInformation: () => { },
    setAdditionalInformation: () => { },
    setCustomContent: () => { },
    interviewAnswers: { applicantTypes: { employee: false, spouse: false, dependent: false }, reflexiveAnswers: {} }, // TODO: probably want to remove reflexive answers from here when it's implemented.
    setCoverageElections: () => { },
    prePopInformation: undefined,
    setPrePopInformation: () => { },
    setMedicalInformationAnswers: () => { },
    setCriticalIllnessAnswers: () => { },
    setReflexiveQuestions: () => { },
    reflexiveQuestions: undefined,
    setReflexiveAnswers: () => { },
    setApplicants: () => { },
    savedInterview: false,
    setSavedInterview: () => { },
    sflModalHeaderText: undefined,
    sflModalBodyText: undefined,
    setApplicantTypes: () => { },
    setPrePopInfoAndInterviewAnswers: (prePopData, verificationData) => { },
    questionSubmissionResponse: undefined,
    setQuestionSubmissionResponse: (questionSubmissionResponse) => { },
    customerConfig: undefined,
    setCustomerConfig: (customerConfig) => { },
    saveForLaterError: undefined,
    setSaveForLaterError: (error) => { },
    displaySaveForLaterModal: false,
    setDisplaySaveForLaterModal: (displaySaveForLaterModal) => { },
    displaySaveForLaterError: false,
    setDisplaySaveForLaterError: (displaySaveForLaterError) => { },
    setVerifyingUserIdentity: (verifyingUserIdentity) => { },
    legalText: undefined,
    setLegalText: (legalText) => { },
    validationError: false,
    setValidationError: (validationError) => { },
    initializeInterviewAnswers: () => { },
});
const getCoverageElectionsFromPrePop = (prePopData) => {
    let employeeCoverages = [];
    const spouseCoverages = [];
    const dependentCoverages = [];
    const familyCoverages = [];
    prePopData.employeeCoverages.map((prePopCoverage) => {
        employeeCoverages.push({
            coverageCode: prePopCoverage.coverageCode,
            currentCoverageType: prePopCoverage.currentAmountType,
            currentCoverageAmount: prePopCoverage.currentAmount,
            requestedCoverageType: prePopCoverage.requestedAmountType,
            requestedCoverageAmount: prePopCoverage.requestedAmount,
        });
    });
    prePopData.spouseCoverages.map((prePopCoverage) => {
        spouseCoverages.push({
            coverageCode: prePopCoverage.coverageCode,
            currentCoverageType: prePopCoverage.currentAmountType,
            currentCoverageAmount: prePopCoverage.currentAmount,
            requestedCoverageType: prePopCoverage.requestedAmountType,
            requestedCoverageAmount: prePopCoverage.requestedAmount,
        });
    });
    prePopData.dependentCoverages.map((prePopCoverage) => {
        dependentCoverages.push({
            coverageCode: prePopCoverage.coverageCode,
            currentCoverageType: prePopCoverage.currentAmountType,
            currentCoverageAmount: prePopCoverage.currentAmount,
            requestedCoverageType: prePopCoverage.requestedAmountType,
            requestedCoverageAmount: prePopCoverage.requestedAmount,
        });
    });
    prePopData.familyCoverages.map((prePopCoverage) => {
        familyCoverages.push({
            coverageCode: prePopCoverage.coverageCode,
            currentCoverageType: prePopCoverage.currentAmountType,
            currentCoverageAmount: prePopCoverage.currentAmount,
            requestedCoverageType: prePopCoverage.requestedAmountType,
            requestedCoverageAmount: prePopCoverage.requestedAmount,
        });
    });
    return {
        employeeCoverage: employeeCoverages,
        spouseCoverage: spouseCoverages,
        dependentCoverage: dependentCoverages,
        familyCoverage: familyCoverages,
        prePopCoverage: prePopData.prePopCoverage,
    };
};
const setPrePopApplicants = (prePopApplicants) => {
    const prePopEmployee = prePopApplicants.filter((applicant) => applicant.applicantType === 'EMPLOYEE')[0];
    const prePopSpouse = prePopApplicants
        .filter((applicant) => applicant.applicantType === 'SPOUSE')
        .shift();
    const prePopDependents = prePopApplicants.filter((applicant) => applicant.applicantType === 'DEPENDENT');
    let applicants = {
        employee: setPrePopEmployeeInfo(prePopEmployee),
    };
    if (prePopSpouse) {
        applicants.spouse = setApplicantInformation(prePopSpouse);
    }
    if (prePopDependents.length > 0) {
        applicants.disableAddingDependents = true;
        applicants.dependents = setPrePopDependents(prePopDependents);
    }
    return applicants;
};
const setPrePopDependents = (prePopDependents) => {
    let dependents = [];
    prePopDependents.map((dependent) => {
        dependents.push(setApplicantInformation(dependent));
    });
    return dependents;
};
const setPrePopEmployeeInfo = (prePopEmployee) => {
    const employeeInfo = setApplicantInformation(prePopEmployee);
    employeeInfo.employmentInformation = setPrePopEmploymentInfo(prePopEmployee);
    if (prePopEmployee.addresses) {
        employeeInfo.contactInformation = setPrePopContactInfo(prePopEmployee);
    }
    return employeeInfo;
};
const setApplicantInformation = (applicant) => {
    return {
        firstName: applicant.firstName,
        middleInitial: applicant.middleInitial,
        lastName: applicant.lastName,
        socialSecurityNumber: applicant.ssn,
        ssnFound: applicant.ssnFound,
        dateOfBirth: applicant.dateOfBirth ? format(new Date(applicant.dateOfBirth), 'MM/dd/yyyy') : undefined,
        sex: applicant.gender,
        birthState: applicant.birthState,
        disableFirstName: applicant.firstName ? true : false,
        disableMiddleInitial: applicant.middleInitial ? true : false,
        disableLastName: applicant.lastName ? true : false,
        disableSocialSecurityNumber: applicant.ssn ? true : false,
        disableDateOfBirth: applicant.dateOfBirth ? true : false,
        disableSex: applicant.gender ? true : false,
        disableBirthState: applicant.birthState ? true : false,
    };
};
const setPrePopEmploymentInfo = (prePopEmployee) => {
    return {
        employeeId: prePopEmployee.applicantID,
        dateOfHire: prePopEmployee.dateOfHire ? format(new Date(prePopEmployee.dateOfHire), 'MM/dd/yyyy') : undefined,
        occupation: prePopEmployee.occupation,
        disableEmployeeId: prePopEmployee.applicantID ? true : false,
        disableDateOfHire: prePopEmployee.dateOfHire ? true : false,
        disableOccupation: prePopEmployee.occupation ? true : false,
    };
};
const setPrePopContactInfo = (prePopEmployee) => {
    const prefillHomeAddress = prePopEmployee.addresses
        .filter((address) => address.addressType === 'HOME')
        .shift();
    const prefillMailingAddress = prePopEmployee.addresses
        .filter((address) => address.addressType === 'MAILING')
        .shift();
    let prePopPhoneType;
    switch (prePopEmployee.phoneType) {
        case 'MBL':
            prePopPhoneType = 'MBL';
            break;
        case 'OFF':
            prePopPhoneType = 'OFF';
            break;
        case 'HM':
            prePopPhoneType = 'HM';
            break;
    }
    return {
        phoneNumberType: prePopPhoneType,
        phoneNumber: prePopEmployee.phone,
        emailAddress: prePopEmployee.email,
        useAsMailingAddress: false,
        residentialAddressField1: prefillHomeAddress ? prefillHomeAddress.addressLine1 : undefined,
        residentialAddressField2: prefillHomeAddress ? prefillHomeAddress.addressLine2 : undefined,
        residentialCountry: prefillHomeAddress ? prefillHomeAddress.country : undefined,
        residentialState: prefillHomeAddress ? prefillHomeAddress.state : undefined,
        residentialCity: prefillHomeAddress ? prefillHomeAddress.city : undefined,
        residentialZipCode: prefillHomeAddress ? prefillHomeAddress.postalCode : undefined,
        mailingAddressField1: prefillMailingAddress ? prefillMailingAddress.addressLine1 : undefined,
        mailingAddressField2: prefillMailingAddress ? prefillMailingAddress.addressLine2 : undefined,
        mailingCountry: prefillMailingAddress ? prefillMailingAddress.country : undefined,
        mailingState: prefillMailingAddress ? prefillMailingAddress.state : undefined,
        mailingCity: prefillMailingAddress ? prefillMailingAddress.city : undefined,
        mailingZipCode: prefillMailingAddress ? prefillMailingAddress.postalCode : undefined,
    };
};
export const EoiProvider = ({ children, }) => {
    const [eoiError, setEoiError] = useState();
    const [isVerificationPage, setIsVerificationPage] = useState();
    const [initialInformation, setInitialInformation] = useState();
    const [savedInterview, setSavedInterview] = useState(false);
    const [sflModalHeaderText, setSflModalHeaderText] = useState(undefined);
    const [sflModalBodyText, setSflModalBodyText] = useState(undefined);
    const [employeeInfo, setEmployeeInfo] = useState();
    const [customContent, setCustomContent] = useState();
    const [prePopInformation, setPrePopInformation] = useState();
    const [customerConfig, setCustomerConfig] = useState();
    const [displaySaveForLaterModal, setDisplaySaveForLaterModal] = useState(true);
    const [verifyingUserIdentity, setVerifyingUserIdentity] = useState(false);
    const [displaySaveForLaterError, setDisplaySaveForLaterError] = useState(false);
    const { authHeader } = useAuthentication();
    const { t } = useTranslate();
    const [legalText, setLegalText] = useState();
    const [validationError, setValidationError] = useState(false);
    // Interview Answer states *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~
    const [verificationInformation, setVerificationInformation] = useState({});
    const [additionalInformation, setAdditionalInformation] = useState({
        disableApplicationReason: false,
        disableNewEmployee: false,
    });
    const [coverageElections, setCoverageElections] = useState({});
    const [medicalInformationAnswers, setMedicalInformationAnswers] = useState([]);
    const [criticalIllnessAnswers, setCriticalIllnessAnswers] = useState([]);
    const [applicants, setApplicants] = useState({});
    const [applicantTypes, setApplicantTypes] = useState({
        employee: false,
        spouse: false,
        dependent: false,
    });
    const [reflexiveQuestions, setReflexiveQuestions] = useState(undefined);
    const [reflexiveAnswers, setReflexiveAnswers] = useState({});
    const [questionSubmissionResponse, setQuestionSubmissionResponse] = useState();
    const [saveForLaterError, setSaveForLaterError] = useState();
    const { language } = useExperience();
    const interviewAnswers = {
        verificationInformation,
        additionalInformation,
        coverageElections,
        applicants,
        applicantTypes,
        medicalInformationAnswers,
        criticalIllnessAnswers,
        reflexiveAnswers,
    };
    const initializeInterviewAnswers = () => {
        setVerificationInformation({});
        setAdditionalInformation({ disableApplicationReason: false, disableNewEmployee: false });
        setCoverageElections({});
        setMedicalInformationAnswers([]);
        setCriticalIllnessAnswers([]);
        setApplicants({});
        setApplicantTypes({ employee: false, spouse: false, dependent: false });
        setReflexiveAnswers({});
        setReflexiveQuestions(undefined);
    };
    useEffect(() => {
        if (customerConfig && customerConfig.saveForLater && !verifyingUserIdentity) {
            if (!isVerificationPage) {
                saveInterview(interviewAnswers, authHeader(), language).then((success) => {
                    setSflModalHeaderText(undefined);
                    setSflModalBodyText(undefined);
                    if (success) {
                        setSavedInterview(true);
                        setSflModalHeaderText(t(SaveForLaterText.savedApplicationHeaderSuccess));
                        setSflModalBodyText(t(SaveForLaterText.savedApplicationTextSuccess) + sflApplicationExpirationDate() + '.');
                    }
                    else {
                        setSflModalHeaderText(t(SaveForLaterText.savedApplicationHeaderError));
                        setSflModalBodyText(t(SaveForLaterText.savedApplicationTextError));
                        setSaveForLaterError(SaveForLaterText.savedApplicationHeaderError);
                    }
                });
            }
        }
    }, [
        verificationInformation,
        additionalInformation,
        coverageElections,
        applicants,
        applicantTypes,
        medicalInformationAnswers,
        criticalIllnessAnswers,
        reflexiveAnswers,
        customerConfig,
    ]);
    const sflApplicationExpirationDate = () => {
        if (initialInformation) {
            return format(addDays(startOfToday(), initialInformation.employerConfiguration.preference.saveForLaterPeriod), 'MM/dd/yyyy');
        }
        else {
            return '';
        }
    };
    const setPrePopInfoAndInterviewAnswers = (prePopData, verificationData) => {
        // save verify info so the first screen populates & validates correctly
        setVerificationInformation({
            verificationSsn: verificationData.employeeSsn,
            verificationEmpid: verificationData.employeeId,
        });
        setPrePopInformation(prePopData);
        setApplicants(setPrePopApplicants(prePopData.applicants));
        if (prePopData.applicationReasonCode && prePopData.applicationReasonCode.trim().length > 0) {
            if (prePopData.prePopCoverage &&
                prePopData.applicationReasonCode.trim() === 'EEAE' &&
                !showAnnualEnrollment(initialInformation)) {
                setAdditionalInformation({
                    applicationReason: ApplicationReasonCode[prePopData.applicationReasonCode],
                    disableApplicationReason: true,
                    disableNewEmployee: true,
                });
            }
            else if (prePopData.applicationReasonCode.trim() === 'EEAE' && !showAnnualEnrollment(initialInformation)) {
                setAdditionalInformation({ disableApplicationReason: false, disableNewEmployee: false });
            }
            else {
                setAdditionalInformation({
                    applicationReason: ApplicationReasonCode[prePopData.applicationReasonCode],
                    disableApplicationReason: true,
                    disableNewEmployee: true,
                });
            }
        }
        else {
            setAdditionalInformation({ disableApplicationReason: false, disableNewEmployee: false });
        }
        setApplicantTypes({
            employee: prePopData.employeeCoverageCheckbox,
            spouse: prePopData.spouseCoverageCheckbox,
            dependent: prePopData.dependentCoverageCheckbox,
        });
        setCoverageElections(getCoverageElectionsFromPrePop(prePopData));
    };
    return (<EoiContext.Provider value={{
            eoiError,
            isVerificationPage,
            setIsVerificationPage,
            setEoiError,
            initialInformation,
            setInitialInformation,
            employeeInfo,
            setEmployeeInfo,
            customContent,
            setVerificationInformation,
            setAdditionalInformation,
            setCustomContent,
            interviewAnswers,
            setCoverageElections,
            prePopInformation,
            setPrePopInformation,
            setMedicalInformationAnswers,
            setCriticalIllnessAnswers,
            setReflexiveQuestions,
            reflexiveQuestions,
            setReflexiveAnswers,
            setApplicants,
            savedInterview,
            setSavedInterview,
            sflModalHeaderText,
            sflModalBodyText,
            setApplicantTypes,
            setPrePopInfoAndInterviewAnswers,
            questionSubmissionResponse,
            setQuestionSubmissionResponse,
            customerConfig,
            setCustomerConfig,
            saveForLaterError,
            setSaveForLaterError,
            displaySaveForLaterModal,
            setDisplaySaveForLaterModal,
            displaySaveForLaterError,
            setDisplaySaveForLaterError,
            setVerifyingUserIdentity,
            legalText,
            setLegalText,
            validationError,
            setValidationError,
            initializeInterviewAnswers,
        }}>
      {children}
    </EoiContext.Provider>);
};
export var ResponseFormatTypeCode;
(function (ResponseFormatTypeCode) {
    ResponseFormatTypeCode["I"] = "I";
    ResponseFormatTypeCode["LBL"] = "LBL";
    ResponseFormatTypeCode["SDC"] = "SDC";
    ResponseFormatTypeCode["MDC"] = "MDC";
    ResponseFormatTypeCode["NUM"] = "NUM";
    ResponseFormatTypeCode["DTE"] = "DTE";
})(ResponseFormatTypeCode || (ResponseFormatTypeCode = {}));
export var VerificationTypeCode;
(function (VerificationTypeCode) {
    VerificationTypeCode["BTH"] = "BTH";
    VerificationTypeCode["EID"] = "EID";
    VerificationTypeCode["SSN"] = "SSN";
})(VerificationTypeCode || (VerificationTypeCode = {}));
export var ICoverageType;
(function (ICoverageType) {
    ICoverageType["AMOUNT"] = "AMOUNT";
    ICoverageType["PERCENTAGE"] = "PERCENT";
    ICoverageType["MULTIPLE"] = "MULTIPLE";
})(ICoverageType || (ICoverageType = {}));
export var ApplicationReasonCode;
(function (ApplicationReasonCode) {
    ApplicationReasonCode["EELE"] = "EELE";
    ApplicationReasonCode["LFSC"] = "LFSC";
    ApplicationReasonCode["SALI"] = "SALI";
    ApplicationReasonCode["EEAE"] = "EEAE";
    ApplicationReasonCode["NENE"] = "NENE";
    ApplicationReasonCode["NEFT"] = "NEFT";
})(ApplicationReasonCode || (ApplicationReasonCode = {}));
