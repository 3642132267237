import React from "react";
export const EMPLOYEE_FAQ = [
    {
        label: {
            english: 'Why do I have to move my account?',
            spanish: '¿Por qué tengo que trasladar mi cuenta?'
        },
        value: {
            english: 'Lincoln’s moving all account login access to LincolnFinancial.com. After moving your MyLincolnPortal.com account, you can access all benefits with one login, either online or via the Lincoln Financial Mobile app. The app is available for download in the Apple and Google app stores.',
            spanish: 'Lincoln trasladará todos los accesos de inicio de sesión de la cuenta a LincolnFinancial.com. Después de trasladar su cuenta de MyLincolnPortal.com, puede acceder a todos los beneficios con un solo inicio de sesión, ya sea en línea o mediante la aplicación Lincoln Financial Mobile. La aplicación está disponible para descargar en las tiendas de aplicaciones de Apple y Google.'
        }
    },
    {
        label: {
            english: 'How do I move my account?',
            spanish: '¿Cómo traslado mi cuenta?'
        },
        value: {
            english: 'To move your account to LincolnFinancial.com, log in to MyLincolnPortal.com and follow the provided step-by-step instructions. After completing this process, you’ll access benefits by logging in at LincolnFinancial.com.',
            spanish: 'Para trasladar su cuenta a LincolnFinancial.com, inicie sesión en MyLincolnPortal.com y siga las instrucciones paso a paso. Después de completar este proceso, accederá a los beneficios iniciando sesión en LincolnFinancial.com.'
        }
    },
    {
        label: {
            english: 'Where do I register to access my benefits?',
            spanish: '¿Dónde me registro para acceder a mis beneficios?'
        },
        value: {
            english: <>If you haven’t previously registered to access your Lincoln benefits, register, and create a new account on LincolnFinancial.com or in the mobile app. You’ll need a company code, provided by your employer. <br /><br /><strong>If you already have LincolnFinancial.com credentials</strong>, no further steps are required. Log in on LincolnFinancial.com or on the mobile app for easy access to all your Lincoln benefits.</>,
            spanish: <>Si no se ha registrado antes para acceder a sus beneficios de Lincoln, regístrese y cree una nueva cuenta en LincolnFinancial.com o en la aplicación móvil. Necesitará un código de empresa que le dará su empleador. <br /><br /><strong>Si ya tiene credenciales de LincolnFinancial.com</strong>, no se necesita hacer nada más. Inicie sesión en LincolnFinancial.com o en la aplicación móvil para acceder fácilmente a todos sus beneficios de Lincoln.</>
        }
    },
    {
        label: {
            english: 'What’s a company code?',
            spanish: '¿Qué es un código de empresa?'
        },
        value: {
            english: 'If you’re part of a group employee benefits plan, your human resources or benefits representative may provide a unique company code for registration to help us easily identify your employer. Contact your employer for your company code.',
            spanish: 'Si forma parte de un plan colectivo de beneficios para empleados, su representante de recursos humanos o beneficios puede darle un código de empresa único para registrarse para ayudarnos a identificar fácilmente a su empleador. Comuníquese con su empleador para obtener el código de su empresa.'
        }
    },
    {
        label: {
            english: 'Why can’t I access my benefits on MyLincolnPortal.com?',
            spanish: '¿Por qué no puedo acceder a mis beneficios en MyLincolnPortal.com?'
        },
        value: {
            english: 'Once you move your account from MyLincolnPortal.com, access your benefits exclusively through LincolnFinancial.com. Use your new account information on LincolnFinancial.com or our mobile app for access to all your benefits.',
            spanish: 'Una vez que traslade su cuenta de MyLincolnPortal.com, acceda a sus beneficios exclusivamente mediante LincolnFinancial.com. Use la información de su nueva cuenta en LincolnFinancial.com o nuestra aplicación móvil para acceder a todos sus beneficios.'
        }
    },
    {
        label: {
            english: 'Why can’t I move my account?',
            spanish: '¿Por qué no puedo trasladar mi cuenta?'
        },
        value: {
            english: <><strong>If you already have LincolnFinancial.com credentials</strong>, no further steps are required. Log in on LincolnFinancial.com or on the mobile app for easy access to all your Lincoln benefits. <br /><br /><strong>If you don't have credentials on LincolnFinancial.com</strong>, register, and create a new account on LincolnFinancial.com or in the mobile app. You'll need a company code, provided by your employer.</>,
            spanish: <><strong>Si ya tiene credenciales de LincolnFinancial.com</strong>, no se necesita hacer nada más. Inicie sesión en LincolnFinancial.com o en la aplicación móvil para acceder fácilmente a todos sus beneficios de Lincoln. <br /><br /><strong>Si no tiene credenciales en LincolnFinancial.com</strong>, regístrese y cree una nueva cuenta en LincolnFinancial.com o en la aplicación móvil. Necesitará un código de empresa que le dará su empleador.</>
        }
    },
    {
        label: {
            english: 'Why can’t I see my other benefits when I log in to my account on LincolnFinancial.com?',
            spanish: '¿Por qué no puedo ver mis otros beneficios cuando inicio sesión en mi cuenta en LincolnFinancial.com?'
        },
        value: {
            english: <>If you have Lincoln benefits, such as dental, vision, or supplemental health coverage, and they’re not visible after logging in on LincolnFinancial.com, complete identify verification on the <strong>Account setup & access</strong> page in your profile. Once successfully completed, your information and accounts will be connected within 24 to 48 hours.</>,
            spanish: <>Si tiene beneficios de Lincoln, como cobertura dental, de la vista o de salud complementaria, y no están visibles después de iniciar sesión en LincolnFinancial.com, complete la verificación de identidad en la página <strong>Account setup & access (Configuración y acceso a la cuenta)</strong> en su perfil. Una vez completado con éxito, su información y sus cuentas se conectarán dentro de 24 a 48 horas.</>
        }
    },
    {
        label: {
            english: 'Why do I need to create a new username?',
            spanish: '¿Por qué necesito crear un nuevo nombre de usuario?'
        },
        value: {
            english: 'If prompted to create a new username, it may be because your previous one is in use or contains special characters not allowed on LincolnFinancial.com. Create a new username to log in and view your benefits online.',
            spanish: 'Si se le pide que cree un nuevo nombre de usuario, puede deberse a que el anterior está en uso o tiene caracteres especiales no permitidos en LincolnFinancial.com. Cree un nuevo nombre de usuario para iniciar sesión y ver sus beneficios en línea.'
        }
    },
    {
        label: {
            english: 'I need assistance — who do I contact?',
            spanish: 'Necesito ayuda — ¿con quién me comunico?'
        },
        value: {
            english: <>Contact our Web Support team at <strong>800-423-2765</strong> for assistance logging in or migrating your account.</>,
            spanish: <>Comuníquese con nuestro equipo de soporte web al <strong>800-423-2765</strong> para obtener ayuda para iniciar sesión o migrar su cuenta.</>
        }
    },
];
