import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { useAuth0 } from 'status/hooks';

import { Alert, Heading } from '@digitools/honest-react';

import { useTranslation } from 'react-i18next';

import EEFindMyInformation from '../../../../employeeProfile/components/EEFindMyInformation';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';

// Helper function to determine if we can show EOI
const showEOI = userMeta => {
  const applicationPermissions = userMeta.applicationPermissions;
  return applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
};

const FindMyInfo = () => {
  const { userMeta } = useAuth0();

  const { t } = useTranslation();

  const [fmiCompleted, setFmiCompleted] = useState(false);

  const { trackView } = useTealium();

  if (fmiCompleted) {
    window.location.assign('/customer/status/employee');
  }

  useEffect(() => {
    trackView({
      page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
      page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.FIND_MY_INFO,
    });
  }, []);

  return (
    <div>
      <Heading data-testid='status-header'>{t('statusHeader')}</Heading>
      {showEOI(userMeta) ? (
        <Alert data-testid={'info-alert'} type={'info'} aria-labelledby='info-alert'>
          <strong>{t('findMyInfoEOIStatusLinkText_1')}</strong>
          {t('findMyInfoEOIStatusLinkText_2')}
          <Link to='/status/employee/eoi'>{t('findMyInfoEOIStatusLinkText_3')}</Link>.
        </Alert>
      ) : null}
      <EEFindMyInformation onComplete={setFmiCompleted} noRedirect={true} />
    </div>
  );
};

export default FindMyInfo;
