import React from 'react';
import { Col, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import { Experience } from 'src/types/Experience';
const MotorVehicleAccidentIndicator = (props) => {
    const { absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const motorVehicleAccidentIndicatorLabel = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(formLabels.motorVehicleAccidentIndicatorEE)
        : t(formLabels.motorVehicleAccidentIndicatorER));
    return (<>
      <Row data-testid='motorVehicleAccidentIndicator' className='mb-3'>
        <Col xs={12}>
          <RadioGroup label={motorVehicleAccidentIndicatorLabel} field={'motorVehicleAccidentIndicator'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={experience === Experience.EMPLOYEE ? fieldRequired : undefined} initialValue={absenceData && absenceData.motorVehicleAccidentIndicator}>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-yes'} key={'hi-yes'} name='motorVehicleAccidentIndicator' label={t(AboutYourAbsenceText.formLabels.yes)} value={'Y'}/>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-no'} key={'hi-no'} name='motorVehicleAccidentIndicator' label={'No'} value={'N'}/>
          </RadioGroup>
        </Col>
      </Row>
    </>);
};
export default MotorVehicleAccidentIndicator;
