import React from 'react';
const AccessibilityTextEs = {
    heading: 'Declaración de accesibilidad',
    paragraph1: 'Lincoln Financial Group se compromete a garantizar la accesibilidad digital para las personas con discapacidad y estamos continuamente comprometidos en los esfuerzos para mejorar nuestra experiencia de usuario. La accesibilidad es una parte integral de la estrategia web general de LFG. LFG audita periódicamente la experiencia de cada usuario para identificar oportunidades para mejorar la accesibilidad.',
    paragraph2: (<>
      Si utiliza software de lector de pantalla o tecnología relacionada para obtener contenido web y tiene problemas
      para obtener el contenido de este sitio web, contáctenos al <a href='tel:+1-800-431-2958'>1-800-431-2958</a> para
      obtener ayuda.
    </>),
};
export default AccessibilityTextEs;
