import { Button, themes, StepperContext } from '@digitools/honest-react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CliText } from '../../constants/CliText';
import { DirectDepositInformationText } from '../../constants/DirectDepositInformationText';
import useCliDataContext from '../../hooks/useCliDataContext';
import useTranslate from '../../../../hooks/useTranslate';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useAuthentication from 'src/hooks/useAuthentication';
import { validateAccountDetails } from '../../api/CliApiCalls';
import PaymentMethodText from './PaymentMethod.Text';
const StyledValidateButton = styled(Button) `
  @media (max-width: 765px) {
    margin-bottom: 0.5rem;
  }
`;
const DirectDepositValidateButton = ({ disable, validateData, setAlert, paymentStepNumber }) => {
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const { isDirectDepositSuccess, setIsDirectDepositSuccess, setIsLoading, paymentMethodData, setPaymentMethodData } = useCliDataContext();
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    const DIRECT_DEPOSIT = 'directDeposit';
    const BANK_CHECK = 'bankCheck';
    const stepNumber = paymentStepNumber + 1;
    const getPaymentMethod = (pType) => {
        return pType === BANK_CHECK ? PaymentMethodText.bankCheck.english : PaymentMethodText.directDeposit.english;
    };
    const handleContinue = async () => {
        if (!isDirectDepositSuccess && validateData.paymentType == DIRECT_DEPOSIT) {
            setIsLoading(true);
            const submitObj = {
                paymentMethodData: {
                    accountNumber: validateData.accountNumber,
                    routingNumber: validateData.routingNumber,
                },
            };
            try {
                const { data, status } = await validateAccountDetails(submitObj, authHeader());
                if (status === 200) {
                    setIsLoading(false);
                    if (data && data.isValid) {
                        setPaymentMethodData({ ...paymentMethodData, bankName: data?.bankName });
                        setIsDirectDepositSuccess(true);
                        setAlert('success');
                    }
                    if (data && !data.isValid) {
                        setAlert('warning');
                        setIsDirectDepositSuccess(false);
                    }
                    window.scrollTo(0, 0);
                }
                else {
                    setError();
                }
            }
            catch (error) {
                setError();
            }
        }
        if (isDirectDepositSuccess || validateData.paymentType == BANK_CHECK || validateData.paymentType == '') {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                event_name: validateData.paymentType ? getPaymentMethod(validateData.paymentType) : '',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: 'payment method',
                event_status: 'step ' + stepNumber
            });
            stepper.next();
        }
    };
    const setError = () => {
        setIsLoading(false);
        setIsDirectDepositSuccess(false);
        setAlert('error');
        window.scrollTo(0, 0);
    };
    return (<StyledValidateButton disabled={validateData.paymentType == DIRECT_DEPOSIT ? disable : false} type={isDirectDepositSuccess ? 'submit' : 'button'} buttonType={'primary'} theme={themes.lincoln} data-testid='validate-continue' onClick={handleContinue}>
      {validateData.paymentType == DIRECT_DEPOSIT
            ? isDirectDepositSuccess
                ? t(CliText.paymentContinue)
                : t(DirectDepositInformationText.validatebuttonText)
            : t(CliText.paymentContinue)}
    </StyledValidateButton>);
};
export default DirectDepositValidateButton;
