export const LandingPageBannerText = {
    eePrimaryMessage: {
        english: 'Manage your benefits with confidence',
        spanish: 'Administre sus beneficios con confianza'
    },
    eeSecondaryMessage: {
        english: 'Workplace benefit solutions',
        spanish: 'Soluciones de beneficios en el lugar de trabajo'
    }
};
