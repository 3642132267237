import React, { createContext, useEffect, useRef, useState } from 'react';
import useExperience from '../hooks/useExperience';
import { Experience } from '../types/Experience';
import ConfigApi from '../api/ConfigApi';
import useAuthentication from '../hooks/useAuthentication';
import { communicationPreferences } from '../api/CommPrefApi';
import NavigationApi from '../api/NavigationApi';
import { useLocation } from 'react-router-dom';
export const CustomerConfigContext = createContext({
    mlpConfigs: undefined,
    isCommPrefEnabled: false,
    bannerMenu: undefined,
    navigationMenu: undefined,
    loadingNavMenu: false,
    currentExperience: '',
    isNewNav: false,
    setIsNewNav: () => { },
    currentIsNewNav: false,
});
export const CustomerConfigProvider = ({ children }) => {
    const { experience } = useExperience();
    const { authHeader, user } = useAuthentication();
    const [mlpConfig, setMlpConfig] = useState();
    const [isCommPrefEnabled, setCommPrefEnabled] = useState(false);
    const [navigationConfig, setNavigationConfig] = useState();
    const [loadingNavMenu, setloadingNavMenu] = useState(false);
    const [currentExperience, setCurrentExperience] = useState('');
    const sessionStorageNewNav = sessionStorage.getItem('isNewNav') == 'true' ? true : false;
    const [isNewNav, setIsNewNav] = useState(sessionStorageNewNav);
    const [currentIsNewNav, setCurrentIsNewNav] = useState(false);
    const location = useLocation();
    /*** Communication Preferences ***/
    useEffect(() => {
        (async function loadCommunicationPreferences() {
            try {
                if (user && user.customerDiv && user.customerSerial) {
                    const custDiv = user ? user.customerDiv || '' : '';
                    const custSeral = user ? user.customerSerial || '' : '';
                    const response = await communicationPreferences(custDiv, custSeral, authHeader());
                    // if either confirmatinos or notifications are active - "communicationsPreferences" feature is enabled
                    setCommPrefEnabled(JSON.parse(response.data.confirmationsActiveIndicator) ||
                        JSON.parse(response.data.notificationsActiveIndicator));
                }
            }
            catch (e) {
                setCommPrefEnabled(false);
            }
        })();
    }, [authHeader, user]);
    // TODO: Move to config provider - MOVED - need to cut over to use the provider
    const storeLocation = useRef(location);
    /*** Configurable Nav Menu -- only when logged in & experience changes ***/
    useEffect(() => {
        (async function loadCustomerNavigationMenu() {
            //Only want to call when going to home on the first time logging in, new experience or new customer. Refresh is taken into account on any page other then customer-search
            if (experience &&
                experience !== Experience.PUBLIC &&
                location.pathname !== '/customer-search' &&
                (!navigationConfig ||
                    currentExperience !== experience ||
                    storeLocation.current.pathname === '/customer-search' ||
                    currentIsNewNav !== isNewNav)) {
                setloadingNavMenu(true);
                var response = await NavigationApi.getCustomerNavigation(experience, isNewNav, authHeader());
                setNavigationConfig(response.data);
                setCurrentExperience(experience);
                setCurrentIsNewNav(isNewNav);
                sessionStorage.setItem('isNewNav', JSON.stringify(isNewNav));
            }
            setloadingNavMenu(false);
        })();
        storeLocation.current = location;
    }, [experience, location, isNewNav]);
    /*** MLP Customer Configurations ***/
    useEffect(() => {
        (async function loadCustomerMlpConfig() {
            if (experience && experience !== Experience.PUBLIC) {
                const response = await ConfigApi.getCustomerMlpConfig(experience, authHeader());
                setMlpConfig(response.data);
            }
        })();
    }, [authHeader, experience]);
    const customerConfigContext = {
        mlpConfigs: mlpConfig,
        isCommPrefEnabled: isCommPrefEnabled,
        bannerMenu: navigationConfig ? navigationConfig.bannerMenu : undefined,
        navigationMenu: navigationConfig ? navigationConfig.navigationMenu : undefined,
        loadingNavMenu,
        isNewNav,
        setIsNewNav,
    };
    return <CustomerConfigContext.Provider value={customerConfigContext}>{children}</CustomerConfigContext.Provider>;
};
export default CustomerConfigProvider;
