import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentBlock, Icon, themes, Link } from '@digitools/honest-react';
import { Experience } from '../../../../types/Experience';
import useTranslate from '../../../../hooks/useTranslate';
import useExperience from '../../../../hooks/useExperience';
import { ContentBlocksText } from './ContentBlocksText';
import styled from 'styled-components';
import useCustomerConfig from '../../../../hooks/useCustomerConfig';
import useAuthentication from '../../../../hooks/useAuthentication';
import { getPreferences } from '../../../../api/CommPrefApi';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { getEnvVariable } from 'src/utils/envUtils';
import { getWellnessSAMLURI } from 'src/api/AuthServiceApi';
const StyledContentBlocksContainer = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: left;
  padding: 2rem;
`;
const ContentBlocks = () => {
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { isCommPrefEnabled } = useCustomerConfig();
    const { authHeader } = useAuthentication();
    const navigate = useNavigate();
    const { user } = useAuthentication();
    const [communicationPreferencesData, setCommunicationPreferencesData] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const fetchData = async () => {
        try {
            const commPreferences = await getPreferences(authHeader());
            setCommunicationPreferencesData(commPreferences);
        }
        catch (error) {
            setIsError(true);
        }
    };
    useEffect(() => {
        fetchData();
    }, [authHeader]);
    const { trackEvent } = useTealium();
    const trackPageEvent = (eventName, eventAction) => {
        trackEvent({
            event_action: eventAction || TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.HOME_PAGE,
            event_version: TEALIUM_EVENT.EVENT_VERSION.LANDING_PAGE_BANNER
        });
    };
    const performWellnessRedirect = async () => {
        try {
            const res = await getWellnessSAMLURI(authHeader());
            window.open(res.data, '_blank')?.focus();
        }
        catch (error) {
            setIsError(true);
        }
    };
    const wellnessPathOnClick = async () => {
        trackPageEvent(ContentBlocksText.wellnessBlockLinkText.english);
        if (user?.isLfgUser && !user?.isIdentityVerified) {
            navigate('/prove');
            scrollTo(0, 0);
        }
        else {
            performWellnessRedirect();
        }
    };
    const commPrefLinkOnClick = async () => {
        trackPageEvent(ContentBlocksText.commPrefBlockLinkText.english);
        navigate('/profile/alertsNotifications');
        scrollTo(0, 0);
    };
    return (<>
    {experience === Experience.EMPLOYEE && user && user.applicationPermissions && user.applicationPermissions.indexOf('wpEE') > -1 && (<StyledContentBlocksContainer data-testid={`wellnesspath-block`}>
          <ContentBlock theme={themes.lincoln} imgSide={'left'} backgroundColor={'accent1'} imageAltText={'Laptop with wellnesspath snapshot'} imageUrl={getEnvVariable('mlpHomeUrl') + '/assets/images/wellnesspath-snapshot.jpg'} heading={t(ContentBlocksText.wellnessBlockHeadingText)} text={t(ContentBlocksText.wellnessBlockBodyText)} link={<>
                <Link onClick={() => wellnessPathOnClick()} data-testid={'wellnesspath-link'} className={'d-inline-block'}>
                  {t(ContentBlocksText.wellnessBlockLinkText)}
                  <Icon style={{ marginLeft: '0.25rem', position: 'relative', top: '2px' }} name={'chevron-right-solid'} color={'secondary'}/>
                </Link>
              </>} footerContent={t(ContentBlocksText.lcnNo)}/>
          
          
        </StyledContentBlocksContainer>)}
      {experience === Experience.EMPLOYEE &&
            isCommPrefEnabled &&
            !communicationPreferencesData?.confirmation &&
            !communicationPreferencesData?.notification && (<StyledContentBlocksContainer data-testid={`comm-pref-block`}>
            <ContentBlock theme={themes.lincoln} imgSide={'right'} backgroundColor={'accent1'} imageAltText={'Woman holding phone'} imageUrl={getEnvVariable('mlpHomeUrl') + '/assets/images/woman-on-phone.jpg'} heading={t(ContentBlocksText.commPrefBlockHeadingText)} text={t(ContentBlocksText.commPrefBlockBodyText)} link={<>
                  <Link onClick={() => commPrefLinkOnClick()} data-testid={'comm-prefs-link'} className={'d-inline-block'}>
                    {t(ContentBlocksText.commPrefBlockLinkText)}
                    <Icon style={{ marginLeft: '0.25rem', position: 'relative', top: '2px' }} name={'chevron-right-solid'} color={'secondary'}/>
                  </Link>
                </>} linkTestID={`comm-prefs-link`}/>
          </StyledContentBlocksContainer>)}
    </>);
};
export default ContentBlocks;
