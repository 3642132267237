import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
export const formatToUtc = (timestamp, _format) => format(utcToZonedTime(parseISO(new Date(timestamp).toISOString()), 'UTC'), _format, {
    timeZone: 'UTC',
});
export const sortDatesByDateAndTime = (a, b) => {
    const aUTC = formatToUtc(a, "yyyy-MM-dd p");
    const bUTC = formatToUtc(b, "yyyy-MM-dd p");
    if (aUTC < bUTC)
        return -1;
    if (aUTC > bUTC)
        return 1;
    return 0;
};
