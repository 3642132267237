import { Label } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { GrayBlock } from '../../components/LayoutComponents';
import { ReviewAccommodationDetailsText } from '../../constants/ReviewAccommodationDetailsText';
import useAsDataContext from '../../hooks/useAsDataContext';
const DisclaimerContent = () => {
    const { isDisclaimerAccepted, setIsDisclaimerAccepted } = useAsDataContext();
    const { t } = useTranslate();
    const pointerCursor = {
        cursor: 'pointer',
    };
    const labelStyle = {
        color: '#333333',
        fontWeight: 'normal',
        fontSize: '0.8125rem',
        paddingLeft: '0.3125rem',
        cursor: 'pointer',
    };
    return (<GrayBlock className={'mb-5 d-flex align-items-start align-items-lg-center'} data-testid='disclaimer-text'>
      <Label htmlFor='acknowledgement' className={'d-block'} style={labelStyle}>
        <input id={'acknowledgement'} data-testid='acknowledgement' type='checkbox' checked={isDisclaimerAccepted} onChange={(e) => setIsDisclaimerAccepted(e.currentTarget.checked)} style={pointerCursor}/>
        <span style={labelStyle}>
          {t(ReviewAccommodationDetailsText.disclaimerText)}
          <sup>1</sup>
        </span>
      </Label>
    </GrayBlock>);
};
export default React.memo(DisclaimerContent);
