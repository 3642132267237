import React from 'react';
import GenericErrorPage from './GenericErrorPage';
import UnauthorizedErrorPage from './UnauthorizedErrorPage';
import { UnauthorizedError } from '../../types/UnauthorizedError';
import LogRocket from 'logrocket';
class GenericErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorType: "" };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, errorMessage: error.message };
    }
    componentDidCatch(error, info) {
        console.error('Caught error', error, info);
        LogRocket.log('Caught error', { error, info });
        this.setState({
            hasError: true,
        });
    }
    render() {
        if (this.state.hasError && this.state.errorMessage === "Unauthorized Error" || this.state.error instanceof UnauthorizedError) {
            return <UnauthorizedErrorPage />;
        }
        else if (this.state.hasError) {
            return <GenericErrorPage />;
        }
        return this.props.children;
    }
}
export default GenericErrorBoundary;
