import { Box, Button, Col, Collapse, DatePickerV2, Icon, Row, TextInput, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { RIA_TEXT } from '../constants/RiaConstants';
import useRiaConfig from '../hooks/useRiaConfig';
import useRiaData from '../hooks/useRiaData';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { absenceConfigDateValidation, validateDateRange, validateDuration } from '../utils/validationUtils';
import { useArrayField, useFormApi } from 'informed';
import { RIA_VALIDATION_CONSTANTS } from '../constants/RiaValidationConstants';
import { RedBulletList } from 'src/components/Formatting/StyledComponents';
import { StyledCollapseHeading } from './LayoutComponents';
import styled from 'styled-components';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
const RemoveIcon = styled(Icon) `
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryDark} !important;
  margin-top: .6rem;
  @media (max-width: 575px) {
    position: relative;
    bottom: 2rem;
  }
`;
const AddButton = styled(Button) `
  color: ${({ theme }) => theme.palette.secondaryDark} !important;
  margin-top: 0;
  padding: 1rem 1rem;
  cursor: pointer;
`;
const RemoveButton = styled(Button) `
  padding-left: 1rem;
  padding-top: 0.75rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.secondaryDark} !important;
`;
const AbsenceDuration = () => {
    const { t } = useTranslate();
    const { riaConfig } = useRiaConfig();
    const { currentLeave, oldAbsences } = useRiaData();
    const formApi = useFormApi();
    const { add, fields } = useArrayField({ field: 'absenceDurations' });
    const [customizedReportingPolicyItem, setCustomizedReportingPolicyItem] = useState();
    const { trackEvent } = useTealium();
    useEffect(() => {
        let reportingPolicy;
        if (riaConfig?.reportingPolicyItems && riaConfig?.reportingPolicyItems?.length > 0) {
            reportingPolicy = riaConfig.reportingPolicyItems[0];
        }
        if (riaConfig?.customizeReportingPolicy && currentLeave?.ssdCde) {
            const customizedReportingPolicyItems = riaConfig?.reportingPolicyItems?.filter((reportingPolicyItem) => {
                return reportingPolicyItem.subsidiary === currentLeave?.ssdCde;
            });
            if (!customizedReportingPolicyItems || customizedReportingPolicyItems.length == 0 ||
                customizedReportingPolicyItems[0].numberOfEditingDaysFuture === null ||
                customizedReportingPolicyItems[0].numberOfEditingDaysPast === null ||
                customizedReportingPolicyItems[0].numberOfReportingDaysFuture === null ||
                customizedReportingPolicyItems[0].numberOfReportingDaysPast === null) {
                throw new Error();
            }
            if (customizedReportingPolicyItems && customizedReportingPolicyItems?.length > 0) {
                reportingPolicy = customizedReportingPolicyItems[0];
            }
        }
        if (reportingPolicy != undefined) {
            setCustomizedReportingPolicyItem(reportingPolicy);
        }
        if (fields.length == 0) {
            add();
        }
    }, [currentLeave]);
    let durationInterval = 1;
    if (customizedReportingPolicyItem?.durationInterval !== undefined && customizedReportingPolicyItem?.durationInterval !== null) {
        if (customizedReportingPolicyItem?.durationInterval === 0) {
            durationInterval = 60;
        }
        else {
            durationInterval = customizedReportingPolicyItem?.durationInterval;
        }
    }
    const absenceRangeDateValidation = (value) => {
        if (value && currentLeave) {
            return validateDateRange(value, currentLeave?.lveBeginDte, currentLeave?.lveEndDte);
        }
    };
    const absenceDateValidation = (value, isEditing) => {
        let error = absenceConfigDateValidation(value, customizedReportingPolicyItem, isEditing);
        if (!error) {
            error = absenceRangeDateValidation(value);
        }
        return error;
    };
    const isDuplicateDate = (value, inputIndex) => {
        const newAbsences = formApi.getValue('absenceDurations'); // not the perfect type but it overlaps at least
        let error = undefined;
        newAbsences.forEach((newAbsence, index) => {
            if (value.length > 0 && inputIndex !== index && newAbsence.absenceDate === value) {
                error = t(RIA_VALIDATION_CONSTANTS.duplicateDateError);
            }
        });
        oldAbsences.forEach((oldAbsence, j) => {
            if (value.length > 0 && oldAbsence.absenceDate === value) {
                error = t(RIA_VALIDATION_CONSTANTS.duplicateDateError);
            }
        });
        return error;
    };
    //FIXME: move this to the constants file as a function that returns the result translatable
    const reportingPolicyInstructions = t({
        english: `Absences must be reported up to ${customizedReportingPolicyItem?.numberOfReportingDaysPast} days in the past and up to ${customizedReportingPolicyItem?.numberOfReportingDaysFuture} days in the future.`,
        spanish: `Las ausencias deben informarse hasta ${customizedReportingPolicyItem?.numberOfReportingDaysPast} días en el pasado y hasta ${customizedReportingPolicyItem?.numberOfReportingDaysFuture} días en el futuro.`,
    });
    const formatDuration = (value) => {
        if (value[1] === ':') {
            return [/\d/, /:/, /[0-5]/, /[0-9]/];
        }
        else {
            return [/\d/, /\d/, ':', /[0-5]/, /[0-9]/];
        }
    };
    const handleDurationBlur = () => {
        const value = formApi.getValue('absenceDuration');
        const regex = /:0$/g;
        formApi.setValue('absenceDuration', value?.replace(regex, ':00'));
    };
    const handleOnClick = () => {
        add();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: t(RIA_TEXT.addNewAdditionalAbsence),
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'add or edit existing absences',
            transaction_id: currentLeave?.lveNum,
        });
    };
    const AbsencesHeading = () => <StyledCollapseHeading>{t(RIA_TEXT.addAbsencesHeader)}</StyledCollapseHeading>;
    return (<>
      <Box>
        <Collapse title={'Add new absences'} // For ADA
     titleComponent={AbsencesHeading} headerElemType={'h3'} type='alternate' isOpen={true} theme={themes.secureSite} headerLeftDisplay={() => (<Icon aria-hidden={true} style={{ fontSize: '2rem' }} name='view-request-status' className='mr-3' color='primary'/>)} color='accent1'>
          <RedBulletList role='region' aria-label='List of criteria for submitting a new absence'>
            <li className={`mb-3`}>
              <span>{t(RIA_TEXT.leaveDetailsConfirmation)}</span>
            </li>
            <li className={`mb-3`}>
              <span>{reportingPolicyInstructions}</span>
            </li>
            <li className={`mt-3 mb-3`}>
              <span>{t(RIA_TEXT.dateValidationHelpText)}</span>
            </li>
            <li className={`mt-3 mb-3`}>
              <span>{customizedReportingPolicyItem?.durationInterval === 0 ? t(RIA_TEXT.durationHourHelpText) : t(RIA_TEXT.durationValidationHelpText)(durationInterval)}</span>
            </li>
          </RedBulletList>

          {fields.map(({ field, key, remove }, i) => (<Row key={key}>
              <Col lg={4} md={5} sm={6} size={fields.length > 1 ? 11 : 12}>
                <DatePickerV2 data-testid='new-date' label={t(RIA_TEXT.dateLabel)} field={field + 'absenceDate'} placeholderText={t(RIA_TEXT.dateLabel)} hideLabel={true} validateOnBlur={true} validate={(value) => isDuplicateDate(value, i) || absenceDateValidation(value)} mask={dateMask}/>
              </Col>
              <Col lg={4} md={5} sm={6} size={fields.length > 1 ? 11 : 12}>
                <TextInput data-testid='new-duration' field={field + 'absenceDuration'} label={t(RIA_TEXT.durationLabel)} placeholder={t(RIA_TEXT.durationLabel)} 
        //@ts-ignore FIXME: typing error here
        formatter={formatDuration} validateOnBlur={true} onBlur={handleDurationBlur} validate={(value) => validateDuration(value, durationInterval, t)}/>
              </Col>
              {fields.length > 1 && (<>
                  <RemoveIcon data-testid='delete-absence-icon' id={'delete-absence-' + i} title={'Delete entry'} onClick={remove} name={'close'}/>
                  <RemoveButton data-testid='delete-absence-button' onClick={remove} theme={themes.lincoln}>
                      {t(RIA_TEXT.removeAbsence)}
                  </RemoveButton>
                </>)}
              <br />
            </Row>))}
          {fields.length < 5 && (<Row>
              <Col lg={4} md={5} sm={6} size={12}>
                <Icon style={{ marginTop: 'auto', marginBottom: 'auto' }} name={'add-something'} color={'secondaryDark'} data-testid='add-absence-icon' size='md'/> 
                <AddButton buttonType='text' type='button' onClick={handleOnClick} data-testid='add-absence-text' theme={themes.lincoln}>
                    {t(RIA_TEXT.addNewAdditionalAbsence)}
                </AddButton>
              </Col>
            </Row>)}
        </Collapse>
      </Box>
    </>);
};
export default AbsenceDuration;
