import React from 'react';
import { Row, Col, themes, Heading, Label } from '@digitools/honest-react';
import EditButton from './EditButton';
import SubsAndLocsViewModal from './subs-locs-model-view/SubsAndLocsViewModal';
const SubsAndLocsDetails = ({ selectedSubsAndLocs, availableSubsAndLocsCount, history }) => {
    const selectedMessage = availableSubsAndLocsCount === selectedSubsAndLocs.length ? 'All' : selectedSubsAndLocs.length;
    return (<div style={{ marginTop: '1.5rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }} data-testid={'section-div'}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {'Subsidiaries/locations'}
        </Heading>
        <Row>
          <Col>
            <Label style={{ marginLeft: '1rem' }}> {selectedMessage + ' selected'} </Label>
          </Col>
        </Row>
        <SubsAndLocsViewModal subsAndLocs={selectedSubsAndLocs}/>
        <Row>
          <Col>
            <EditButton history={history} path='/create-my-report'/>
          </Col>
        </Row>
      </div>
      <hr />
    </div>);
};
export default SubsAndLocsDetails;
