import { Button, Icon, themes, useOutsideClick } from '@digitools/honest-react';
import React, { useRef, useState } from 'react';
import { DropdownCloseButton, DropdownMenuContainer } from './DropdownMenuContainer';
import styled from 'styled-components';
const StyledDropDownButton = styled(Button) `
  height: auto;
  max-height: none;
`;
const DropdownMenu = ({ buttonStyle, menuStyle, label, children, dropdownPosition = 'center', ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const setButtonFocus = () => { buttonRef.current && buttonRef.current.focus(); };
    useOutsideClick([buttonRef, menuRef], () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });
    return (<div id='dropdown' style={{ position: 'relative' }} {...rest}>
      <StyledDropDownButton data-testid='dropdown-menu-button' aria-label={'dropdown menu button'} onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen); }} buttonType='text' ref={buttonRef} style={buttonStyle}>
        {label}
        <Icon style={{ color: themes.lincoln.palette.secondary, fontSize: 'inherit', marginLeft: '.25rem ' }} name={isOpen ? 'caret-up' : 'caret-down'}/>
      </StyledDropDownButton>
      <DropdownMenuContainer ref={menuRef} data-testid='dropdown-menu' style={menuStyle} dropdownPosition={dropdownPosition} isOpen={isOpen} onClick={(e) => { e.stopPropagation(); }}>
        <DropdownCloseButton data-testid='dropdown-close-button' aria-label='dropdown close button' onClick={() => { setIsOpen(false); setButtonFocus(); }}>
          <Icon theme={themes.lincoln} name='close-thin' size='xs'/>
        </DropdownCloseButton>
        <div id='dropdownChildren' data-testid='dropdown-children'>
          {children}
        </div>
      </DropdownMenuContainer>
    </div>);
};
export default DropdownMenu;
