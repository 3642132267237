import { Language } from '../../../../types/Language';
import React from 'react';
const usernameHelperText = (<ul className={'mt-0'}>
    <li className={'mt-0'}>El nombre de usuario debe contener 5 caracteres como mínimo</li>
    <li className={'mt-0'}>
      El campo de nombre de usuario acepta los siguientes caracteres:
      <ul style={{
        listStyleType: 'circle',
        marginLeft: '.5rem',
        marginTop: '0px',
    }}>
        <li className={'mt-0'}>Caracteres alfanuméricos (sin acento)</li>
        <li className={'mt-0'}>El carácter de subrayado (_)</li>
        <li className={'mt-0'}>El carácter más (+)</li>
        <li className={'mt-0'}>El carácter menos (-)</li>
        <li className={'mt-0'}>El caracter del punto (.) Un nombre de usuario no puede terminar en un punto.</li>
        <li className={'mt-0'}>No se permiten otros caracteres / símbolos</li>
      </ul>
    </li>
  </ul>);
export const RegistrationDisplayTextEs = {
    language: Language.SPANISH,
    pageHeading: 'Registro de Cuenta',
    registrationInfoTextCompanyCode: 'Registrarse es fácil. Ingrese el código de su empresa y valide para continuar el registro.',
    registrationInfoTextRegistrationForm: 'Una vez que se haya registrado, puede usar todas las funciones disponibles en el portal financiero seguro de Lincoln Financial Group, según la configuración de su empleador.',
    companyCodeToolTip: 'Para ayuda con el código de la compañía, empleados—comuníquense con su gerente de recursos humanos o su representante de beneficios. Empleadores—comuníquense con su representante de servicios de la cuenta de Lincoln Financial Group. Para más ayuda llame al 1-800-431-2958.',
    companyCodeInstructions: 'Ingrese el código de su compañía y valide para continuar el registro',
    companyCodeLabel: 'Código de Compañía',
    companyCodeError: 'Empresa código de acceso no válido.',
    employeeCompanyCodeError: 'Los empleados utilizan su código de empresa para registrarse en www.LincolnFinancial.com.',
    fieldRequired: 'Este campo es obligatorio.',
    validateButton: 'Validar',
    validationServiceError: 'El sistema ha experimentado un error inesperado. Su código de empresa no puede ser validado en este momento. Inténtalo de nuevo.',
    agreeToPrivacyStatement: 'He leído y acepto lo siguiente:',
    cancelButtonLabel: 'Cancelar',
    cancelModalBodyText: 'Si cancela ahora, la información que ingresó se perderá y su perfil de usuario no se creará. Como resultado, no podrá iniciar sesión en el sitio seguro. Si desea abandonar esta página, seleccione OK. Si desea volver a crear su perfil de usuario, seleccione Cancelar.',
    cancelModalHeading: '¿Está seguro?',
    closeButtonText: 'Cerrar',
    duplicateEmailError: 'Dirección de correo electrónico ya está en uso.',
    duplicateUserNameError: 'Usuario no disponible.',
    emailErrorMessage: 'La dirección de correo electrónico no es válida.',
    emailToolTip: 'Ingrese una dirección de correo electrónico a la que pueda acceder cuando no esté en el trabajo, para que podamos enviarle información sobre su cuenta. Si necesita una dirección de correo electrónico, visite www.yahoo.com o www.gmail.com. Su dirección de correo electrónico no será compartida o utilizada con fines comerciales.',
    employerNameLabel: 'Código de Compañía',
    firstName: 'Primer nombre',
    firstNameErrormessage: 'El primer nombre no es válido.',
    hide: 'Esconder',
    lastName: 'Apellido',
    lastnameErrorMessage: 'El apellido no es válido.',
    loginInfoHeading: 'Información para ingresar',
    nonApprovedCountryError: 'Este país no está aprobado para la autenticación de dos factores. Por favor contacte a asistencia técnica.',
    okButton: 'Bien',
    passwordEqualMessage: 'Las contraseñas deben coincidir.',
    passwordErrorMessage: 'Ingrese una contraseña válida',
    personalInfoHeading: 'Informacion Personal',
    privacyStatementAgreeRequired: 'Debe aceptar la Declaración de privacidad y el Acuerdo web para continuar.',
    phoneErrorMessage: 'Este número de teléfono no es válido.',
    phoneNumber: 'Teléfono preferido ',
    preferredEmailAddress: 'Correo electrónico preferido',
    pwdLabel: 'Contraseña',
    pwdusernameErrorMessage: 'El nombre de usuario y la contraseña no pueden ser iguales.',
    reenterPwdLabel: 'Re-escribir Contraseña',
    registerButtonLabel: 'Registro',
    registerServiceError: 'El sistema ha experimentado un error inesperado. Su registro no puede ser procesado en este momento. Inténtalo de nuevo.',
    show: 'Show',
    termsAndConditionsHeader: 'Ver declaración de privacidad y acuerdo web',
    usernameErrormessage: 'Ingrese un nombre de usuario válido',
    usernameEmailErrorMessage: 'El nombre de usuario o correo electrónico que ingresó ya está asociado a una cuenta.',
    usernameHelperText,
    usernameLabel: 'Nombre de usuario',
    usernameToolTip: [
        'The username field accepts the following characters',
        '•Alphanumeric characters (without accent marks)',
        '•The underscore (_) character',
        '•The minus (-) character',
        '•The period (.) character. A username cannot end with a period.',
        '•No other characters/symbols are allowed',
    ],
    pwHelperText: [
        `Las contraseñas deben tener al menos ocho caracteres de longitud`,
        `Las contraseñas deben contener caracteres de tres o más de las siguientes categorías:`,
        `Aracteres en mayúsculas (de la A a la Z)`,
        `Caracteres en minúscula (de la A a la Z)`,
        `Dígitos numéricos (0 a 9)`,
        `Un carácter especial (como!@#$%^&*)`,
        `Las contraseñas no deben contener un espacio`,
    ],
    pwHelperTextMigration: [
        `8-40 caracteres de longitud`,
        `Al menos 3 de los siguientes:`,
        `Letras minúsculas (a-z)`,
        `Letras mayúsculas (A-Z)`,
        `Números (0-9)`,
        `Caracteres especiales (ej. !@#)`,
        `No puede contener su nombre de usuario`,
        `No puede contener un espacio`,
    ],
    usernameHelperTextMigration: [
        `De 5 a 35 caracteres de longitud`,
        `Al menos 1 letra`,
        `No puede contener caracteres especiales ni espacios`,
        `No pueden ser 9 números consecutivos`,
    ],
    serviceError: 'El sistema ha experimentado un error inesperado. Su registro no puede ser procesado en este momento.',
    weakPasswordErrorMessage: 'Elija una contraseña más segura.',
};
