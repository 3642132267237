import React from 'react';
import { Scope, useFormState } from 'informed';
import LastDayWorked from '../components/LastDayWorked';
import AdmissionDate from '../components/AdmissionDate';
import SurgeryDate from '../components/SurgeryDate';
import ClaimBeginDate from '../components/ClaimBeginDate';
import IncidentDate from '../components/IncidentDate';
import HospitalizationIndicator from '../components/HospitalizationIndicator';
import WorkRelatedIndicator from '../components/WorkRelatedIndicator';
import AccidentIndicator from '../components/AccidentIndicator';
import MotorVehicleAccidentIndicator from '../components/MotorVehicleAccidentIndicator';
import SurgeryIndicator from '../components/SurgeryIndicator';
import ReleaseDate from 'src/packages/cli/pages/aboutYourAbsence/components/ReleaseDate';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import LeaveDates from '../components/LeaveDates';
import SurgeryType from '../components/SurgeryType';
import OtherSurgery from '../components/OtherSurgery';
import SurgeryComplication from '../components/SurgeryComplication';
import { getEnvVariable } from 'src/utils/envUtils';
const IllnessInjuryFlow = () => {
    const { values } = useFormState();
    /* Conditional Field Display Logic */
    const shouldDisplayIllnessInjury = values?.absenceData?.claimantCondition === 'I' || values?.absenceData?.claimantCondition === 'S';
    const shouldDisplayIncidentDate = shouldDisplayIllnessInjury && values?.absenceData?.claimantCondition === 'I';
    const shouldDisplayMotorVehicleAccidentIndicator = values.absenceData?.accidentIndicator === 'Y';
    const shouldDisplayAdmissionDate = values.absenceData?.hospitalizationIndicator === 'Y';
    const shouldDisplaySurgeryDate = values.absenceData?.surgeryIndicator === 'Y';
    const shouldDisplaySurgeryType = values.absenceData?.leaveType === LeaveType.OWN_ILLNESS && values?.absenceData?.claimantCondition && values?.absenceData?.surgeryIndicator;
    const shouldDisplayOtherSurgery = (getEnvVariable('enableNewSurgeryFlow') ? values.absenceData?.injuryTier === 'Other' : ((values.absenceData?.surgerySubTier || values.absenceData?.injuryTier) === 'Other'));
    const shouldDisplayReleaseDate = shouldDisplayAdmissionDate && values.absenceData?.admissionDate && values.absenceData?.admissionDate.length === 10;
    const shouldDisplayLeaveDates = values?.absenceData?.leaveType === LeaveType.OWN_ILLNESS && values?.absenceData?.leaveReason;
    return (<>
      {shouldDisplayLeaveDates && <LeaveDates />}
      {shouldDisplayIllnessInjury && (<div data-testid='illness-injury-flow'>
          {/* @ts-ignore */}
          <Scope scope='absenceData'>
            {/* incidentDate - if condition = injury */}
            {shouldDisplayIncidentDate && <IncidentDate />}

            {/* claimBeginDate - Display after incidentDate if that should show, otherwise show first */}
            {<ClaimBeginDate />}

            {/* lastDayWorked */}
            {<LastDayWorked />}

            {/* accidentIndicator - // ER OPTIONAL */}
            {<AccidentIndicator />}
            {/* motorvehicleAccidentIndicator */}
            {shouldDisplayMotorVehicleAccidentIndicator && <MotorVehicleAccidentIndicator />}

            {/* workRelatedIndicator */}
            {<WorkRelatedIndicator />}

            {/* hospitalizationIndicato - // ER OPTIONAL */}
            {<HospitalizationIndicator />}
            {/* admissionDate - show if hospitalized is Y */}
            {shouldDisplayAdmissionDate && <AdmissionDate />}

            {/* ReleaseDate */}
            {shouldDisplayReleaseDate && <ReleaseDate />}

            {/* surgeryIndicator */}
            {<SurgeryIndicator />}
            {/* surgeryDate - show if surgery indicator is Y */}
            {shouldDisplaySurgeryDate && <SurgeryDate />}
            {/* surgeryType - show if leaveType is OWN */}
            {shouldDisplaySurgeryType && <SurgeryType shouldDisplayOtherSurgery={shouldDisplayOtherSurgery}/>}
            {/* otherSurgery - show if surgeryType is OTH */}
            {shouldDisplayOtherSurgery && <OtherSurgery />}
            {/* surgeryComplication */}
            {<SurgeryComplication />}
          </Scope>
        </div>)}
    </>);
};
export default IllnessInjuryFlow;
