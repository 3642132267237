import React, { useLayoutEffect, useState } from 'react';
import useAuthentication from '../../../../hooks/useAuthentication';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import Review from './Review';
import Sign from './Sign';
const ReviewAndSignContainer = (props) => {
    const { user } = useAuthentication();
    const { trackClickWithPage } = useGoogleAnalytics();
    const [step, setStep] = useState(0);
    const next = () => {
        trackClickWithPage('continue', '/customer/eoi/review');
        setStep(1);
    };
    const back = () => {
        trackClickWithPage('back', '/customer/eoi/sign');
        setStep(0);
    };
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);
    return step === 0 ? <Review nextStep={next}/> : <Sign back={back} setIsSubmitted={props.setIsSubmitted}/>;
};
export default ReviewAndSignContainer;
