import { default as React } from 'react';
import { Alert, Col, Row } from '@digitools/honest-react';
import CaseManager from './CaseManager';
import { useLanguage, useExperience } from 'status/hooks';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getClaimStatusDefinition from 'status/utilities/getClaimStatusDefinition';
import { encode } from 'status/utilities/hash';
import Glossary from 'status/components/Glossary/Glossary';
import ClaimInfoCard from './ClaimInfoCard';

const ClaimError = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No claim found.'
    ) : (
      <span>
        <strong>Error!</strong> Something went wrong when loading claim details.
      </span>
    )}
  </Alert>
);

const WatchListError = () => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong> Cannot load or modify watchlist at this moment.
    </span>
  </Alert>
);

// status-service is converting the productTypes returned from S1 in the helper-library PaidLeaveProductCodeEnum.java
// Need to convert it back here to determine Status Reason.
const getConvertedProductType = productType => {
  if (productType === 'WAPL') {
    return 'PLVA';
  }
  if (productType === 'MAPL') {
    return 'PLVB';
  }
  return productType;
};

const BasicInfo = ({
  params,
  claim,
  empNum,
  associatedLeave,
  prodAttCodes,
  statusDefinitionList,
  updateStatus,
  isSmallMidCustomer,
  claimId,
}) => {
  const { language } = useLanguage();
  const { experience } = useExperience();

  const productAttribute = getProductAttributeCode(
    language,
    prodAttCodes,
    'Claim',
    'Status',
    claim.clmSttusCde,
    isSmallMidCustomer,
  );
  updateStatus({ status: productAttribute });

  const statusDef = getClaimStatusDefinition(
    language,
    statusDefinitionList,
    getConvertedProductType(claim.prodType),
    claim.clmSttusCde,
    claim.clmClosureReasCde,
    claim.clmPendReasCde,
    isSmallMidCustomer,
  );

  const expPrefix = experience === 'EE' ? 'employee' : 'employer';

  if (associatedLeave) {
    associatedLeave.to = `/status/${expPrefix}/${encode(empNum)}/leaves/${encode(associatedLeave.lveNum)}/details`;
    if (params.search) {
      associatedLeave.to = `${associatedLeave.to}?search=${params.search}&searchCrit=${params.searchCrit}`;
    }
    if (params.from) {
      associatedLeave.to = `${associatedLeave.to}?from=${params.from}`;
    }
  }

  return (
    <ClaimInfoCard 
      claim={claim}
      associatedLeave={associatedLeave}
      productAttribute={productAttribute}
      statusDef={statusDef}
      claimId={claimId}
      empNum={empNum}
    />
  );
};

const ClaimInfo = ({
  claim,
  employeeName,
  params,
  leaveData,
  empNum,
  error,
  watchlistError,
  prodAttCodes,
  statusDefinitionList,
  claimId,
  updateStatusCallback,
  isSmallMidCustomer,
}) => {

  if (error) {
    return <ClaimError error={error} />;
  }

  let associatedLeave;

  if (leaveData) {
    // We need to do shitty stuff and find the matching leave gross but we have to
    const leaves = [...leaveData._embedded['leave-claims'], ...leaveData._embedded.leaves];
    associatedLeave = leaves.filter(elem => elem.coordClmNum === claim.clmNum)[0];
  }
  const updateStatus = status => {
    if (updateStatusCallback) {
      updateStatusCallback(status);
    }
  };

  return (
    <>
      {watchlistError && <WatchListError />}
      <Row data-public={true} style={{ marginBottom: '1rem', marginTop: '1.5rem' }}>
        <Col md={9} xs={12} xl={8}>
          <BasicInfo
            claim={claim}
            empNum={empNum}
            params={params}
            associatedLeave={associatedLeave}
            statusDefinitionList={statusDefinitionList}
            prodAttCodes={prodAttCodes}
            updateStatus={updateStatus}
            isSmallMidCustomer={isSmallMidCustomer}
            watchlistError={watchlistError}
            claimId={claimId}
          />
        </Col>
        <Col md={3} xs={12} xl={4}>
          <CaseManager
            caseManager={claim.caseManager}
            claimNum={claim.clmNum}
            offNum={claim.clmOffNum}
            employeeName={employeeName}
            prodType={claim.prodType}
          />
          <Glossary />
        </Col>
      </Row>
    </>
  );
};

export default ClaimInfo;
