import React from 'react';
import { Row, Col, TextInput } from '@digitools/honest-react';
import useValidPassword from 'src/hooks/fieldValidation/useValidPassword';
import useValidReEnteredPassword from 'src/hooks/fieldValidation/useValidRenteredPassword';
import { getEnvVariable } from 'src/utils/envUtils';
const passwordHelperText = (isLfg, pwHelperText) => {
    const enableEmployeeMigration = getEnvVariable('enableEmployeeMigration');
    return (<>
      {enableEmployeeMigration && isLfg ? (<ul className={'mt-0'}>
          <li className={'mt-0'}>{pwHelperText[0]}</li>
          <li className={'mt-0'}>
            {pwHelperText[1]}
            <ul style={{
                listStyleType: 'circle',
                marginLeft: '.5rem',
                marginTop: '0px',
            }}>
              <li style={{ marginTop: '0px' }}>{pwHelperText[2]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[3]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[4]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[5]}</li>
            </ul>
          </li>
          <li className={'mt-0'}>{pwHelperText[6]}</li>
          <li className={'mt-0'}>{pwHelperText[7]}</li>
        </ul>) : (<ul className={'mt-0'}>
          <li className={'mt-0'}>{pwHelperText[0]}</li>
          <li className={'mt-0'}>
            {pwHelperText[1]}
            <ul style={{
                listStyleType: 'circle',
                marginLeft: '.5rem',
                marginTop: '0px',
            }}>
              <li style={{ marginTop: '0px' }}>{pwHelperText[2]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[3]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[4]}</li>
              <li style={{ marginTop: '0px' }}>{pwHelperText[5]}</li>
            </ul>
          </li>
          <li className={'mt-0'}>{pwHelperText[6]}</li>
        </ul>)}
    </>);
};
const Password = ({ ...props }) => {
    const { language, disableForm, getLocalDisplayText } = props;
    const validatePassword = useValidPassword(true, props.isLfg);
    const validateReEnterPassword = useValidReEnteredPassword(true);
    const colSize = props.colSize ? props.colSize : 6;
    return (<Row className={props.marginNone ? 'mt-0' : 'mt-3'}>
      <Col sm={colSize} className={'mb-3 mb-sm-0'}>
        <TextInput ariaLabelText={'Password'} label={props.pwdLabel ? props.pwdLabel : getLocalDisplayText('pwdLabel')} field='pwd' type={'password'} toggleShowPassword={true} validateOnChange={true} validateOnBlur={true} disabled={disableForm} validate={validatePassword} helperText={passwordHelperText(props.isLfg, getLocalDisplayText(props.isLfg ? 'pwHelperTextMigration' : 'pwHelperText'))} notify={['reenterpwd']} maxLength={35} success={true} language={language} data-testid={'password'} data-private={true} onChange={props.setPassword ? (e) => props.setPassword(e.target.value) : props.clearError}/>
      </Col>
      <Col sm={colSize}>
        <TextInput ariaLabelText={'Re-enter Password'} label={props.reenterPwdLabel ? props.reenterPwdLabel : getLocalDisplayText('reenterPwdLabel')} field='reenterpwd' type={'password'} toggleShowPassword={true} validateOnChange={true} validateOnBlur={true} disabled={disableForm} validate={validateReEnterPassword} notify={['pwd']} maxLength={35} success={true} language={language} data-testid={'reEnteredPassword'} data-private={true} onChange={props.clearError}/>
      </Col>
    </Row>);
};
export default Password;
