export const IMfaTroubleshootDisplayTextEn = {
    linkText: 'Troubleshoot issues',
    heading: 'Not receiving codes by SMS?',
    body: 'SMS is a best effort protocol, meaning our carrier partners will do its best effort to deliver the message to handsets but there may be failures. Sometimes due to high network traffic or spam filters users won\'t receive the text message.',
    stepsTitle: 'There are some things users can try to improve the chances of receiving a code:',
    step1Title: 'Try with a Phone Call',
    step1Text: 'Phone calls usually arrive much faster. If SMS are not arriving, try receiving the token via phone call. Note, this will require a live phone not behind an extension or going straight to voicemail, as you will need to enter codes into the phone.',
    step2Title: 'Try with the Authy Mobile App',
    step2Text: 'The app allows you to generate a code on demand at any time, as long as you have internet access. No need to wait for an SMS or voice call!',
    step3Title: 'Check with your carrier',
    step3Text: 'Some cell providers may actually be pretty helpful in diagnosing the problem if you ask. For example, some US Sprint customers may not realize their phone plan default denies SMS from \'short codes\' Authy may be using. You can try texting \'Allow 78156\' to the number 9999 to see if Authy\'s primary US short code 78156 can reach your handset after being approved by you. Similarly, T-Mobile users may have opted out of this type of message without knowing it, and these messages might be blocked.',
};
