export const EEMigrationLoginText = {
    timeToMoveAccount: {
        english: `Employees, it’s time to move your account`,
        spanish: `Empleados, es hora de mover su cuenta`,
    },
    loginToBegin: {
        english: `Log in to begin the move`,
        spanish: `Inicia sesión para comenzar la mudanza`,
    },
    newLoginUrlInfo: {
        english: <>Where you log in to access your Lincoln benefits is changing. After completing the move, you will log in at </>,
        spanish: <>El lugar donde inicia sesión para acceder a sus beneficios Lincoln está cambiando. Después de completar la mudanza, iniciará sesión en </>,
    },
    newLoginUrlInfo2: {
        english: ` or on our mobile app.`,
        spanish: ` o en nuestra aplicación móvil.`
    },
    additionalLoginFailedInfo: {
        english: `If you are an employee and already moved your account, please log in at `,
        spanish: `If you are an employee and already moved your account, please log in at `,
    },
    lincolnLogInText: {
        english: 'If you log in to LincolnFinancial.com today or need to register a new ID, go directly to ',
        spanish: 'Si inicia sesión en LincolnFinancial.com hoy o necesita registrar una nueva identificación, vaya directamente a '
    },
    whereToLogIn: {
        english: `Not sure if you are in the right place? `,
        spanish: `¿No estás segura de dónde iniciar sesión? `,
    },
    whereToLogIn2: {
        english: `Not sure where to log in? `,
        spanish: `¿No está seguro de adónde ir? `,
    },
    needMoreInfo: {
        english: 'Need more information?',
        spanish: '¿Necesitas más información?'
    },
    learnMore: {
        english: `Learn more`,
        spanish: 'Aprende más',
    },
    signinToAccount: {
        english: `Sign into my account`,
        spanish: `Iniciar sesión en mi cuenta`,
    },
    noOnlineAccount: {
        english: `Don't have an online account?`,
        spanish: `¿No tienes una cuenta en línea?`,
    },
    newErOrBroker: {
        english: `Employers & Brokers, `,
        spanish: `Empleadores y corredores, `,
    },
    registerNow: {
        english: `Register now.`,
        spanish: `Regístrate ahora.`,
    },
    registerNow2: {
        english: `register now.`,
        spanish: `regístrate ahora.`,
    },
    emailVerificationHelp: {
        english: `Never received a`,
        spanish: `¿Nunca has recibido un correo electrónico de `,
    },
    emailVerificationLink: {
        english: `verification email?`,
        spanish: `verificación?`,
    },
    whereToStart: {
        english: 'Not sure where to start?',
        spanish: '¿No estás seguro por dónde empezar?'
    },
    needHelp: {
        english: 'We are here to help you get to the right place.',
        spanish: 'Estamos aquí para ayudarle a llegar al lugar correcto.'
    },
    getHelp: {
        english: 'Let us help',
        spanish: 'Dejanos ayudarte' //TODO: update
    },
    getHelp2: {
        english: 'Let us help you.',
        spanish: 'Podemos ayudarlo.'
    },
};
