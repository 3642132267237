import { Button, Col, Row } from '@digitools/honest-react';
import React from 'react';
import CancelButton from '../../CancelButton';
import { useNavigate } from 'react-router-dom';
const ReportDetailFooter = ({ runReport, reportCount, reportCountError }) => {
    const navigate = useNavigate();
    return (<div style={{ marginTop: '20px', marginBottom: '1.5rem' }}>
      <Row>
        <Col>
          <Button onClick={() => navigate('/create-my-report/report-field')} buttonType={'secondary'}>
            &larr;Back
          </Button>
          <Button buttonType={'primary'} style={{ float: 'right' }} onClick={runReport} disabled={reportCount === '0' || reportCountError}>
            Run Report
          </Button>
          <div style={{ float: 'right' }}>
            <CancelButton url={'/create-my-report'}/>
          </div>
        </Col>
      </Row>
    </div>);
};
export default ReportDetailFooter;
