export const getLabels = (customLabels, language) => {
    const customFields = {};
    customLabels.map(obj => {
        if (obj.title === 'EmployeeIDText') {
            customFields.employeeId = language === 'en' ? obj.value_EN : obj.value_ES || 'Employee ID';
        }
        if (obj.title === 'EmployeeText') {
            customFields.employee = language === 'en' ? obj.value_EN : obj.value_ES || 'Employee';
        }
    });
    return customFields;
};
export const camelize = function (str) {
    str = str.toLowerCase();
    str = str.trim();
    str = findAndReplace(str, ' ');
    str = findAndReplace(str, '-');
    return str;
};
const findAndReplace = function (str, replaceChar) {
    while (str.indexOf(replaceChar) != -1) {
        str = str.replace(replaceChar + str[str.indexOf(replaceChar) + 1], str[str.indexOf(replaceChar) + 1].toUpperCase());
    }
    return str;
};
