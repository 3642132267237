import { Button, Col, Row, Heading, TabContainer } from '@digitools/honest-react';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import EmployeeTab from './components/EmployeeTab';
import EmployerTab from './components/EmployerTab';
import { useNavigate } from 'react-router-dom';
import { LearnMoreText } from './constants/LearnMoreText';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import { ExperienceContext } from 'src/providers/ExperienceProvider';
import useTranslate from 'src/hooks/useTranslate';
import useAuthentication from 'src/hooks/useAuthentication';
import { BELOW_LARGE_DESKTOP, BELOW_MEDIUM_DESKTOP, MOBILE } from 'src/constants/ScreenSizeConstants';
const Banner = styled.div `
    background-image: linear-gradient(to right, rgba(173, 17, 43, 1) 65%, rgba(242, 0, 29, 1) 100%);
    color: white;
    margin-top: 30px;
    min-height: auto;
    padding: 20px 25px;
    @media (min-width: ${BELOW_MEDIUM_DESKTOP}) {
        & {
            min-height: 365px;
            padding: 40px 45px;
        }
    }
    a {
        color: white;
    }
    button {
        border-color: rgba(159,38,48,1);
        color: rgba(159,38,48,1);
        margin-top: 1rem;
        @media (max-width: ${MOBILE}) {
            & {
                min-width: max-content;
            }
        }
        @media (min-width: 768px) {
            & {
                margin-top: 0;
                min-width: max-content;
                margin-left: -1.5rem;
            }
        }
    }
`;
const StyledFAQContainer = styled.div `
    background: white;
    min-height: 535px;
    margin-top: 2.5rem;
    @media (min-width: ${BELOW_MEDIUM_DESKTOP}) {
        & {
            margin: 0 44px;
            position: relative;
            top: -40px
            padding: 35px
            border: 1px solid rgba(222, 222, 222, 1);
            border: none
        }
    }
    @media (min-width: ${BELOW_LARGE_DESKTOP}) {
        top: -50px;
    }
`;
const StyledTabContainer = styled(TabContainer) `
    @media (min-width: ${BELOW_MEDIUM_DESKTOP}) {
        & {
            border: none;
        }
    }
`;
const StartMoveButton = styled(Button) `
    @media (min-width: ${BELOW_MEDIUM_DESKTOP}) {
        max-width: 260px;
    }
`;
const LearnMore = () => {
    const navigate = useNavigate();
    const { trackView } = useTealium();
    const { t } = useTranslate();
    const { language, experience } = useContext(ExperienceContext);
    const { user } = useAuthentication();
    useEffect(() => {
        setTimeout(() => {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MIGRATION_LEARN_MORE,
                page_l4: (user?.roles && user.roles.includes('employee') ? 'employee' : 'tbd'),
                page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.LEARN_MORE,
            });
        }, 1000);
    }, [user?.roles]);
    const handleOnClick = () => {
        // EE only page - go to EE login
        navigate(`/public/login?employee=true`);
    };
    return (<>
            <Banner>
                <Row>
                    <Col md={7} xl={6}>
                        <Row>
                            <Heading elemType={'h1'} elemStyle={'h1'} color="white">{t(LearnMoreText.timeToMove)}</Heading>
                        </Row>
                        <p> {t(LearnMoreText.benefitAccountChange)} </p>
                        <p> {t(LearnMoreText.login)} </p>
                        <p>{t(LearnMoreText.alreadyMoved)}</p>
                    </Col>
                    <Col md={language === 'en' ? 3 : 5} xl={4} offset={`${language === 'en' ? 'md-2' : 'xl-2'}`} className={'d-flex flex-column justify-content-end align-items-end'}>
                        <StartMoveButton buttonType="secondary" size="medium" className={'d-block w-100'} onClick={handleOnClick}>
                                {t(LearnMoreText.startMove)}
                        </StartMoveButton>
                    </Col>
                </Row>
            </Banner>
            <StyledFAQContainer>
                <Heading elemType='h2' elemStyle='h2'> {t(LearnMoreText.faq)}</Heading>
                <StyledTabContainer data-testid='FAQTabContainer'>
                    <EmployeeTab />
                    <EmployerTab />
                </StyledTabContainer>
            </StyledFAQContainer>
        </>);
};
export default LearnMore;
