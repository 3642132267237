import { Spinner } from '@digitools/honest-react';
import axios from 'axios';
import saveAs from 'file-saver';
import React from 'react';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import withAuthentication from '../../hocs/withAuthentication';
import withExperience from '../../hocs/withExperience';
import withTealium from '../../hocs/withTealium';
import withUnexpectedError from '../../hocs/withUnexpectedError';
import { Language } from '../../types/Language';
import EmployeeForms from './EmployeeForms';
export class EmployeeFormsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeForms: [],
            isPageLoading: true,
            errorMessage: undefined,
        };
    }
    componentDidMount() {
        this.getEmployeeForms();
        this.props.unexpectedErrorContext.setIsMessagingDisabled(false);
    }
    componentDidUpdate() {
        // would make this a useEffect on just experienceContext if this was an FC
        this.alphabetizeEmployeeForms(this.state.employeeForms);
    }
    getEmployeeForms = async () => {
        if (this.props.authenticationContext.user !== undefined) {
            const customerId = encodeURIComponent(this.props.authenticationContext.user.customerId);
            try {
                const response = await axios.get(`/mlp-ui-service/api/documents/employee-forms?customerId=${customerId}`, {
                    headers: { Authorization: this.props.authenticationContext.authHeader() },
                });
                const sortedForms = this.alphabetizeEmployeeForms(response.data);
                this.setState({
                    isPageLoading: false,
                    employeeForms: sortedForms,
                });
            }
            catch (error) {
                this.setState({
                    isPageLoading: false,
                });
            }
        }
    };
    /**
     * takes in a list of IEmployeeForm and returns the same list alphabetized by
     * the display name of the current language
     */
    alphabetizeEmployeeForms = (employeeForms) => {
        employeeForms.sort((form1, form2) => {
            let formA;
            let formB;
            if (this.props.experienceContext.language === Language.ENGLISH) {
                formA = form1.englishDisplayName.toLowerCase();
                formB = form2.englishDisplayName.toLowerCase();
            }
            else {
                formA = form1.spanishDisplayName.toLowerCase();
                formB = form2.spanishDisplayName.toLowerCase();
            }
            if (formA < formB) {
                // sort string ascending
                return -1;
            }
            else if (formA > formB) {
                return 1;
            }
            else {
                return 0; // default return value (no sorting)
            }
        });
        return employeeForms;
    };
    /**
     * Retreieves the employee form based on id.
     * Tracks the download event in GA (generic download event & per ID)
     */
    getEmployeeForm = async (id, recordType) => {
        try {
            this.props.trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EMPLOYEE_FORMS,
                event_name: `Employee Form Download - DocumentId: ${id}`,
            });
            // const response = await axios.get(`/doc-service/employee-forms/${id}?recordType=${recordType}`,
            const response = await axios.get(`/document-service/employee-forms/${id}?recordType=${recordType}`, {
                responseType: 'arraybuffer',
                headers: { Authorization: this.props.authenticationContext.authHeader() },
            });
            const contentDispositionHeader = response.headers['content-disposition'];
            const contentType = response.headers['content-disposition'];
            const filename = contentDispositionHeader?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim();
            const blob = new Blob([response.data], { type: contentType });
            saveAs(blob, filename);
            /* * *  START - set sessionStorage for survey trigger * * */
            sessionStorage.setItem('download_flag', 'true');
            let downloads = sessionStorage.getItem('downloads');
            // let downloadsArray;
            // if( curDownloads ){
            //   downloadsArray = JSON.parse(curDownloads);
            //   downloadsArray.push(filename);
            // } else { 
            //   downloadsArray = [filename];
            // }
            downloads = `${filename}, ${downloads}`;
            sessionStorage.setItem("downloads", downloads);
            /* * *  END - set sessionStorage for survey trigger * * */
        }
        catch (error) {
            if (error.response && error.response.status === 415) {
                this.setState({
                    errorMessage: 'File type is not supported by download.',
                });
            }
            else if (error.response && error.response.status === 404) {
                this.setState({
                    errorMessage: 'File not found, unable to download requested file.',
                });
            }
        }
    };
    closeError = () => {
        this.setState({
            errorMessage: undefined,
        });
    };
    render() {
        return (<div data-public={true}>
        {this.state.isPageLoading && <Spinner id='ee-forms-container-spinner'/>}
        {!this.state.isPageLoading && (<EmployeeForms language={this.props.experienceContext.language} employeeForms={this.state.employeeForms} getEmployeeForm={this.getEmployeeForm} errorMessage={this.state.errorMessage} closeError={this.closeError}/>)}
      </div>);
    }
}
export default withUnexpectedError(withExperience(withAuthentication(withTealium(EmployeeFormsContainer))));
