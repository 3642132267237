import { Button, Icon, Modal } from '@digitools/honest-react';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import { SubmitUnplannedAbsence } from '../api/UnplannedAbsenceApi';
const CancelButton = ({ hideModal }) => (<Button buttonType='secondary' data-testid='cancel-delete-btn' onClick={hideModal}>
    Cancel
  </Button>);
const DeleteAbsenceButton = ({ absence, getLeaves, setSuccessMessage, setFailureMessage, }) => {
    const [disableButton, setDisableButton] = useState(false);
    const onSuccess = () => {
        setDisableButton(false);
        getLeaves();
        setSuccessMessage('You have successfully deleted an Unplanned Absence.');
    };
    const onFailure = () => {
        setDisableButton(false);
        getLeaves();
        setFailureMessage('There was a problem deleting an Unplanned Absence. Please try again later.');
    };
    const { post: postAbsence } = SubmitUnplannedAbsence(onSuccess, onFailure);
    const deleteAbsence = () => {
        setDisableButton(true);
        postAbsence({
            payload: {
                absenceDate: format(absence.uaStartDate, 'MM/dd/yyyy'),
                absReason: absence.absReason,
                absType: absence.absType,
                additionalDates: absence.additionalDates ? absence.additionalDates.map(date => '111/11/1') : [],
                associatedLeaveNumber: absence.associatedLeave?.trim(),
                associatedTransNum: absence.transNum,
                deathFollowsPolicy: absence.uaHasDeath,
                duration: absence.uaDuration,
                endTime: moment.utc(absence.uaEndTime).format('HH:mm'),
                hasAssociatedLeave: absence.uaHasLeave,
                spansMidnight: absence.uaSpansMidnight,
                startTime: moment.utc(absence.uaStartTime).format('HH:mm'),
                transType: 'D',
            },
        });
    };
    const ConfirmDeleteButton = ({ hideModal }) => {
        const handleOnClick = () => {
            deleteAbsence();
            hideModal();
        };
        return (<Button buttonType='primary' onClick={handleOnClick} data-testid='confirm-delete-btn'>
        Delete Absence
      </Button>);
    };
    const TrashButton = ({ showModal }) => (<Button onClick={showModal} buttonType='text' className='p-2' disabled={disableButton} title='Delete Absence' data-testid='delete-btn'>
      <Icon name='trash' color={'secondary'}/>
    </Button>);
    return (<Modal disabledBackdropClick={true} footerContentPosition='center' footerPrimaryRenderButton={ConfirmDeleteButton} footerSecondaryRenderButton={CancelButton} hasCloseIcon={false} headerText='Please Confirm' modalOpenRenderButton={TrashButton}>
      <p data-testid='delete-modal'>Are you sure that you want to delete this absence?</p>
    </Modal>);
};
export default DeleteAbsenceButton;
