import React from "react";
export const EMPLOYER_FAQ = [
    {
        label: {
            english: 'Why do employees have to move their accounts?',
            spanish: '¿Por qué los empleados tienen que trasladar sus cuentas?'
        },
        value: {
            english: 'To simplify login and access, Lincoln’s consolidating individual employee accounts to a single login at LincolnFinancial.com. After moving their MyLincolnPortal.com account, employees can access all benefits with one login, either online or via the Lincoln Financial Mobile app. The app is available for download in the Apple and Google app stores.',
            spanish: 'Para simplificar el inicio de sesión y el acceso, Lincoln consolidará las cuentas de empleados individuales en un único inicio de sesión en LincolnFinancial.com. Después de trasladar sus cuentas de MyLincolnPortal.com, los empleados pueden acceder a todos los beneficios con un solo inicio de sesión, ya sea en línea o mediante la aplicación Lincoln Financial Mobile. La aplicación está disponible para descargar en las tiendas de aplicaciones de Apple y Google.'
        }
    },
    {
        label: {
            english: 'Do I need to move my employer or personal account?',
            spanish: '¿Necesito trasladar mi cuenta personal o de empleador?'
        },
        value: {
            english: <><strong>Employer accounts: </strong>Your employer account isn’t moving at this time; continue accessing MyLincolnPortal.com with your current credentials. <br /><br />
                        <strong>Personal accounts: </strong><a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>Register</u></a> on LincolnFinancial.com to view and manage your personal benefits.</>,
            spanish: <><strong>Cuentas de empleadores: </strong>Su cuenta de empleador no se trasladará en este momento; siga accediendo a MyLincolnPortal.com con sus credenciales actuales. <br /><br />
            <strong>Cuentas personales: </strong><a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>Regístrese</u></a> en LincolnFinancial.com para ver y manejar sus beneficios personales.</>
        }
    },
    {
        label: {
            english: 'How do I register my personal account on LincolnFinancial.com?',
            spanish: '¿Cómo registro mi cuenta personal en LincolnFinancial.com?'
        },
        value: {
            english: <>Visit <strong>LincolnFinancial.com </strong>and follow the steps to <a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>register</u></a> as an indvidual. You’ll need to enter your <u>employee</u> company code.</>,
            spanish: <>Visite <strong>LincolnFinancial.com </strong>y siga los pasos para <a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>registrarse</u></a> como persona individual. Deberá escribir el código <u>de empleado</u> de su empresa.</>
        }
    },
    {
        label: {
            english: 'What if I already have a personal account on LincolnFinancial.com?',
            spanish: '¿Qué pasa si ya tengo una cuenta personal en LincolnFinancial.com?'
        },
        value: {
            english: 'If you already have LincolnFinancial.com credentials for your personal benefits, no additional action is needed. Log in as usual to access or manage your Lincoln benefits, including through our mobile app. Continue using your employer credentials on MyLincolnPortal.com to manage benefits for your company.',
            spanish: 'Si ya tiene credenciales de LincolnFinancial.com para sus beneficios personales, no tiene que hacer nada más. Inicie sesión como siempre para acceder o manejar sus beneficios de Lincoln, incluso mediante nuestra aplicación móvil. Siga usando sus credenciales de empleador en MyLincolnPortal.com para manejar los beneficios para su empresa.'
        }
    },
    {
        label: {
            english: 'How do employees move their accounts to LincolnFinancial.com?',
            spanish: '¿Cómo trasladan los empleados sus cuentas a LincolnFinancial.com?'
        },
        value: {
            english: 'To move their accounts, employees will log in on MyLincolnPortal.com and follow the instructions. Once complete, they can access their benefits at LincolnFinancial.com and via our mobile app.',
            spanish: 'Para trasladar sus cuentas, los empleados deben iniciar sesión en MyLincolnPortal.com y seguir las instrucciones. Una vez completado, podrán acceder a sus beneficios en LincolnFinancial.com y mediante nuestra aplicación móvil.'
        }
    },
    {
        label: {
            english: 'Why can’t I toggle to the employee dashboard to see my benefits?',
            spanish: '¿Por qué no puedo acceder al panel de empleados para ver mis beneficios?'
        },
        value: {
            english: <>On MyLincolnPortal.com, you can no longer toggle between personal benefits and the employer view. <a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>Register</u></a> on LincolnFinancial.com to manage your personal benefits.</>,
            spanish: <>En MyLincolnPortal.com, ya no podrá alternar entre los beneficios personales y la vista del empleador. <a href='https://www.lincolnfinancial.com/public/individuals' target='_blank' data-testid='registerLink'><u>Regístrese</u></a> en LincolnFinancial.com para manejar sus beneficios personales.</>
        }
    },
    {
        label: {
            english: 'Why aren’t my personal benefits showing after logging in on LincolnFinancial.com?',
            spanish: '¿Por qué no se muestran mis beneficios personales después de iniciar sesión en LincolnFinancial.com'
        },
        value: {
            english: <>If you have Lincoln benefits such as dental, vision, or supplemental health coverage, and they’re not visible after logging in on LincolnFinancial.com, complete identify verification on the <strong>Account setup & access</strong> page in your profile. Once successfully completed, your information and accounts will be connected within 24 to 48 hours.</>,
            spanish: <>Si tiene beneficios de Lincoln, como cobertura dental, de la vista o de salud complementaria, y no están visibles después de iniciar sesión en LincolnFinancial.com, complete la verificación de identidad en la página <strong>Account setup & access (Configuración y acceso a la cuenta)</strong> en su perfil. Una vez completado con éxito, su información y sus cuentas se conectarán dentro de 24 a 48 horas.</>
        }
    },
    {
        label: {
            english: 'I need assistance — who do I contact?',
            spanish: 'Necesito ayuda — ¿con quién me comunico?'
        },
        value: {
            english: <>Contact our Web Support team at <strong>800-423-2765</strong> with questions or if you need help creating a personal account on LincolnFinancial.com.</>,
            spanish: <>Comuníquese con nuestro equipo de soporte web al <strong>800-423-2765</strong> si tiene preguntas o si necesita ayuda para crear una cuenta personal en LincolnFinancial.com.</>
        }
    },
];
