import { HeaderDropdownButton, HeaderIcon, HeaderDropdownContainer, useOutsideClick } from "@digitools/honest-react";
import { HeaderDropdownExternalLink, HeaderDropdownLink, HeaderLink } from './Header';
import React, { useRef, useState } from "react";
import { Experience } from "../../types/Experience";
import { Language } from "../../types/Language";
import { CustomExperienceText, CustomEmployeeText } from "../../utils/CustomExperienceText";
import { HeaderText } from "./HeaderText";
//@ts-ignore
import styles from './HeaderStyles.scss';
import useExperience from "../../hooks/useExperience";
import useTranslate from "../../hooks/useTranslate";
import { isProd } from "../../utils/envUtils";
import useCustomerConfig from '../../hooks/useCustomerConfig';
const ExperienceMenu = ({ trackPageEvent, user, }) => {
    const { changeLanguage, setExperience, experience } = useExperience();
    const [isExperienceMenuOpen, setIsExperienceMenuOpen] = useState(false);
    const experienceButtonRef = useRef(null);
    const { isNewNav, setIsNewNav } = useCustomerConfig();
    const { t } = useTranslate();
    /* Click handling for closing dropdown menus */
    useOutsideClick([experienceButtonRef], () => {
        if (isExperienceMenuOpen) {
            setIsExperienceMenuOpen(false);
        }
    });
    let roles = 0, isEmployee, isEmployer, isMultiCustomer, isInternal, showNewNavMenu;
    if (user.roles.indexOf('employee') > -1) {
        isEmployee = true;
        roles = 1;
    }
    if (user.roles.indexOf('employer') > -1) {
        isEmployer = true;
        roles++;
    }
    if (user.roles.indexOf('internal') > -1) {
        isInternal = true;
        showNewNavMenu = true;
        roles++;
    }
    if (user.accessAllCustomers || (user.customers && user.customers.length > 1)) {
        isMultiCustomer = true;
        roles++;
    }
    const getAdminHomeUrl = () => {
        let adminUrl = isProd() ? 'https://admin.mylincolnportal.com' : 'https://admin.np-mylincolnportal.com';
        return adminUrl;
    };
    const getEEDashboardText = showNewNavMenu ? `${CustomEmployeeText()} Dashboard - Legacy` : `${CustomEmployeeText()} Dashboard`;
    return (<>
            <div style={{ display: 'flex', }}>
                {roles > 1
            && <HeaderDropdownButton className={styles.fontFix + ' ' + styles.alignLeft} aria-controls={'experience-menu'} aria-haspopup={true} data-testid='experience-menu' data-public={true} ref={experienceButtonRef} onClick={() => setIsExperienceMenuOpen(!isExperienceMenuOpen)}>
                        <HeaderIcon aria-hidden={true} name='exchange-alt-solid'/>
                        <strong style={{ whiteSpace: 'pre' }}>{t(HeaderText.viewing)}</strong><span style={{ fontWeight: 'normal', textTransform: 'capitalize' }}><CustomExperienceText keyword={experience == Experience.EMPLOYEE && showNewNavMenu ? (isNewNav ? 'Dashboard - New' : 'Dashboard - Legacy') : 'Dashboard'}/></span>
                        {isExperienceMenuOpen && roles > 1
                    && <HeaderDropdownContainer aria-hidden={!isExperienceMenuOpen} id={'experience-menu'} style={{ zIndex: 2001 }}>
                                {(isEmployee || isInternal)
                            && <HeaderDropdownLink to='/employee/home' data-testid={'experienceEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(false); }}>
                                        {getEEDashboardText}
                                </HeaderDropdownLink>}
                                {showNewNavMenu
                            && <HeaderDropdownLink to='/employee/home' data-testid={'newNavEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(true); }}>
                                        {`${CustomEmployeeText()} Dashboard - New`}
                                </HeaderDropdownLink>}
                                {(isEmployer || isInternal)
                            && <HeaderDropdownLink to='/employer/home' className={styles.dropdownButtonFix} data-testid={'experienceER'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                                        Employer Dashboard
                                </HeaderDropdownLink>}
                                {isInternal
                            && <HeaderDropdownExternalLink href={getAdminHomeUrl()} className={styles.dropdownButtonFix} data-testid={'adminHomeLink'} onClick={() => { setIsNewNav(false); }}>
                                        Admin Home
                                    </HeaderDropdownExternalLink>}
                                {isMultiCustomer
                            && <HeaderDropdownLink to={'/customer-search'} data-testid={'experienceER'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                                        Change Customer
                                </HeaderDropdownLink>}
                            </HeaderDropdownContainer>}
                    </HeaderDropdownButton>}
                {roles === 1 && (isEmployee || isInternal)
            && <HeaderLink to='/employee/home' data-testid={'experienceEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(false); }}>
                        {getEEDashboardText}
                    </HeaderLink>}
                {roles === 1 && showNewNavMenu
            && <HeaderLink to='/employee/home' data-testid={'newNavEE'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYEE); setExperience(Experience.EMPLOYEE); setIsNewNav(true); }}>
                        {`${CustomEmployeeText()} Dashboard - New`}
                    </HeaderLink>}
                {roles === 1 && (isEmployer || isInternal)
            && <HeaderLink to='/employer/home' className={styles.dropdownButtonFix} data-testid={'experienceER'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                        Employer Dashboard
                    </HeaderLink>}
                {roles === 1 && isMultiCustomer
            && <HeaderLink to={'/customer-search'} data-testid={'changeCustomer'} onClick={() => { trackPageEvent('Experience', Experience.EMPLOYER); setExperience(Experience.EMPLOYER); changeLanguage(Language.ENGLISH); setIsNewNav(false); }}>
                        Change Customer
                    </HeaderLink>}
            </div>
        </>);
};
export default ExperienceMenu;
