import axios from 'axios';
import { getEnvVariable } from 'src/utils/envUtils';
export const GetSubscriber = async (authHeader, language) => {
    let dentalGraphqlUrl = getEnvVariable('dentalGraphqlUrl');
    return await axios.post(dentalGraphqlUrl, {
        query: `
            query subscriber {
                subscriber(language: ${language}) {
                    firstName
                    lastName
                    id
                    dateOfBirth
                    effectiveDate
                    terminationDate
                    memberType
                    memberTypeDescription
                    status
                    deductiblesAndCoinsurance {
                        deductibleType
                        amountApplied
                        inNetworkLabel
                        outNetworkLabel
                        planDeductibles {
                            coverageType
                            inNetwork {
                                networkDeductible
                            }
                            outNetwork {
                                networkDeductible
                            }
                        }
                        coinsurances {
                            serviceCategory
                            categoryDesc
                            covered
                            inNetwork {
                                networkCoinsurance
                                deductibleApplies
                            }
                            outNetwork {
                                networkCoinsurance
                                deductibleApplies
                            }
                        }
                    }
                    planOverview {
                        planFundingClause
                    }
                    dependents {
                        firstName
                        lastName
                        id
                        dateOfBirth
                        effectiveDate
                        terminationDate
                        memberType
                        memberTypeDescription
                        status
                }
            }
        }`,
    }, { headers: { authorization: authHeader } });
};
export const GetDeductibles = async (authHeader, language, memberType, memberId) => {
    let dentalGraphqlUrl = getEnvVariable('dentalGraphqlUrl');
    return await axios.post(dentalGraphqlUrl, {
        query: `
            query deductiblesAndCoinsurance {
                deductiblesAndCoinsurance(memberType: ${memberType}, memberId: "${memberId}", language: ${language}) {
                    deductibleType
                    amountApplied
                    inNetworkLabel
                    outNetworkLabel
                    planDeductibles {
                        coverageType
                        inNetwork {
                            networkDeductible
                        }
                        outNetwork {
                            networkDeductible
                        }
                    }
                    coinsurances {
                        serviceCategory
                        categoryDesc
                        covered
                        inNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                        outNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                    }
                }
            }`,
    }, { headers: { authorization: authHeader } });
};
