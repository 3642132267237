import { TextInput, Row, Col, themes, RadioGroup, Radio } from '@digitools/honest-react';
import { Scope, useFormApi, useFormState } from 'informed';
import { AboutYourAbsenceText } from 'src/packages/cli/constants/AboutYourAbsenceText';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import { othLeaveReasons } from '../components/LeaveReason';
//TODO: fix margin bottom styling on hidden inputs
/**
 * Sub-form for Absence data - LeaveType and LeaveCategory fields
 * These fields are common to all leaveTypes and help drive the rest of the absenceData form
 */
const LeaveTypeAndCategory = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { setValue } = useFormApi();
    const { claimantData, absenceData } = useCliDataContext();
    const { leaveTypes, isLeaveCustomer, isClaimLeaveCustomer, paidLeaveStates, leaveCategoryDays, leaveReasons, } = useCliCustomerConfigContext();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const inValidPaidLeaveState = claimantData &&
        claimantData.selectedEmpStateID &&
        paidLeaveStates.includes(claimantData.selectedEmpStateID);
    const leaveCategoryLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ?
        t(formLabels.leaveCategory1) + ' ' + leaveCategoryDays + ' ' + t(formLabels.leaveCategory2) :
        `${t(formLabels.leaveCategory1ER)} ${leaveCategoryDays} ${t(formLabels.leaveCategory2ER)}`);
    const leaveTypeLabel = experience === Experience.EMPLOYEE ?
        t(formLabels.leaveType) :
        `${t(formLabels.leaveTypeER)}`;
    //@ts-ignore
    const leaveType = values?.absenceData?.leaveType;
    const shouldDisplayLeaveCategory = !(leaveType === LeaveType.PREGNANT);
    const filteredLeaveReasons = leaveReasons.filter(leaveReason => othLeaveReasons.includes(leaveReason.code));
    return (<>
      <div data-testid='leaveTypeAndCategory'>
        {/* @ts-ignore */}
        <Scope scope='absenceData'>
          {/* default to OWN for claim customer */}
          {!(isLeaveCustomer || isClaimLeaveCustomer || inValidPaidLeaveState) && (<TextInput hidden={true} skinny={true} hideLabel={true} label={''} field='leaveType' initialValue={'OWN'}/>)}
          {/* show question for Leave or valid PFML customer */}
          {(isLeaveCustomer || isClaimLeaveCustomer || inValidPaidLeaveState) && (<Row className='mb-3'>
              <Col xs={12}>
                <RadioGroup id="test" label={leaveTypeLabel} field={'leaveType'} theme={themes.lincoln} legendStyles={{ marginBottom: '.5rem' }} validateOnBlur={true} validateOnChange={true} 
        //@ts-ignore
        onChange={() => { values?.absenceData?.relationship && setValue('absenceData.relationship', undefined); values?.absenceData?.leaveReason && setValue('absenceData.leaveReason', undefined); }} validate={fieldRequired} initialValue={absenceData && absenceData.leaveType}>
                  {leaveTypes.map(leaveType => {
                // Hide Pregnant option from Male claimants
                if (leaveType.code === 'MAT' && claimantData?.gender === 'M') {
                    return null;
                }
                else if (leaveType.code === 'OTH' && filteredLeaveReasons.length === 0) {
                    return null;
                }
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lt-' + leaveType.code} key={'lt-' + leaveType.code} name='leaveType' label={t(leaveType)} value={leaveType.code}/>);
            })}
                </RadioGroup>
              </Col>
            </Row>)}

          {/* --- Leave Category --- */}
          {/* Claim Only: default to "C"/continuous */}
          {(!isLeaveCustomer || !isClaimLeaveCustomer || !inValidPaidLeaveState || leaveType === LeaveType.PREGNANT) && (<TextInput skinny={true} hidden={true} hideLabel={true} label={''} field='leaveCategory' initialValue={'C'}/>)}

          {/* Leave/ClaimLeave: wait for answer to leaveType before display */}
          {/* @ts-ignore */}
          {(isLeaveCustomer || isClaimLeaveCustomer || inValidPaidLeaveState) && values.absenceData && values.absenceData['leaveType'] && shouldDisplayLeaveCategory && (<Row className='mb-3'>
                <Col xs={12}>
                  <RadioGroup 
        // FIXME: leaveCategoryDays reads as null for ER users
        label={leaveCategoryLabel} field={'leaveCategory'} theme={themes.lincoln} legendStyles={{ marginBottom: '.5rem' }} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.leaveCategory}>
                    <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lc-continuous'} name='leaveCategory' label={t({ english: 'Yes', spanish: 'Sí' })} value={'C'}/>
                    <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lc-intermittent'} name='leaveCategory' label={'No'} value={'I'}/>
                  </RadioGroup>
                </Col>
              </Row>)}
        </Scope>
      </div>
    </>);
};
export default LeaveTypeAndCategory;
