import { Checkbox, CheckboxGroup, Col, Heading, Row, TextInput } from '@digitools/honest-react';
import { format } from 'date-fns';
import React from 'react';
import { formatName, formatPhone, isNotEmpty, validateEmail, validatePhone } from '../../../../utils/validationUtils';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
const Signature = () => {
    const timestamp = format(new Date(), 'MM/dd/yyyy HH:mm:ss');
    const validateSignatureCheckBox = (value) => {
        return !value || value.length < 1 ? 'Please check box to submit' : undefined;
    };
    return (<>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>
          Signature
        </Heading>
      </BorderLessGrayBox>
      <CheckboxGroup field={'checkboxInputs'} label={''} hideLabel={true} validate={validateSignatureCheckBox} validateOnChange={true}>
        <Checkbox field={'signatureCheckBox'} label={'I certify that the facts as indicated above are true to the best of my knowlege and belief.'}/>
      </CheckboxGroup>
      
      <Row className='mt-3'>
        <Col md={8} sm={12}>
          <TextInput id={'name'} field={'name'} label={'Name'} mask={formatName} validate={isNotEmpty} validateOnBlur={true} placeholder={'Associate Name'} type={'text'} data-private={true}/>
        </Col>
        <Col md={4} sm={12}>
          <Heading elemType={'h4'} elemStyle={'h5'}>Date Signed</Heading>
          <p style={{ marginTop: 0 }}>{timestamp}</p>
        </Col>
      </Row>
      <Row className='mt-0'>
        <Col md={4} sm={12}>
          <TextInput id={'phone'} field={'phone'} label={'Phone'} mask={formatPhone} validate={validatePhone} validateOnBlur={true} type={'text'}/>
        </Col>
        <Col md={4} sm={12}>
          <TextInput id={'email'} field={'email'} label={'Email'} validate={validateEmail} validateOnBlur={true} type={'text'}/>
        </Col>
      </Row>
    </>);
};
export default Signature;
