import React, { useState } from 'react';
import { Form } from 'informed';
import { themes, Checkbox, Col, Row } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Link from '@digitools/honest-react/dist/lib/components/Link';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import EoiVerificationText, { ApplicationReasonText, LicenseAgreementText } from '../../i18n/EoiVerificationText';
import { ApplicationReasonCode } from '../../providers/EoiProvider';
import { EoiGeneralText } from '../../i18n/EoiText';
import QuestionBox from '../../components/QuestionBox';
import { downloadEsignAuthForm } from './../../api/EoiApiCalls';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from '../../../../hooks/useExperience';
import { fieldRequired } from '../../utils/FormFieldValidations';
import useEoiContext from '../../hooks/useEoiContext';
import { addDays, parse, startOfToday, isWithinInterval, parseJSON } from 'date-fns';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import styled from 'styled-components';
import { isSitusStateNY } from '../../utils/HelperFunctions';
const StyledLicenseDiv = styled('div') `
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    width: fit-content;
    margin-right: 10rem;
  };
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0rem;
  };
`;
const StyledSubmitButton = styled(Button) `
  width: 100%;
  @media (max-width: 1199px) {
    margin-top: 1rem;
    margin-left: -4.1rem;
    width: fit-content;
  };
  @media (min-width: 1200px) {
    width: fit-content;
    margin-left: -1.5rem;
    float: right;
  };
`;
/* Helper method to set the value of newEmployee on the form */
const setNewEmployeeValueFalse = (formState) => {
    formState.values.newEmployee = 'false'; // value must be a string or informed treats it as undefined
};
export const showAnnualEnrollment = (initialInformation) => {
    if (initialInformation && initialInformation.employerConfiguration.preference.annualEnrollmentIndicator) {
        const startDate = getUTCDateFromLocalDate(parseJSON(initialInformation.employerConfiguration.preference.annualEnrollmentStartDate));
        const now = startOfToday();
        startDate.setFullYear(now.getFullYear()); //Makes the start date be on the current year 
        const endDate = addDays(startDate, initialInformation.employerConfiguration.preference.annualEnrollmentPeriodQuantity);
        //Date logic for AE rollover into new year scenario
        const startDatePriorYear = getUTCDateFromLocalDate(parseJSON(initialInformation.employerConfiguration.preference.annualEnrollmentStartDate));
        startDatePriorYear.setFullYear(now.getFullYear() - 1);
        const endDatePriorYear = addDays(startDatePriorYear, initialInformation.employerConfiguration.preference.annualEnrollmentPeriodQuantity);
        if (isWithinInterval(now, { start: startDate, end: endDate }) || isWithinInterval(now, { start: startDatePriorYear, end: endDatePriorYear })) {
            return true;
        }
    }
    return false;
};
/*
If annualEnrollmentStartDate = 10-01-2020T00:00:00,
making it into a date will convert it into a local date -> 09-30-2020T20:00:00 EDT-0400 (eastern timezone)
This method will conver it back to 10-01-2020T00:00:00 EDT-0400
*/
const getUTCDateFromLocalDate = (localDate) => {
    const startDateYear = localDate.getUTCFullYear();
    const startDateMonth = localDate.getUTCMonth() + 1;
    const startDateDay = localDate.getUTCDate();
    const startDateString = startDateYear + '-' + (startDateMonth < 10 ? '0' + startDateMonth : startDateMonth) + '-' + (startDateDay < 10 ? '0' + startDateDay : startDateDay);
    return parse(startDateString, 'yyyy-MM-dd', new Date());
};
const AdditionalInfoForm = ({ setIsOnGettingStartedPage, }) => {
    const { t } = useTranslate();
    const { initialInformation, setAdditionalInformation, interviewAnswers, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, setIsVerificationPage } = useEoiContext();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    const [downloadError, setDownloadError] = useState();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { customerConfig } = useEoiContext();
    let displayNewHireQuestion = true;
    if (initialInformation && !initialInformation.employerConfiguration.preference.initialEnrollmentCode) {
        displayNewHireQuestion = false;
    }
    const applicationReasonIsAvailable = initialInformation &&
        (initialInformation.employerConfiguration.preference.allowLateEnrollmentIndicator
            || initialInformation.employerConfiguration.preference.lateEntrantIndicator
            || initialInformation.employerConfiguration.preference.allowFamilyStatusChangeIndicator
            || initialInformation.employerConfiguration.preference.salaryIncreaseRequireEOIIndicator
            || showAnnualEnrollment(initialInformation)
            || initialInformation.employerConfiguration.preference.newlyEligibleEmployeesIndicator);
    const handleOnSubmit = async (values) => {
        setIsVerificationPage(false);
        trackClickWithPage('continue', '/customer/eoi/verification');
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        if (displayNewHireQuestion && values.newEmployee && values.newEmployee === 'true') {
            values.applicationReason = 'NEFT'; // newEmployee === NEFT application reason
        }
        setAdditionalInformation({
            ...values,
            disableApplicationReason: interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false,
            disableNewEmployee: interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableNewEmployee : false
        });
        setIsOnGettingStartedPage(false);
    };
    const downloadEsignAuthFormOnClick = () => {
        trackClickWithPage('downloadEsignAuthForm', '/customer/eoi/verification');
        downloadEsignAuthForm(authHeader(), language, t(LicenseAgreementText.documentTitle), t(LicenseAgreementText.errorMessage), setDownloadError, initialInformation.employerConfiguration.situsStateCode);
    };
    if (interviewAnswers.additionalInformation && interviewAnswers.additionalInformation.disableApplicationReason) {
        // received application reason from MUW
        if (ApplicationReasonCode['NEFT'] === interviewAnswers.additionalInformation.applicationReason) {
            interviewAnswers.additionalInformation.newEmployee = 'true';
        }
        else {
            interviewAnswers.additionalInformation.newEmployee = 'false';
        }
    }
    const showAnnualEnrollmentRadio = showAnnualEnrollment(initialInformation) || (interviewAnswers && interviewAnswers.additionalInformation && interviewAnswers.additionalInformation.applicationReason === 'EEAE');
    const applicationReasonInitialValue = () => {
        if (interviewAnswers.additionalInformation && interviewAnswers.additionalInformation.applicationReason) {
            return interviewAnswers.additionalInformation.applicationReason;
        }
        if (showAnnualEnrollmentRadio) {
            return 'EEAE';
        }
    };
    return (<Form onSubmit={handleOnSubmit} data-testid='additional-info-form' data-public={true}>
      {({ formState }) => (<>
          {displayNewHireQuestion ?
                <QuestionBox data-testid='new-employee' heading={isSitusStateNY(initialInformation) ? t(EoiVerificationText.newEmployeeHeadingNY) : t(EoiVerificationText.newEmployeeHeading)} isSuccess={formState.values.newEmployee !== undefined}>
              <RadioGroup label={t(EoiVerificationText.newEmployeeSubHeading)} hideLabel={true} field='newEmployee' validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.newEmployee : undefined}>
                <Radio id='yes' name='newEmployee' label={t(EoiGeneralText.yes)} value='true' theme={themes.lincoln} containerStyle={{ display: 'inline-block', marginRight: '1rem' }} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableNewEmployee : false}/>
                <Radio id='no' name='newEmployee' label={t(EoiGeneralText.no)} value='false' theme={themes.lincoln} containerStyle={{ display: 'inline-block' }} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableNewEmployee : false}/>
              </RadioGroup>
            </QuestionBox>
                : setNewEmployeeValueFalse(formState)}

          {!applicationReasonIsAvailable && ((formState.values.newEmployee && formState.values.newEmployee === 'false') || !displayNewHireQuestion) &&
                <Alert type={'info'} closeable={false} data-testid='no-application-reasons-alert'>
              {t(EoiVerificationText.noApplicationReasonMsg)}
            </Alert>}

          {applicationReasonIsAvailable && ((formState.values.newEmployee && formState.values.newEmployee === 'false') || !displayNewHireQuestion) &&
                <QuestionBox data-testid='application-reasons' heading={t(ApplicationReasonText.heading)} isSuccess={formState.values.applicationReason}>
              <RadioGroup label={'Application reason'} hideLabel={true} field='applicationReason' validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={applicationReasonInitialValue()}>
                {initialInformation &&
                        <>
                    {initialInformation.employerConfiguration.preference.allowLateEnrollmentIndicator &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='lateEnrollment' label={t(ApplicationReasonText.lateEnrollment)} value='EELE' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}
                    {initialInformation.employerConfiguration.preference.lateEntrantIndicator &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='lateEntrant' label={t(ApplicationReasonText.lateEntrant)} value='EELE' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}
                    {initialInformation.employerConfiguration.preference.allowFamilyStatusChangeIndicator &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='familyStatusChange' label={t(ApplicationReasonText.familyStatusChange)} value='LFSC' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}
                    {initialInformation.employerConfiguration.preference.salaryIncreaseRequireEOIIndicator &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='salaryIncrease' label={t(ApplicationReasonText.salaryIncrease)} value='SALI' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}
                    {showAnnualEnrollmentRadio &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='annualEnrollmentIndicator' data-testid='eeae-reason' label={t(ApplicationReasonText.annualEnrollmentIndicator)} value='EEAE' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}

                    {initialInformation.employerConfiguration.preference.newlyEligibleEmployeesIndicator &&
                                <Radio containerStyle={{ marginBottom: '.5rem' }} name='applicationReason' id='initialEnrollment' label={t(ApplicationReasonText.initialEnrollment)} value='NENE' theme={themes.lincoln} disabled={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.disableApplicationReason : false}/>}
                  </>}
              </RadioGroup>
            </QuestionBox>}
          {((formState.values.newEmployee && formState.values.newEmployee === 'true') || formState.values.applicationReason) &&
                <QuestionBox data-testid='license-agreement' heading={t(LicenseAgreementText.heading)} isSuccess={formState.values.licenseAgreement}>
              {t(LicenseAgreementText.body)}
              <Row style={{ marginTop: '1rem' }}>
                <Col lg={9} md={9} sm={12}>
                  <StyledLicenseDiv>
                    <Checkbox field='licenseAgreement' label={<>
                        {initialInformation && t(LicenseAgreementText.iAgree)}<Link style={{ marginLeft: '.5rem' }} onClick={() => downloadEsignAuthFormOnClick()}><Icon color='inherit' name='pdf' style={{ marginRight: '.25rem' }}/><strong>{t(LicenseAgreementText.documentTitle)}</strong></Link>
                      </>} hideLabel={false} required={true} theme={themes.lincoln} initialValue={interviewAnswers.additionalInformation ? interviewAnswers.additionalInformation.licenseAgreement : false}/>
                  </StyledLicenseDiv>
                </Col>
                {/* {initialInformation && t(LicenseAgreementText.iAgree)}<Link style={{ marginLeft: '.5rem' }} onClick={()=>downloadEsignAuthFormOnClick()}><Icon color='inherit' name='pdf' style={{ marginRight: '.25rem' }} /><strong>{t(LicenseAgreementText.documentTitle)}</strong></Link> */}
                <Col lg={3} md={3} sm={12}>
                  <StyledSubmitButton buttonType='primary' type='submit' theme={themes.lincoln}>
                    {customerConfig && customerConfig.saveForLater ? t(EoiGeneralText.saveAndContinue) : t(EoiGeneralText.continue)}
                  </StyledSubmitButton>
                </Col>
              </Row>
              {downloadError && <Alert style={{ marginTop: '.5rem' }} type='error' closeable={true} onClose={() => setDownloadError(undefined)}>{downloadError}</Alert>}
            </QuestionBox>}
        </>)}
    </Form>);
};
export default AdditionalInfoForm;
