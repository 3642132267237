import { Box, Col, Heading, Label, Row, themes } from '@digitools/honest-react';
import styled from 'styled-components';
export const StyledHeading = styled(Heading) `
font-size: 2.063rem !important;
margin-top: 1rem;
margin-bottom: 1.563rem;
margin-left: 0.125rem;
font-weight: 200;
@media (max-width: 1025px) {
  margin-top: 0rem;
}
`;
export const StyledDiv = styled('div') `{
  margin-top: 1rem;
  margin-bottom: 1rem;
}`;
export const pStyle = {
    marginTop: '-28px',
    marginLeft: '40px',
};
export const RadioBtnContainer = styled('div') `
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 20px;
    }`;
export const RadioContainer = styled('div') `
    justify-content: flex-start;
    display: flex;
    margin-bottom: 25px;
    gap: 20px;
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }`;
export const RadioContainer2 = styled('div') `
    display: flex;
    justify-content: none;
    label {
      margin-right: 1rem;
      margin-bottom: .5rem;
    }
  `;
export const LabelText = styled(Label) `
display: block !important;
margin-bottom: 5px;
color: #37424a;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 18px;
span {
  color:  #555e64;
}
@media (min-width: 768px) {
  font-size: 16px !important;
}`;
export const SectionHeading = styled(Heading) `
  font-size: 24px !important;
  margin-top: 0  !important;
  @media (min-width: 768px) {
    font-size: 26px !important;
  }
`;
export const DatePickerBlock = styled(Col) `
padding-left: 0;
margin-bottom: 3px;
@media (min-width: 768px) {
  margin-bottom: 18px;
}`;
export const TextBlock = styled(Col) `
margin-bottom: 25px;
@media (min-width: 768px) {
  margin-bottom: 40px;
}`;
export const SelectBlock = styled(Col) `
margin-bottom: 3px;
@media (min-width: 768px) {
margin-bottom: 18px;
}`;
export const DivBlock = styled('div') `
  margin-bottom: 1rem;
  @media only screen and (max-width: 500px) {
    margin-bottom: 0rem;
  }
  `;
export const headingStyle = {
    fontSize: '0.9375rem;'
};
export const GrayBox = styled(Box) `
  background-color: ${themes.lincoln.palette.grayLightest} !important;
  border: none;
  height: 45px;
  margin-top: 10px;
`;
export const StyledRow = styled(Row) `
  margin-top: 1rem;
  @media only screen and (max-width: 500px) {
    margin-top: 0rem;
  }
`;
export const GrayBlock = styled('div') `
  padding: 0.9375rem;
  background-color: #f2f2f2;
  font-weight: normal;
  margin: 1.25rem 0 0.9375rem;
`;
