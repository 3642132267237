import React from 'react';
const InfoText = {
    pageTitle: {
        english: 'Getting started',
        spanish: 'Cómo empezar'
    },
    header: {
        english: 'What do I need before submitting a new claim?',
        spanish: '¿Qué necesito para presentar una nueva solicitud?',
    },
    pageInfo: {
        english: 'To file a claim for New York Paid family leave, call 866-783-2255',
        spanish: 'Para presentar un reclamo de licencia familiar pagada de Nueva York, llame al 866-783-2255',
    },
    collapseHeader: {
        english: 'Information you will need, quick tips and saving your progress',
        spanish: 'Información necesaria, consejos simples e instrucciones para guardar su progreso',
    },
    collapseHeaderER: {
        english: 'Information you will need and quick tips',
        spanish: 'Information you will need and quick tips',
    },
    verificationInfoHeader: {
        english: 'Personal Identification',
        spanish: 'Identificación personal',
    },
    claimDetailsHeader: {
        english: 'Claim and event details',
        spanish: 'Detalles del reclamo y del evento',
    },
    reasonForClaim: {
        english: 'This includes the reason for the claim and dates of medical or other authorized events',
        spanish: 'Esto incluye el motivo del reclamo y las fechas de los eventos médicos u otros eventos autorizados.',
    },
    medicalInfoHeader: {
        english: 'Medical Information',
        spanish: 'Información médica',
    },
    physician: {
        english: 'Physician or hospital contact information (if available)',
        spanish: 'Información de contacto del médico o del hospital (si la tiene).',
    },
    quickTipsHeaderEE: {
        english: 'Quick tips and saving your progress',
        spanish: 'Consejos simples e instrucciones para guardar su progreso',
    },
    quickTipsHeaderER: {
        english: 'Quick tips',
        spanish: 'Quick tips',
    },
    quickTipsText: {
        english: 'All questions will need to be answered to complete the application process',
        spanish: 'Es necesario responder a todas las preguntas para completar el proceso de solicitud.',
    },
    timeToFill: {
        english: 'The time to complete filling out the application will vary. However, you will have the option to save your progress, if you wish to come back at a later time to complete your application',
        spanish: 'El tiempo necesario para completar la solicitud varía. Sin embargo, tiene la opción de guardar su progreso si desea completar su solicitud en otro momento.',
    },
    timeOut: {
        english: <>For added security this site has a <em><strong>time-out feature</strong></em>. To protect your personal information, your session will automatically end after 20 minutes of inactivity.</>,
        spanish: <>Para brindar mayor seguridad, este sitio tiene una <em><strong>función de límite de tiempo de inactividad</strong></em>. Para proteger su información personal, su sesión se cerrará automáticamente luego de 20 minutos de inactividad.</>,
    },
    timeOutER: {
        english: 'For added security this site has a',
        spanish: 'For added security this site has a',
    },
    timeOutER1: {
        english: 'time-out feature',
        spanish: 'time-out feature',
    },
    timeOutER2: {
        english: 'To protect your Employee\'s personal information, your session will automatically end after 20 minutes of inactivity.',
        spanish: 'personal information, your session will automatically end after 20 minutes of inactivity.',
    },
};
export default InfoText;
