import React from 'react';
import { Button, Row } from '@digitools/honest-react';
import CancelButton from '../CancelButton';
const ReportFilterButtonPanel = ({ cancelRedirectionUrl }) => {
    return (<div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
      <Row>
        <CancelButton url={cancelRedirectionUrl}/>
        <Button buttonType={'primary'} type='submit'>
          Continue
        </Button>
      </Row>
    </div>);
};
export default ReportFilterButtonPanel;
