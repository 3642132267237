import titleCaseAll from './titleCaseAll';
import formatZip from './formatZip';

const formatAddress = (address, city, state, zip) => {
  const formattedAddress = address != null && address.trim() != '' ? `${titleCaseAll(address.trim())}, ` : '';
  const formattedCity = city != null && city.trim() != '' ? `${titleCaseAll(city.trim())} ` : '';
  const formattedState = state != null && state.trim() != '' ? `${state.trim()} ` : '';
  const formattedZip = zip != null && zip.trim() != '' ? `${formatZip(zip)}` : '';

  return `${formattedAddress}${formattedCity}${formattedState}${formattedZip}`;
};

export default formatAddress;
