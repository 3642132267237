import React, { useEffect, useState } from 'react';
import VanityPageQuestions1 from './components/VanityPageQuestions1';
import VanityPageQuestions2 from './components/VanityPageQuestions2';
import VanityPageQuestions3 from './components/VanityPageQuestions3';
import ReRouteInfoPage from './components/ReRouteInfoPage';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import { HeadingBanner, HelpHeading, StyledIcon, StyledLink, StyledPageContainer } from './components/StyledComponents';
import { StepperProvider } from './components/StepperProvider';
import { VanityPageText } from './constants/VanityPageText';
import useTranslate from 'src/hooks/useTranslate';
import { StyledLCNText } from 'src/components/styledComponents';
import EmployeeLoginChoice from './components/EmployeeLoginChoice';
import RoleSelect from './components/RoleSelect';
import useExperience from 'src/hooks/useExperience';
import { Language } from 'src/types/Language';
// export const StyledBox = styled(Box)`
//   border-radius: 4px;
//   max-width: 650px;
//   position: relative;
//   padding: 35px;
//   @media (min-width: 992px ) {
//     min-width: 850px;
//     padding: 65px;
//   }
// ` 
// //TODO -- fix up banner so other later components dont need to offset their margins
// export const StyledPageContainer = styled('div')`
//   margin-top: -8rem;
//   display: flex;
//   justify-content: center;
//   @media only screen and (max-width: 767px) {
//     margin-top: -10rem;
//   }
// `
// export const HelpHeading = styled(Heading)`
//   margin-bottom: 3rem;
//   margin-top: -3rem;
//   @media only screen and (max-width: 767px) {
//     margin-top: -3rem
//     margin-bottom: 0;
//   }
// `
// // TODO: this is the final banner - auto fills to top and sides on mobile
// // TODO: try to get it to auto apply margin on next sibling 
// // TODO: doesn't fill 100% at all screens with this CSS - large mobile doesn't look ideal
// export const HeadingBanner = styled('div')`
// {
//   background-image: linear-gradient(to right, rgba(173, 17, 43, 1) 65%, rgba(242, 0, 29, 1) 100%);
//   height: 16.8rem;
//   display: flex;
//   flex: 1;
//   @media only screen and (max-width: 767px) {
//     margin-left: -1rem !important;
//     margin-right: -1rem !important;
//     margin-top: -1rem !important;
//   }
// }
// `;
const VanityPage = () => {
    const { t } = useTranslate();
    const { trackView } = useTealium();
    const [showLogin, setShowLogin] = useState(false);
    const { language } = useExperience();
    useEffect(() => {
        scrollTo(0, 0);
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MIGRATION,
            page_l4: 'tbd',
            page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.MIGRATION.MIGRATION_HELP,
            user_audience: TEALIUM_PAGE_VIEW.USER_AUDIENCE.PUBLIC
        });
    }, []);
    /** Clicking 'My Lincoln portal' from the top of the guided experience
     *  on any page will bring the user back to whichever of these two
     *  pages they started from.
     *   -- let us help == employee login
     *   -- otherwise -- reload the migrate page on
     *
     */
    /** Hacky Stepper Init & Backlink Logic based on query params **/
    let reloadDocument;
    let skipToStep;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get('from');
    let backLink;
    if (from === 'ee-login-help') {
        backLink = {
            pathname: '/public/login',
            search: `?employee=true${language === Language.SPANISH ? '&lang=es' : ''}`,
        };
        skipToStep = 2;
        reloadDocument = false;
    }
    else {
        backLink = {
            pathname: '/public/migration-help',
            search: `?from=guided${language === Language.SPANISH ? '&lang=es' : ''}`,
        };
        reloadDocument = true;
        if (from === 'ee-login-back' || from === 'guided') {
            skipToStep = 1;
        }
    }
    return (<div style={{}}>
      {showLogin && <StyledLink to={backLink} reloadDocument={reloadDocument} className={'d-block'}>
        <StyledIcon size='xs' name='long-arrow-left-regular'/> {t(VanityPageText.backToLogin)}
      </StyledLink>}
        <HeadingBanner />
        <StyledPageContainer style={{}}>
          <div>
            {showLogin && <HelpHeading elemType='h1' color='white'>{t(VanityPageText.hereToHelp)}</HelpHeading>}
            <StepperProvider skipToStep={skipToStep} setShowLogin={setShowLogin}>
              <RoleSelect />
              <EmployeeLoginChoice />
              <VanityPageQuestions1 />
              <VanityPageQuestions2 />
              <VanityPageQuestions3 />
              <ReRouteInfoPage />
            </StepperProvider>
          <StyledLCNText>{t(VanityPageText.LCN)}</StyledLCNText>
          </div>
        </StyledPageContainer>
    </div>);
};
export default VanityPage;
