import { initialState } from '../../utils/InitialState';
import { workProvincesModel, workStatesModel } from './work-location-model/initialStateAndProvinces';
import { isAfter, isBefore, isValid } from 'date-fns';
export const isDateSelectionRequired = (reportDateRange) => {
    const { dateSearchBy, fromDate, toDate } = reportDateRange;
    return !dateSearchBy || !fromDate || !toDate;
};
export const isFromDateAfterThroughDate = (reportDateRange) => {
    const fromDate = reportDateRange.fromDate;
    const throughDate = reportDateRange.toDate;
    return fromDate && throughDate ? isAfter(new Date(fromDate), new Date(throughDate)) : false;
};
export const isValidProductSelected = (category) => {
    return category !== 'Select Criteria' && category !== '';
};
export const isProductSelectionRequired = (products) => {
    const selectedProduct = products.find(product => product.checked === true);
    return selectedProduct === undefined;
};
export const validateStatusAndDatesGroup = (formApi) => (value, values) => {
    const statusGroupSelection = statusSelected(values);
    const dateSearchBy = formApi.getValue('dateSearchBy');
    if (formApi.getTouched('dateSearchBy')) {
        return (dateSearchBy && dateSearchBy !== 'Choose an option') ||
            (statusGroupSelection && statusGroupSelection.length)
            ? undefined
            : 'Please select Status and/or Date';
    }
    return undefined;
};
export const validateDatesSearchOn = (formApi) => (value, values) => {
    const statusGroupSelection = statusSelected(values);
    formApi.setTouched('dateSearchBy', true);
    return (value && value !== 'Choose an option') || (statusGroupSelection && statusGroupSelection.length)
        ? undefined
        : 'Please select Status and/or Date';
};
export const validateFromDateAndThroughDate = (value, values) => {
    const { fromDate, toDate } = values;
    const fromDateString = fromDate ? fromDate.toString() : '';
    const toDateString = toDate ? toDate.toString() : '';
    const isValidaDate = isValid(fromDateString) && isValid(toDateString);
    return isValidaDate
        ? isAfter(new Date(fromDateString), new Date(toDateString))
            ? 'Required'
            : undefined
        : undefined;
};
export const resetThroughDateError = (formApi) => () => {
    if (!formApi.getValue('toDate')) {
        formApi.setError('toDate', undefined);
    }
};
export const enterValidDateMessage = (formApi, field) => () => {
    if (!formApi.getValue(field)) {
        formApi.setError(field, 'Invalid Date');
    }
    if (formApi.getError(field) && formApi.getError(field) !== 'Invalid Date') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (formApi.getValue(field).toString().match(/\//g) || []).length !== 2
            ? formApi.setError(field, 'Invalid Date')
            : undefined;
    }
    if (field === 'toDate' && isBefore(new Date(formApi.getValue('toDate')), new Date(formApi.getValue('fromDate')))) {
        formApi.setError(field, 'To date cannot be before from date');
    }
};
export const resetDateFields = (formApi) => () => {
    formApi.setValue('fromDate', '');
    formApi.setValue('toDate', '');
    formApi.setError('toDate', undefined);
    formApi.setError('fromDate', undefined);
};
export const createFilterMyReportPayLoad = (values, selectedSubLocs, subLocs, reportFilterCategory) => {
    const filterReportRequest = {
        products: [],
        status: [],
        dateSearchBy: '',
        fromDate: '',
        toDate: '',
        workStates: [],
        workProvinces: [],
        selectedSubLocs,
        subLocs,
    };
    if (values) {
        const productGroupSelection = initialState.products
            .filter((product) => values[product.code] === true)
            .map((product) => product.code);
        filterReportRequest.products.push(...productGroupSelection);
        const statusGroupSelection = initialState.filterStatusCodes
            .filter((statusCode) => values[statusCode.code] === true &&
            ((reportFilterCategory === 'Accommodation Services' && statusCode.label !== 'Denied') ||
                reportFilterCategory !== 'Accommodation Services'))
            .map((statusCode) => statusCode.code);
        filterReportRequest.status.push(...statusGroupSelection);
        filterReportRequest.dateSearchBy = values['dateSearchBy'] ? values['dateSearchBy'].toString() : '';
        filterReportRequest.fromDate = values['fromDate']
            ? values['fromDate'].toString().replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '/')
            : '';
        filterReportRequest.toDate = values['toDate']
            ? values['toDate'].toString().replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '/')
            : '';
        const workStates = workStatesModel.filter((workState) => values[workState.stateCode] === true);
        filterReportRequest.workStates.push(...workStates);
        const workProvinces = workProvincesModel.filter((workProvince) => values[workProvince.provinceCode] === true);
        filterReportRequest.workProvinces.push(...workProvinces);
    }
    return filterReportRequest;
};
export const getSelectedClaimProductsFromFormApi = (formApi) => {
    const selectedProducts = initialState.products
        .filter((product) => formApi.getValue(product.code) === true)
        .map((product) => product.code);
    return selectedProducts;
};
export const validateDateSection = (formValues) => {
    const selectedStatusCounts = initialState.filterStatusCodes.filter(({ code }) => formValues && formValues[code] === true).length;
    const dateSearchBy = formValues && formValues['dateSearchBy'];
    return selectedStatusCounts > 0 || dateSearchBy ? undefined : 'Please select Status and/or Date';
};
export const validateReportCategory = (formValues) => formValues && formValues['reportCategory'] ? undefined : 'Required';
export const initializeFormValues = (products, filterStatusCodes, reportDateRange, workStates, workProvinces) => {
    const initialValues = {};
    // Initialize products
    products.forEach(product => {
        initialValues[product.code] = product.checked;
        if (product.checked) {
            initialValues.productgroup = initialValues.productgroup ? initialValues.productgroup : [];
            initialValues.productgroup.push(product.code);
        }
    });
    // Initialize status codes
    filterStatusCodes.forEach((statusCode) => {
        initialValues[statusCode.code] = statusCode.checked;
        if (statusCode.checked) {
            initialValues.statusgroup = initialValues.statusgroup ? initialValues.statusgroup : [];
            initialValues.statusgroup.push(statusCode.code);
        }
        for (const key of Object.keys(reportDateRange)) {
            initialValues[key] = reportDateRange[key] ? reportDateRange[key].toString() : '';
        }
    });
    // Initialize States and Locations
    const workStatesAndLocationsCount = workStates.length + workProvinces.length;
    initializeWorkStates(workStatesAndLocationsCount, workStates, initialValues);
    initializeWorkLocations(workStatesAndLocationsCount, workProvinces, initialValues);
    return initialValues;
};
const initializeWorkStates = (selectedStatesAndWorkLocations, workStates, initialValues) => {
    if (workStates && selectedStatesAndWorkLocations > 0) {
        workStates.forEach((workstate) => {
            initialValues[workstate.stateCode] = true;
        });
    }
    else {
        workStatesModel.forEach((workstate) => {
            initialValues[workstate.stateCode] = true;
        });
    }
};
const initializeWorkLocations = (selectedStatesAndWorkLocations, workLocations, initialValues) => {
    if (workLocations && selectedStatesAndWorkLocations > 0) {
        workLocations.forEach((workLocation) => {
            initialValues[workLocation.provinceCode] = true;
        });
    }
    else {
        workProvincesModel.forEach((workLocation) => {
            initialValues[workLocation.provinceCode] = true;
        });
    }
};
export const getProductDisplayLabel = (product) => {
    const { code, label } = product;
    return code === label ? label : label ? `(${code}) ${label}` : `(${code})`;
};
export const countOfSelectedStates = (states) => {
    return states.filter((state) => state.stateCode !== 'AWS').length;
};
export const countOfSelectedLocations = (locations) => {
    return locations.filter((location) => location.provinceCode !== 'AWL').length;
};
export const totalStatesAndLocations = (states, locations) => {
    let total = 0;
    const userSelectedStatesCount = countOfSelectedStates(states);
    const userSelectedLocationsCount = countOfSelectedLocations(locations);
    const prevSelectedStatesAndProvincesCount = userSelectedStatesCount + userSelectedLocationsCount;
    total = prevSelectedStatesAndProvincesCount > 0 ? userSelectedStatesCount : countOfSelectedStates(workStatesModel);
    total +=
        prevSelectedStatesAndProvincesCount > 0 ? userSelectedLocationsCount : countOfSelectedLocations(workProvincesModel);
    return total;
};
export const statusSelected = (values) => {
    return initialState.filterStatusCodes
        .filter((statusCode) => values[statusCode.code] === true)
        .map((statusCode) => statusCode.code);
};
