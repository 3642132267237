import React, { useMemo, useState } from 'react';
import { Alert, Spinner } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import styles from './LeaveDetails.scss';
import { useExperience } from 'status/hooks';
import formatDateMMDDYYY from 'status/utilities/formatDateMMDDYYY';
import titleCase from 'status/utilities/titleCase';
import getMethodSent from 'status/utilities/getMethodSent';
import { useLanguage, useUiData } from 'status/hooks';
import CorrespondenceFilter from '../filters/CorrespondenceFilter';
import useGet from '../../../../../hooks/useGet';
import CorrespondenceCards from '../ClaimDetails/CorrespondenceCards';
import downloadPdf from 'status/utilities/downloadPdf';

const Error = ({ error, noResults }) => {
  const notFound = noResults || (error.response && error.response.status === 404);
  return (
    <Alert type={notFound ? 'info' : 'error'}>
      {notFound ? (
        'There are no documents to display at this time.'
      ) : (
        <span>
          <strong>Error!</strong>&nbsp;Unable to load correspondence documents.
        </span>
      )}
    </Alert>
  );
};

const openPdfDocument = (get, href, title, i, id) => {
  get({
    url: `/status-service/correspondence/retrieve/document/${id}?docURL=${href}`,
    options: {
      headers: {
        Accept: 'application/pdf;',
        'Content-Type': 'application/json; charset=utf-8',
      },
      responseType: 'blob',
    },
    success: res => downloadPdf(res, title),
  });
};

const Correspondence = ({ leaveNum }) => {
  const { experience } = useExperience();
  const { language } = useLanguage();
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState();

  const { error: uiDataError, loading: uiDataLoading, uiData } = useUiData();

  const { error, loading, data } = useGet({
    url: `/status-service/correspondence/search/documents/${experience}/LEAVE/ASO`,
    options: {
      params: {
        claimLeaveNum: leaveNum,
      },
    },
  });

  const {
    error: downloadError,
    loading: downloading,
    get,
  } = useGet({
    noInitial: true,
  });

  const formatDocToDisplay = doc => {
    return {
      ...doc,
      documentType: titleCase(doc.documentType),
      productTypeCode: titleCase(doc.productTypeCode),
      documentDate: formatDateMMDDYYY(new Date(doc.documentDate)),
      sentDate: doc.sentDate ? formatDateMMDDYYY(new Date(doc.sentDate)) : '',
      sentToDistributionMethod: getMethodSent(doc.sentToDistributionMethodCode, uiData),
    };
  };

  const formattedData = useMemo(() => {
    if (filteredData) {
      return filteredData.map(doc => formatDocToDisplay(doc));
    } else if (data) {
      return data._embedded.documentList.map(doc => formatDocToDisplay(doc));
    }
  }, [data, uiData, language, filteredData]);

  if (loading) {
    return <Spinner id='leave-correspondence-spinner' />;
  }

  const initialData = data ? data._embedded.documentList : undefined;
  const noResults = initialData && initialData.length === 0;

  if (error || noResults) {
    return <Error error={error} noResults={noResults} />;
  }

  return (
    <div className={styles.tableContainer}>
      <CorrespondenceFilter initialData={formattedData} setFilteredData={setFilteredData} />
      {!noResults && (
        <>
          <CorrespondenceCards openDocGet={get} openDoc={openPdfDocument} correspondenceList={formattedData} />
          <span>{t('questionsCaseManagerText')}</span>
        </>
      )}
    </div>
  );
};

export default Correspondence;
