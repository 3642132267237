import React, { useEffect, useState } from 'react';
import { Form } from 'informed';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import useEoiContext from '../../hooks/useEoiContext';
import { format } from 'date-fns';
import useTranslate from '../../../../hooks/useTranslate';
import { SaveForLaterText } from '../../i18n/SaveForLaterText';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import styled from 'styled-components';
const LoadSavedData = ({ savedInterview, setIsVerified }) => {
    const { setVerifyingUserIdentity, setSavedInterview, setCoverageElections, setVerificationInformation, setAdditionalInformation, setMedicalInformationAnswers, setCriticalIllnessAnswers, setApplicants, setApplicantTypes, setReflexiveAnswers, } = useEoiContext();
    const { t } = useTranslate();
    const [interview, setInterview] = useState();
    const { trackClickWithPage } = useGoogleAnalytics();
    useEffect(() => {
        setInterview(JSON.parse(savedInterview.interviewAnswers));
    }, [savedInterview]);
    const StyledDiv = styled('div') `
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: 765px) {
      flex-direction: column;
    }
  `;
    // prepop endpoint has not been called yet so IInterviewAnswers will be uninitialized/empty.
    const handleOnSubmit = () => {
        trackClickWithPage('loadSavedInterview', '/customer/eoi/verification');
        setVerifyingUserIdentity(true);
        setSavedInterview(true);
        setIsVerified(true); // all saved data is verified.
        if (interview.coverageElections) {
            setCoverageElections(interview.coverageElections);
        }
        if (interview.verificationInformation) {
            setVerificationInformation(interview.verificationInformation);
        }
        if (interview.additionalInformation) {
            setAdditionalInformation(interview.additionalInformation);
        }
        if (interview.medicalInformationAnswers) {
            setMedicalInformationAnswers(interview.medicalInformationAnswers);
        }
        if (interview.criticalIllnessAnswers) {
            setCriticalIllnessAnswers(interview.criticalIllnessAnswers);
        }
        if (interview.applicants) {
            setApplicants(interview.applicants);
        }
        setApplicantTypes(interview.applicantTypes);
        setReflexiveAnswers(interview.reflexiveAnswers);
        setVerifyingUserIdentity(false);
    };
    return (<Form onSubmit={handleOnSubmit} data-testid='loadSavedInterview-form' data-public={true}>
      <>
        <Heading elemType={'h3'} elemStyle={'h4'} style={{ marginTop: '.5rem' }}>
          {t(SaveForLaterText.loadSavedInterviewHeader)}
        </Heading>
        <StyledDiv style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}` }}>
          <Heading elemType={'h3'} elemStyle={'h3'}>
            {t(SaveForLaterText.loadSavedInterviewBody)}
            <br />
            {format(new Date(savedInterview.savedDate), 'MM/dd/yyyy')}
            {t(SaveForLaterText.at)}
            {format(new Date(savedInterview.savedDate), 'hh:mm a')}
          </Heading>
          <Button theme={themes.lincoln} buttonType='primary' type='submit'>
            {t(SaveForLaterText.loadInterviewButtonText)}
          </Button>
        </StyledDiv>
        <hr />
      </>
    </Form>);
};
export default LoadSavedData;
