import { Language } from '../../../types/Language';
export const UserProfileInformationDisplayTextEs = {
    language: Language.SPANISH,
    profileHeading: 'Información del perfil',
    profileHeadingSso: 'Información del perfil',
    employerNameLabel: 'Nombre del empleador',
    firstNameLabel: 'Nombre',
    firstNamePlaceholder: 'Ingresar nombre',
    lastNameLabel: 'Apellido',
    lastNamePlaceholder: 'Ingresar apellido',
    preferredPhoneLabel: 'Teléfono de preferencia',
    phoneHelperText: 'Recibirá un código de seguridad en este número al momento de iniciar sesión.',
    preferredEmailLabel: 'Correo electrónico de preferencia',
    reEnterPreferredEmailLabel: 'Confirmar correo electrónico de preferencia',
    preferredEmailPlaceholder: 'Ingresar dirección de correo electrónico',
    saveChangesButton: 'Guardar cambios',
    formSubmitError: `Algo salió mal. Intente nuevamente dentro de unos instantes o llame al 1-800-431-2958 (de 8:00 AM a 8:00 PM EST) para recibir asistencia.`,
    formSubmitSuccess: 'Éxito! Su información ha sido guardada.',
    emailInUseError: 'La dirección de correo electrónico ya está en uso.',
    emailVerificationHead: 'Aún debe confirmar su dirección de correo electrónico!',
    emailVerificationBegin: 'Hemos enviado un correo electrónico de confirmación a ',
    emailVerificationEnd: '. Debe presionar el enlace en ese mensaje para que su cambio de correo electrónico tome efecto.',
    emailHelperText: 'Si usted cambia su dirección de correo electrónico, deberá iniciar una nueva sesión y un correo electrónico de verificación será enviado a su nueva cuenta.',
    ssoUserAlert: 'Por favor, diríjase a la página web de su Empleador para actualizar la información de su perfil.',
    mlpEmpCustCode: 'Para actualizar su dirección de correo electrónico, cambiar su nombre para mostrar o restablecer su número de teléfono para la autenticación, llame al 866-360-0683'
};
