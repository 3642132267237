import React from 'react';
import { Alert, Col, Row } from '@digitools/honest-react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import useDentalDataContext from './hooks/useDentalContext';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import MemberInformationBox from 'src/packages/dental/components/MemberInformationBox';
import { Spinner } from '@digitools/honest-react';
import TabContent from './components/TabContent';
import useMediaQuery from 'src/hooks/useMediaQuery';
import MemberDropdown from 'src/packages/dental/components/MemberDropdown';
import useSetCurrentMember from 'src/packages/dental/hooks/useSetCurrentMember';
const PathNavLink = (props) => {
    return <NavLink {...props}/>;
};
export const StyledNavLink = styled(PathNavLink) `
  color: ${({ theme }) => `${theme.palette.black}`};
  font-weight: 400;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 1rem 0rem 1rem 1rem;

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  }

  &:before {
    content: '';
    height: 50%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:focus {
    color: ${({ theme }) => `${theme.palette.primary}`};
    font-weight: 500;
    &:before {
      background: ${({ theme }) => `${theme.palette.primary}`};
    }
  }
`;
const Dental = () => {
    const { subscriber, currentMember, success, errorMessage, loading } = useDentalDataContext();
    const { trackEvent, trackView } = useTealium();
    const trackDropdownEvent = (member) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
            event_name: TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: member,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            user_role: 'employee',
        });
    };
    const isMobile = useMediaQuery('(max-width: 1439px)');
    useSetCurrentMember();
    if (loading) {
        return <Spinner data-testid='dental-spinner'/>;
    }
    return (<>
      {' '}
      {success ? (<>
          {currentMember && subscriber && (<>
              {isMobile ? (<Col>
                  <Row style={{ padding: '.5rem', paddingBottom: '.5rem' }}>
                    {subscriber.planOverview?.planFundingClause}
                  </Row>
                  <MemberDropdown url={''} trackDropdownEvent={trackDropdownEvent}/>
                  <MemberInformationBox />
                  <TabContent />
                </Col>) : (<Col>
                  <Row style={{ padding: '.3rem', paddingBottom: '.5rem' }}>
                    {subscriber.planOverview?.planFundingClause}
                  </Row>
                  <Row>
                    <Col xl={2} lg={3}>
                      <nav style={{ display: 'flex', flexDirection: 'column' }}>
                        {[...[subscriber], ...(subscriber.dependents || [])].map((member, index) => (<StyledNavLink data-testid={`navlink-${member.id}`} key={encode(member?.id)} data-public={true} to={{
                            pathname: `/employee/dental/members/${encode(member?.id)}`,
                        }} onClick={() => {
                            trackDropdownEvent(index === 0 ? 'subscriber' : 'member' + index);
                        }}>
                            {`${member?.firstName} ${member?.lastName}`}
                          </StyledNavLink>))}
                      </nav>
                    </Col>

                    <Col xl={10} lg={9}>
                      <div style={{ height: '210px' }}>
                        <MemberInformationBox />
                      </div>
                      <TabContent />
                    </Col>
                  </Row>
                </Col>)}
            </>)}
        </>) : (<Alert type={'error'}>
          <strong>Error!</strong>&nbsp;{errorMessage}
        </Alert>)}
    </>);
};
export default Dental;
