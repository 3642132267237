import React, { useState, useContext } from 'react';
import { Row, Col, Checkbox, CheckboxGroup, SelectAllCheckbox, Box } from '@digitools/honest-react';
import { handleProductAllSelection, checkBoxSelection } from '../api';
import { AuthenticationContext } from '../../../providers/AuthenticationProvider';
const Crs = (props) => {
    const authContext = useContext(AuthenticationContext);
    const hasCrsAccess = props.permissions.permission.crsER;
    const crsChange = (event) => {
        props.userInfo.customerReportingSolution = event;
    };
    const [crsEnabled, setCrsEnabled] = useState(props.userInfo.customerReportingSolution);
    const crsUpdate = (event) => {
        setCrsEnabled(event.currentTarget.checked);
        crsChange(event.currentTarget.checked);
    };
    return (<>
      {hasCrsAccess ? (<div style={{ marginTop: '1.5rem' }}>
          <Box type='header' color={'grayLighter'}>
            <Checkbox field='createMyReport' label={'Create My Report'} initialValue={props.userInfo.customerReportingSolution} onChange={crsUpdate}/>
          </Box>
          <Box>
            {props.sensitiveData.length > 0 ? (<CheckboxGroup field={'sensitiveDataGroup'} label={'Sensitive Data'} validateOnChange={true}>
                <Row>
                  <Col lg={4}>
                    <SelectAllCheckbox field={'sensitiveAll'} disabled={!crsEnabled} onChange={(event) => handleProductAllSelection({ code: 'sensitiveAll', label: 'All', checked: event.currentTarget.checked }, props.sensitiveData, 'crsSensitive', props.userInfo, props.customerConfig)}/>
                  </Col>
                </Row>
                <Row style={{ marginTop: '-0.05rem' }}>
                  {checkBoxSelection(props.sensitiveData, 'crsSensitive', crsEnabled, props.userInfo, props.customerConfig)}
                </Row>
              </CheckboxGroup>) : (<></>)}
          </Box>
        </div>) : (<></>)}
    </>);
};
export default Crs;
