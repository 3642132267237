export var MigrateAlertType;
(function (MigrateAlertType) {
    MigrateAlertType["UNEXPECTED"] = "UNEXPECTED";
    MigrateAlertType["CLEAR"] = "CLEAR";
})(MigrateAlertType || (MigrateAlertType = {}));
export const initialState = null;
export default function reducer(state, action) {
    switch (action.type) {
        case MigrateAlertType.CLEAR:
            return null;
        default:
            return {
                alertTitle: 'unexpectedTitle',
                alertBody: 'unexpectedBody',
                type: 'error',
            };
    }
}
