import React from 'react';
// @ts-ignore
import styles from './Spinner.scss';
const Spinner = ({ id = 'spinner', center = false, style = {}, size = 'large', ...rest }) => {
    if (center) {
        return (<div id={id} className={styles.center}>
        <div className={`${styles.loader} ${styles[size]}`} style={style} {...rest}/>
      </div>);
    }
    return <div id={id} className={`${styles.loader} ${styles[size]}`} style={style} {...rest}/>;
};
export default Spinner;
