export const MigrateDisplayText = {
    newPassword: {
        english: `New Password`,
        spanish: `Nueva contraseña`,
    },
    newPasswordRe: {
        english: `Confirm New Password`,
        spanish: `Confirmar nueva contraseña`,
    },
    migrate: {
        english: `Migrate Me`,
        spanish: `Migrate Me`,
    },
    unexpectedBody: {
        english: `Please try submitting your information again, if you continue to experience any problems, contact us for further assistance.`,
        spanish: `Intente enviar su información nuevamente, si aún tiene problemas, contáctenos para obtener más ayuda.`,
    },
    unexpectedTitle: {
        english: `Something went wrong!`,
        spanish: `Algo salió mal!`,
    },
    passwordsDontMatchBody: {
        english: ``,
        spanish: ``,
    },
    passwordsDontMatch: {
        english: `Passwords must match`,
        spanish: `Las contraseñas deben coincidir`,
    },
    loginToLFG: {
        english: `Login to LFG`,
        spanish: `Iniciar sesión en LFG`,
    },
    migrateSuccessMessage: {
        english: `Your migration to LFG.com has been completed. From now on you can start accessing MyLincolnPortal
    by logging into LFG.com with your same username and the password you just entered.`,
        spanish: ``,
    },
    heading: {
        english: `It’s time to move your account to LincolnFinancial.com`,
        spanish: `Es hora de mover su cuenta a LincolnFinancial.com`,
    },
    infoParagraph: {
        english: `We are making changes to where you log in to access your benefits. `,
        spanish: `Estamos haciendo cambios en el lugar donde inicia sesión para acceder a sus beneficios. `,
    },
    infoSubParagraph: {
        english: `To move your account to LincolnFinancial.com, update your information.`,
        spanish: `Para mover tu cuenta a LincolnFinancial.com, actualiza tu información.`,
    },
    learnMore: {
        english: `Learn more`,
        spanish: `Obtenga más información`,
    },
    unexpectedErrorAlert: {
        english: `The system has experienced an unexpected error. Your migration cannot be processed at this time.`,
        spanish: `El sistema ha experimentado un error inesperado. La migración no se puede procesar en este momento.`,
    },
    migrateButton: {
        english: `Move my account`,
        spanish: `Trasladar mi cuenta`,
    },
    loadingText: {
        english: `We are moving your account to LincolnFinancial.com`,
        spanish: `We are moving your account to LincolnFinancial.com`,
    },
    LCN: {
        english: `LCN-5999502-100423`,
        spanish: `LCN-6526599-040324`,
    },
    termsAndConditions: {
        privacyStatementAgreeRequired: {
            english: `You must agree to the Privacy Statement and Web Agreement to continue.`,
            spanish: `You must agree to the Privacy Statement and Web Agreement to continue.`,
        },
        agreeToPrivacyStatement: {
            english: `I have read and agree to the following:`,
            spanish: `I have read and agree to the following:`,
        },
        termsAndConditionsHeader: {
            english: `Online Terms and Conditions`,
            spanish: `Ver Declaración de Privacidad y Acuerdo Web`,
        },
        eSignature: {
            english: `eSignature`,
            spanish: `eSignature`,
        },
        eTransmissions: {
            english: `eTransmissions`,
            spanish: `eTransmissions`,
        },
        tfa: {
            english: `Two-Factor authentication`,
            spanish: `Autenticación de dos factores`,
        },
    },
    success: {
        heading: {
            english: `Your account has been moved`,
            spanish: `Se trasladó su cuenta`,
        },
        infoParagraph: {
            english: `From now on, log in to access your benefits at LincolnFinancial.com.`,
            spanish: `En adelante, inicie sesión en LincolnFinancial.com para acceder a sus beneficios.`,
        },
        infoLink: {
            english: `LincolnFinancial.com`,
            spanish: `LincolnFinancial.com`,
        },
        infoParagraph2: {
            english: `Please bookmark`,
            spanish: `Marque`,
        },
        infoParagraph3: {
            english: `or download the Lincoln Financial Mobile app to quickly log in and access your benefits.`,
            spanish: `como favorito o descargue la aplicación Lincoln Financial Mobile para iniciar sesión rápidamente y acceder a sus beneficios.`,
        },
        loginButton: {
            english: `LOG IN`,
            spanish: `INICIAR SESIÓN`,
        },
    },
    updatePassword: {
        passwordDictionaryError: {
            english: 'Please choose a stronger password.',
            spanish: 'Por favor elija una contraseña más segura.',
        },
        passwordStrengthError: {
            english: 'Please choose a stronger password.',
            spanish: 'Por favor elija una contraseña más segura.',
        },
        passwordNoUserInfoError: {
            english: 'Please choose a password that does not contain your personal information.',
            spanish: 'Por favor elija una contraseña que no contenga su información personal.',
        },
    },
    updateUsername: {
        usernameLabel: {
            english: `New Username`,
            spanish: `Nuevo nombre de usuario`,
        },
        invalidUsername: {
            english: `Username not available. Please try again.`,
            spanish: `Nombre de usuario no disponible. Inténtalo de nuevo.`,
        },
        usernameReq: {
            english: `5-35 characters, at least 1 letter, no special characters, spaces, or 9 consecutive numbers.`,
            spanish: `5-35 caracteres, al menos 1 letra, sin caracteres especiales, espacios o 9 números consecutivos.`,
        },
    },
    emailClash: {
        heading: {
            english: `Access to your benefits is now on LincolnFinancial.com`,
            spanish: `El acceso a sus beneficios ahora está en LincolnFinancial.com`,
        },
        infoParagraph1: {
            english: `It looks like you already have an account with this email on LincolnFinancial.com`,
            spanish: `Parece que ya tiene una cuenta con este email en LincolnFinancial.com`,
        },
        infoParagraph2: {
            english: `To access your benefits, bookmark the site and log in now or download the Lincoln Financial Mobile app.`,
            spanish: `Para acceder a sus beneficios, agregue el sitio a favoritos e inicie sesión ahora o descargue la aplicación Lincoln Financial Mobile.`,
        },
        loginText: {
            english: `LOG IN`,
            spanish: `INICIAR SESIÓN`,
        },
        footerText: {
            english: `How do I access my benefits?`,
            spanish: `¿Cómo accedo a mis beneficios?`,
        },
        learnMore: {
            english: ` Learn more`,
            spanish: ` Obtenga más información`,
        },
    },
};
