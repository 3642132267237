import React, { Children, createContext, useEffect, useState } from 'react';
export const StepperContext = createContext({
    step: 0,
    next: () => { },
    back: () => { },
    goToStep: () => { },
    stepHistory: [],
    isLfg: false,
    setIsLfg: () => { },
    isMigrated: false,
    setIsMigrated: () => { },
});
/** Stepper that allows navigation forward and back between the children of the Provider
 *
 * @param setShowLogin - used by MigrationHelp page container to know whether or not to display the MLP link at the top
 * @param skipToStep - default 0, initializes the first step to be this number, stepHistory will populate to allow you to go back
 */
export const StepperProvider = ({ setShowLogin, skipToStep = 0, children }) => {
    const arrayChildren = Children.toArray(children);
    // Skip automatically to correct step for Employees if needed - need to also set up history so back works
    // skipToStep = 0 then stepHistory = [0] &  skipToStep = 3 then stepHistory = [0,1,2,3]
    const [step, setStep] = useState(skipToStep);
    const [stepHistory, setStepHistory] = useState(skipToStep ? Array.from({ length: skipToStep + 1 }, (_, index) => index) : [step]);
    const [isLfg, setIsLfg] = useState(false);
    const [isMigrated, setIsMigrated] = useState(false);
    useEffect(() => {
        console.log(step);
        if (step < 2) {
            setShowLogin(false);
        }
        else {
            setShowLogin(true);
        }
    }, [step]);
    const goToStep = (step) => {
        setStep(step);
        setStepHistory(prev => [...prev, step]);
    };
    const next = () => {
        if (step <= arrayChildren.length) {
            setStepHistory(prev => [...prev, step + 1]);
            setStep(step + 1);
        }
    };
    const back = () => {
        // console.log('<- in back');
        // console.log('<- current step:', step);
        // console.log('<- stepHistory:', stepHistory);
        stepHistory.pop();
        if (step > 0) {
            // console.log('<- new step:', stepHistory[stepHistory.length - 1]);
            setStep(stepHistory[stepHistory.length - 1]);
        }
    };
    const stepperContext = {
        step,
        next,
        back,
        goToStep,
        stepHistory,
        isLfg,
        setIsLfg,
        isMigrated,
        setIsMigrated,
    };
    return (<StepperContext.Provider value={stepperContext}>
      {arrayChildren && arrayChildren.map((child, index) => <>{step === index && child}</>)}
    </StepperContext.Provider>);
};
