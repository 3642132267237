import { Box, Row, Col, Heading, Icon } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import usePortalMessenger from 'src/hooks/usePortalMessenger';
import useTranslate from 'src/hooks/useTranslate';
import styled from 'styled-components';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
const StyledBox = styled(Box) `
margin-top: 4rem;
width: 9.25rem;
@media only screen and (min-width : 1200px)  {
  margin-left: 3.375rem;
}
@media only screen and (max-width : 1200px)  {
  margin-left: 0;
}

`;
export const maintenanceText = {
    maintenanceTitleHeaderText: {
        english: 'Planned system maintenance:',
        spanish: 'Mantenimiento planificado del sistema:',
    },
    maintenanceBodyHeaderText: {
        english: 'The Lincoln Financial Group website is currently undergoing maintenance. It is temporarily unavailable. We apologize for any inconvenience.',
        spanish: 'El sitio web de Lincoln Financial Group se encuentra actualmente en mantenimiento y no está disponible temporalmente. Nos disculpamos por cualquier inconveniente.',
    },
    maintenanceTitleText: {
        english: 'Planned system maintenance',
        spanish: 'Mantenimiento planificado del sistema',
    },
    maintenanceBodyText: {
        english: 'The Lincoln Financial website is currently undergoing maintenance. It is currently unavailable. We apologize for any inconvenience.',
        spanish: 'Le site Web de Lincoln Financial est actuellement en cours de maintenance et est actuellement indisponible. Nous nous excusons pour tout inconvénient.'
    },
    maintenanceBodyText2: {
        english: 'If you require further assistance please visit our ',
        spanish: 'Si vous avez besoin daide supplémentaire, veuillez visiter notre ',
    },
    contactSupportLink: {
        english: 'contact support',
        spanish: 'Contactez le support'
    }
};
// SSO Users will be redirected to this static component while mlp is under maintenance mode
const Maintenance = () => {
    const { t } = useTranslate();
    const { addMessage, clearMessages } = usePortalMessenger();
    useEffect(() => {
        addMessage({
            type: 'warning',
            title: [maintenanceText.maintenanceTitleHeaderText.english, maintenanceText.maintenanceTitleHeaderText.spanish],
            message: [<>{maintenanceText.maintenanceBodyHeaderText.english}</>, <>{maintenanceText.maintenanceBodyHeaderText.spanish}</>],
        });
        return () => {
            clearMessages();
        };
    }, []);
    return (<><div data-testid='sso-maintenance'>
    <Row>
      <Col md={2} xs={12}/>
      <Col md={2} xs={12}>
        <StyledBox type='color' theme={themes.secureSite}>
          <Icon name="maintenance" style={{ fontSize: '5rem', marginLeft: '.7rem' }} color={'white'}/>
        </StyledBox>
      </Col>
    </Row>
    <Row>
      <Col md={4} xs={12}/>
      <Col md={6} xs={12}>
        <Heading elemStyle={'h1'} elemType={'h1'} color={'primaryDark'}>
          {t(maintenanceText.maintenanceTitleText)}
        </Heading>
        <br></br>
        <p>{t(maintenanceText.maintenanceBodyText)}</p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '12.5rem' }}>
      <Col md={4} xs={12}/>
      <Col md={7} xs={12}><p>
        {t(maintenanceText.maintenanceBodyText2)}<a href='/customer/public/contacts' data-testid='contact-support'><b>{t(maintenanceText.contactSupportLink)}.</b></a></p>
      </Col>
    </Row>
  </div>
  </>);
};
export default Maintenance;
