import axios from 'axios';
export const getBeneficiaries = async (authHeader, requestBody) => {
    const api = `/bdm-service/services/v1/beneficiaries`;
    const response = await axios.post(api, requestBody, {
        headers: {
            Authorization: authHeader,
        },
    });
    return response.data;
};
export const downloadPdf = async (data, authHeader) => {
    return axios.post(`/bdm-service/services/v1/beneficiaries/pdf`, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
        responseType: 'blob',
    });
};
export const saveBeneficiaries = async (authHeader, bdmSubmission) => {
    const api = '/bdm-service/services/v1/beneficiaries';
    const response = await axios.put(api, bdmSubmission, {
        headers: {
            Authorization: authHeader,
        },
    });
    return response.status;
};
