import { tomorrowDateString } from "../../eoi/utils/HelperFunctions";
export const PersonalInformationText = {
    headingER: {
        english: 'Employee\'s personal information',
        spanish: 'Información personal del Empleado',
    },
    heading: {
        english: 'Personal information',
        spanish: 'Informacion personal',
    },
    firstName: {
        english: 'First name',
        spanish: 'Nombre de pila'
    },
    middleInitial: {
        english: 'Middle initial (optional)',
        spanish: 'Inicial del segundo nombre (opcional)'
    },
    lastName: {
        english: 'Last name',
        spanish: 'Apellido'
    },
    dateOfBirthEE: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento'
    },
    gender: {
        english: 'Sex assigned at birth',
        spanish: 'Sexo asignado al nacer'
    },
    male: {
        english: 'Male',
        spanish: 'Masculino'
    },
    female: {
        english: 'Female',
        spanish: 'Hembra'
    },
    dobErrorMessage: {
        english: `Date is not between 01/01/1900 and ${tomorrowDateString()}`,
        spanish: `La fecha no está entre 01/01/1900 y ${tomorrowDateString()}`
    }
};
