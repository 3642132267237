import React from 'react';

import { Heading, Icon, Link, Alert } from '@digitools/honest-react';
import { usePost, useExperience, useAuth0 } from 'status/hooks';
import { useTranslation } from 'react-i18next';
import { isTeamViewReadOnly, isTeamView } from './../../../../../packages/public/usaa/utils/CustomerUtils';

const teamViewTDSSaveURL = '/mlp-ui-service/api/tds/teamview';

const CLILink = ({ post, appName, empId }) => {
  const success = () => {
    window.location.assign('/customer/cli/employer/intake');
  };
  const handleClick = () => {
    post({
      payload: {
        app: appName,
        empId: empId,
      },
      success,
    });
  };
  return (
    <Link onClick={handleClick} data-testid={'SubmitClaim'} aria-label={'Submit Claim'} href='#'>
      <Icon name='claims' style={{ marginRight: '0.5rem' }} />
      Submit Claim
    </Link>
  );
};

const ASERLink = ({ post, appName, empId }) => {
  const success = () => {
    window.location.assign('/customer/as/employer');
  };
  const handleClick = () => {
    post({
      payload: {
        app: appName,
        empId: empId,
      },
      success,
    });
  };
  return (
    <Link
      onClick={handleClick}
      style={{ marginLeft: '2rem' }}
      data-testid={'Request Accomodation'}
      aria-label={'Request Accomodation'}
      href='/customer/as/employer'>
      <Icon name='building-outline' style={{ marginRight: '0.5rem' }} />
      Request Accommodation
    </Link>
  );
};

const UAERLink = ({ post, appName, empId }) => {
  const success = () => {
    window.location.assign('/customer/employer/unplanned-absence');
  };
  const handleClick = () => {
    post({
      payload: {
        app: appName,
        empId: empId,
      },
      success,
    });
  };
  return (
    <Link
      onClick={handleClick}
      style={{ marginLeft: '2rem' }}
      data-testid={'UnplannedAbsence'}
      aria-label={'Report or Change an Unplanned Absence'}
      href='#'>
      <Icon name='calendar' style={{ marginRight: '0.5rem' }} />
      Report or Change an Unplanned Absence
    </Link>
  );
};

const ErrorAlert = ({ error }) => {
  return error ? (
    <Alert type={'error'} closeable={true}>
      <span>
        <strong>Error!</strong>&nbsp;Something went wrong, please try again.
      </span>
    </Alert>
  ) : null;
};

const EmployeeActionLinks = ({ empId }) => {
  const { userMeta } = useAuth0();
  const applicationPermissions = userMeta.applicationPermissions;
  const isUaEr = applicationPermissions.indexOf('uaER') > -1;
  const isCliEr = applicationPermissions.indexOf('cliER') > -1;
  const isAsEr = applicationPermissions.indexOf('asER') > -1;
  const showEmployeeAction = (isUaEr || isCliEr || isAsEr) && !isTeamViewReadOnly(userMeta);
  const { t } = useTranslation();
  const { post, error } = usePost({
    url: teamViewTDSSaveURL,
  });
  return (
    <>
      {showEmployeeAction ? (
        <>
          <Heading elemType={'h1'} style={{ marginBottom: '2rem' }}>
            Employee Actions
          </Heading>
          <ErrorAlert error={error} />
          <div style={{ marginBottom: '2rem' }}>
            {isCliEr ? <CLILink post={post} appName={'CLI'} empId={empId} /> : null}
            {isUaEr ? <UAERLink post={post} appName={'UA'} empId={empId} /> : null}
            {isAsEr ? <ASERLink post={post} appName={'AS'} empId={empId} /> : null}
          </div>
          {isUaEr ? (
            <div className='d-flex align-items-center' style={{ marginBottom: '2rem' }}>
              {t('returnToViewTeamMemberText')}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

const TeamViewEmployeeActions = ({ empId }) => {
  const { userMeta } = useAuth0();
  const { experience } = useExperience();
  const teamView = isTeamView(userMeta);
  return experience === 'ER' && teamView && empId ? (
    <div style={{ marginBottom: '2rem' }}>
      <EmployeeActionLinks empId={empId} />
    </div>
  ) : null;
};

export default TeamViewEmployeeActions;
