import React from 'react';
import { Button, Modal } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
const RenderOpenModalButton = (getLocalDisplayText) => ({ showModal }) => {
    const handleOnClick = () => {
        showModal();
    };
    return (<Button buttonType='secondary' id='Cancel' type='button' style={{ marginRight: '.5rem' }} onClick={handleOnClick}>
        {getLocalDisplayText('cancelButtonLabel')}
      </Button>);
};
const FooterSecondaryRenderButton = (getLocalDisplayText) => ({ hideModal }) => {
    const handleOnClick = () => {
        hideModal();
    };
    return (<Button aria-label={'return to registration form'} buttonType={'secondary'} onClick={handleOnClick} type='button'>
        {getLocalDisplayText('cancelButtonLabel')}
      </Button>);
};
const FooterPrimaryRenderButton = (getLocalDisplayText, navigate) => ({ hideModal }) => {
    const handleOnClick = () => {
        hideModal();
        navigate('/public/login');
    };
    return (<Button aria-label={'confirm cancel'} buttonType={'primary'} type='button' onClick={handleOnClick}>
        {getLocalDisplayText('okButton')}
      </Button>);
};
export const CancelModal = ({ ...props }) => {
    const { getLocalDisplayText, history } = props;
    const navigate = useNavigate();
    return (<>
      <Modal hasCloseIcon={true} headerText={getLocalDisplayText('cancelModalHeading')} modalOpenRenderButton={RenderOpenModalButton(getLocalDisplayText)} footerSecondaryRenderButton={FooterSecondaryRenderButton(getLocalDisplayText)} footerPrimaryRenderButton={FooterPrimaryRenderButton(getLocalDisplayText, navigate)} footerContentPosition={'center'}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <p tabIndex={0}>{getLocalDisplayText('cancelModalBodyText')}</p>
      </Modal>
    </>);
};
export default CancelModal;
