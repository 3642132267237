import React from 'react';
const ReviewAndSignText = {
    reviewYourApplication: {
        english: 'Review your application',
        spanish: 'Revise su solicitud',
    },
    applicationReason: {
        english: 'You were instructed to fill out this application today for the following reason: ',
        spanish: 'Se le indicó que llenara esta solicitud hoy por el siguiente motivo: ',
    },
    generalInfo: {
        english: 'Please ensure all coverage selections are correct, for each application, before continuing.',
        spanish: 'Asegúrese de que todas las selecciones de cobertura sean correctas, para cada aplicación, antes de continuar.',
    },
    updateInstructions: {
        english: 'If you need to make updates to any applicant information, you will be directed back to the corresponding section of this application.',
        spanish: 'Si necesita actualizar la información del solicitante, se lo dirigirá nuevamente a la sección correspondiente de esta solicitud.',
    },
    coverageSelections: {
        english: 'COVERAGE SELECTION(S)',
        spanish: 'SELECCIONE(S) DE COBERTURA',
    },
    currentAmount: {
        english: 'Current coverage amount',
        spanish: 'Monto de cobertura actual',
    },
    requestedAmount: {
        english: 'Requested coverage amount',
        spanish: 'Cantidad de cobertura solicitada',
    },
    activeEmployee: {
        english: 'Actively at work',
        spanish: 'Activamente en el trabajo',
    },
    fullTimePartTime: {
        english: 'Full-time/part-time',
        spanish: 'Tiempo completo/medio Tiempo',
    },
    currentAnnualSalary: {
        english: 'Current annual salary',
        spanish: 'Salario anual actual',
    },
    signatureHeader: {
        english: 'Electronic agreement and signature',
        spanish: 'Acuerdo y firma electrónicos',
    },
    pleaseReview: {
        english: 'Please review the authorization disclosure and provide your consent prior to submitting your application.',
        spanish: 'Revise la divulgación de autorización y brinde su consentimiento antes de enviar su solicitud.',
    },
    acknowledgments: {
        english: 'Acknowledgments',
        spanish: 'Reconocimientos',
    },
    stateDisclosureNY: {
        english: 'State Disclosure(s)',
        spanish: 'Divulgación (es) estatal (es)',
    },
    fraudDisclosure: {
        english: 'Fraud Warning/State Disclosure(s)',
        spanish: 'Advertencia de fraude / divulgación (es) estatal (es)',
    },
    checkboxText: {
        english: 'I acknowledge that I have reviewed the authorization disclosure above and that I have received the ',
        spanish: 'Reconozco que he revisado la divulgación de autorización anterior y que he recibido el ',
    },
    noticeOfInformationPractices: {
        english: 'Notice of Information Practices',
        spanish: 'Aviso de Prácticas de Información',
    },
    downloadError: {
        english: 'Something went wrong downloading the Notice of Information Practices, please try again later.',
        spanish: 'Se produjo un error al descargar el Aviso de Prácticas de Información. Vuelve a intentarlo más tarde.',
    },
    eSignature: {
        english: 'ESIGNATURE(S)',
        spanish: 'FIRMA(S) ELECTRONICA(S)',
    },
    eSignatureConsent: {
        english: 'If you consent to the terms outlined above for your coverage application, type your full name in the box below and select submit.',
        spanish: 'Si acepta los términos descritos anteriormente para su solicitud de cobertura, escriba su nombre completo en el cuadro a continuación y seleccione enviar.',
    },
    eSignatureWarningNY: {
        english: <><b>By signing below, you agree that all statements made above are to the best of your knowledge and belief.<br /> </b></>,
        spanish: <><b>Al firmar a continuación, usted acepta que todas las declaraciones hechas anteriormente son según su leal saber y entender.<br /></b></>
    },
    eAgentSignature: {
        english: 'AGENT ESIGNATURE',
        spanish: 'AGENTE ELECTRONICA',
    },
    eAgentSignatureConsent1: {
        english: 'If an Agent assisted in the completion of this application form, the agent must sign below.',
        spanish: 'Si un agente ayudó a completar este formulario de solicitud, el agente debe firmar a continuación.',
    },
    eAgentSignatureConsent2: {
        english: 'I, the Agent, certify that I have truly and accurately recorded on the application form the information supplied by the applicant.',
        spanish: 'Yo, el Agente, certifico que he registrado de manera verdadera y precisa en el formulario de solicitud la información proporcionada por el solicitante.',
    },
    dependentsESignature: {
        english: 'DEPENDENT(S) ESIGNATURE',
        spanish: 'FIRMA ELECTRÓNICA DE DEPENDIENTE(S)',
    },
    dependentESignatureDisclaimer: {
        english: 'A signature of the child applicant is required only if applying for dependent insurance and the child applicant is over (18 years of age/age of majority of the state in which the child applicant resides).',
        spanish: 'Se requiere la firma del niño solicitante solo si solicita el seguro para dependientes y el niño solicitante es mayor de edad (18 años de edad / mayoría de edad del estado en el que reside el niño solicitante).',
    },
    underEighteenLabel: {
        english: 'Parent/Guardian Signature (Typed) on behalf of ',
        spanish: 'Firma del padre / tutor (mecanografiada) en nombre de ',
    },
    authorization: {
        english: 'AUTHORIZATION',
        spanish: 'AUTORIZACIÓN',
    },
    submittingApplication: {
        english: 'Submitting application...',
        spanish: 'Enviar solicitud...',
    },
    errorSubmittingApplication: {
        english: 'There was an error submitting your application. Please try again.',
        spanish: 'Hubo un error al enviar su solicitud. Inténtalo de nuevo.',
    },
    applicationNotAccepted: {
        english: 'Your application could not be accepted due to a system issue. Please call 1-800-431-2958 to report this issue.',
        spanish: 'No se pudo aceptar su solicitud debido a un problema del sistema. Llame al 1-800-431-2958 para informar este problema.',
    },
    firstLastName: {
        english: 'First last name',
        spanish: 'Nombre y apellido',
    },
    agentSignature: {
        english: 'Agent Signature',
        spanish: 'Firma del agente',
    },
    payrollDeductionAuthSignature: {
        english: 'Payroll Deduction Authorization',
        spanish: 'Autorización de deducción de nómina',
    },
    payrollDeductionAuthConsent1: {
        english: 'PAYROLL DEDUCTION AUTHORIZATION (Sign where indicated):',
        spanish: 'AUTORIZACIÓN DE DEDUCCIÓN DE NÓMINA (Firme donde se indica):',
    },
    payrollDeductionAuthConsent2: {
        english: 'I authorize any required deductions from my earnings',
        spanish: 'Autorizo ​​cualquier deducción requerida de mis ganancias',
    },
    applicantInitials: {
        english: 'Initials',
        spanish: 'Iniciales',
    },
};
export default ReviewAndSignText;
