import React from 'react';
import { Icon, Button, Col } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import { AddRequestText } from '../../constants/AddAccommodationRequestText';
import AccomodationRequest from './AccommodationRequest';
import useAsDataContext from '../../hooks/useAsDataContext';
const AddAccommodationRequest = () => {
    const { t } = useTranslate();
    const { requestGroup, setRequestGroup, requestCount, setRequestCount } = useAsDataContext();
    const maxRequestCount = 10;
    const addRequest = (e) => {
        e.preventDefault();
        if (requestCount < maxRequestCount) {
            setRequestCount((requestCount + 1));
            setRequestGroup([...requestGroup, <AccomodationRequest count={requestCount + 1} key={requestCount + 1}/>]);
        }
    };
    const removeRequest = (e) => {
        e.preventDefault();
        setRequestCount((requestCount - 1));
        setRequestGroup([...requestGroup.slice(0, requestCount - 1), ...requestGroup.slice(requestCount + 2)]);
    };
    const mainContainer = {
        marginTop: '36px',
        marginBottom: '36px',
    };
    const iconStyles = {
        paddingRight: '10px',
    };
    return (<>
      <div>{requestGroup}</div>
      <Col style={mainContainer} sm={10} md={12} data-testid={'add-accommodation-request'} className={'d-flex justify-content-between p-0'}>
        {requestCount < maxRequestCount &&
            <Button buttonType={'text'} onClick={addRequest} data-testid='add-request-btn' className={'p-0 text-left'}>
            <Icon color={'secondary'} name={'add-something'} style={iconStyles}/>
            <span>{t(AddRequestText.addrequestBtnEE)}</span>
          </Button>}
        {(requestCount <= maxRequestCount && requestCount > 1) &&
            <Button buttonType={'text'} onClick={removeRequest} data-testid='remove-request-btn' className={'p-0 text-right'}>
            <Icon color={'secondary'} name={'close-circle'} style={iconStyles} size={'small'}/>
            <span>{t(AddRequestText.removeRequestBtnEE)}</span>
          </Button>}
      </Col>
    </>);
};
export default React.memo(AddAccommodationRequest);
