/* Search Function - Triggered on Submit of Search Form */
const search = (values, setSearchValues, cancel, error, getSearchUrl, searchGet) => {
    error = null;
    // If we have active fetch then cancel the old one 
    // TODO: evaluate if we can remove the cancel functionality
    if (cancel.current) {
        cancel.current();
    }
    // Set search values to state - to be passed to Results to help it render correctly
    setSearchValues(values);
    // build correct get request based on the searchCriteria TODO: refactor this to be simpler, lots of unused/overwritten code
    const searchString = values.search;
    const params = {};
    let url = `/status-service/${getSearchUrl(values.searchCriteria)}`;
    if (values.searchCriteria === 'empLastName' && searchString.split(',').length === 2) {
        params.empLastName = searchString.split(',')[0].trim();
        params.empFirstName = searchString.split(',')[1].trim();
        url = '/status-service/employee/search/searchByFullName';
    }
    else if (values.searchCriteria === 'accommodationEventId') {
        url = `/status-service/accommodations/${values.search}/basicDetails`;
    }
    else {
        params[values.searchCriteria] = searchString;
    }
    // only execute the search if there's a searchString
    if (searchString && searchString.length >= 2) {
        // If search is claim number then queue a retry for leaveId
        let retry;
        if (values.searchCriteria === 'claimNum') {
            retry = {
                url: '/status-service/leaves/search/searchByLeaveId',
                options: {
                    params: {
                        leaveId: searchString,
                    },
                },
            };
        }
        // Execute the get
        cancel.current = searchGet({
            url,
            options: {
                params,
            },
            retry,
        });
    }
};
export default search;
