// Go-Forward User Roles
export const EMPLOYEE = 'employee';
export const EMPLOYER = 'employer';
export const EMPLOYER_ADMIN = 'employeradmin';
export const BROKER = 'broker';
export const VENDOR = 'vendor';
export const INTERNAL = 'internal';
// Legacy Internal Roles
export const CLIENT_SERVICE_REP = 'clientservicerep';
export const CLIENT_SERVICE_REPS = 'client_service_reps';
export const CLIENT_SERVICE_REP_OLD = 'gb_ecr_group_role_client_service_rep';
export const SUPER_ADMIN = 'super_admin';
export const SUPER_USER = 'superuser';
export const INTERNAL_ADMIN = 'internal_admin';
export const SERVICE_REGION_EAST = 'service_region_east';
export const SERVICE_REGION_WEST = 'service_region_west';
export const SERVICE_REGION_CENTRAL = 'service_region_central';
export const INSTALLATION_SUPPORT = 'installation_support';
export const PT_REPORTING = 'pt_reporting';
export const GMHO = 'gmho';
export const CONTENT_MANAGERS = 'content_managers';
export const LIFE_BENEFICIARY_MANAGERS = 'life_beneficiery_managers';
