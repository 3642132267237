import { Alert, Form, Spinner } from '@digitools/honest-react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from '../../../../hooks/useTealium';
import { AuthenticationContext } from 'src/providers/AuthenticationProvider';
import { UNAUTHORIZED_ALERT } from '../../Constants/CrsConstants';
import { custConfigDataUpdate, filterPageAction, handleReportByCategory, startLoading, stopLoading, } from '../../crsActions';
import { initialState } from '../../utils/InitialState';
import { createFilterMyReportPayLoad, getSelectedClaimProductsFromFormApi, initializeFormValues, isValidProductSelected, totalStatesAndLocations, validateDateSection, validateReportCategory, } from './FilterMyReportValidation';
import FilterReportByProduct from './FilterReportByProduct';
import ReportFilterButtonPanel from './ReportFilterButtonPanel';
import SelectProducts from './SelectProducts';
import SelectStatesAndProvinces from './SelectStatesAndProvinces';
import SelectStatusAndDates from './SelectStatusAndDates';
import SelectSubsAndLocs from './SelectSubsAndLocs';
import { workProvincesModel, workStatesModel } from './work-location-model/initialStateAndProvinces';
import { withRouter } from 'src/utils/withRouter';
const SYSTEM_ERROR_ALERT = (<Alert type={'info'}>The system has experienced an unexpected error, please try again later. </Alert>);
const FilterMyReport = (props) => {
    const [state, setState] = useState();
    const [getSubsLocFirstTime, setGetSubsLocFirstTime] = useState(true);
    const [configError, setConfigError] = useState(false);
    const [errorAlert, setErrorAlert] = useState(<></>);
    const [totalSelectedStatesAndLocations, settotalSelectedStatesAndLocations] = useState();
    const [subLocs, setSubLocs] = useState(props.subLocs);
    const [selectedSubLocs, setSelectedSubLocs] = useState(props.selectedSubLocs);
    const authContext = useContext(AuthenticationContext);
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CRS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.CRS.FILTER_REPORT,
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.callCustomerConfig) {
            customerConfigData();
        }
        settotalSelectedStatesAndLocations(totalStatesAndLocations(workStates, workProvinces));
    }, []);
    const customerConfigData = async () => {
        const url = `/crs-service/customerconfig`;
        props.startLoading();
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authContext.authHeader(),
                },
            });
            props.updateCustomerConfigData(response.data);
        }
        catch (error) {
            setErrorAlert(error.response.status === 403 ? UNAUTHORIZED_ALERT : SYSTEM_ERROR_ALERT);
            setConfigError(true);
        }
        finally {
            props.stopLoading();
        }
    };
    const getSubsLocData = async (categoryOrProduct) => {
        if (categoryOrProduct !== '' && categoryOrProduct !== 'CLAIM') {
            const url = `/crs-service/subs-locs?product=` + categoryOrProduct;
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authContext.authHeader(),
                    },
                });
                setSubLocs(response.data);
            }
            catch (error) {
                setErrorAlert(error.response.status === 403 ? UNAUTHORIZED_ALERT : SYSTEM_ERROR_ALERT);
                setConfigError(true);
            }
        }
    };
    const selectSubLoc = (selectedIds) => {
        if (getSubsLocFirstTime) {
            if (selectedIds.length === 0 && selectedSubLocs.length === 0) {
                setSelectedSubLocs(subLocs);
            }
            setGetSubsLocFirstTime(false);
        }
        subLocs.map(subLoc => {
            if (selectedIds.includes(subLoc.locationId)) {
                subLoc.selected = true;
            }
            else if (selectedIds.length > 0 || selectedSubLocs.length > 0) {
                subLoc.selected = false;
            }
        });
    };
    const getSelected = (selectedRef) => {
        setSelectedSubLocs(selectedRef.current);
        const selectedIds = selectedRef.current.map(ref => ref.locationId);
        selectSubLoc(selectedIds);
    };
    const getReportFilterCategory = (filterCategory) => {
        if (filterCategory === 'Accommodation Services') {
            return 'AS';
        }
        return filterCategory.toUpperCase();
    };
    const formSubmission = (values) => {
        props.submitFilterPageRequest(createFilterMyReportPayLoad(values, selectedSubLocs, subLocs, reportFilterCategory));
        props.navigate('/create-my-report/report-field');
    };
    const setFormApi = (formApi) => {
        setState({ formApi });
    };
    const handleProductAllSelection = (product) => {
        let selectedProducts = [];
        if (product.code === 'All') {
            if (product.checked == true) {
                selectedProducts = products.map((product) => product.code);
            }
            else {
                selectedProducts = [];
            }
        }
        else {
            const { formApi } = state;
            selectedProducts = getSelectedClaimProductsFromFormApi(formApi);
        }
        setState({ formApi: state.formApi });
        getSubsLocData(selectedProducts.join());
    };
    const validateFields = (values) => {
        if (values && values['dateSearchBy']) {
            return {
                dateSearchBy: validateDateSection(values),
                statusgroup: validateDateSection(values),
                reportCategory: validateReportCategory(values),
            };
        }
        else {
            return {
                dateSearchBy: validateDateSection(values),
                statusgroup: validateDateSection(values),
                reportCategory: validateReportCategory(values),
                fromDate: undefined,
                toDate: undefined,
            };
        }
    };
    const { isLoading, filterReportLabels, reportFilterCategory, products, filterStatusCodes, reportDateRange, workStates, workProvinces, handleReportFilterByCategory, employeeWorkLocationLabel, } = props;
    const isValidCategorySelected = isValidProductSelected(reportFilterCategory);
    const initialValues = initializeFormValues(products, filterStatusCodes, reportDateRange, workStates, workProvinces);
    const changeReportFilterCategory = (payLoad) => {
        const { formApi } = state;
        handleReportFilterByCategory(payLoad);
        formApi.setError('reportCategory', undefined);
        if (getReportFilterCategory(payLoad)) {
            initialState.filterStatusCodes.forEach(status => formApi.setValue(status.code, false));
            resetFilterPage(formApi);
            const count = totalStatesAndLocations([], []);
            settotalSelectedStatesAndLocations(count);
        }
        resetSubLocs();
        setState({ formApi });
        getSubsLocData(getReportFilterCategory(payLoad));
    };
    const resetFilterPage = (formApi) => {
        formApi.setValue('dateSearchBy', '');
        formApi.setValue('statusgroup', '');
        formApi.setError('dateSearchBy', undefined);
        formApi.setError('statusgroup', undefined);
        formApi.setValue('fromDate', '');
        formApi.setValue('toDate', '');
        formApi.setError('toDate', undefined);
        formApi.setError('fromDate', undefined);
        formApi.setValue('ASC', false);
        workStatesModel.forEach((workstate) => {
            formApi.setValue(workstate.stateCode, true);
        });
        workProvincesModel.forEach((workProvince) => {
            formApi.setValue(workProvince.provinceCode, true);
        });
    };
    const resetSubLocs = () => {
        setSubLocs([]);
        setSelectedSubLocs([]);
    };
    if (subLocs.length > 0) {
        const selectedIds = selectedSubLocs.map(ref => ref.locationId);
        selectSubLoc(selectedIds);
    }
    if (isLoading) {
        return <Spinner id='filter-my-report-spinner'/>;
    }
    if (configError) {
        return errorAlert;
    }
    return (<>
      <Form validateFields={validateFields} onSubmit={formSubmission} initialValues={initialValues} getApi={setFormApi} data-public={true}>
        <FilterReportByProduct filterReportLabels={filterReportLabels} handleReportByCategory={changeReportFilterCategory} reportCategory={reportFilterCategory}/>
        {reportFilterCategory === 'Claim' && isValidCategorySelected && (<SelectProducts onProductSelect={handleProductAllSelection} products={products} reportFilterCategory={reportFilterCategory}/>)}
        {isValidCategorySelected && (<SelectStatusAndDates filterStatusCodes={filterStatusCodes} reportDateRange={reportDateRange} reportFilterCategory={reportFilterCategory}/>)}
        <SelectSubsAndLocs subLocData={subLocs} selectedSubLocs={selectedSubLocs} getSelected={getSelected} reportFilterCategory={reportFilterCategory}/>
        <SelectStatesAndProvinces totalStatesAndProvinces={totalSelectedStatesAndLocations} headerLabel={employeeWorkLocationLabel} reportFilterCategory={reportFilterCategory}/>
        <ReportFilterButtonPanel cancelRedirectionUrl={'/employer/home'}/>
      </Form>
    </>);
};
const mapStateToProps = ({ crsReducer }) => {
    return {
        isLoading: crsReducer.isLoading,
        filterReportLabels: crsReducer.filterReportLabels,
        filterStatusCodes: crsReducer.filterStatusCodes,
        products: crsReducer.products,
        reportDateRange: crsReducer.reportDateRange,
        reportFilterCategory: crsReducer.reportFilterCategory,
        workStates: crsReducer.workStates,
        workProvinces: crsReducer.workProvinces,
        callCustomerConfig: crsReducer.callCustomerConfig,
        employeeWorkLocationLabel: crsReducer.employeeTextCustomContent,
        selectedSubLocs: crsReducer.selectedSubLocs,
        subLocs: crsReducer.subLocs,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch(startLoading()),
        stopLoading: () => dispatch(stopLoading()),
        submitFilterPageRequest: (payload) => dispatch(filterPageAction(payload)),
        handleReportFilterByCategory: (payload) => dispatch(handleReportByCategory(payload)),
        updateCustomerConfigData: (payload) => dispatch(custConfigDataUpdate(payload)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterMyReport));
