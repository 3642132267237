const ForgotInfoDisplayTextEs = {
    forgotUsernameHeader: 'Olvidé usuario',
    forgotPasswordHeader: 'Olvidé contraseña',
    forgotUsernameParagraph: 'Ingrese la dirección de correo electrónico de su cuenta y recibirá un correo electrónico con su nombre de usuario.',
    forgotPasswordParagraph: 'Si ha olvidado su contraseña, ingrese su nombre de usuario. Intentaremos consultar su cuenta y le permitiremos restablecer su contraseña.',
    forgotUsernameSuccessAlertTitle: 'Presentado con éxito.',
    forgotUsernameSuccessAlertBody: 'Si la información proporcionada coincide con una cuenta, se le enviará su nombre de usuario por correo electrónico.',
    forgotPasswordSuccessAlertTitle: 'Presentado con éxito.',
    forgotPasswordSuccessAlertBody: 'Si la información proporcionada coincide con una cuenta, recibirá un correo electrónico con un enlace para restablecer su contraseña. El enlace caducará en 10 minutos.',
    unverifiedEmailTitle: 'No se puede verificar la cuenta!',
    unverifiedEmailBody: 'Se requiere verificación de correo electrónico de cuenta. Verifique su bandeja de entrada o carpeta de correo no deseado para verificar su correo electrónico antes de poder restablecer su contraseña.',
    unexpectedBody: 'Intente enviar su información nuevamente, si aún tiene problemas, contáctenos para obtener más ayuda.',
    unexpectedTitle: '¡Algo salió mal!',
    emailAddressLabel: 'Dirección de correo electrónico',
    usernameLabel: 'Nombre de usuario',
    submitButton: 'Enviar',
    fieldRequired: 'Este campo es requerido.',
    backToLogin: 'Volver a iniciar sesión',
    movedMessage: 'Mensaje importante para los empleados',
    mightHaveMoved: 'Es posible que su cuenta de beneficios ya se haya trasladado. Si ya tiene una cuenta en LincolnFinancial.com, inicie sesión ',
    here: 'aquí',
};
export default ForgotInfoDisplayTextEs;
