import { workStatesModel, workProvincesModel, } from '../../filter-my-report/work-location-model/initialStateAndProvinces';
const filterCategoryCodes = [
    { code: 'ClaimData', value: 'Claim' },
    { code: 'LeaveData', value: 'Leave' },
    { code: 'AccommodationService', value: 'Accommodation Services' },
];
const dateTypeCodes = [
    { code: 'ClaimReceivedDate', value: 'Claim Received Date' },
    { code: 'ClosedDate', value: 'Closed Date' },
    { code: 'DisabilityDate', value: 'Disability Date' },
    { code: 'DeterminationDate', value: 'Determination Date' },
    { code: 'LeaveBeginDate', value: 'Leave Begin Date' },
    { code: 'LeaveReceivedDate', value: 'Leave Received Date' },
    { code: 'AccommodationReceivedDate', value: 'Accommodation Received Date' },
    { code: 'AccommodationClosedDate', value: 'Accommodation Closed Date' },
    { code: 'ProvidedStartDate', value: 'Provided Start Date' },
];
const searchTypeCodes = [
    { code: 'Claims', value: 'Claim' },
    { code: 'Leaves', value: 'Leave' },
    { code: 'AS', value: 'Accommodation Services' },
];
// Needs to be revisited while working on changes related to Work State. Currently Status Codes and Work State codes clash which fails the form
export const statusCodes = [
    { code: 'DE', value: 'Denied' },
    { code: 'PE', value: 'Pending' },
    { code: 'AP', value: 'Approved' },
    { code: 'CL', value: 'Closed' },
    { code: 'IP', value: 'In Process' },
];
export const runReportParam = (filterCategory, reportedDateRange, products, status, fieldPageInfo, reportId, workProvinces, workStates, subLocs, selectedSubLocs) => {
    // Report Type
    const reportTypeSelected = reportTypeSelectedCode(filterCategory);
    // Date Type
    const dateTypeSelected = selectedDateTypeSearchOn(reportedDateRange, filterCategory);
    // Search Type
    const filteredSearchType = searchTypeCodes.filter(parm => parm.value === filterCategory);
    const searchTypeCode = filteredSearchType.length ? filteredSearchType[0].code : '';
    // Products
    const filteredProducts = getProductList(filterCategory, products);
    // Status
    const statusSelected = statusSelectedList(status, filterCategory);
    // Fields
    const fieldsSelected = getFieldSelectedCode(fieldPageInfo, filterCategory);
    const fieldsForReport = [];
    if (fieldsSelected.length) {
        fieldsSelected.forEach(field => fieldsForReport.push(field.fieldCode));
    }
    // Work Locations
    const statesSelected = workStates
        ? workStates.filter(state => state.stateName !== 'All').map(state => state.stateCode)
        : [];
    const provinceSelected = workProvinces
        ? workProvinces.filter(province => province.provinceName !== 'All').map(province => province.provinceCode)
        : [];
    const selectedWorkLocations = workStates.length === workStatesModel.length && workProvinces.length === workProvincesModel.length
        ? ['ALL']
        : statesSelected.concat(provinceSelected);
    // Locations
    const locationsSelected = subLocs.length > 0 && subLocs.length == selectedSubLocs.length
        ? ['ALL']
        : selectedSubLocs.map(subLoc => subLoc.locationId);
    const payload = {
        reportId,
        reportType: reportTypeSelected,
        searchType: searchTypeCode,
        dateType: dateTypeSelected.code,
        fromDate: reportedDateRange ? reportedDateRange.fromDate : '',
        throughDate: reportedDateRange ? reportedDateRange.toDate : '',
        products: filteredProducts,
        fields: fieldsForReport,
        statusList: statusSelected,
        locations: locationsSelected,
        workLocations: selectedWorkLocations,
    };
    return payload;
};
export const getFieldSelectedCode = (fieldPageInfo, filterCategory) => {
    const fieldsSeleted = [];
    const filterSelectedFields = (fieldsSection) => fieldsSection
        .filter(field => field.status && field.fieldLabel !== 'All')
        .map(field => {
        const fieldSelected = {
            fieldCode: field.id,
            fieldLabel: field.fieldLabel,
        };
        fieldsSeleted.push(fieldSelected);
    });
    if (fieldPageInfo && filterCategory) {
        filterSelectedFields(fieldPageInfo.personalInfoFields);
        filterSelectedFields(fieldPageInfo.workInfoFields);
        switch (filterCategory) {
            case 'Claim': {
                filterSelectedFields(fieldPageInfo.claimDatesFields);
                filterSelectedFields(fieldPageInfo.claimDetailFields);
                break;
            }
            case 'Leave': {
                filterSelectedFields(fieldPageInfo.leaveDatesFields);
                filterSelectedFields(fieldPageInfo.leaveDetailFields);
                break;
            }
            case 'Accommodation Services': {
                filterSelectedFields(fieldPageInfo.accommodationDatesFields);
                filterSelectedFields(fieldPageInfo.accommodationDetailFields);
                break;
            }
            default:
                break;
        }
    }
    return fieldsSeleted;
};
const reportTypeSelectedCode = (filterCategory) => {
    const filteredFilterCategory = filterCategoryCodes.filter(parm => parm.value === filterCategory);
    const reportTypeSelected = filteredFilterCategory.length ? filteredFilterCategory[0].code : '';
    return reportTypeSelected;
};
export const generateReportParam = (fieldPageInfo, filterCategory, reportId, reportedDateRange, status, reportAsOfDate) => {
    const dateTypeSelected = selectedDateTypeSearchOn(reportedDateRange, filterCategory);
    const payload = {
        reportId,
        reportType: reportTypeSelectedCode(filterCategory),
        fields: getFieldSelectedCode(fieldPageInfo, filterCategory),
        statusList: statusSelectedList(status, filterCategory, true),
        reportDateType: dateTypeSelected.value,
        fromDate: reportedDateRange ? reportedDateRange.fromDate : '',
        throughDate: reportedDateRange ? reportedDateRange.toDate : '',
        reportAsOfDate: reportAsOfDate ? reportAsOfDate : '',
    };
    return payload;
};
export const statusSelectedList = (status, filterCategory, sendValue = false) => {
    let statusSelected = status
        ? status.filter(stat => stat.checked && stat.label !== 'All').map(stat => stat.label)
        : [];
    if (!statusSelected.length) {
        // Can be generic if other filter category has same status. To be re-visited in future stories
        if (filterCategory === 'Claim' || filterCategory === 'Leave') {
            statusSelected = ['Denied', 'Pending', 'Approved', 'Closed', 'In Process'];
        }
        else if (filterCategory === 'Accommodation Services') {
            statusSelected = ['Pending', 'Approved', 'Closed', 'In Process'];
        }
    }
    if (sendValue) {
        return statusSelected;
    }
    statusSelected.forEach((stat, index) => {
        statusCodes
            .filter(statusCode => statusCode.value === stat)
            .map(filterStatusCode => {
            statusSelected[index] = filterStatusCode.code;
        });
    });
    return statusSelected;
};
export const selectedDateTypeSearchOn = (reportedDateRange, filterCategory) => {
    const filteredDateType = reportedDateRange && reportedDateRange.dateSearchBy !== ''
        ? dateTypeCodes.filter(parm => parm.value === reportedDateRange.dateSearchBy)
        : getDefaultFilterDateCategory(filterCategory);
    const dateTypeSelected = filteredDateType && filteredDateType.length ? filteredDateType[0] : { code: '', value: '' };
    return dateTypeSelected;
};
const getDefaultFilterDateCategory = (filterCategory) => {
    switch (filterCategory) {
        case 'Claim':
            return [{ code: 'ClaimReceivedDate', value: 'Claim Received Date' }];
        case 'Leave':
            return [{ code: 'LeaveReceivedDate', value: 'Leave Received Date' }];
        case 'Accommodation Services':
            return [{ code: 'AccommodationReceivedDate', value: 'Accommodation Received Date' }];
        default:
            return [];
    }
};
const getProductList = (filterCategory, products) => {
    switch (filterCategory) {
        case 'Claim':
            return products
                ? products.filter(product => product.checked && product.code !== 'All').map(product => product.code)
                : [];
        case 'Leave':
            return ['LEAVE'];
        case 'Accommodation Services':
            return ['AS'];
        default:
            return [];
    }
};
