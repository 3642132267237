import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState, Form, useFormApi } from 'informed';
import { Button, StepperContext, Row, Col, SelectInput, Box, Heading, InputMessage, themes, DateInput, } from '@digitools/honest-react';
import moment from 'moment';
import styled from 'styled-components';
import { format, startOfToday, subYears } from 'date-fns';
import useExperience from '../../../hooks/useExperience';
import { dateMask } from '../../eoi/utils/InputMasks';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from 'src/hooks/useAuthentication';
const StyledLabel = styled('label') `
  margin: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  & + div {
    margin-top: 10px;
  }
`;
const numberOfBirthTypes = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
];
const hoursTypes = [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
];
const amPmTypes = [{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }];
const RabReportForm = (props) => {
    const { language } = useExperience();
    const formApi = useFormApi();
    const formState = useFormState();
    const { t: translation } = useTranslation('rabTranslation');
    const isFutureTime = (time) => {
        const currentTime = moment().format('HH:mm');
        if (time) {
            const receivedTime = moment(time, 'hh:mm A').format('HH:mm');
            if (moment(receivedTime, 'HH:mm').isAfter(moment(currentTime, 'HH:mm'))) {
                return true;
            }
            return false;
        }
        return false;
    };
    const validateTime = () => {
        const now = moment().format('mm/dd/yyyy');
        const target = moment(JSON.stringify(formState.values.dateOfBirth)).format('mm/dd/yyyy');
        const hours = formState.values.hours;
        const minutes = formState.values.minutes;
        let futureTime = false;
        if (hours && minutes) {
            const ampm = formState.values.ampm === 'AM' ? 'A' : 'P';
            const time = `${hours}:${minutes} ${ampm}`;
            futureTime = isFutureTime(time);
        }
        if (target === now && futureTime) {
            return true;
        }
        return false;
    };
    const validateTimeErrorText = () => {
        if (validateTime()) {
            return translation('timeCannotBeInFutureText');
        }
        return '';
    };
    useEffect(() => {
        if (formApi && validateTime()) {
            formApi.setFormError(translation('timeCannotBeInFutureText'));
        }
    }, [formState.values.hours, formState.values.minutes, formState.values.ampm]);
    const deliveryTypes = [
        { label: translation('vaginalText'), value: translation('vaginalText') },
        { label: translation('cesareanText'), value: translation('cesareanText') },
    ];
    const deliveryMultipleTypes = [
        { label: translation('yesText'), value: translation('cesareanText') },
        { label: translation('noText'), value: translation('vaginalText') },
    ];
    const bondingQuestionTypes = [
        { label: translation('yesText'), value: translation('yesText') },
        { label: translation('noText'), value: translation('noText') },
        { label: translation('unknownText'), value: translation('unknownText') },
    ];
    const createArrayOfHoursSelect = (input) => {
        const returnArray = Array(input)
            .fill(null)
            .map((value, i) => {
            if (i < 9) {
                return { label: `0${i + 1}`, value: `0${i + 1}` };
            }
            else {
                return { label: `${i + 1}`, value: `${i + 1}` };
            }
        });
        returnArray.unshift({ label: '00', value: '00' });
        return returnArray;
    };
    const validate = useCallback((value) => {
        if (!value) {
            return translation('fieldRequiredText');
        }
    }, []);
    const bondingTypeText = () => {
        let bondingType;
        const deliveryWeeks = formState.values.deliveryType === translation('vaginalText')
            ? props.rabCustomerConfData.vaginalDeliveryWeeks.toString()
            : formState.values.deliveryType === translation('cesareanText')
                ? props.rabCustomerConfData.cesareanDeliveryWeeks.toString()
                : '';
        bondingType = translation('bondingQuestionText').replace('{deliveryWeeks}', deliveryWeeks);
        if (formState.values.numberOfBirth > 1) {
            bondingType = bondingType.replace('?', 's?');
        }
        return bondingType;
    };
    const between = useMemo(() => {
        return [
            format(subYears(new Date(), 2), 'P'),
            format(startOfToday(), 'P'),
        ];
    }, []);
    const dateFormat = ' (mm/dd/yyyy)';
    return (<>
      <div data-public={true} style={{ marginTop: '2rem' }}>
        <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'} data-testid='rabReport'>
          {translation('stepLabelReport').toString()}
        </Heading>
        <Box>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <StyledLabel id='numberOfBirthText'>
                {translation('numberOfBirthsQuestonText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <SelectInput field='numberOfBirth' options={numberOfBirthTypes} theme={themes.lincoln} data-testid='numberOfBirth' 
    //@ts-ignore FIXME: type error
    label={translation('birthsText')} aria-label={translation('numberOfBirthsText')} initialValue='1'/>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <StyledLabel id='deliveryTypeText'>
                {formState.values.numberOfBirth <= 1
            ? translation('deliveryTypeQuestionText').toString()
            : translation('deliveryTypeMultipleBirthsQuestionText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md={4} xs={12}>
              <SelectInput field='deliveryType' 
    //@ts-ignore FIXME: typing errors
    placeholderText={translation('birthTypeText')} validate={validate} options={formState.values.numberOfBirth <= 1 ? deliveryTypes : deliveryMultipleTypes} theme={themes.lincoln} data-testid='deliveryType' 
    //@ts-ignore FIXME: typing errors
    label={translation('birthTypeText')} aria-label={translation('deliveryTypeQuestionText')} validateOnChange={true}/>
            </Col>
          </Row>

          <Row>
            <Col md={4} xs={12} sm={12}>
              <StyledLabel id='dateOfBirthText'>
                {formState.values.numberOfBirth <= 1
            ? translation('dateOfBirthQuestionText').toString()
            : translation('dateOfMultipleBirthsQuestionText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <DateInput label={translation('birthDateText') + dateFormat} field='dateOfBirth' ariaLabelText='dateOfBirth' data-testid='dateOfBirth' between={between} validateOnChange={true} theme={themes.lincoln} lang={language} mask={dateMask}/>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <StyledLabel id='timeOfBirth' aria-labelledby='timeOfBirth'>
                {formState.values.numberOfBirth <= 1
            ? translation('timeOfBirthQuestionText').toString()
            : translation('timeOfMultipleBirthQuestionText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col md={2} xs={12} sm={12}>
              <SelectInput field='hours' placeholderText='Hour' label={'Hour'} data-testid='hours' options={hoursTypes} theme={themes.lincoln} validate={validate} validateOnChange={true} aria-label='hours'/>
            </Col>
            <Col md={2} xs={12} sm={12}>
              <SelectInput field='minutes' placeholderText='Minute' label={'Minute'} data-testid='minutes' options={createArrayOfHoursSelect(59)} theme={themes.lincoln} validate={validate} validateOnChange={true} aria-label='minutes'/>
            </Col>
            <Col md={2} xs={12} sm={12}>
              <SelectInput field={'ampm'} data-testid='ampm' options={amPmTypes} theme={themes.lincoln} initialValue='AM' label={'AM/PM'} placeholderText='AM' aria-label='am pm'/>
            </Col>
            <Col md={6} xs={12} sm={12}/>
          </Row>
          {validateTimeErrorText() !== '' ? (<Row>
              <Col>
                <InputMessage error={true} className='mt-0'>
                  {validateTimeErrorText()}
                </InputMessage>
              </Col>
            </Row>) : null}
          <Row>
            <Col md={4} xs={12} sm={12}>
              <StyledLabel id='lastDayWorkedText'>
                {translation('lastDayWorkedQuestionText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <DateInput label={translation('lastDayWorkedText') + dateFormat} field='lastDayWorked' data-testid='lastDayWorked' ariaLabelText='lastDayWorked' theme={themes.lincoln} lang={language} between={between} validateOnChange={true} mask={dateMask}/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12}>
              <StyledLabel id='hospitalAdmissionDate' aria-labelledby='hospitalAdmissionDate'>
                {translation('hospitalAdmissionDateQuestionText').toString()}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} sm={12}>
              <DateInput label={translation('hospitalAdmissionDateText') + dateFormat} field='hospitalAdmissionDate' data-testid='hospitalAdmissionDate' ariaLabelText='hospitalAdmissionDate' theme={themes.lincoln} between={between} validateOnChange={true} mask={dateMask} optional={true}/>
            </Col>
          </Row>
          {formState.values.deliveryType === undefined || !props.rabCustomerConfData.displayBondingQuestion ? null : (<>
              <Row style={{ marginBottom: '0.5rem' }}>
                <Col xs={12} sm={12}>
                  <StyledLabel id='bondingQuestionType' aria-labelledby='bondingQuestionType'>
                    {bondingTypeText()}
                  </StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={12} sm={12}>
                  <SelectInput label={translation('bondingTimeText') + ' ' + translation('optional')} data-testid='bondingQuestionType' field='bondingQuestionType' placeholderText={translation('bondingTimeText') + ' ' + translation('optional')} options={bondingQuestionTypes} theme={themes.lincoln} aria-label='bondingQuestionType'/>
                </Col>
              </Row>
            </>)}
        </Box>
        <Row>
          <Col>
            <Button aria-label={'Verify'} buttonType='primary' theme={themes.lincoln} role='button' id='verify' type='submit' data-testid='verifyButton'>
              {translation('verifyText').toString()}
            </Button>
          </Col>
        </Row>
      </div>
    </>);
};
const RabReport = (props) => {
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    const { user } = useAuthentication();
    const handleVerify = (values) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: 'verify',
            event_version: TEALIUM_EVENT.EVENT_VERSION.REPORT,
            event_status: TEALIUM_EVENT.EVENT_STATUS.START,
            user_id: user?.webGUID
        });
        props.onComplete({
            numberOfBirth: values.numberOfBirth,
            deliveryType: values.deliveryType,
            dateOfBirth: values.dateOfBirth,
            hours: values.hours,
            minutes: values.minutes,
            ampm: values.ampm,
            lastDayWorked: values.lastDayWorked,
            hospitalAdmissionDate: values.hospitalAdmissionDate,
            bondingQuestionType: values.bondingQuestionType,
        });
        stepper.next();
    };
    return (<Form onSubmit={handleVerify}>
      <RabReportForm rabCustomerConfData={props.rabCustomerConfData}/>
    </Form>);
};
export default RabReport;
