import { Col, DatePickerV2, Row, SelectInput, TextInput } from '@digitools/honest-react';
import { RadioGroup, useFormApi } from 'informed';
import React, { useState } from 'react';
import styled from 'styled-components';
import useBdmLciConfig from '../../../../../hooks/useBdmLciConfig';
import useExperience from '../../../../../hooks/useExperience';
import { formatCurrency, isNotEmpty, numbersOnly, validateCurrency, optionalFieldCharacters, decimalDollar, validateCurrencyOptional, } from '../../../../../utils/validationUtils';
import RadioButton from '../../../../employeeProfile/components/commPref/RadioButton/RadioButton';
import { LCI_EMPLOYEE_WORK_STATUS, MAX_LENGTH_WHOURS } from '../../../constants/LCIConstant';
import { getLabels } from '../../../utils/LCIUtils';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
import useTranslate from 'src/hooks/useTranslate';
const StyledOptionalElement = styled('span') `
  font-size: 0.875rem;
  padding-left: 5px;
  font-weight: normal !important;
`;
/*
 * Employee Termination Status
 */
const EmployeeTerminationInfo = ({ updateRadio, customLabel, }) => {
    const handleChange = (e) => {
        updateRadio(e.target.value);
    };
    return (<>
      <div>
        <label>
          <b>Is the {customLabel} Terminated?</b>
          <StyledOptionalElement>(optional)</StyledOptionalElement>
        </label>
      </div>
      <RadioGroup field='policyInfo_terminationInfo' initialValue={''} onChange={handleChange}>
        <div className={'d-inline-block pr-3'}>
          <RadioButton value={'yes'} labelText='Yes'/>
        </div>
        <div className={'d-inline-block pr-3'}>
          <RadioButton value={'no'} labelText='No'/>
        </div>
      </RadioGroup>
    </>);
};
/*
 * Employee Retirement Status
 */
const EmployeeRetirementStatus = ({ customLabel }) => {
    return (<>
      <div>
        <label>
          <b>Is {customLabel} receiving retirement benefits</b>
          <StyledOptionalElement>(optional)</StyledOptionalElement>
        </label>
      </div>
      <RadioGroup className={'mt-2'} field='policyInfo_retirementStatusInfo'>
        <div className={'d-inline-block pr-3'}>
          <RadioButton value={'yes'} labelText='Yes'/>
        </div>
        <div className={'d-inline-block pr-3'}>
          <RadioButton value={'no'} labelText='No'/>
        </div>
      </RadioGroup>
    </>);
};
/*
 * Policy information details
 */
const PolicyInformation = ({ claimant, event }) => {
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    const { t } = useTranslate();
    const [terminated, setTerminated] = useState('no');
    const [employeeWorkStatus, setWorkStatus] = useState('');
    let showWorkHours = false;
    let showClassification = false;
    let showOccupation = false;
    let showRetirementAmount = false;
    let showTerminationStatus = false;
    let showDateOfTermination = false;
    let showDateOfRetirement = false;
    let showRetirementBenefitStatus = false;
    const formApi = useFormApi();
    const handleBlur = (field) => (e) => {
        const val = formApi.getValue(field);
        formApi.setValue(field, decimalDollar(val));
    };
    switch (event) {
        case 'death':
            showClassification = true;
            showOccupation = true;
            showWorkHours = true;
            if (claimant != 'dependent' && employeeWorkStatus === 'RETIRED') {
                showRetirementAmount = true;
            }
            break;
        case 'accelerated death':
            if (claimant === 'dependent' && employeeWorkStatus === 'ACTIVE') {
                showTerminationStatus = true;
            }
            if (claimant === 'dependent' && employeeWorkStatus === 'ACTIVE' && terminated === 'yes') {
                showDateOfTermination = true;
            }
            if (claimant === 'employee') {
                showClassification = true;
                showOccupation = true;
                showWorkHours = true;
            }
            break;
        // TODO : this event value will be replaced based on backend response
        case `dismemberment / loss of sight`:
            showClassification = true;
            if (claimant === 'dependent' && employeeWorkStatus === 'ACTIVE') {
                showTerminationStatus = true;
            }
            if (claimant === 'dependent' && employeeWorkStatus === 'ACTIVE' && terminated === 'yes') {
                showDateOfTermination = true;
            }
            if (claimant === 'employee') {
                showOccupation = true;
                showWorkHours = true;
            }
            break;
        case 'total disability':
            showClassification = true;
            showOccupation = true;
            showWorkHours = true;
            if (employeeWorkStatus === 'ACTIVE') {
                showTerminationStatus = true;
            }
            else if (employeeWorkStatus === 'RETIRED') {
                showDateOfRetirement = true;
                showRetirementBenefitStatus = true;
            }
            if (employeeWorkStatus === 'ACTIVE' && terminated === 'yes') {
                showDateOfTermination = true;
            }
            break;
    }
    const handleWorkStatusSelection = (value) => {
        setWorkStatus(value);
        setTerminated('no');
    };
    const showTerminateDate = showDateOfTermination ? (<Col xl={4} md={6} sm={12} className={terminated === 'yes' ? 'mt-0' : 'mt-3'}>
      <DatePickerV2 data-testid='policyInfo_dateOfTermination' field={'policyInfo_dateOfTermination'} label={`Date ${labelEmployee} was Terminated (optional)`} ariaLabelText={'Date Employee was Terminated'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} mask={dateMask} validateOnBlur={true}/>
    </Col>) : null;
    const showRetirementDate = showDateOfRetirement ? (<Col lg={4} md={6} sm={12} className={employeeWorkStatus === 'RETIRED' ? 'mt-0' : 'mt-3'}>
      <DatePickerV2 data-testid='policyInfo_dateOfRetired' field={'policyInfo_dateOfRetired'} label={'Date of Retired (optional)'} ariaLabelText={'Date of Retired'} validate={(props) => validateDateIsOnOrBeforeToday(props, t, false)} mask={dateMask} validateOnBlur={true}/>
    </Col>) : null;
    return (<Row className={'mt-0'}>
      {showWorkHours && (<Col lg={4} md={6} sm={12} className={'mt-3'}>
          <TextInput field={'policyInfo_workHours'} label={'Scheduled Work Hours'} mask={numbersOnly} maxLength={MAX_LENGTH_WHOURS} type={'text'} validate={isNotEmpty} validateOnChange={true} validateOnBlur={true}/>
        </Col>)}
      <Col lg={4} md={6} sm={12} className={'mt-3'}>
        <TextInput field={'policyInfo_earnings'} label={'Earnings'} mask={formatCurrency} onBlur={handleBlur('policyInfo_earnings')} type={'text'} validate={validateCurrency} validateOnChange={true} validateOnBlur={true} icon={'usd'}/>
      </Col>
      <Col lg={4} md={6} sm={12} className={'mt-3'}>
        <SelectInput field={'policyInfo_workStatus'} label={'Work Status'} options={LCI_EMPLOYEE_WORK_STATUS} validate={isNotEmpty} onValueChange={(value) => handleWorkStatusSelection(value)} validateOnBlur={true} validateOnChange={true} placeholderText={'Work Status'}/>
      </Col>
      {showTerminationStatus && (<Col lg={4} md={6} sm={12} className={(event === 'total disability' && claimant === 'employee') || (event === 'dismemberment / loss of sight') || (event === 'accelerated death' && claimant === 'dependent') ? 'mt-3' : 'mt-0'}>
          <EmployeeTerminationInfo id='terminationInfo' updateRadio={setTerminated} customLabel={labelEmployee}/>
        </Col>)}

      {showTerminateDate}
      {showRetirementDate}
      {showRetirementBenefitStatus && (<Col lg={4} md={6} sm={12} className={employeeWorkStatus === 'RETIRED' ? 'mt-0' : 'mt-3'}>
          <EmployeeRetirementStatus id='retirementStatusInfo' customLabel={labelEmployee}/>
        </Col>)}

      {showRetirementAmount === true && (<Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Amount of Insurance at Retirement:'} field={'policyInfo_retirementAmount'} icon={'usd'} label={'Insurance at Retirement: (optional)'} mask={formatCurrency} onBlur={handleBlur('policyInfo_retirementAmount')} type={'text'} validateOnChange={true} validate={validateCurrencyOptional}/>
        </Col>)}

      {showClassification && (<Col lg={4} md={6} sm={12} className={((event === 'total disability') || (event === 'accelerated death' && claimant === 'employee') || (event === 'death') || (event === 'dismemberment / loss of sight' && (claimant === 'employee' || showTerminationStatus)) ? 'mt-0' : 'mt-3')}>
          <TextInput ariaLabelText={'classification'} field={'policyInfo_classification'} label={'Classification (optional)'} mask={optionalFieldCharacters} type={'text'} validateOnBlur={true}/>
        </Col>)}

      {showOccupation && (<Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Occupation'} field={'policyInfo_occupation'} label={'Occupation (optional)'} mask={optionalFieldCharacters} validateOnBlur={true} type={'text'}/>
        </Col>)}
    </Row>);
};
export default PolicyInformation;
