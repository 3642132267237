import HashidsGenerator from 'hashids';
// Never include 0's or 1's or i's or d's in hash :)
const hashids = new HashidsGenerator(
  'e476ebb5-56fa-4d0e-83e0-143077d2cc31',
  0,
  'abcefghjklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ23456789',
);

const DELIMITER = '-';
const STARTS_WITH_ZERO = '4';
const HAS_ID = '8';
const KEY_PLACEHOLDER = '6';

//parameters are the data to be encoded and a flag to signafy if the data is sso data
const encode = (data, hasEmpId = false) => {
  if (!data) {
    return data;
  }

  let formattedData = formatData(data);
  //if the data is sso data prepend the HAS_ID flag to the key (first data group)
  if (hasEmpId) {
    formattedData = HAS_ID + formattedData;
  }

  const parts = formattedData.split(DELIMITER);
  return parts.map(part => encoder(part)).join(DELIMITER);
};

const decode = data => {
  if (!data) {
    return data;
  }

  const parts = data.split(DELIMITER);
  const result = parts.map(part => decoder(part));

  let ret = '';
  //retrieve the key from the data
  let key = result.shift();
  if (key.charAt(0) === HAS_ID) {
    ret = ret + 'id-';
    key = key.substring(1);
  }

  //decode the rest of the data with the provided key
  return (
    ret +
    result
      .map((elm, index) => {
        if (key[index] === STARTS_WITH_ZERO) {
          return elm.substring(1);
        }
        return elm;
      })
      .join('')
  );
};

const encoder = id => {
  // 0 after lowercase 1 after uppercase
  id = id
    .replace(/([a-z])/g, '$10')
    .replace(/([A-Z])/g, '$11')
    .toLowerCase();
  // Lowercase value
  const lowercase = id + ''.toLowerCase();
  // split the value into groups of 10 for encoding
  const groups = lowercase.match(/.{1,10}/g);
  // encode each section as base 10 to be hashed
  const baseTens = groups.map(group => {
    return parseInt(group, 36).toString(10);
  });
  // hash the value
  return hashids.encode(baseTens);
};

// Restores uppercase letters :) I hate this codeand need to go back and cleanup
const restore = parsed => {
  let res = '';
  for (let i = 0; i < parsed.length; i++) {
    if (/[a-z]/.test(parsed[i])) {
      if (parsed[i + 1] == 1) {
        res = res + parsed[i].toUpperCase();
      } else {
        res = res + parsed[i];
      }
      i++;
    } else {
      res = res + parsed[i];
    }
  }

  return res;
};

const decoder = id => {
  // Decode the encoded base 10 values back into groups of 10
  const groups = hashids.decode(id);
  // encode each section as base 36 to go back to original value
  const parsed = groups
    .map(group => {
      return parseInt(group, 10).toString(36);
    })
    .join('');

  // Restore uppercase
  return restore(parsed);
};

//Takes in the value to be encoded and returns the formatted string (key-DATA)
const formatData = data => {
  let key = '';
  //Break data into groups of 4 (if a group is all letters that would result in 9 characters when translating to an int value)
  let da = data
    .match(/.{1,4}/g)
    .map(elm => {
      // if the group begins with a 0 append a placeholder and add to the key
      if (elm.charAt(0) === '0') {
        key = key + STARTS_WITH_ZERO;
        return STARTS_WITH_ZERO + elm;
      }
      // else append a key placeholder and return group as is
      key = key + KEY_PLACEHOLDER;
      return elm;
    })
    .join('-');

  //return the key appended to the data each piece is seperated by a -
  return key + '-' + da;
};

export { encode, decode };
