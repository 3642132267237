import { Form } from '@digitools/honest-react';
import React from 'react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import { StyledHeading } from '../../components/LayoutComponents';
import StepperButtons from '../../components/StepperButtons';
import { ReviewText } from '../../constants/ReviewText';
import AccommodationDetails from './AccommodationDetails';
import DisclaimerContent from './DisclaimerContent';
import ReviewAboutYou from './ReviewAboutYou';
import ReviewGettingStarted from './ReviewGettingStarted';
import ReviewSubmitButton from './ReviewSubmitButton';
import { Experience } from "src/types/Experience";
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const disclaimerFooterText = {
    fontSize: '0.813rem',
    lineHeight: 1.16,
    color: '#60696F',
    marginTop: '3rem',
};
const Review = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    return (<div>
      <StyledHeading elemType={'h2'} color={'primary'}>
        {t(ReviewText.pageTitle)}
      </StyledHeading>
      <Form data-testid='test-review'>
        <ReviewGettingStarted />
        <ReviewAboutYou displayButton={true}/>
        <AccommodationDetails displayButton={true}/>
        {experience === Experience.EMPLOYEE && <DisclaimerContent />}
        <StepperButtons last lastButton={<ReviewSubmitButton />} type="continue" analyticsPageName='review'/>
        <p style={disclaimerFooterText}>
          <sup>1</sup>
          {CustomEmployeeText(t(ReviewText.disclaimerFooterText))}
        </p>
      </Form>
    </div>);
};
export default React.memo(Review);
