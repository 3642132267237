export default {
  genericError: 'We are sorry! Something went wrong!',
  unauthorizedError: 'You are not authorized to view this page.',
  welcome: 'Hello World! You are Home!',
  modal: 'Welcome to the Modal',
  helpText: 'Call for assistance, 8:00 AM to 8:00 PM EST 1-800-431-2958',
  disclaimer:
    'Group insurance products and services described herein are issued by The Lincoln National Life Insurance Company, a Lincoln Financial company.  Home Office: Boston, MA. Lincoln Financial is the marketing name for Lincoln National Corporation and its affiliates. Affiliates are separately responsible for their own financial and contractual obligations.',
  rights: 'Lincoln National Corporation. All rights reserved.',
  about: 'About Lincoln Financial',
  terms: 'Terms & Conditions',
  policy: 'Privacy Policy',
  analytics: 'Google Analytics',
  statusHeader: 'View status',

  // Claim Detail
  claimDatesText: 'Claim Dates',
  paymentInfoHeader: 'Most Recent Payment Information',
  dateIssued: 'Date Issued',
  netAmount: 'Date Issued',
  paymentMethod: 'Payment Method',
  futurePaymentDate: 'Future Payment Date',
  paymentCheckText: 'Check',
  paymentDirectDepositText: 'Direct Deposit',
  disabilityDatesText: 'Disability Dates',
  claimReceivedText: 'Claim Received',
  lastDateWorkedText: 'Last Day Worked',
  dateOfDisabilityText: 'Date of Disability',
  benefitsBeginText: 'Benefits Begin Date',
  benefitsEndText: 'Potential Max Benefit End Date',
  returnToWorkEstimatedText: 'Return to Work (Estimated)',
  returnToWorkModifiedText: 'Return to Work (Modified)',
  returnToWorkActualText: 'Return to Work (Actual)',
  returnToWorkNumberText: 'To report your return to work date, please call ',
  tab: 'Return-To-Work',
  tabDefault: 'What is your return-to-work date?',
  sameEmployer: 'Are you returning to work for',
  capacity: 'What capacity are you returning-to-work?',
  fullTimeText: 'Full-time',
  partTimeText: 'Part-time',
  yes: 'Yes',
  no: 'No',
  fullTimeDate: 'What is your full-time return-to-work date?',
  partTimeDate: 'What is your part-time return-to-work date?',
  circumstances: 'Please note any special circumstances regarding your return-to-work.',
  submit: 'Submit',
  requiredMsg: 'Required Field',
  placeholder: '190 Character limit',
  estimated: 'Estimated',
  estimatedDateValidation: 'Estimated Return to work date should be greater than today date',
  returnToWorkValidation: 'Return to work date should be less than today date',
  circumstancesValidation: 'Text must be less than 190 characters',
  specialCharValidation: 'The following special characters cannot be used while entering your note \\ | " ~  ^  [ ]',
  selectDate: 'Select a date',
  claimSuccessMessage:
    'Please allow 1-3 business days for your submission to process and display accurately on the portal.',
  leaveSuccessMessage:
    'Your return-to-work details were successfully submitted. If your return-to-work date changes, please be sure to update and submit your new return-to-work details.',
  claimErrorMessage:
    "We're sorry, we were unable to submit your request at this time.  Please wait a moment and try again.  If the issue continues, please ",
  contactUs: 'contact us',
  approvedThroughText: 'Approved Through Date',
  approvedToText: 'Approved To Date',
  reopenDateText: 'Re-Open Date',
  associatedLeaveText: 'Associated Leave',
  caseManagerText: 'Case Manager',
  diagnosticCategoryText: 'Diagnostic Category',
  disabilityCauseText: 'Disability Cause',
  claimDetailsText: 'Claim',
  classDescriptionText: 'Class Description',
  docUploaded: 'Document uploaded successfully',
  docAlert: 'Please upload the applicable required RTW document(s)',
  uploadRtwLabel: 'Upload RTW Documents',
  helperText: 'Return-to-work details',
  helperTextContent: 'Please enter your return-to-work details below. If you are returning to work in a reduced capacity, please select part-time option.',
  docUploadHeader: 'Upload documents related to your return-to-work.',
  none: 'None',
  dateDisclaimerText: 'The Approved Through Date is dependent upon the claimant continuing to meet the definition of disability under contract/plan provisions and therefore, is subject to change.',
  cancel: 'Cancel',
  updatelink: 'Update return-to-work',

  // Leave Detail
  datesTakenText: 'Absences taken',
  dateText: 'Leave Date',
  leaveReceivedText: 'Leave Received',
  leaveBeginText: 'Leave Begin',
  leaveEndText: 'Leave End',
  requestedBeginText: 'Requested To Begin',
  requestedEndText: 'Requested To End',
  intermittentApprovedFrequencyText: 'Intermittent Approved Frequency',
  intermittentApprovedModeText: 'Intermittent Approved Mode',
  intermittentApprovedPeriodText: 'Intermittent Approved Period',
  takeoverText: 'Takeover',
  lastDayWorkedText: 'Last Day Worked',
  dateCertifiedFromText: 'Date Certified From',
  dateCertifiedThroughText: 'Date Certified Through',
  approvedFrequencyText: 'Approved Leave Frequency',
  asOfText: 'as of',
  leaveProgramText: 'Leave Program',
  programHoursUsedText: 'Program Hours Used',
  programHoursRemainingText: 'Program Hours Remaining',
  programHoursDescriptionText:
    'The program hours used and program hours remaining that display, are based on the last absence applied to this leave. If you have reported additional absences, or have/had another leave, this will impact your balances.',
  leaveTypeText: 'Leave Type',
  leaveReasonText: 'Leave Reason',
  leaveCategoryText: 'Leave Category',
  associatedClaimText: 'Associated Claim',
  leaveDetailsText: 'Leave',
  leaveSpecialistText: 'Leave Specialist Information',
  programType: 'Program Type',
  state: 'State',
  qualified: 'Qualified',
  qualifiedThrough: 'Qualified Through',
  eligible: 'Eligible',
  eligibleThrough: 'Eligible Through',
  leaveBalanceText: 'Leave Balance',
  downloadText: 'Download',
  leaveOverviewText: 'Leave Overview',
  leaveNotesText: 'Leave Notes',

  // Leave Applied Report
  leaveAppliedReportText: 'Leave Time Applied Report',
  leaveIdText: 'Leave ID',
  leaveCatagoryText: 'Leave Category',
  leaveAppliedReceivedText: 'Received',

  //Absence Details
  absenceDateText: 'Absence date',
  absenceDetails: 'Absence details',
  workScheduleDetailsText: 'Work schedule details',
  daysInWorkWeekText: 'Days in work week',
  hoursInWorkWeekText: 'Hours in work week',
  hoursinWorkDayText: 'Hours in work day',
  programText: 'Program',
  reportedText: 'Reported on',
  addedToLeaveText: 'Added to leave',
  lastModifiedText: 'Last modified',
  // Shared
  dayText: 'Day',
  hoursText: 'Hours',
  mondayText: 'Monday',
  tuesdayText: 'Tuesday',
  wednesdayText: 'Wednesday',
  thursdayText: 'Thursday',
  fridayText: 'Friday',
  saturdayText: 'Saturday',
  sundayText: 'Sunday',
  statusReasonText: 'Status Reason',
  statusText: 'Status',
  closedDateText: 'Closed Date',
  coverageText: 'Coverage',
  phoneText: 'Phone',
  faxText: 'Fax',
  returnCoverageDetailsText: 'Return to coverage details',
  productText: 'Product',
  determinationDateText: 'Determination Date',
  mailingAddressHeaderText: 'Mailing Address',
  workEmailText: 'Work Email',
  supervisorText: 'Supervisor',
  hoursWorkedInText: 'Hours worked in the last 12 months',
  serviceMonthsText: 'Service Months',
  statusAvailableText: 'All statuses available for this employee are listed below',
  salaryAmountText: 'Salary Amount',
  salaryModeText: 'Salary Mode',
  jobCategoryText: 'Job Category',
  jobTitleText: 'Job Title',
  benefitEligibleText: 'Date Eligible for Benefits',
  genderText: 'Sex assigned at birth',
  addressText: 'Address',
  emailText: 'Email',
  uploadDocumentsText: 'Upload Documents',
  claimManagement: 'Claim Management',
  homeAddressText: 'Home address',
  homePhoneText: 'Home phone',
  workAddressText: 'Work address',
  workPhoneText: 'Work phone',

  employeeIdText: 'Employee ID',
  ssnSidText: 'SSN/SID',
  dateOfBirthText: 'Date of birth',
  dateOfHireText: 'Date of hire',
  locationText: 'Location',
  subsidiaryText: 'Subsidiary',
  benefitClassText: 'Benefit class',

  printSummaryText: 'Print Summary',

  findMyInfoEOIStatusLinkText_1: 'Please note: ',
  findMyInfoEOIStatusLinkText_2: 'For Evidence of insurability, you may continue without entering a number and date of birth. ',
  findMyInfoEOIStatusLinkText_3: 'View my Evidence of insurability',

  // EOI Stuff
  eoiSpecialistHeader: 'EOI Specialist Information',
  eoiSpecialistMailingAddressHeader: 'Mailing Address',
  eoiSpecialistPhoneNumberHeader: 'Phone Number',
  eoiSpecialistOvernightMailingAddressHeader: 'Overnight Mailing Address',
  eoiSpecialistFaxNumberHeader: 'Fax Number',

  // Coverage Details
  allHeaderText: 'All',
  claimHeaderText: 'Claim',
  leaveHeaderText: 'Leave',
  eoiHeaderText: 'Evidence of insurability',
  eventNumberText: 'Event#',
  receivedDateText: 'Received Date',
  statusDefinitionText: 'Status Definition',
  questionsCaseManagerText: 'Questions? Contact the case manager shown.',
  questionsLeaveSpecialistText: 'Questions? Contact the leave specialist shown.',
  returnToSearch: 'Return to search',
  returnToViewTeamMember: 'Return to Team View',
  returnToViewTeamMemberText:
    'To edit or cancel unplanned time off transactions within +/- 5 days, click the "Report or Change Unplanned Absence" link above for this employee.',

  // Modes and Periods
  modeText_H: 'Hourly',
  modeText_D: 'Daily',
  modeText_W: 'Weekly',
  modeText_M: 'Monthly',
  modeText_Y: 'Yearly',
  simpleModeText_H: 'hour',
  simpleModeText_D: 'day',
  simpleModeText_W: 'week',
  simpleModeText_M: 'month',
  simpleModeText_Y: 'year',
  perEpisodeText: 'Per episode',
  episodeText: 'episode',
  perText: 'per',

  //Payment List
  paymentInformationTabHeaderText: 'Payment Information',
  checkDateHeaderText: 'Check Date',
  beginPaymentDateHeaderText: 'Begin Payment Date',
  endPaymentDateHeaderText: 'End Payment Date',
  checkStatusHeaderText: 'Check Status',
  grossBenefitsHeaderText: 'Gross Benefits',
  netBenefitsHeaderText: 'Net Benefits',
  checkNumberHeaderText: 'Check Number',
  payeeHeaderText: 'Payee',
  issuedText: 'Issued',
  IS: 'Issued',
  cashedText: 'Cashed',
  CA: 'Cashed',

  beginDateText: 'Begin Payment Date',
  endDateText: 'End Payment Date',
  amountText: 'Amount',
  reasonText: 'Reason',

  wageText: 'Wages',
  taxableText: 'Taxable',
  nonTaxableText: 'Non-Taxable',
  federalText: 'Federal',

  checkDetailsHeaderText: 'Check Details',
  benefitGrossAmountText: 'Gross Benefit',
  checkTotalOffsetAmountText: 'Offsets',
  checkAdjustedBenefitGrossAmountText: 'Adjusted Gross',
  colaAmountText: 'COLA',
  checkTaxWithheldAmountText: 'Taxes (WithHolding)',
  checkTotalDeductionAmountText: 'Other Deductions',
  benefitNetAmountText: 'Net Benefit',

  checkFicaTaxWithheldAmountText: 'FICA',
  checkFederalTaxWithheldAmountText: 'Federal',
  checkStateTaxWithheldAmountText: 'State',
  checkLoclTaxWithheldAmountText: 'Local',
  checkSsoaiTaxWithheldAmountText: 'OASDI',
  checkMedicareTaxWithheldAmountText: 'Medicare',

  noInformationText: 'No Information Available',

  taxesText: 'Taxes',
  withholdingAmountText: 'Withholding Amount',
  totalText: 'Total',
  withholdingAmountSmallText: 'Amount',

  noDataMessageOffset: 'There is no offset data to display',
  noDataMessageOtherDeductions: 'There is no other deduction data to display',

  // Correspondence
  correspondenceText: 'Correspondence',
  documentType: 'Document Type',
  description: 'Description',
  documentDate: 'Document Date',
  documentSentDate: 'Sent Date',
  documentSentToName: 'Sent to Name',
  documentMethodSent: 'Method Sent',

  //CoverageDetail - AS Table Header
  event: 'Event#',
  status: 'Status',
  accommodationHeaderText: 'Accommodation',
  accommodationEventHeaderText: 'Accommodation event',

  //AccommodationDetails - AS Info
  eventId: 'Event ID',
  eventStatus: 'Event Status',
  jobAccommodation: 'Job Accommodation',
  eventReceivedDate: 'Event Received Date',

  //AccommodationDetails - Request Info
  request: 'Request',
  requestType: 'Request Type',
  providedType: 'Provided Type',
  eeAccommodationCallForHelpText:
    'If you have any question about the details of your accommodation request please contact your employer.',

  //EOI - TODO: Move this lol
  eoiError:
    'Something went wrong. Please reload the page and try again. If the problem persists, please contact technical support.',
  eoiHomepage: 'Go to Homepage.',

  employeeDetails: 'Employee details',
};
