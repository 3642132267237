export const UA_TIME_OFF_TYPES = [
    { label: 'Absent for full day', value: 'OUT' },
    { label: 'Arriving to work late', value: 'LATE' },
    { label: 'Leave w/ No Return', value: 'EARLYNORTN' },
    { label: 'Leave w/ Return', value: 'EARLYRTN' },
];
export const UA_TIME_OFF_REASONS = [
    { label: 'Personal Sickness', value: 'SELF' },
    { label: 'Sick Family Member', value: 'FAMILY' },
    { label: 'Transportation', value: 'TRANSP' },
    { label: 'House Maintenance', value: 'HOUSE' },
    { label: 'Death in the Family', value: 'BEREAV' },
    { label: 'Civic Duty', value: 'CIVIC' },
    { label: 'Other', value: 'OTHER' },
];
export const UA_DURATION_HOURS = Array.from(Array(24).keys(), (value, index) => ({
    label: `${index}`.padStart(2, '0'),
    value: `${index}`.padStart(2, '0'),
}));
export const UA_HOURS = [
    { label: '12 AM', value: "00" },
    { label: '1 AM', value: "01" },
    { label: '2 AM', value: "02" },
    { label: '3 AM', value: "03" },
    { label: '4 AM', value: "04" },
    { label: '5 AM', value: "05" },
    { label: '6 AM', value: "06" },
    { label: '7 AM', value: "07" },
    { label: '8 AM', value: "08" },
    { label: '9 AM', value: "09" },
    { label: '10 AM', value: "10" },
    { label: '11 AM', value: "11" },
    { label: '12 PM', value: "12" },
    { label: '1 PM', value: "13" },
    { label: '2 PM', value: "14" },
    { label: '3 PM', value: "15" },
    { label: '4 PM', value: "16" },
    { label: '5 PM', value: "17" },
    { label: '6 PM', value: "18" },
    { label: '7 PM', value: "19" },
    { label: '8 PM', value: "20" },
    { label: '9 PM', value: "21" },
    { label: '10 PM', value: "22" },
    { label: '11 PM', value: "23" }
];
export const UA_MINUTES = Array.from(Array(60).keys(), (value, index) => ({
    label: `${index}`.padStart(2, '0'),
    value: `${index}`.padStart(2, '0'),
}));
export const BASE_URL = '/ua-service';
