import { Spinner } from '@digitools/honest-react';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import { Modal } from '@digitools/honest-react/dist/lib/components/Modal';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { useFormState } from 'informed';
import React, { useEffect, useRef } from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import useTranslate from '../../../hooks/useTranslate';
import { DeleteInterviewData } from '../api/EoiApiCalls';
import useEoiContext from '../hooks/useEoiContext';
import { SaveForLaterText } from '../i18n/SaveForLaterText';
import styled from 'styled-components';
const StyledLaterButton = styled(Button) `
    @media(max-width: 1200px) {
      padding: 0 1rem;
    }
`;
const StyledDeleteButton = styled(Button) `
    @media(max-width: 1200px) {
        padding: 0 1rem;
    }
`;
const SaveForLaterButtons = (props) => {
    const { savedInterview, sflModalBodyText, sflModalHeaderText, setSaveForLaterError, saveForLaterError, displaySaveForLaterModal, setDisplaySaveForLaterModal, setDisplaySaveForLaterError, displaySaveForLaterError, } = useEoiContext();
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const formState = useFormState();
    const { trackClickWithPage } = useGoogleAnalytics();
    const setIsOpenRef = useRef(() => { });
    const setControl = (setIsOpen) => {
        setIsOpenRef.current = setIsOpen;
    };
    useEffect(() => {
        if (!saveForLaterError && displaySaveForLaterModal && displaySaveForLaterError) {
            setIsOpenRef.current(true);
            setDisplaySaveForLaterModal(true);
        }
        else if (saveForLaterError) {
            setIsOpenRef.current(false);
            setDisplaySaveForLaterModal(false);
        }
    }, [saveForLaterError, savedInterview, displaySaveForLaterModal, displaySaveForLaterError]);
    const saveInterview = async () => {
        setSaveForLaterError(undefined);
        if (props.saveToProvider) {
            props.saveToProvider(formState.values);
        }
    };
    // Save/Delete Modal button behavior
    const ReturnToApplicationButton = ({ hideModal }) => {
        const handleOnClick = () => {
            trackClickWithPage('saveForLaterReturnToApplication', props.page);
            hideModal();
            setDisplaySaveForLaterModal(false);
            setDisplaySaveForLaterError(false);
        };
        return (<Button type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-return-button'}>
        {t(SaveForLaterText.returnToApplication)}
      </Button>);
    };
    // Save Modal wraps this button
    const SaveButton = ({ showModal }) => {
        const handleOnClick = async () => {
            trackClickWithPage('saveApplication', props.page);
            if (!formState.invalid) {
                setDisplaySaveForLaterModal(true);
                setDisplaySaveForLaterError(true);
                await saveInterview();
                setDisplaySaveForLaterModal(false);
            }
        };
        return (<StyledLaterButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-save-button'}>
        <Icon name={'save'} color={'secondary'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        {t(SaveForLaterText.saveForLater)}
      </StyledLaterButton>);
    };
    // Save Modal button behavior
    const ExitApplicationButton = () => {
        const handleOnClick = () => {
            trackClickWithPage('saveForLaterExitApplication', props.page);
            window.location.assign('/customer/employee/home');
        };
        return (<Button type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-exit-button'}>
        {t(SaveForLaterText.exitApplication)}
      </Button>);
    };
    // Delete Modal button behavior
    const DeleteApplicationButton = ({ hideModal }) => {
        const handleOnClick = async () => {
            trackClickWithPage('saveForLaterDeleteApplicationConfirmation', props.page);
            try {
                await DeleteInterviewData(authHeader());
                window.location.assign('/customer/employee/home');
            }
            catch (error) {
                setSaveForLaterError(t(SaveForLaterText.saveForLaterDeleteErrorMessage));
                setDisplaySaveForLaterError(true);
                hideModal();
            }
        };
        return (<Button type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleOnClick}>
        {t(SaveForLaterText.deleteApplicationModal)}
      </Button>);
    };
    // Delete Modal wraps this button
    const DeleteButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackClickWithPage('saveForLaterDeleteApplication', props.page);
            showModal();
        };
        return (<StyledDeleteButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-delete-button'} style={{ marginLeft: '0rem' }}>
        <Icon name={'trash'} color={'primaryDark'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        <span style={{ color: themes.lincoln.palette.primaryDark }}>{t(SaveForLaterText.deleteApplication)}</span>
      </StyledDeleteButton>);
    };
    return (<>
      {savedInterview && (<Modal headerText={t(SaveForLaterText.deleteApplication)} modalOpenRenderButton={DeleteButton} footerPrimaryRenderButton={DeleteApplicationButton} footerSecondaryRenderButton={ReturnToApplicationButton} footerContentPosition={'flex-start'} theme={themes.lincoln} data-testid={'sfl-delete-modal'}>
          <p>
            <strong>{t(SaveForLaterText.deleteApplicationHeaderSuccess)}</strong>
            <br />
            <br />
            {t(SaveForLaterText.deleteApplicationTextSuccess)}
          </p>
        </Modal>)}
      <Modal headerText={t(SaveForLaterText.saveForLater)} modalOpenRenderButton={SaveButton} footerPrimaryRenderButton={ExitApplicationButton} footerSecondaryRenderButton={ReturnToApplicationButton} footerContentPosition={'flex-start'} theme={themes.lincoln} getControl={setControl} data-testid={'sfl-save-modal'}>
        {sflModalHeaderText && sflModalBodyText ? (<p>
            <strong> {sflModalHeaderText} </strong>
            <br />
            <br />
            {sflModalBodyText}
          </p>) : (<Spinner id='sfl-buttons-spinner'/>)}
      </Modal>
    </>);
};
export default SaveForLaterButtons;
