import { invalidRenteredPasswordMessageMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidReEnteredPassword } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidReEnteredPassword(isRequired = false) {
    const errorMessageMap = [invalidRenteredPasswordMessageMap];
    const validators = [isValidReEnteredPassword];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: 'Re-enter Password', [Language.SPANISH]: 'Re-escribir Contraseña' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidReEnteredPassword;
