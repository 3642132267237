import React from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import userAuthentication from '../../../../../hooks/useAuthentication';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { ConfirmationText } from '../../../i18n/ConfirmationText';
import { RedBulletList } from '../../../../../components/Formatting/StyledComponents';
const checkIfEoiStatusEnabled = (user) => {
    if (user) {
        return user.applicationPermissions && user.applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
    }
    else {
        return false;
    }
};
const YourNextSteps = () => {
    const { t } = useTranslate();
    const { user } = userAuthentication();
    return (<div data-testid={'your-next-steps'} style={{ marginTop: '2.5rem' }}>
      <Heading theme={themes.lincoln} elemType={'h3'} color={'grayDark'} style={{ marginBottom: '0.5rem' }} data-testid={'your-next-steps-heading'}>
        {t(ConfirmationText.yourNextSteps)}
      </Heading>
      <RedBulletList>
        <li data-testid={'please-take-a-moment'}><span>{t(ConfirmationText.pleaseTakeAMoment)}</span></li>
        {checkIfEoiStatusEnabled(user) && <li data-testid={'eoi-status'}><span>{t(ConfirmationText.eoiStatus)}</span></li>}
      </RedBulletList>
    </div>);
};
export default YourNextSteps;
