import React, { useEffect, useState } from 'react';
import { Col, Row } from '@digitools/honest-react';
import { getEnvVariable } from 'src/utils/envUtils';
import LoginBox from './LoginBox';
import { useNavigate } from 'react-router-dom';
import { StyledLCNText } from 'src/components/styledComponents';
import { VanityPageText } from '../../vanityPage/constants/VanityPageText';
import useTranslate from 'src/hooks/useTranslate';
import { BackButton, HeadingBanner, StyledPageContainer } from '../../vanityPage/components/StyledComponents';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
import useStepperContext from '../../vanityPage/components/useStepperContext';
const Login = () => {
    const enableEmployeeMigration = getEnvVariable('enableEmployeeMigration');
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { trackView } = useTealium();
    const { language } = useExperience();
    const { back } = useStepperContext();
    /* Migration Code -- used to set up flow into employer or employee log in (they look different now during migration) */
    /* New Employer / Employee display */
    // uses url params to shortcut into one mode or another and allow for direct navigation
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [isEmployee, setIsEmployee] = useState(urlParams.get('employee'));
    const [isEmployer, setIsEmployer] = useState(urlParams.get('employer'));
    useEffect(() => {
        if (enableEmployeeMigration) {
            if (!isEmployee && !isEmployer) {
                // no persona yet determined - send to guided exp.
                navigate('/public/migration-help');
            }
            else {
                //TODO: analytics bug requires timeout or this pageView gets swallowed by event firing simultaneously
                setTimeout(() => {
                    trackView({
                        page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.LOGIN,
                        page_l4: isEmployee
                            ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE
                            : isEmployer
                                ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER
                                : 'tbd',
                        user_audience: isEmployee
                            ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE
                            : isEmployer
                                ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER
                                : TEALIUM_PAGE_VIEW.USER_AUDIENCE.PUBLIC,
                    });
                }, 1000);
            }
        }
        else {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.LOGIN,
                page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.LOGIN.LOGIN,
            });
        }
    }, []);
    /** ************** */
    //TODO: for accessibility this really should be a Link!
    const handleOnBack = () => {
        back();
        if (isEmployee) {
            navigate(`/public/migration-help?from=ee-login-back${language === Language.SPANISH ? '&lang=es' : ''}`);
        }
        else if (isEmployer) {
            navigate(`/public/migration-help?from=er-login${language === Language.SPANISH ? '&lang=es' : ''}`);
        }
        else {
            // should never happen if redirect happens - but just in case
            navigate(`public/migration-help${language === Language.SPANISH ? 'lang=es' : ''}`);
        }
    };
    return (<div>
      {enableEmployeeMigration ? (<>
          <HeadingBanner />
          <StyledPageContainer>
            <div>
              <LoginBox enableEmployeeMigration={true} isEmployee={isEmployee} isEmployer={isEmployer}/>
              <BackButton onClick={() => handleOnBack()}/>
              <StyledLCNText>{t(VanityPageText.LCN)}</StyledLCNText>
            </div>
          </StyledPageContainer>
        </>) : (<>
          <Row className={''}>
            <Col id='columnAroundLogin' xl={5} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
              {/* <Box className={'p-4'}> */}
                <LoginBox />
              {/* </Box> */}
            </Col>
          </Row>
        </>)}
    </div>);
};
export default Login;
