import React from 'react';
import { Button } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
const CancelButton = ({ url }) => {
    const navigate = useNavigate();
    return (<Button buttonType={'text'} className={'ml-auto'} onClick={() => navigate(url, { replace: true })}>
      Cancel
    </Button>);
};
export default CancelButton;
