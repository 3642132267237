import React from 'react';
import Modal from '@digitools/honest-react/dist/lib/components/Modal';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import getDisplayText from '../../../utils/languageDisplay';
import { IMfaTroubleshootDisplayTextEn } from '../i18n/IMfaTroubleshootDisplayTextEn';
import { IMfaTroubleshootDisplayTextEs } from '../i18n/IMfaTroubleshootDisplayTextEs';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const MfaHelpTextModal = (props) => {
    const { trackEvent } = useTealium();
    const OpenMfaHelpTextModalButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.MFA_HELP_MODAL,
                event_name: 'MFA Help Text Modal'
            });
            showModal();
        };
        return (<Button buttonType={'text'} onClick={handleOnClick} className={'p-0'} data-testid='open-mfa-help-text-btn'>
        {getDisplayText('linkText', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}
      </Button>);
    };
    return (<Modal containerMaxHeight={'90%'} hasCloseIcon={true} headerText={getDisplayText('heading', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)} modalOpenRenderButton={OpenMfaHelpTextModalButton}>
      <p data-testid='help-modal-text'>{getDisplayText('body', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}</p>
      <p>
        {getDisplayText('stepsTitle', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}
      </p>
      <ol>
        <li>
          <strong>
            {getDisplayText('step1Title', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}{' '}
          </strong>
          {getDisplayText('step1Text', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}
        </li>
        <li>
          <strong>
            {getDisplayText('step2Title', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}{' '}
          </strong>
          {getDisplayText('step2Text', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}
        </li>
        <li>
          <strong>
            {getDisplayText('step3Title', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}{' '}
          </strong>
          {getDisplayText('step3Text', IMfaTroubleshootDisplayTextEn, IMfaTroubleshootDisplayTextEs, props.language)}
        </li>
      </ol>
    </Modal>);
};
export default MfaHelpTextModal;
