export const findMyInformationDisplayTextEs = {
    dateOfBirthLabel: 'Fecha de nacimiento',
    dateOfBirthPlaceholder: 'MM/DD/AAAA',
    emailLabel: 'Correo electrónico preferido',
    employeeIdLabel: 'Número de empleado',
    fieldRequired: 'Este campo es obligatorio.',
    findButton: 'Seguir',
    firstNameLabel: 'Primer nombre',
    FmiHeading: 'Encontrar mi información',
    FmiText: 'Para garantizar una experiencia segura para nuestros clientes, debe completar una verificación de identidad única completando todos los campos a continuación.',
    invalidSSN: 'Número de seguro social no válido.',
    lastNameLabel: 'Apellido',
    ManageBensHeading: 'Administrar mis beneficiarios',
    postalCodeLabel: 'Código postal',
    ssnLabel: 'Número de seguro social ',
    infoMessage: 'Algo salió mal. Inténtalo de nuevo.',
    noIdFoundErrorMessage: 'No se encontró ninguna identificación. Por favor, verifique su información e inténtelo nuevamente.'
};
