import React, { useContext, useEffect, useState } from 'react';
import useStateWithGetter from '../hooks/useStateWithGetter';
import { MessageContext } from './MessageProvider';
const defaultEnglishError = 'The application has encountered an unknown error, if this issue continues, please contact us for assistance.';
const defaultSpanishError = 'La aplicación ha encontrado un error desconocido. Si este problema continúa, contáctenos para obtener ayuda.';
export const defaultUnexpectedErrorApi = {
    setTraceId: () => { },
    getGenerateMessage: () => () => ['', ''],
    setGenerateMessage: () => { },
    setIsMessagingDisabled: () => { },
    setMessage: () => { },
};
export const UnexpectedErrorContext = React.createContext({
    traceId: '',
    isMessagingDisabled: false,
    setGenerateMessage: () => { },
    setIsMessagingDisabled: () => { },
    getGenerateMessage: () => undefined,
    message: ['', ''],
    setMessage: () => { },
});
export const defaultMessageGenerator = (traceId) => {
    const messages = [defaultEnglishError, defaultSpanishError];
    if (traceId) {
        // Apparently Error is Error in spanish.
        messages[0] = messages[0].concat(` (Error: ${traceId})`);
        messages[1] = messages[1].concat(` (Error: ${traceId})`);
    }
    return messages;
};
const UnexpectedErrorProvider = ({ children, getApi = () => { }, }) => {
    const [traceId, setTraceId] = useState('');
    const [isMessagingDisabled, setIsMessagingDisabled] = useState(true);
    const [_, setGenerateMessage, getGenerateMessage] = useStateWithGetter(() => defaultMessageGenerator);
    const [message, setMessage] = useState(['', '']);
    const { addMessage } = useContext(MessageContext);
    const value = {
        traceId,
        isMessagingDisabled,
        setIsMessagingDisabled,
        setGenerateMessage,
        getGenerateMessage,
        message,
        setMessage,
    };
    const api = {
        setTraceId,
        getGenerateMessage,
        setGenerateMessage,
        setIsMessagingDisabled,
        setMessage,
    };
    useEffect(() => getApi(api), []);
    useEffect(() => {
        if (message && !isMessagingDisabled) {
            addMessage({ message, type: 'error', closeable: true });
        }
    }, [message]);
    return <UnexpectedErrorContext.Provider value={value}>{children}</UnexpectedErrorContext.Provider>;
};
export default UnexpectedErrorProvider;
