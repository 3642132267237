import { useAuth0 } from 'status/hooks';
import { Navigate } from 'react-router-dom';
import React from 'react';
const EmployerRedirect = () => {
    const { userMeta } = useAuth0();
    /* ---------- IF statements are in order of highest role first ---------- */
    // TODO remove this when we get rid of super_admin role
    if (userMeta.roleNames.indexOf('super_admin') > -1) {
        return <Navigate replace to={'/status/employer/search'}/>;
    }
    if (userMeta.roleNames.indexOf('employeradmin') > -1) {
        return <Navigate replace to='/status/employer/search'/>;
    }
    if (userMeta.roleNames.indexOf('internal') > -1) {
        return <Navigate replace to='/status/employer/search'/>;
    }
    if (userMeta.roleNames.indexOf('broker') > -1) {
        return <Navigate replace to='/status/employer/search'/>;
    }
    if (userMeta.roleNames.indexOf('vendor') > -1) {
        return <Navigate replace to='/status/employer/search'/>;
    }
    if (userMeta.roleNames.indexOf('employer') > -1) {
        return <Navigate replace to='/status/employer/search'/>;
    }
    return <Navigate replace to={'/status/employee/findmyinfo'}/>;
};
export default EmployerRedirect;
