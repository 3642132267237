import { Row, Col, Checkbox, themes, Heading } from '@digitools/honest-react';
import React from 'react';
import { useFormApi } from 'informed';
import * as _ from 'lodash';
export const reportFieldSectionLabels = [
    ' personal information fields',
    ' work information fields',
    'Claim date fields',
    'Claim detail fields',
    'Leave date fields',
    'Leave detail fields',
    'Accommodation date fields',
    'Accommodation detail fields',
];
export const allFields = [
    'AllPersonalInfo',
    'AllWorkInfo',
    'AllClaimDates',
    'AllClaimDetails',
    'AllASDetails',
    'AllASDates',
    'AllLeaveDetails',
    'AllLeaveDates',
];
export const fieldsAlwaysSelected = ['Claim Number', 'Leave Number', 'Event Number', 'Request Number', 'Linked Claims'];
export const EmployeePersonalInformation = ({ infoFields, employeeTextCustomContent, }) => {
    const formApi = useFormApi();
    return commonCollapseSection(infoFields, employeeTextCustomContent + reportFieldSectionLabels[0], formApi);
};
export const EmployeeWorkInformation = ({ infoFields, employeeTextCustomContent, }) => {
    const formApi = useFormApi();
    return commonCollapseSection(infoFields, employeeTextCustomContent + reportFieldSectionLabels[1], formApi);
};
export const DateInformation = ({ claimFields, leaveFields, accommodationFields, reportFilterCategory, }) => {
    const formApi = useFormApi();
    switch (reportFilterCategory) {
        case 'Claim':
            return commonCollapseSection(claimFields, reportFieldSectionLabels[2], formApi);
        case 'Leave':
            return commonCollapseSection(leaveFields, reportFieldSectionLabels[4], formApi);
        case 'Accommodation Services':
            return commonCollapseSection(accommodationFields, reportFieldSectionLabels[6], formApi);
    }
    return <div />;
};
export const DetailInformation = ({ claimFields, leaveFields, accommodationFields, reportFilterCategory, }) => {
    const formApi = useFormApi();
    switch (reportFilterCategory) {
        case 'Claim':
            return commonCollapseSection(claimFields, reportFieldSectionLabels[3], formApi);
        case 'Leave':
            return commonCollapseSection(leaveFields, reportFieldSectionLabels[5], formApi);
        case 'Accommodation Services':
            return commonCollapseSection(accommodationFields, reportFieldSectionLabels[7], formApi);
    }
    return <div />;
};
function commonCollapseSection(infoFields, reportFieldSectionLabel, formApi) {
    const subFieldsArray = _.chunk(infoFields, Math.ceil(infoFields ? infoFields.length / 4 : 3));
    return (<div style={{ marginBottom: '2rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }} data-testid={'section-div'}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {reportFieldSectionLabel}
        </Heading>
        <Row>
          {subFieldsArray.map((subFields, index) => (<Col lg={3} key={reportFieldSectionLabel + index}>
              {subFields.map((subField) => (<Col key={reportFieldSectionLabel + subField.fieldLabel} id={reportFieldSectionLabel + subField.fieldLabel} style={{ marginTop: '.3rem' }}>
                  <Checkbox onClick={() => updateAllCheckBox(formApi, infoFields, subField.field)} field={subField.field} label={subField.fieldLabel} initialValue={subField.status} disabled={fieldsAlwaysSelected.indexOf(subField.fieldLabel) >= 0} title={subField.hoverText}/>
                </Col>))}
            </Col>))}
        </Row>
      </div>
      <hr />
    </div>);
}
const updateAllCheckBox = (formApi, infoFields, field) => {
    // Select every checkbox when All checkbox is selected
    if (allFields.indexOf(field) >= 0) {
        for (const eachField of infoFields) {
            if (eachField.fieldLabel !== 'All' && fieldsAlwaysSelected.indexOf(eachField.fieldLabel) < 0) {
                formApi.getValue(field) ? formApi.setValue(eachField.field, false) : formApi.setValue(eachField.field, true);
            }
        }
    }
    // Update All checkbox based on values from other checkbox
    else {
        const allField = infoFields.filter(infoField => infoField.fieldLabel === 'All').map(infoField => infoField.field);
        formApi.setValue(field, !formApi.getValue(field));
        if (allField && allField[0]) {
            formApi.setValue(allField[0], true);
            for (const eachField of infoFields) {
                if (eachField.fieldLabel !== 'All' && !formApi.getValue(eachField.field)) {
                    formApi.setValue(allField[0], false);
                }
            }
        }
    }
};
