import React from 'react';
import LanguageToggle from '../../../utils/LanguageToggle';
import EoiVerificationText from '../i18n/EoiVerificationText';
import EoiCoverageValidations from '../i18n/EoiCoverageText';
import { EoiGeneralText } from '../i18n/EoiText';
import { ApplicantCoverageText } from '../i18n/ApplicantCoverageText';
import { ICoverageType } from '../providers/EoiProvider';
import { PersonalInformationText } from '../i18n/ApplicantInformationText';
export const selectInputErrorMessage = <LanguageToggle en={EoiGeneralText.dropdownSelectError.english} es={EoiGeneralText.dropdownSelectError.spanish}/>;
export const fieldRequired = (value) => {
    if (!value) {
        return <LanguageToggle en={EoiGeneralText.requiredError.english} es={EoiGeneralText.requiredError.spanish}/>;
    }
    return undefined;
};
export const valueMatchRequired = (value, matchingValue) => {
    if (!value || value.toLocaleLowerCase().localeCompare(matchingValue.toLocaleLowerCase()) != 0) {
        return <LanguageToggle en={EoiGeneralText.signatureMatchError.english} es={EoiGeneralText.signatureMatchError.spanish}/>;
    }
    return undefined;
};
export const weightValidation = (value) => {
    if (!value) {
        return <LanguageToggle en={EoiGeneralText.requiredError.english} es={EoiGeneralText.requiredError.spanish}/>;
    }
    else if (value < 1 || value > 999) {
        return <LanguageToggle en={PersonalInformationText.weightErrorMessage.english} es={PersonalInformationText.weightErrorMessage.spanish}/>;
    }
    return undefined;
};
export const ssnValidation = (ssn, ssnFound) => {
    /* A Social Security number CANNOT :
          // Contain all zeroes in any specific group (ie 000-##-####, ###-00-####, or ###-##-0000)
          // Begin with '666′.
          // Begin with any value from '900-999′
          // Be '078-05-1120′ (due to the Woolworth's Wallet Fiasco)
          // Be '219-09-9999′ (appeared in an advertisement for the Social Security Administration)  */
    const regex = RegExp('^(?!219099999|078051120)(?!666|000|9\\d{2})\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}$', 'g');
    if (ssn && (!regex.test(ssn) || ssn === '666666666')) {
        if (ssn?.startsWith('*****') && ssnFound === true) {
            return undefined;
        }
        return <LanguageToggle en={EoiVerificationText.invalidSsn.english} es={EoiVerificationText.invalidSsn.spanish}/>;
    }
    else if (!ssn) {
        return <LanguageToggle en={EoiVerificationText.verifyRequiredError.english} es={EoiVerificationText.verifyRequiredError.spanish}/>;
    }
    return undefined;
};
export const empIdValidation = (empId) => {
    if (empId && empId.length > 11) {
        return <LanguageToggle en={EoiVerificationText.invalidEmpId.english} es={EoiVerificationText.invalidEmpId.spanish}/>;
    }
    else if (!empId) {
        return <LanguageToggle en={EoiVerificationText.verifyRequiredError.english} es={EoiVerificationText.verifyRequiredError.spanish}/>;
    }
    return undefined;
};
export const validateCoverageApplicantElection = (values) => {
    return !values || values.length < 1 ? <LanguageToggle en={ApplicantCoverageText.applicantElectionError.english} es={ApplicantCoverageText.applicantElectionError.spanish}/> : undefined;
};
export const validateCoverageElection = (checkboxGroupValues, applicant, formState, formApi) => {
    if (applicant === 'family') {
        if (formState.errors && formState.errors.spouseCoverageSelections) {
            formApi.setError('spouseCoverageSelections', undefined);
        }
        if (formState.errors && formState.errors.employeeCoverageSelections) {
            formState.errors.employeeCoverageSelections = undefined;
            formApi.setError('employeeCoverageSelections', undefined);
        }
        if (formState.errors && formState.errors.dependentCoverageSelections) {
            formState.errors.dependentCoverageSelections = undefined;
            formApi.setError('dependentCoverageSelections', undefined);
        }
        return undefined;
    }
    else {
        if (formState.values && formState.values.familyCoverage && formState.values.familyCoverage.familyCoverageSelections && formState.values.familyCoverage.familyCoverageSelections.length >= 1 && applicant !== 'employee') {
            return undefined;
        }
        else if (!checkboxGroupValues || checkboxGroupValues.length < 1) {
            // TODO: Future story - need one more check for [hasFamilyOption] - if true show 'Please select a coverage of a family coverage below'
            return <LanguageToggle en={ApplicantCoverageText.coverageElectionError.english} es={ApplicantCoverageText.coverageElectionError.spanish}/>;
        }
    }
};
export const coverageTypeValidation = (coverageType) => {
    if (!coverageType) {
        return <LanguageToggle en={EoiCoverageValidations.coverageType.english} es={EoiCoverageValidations.coverageType.spanish}/>;
    }
    return undefined;
};
export const coverageAmountValidation = (coverageAmount, coverageType) => {
    // could move regex's to their own file but not sure these will be used anywhere else?
    const dollarAmountRegex = RegExp(/(?=.)^(([0-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/);
    const percentRegex = RegExp(/(^[0-9]{0,2}(\.[0-9]{1,2})?)$|(^(100)(\.[0]{1,2})?)$/);
    const salaryMultipleRegex = RegExp(/(^[0-9]{0,5}(\.[0-9]{1,2})?)$/);
    if (!coverageAmount) {
        return <LanguageToggle en={EoiCoverageValidations.coverageAmountRequired.english} es={EoiCoverageValidations.coverageAmountRequired.spanish}/>;
    }
    else if (coverageType === ICoverageType.AMOUNT && !dollarAmountRegex.test(coverageAmount)) {
        return <LanguageToggle en={EoiCoverageValidations.validDollarAmount.english} es={EoiCoverageValidations.validDollarAmount.spanish}/>;
    }
    else if (coverageType === ICoverageType.PERCENTAGE && (!percentRegex.test(coverageAmount) || Number(coverageAmount) < 1)) {
        return <LanguageToggle en={EoiCoverageValidations.validPercent.english} es={EoiCoverageValidations.validPercent.spanish}/>;
    }
    else if (coverageType === ICoverageType.MULTIPLE && !salaryMultipleRegex.test(coverageAmount)) {
        return <LanguageToggle en={EoiCoverageValidations.salaryMultiple.english} es={EoiCoverageValidations.salaryMultiple.spanish}/>;
    }
};
export const validateZipCode = (zipCode) => {
    if (zipCode) {
        const numbers = zipCode.replace(/[^\d]/g, '');
        if (!(numbers.length === 5 || numbers.length === 9)) {
            return <LanguageToggle en={EoiCoverageValidations.zipCode.english} es={EoiCoverageValidations.zipCode.spanish}/>;
        }
    }
    return fieldRequired(zipCode);
};
export const validateCanadianZipCode = (zipCode) => {
    if (zipCode) {
        const zipWithoutDash = zipCode.replace(/-|\s/g, '').toUpperCase();
        const canadianZipCodeRegex = RegExp(/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/);
        if (!canadianZipCodeRegex.test(zipWithoutDash)) {
            return <LanguageToggle en={EoiCoverageValidations.zipCode.english} es={EoiCoverageValidations.zipCode.spanish}/>;
        }
    }
    return fieldRequired(zipCode);
};
export const validateSalary = (salary) => {
    if (salary) {
        const numbers = salary.replace(/,/g, '');
        const maxSalary = 99999999.00;
        if (parseFloat(numbers) > maxSalary) {
            return <LanguageToggle en={EoiCoverageValidations.salary.english} es={EoiCoverageValidations.salary.spanish}/>;
        }
    }
    else {
        return fieldRequired(salary);
    }
};
export const validateName = (name) => {
    const regex = RegExp(/^[-A-Za-z'. ]*$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={EoiCoverageValidations.name.english} es={EoiCoverageValidations.name.spanish}/>;
    }
    else if (!name) {
        return fieldRequired(name);
    }
    return undefined;
};
export const validateNameOptional = (name) => {
    const regex = RegExp(/^[-A-Za-z'. ]*$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={EoiCoverageValidations.name.english} es={EoiCoverageValidations.name.spanish}/>;
    }
    return undefined;
};
