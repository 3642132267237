import AboutYourAbsenceText from "../constants/AboutYourAbsenceText";
import { AboutYouText } from "../constants/AboutYouText";
import { AdditionalInfoText } from "../constants/AdditionalInfoText";
import { ReviewText } from "../constants/ReviewText";
export const getEventStatusByCurrentStep = (stepper, t, aboutYouPageTitle) => {
    if ((stepper.currentStep === aboutYouPageTitle) || stepper.currentStep === t(AboutYouText.pageTitleEE))
        return 'step 2';
    if (stepper.currentStep === t(AboutYourAbsenceText.pageTitleEE) || stepper.currentStep === t(AboutYourAbsenceText.pageTitleER))
        return 'step 3';
    if (stepper.currentStep === t(AdditionalInfoText.pageTitle) || stepper.currentStep === t(AboutYourAbsenceText.contactTitle))
        return 'step 4';
    if (stepper.currentStep === t(ReviewText.pageTitle))
        return 'step 6';
};
