import { useEffect, useRef, useReducer, useState } from 'react';
import axios from 'axios';
import { UnauthorizedError } from 'src/types/UnauthorizedError';
import useAuthentication from './useAuthentication';
const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                data: undefined,
                loading: false,
                error: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
const configureOptions = (opts, authHeader) => {
    const defaultHeaders = {
        Authorization: authHeader,
        Accept: 'application/json',
    };
    const configuredOptions = {
        ...opts,
    };
    if (opts && opts.headers) {
        configuredOptions.headers = {
            ...defaultHeaders,
            ...opts.headers,
        };
    }
    else {
        configuredOptions.headers = defaultHeaders;
    }
    return configuredOptions;
};
const useGet = ({ url, options, noInitial, handleUnauthorized, success, failure, retry }, reget = []) => {
    const { authHeader } = useAuthentication();
    const [state, dispatch] = useReducer(dataFetchReducer, {
        loading: !noInitial,
        error: null,
        data: null,
    });
    const [_, throwError] = useState();
    // Becaues we wrap these up in refetch
    const urlRef = useRef(url);
    const optionsRef = useRef(options);
    const retryRef = useRef(retry);
    urlRef.current = url;
    optionsRef.current = options;
    retryRef.current = retry;
    const fetchData = async (uri, opts = {}, canceler, handlers, fetchConfig = {}) => {
        // Configure options
        const configuredOptions = configureOptions(opts, authHeader());
        // We are starting to fetch
        dispatch({ type: 'FETCH_INIT' });
        // Define error handler function
        const handleError = (e) => {
            // By default we throw unauthorized error
            const unauthorizedCodeResponses = [401, 403, 424];
            if (!handleUnauthorized && unauthorizedCodeResponses.includes(e?.response?.status)) {
                // So, we need to do some magic to throw up to an error boundary
                // https://github.com/facebook/react/issues/14981
                throwError(() => {
                    throw new UnauthorizedError('Unauthorized Error');
                });
            }
            // Call failure handler if there is one
            if (handlers.failure) {
                handlers.failure(e);
            }
            // Finish up if we were not canceled
            if (!canceler.canceled) {
                dispatch({ type: 'FETCH_FAILURE', error: e });
            }
        };
        try {
            const result = await axios.get(uri, configuredOptions);
            if (!canceler.canceled) {
                dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                // Call success handler if there is one
                if (handlers.success) {
                    handlers.success(result.data);
                }
            }
        }
        catch (error) {
            // If a retry was passed then try again
            if (fetchConfig.retry) {
                try {
                    // Configure options
                    const configuredOptions = configureOptions(fetchConfig.retry.options, authHeader());
                    // Make the call
                    const result = await axios.get(fetchConfig.retry.url, configuredOptions);
                    if (!canceler.canceled) {
                        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                        // Call success handler if there is one
                        if (handlers.success) {
                            handlers.success(result.data);
                        }
                    }
                }
                catch (e) {
                    handleError(e);
                }
            }
            else {
                handleError(error);
            }
        }
    };
    useEffect(() => {
        // Create canceler
        const canceler = {
            canceled: false,
        };
        // Create handlers
        const handlers = {
            success,
            failure,
        };
        if (!noInitial) {
            fetchData(url, options, canceler, handlers);
        }
        return () => {
            canceler.canceled = true;
        };
    }, [url, ...reget]);
    const get = ({ url: newUrl, options: newOptions, success: newSuccess, failure: newFailure, retry: newRetry, } = {}) => {
        // Create canceler
        const canceler = {
            canceled: false,
        };
        // Create handlers
        const handlers = {
            success: newSuccess || success,
            failure: newFailure || failure,
        };
        const fetchConfig = {
            retry: newRetry || retryRef.current,
        };
        // Fetch the data
        fetchData(newUrl || urlRef.current, newOptions || optionsRef.current, canceler, handlers, fetchConfig);
        // Return function that will cancel the result
        return () => (canceler.canceled = true);
    };
    return {
        ...state,
        get,
    };
};
export default useGet;
