import { Box, Col, Heading, Row, themes } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import useTealium from '../../../hooks/useTealium';
import useTranslate from '../../../hooks/useTranslate';
//@ts-ignore
import PhoneImg from './Basic_Icons_Call.svg';
import ContactUsText from './ContactUsText';
//@ts-ignore
import EmailImg from './FileManagement_Icons_Mail.svg';
// TODO: this should probably live inside honest's global styles
const ColoredListItem = styled('li') `
  ::marker {
    color: ${themes.lincoln.palette.primaryDark};
    margin-right: 0.15rem important!;
  }
`;
const ContactUs = () => {
    const { t } = useTranslate();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.CONTACT_US,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.CONTACT_US.VIEW,
        });
    }, []);
    return (<div id='contactUsPublic' data-testid='contactUsPublic' style={{ padding: '2rem' }}>
      <Heading elemType={'h1'} elemStyle={'h1'}>
        {t(ContactUsText.heading)}
      </Heading>
      <Heading elemType={'h2'} elemStyle={'h2'} style={{ color: `${themes.lincoln.palette.primaryDark}` }}>
        {t(ContactUsText.subHeading)}
      </Heading>
      <br />
      <p>
        <Heading elemType={'h3'} elemStyle={'h3'}>
          {t(ContactUsText.requestHeading)}
        </Heading>
        <p>{t(ContactUsText.requestText)}</p>
      </p>
      <br />
      <p>
        <Heading elemType={'h3'} elemStyle={'h3'}>
          {t(ContactUsText.claimHeading)}
        </Heading>
        <p>
          <ul>
            <ColoredListItem>
              <span style={{ color: 'black' }}>{t(ContactUsText.stdText)}</span>
            </ColoredListItem>
            <ColoredListItem>
              <span style={{ color: 'black' }}>{t(ContactUsText.ltdText)}</span>
            </ColoredListItem>
          </ul>
        </p>
      </p>
      <br />
      <Heading elemType={'h3'} elemStyle={'h3'}>
        {t(ContactUsText.registrationHelpHeader)}
      </Heading>
      {t(ContactUsText.registrationHelpText)}
      <Box style={{
            display: 'flex',
            marginTop: '1.5rem',
            borderLeft: `10px solid ${themes.lincoln.palette.primaryDark}`,
        }}>
        <Row>
          <Col sm={12} md={6} style={{ display: 'flex' }}>
            <PhoneImg width='75' height='75' style={{
            height: '75px',
            marginLeft: '1rem',
            marginRight: '1rem',
            fill: `${themes.lincoln.palette.primaryDark}`,
        }}/>
            <p>
              <Heading style={{ fontWeight: 300, fontSize: '1.25rem' }} elemType={'h3'} elemStyle={'h5'}>
                {t(ContactUsText.phone)}
              </Heading>
              {t(ContactUsText.callUs)}
              <a href='tel:1-800-431-2958'>1-800-431-2958</a>
              <br />
              8:00am–8:00pm EST {t(ContactUsText.monday)}-{t(ContactUsText.thursday)}
              <br />
              8:00am–6:00pm EST {t(ContactUsText.friday)}
              <br />
            </p>
          </Col>
          <Col sm={12} md={6} style={{ display: 'flex' }}>
            <EmailImg width='75' height='75' style={{ marginLeft: '1rem', marginRight: '1rem', fill: `${themes.lincoln.palette.primaryDark}` }}/>
            <p>
              <Heading style={{ fontWeight: 300, fontSize: '1.25rem' }} elemType={'h3'} elemStyle={'h5'}>
                Email
              </Heading>
              {t(ContactUsText.messageUs)}
              <a href='mailto:mylincolnportal_help@lfg.com'>mylincolnportal_help@lfg.com</a>
            </p>
          </Col>
        </Row>
      </Box>
    </div>);
};
export default ContactUs;
