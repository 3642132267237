export const AboutYourAbsenceText = {
    pageTitleEE: {
        english: 'About your absence',
        spanish: 'Acerca de su ausencia',
    },
    pageTitleER: {
        english: 'About absence',
        spanish: 'About absence',
    },
    contactTitle: {
        english: 'Medical Contacts',
        spanish: 'Contactos Médicos',
    },
    heading: {
        english: 'About your absence',
        spanish: 'Sobre tu ausencia',
    },
    mainSubHeading: {
        english: 'Tell us about your absence',
        spanish: 'Cuéntanos sobre tu ausencia',
    },
    mainSubHeadingER: {
        english: "Tell us about your Employee's absence",
        spanish: 'Tell us about your',
    },
    medicalContactSubHeadingEE: {
        english: 'Where did you get assistance? (optional)',
        spanish: '¿Dónde obtuvo asistencia? (opcional)',
    },
    medicalContactIntro: {
        english: 'Please provide us with the contact information for your physician to help expedite the processing of your absence request. While we do not require this information for purposes of reporting your absence, providing it now will help expedite the determination process.',
        spanish: 'Proporciónenos la información de contacto de su médico para ayudar a acelerar el procesamiento de su solicitud de ausencia. Si bien no requerimos esta información para informar su ausencia, proporcionarla ahora ayudará a acelerar el proceso de determinación.',
    },
    formLabels: {
        leaveType: {
            english: 'What best describes the circumstances for your absence?',
            spanish: '¿Cuál es la mejor descripción de las circunstancias de su ausencia?',
        },
        leaveTypeER: {
            english: 'What best describes the circumstances for the absence?',
            spanish: 'What best describes the circumstances for the absence?',
        },
        leaveCategory1ER: {
            english: 'Will the Employee be out for at least',
            spanish: 'Will the Employee be out for at least',
        },
        leaveCategory2ER: {
            english: 'consecutive days?',
            spanish: 'consecutive days?',
        },
        leaveCategory1: {
            english: 'Will you be out for at least',
            spanish: '¿No se presentará al trabajo al menos por',
        },
        leaveCategory2: {
            english: 'consecutive days?',
            spanish: 'días consecutivos?',
        },
        leaveReason: {
            english: 'What is the reason for your absence?',
            spanish: '¿Cuál es la razón para su ausencia?',
        },
        leaveReasonER: {
            english: "What is the reason for the Employee's absence?",
            spanish: "What is the reason for the Employee's absence?",
        },
        leaveReasonShortLabel: {
            english: 'Leave reason',
            spanish: 'Motivo de ausencia',
        },
        claimantConditionEE: {
            english: 'Select the condition that best describes your circumstances',
            spanish: 'Seleccione la condición que mejor describa sus circunstancias',
        },
        claimantConditionER: {
            english: 'Select the condition that best describes the circumstances',
            spanish: 'Seleccione la condición que mejor describa las circunstancias',
        },
        deliveryTypeEE: {
            english: 'Have you already delivered?',
            spanish: '¿Ya dio a luz?',
        },
        deliveryTypeER: {
            english: 'Has the Employee already delivered?',
            spanish: 'Has the Employee already delivered?',
        },
        deliveryDateEE: {
            english: 'What date did you deliver?',
            spanish: '¿Qué fecha entregaste?',
        },
        deliveryDateExpectedEE: {
            english: 'What is your expected delivery date?',
            spanish: '¿Cuál es la fecha aproximada de su parto?',
        },
        deliveryDateER: {
            english: 'What was the date of delivery?',
            spanish: '¿cual fue la fecha de entrega?',
        },
        deliveryDateExpectedER: {
            english: 'What is the expected delivery date?',
            spanish: '¿Cuál es la fecha de entrega prevista?',
        },
        deliveryDateShortLabel: {
            english: 'Delivery date',
            spanish: 'Fecha de entrega',
        },
        deliveryDateExpectedShortLabel: {
            english: 'Expected Delivery Date',
            spanish: 'Fecha de entrega',
        },
        deliveryDateExpectedError: {
            english: 'Date must be no longer than 9 months in the future',
            spanish: 'La fecha no debe ser superior a 9 meses en el futuro',
        },
        deliveryDateError: {
            english: 'Date must be in the past',
            spanish: 'La fecha debe estar en el pasado',
        },
        deliveryComplicationsEE: {
            english: 'Have you experienced any complications that required a modified work schedule or absences during your pregnancy?',
            spanish: '¿Tuvo alguna complicación que haya requerido modificaciones en su horario de trabajo o que se ausentara durante su embarazo?',
        },
        deliveryComplicationsER: {
            english: 'Has the Employee experienced any complications that required a modified work schedule or absences during their pregnancy?',
            spanish: 'Has the Employee experienced any complications that required a modified work schedule or absences during their pregnancy?',
        },
        lastDayWorked: {
            english: 'What was the last day worked or expected last day worked?',
            spanish: '¿Cuál fue el último día trabajado o esperado el último día de trabajo?',
        },
        lastDayWorkedShort: {
            english: 'Last day worked',
            spanish: 'Último día en que trabajó',
        },
        dateWithinAYearError: {
            english: "The date entered must be within one year before or after today's date",
            spanish: 'La fecha ingresada debe estar dentro de un año antes o después de la fecha de hoy',
        },
        hospitalizationIndicatorEE: {
            english: 'Did you, or will you, go to the hospital?',
            spanish: '¿Fue o irá al hospital?',
        },
        hospitalizationIndicatorER: {
            english: 'Did/Will the Employee go to the hospital? (optional)',
            spanish: 'Did/Will the Employee go to the hospital? (optional)',
        },
        accidentIndicator: {
            english: 'Was this the result of an accident?',
            spanish: '¿Fue consecuencia de un accidente?',
        },
        motorVehicleAccidentIndicatorEE: {
            english: 'Were you in a motor vehicle accident?',
            spanish: '¿Estuvo involucrado en un accidente con vehiculos de motor?',
        },
        motorVehicleAccidentIndicatorER: {
            english: 'Was the Employee in a motor vehicle accident? (optional)',
            spanish: 'Was the Employee in a motor vehicle accident? (optional)',
        },
        workRelatedIndicatorEE: {
            english: 'Did this occur while on the job?',
            spanish: '¿Esto ocurrió en el trabajo?',
        },
        workRelatedIndicatorER: {
            english: 'Did this occur while on the job?',
            spanish: 'Did this occur while on the job?',
        },
        surgeryIndicatorEE: {
            english: 'Did you, or will you, have surgery?',
            spanish: '¿Se sometió a una operación o lo hará en el futuro?',
        },
        surgeryIndicatorER: {
            english: 'Did/Will the Employee have surgery?',
            spanish: 'Did/Will the Employee have surgery?',
        },
        relationshipEE: {
            english: 'What is the person’s relationship to you?',
            spanish: '¿Qué relación tiene esta persona con usted?',
        },
        relationshipER: {
            english: "What is the person's relationship to the Employee?",
            spanish: "What is the person's relationship to the Employee?",
        },
        otherRelationship: {
            english: 'Other relationship',
            spanish: 'Otra relación',
        },
        otherSurgery: {
            english: 'Other surgery',
            spanish: 'Otra cirugía',
        },
        otherIllnessInjurySurgery: {
            english: 'Other illness or injury',
            spanish: 'Otra enfermedad o lesión',
        },
        careRecipientDateOfBirthEE: {
            english: 'What is the date of birth of the person you are caring for?',
            spanish: '¿Cuál es la fecha de nacimiento de la persona que está cuidando?',
        },
        careRecipientDateOfBirthER: {
            english: 'What is the date of birth of the person the Employee is caring for?',
            spanish: 'What is the date of birth of the person the Employee is caring for?',
        },
        careRecipientDateOfBirthShortLabel: {
            english: 'Date of birth (optional)',
            spanish: 'Fecha de nacimiento (opcional)',
        },
        placementDateEE: {
            english: 'What is the date the child was placed in your care?',
            spanish: '¿Cuál es la fecha en que el niño fue puesto bajo su cuidado?',
        },
        placementDateER: {
            english: "What is the date the child was placed in the Employee's care?",
            spanish: "What is the date the child was placed in the Employee's care?",
        },
        placementDateShortLabel: {
            english: 'Placement date (optional)',
            spanish: 'Fecha de colocación (opcional)',
        },
        admissionDateEE: {
            english: 'What date did you, or will you, arrive at the hospital?',
            spanish: '¿En qué fecha llegó al hospital?',
        },
        admissionDateER: {
            english: 'What date did, or will the Employee arrive at the hospital?',
            spanish: '¿En qué fecha llegó o llegará el Empleado al hospital?',
        },
        admissionDateShortLabel: {
            english: 'Admission date (optional)',
            spanish: 'Fecha de internación (opcional)',
        },
        admissionDateErrorRelease: {
            english: 'Admission date cannot be on or after the release date',
            spanish: 'La fecha de admisión no puede ser igual o posterior a la fecha de salida',
        },
        admissionDateErrorClaimBegin: {
            english: 'Admission date cannot come before the date of disability',
            spanish: 'La fecha de admisión no puede ser anterior a la fecha de incapacidad',
        },
        admissionDateErrorIncident: {
            english: 'Admission date cannot come before the incident date',
            spanish: 'La fecha de admisión no puede ser anterior a la fecha del incidente',
        },
        releaseDateEE: {
            english: 'What was, or is, your discharge date from the hospital?',
            spanish: '¿Cuál fue, o es, su fecha de alta del hospital?',
        },
        releaseDateER: {
            english: "What was, or is, the Employee's discharge date from the hospital?",
            spanish: '¿Cuál fue, o es, la fecha de alta del Empleado del hospital?',
        },
        releaseDateShortLabel: {
            english: 'Release date (optional)',
            spanish: 'Fecha de alta (opcional)',
        },
        releaseDateError: {
            english: 'Release date cannot be before the admission date',
            spanish: 'Su fecha de salida no puede ser anterior a su fecha de admisión',
        },
        leaveBeginDateEE: {
            english: 'What is the first day, or expected first day, of your leave of absence?',
            spanish: '¿Cuál es el primer día del primer día esperado de su ausencia?',
        },
        leaveBeginDateER: {
            english: 'What is the first day, or expected first day, of the leave of absence?',
            spanish: 'What is the first day, or expected first day, of the leave of absence?',
        },
        leaveBeginDateError: {
            english: 'Leave begin date cannot be after the leave end date',
            spanish: 'la fecha de inicio de la ausencia no puede ser posterior a la fecha de finalización de la ausencia',
        },
        leaveBeginDateShortLabel: {
            english: 'Leave begin date',
            spanish: 'Primera fecha de ausencia',
        },
        leaveEndDateEE: {
            english: 'What is the last day, or estimated last day, of the leave of absence?',
            spanish: '¿Cuál es el último día, o estimado último día, de su licencia?',
        },
        leaveEndDateER: {
            english: 'What is the last day, or estimated last day, of the leave of absence?',
            spanish: 'What is the last day, or estimated last day, of the leave of absence?',
        },
        leaveEndDateShortLabel: {
            english: 'Leave end date (optional)',
            spanish: 'Última fecha de ausencia (opcional)',
        },
        leaveEndDateError: {
            english: 'The leave end date cannot be on or before your leave begin date',
            spanish: 'La última fecha de ausencia no puede ser igual o anterior a su primera fecha de ausencia',
        },
        incidentDateEE: {
            english: 'What was the date of your injury?',
            spanish: '¿Cuàl fue la fecha de su lesión?',
        },
        incidentDateER: {
            english: 'What was the date of the injury?',
            spanish: '',
        },
        incidentDateShortLabel: {
            english: 'Injury date',
            spanish: 'Fecha de su lesión',
        },
        claimBeginDateEE: {
            english: 'What is your date of disability? This is the first day you are unable to perform your job duties, regardless of work schedule.',
            spanish: '¿Cuàl es su fecha de discapacidad? Este es el primer día que no puede realizar sus tareas laborales, independientemente del horario de trabajo.',
        },
        claimBeginDateER: {
            english: "What is the Employee's date of disability?  This is the first day the Employee is unable to perform their job duties, regardless of work schedule.",
            spanish: "What is the Employee's date of disability?  This is the first day the Employee is unable to perform their job duties, regardless of work schedule.",
        },
        claimBeginDateShortLabel: {
            english: 'Date of disability',
            spanish: 'Fecha de discapacidad',
        },
        surgeryDateEE: {
            english: 'What was, or is, the date of your surgery?',
            spanish: '¿Cuàl fue o serà la fecha de su cirguía?',
        },
        surgeryDateER: {
            english: "What was, or is, the date of the Employee's surgery",
            spanish: 'Fecha de su cirguía',
        },
        surgeryDateShortLabel: {
            english: 'Surgery date (optional)',
            spanish: 'Fecha de la operación (opcional)',
        },
        surgeryDateErrorClaimBegin: {
            english: 'Surgery date cannot come before the date of disability',
            spanish: 'La fecha de la cirugía no puede ser anterior a la fecha de la discapacidad',
        },
        surgeryDateErrorIncident: {
            english: 'Surgery date cannot come before the incident date',
            spanish: 'La fecha de la cirugía no puede ser anterior a la fecha del incidente',
        },
        surgeryTypeYesEE: {
            english: 'What type of surgery are you having?',
            spanish: '¿Qué tipo de cirugía le harán?'
        },
        surgeryTypeYesER: {
            english: 'What type of surgery is the Employee having?',
            spanish: 'What type of surgery is the Employee having?'
        },
        surgeryTypeNoIllnessEE: {
            english: 'What best describes your illness?',
            spanish: '¿Qué es lo que mejor describe su enfermedad?'
        },
        surgeryTypeNoIllnessER: {
            english: 'What best describes your Employee\'s illness?',
            spanish: 'What best describes your Employee\'s illness?'
        },
        surgeryTypeNoInjuryEE: {
            english: 'What best describes your injury?',
            spanish: '¿Qué es lo que mejor describe su lesión?'
        },
        surgeryTypeNoInjuryER: {
            english: 'What best describes your Employee\'s injury?',
            spanish: 'What best describes your Employee\'s injury?'
        },
        surgeryComplicationTextEE: {
            english: 'Please provide details around your related complications, surgeries, injuries, or any additional details related to your absence.',
            spanish: 'Proporcione detalles sobre sus complicaciones relacionadas, cirugías, lesiones o cualquier detalle adicional relacionado con su ausencia.',
        },
        surgeryComplicationTextER: {
            english: "Please provide details around the Employee's related complications, surgeries, injuries, or any additional details related to the absence.",
            spanish: "Please provide details around the Employee's related complications, surgeries, injuries, or any additional details related to the absence.",
        },
        surgeryComplicationsTextShort: {
            english: 'Complications, surgeries, injuries and any additional details. (optional)',
            spanish: 'Complicaciones, cirugías, lesiones y demás información. (optional)',
        },
        surgeryIllnessInjuryComplicationEE: {
            english: 'Please provide details around your diagnosis/condition, current symptoms, related complications, surgeries, injuries, or any additional details related to your absence.',
            spanish: 'Dé información sobre su diagnóstico/condición, síntomas actuales, complicaciones relacionadas, cirugías, lesiones o cualquier información adicional sobre su ausencia.',
        },
        surgeryIllnessInjuryComplicationER: {
            english: 'Please provide details around the Employee\'s diagnosis/condition, current symptoms, related complications, surgeries, injuries, or any additional details related to your absence.',
            spanish: 'Please provide details around the Employee\'s diagnosis/condition, current symptoms, related complications, surgeries, injuries, or any additional details related to your absence.',
        },
        textAreaError: {
            english: (charLimit) => `Field must be less than ${charLimit} characters and may contain any character but \\ \` ~ ^ [ ] and "`,
            spanish: (charLimit) => `El campo debe tener menos de ${charLimit} caracteres y puede contener cualquier carácter excepto \\ \` ~ ^ [ ] y "`,
        },
        relationshipError: {
            english: 'Field must be less than 35 characters and may only contain letters.',
            spanish: 'El campo debe tener menos de 35 caracteres y solo puede contener letras.',
        },
        medicalContact: {
            physicianFirstName: {
                english: "Physician's first name (optional)",
                spanish: 'Nombre del médico (optional)',
            },
            physicianLastName: {
                english: "Physician's last name (optional)",
                spanish: 'Apellido del medico (optional)',
            },
            physicianPhoneNumber: {
                english: "Physician's phone number (optional)",
                spanish: 'Número de teléfono del médico (optional)',
            },
        },
        yes: { english: 'Yes', spanish: 'Sí' },
        unknown: { english: 'Unknown, at this time', spanish: 'Desconocido, en este momento' },
    },
    geneticInfoDisclosure: {
        english: "The Genetic Information Nondiscrimination Act of 2008 (GINA) prohibits employers and other entities covered by GINA Title II from requesting or requiring genetic information of an individual or family member of the individual, except as specifically allowed by this law. To comply with this law, we are asking that you not provide any genetic information when responding to this request for medical information. \"Genetic information\" as defined by GINA, includes an individual's family medical history, the results of an individual's or family member's genetic tests, the fact that an individual or an individual's family member sought or received genetic services, and genetic information of a fetus carried by an individual or an individual's family member or an embryo lawfully held by an individual or family member receiving assistive reproductive services.",
        spanish: 'La información genética no discriminación Act de 2008 (GINA) prohíbe que los empleadores y otras entidades cubiertas por el título II de GINA de solicitar o requerir la información genética de un individuo o miembro de la familia del individuo, salvo lo específicamente permitido por esta ley. Para cumplir con esta ley, estamos pidiendo que no proporcione ninguna información genética al responder a esta solicitud de información médica. "Información Genética" como se define por Gina, incluye el historial médico familiar de un individuo, los resultados de un individuo o miembro de la familia de las pruebas genéticas, el hecho de que un individuo o miembro de la familia del individuo buscó o recibió los servicios genéticos, y la información genética de un feto llevada por un individuo o miembro de la familia del individuo o un embrión legalmente mantenido por un individuo o miembro de la familia que recibe servicios de reproducción asistida.',
    },
    NYDIText: {
        english: 'To file a claim for New York Paid family leave, call 866-783-2255.',
        spanish: 'Para presentar un reclamo de licencia familiar pagada de Nueva York, llame al 866-783-2255.',
    }
};
export default AboutYourAbsenceText;
