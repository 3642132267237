export const AboutAccommodationText = {
    aboutAccommodationEE: {
        english: 'Your accommodation details',
        spanish: 'Información de su adaptación',
    },
    aboutAccommodationER: {
        english: `Your Employee's accommodation details`,
        spanish: `Información de la adaptación de su empleado`,
    },
    heading: {
        english: 'Accommodation request',
        spanish: 'Solicitud de adaptación',
    }
};
export default AboutAccommodationText;
