const SubsAndLocsColumns = [
    {
        Header: 'Subsidiary Code',
        accessor: 'ssdCode',
        id: 'ssdCode',
    },
    {
        Header: 'Subsidiary Name',
        accessor: 'ssdName',
        id: 'ssdName',
    },
    {
        Header: 'Location Code',
        accessor: 'locationCode',
        id: 'locationCode',
    },
    {
        Header: 'Location Name',
        accessor: 'locationName',
        id: 'locationName',
    },
];
export default SubsAndLocsColumns;
