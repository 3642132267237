import React, { useState } from 'react';
import EEFindMyInformation from '../../employeeProfile/components/EEFindMyInformation';
const FindMyInfo = () => {
    const [fmiCompleted, setFmiCompleted] = useState(false);
    if (fmiCompleted) {
        window.location.assign('/customer/rab/employee');
    }
    return (<div data-testid='findMyInfo'>
      <EEFindMyInformation onComplete={setFmiCompleted} noRedirect={true}/>
    </div>);
};
export default FindMyInfo;
