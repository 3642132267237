import React from 'react';
import { Row, Col } from '@digitools/honest-react';
const NoFieldSelected = () => (<div style={{ marginTop: '10px', textAlign: 'left' }}>
    <Row>
      <Col lg={12} md={12}>
        None selected
      </Col>
    </Row>
  </div>);
export default NoFieldSelected;
