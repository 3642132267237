import { Col, Row } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import { VanityPageText } from '../constants/VanityPageText';
import useStepperContext from './useStepperContext';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { StyledBox, StyledHeading, StepperButton, BackButton } from './StyledComponents';
const VanityPageQuestions2 = () => {
    const { t } = useTranslate();
    const { back, goToStep, next, setIsLfg, setIsMigrated } = useStepperContext();
    const { trackEvent } = useTealium();
    const handleOnClickLFG = () => {
        setIsLfg(true);
        setIsMigrated(true);
        goToStep(5);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: 'LincolnFinancial.com',
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickMLP = () => {
        next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: 'MyLincolnPortal.com',
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    return (<div>
      <StyledBox>
        <Row>
          <Col>
            <StyledHeading elemType='h2'>
              {t(VanityPageText.currentLogIn)}
            </StyledHeading>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} className='mx-auto'>
            <StepperButton className='d-block w-100 mb-2' buttonType='primary' onClick={handleOnClickLFG}>
              LincolnFinancial.com
            </StepperButton>
            <StepperButton className='d-block w-100 ml-0 mb-2' buttonType='primary' onClick={handleOnClickMLP}>
              MyLincolnPortal.com
            </StepperButton>
          </Col>
        </Row>
      </StyledBox>
      <BackButton onClick={() => back()}/>
    </div>);
};
export default VanityPageQuestions2;
