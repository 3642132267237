export const HeaderText = {
    welcome: {
        english: 'Welcome',
        spanish: 'Bienvenidas'
    },
    lastLogin: {
        english: 'Last login:',
        spanish: 'Último acceso:',
    },
    logout: {
        english: 'LOG OUT',
        spanish: 'CERRAR SESIÓN'
    },
    language: {
        english: 'In English',
        spanish: 'En Español',
    },
    contactSupport: {
        english: 'CONTACT SUPPORT',
        spanish: 'SOPORTE DE CONTACTO'
    },
    profile: {
        english: 'Profile information',
        spanish: 'Información del perfil',
    },
    settings: {
        english: 'Security settings',
        spanish: 'Seguridad configuración',
    },
    commPrefs: {
        english: 'Alerts & notifications',
        spanish: 'Preferencia de comunicacion',
    },
    emailNotifications: {
        english: 'Email notifications',
        spanish: 'Notificaciónes de correo electrónico',
    },
    userAccess: {
        english: 'User access',
        spanish: 'Acceso de usuario',
    },
    viewing: {
        english: 'VIEWING: ',
        spanish: 'VISITA: ',
    },
};
