import React, { useEffect } from 'react';
import { Button, Container, Heading, Row } from '@digitools/honest-react';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { downloadPdf } from '../../../api/BdmApi';
import { Language } from '../../../types/Language';
import { BdmDisplayTextEn as LanguageEn } from '../language/BdmDisplayTextEn';
import { BdmDisplayTextEs as LanguageEs } from '../language/BdmDisplayTextEs';
import useRouteCheck from '../../../hooks/useRouteCheck';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import { formatInTimeZone } from 'date-fns-tz';
const BdmConfirmation = ({ contingentBeneficiaries, primaryBeneficiaries, signatureData, contingentEqualShares, primaryEqualShares, ssn, empId, }) => {
    const { setBdmCompleted } = useRouteCheck();
    const { authHeader, user } = useAuthentication();
    const { language } = useExperience();
    const { trackEvent, trackView } = useTealium();
    const emailAddress = user ? user.emailAddress : undefined;
    const displayText = language === Language.SPANISH ? LanguageEs : LanguageEn;
    const replaceWith = language === Language.ENGLISH ? ' at' : ' a las';
    const dateSubmitted = formatInTimeZone(new Date(), 'America/New_York', 'P, hh:mm a');
    const estTimeNoSeconds = dateSubmitted.replace(',', replaceWith);
    const config = useBdmLciConfig();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.BDM,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.BDM.CONFIRMATION,
        });
    }, []);
    useEffect(() => {
        setBdmCompleted(true);
    }, []);
    const handlePdfDownload = () => {
        const dataToBackend = {
            contingentBeneficiaries,
            equalSharesContingent: contingentEqualShares,
            equalSharesPrimary: primaryEqualShares,
            primaryBeneficiaries,
            signatureData,
            language,
            ssn,
            employeeId: empId,
            dateSubmitted,
        };
        downloadPdf(dataToBackend, authHeader()).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            // Removed code was for IE only - IM
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Confirmation_Report.pdf`);
            document.body.appendChild(link);
            link.click();
            // @ts-ignore
            link.parentNode.removeChild(link);
        });
        // trackClickWithPage('View Submission', BDM_CONFIRMATION_GA_ROUTE);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIDEO_ENGAGEMENT,
            event_name: 'View submission',
        });
    };
    return (<Container style={{ paddingTop: '.625rem' }}>
      <Row>
        <span>
          <Heading>{displayText.thankYou}</Heading>
        </span>
      </Row>
      <Row>
        <Heading elemType={'h2'} elemStyle={'h4'}>
          {displayText.successMsgStart}
          {estTimeNoSeconds}
          {displayText.successMsgEnd}
        </Heading>
      </Row>
      <Row>
        <p>
          {displayText.confirmEmailMsgStart}
          {emailAddress}.{displayText.confirmEmailMsgEnd}
        </p>
      </Row>
      <Row>
        <Button onClick={handlePdfDownload} type='button' buttonType='secondary' id='btn-view-bdm-submission' data-testid='view-sub-btn'>
          {displayText.viewSubmissionButton}
        </Button>
      </Row>
      <Row>
        {(config?.bdmApplication?.customMessageHeaderConfirmation.english || config?.bdmApplication?.customMessageHeaderConfirmation.spanish) && (<Heading elemType={'h3'}>
            {BdmBeneficiaryUtils.getCustomConfirmation(config.bdmApplication.customMessageHeaderConfirmation, language)}
          </Heading>)}
      </Row>
      <Row>
        <p>{BdmBeneficiaryUtils.getCustomConfirmation(config.bdmApplication.customMessageConfirmation, language)}</p>
      </Row>
    </Container>);
};
export default BdmConfirmation;
