import { Box, Button, Heading, Icon, Row } from '@digitools/honest-react';
import { Link } from 'react-router-dom';
import { BELOW_LARGE_DESKTOP, BELOW_MEDIUM_DESKTOP, MOBILE } from 'src/constants/ScreenSizeConstants';
import styled from 'styled-components';
import { VanityPageText } from '../constants/VanityPageText';
import useTranslate from 'src/hooks/useTranslate';
export const BackButton = ({ onClick }) => {
    const { t } = useTranslate();
    return (<Button buttonType='text' onClick={onClick}>
      <Icon name='long-arrow-left-regular' color='secondaryDark' style={{ fontSize: '.75rem', marginRight: '.5rem' }}/>
      {t(VanityPageText.back)}
    </Button>);
};
export const StyledActionCardRow = styled(Row) `
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 1rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const StyledDiv = styled('div') `
    max-width: 600px
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    @media (max-width: 765px) {
        flex-direction: column-reverse;
    }
`;
export const StyledButtonDiv = styled('div') `
  width: 50%;
  @media (max-width: 765px) {
    width: 100%;
  }
`;
export const StyledRegisterLoginButton = styled(Button) `
  margin-top: 3rem;
  width: 25%;
  @media (max-width: 765px) {
    margin-top: 1rem;
    width: 100%;
  }
`;
export const StyledBackButton = styled(Button) `
  margin-top: 3rem;
  width: 25%;
  @media (max-width: 765px) {
    margin-bottom: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
  }
`;
//   font-size: 2.0625rem; <-- this was overriding h1/h2 sizes
export const StyledHeading = styled(Heading) `
  word-break: break-word;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE}) {
    margin-bottom: 1rem;
    word-break: break-word;
    font-size: 1.825rem !important;
  }
`;
export const StyledQuestionsContainer = styled.div `
  background: white;
  margin-top: 2rem;
  margin: 0 120px;
  position: relative;
  top: -70px;
  border-radius: 4px;
  @media (max-width: ${MOBILE}) {
    & {
      margin: 0 50px;
    }
  }
  @media (max-width: 482px) {
    margin: 0 20px;
  }
  @media (min-width: ${BELOW_LARGE_DESKTOP}) {
    // top: -70px;
  }
`;
export const StyledQuestions = styled(Box) `
  background: white;
  min-height: fit-content;
  padding-top: 0.5rem;
  @media (min-width: 768px) {
    & {
      padding: 35px;
    }
  }
  @media (max-width: ${MOBILE}) {
    margin-bottom: 0;
  }
`;
export const StyledRow = styled(Row) `
  @media (max-width: ${MOBILE}) {
    margin-bottom: 0;
  }
`;
export const StepperButton = styled(Button) `
  max-height: none;
  text-transform: none;
  font-weight: normal;
  width: 50rem;
  text-align: left;
  border-radius: 4px;
  &,
  &:hover:enabled,
  &:focus:enabled {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.palette.grayLight};
    color: ${({ theme }) => theme.palette.grayDarker};
  }
  &:hover:enabled,
  &:focus:enabled {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: ${MOBILE}) {
    text-align: center;
  }
`;
export const StyledHelpHeading = styled(Heading) `
  position: relative;
  margin: 0 120px;
  top: 5rem;
  @media (max-width: ${BELOW_MEDIUM_DESKTOP}) {
    & {
      margin: 0 75px;
      top: 2.5rem;
    }
  }
  @media (max-width: ${MOBILE}) {
    & {
      padding-bottom: 20px;
      padding-top: 20px;
      top: 2rem;
      margin: 0 10px;
    }
  }
  @media (max-width: 482px) {
    & {
      top: 1rem;
      margin: 0 15px;
    }
  }
`;
export const StyledLink = styled(Link) `
  margin-bottom: 0.5rem;
  @media (max-width: ${MOBILE}) {
    height: 0;
    margin: 0;
    color: white;
    position: relative;
    font-size: 0.825rem !important;
  }
  small {
    font-size: 11px;
  }
`;
export const StyledIcon = styled(Icon) `
  color: ${({ theme }) => theme.palette.secondary} @media (max-width: ${MOBILE}) {
    color: white !important;
  }
`;
export const RowCenterOnDesktop = styled(Row) `
  @media (min-width: 768px) {
    text-align: center;
  }
`;
export const StyledBox = styled(Box) `
  border-radius: 4px;
  max-width: 650px;
  position: relative;
  padding: 35px;
  @media (min-width: 992px ) {
    min-width: 850px;
    padding: 65px;
  }

`;
//TODO -- fix up banner so other later components dont need to offset their margins
export const StyledPageContainer = styled('div') `
  margin-top: -8rem;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    margin-top: -10rem;
  }
`;
export const HelpHeading = styled(Heading) `
  margin-bottom: 3rem;
  margin-top: -3rem;
  @media only screen and (max-width: 767px) {
    margin-top: -3rem
    margin-bottom: 0;
  }
`;
// TODO: this is the final banner - auto fills to top and sides on mobile
// TODO: try to get it to auto apply margin on next sibling 
// TODO: doesn't fill 100% at all screens with this CSS - large mobile doesn't look ideal
export const HeadingBanner = styled('div') `
{
  background-image: linear-gradient(to right, rgba(173, 17, 43, 1) 65%, rgba(242, 0, 29, 1) 100%);
  height: 16.8rem;
  display: flex;
  flex: 1;
  @media only screen and (max-width: 767px) {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
    margin-top: -1rem !important;
  }
}
`;
