import React, { createContext, useEffect, useState } from 'react';
import { getCorrespondencePreference, getRiaConfig } from '../api/RiaApi';
export const RiaConfigContext = createContext({
    riaConfig: undefined,
    riaConfigLoading: false,
    riaConfigError: false,
    setRiaConfig: () => { },
    correspondenceType: undefined,
    setCorrespondenceType: () => { },
});
/**
 *  Provides RiaConfigProvider - customer configuration data loaded from ria api
 */
export const RiaConfigProvider = ({ children }) => {
    const [riaConfig, setRiaConfig] = useState();
    const [correspondenceType, setCorrespondenceType] = useState();
    const { data: riaConfigData, loading: riaConfigLoading, error: riaConfigError } = getRiaConfig();
    const { data: corrsInfo } = getCorrespondencePreference();
    useEffect(() => {
        setCorrespondenceType(corrsInfo?.empLveCrrsType);
        setRiaConfig(riaConfigData);
        const firstPolicyItem = riaConfigData?.reportingPolicyItems[0];
        const isReportingPolicyEmpty = riaConfigData && !riaConfigData.customizeReportingPolicy && firstPolicyItem && (firstPolicyItem.numberOfEditingDaysFuture === null
            || firstPolicyItem.numberOfEditingDaysPast === null
            || firstPolicyItem.numberOfReportingDaysFuture === null
            || firstPolicyItem.numberOfReportingDaysPast === null);
        if (riaConfigError || isReportingPolicyEmpty) {
            throw new Error();
        }
    }, [riaConfigData, corrsInfo, riaConfigError]);
    const riaConfigContext = {
        riaConfig,
        riaConfigLoading,
        riaConfigError,
        setRiaConfig,
        correspondenceType,
        setCorrespondenceType,
    };
    return (<RiaConfigContext.Provider value={riaConfigContext}>
      {children}
    </RiaConfigContext.Provider>);
};
