import { Alert, Heading, Loader } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceUnavailable from '../../../components/ServiceUnavailable/ServiceUnavailable';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import api from '../api';
import FindMyInfo from '../components/FindMyInfo';
import RabHome from '../components/RabHome';
const RabContainer = () => {
    const { t: translation } = useTranslation('rabTranslation');
    const { language } = useExperience();
    const { authHeader } = useAuthentication();
    const [claimNumber, setClaimNumber] = useState('');
    const [error, setError] = useState();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    useEffect(() => setIsMessagingDisabled(false), []);
    let errorFC;
    const [rabCustomerConfData, setRabCustomerConfData] = useState({
        displayBondingQuestion: false,
        customConfirmationText: '',
        vaginalDeliveryWeeks: 0,
        cesareanDeliveryWeeks: 0,
    });
    useEffect(() => {
        setIsPageLoading(true);
        api
            .getActiveMaternityClaimNum(authHeader())
            .then((response) => {
            setClaimNumber(response.data);
            setIsPageLoading(false);
        })
            .catch((e) => {
            setError(e);
            setIsPageLoading(false);
        });
    }, [claimNumber]);
    useEffect(() => {
        // TODO this service call should be replaced with newer service endpoint which only returns data from config service
        setIsPageLoading(true);
        api
            .getLanguageFile(authHeader(), language)
            .then((response) => {
            setRabCustomerConfData(response.data);
            setIsPageLoading(false);
        })
            .catch((e) => {
            setError(e);
            setIsPageLoading(false);
        });
    }, [language]);
    if (error) {
        if (error.response.status === 404) {
            errorFC = (
            // @ts-ignore
            <Alert data-testid='no-maternity-claim' id='error' type='error' data-public={true}>
          <strong>Error: </strong>
          {translation('noMaternityClaimText')}
        </Alert>);
        }
        else if (error.response.status === 412) {
            return <FindMyInfo />;
            // TODO Replace with React Routing??
        }
        else if (error.response.status === 403 || error.response.status === 401) {
            errorFC = (
            // @ts-ignore
            <Alert data-testid='unauthorized-error' id='error' type='error' data-public={true}>
          <strong>Error: </strong>
          {translation('unauthorizedErrorHeader')}
          <br />
          {translation('unauthorizedErrorText')}
        </Alert>);
        }
        else if (error.response.status === 503 || error.response.status === 500) {
            errorFC = <ServiceUnavailable />;
        }
        else {
            errorFC = (
            // @ts-ignore
            <Alert data-testid='generic-error' id='error' type='error' data-public={true}>
          <strong>Error: </strong>
          {translation('genericError')}
        </Alert>);
        }
    }
    return (<>
      <Loader id='rab-container-spinner' isShown={isPageLoading}>
        <Heading data-public={true} className='pb-2 pl-0' style={{ paddingBottom: '.5rem', paddingLeft: 0 }} data-testid='reportABirthHeaderText'>
          {translation('reportABirthHeaderText').toString()}
        </Heading>
        {errorFC}
        {claimNumber && <RabHome claimNumber={claimNumber} rabCustomerConfData={rabCustomerConfData}/>}
      </Loader>
    </>);
};
export default RabContainer;
