import { Checkbox, Col, Heading, Row, SelectInput, TextInput } from '@digitools/honest-react';
import React, { useState } from 'react';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import { CLI_EMPLOYEE_WORK_STATUS } from 'src/packages/cli/constants/CliConstants';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { rangedNumberValidation } from 'src/packages/cli/utils/validationUtils';
const WorkSchedule = () => {
    const { additionalDetails } = useCliDataContext();
    const [daysOfTheWeek, setDaysOfTheWeek] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    return (<>
            <BorderLessGrayBox>
                <Heading elemType='h3' elemStyle='h4'>Work schedule (optional) </Heading><small style={{ marginTop: 0 }}>(at last time worked) </small>
            </BorderLessGrayBox>
            <Row style={{ marginBottom: '2.5rem' }}>
                <Col md={5} xs={12}>
                    <TextInput data-testid='daysperweek' field={'daysperweek'} label={'Days per week'} placeholder={'Days per week'} ariaLabelText={'Days per week'} validateOnBlur={true} validateOnChange={true} validate={(value) => rangedNumberValidation(value, 0, 7)} initialValue={additionalDetails && additionalDetails.daysperweek}/>
                    <TextInput data-testid='hoursperday' field={'hoursperday'} label={'Hours per day'} placeholder={'Hours per day'} ariaLabelText={'Hours per day'} validateOnBlur={true} validateOnChange={true} validate={(value) => rangedNumberValidation(value, 0, 24)} initialValue={additionalDetails && additionalDetails.hoursperday}/>
                    <SelectInput data-testid='workstatus' ariaLabelText={'Work status'} field={'workstatus'} label={'Work status'} placeholderText={'Work status'} options={CLI_EMPLOYEE_WORK_STATUS} initialValue={additionalDetails && additionalDetails.workstatus}/>
                </Col>
                <Col md={6} xs={12}>
                    <strong className={`d-block mb-2`}>Scheduled work days</strong>
                    {Object.keys(daysOfTheWeek).map((key, i) => {
            const capitalizedLabel = key.charAt(0).toUpperCase() + key.slice(1);
            return (<Checkbox key={key} data-testid={key} field={key} label={capitalizedLabel} name={key} initialValue={additionalDetails && additionalDetails[key]} onChange={(e) => {
                    setDaysOfTheWeek({ ...daysOfTheWeek, [e.target.name]: e.target.checked });
                }}/>);
        })}
                </Col>
            </Row>
        </>);
};
export default WorkSchedule;
