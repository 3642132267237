import React from 'react';
import useExperience from '../../hooks/useExperience';
import useTranslate from '../../hooks/useTranslate';
import { Experience } from '../../types/Experience';
import ErrorPage from './ErrorPage';
const cliErrorMessage = {
    english: 'Something went wrong. Please reload the page and try again. If the problem persists, please contact technical support.',
    spanish: 'Algo salió mal. Vuelva a cargar la página y vuelva a intentarlo. Si el problema persiste, comuníquese con el soporte técnico.',
};
const homeLinkTitle = {
    english: 'Go to homepage',
    spanish: 'Ir a la página de inicio.',
};
const GenericErrorPage = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const homeLink = experience === Experience.EMPLOYEE ? 'employer/home' : 'employee/home';
    return (<ErrorPage data-testid="generic-error-page" message={t(cliErrorMessage)} link={homeLink} linkTitle={t(homeLinkTitle)} theme={''}/>);
};
export default GenericErrorPage;
