const formatDateMMDDYYY = date => {
  if (!date) {
    return date;
  }
  const formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  if (formattedDate === '12/31/1969' || formattedDate === '01/01/1900') {
    return '';
  }
  // TODO figure out what causes this
  if (formattedDate === 'NaN/NaN/NaN') {
    return '-';
  }
  return formattedDate;
};

export default formatDateMMDDYYY;
