import { userNameInvalidMessageMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidMigrateUsername } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidMigrateUsername(isRequired = false) {
    const errorMessageMap = [userNameInvalidMessageMap];
    const validators = [isValidMigrateUsername];
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({
            [Language.ENGLISH]: 'New Username',
            [Language.SPANISH]: 'Nuevo nombre de usuario',
        }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidMigrateUsername;
