import React from 'react';
import { Col, DatePickerV2, Label, Row, themes } from '@digitools/honest-react';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import { isBefore, parse } from 'date-fns';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { useFormState } from 'informed';
import { validateDateIsWithinAYear } from 'src/packages/cli/utils/validationUtils';
const ReleaseDate = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { experience, language } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const labelText = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.releaseDateEE) : t(formLabels.releaseDateER));
    // FIXME: can't allow same day temporarily (needing fix for backend)
    const validateReleaseDate = (input) => {
        if (input && values?.absenceData?.admissionDate) {
            if (isBefore(parse(input, 'P', new Date()), parse(values.absenceData.admissionDate, 'P', new Date()))) {
                return t(formLabels.releaseDateError);
            }
        }
        return validateDateIsWithinAYear(input, t, false);
    };
    return (<>
      <Row className='mb-3'>
        <Col size={12}>
          <Label className='mb-2'>{labelText}</Label>
        </Col>
        <Col size={12} sm={6} xl={4}>
          <DatePickerV2 data-testid='releaseDate' theme={themes.lincoln} field={'releaseDate'} lang={language} label={t(formLabels.releaseDateShortLabel)} placeholder={'mm/dd/yyyy'} ariaLabelText={labelText} mask={dateMask} validateOnBlur={true} validate={validateReleaseDate} initialValue={absenceData && absenceData.releaseDate}/>
        </Col>
      </Row>
    </>);
};
export default ReleaseDate;
