import React from 'react';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { Heading } from '@digitools/honest-react/dist/lib/components/Heading';
import { EOIHelpText } from '../i18n/EoiText';
import HelpIcon from '../../../assets/help_icon.svg';
import useTranslate from '../../../hooks/useTranslate';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
const NeedHelpBox = ({ configData, page }) => {
    const { t } = useTranslate();
    const showHRDetails = (configData && (configData.hrPhone || configData.hrEmail));
    const { trackClickWithPage } = useGoogleAnalytics();
    return (<Box data-testid='need-help' style={{ textAlign: 'center' }} data-public={true}>
      <HelpIcon width='4rem' alt='Document with magnifying glass' style={{ marginTop: '2.1875rem' }}/>
      <Heading color={'black'} theme={themes.lincoln} style={{ marginTop: '.25rem', }} elemType={'h3'}>
        {t(EOIHelpText.helpHeader)}
      </Heading>
      <p>{t(EOIHelpText.helpText)}</p>
      {showHRDetails && (<>
          {configData.hrContact && (<Heading elemStyle={'h4'} color={'black'}>{t(configData.hrContact)}</Heading>)}
          {configData.hrPhone && (<>
            <span>
              {t(EOIHelpText.phoneText)}: <a href={`tel:${configData.hrPhone}`} onClick={() => trackClickWithPage('needHelpPhone', page)}>
                {configData.hrPhone}
                </a>
            </span>
            <br />
            </>)}
          {configData.hrEmail && (<span style={{ wordBreak: 'break-all' }}>
              {t(EOIHelpText.emailText)}: <a href={`mailto:${configData.hrEmail}`} onClick={() => trackClickWithPage('needHelpEmail', page)}>{configData.hrEmail}</a>
            </span>)}
        </>)}
    </Box>);
};
export default NeedHelpBox;
