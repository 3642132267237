export const AddRequestText = {
    sectionTitleEE: {
        english: 'Accomodation Request',
        spanish: 'Solicitud de alojamiento'
    },
    accommodationTypeEE: {
        english: 'What type of accommodation do you need?',
        spanish: '¿Qué tipo de alojamiento necesitas?',
    },
    timeAwayRequestEE: {
        english: 'Be certain to specify if you are requesting any time away from work as an accommodation.',
        spanish: 'Asegúrese de especificar si está solicitando algún tiempo fuera del trabajo como adaptación.',
    },
    isPermanentRequestEE: {
        english: 'Is this request expected to be temporary or permanent?',
        spanish: '¿Se espera que esta solicitud sea temporal o permanente?',
    },
    startDateEE: {
        english: 'What is the desired start date for the accommodation?',
        spanish: '¿Cuál es la fecha de inicio deseada para el alojamiento?',
    },
    alreadyReportedEE: {
        english: 'Does this request relate to a condition that has already been reported?',
        spanish: '¿Esta solicitud se relaciona con una condición que ya ha sido reportada?',
    },
    backToWorkEE: {
        english: 'Will you be returning to work after being out on leave, or will you be staying at work?',
        spanish: '¿Regresará al trabajo después de estar de baja o se quedará en el trabajo?',
    },
    addrequestBtnEE: {
        english: 'Add additional request',
        spanish: 'Agregar solicitud adicional',
    },
    removeRequestBtnEE: {
        english: 'Remove last request',
        spanish: 'Eliminar última solicitud',
    },
    yesTextEE: {
        english: 'Yes',
        spanish: 'Sí',
    },
    noTextEE: {
        english: 'No',
        spanish: 'No',
    },
    temporaryTextEE: {
        english: 'Temporary',
        spanish: 'Temporaria',
    },
    permanentTextEE: {
        english: 'Permanent',
        spanish: 'Permanente',
    },
    backToWorkTextEE: {
        english: 'Returning to work',
        spanish: 'volviendo al trabajo',
    },
    stayingAtWorkTextEE: {
        english: 'Continue to stay at work',
        spanish: 'Continuar en el trabajo',
    },
    selectAccommodation: {
        english: 'Select an accommodation',
        spanish: 'Seleccione un alojamiento',
    },
    textAreaErrorMsgEE: {
        english: 'The entered value is not long enough',
        spanish: 'El valor ingresado no es lo suficientemente largo'
    },
    textAreaHelperTextEE: {
        english: 'Type at least 5 characters',
        spanish: 'Escriba al menos 5 caracteres'
    },
    dateFormatEE: {
        english: 'Date (mm/dd/yyyy)',
        spanish: 'Fecha (mm/dd/aaaa)',
    },
    datePickerLabelEE: {
        english: 'Date Picker label',
        spanish: 'Etiqueta del selector de fecha',
    },
    anticipatedEndDate: {
        english: 'On what date do you anticipate this accommodation will no longer be needed?',
        spanish: '¿En qué fecha prevé usted que esta adaptación ya no se necesitará más?',
    },
    requestReceivedDate: {
        english: 'On what date was this specific accommodation request submitted by the Employee?',
        spanish: '¿En qué fecha envió el empleado esta solicitud específica de adaptación?',
    },
    status: {
        english: 'What is the status of this accommodation request?',
        spanish: '¿Cuál es el estado de esta solicitud de adaptación?',
    },
    closedDate: {
        english: 'On what date was this accommodation request closed?',
        spanish: '¿En qué fecha se cerró esta solicitud de adaptación?',
    },
    statusReasonCode: {
        english: 'What best describes the resolution of this request?',
        spanish: '¿Cuál es la mejor descripción de la resolución de esta solicitud?',
    },
    assistRequired: {
        english: 'Would you like assistance from Lincoln Financial Group with this request?',
        spanish: '¿Quiere ayuda de Lincoln Financial Group para esta solicitud?',
    },
    providedAccomodationTypeCode: {
        english: 'What type of accommodation was approved?',
        spanish: '¿Qué tipo de adaptación se aprobó?',
    },
    providedDetailsText: {
        english: 'Please provide additional information below about the approved accommodation:',
        spanish: 'Dé más información abajo sobre la adaptación aprobada:',
    },
    actualBeginDate: {
        english: 'On what date has this accommodation request been approved to begin?',
        spanish: '¿Cuál es la fecha aprobada de inicio de esta adaptación?',
    },
    restrictonOrLimitation: {
        english: 'What restrictions and/or limitations have been confirmed by a medical provider?',
        spanish: '¿Qué restricciones o limitaciones ha confirmado un proveedor médico?',
    },
    interactiveBeginDate: {
        english: 'What was the first date of interaction with the Employee about this accommodation request?',
        spanish: '¿Cuál fue la primera fecha de comunicación con el empleado por esta solicitud de adaptación?',
    },
    initialMeetingDate: {
        english: 'What was/will be the date of the first meeting held with the Employee regarding this accommodation?',
        spanish: '¿Cuál fue/será la fecha de la primera reunión con el empleado sobre esta adaptación?',
    },
    determinationInfoReceivedDate: {
        english: 'On what date was all of the information received from the Employee that was necessary to make a determination regarding this request?',
        spanish: '¿En qué fecha se recibió toda la información del empleado que fue necesaria para tomar una decisión sobre esta solicitud?',
    },
    determinationDate: {
        english: 'On what date was the determination regarding this request made?',
        spanish: '¿En qué fecha se tomó la decisión sobre esta solicitud?',
    },
    priorCarrier: {
        english: 'Is this request currently being tracked elsewhere, either by a prior carrier or internally within your company?',
        spanish: '¿Se está haciendo actualmente un seguimiento de esta solicitud en otro lugar, sea por parte de la compañía anterior o de manera interna en su compañía?',
    },
    requestedAccommCost: {
        english: 'What is the anticipated cost of this accommodation?',
        spanish: '¿Cuál es el costo previsto de esta adaptación?',
    },
    providedCostAmount: {
        english: 'What was the actual cost of this accommodation?',
        spanish: '¿Cuál fue el costo real de esta adaptación?',
    },
    suppRestrictionsAndLimitations: {
        english: 'If applicable, please provide additional information about the confirmed restrictions and/or limitations below:',
        spanish: 'Si corresponde, dé más información abajo sobre las restricciones o limitaciones confirmadas:',
    },
    requestedAccommType: {
        english: 'What type of accommodation was requested?',
        spanish: '¿Qué tipo de alojamiento se solicitó?'
    },
    actualPeriodType: {
        english: 'Is this approved accommodation temporary or permanent?',
        spanish: '¿Es esta adaptación aprobada temporal o permanente?'
    },
    actualEndDate: {
        english: 'On what date was this accommodation removed?',
        spanish: '¿En qué fecha se eliminó esta adaptación?'
    },
    rtwWithoutAccomodationDate: {
        english: `What was the Employee's first working day after the accommodation ended?`,
        spanish: `¿Cuál fue el primer día de trabajo del Empleado después de que finalizó el alojamiento?`
    }
};
