import { Icon, ToggleSwitch } from "@digitools/honest-react";
import React from 'react';
import { TEALIUM_EVENT } from "src/constants/tealiumConstants";
import useTealium from "src/hooks/useTealium";
import useStatusDataContext from "src/packages/status/hooks/useStatusDataContext";
const LegacyPreference = ({}) => {
    const { legacyPref, setLegacyPref } = useStatusDataContext();
    const { trackEvent } = useTealium();
    const toggleState = (legacyPref ? 'off' : 'on');
    const changePref = (curPref) => {
        // set legacy pref session token so the context will re-init with the session's choice
        sessionStorage.setItem('coverageDetailsPref', !curPref ? '' : 'legacy');
        setLegacyPref(!legacyPref);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TOGGLE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: `return to legacy view - ${toggleState}`,
        });
    };
    return (<>
      <div className='mt-0 mb-3' style={{ display: 'flex', alignItems: 'center' }}>
          <Icon size='medium' name='important-message' color='secondaryDark'/>
          <span className='ml-2 mr-2'>
            <em><strong>New experience!</strong> {legacyPref ? ' Toggle on to return to the new experience' : ' Toggle off to return to the legacy view.'}</em>
          </span>
          <ToggleSwitch label={'Toggle legacy experience'} field={'toggle'} toggleSize={'medium'} initialValue={!legacyPref} onChange={() => changePref(!legacyPref)}/>
      </div>
    </>);
};
export default LegacyPreference;
