import React, { useState } from 'react';
import FindMyInformation from './FindMyInformation';
import BdmContainer from './BdmContainer';
import useAuthentication from '../../../hooks/useAuthentication';
import IsLciContext from '../../../providers/IsLciProvider';
import BdmLciConfigProvider from '../../../providers/BdmLciConfigProvider';
const BdmEligibilityContainer = () => {
    const { user } = useAuthentication();
    let bdmVerified = false;
    let ssoUser = false;
    if (user) {
        bdmVerified = user.bdmVerified;
        ssoUser = user.ssoUser;
    }
    const [employeeId, setEmployeeId] = useState('');
    const [employeeSsn, setEmployeeSsn] = useState('');
    const [employeeDob, setEmployeeDob] = useState('');
    const [employeeZip, setEmployeeZip] = useState('');
    const [isVerified, setIsVerified] = useState(bdmVerified || ssoUser);
    return (<BdmLciConfigProvider isLciConfigRequired={false}>
      <IsLciContext.Provider value={false}>
        {isVerified ? <BdmContainer employeeId={employeeId} ssn={employeeSsn} dob={employeeDob} zip={employeeZip} data-public={true}/> :
            <FindMyInformation setVerification={setIsVerified} setEmpSsn={setEmployeeSsn} setEmpId={setEmployeeId} data-public={true} setEmpDob={setEmployeeDob} setEmpZip={setEmployeeZip}/>}
      </IsLciContext.Provider>
    </BdmLciConfigProvider>);
};
export default BdmEligibilityContainer;
