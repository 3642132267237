import React, { createContext, useEffect, useState } from 'react';
import useExperience from '../../../hooks/useExperience';
import { Experience, ExperienceCode } from '../../../types/Experience';
import { getAsCustomerConfig } from '../api/ApiCalls';
export const AsConfigContext = createContext({
    asConfig: undefined,
    asConfigLoading: undefined,
});
/**
 *  Provides AsConfigProvider - customer configuration data loaded from as api
 */
export const AsConfigProvider = ({ children }) => {
    const [asConfig, setAsConfig] = useState();
    const { experience, language } = useExperience();
    const experienceCode = experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER;
    const { loading: configLoading, data: asConfigData, error: configError } = getAsCustomerConfig(experienceCode);
    useEffect(() => {
        setAsConfig(asConfigData);
    }, [asConfigData]);
    const asConfigContext = {
        asConfig,
        asConfigLoading: configLoading,
    };
    return <AsConfigContext.Provider value={asConfigContext}>{children}</AsConfigContext.Provider>;
};
