import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ClaimDetails, CoverageDetails, EmployeeSearch, EOI, FindMyInfo, LeaveDetails, NotFound, Unauthorized, } from 'status/components/pages';
import EmployeeRedirect from './EmployeeRedirect';
import EmployerRedirect from './EmployerRedirect';
import AccommodationDetails from '../components/pages/AccommodationDetails/AccommodationDetails';
const FixedStatusRoutes = () => {
    return (<Routes>
      <Route path={`employee/findmyinfo`} element={<FindMyInfo />}/>
      <Route path={`employee/eoi`} element={<EOI />}/>
      <Route path={`employer/search`} element={<EmployeeSearch />}/>
      <Route path={`employee/*`} element={<EmployeeRedirect />}/>
      <Route path={`/`} element={<EmployerRedirect />}/>
      <Route path={`employer/:empNum/coveragedetail`} element={<CoverageDetails />}/>
      <Route path={`employee/:empNum/coveragedetail`} element={<CoverageDetails />}/>
      <Route path={`employer/:empNum/claims/:claimNum/details`} element={<ClaimDetails />}/>
      <Route path={`employee/:empNum/claims/:claimNum/details`} element={<ClaimDetails />}/>
      <Route path={`employer/:empNum/leaves/:leaveId/details`} element={<LeaveDetails />}/>
      <Route path={`employee/:empNum/leaves/:leaveId/details`} element={<LeaveDetails />}/>
      <Route path={`employer/:empNum/accommodations/:eventId/details`} element={<AccommodationDetails />}/>
      <Route path={`employee/:empNum/accommodations/:eventId/details`} element={<AccommodationDetails />}/>
      <Route path={`unauthorized`} element={<Unauthorized />}/>
      <Route path={'*'} element={<NotFound />}/>
    </Routes>);
};
export default FixedStatusRoutes;
