import axios from 'axios';
//import config from 'config';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
function requestCustomers(searchString) {
  return {
    type: GET_CUSTOMERS,
    searchString,
  };
}

export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export function receiveCustomers(customers) {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    customers,
  };
}

export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';
function errorCustomers(error) {
  return {
    type: GET_CUSTOMERS_ERROR,
    error,
  };
}

export const CANCEL_CUSTOMERS_LOADING = 'CANCEL_CUSTOMERS_LOADING';
export function cancelCustomersLoading() {
  return {
    type: CANCEL_CUSTOMERS_LOADING,
  };
}

export const GET_LCI_CONFIG_SUCCESS = 'GET_LCI_CONFIG_SUCCESS';
export const GET_LCI_CONFIG_FAILURE = 'GET_LCI_CONFIG_FAILURE';

export const LCI_LOADING = 'LCI_LOADING';
export const LCI_ERROR = 'LCI_ERROR';

export const GET_BDM_CONFIG_SUCCESS = 'GET_BDM_CONFIG_SUCCESS';
export const GET_BDM_CONFIG_FAILURE = 'GET_BDM_CONFIG_FAILURE';

export const BDM_LOADING = 'BDM_LOADING';
export const BDM_ERROR = 'BDM_ERROR';

//asyncronous action creator handling dispatch of all actions/follow ups needed to fetch customers
export function fetchCustomers(searchString) {
  return async dispatch => {
    dispatch(requestCustomers(searchString));
    try {
      const res = await axios.get(`mlp-ui-service/services/v1/customers/search/${searchString}`);
      // console.log('done awaiting request');
      dispatch(receiveCustomers(res.data));
    } catch (err) {
      // console.log(err.message);
      // console.log(err.response);
      dispatch(errorCustomers(err));
    }
  };
}

export function fetchLciConfig(authHeader) {
  return async dispatch => {
    const url = `/lci-service/services/v1/customer/configuration`;
    try {
      dispatch({ type: LCI_LOADING });
      const { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader,
        },
      });
      dispatch({ type: GET_LCI_CONFIG_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: LCI_ERROR });
    }
  };
}

export function fetchBdmConfig(authHeader) {
  return async dispatch => {
    const url = `/bdm-service/services/v1/customer/configuration`;
    try {
      dispatch({ type: BDM_LOADING });
      const { data } = await axios.get(url, {
        headers: {
          Authorization: authHeader,
        },
      });
      dispatch({ type: GET_BDM_CONFIG_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: BDM_ERROR });
    }
  };
}

// uses axios cancelToken to allow new searches to cancel pending ones
export function fetchCustomersWithCancel(searchString, cancelSource, authHeader) {
  return async dispatch => {
    dispatch(requestCustomers(searchString));
    try {
      const response = await axios.get(`/mlp-ui-service/services/v1/customers/search/${searchString}`, {
        cancelToken: cancelSource.token,
        headers: {
          Authorization: authHeader,
        },
      });
      // console.log('done awaiting request');
      // await axios.get('somenewTestThing');
      dispatch(receiveCustomers(response.data));
    } catch (err) {
      if (axios.isCancel(err)) {
        // console.log('Request Cancelled', err.message);
      } else {
        // console.log(err.message);
        // console.log(err.response);
        dispatch(errorCustomers(err));
      }
    }
  };
}
