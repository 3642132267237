import { Box, Heading, Icon, TabContainer, Table, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const PlanRows = (props) => {
    return (<>
      {props.plans.map((plan) => (<Table className={'border-0'}>
          {props.isInNetwork ? (<TableRow theme={themes.lincoln} className={'border-bottom'}>
              <TableData className={'border-0'}>
                <Heading elemType={'h4'}>{plan.coverageType?.toUpperCase()}</Heading>
              </TableData>
              <TableData className={'border-0 text-right font-weight-light'}>
                {plan.inNetwork.networkDeductible}
              </TableData>
            </TableRow>) : (<TableRow theme={themes.lincoln} className={'border-bottom'}>
              <TableData className={'border-0'}>
                <Heading elemType={'h4'} className={'text-capitalize'}>
                  {plan.coverageType?.toUpperCase()}
                </Heading>
              </TableData>
              <TableData className={'border-0 text-right font-weight-light'}>
                {plan.outNetwork.networkDeductible}
              </TableData>
            </TableRow>)}
        </Table>))}
    </>);
};
const PlanDeductibles = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    let memberEvent = 'subscriber';
    if (currentMember && subscriber?.dependents?.indexOf(currentMember) !== -1) {
        memberEvent = 'member' + (subscriber?.dependents?.indexOf(currentMember) + 1);
    }
    const trackDropdownEvent = (tab) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_name: tab,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            event_version: memberEvent,
        });
    };
    return (<>
      {currentMember && (<>
          {currentMember.deductiblesAndCoinsurance && (<Box theme={themes.lincoln}>
              <Table>
                <TableRow className={'border-0'} isHoverable={false}>
                  <TableHeaderCol className={'border-0 mr-0 mb-2'}>
                    <Heading data-testid='deductible-type' elemType={'h2'}>
                      {currentMember.deductiblesAndCoinsurance.deductibleType} deductibles
                      <Icon className={'ml-3 align-middle'} size='xs' name='info' color={'secondary'} title={'deductibles info'}/>
                    </Heading>
                  </TableHeaderCol>
                </TableRow>
                <TableRow className={'border-bottom'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.deductibleAmountPaidText)}</Heading>
                  </TableData>
                  <TableData className={'border-0 text-right font-weight-light align-bottom'}>
                    {' '}
                    ${currentMember.deductiblesAndCoinsurance.amountApplied}
                  </TableData>
                </TableRow>
              </Table>
              <TabContainer>
                {currentMember.deductiblesAndCoinsurance.inNetworkLabel.replace(/\s/g, '').length && (<TabSection onClick={() => {
                        trackDropdownEvent(currentMember?.deductiblesAndCoinsurance?.inNetworkLabel +
                            ' ' +
                            currentMember?.deductiblesAndCoinsurance?.deductibleType +
                            ' ' +
                            t(DentalText.deductiblesText));
                    }} name={currentMember.deductiblesAndCoinsurance.inNetworkLabel}>
                    <PlanRows plans={currentMember.deductiblesAndCoinsurance.planDeductibles} isInNetwork={true}/>
                  </TabSection>)}
                {currentMember.deductiblesAndCoinsurance.outNetworkLabel.replace(/\s/g, '').length && (<TabSection onClick={() => {
                        trackDropdownEvent(currentMember?.deductiblesAndCoinsurance?.outNetworkLabel +
                            ' ' +
                            currentMember?.deductiblesAndCoinsurance?.deductibleType +
                            ' ' +
                            t(DentalText.deductiblesText));
                    }} name={currentMember.deductiblesAndCoinsurance.outNetworkLabel}>
                    <PlanRows plans={currentMember.deductiblesAndCoinsurance.planDeductibles} isInNetwork={false}/>
                  </TabSection>)}
              </TabContainer>
            </Box>)}
        </>)}
    </>);
};
export default PlanDeductibles;
