import React from 'react';
import { Form } from 'informed';
import { Button, Heading, themes } from '@digitools/honest-react';
import useCliContext from '../../hooks/useCliContext';
import { format, parseJSON } from 'date-fns';
import useTranslate from '../../../../hooks/useTranslate';
import { CliSaveForLaterText } from '../../i18n/CliSaveForLaterText';
import styled from 'styled-components';
const StyledDiv = styled('div') `
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 765px) {
    flex-direction: column;
  }
`;
const LoadSavedCli = ({ setShowVerification }) => {
    const { t } = useTranslate();
    const { saveForLaterEntries } = useCliContext();
    const dateTime = (saveForLaterEntries && saveForLaterEntries?.updatedDateAndTime)?.concat('Z') ||
        (saveForLaterEntries && saveForLaterEntries?.savedDateTime)?.concat('Z');
    const parsedDate = parseJSON(dateTime);
    const date = format(parsedDate, 'MM/dd/yyyy');
    const time = format(parsedDate, 'h:mm a');
    // prepop endpoint has not been called yet so IInterviewAnswers will be uninitialized/empty.
    const handleOnSubmit = () => {
        setShowVerification(true);
    };
    return (<Form onSubmit={handleOnSubmit} data-testid='loadSavedCli' data-public={true} style={{ marginTop: '3rem' }}>
      <>
        <Heading elemType={'h3'} elemStyle={'h4'} style={{ marginTop: '.5rem' }}>
          {t(CliSaveForLaterText.loadSavedCliHeader)}
        </Heading>
        <StyledDiv style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}` }}>
          <Heading elemType={'h3'} elemStyle={'h3'}>
            {t(CliSaveForLaterText.loadSavedCliBody)}
            <br />
            {`${date} ${t(CliSaveForLaterText.at)} ${time}`}
          </Heading>
          <Button data-testid='view-application' theme={themes.lincoln} buttonType='primary' type='submit'>
            {t(CliSaveForLaterText.cliViewApplication)}
          </Button>
        </StyledDiv>
        <hr />
      </>
    </Form>);
};
export default LoadSavedCli;
