import React from 'react';
import { PortalStatusContext } from '../providers/PortalStatusProvider';
import { getDisplayName } from '../utils/reactUtils';
function withPortalStatus(WrappedComponent) {
    class WithPortalStatus extends React.Component {
        render() {
            return (<PortalStatusContext.Consumer>
          {contexts => <WrappedComponent {...this.props} portalStatusContext={contexts}/>}
        </PortalStatusContext.Consumer>);
        }
    }
    // @ts-ignore
    WithPortalStatus.displayName = `WithPortalStatus(${getDisplayName(WrappedComponent)})`;
    return WithPortalStatus;
}
export default withPortalStatus;
