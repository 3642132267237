import { TabSection, Collapse, themes, Icon, Col, Row } from '@digitools/honest-react';
import React, { useState } from 'react';
import { EMPLOYEE_FAQ } from '../constants/EmployeeFAQ';
import { LearnMoreText } from '../constants/LearnMoreText';
import useTranslate from 'src/hooks/useTranslate';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { StyledLCNText } from 'src/components/styledComponents';
const EmployeeTab = () => {
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const [expandedState, setExpandedState] = useState(Array(EMPLOYEE_FAQ.length).fill(false));
    const handleOnBlur = (index, questionString) => {
        const tempArray = expandedState;
        if (expandedState[index] === false) {
            trackLearnMoreEvent(questionString);
            tempArray[index] = true;
            setExpandedState(tempArray);
        }
        else if (expandedState[index]) {
            tempArray[index] = false;
            setExpandedState(tempArray);
        }
    };
    const trackLearnMoreEvent = (eventName) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: TEALIUM_EVENT.EVENT_VERSION.FAQ,
            event_status: TEALIUM_EVENT.EVENT_STATUS.EXPAND
        });
    };
    const ExpandedIcon = () => (<Icon name="subtract" size="small" className="mr-2 mt-1" color='primary'/>);
    const CollapsedIcon = () => (<Icon name="add" size="small" className="mr-2 mt-1" color='primary'/>);
    return (<>
            <TabSection name={t(LearnMoreText.employeeQuestions)} key={1}>
                    {EMPLOYEE_FAQ.map((questions, index) => {
            return (<Collapse isOpen={false} titleComponent={() => (t(questions.label))} theme={themes.lincoln} type='box' collapsedIcon={CollapsedIcon} expandedIcon={ExpandedIcon} color='white' textColor='black' onBlur={() => handleOnBlur(index, "ee question, " + questions.label.english)} headerElemType='h2'>
                                {t(questions.value)}
                            </Collapse>);
        })}
                    <Row>
                        <Col>
                            <StyledLCNText>
                                {t(LearnMoreText.LCNEmployee)}
                            </StyledLCNText>
                        </Col>
                    </Row>
            </TabSection>
            
        </>);
};
export default EmployeeTab;
