import { Alert } from '@digitools/honest-react';
import React, { useEffect, useState, useMemo } from 'react';
import { Language } from '../../types/Language';
import axios from 'axios';
import useExperience from '../../hooks/useExperience';
import { v4 as uuidv4 } from 'uuid';
import Link from '@digitools/honest-react/dist/lib/components/Link';
export var FunctionalArea;
(function (FunctionalArea) {
    FunctionalArea["LOGIN"] = "LOGIN";
    FunctionalArea["EMPLOYEE_LANDING"] = "EMPLOYEE_LANDING";
    FunctionalArea["EMPLOYER_LANDING"] = "EMPLOYER_LANDING";
    FunctionalArea["USAA_MOBILE"] = "USAA_MOBILE";
    FunctionalArea["MULTI_CUSTOMER_LANDING"] = "MULTI_CUSTOMER_LANDING";
    FunctionalArea["COVERAGE_DETAILS"] = "COVERAGE_DETAILS";
    FunctionalArea["CLI"] = "CLI";
    FunctionalArea["RIA"] = "RIA";
    FunctionalArea["AS"] = "AS";
    FunctionalArea["EOI"] = "EOI";
})(FunctionalArea || (FunctionalArea = {}));
/**
 * Paired with functionality on the notification admin-ui to parse messages with embedded links
 * to format them into an honest link correctly. Non fully qualified links will route keeping
 * the base url (to link within the app)
 *
 * Format: <http://www.test.com[Click Here!]>
 *
 * @param rawMessage
 */
const parseMessageForLinks = (rawMessage) => {
    const regex = /<(.*?)\[.*?\]>/;
    if (rawMessage.search(regex) < 0) {
        return rawMessage;
    }
    else {
        const linkToken = rawMessage.substring(rawMessage.indexOf('<') + 1, rawMessage.indexOf('>'));
        const textBefore = rawMessage.substring(0, rawMessage.indexOf('<'));
        let textAfter = rawMessage.substring(rawMessage.indexOf('>') + 1);
        const url = linkToken.substring(0, linkToken.indexOf('['));
        const title = linkToken.substring(linkToken.indexOf('[') + 1, linkToken.indexOf(']'));
        if (textAfter) {
            textAfter = parseMessageForLinks(textAfter);
        }
        return (<>{textBefore}<Link data-testid='notification-link' href={url}>{title}</Link>{textAfter}</>);
    }
};
const Notifications = ({ functionalArea }) => {
    const [notifications, setNotifications] = useState([]);
    const { language } = useExperience();
    useEffect(() => {
        fetchActiveNotifications();
    }, []);
    const fetchActiveNotifications = async () => {
        const url = `/mlp-ui-service/services/v1/notifications/active?functionalArea=${functionalArea}`;
        try {
            const response = await axios.get(url);
            setNotifications(response.data);
        }
        catch (error) {
            console.error(error);
        }
    };
    const alerts = useMemo(() => {
        let alerts = [];
        if (notifications && !Array.isArray(notifications)) {
            console.error('Notifications returned as an unknown type, the app is not accessed correctly and/or is recieving invalid data');
        }
        if (notifications && Array.isArray(notifications) && notifications?.length > 0) {
            notifications.forEach(notificiation => {
                alerts.push(<Alert data-public={true} type={notificiation.severity.toLowerCase()} closeable={true} id={uuidv4()} key={uuidv4()}>
            <strong> {language === Language.SPANISH ? notificiation.titleES : notificiation.titleEN}</strong>{' '}
            {language === Language.SPANISH ? parseMessageForLinks(notificiation.msgES) : parseMessageForLinks(notificiation.msgEN)}
          </Alert>);
            });
        }
        return alerts;
    }, [notifications, language]);
    return <>{alerts}</>;
};
export default Notifications;
