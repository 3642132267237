import React, { Fragment } from 'react';
import { Language } from '../../types/Language';
import { Container, Row, Heading, Alert, Link } from '@digitools/honest-react';
import { renderIcon } from '../../utils/FileUtils';
const EmployeeForms = ({ employeeForms, language, errorMessage, getEmployeeForm, closeError, }) => {
    const rows = employeeForms.map((employeeForm, index) => {
        const title = language === Language.ENGLISH ? employeeForm.englishDisplayName : employeeForm.spanishDisplayName;
        const descr = language === Language.ENGLISH ? employeeForm.englishDescription : employeeForm.spanishDescription;
        const divider = employeeForms && employeeForms.length !== (index + 1) ? <hr /> : null;
        return (<Fragment key={'form-' + employeeForm.id}>
                <Row>
                    <Link onClick={() => getEmployeeForm(employeeForm.id, employeeForm.recordType)}><Heading elemType={'h2'} elemStyle={'h4'} color={'secondaryDark'}>{renderIcon(employeeForm.type)} {title} - (Download)</Heading> </Link>
                </Row>
                    {descr && <Row style={{ marginTop: 0 }}><div>{descr}</div></Row>}
                {divider}
            </Fragment>);
    });
    return (<>
            <br />
            <Heading elemType='h1' style={{ marginBottom: '2rem' }}>{language === Language.ENGLISH ? 'Forms & documents' : 'Formularios y documentos'}</Heading>
            <Container>
                {errorMessage && <Alert type='error' closeable={true} onClose={closeError}><strong>Error: </strong>{errorMessage}</Alert>}
                {(!errorMessage && (employeeForms === undefined || employeeForms.length === 0))
            && <Alert type='info'>We could not find any Employee Forms. If you believe this to be in error, please contact us for assistance.</Alert>}
                {rows}
            </Container>
        </>);
};
export default EmployeeForms;
