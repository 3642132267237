import React, { useContext, useState, useEffect } from 'react';
import { Collapse, Row, Col, Alert, Label } from '@digitools/honest-react';
import axios from 'axios';
import { AuthenticationContext } from '../../../providers/AuthenticationProvider';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { AccessLevelEnum } from '../types/permissionTypes';
import EmployerSubLocModal from '../modal/EmployerSubLocModal';
const EmployerSubsAndLocs = (props) => {
    const authContext = useContext(AuthenticationContext);
    const headerConfig = { headers: { Authorization: authContext.authHeader() } };
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [saveModal, setSaveModal] = useState(false);
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const [subLocs, setSubLocs] = useState([]);
    const [subAndLocAccessLevel, setSubAndLocAccessLevel] = useState(props.userInfo.subsidiariesAndLocations);
    useEffect(() => setIsMessagingDisabled(false), []);
    useEffect(() => {
        if (props.clearAll) {
            setSubAndLocAccessLevel(props.subsidiariesAndLocations);
        }
        if (saveModal) {
            setSubAndLocAccessLevel(subAndLocAccessLevel);
            setSaveModal(false);
        }
    });
    const getSubsLocData = async () => {
        const url = `/crs-service/er-admin/subs-locs/` + props.userInfo.userId + `?products=` + props.products;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authContext.authHeader(),
                },
            });
            const subLocData = response.data;
            if (subLocs.length === 0) {
                if (props.clearAll) {
                    subLocData.map(data => {
                        data.selected = false;
                    });
                }
                else {
                    if (subAndLocAccessLevel === AccessLevelEnum.FULL) {
                        subLocData.map(data => {
                            data.selected = true;
                        });
                    }
                    else if (subAndLocAccessLevel === AccessLevelEnum.NO_ACCESS) {
                        subLocData.map(data => {
                            data.selected = false;
                        });
                    }
                }
                setSubLocs(subLocData);
            }
        }
        catch (error) {
            setError(true);
            setErrorMessage('Error while retrieving Subsidiaries/Locations');
            throw error;
        }
    };
    const saveSubsAndLocs = async (selectedIds, subsidiariesAndLocationsFromModal) => {
        try {
            setSaveModal(true);
            setSubAndLocAccessLevel(subsidiariesAndLocationsFromModal);
            if (subsidiariesAndLocationsFromModal === AccessLevelEnum.LIMITED) {
                const crsServiceSaveRequestBody = {
                    portalUserNumber: props.userInfo.userId,
                    adminLocationIds: selectedIds,
                };
                await axios.put(`/crs-service/er-admin/subs-locs`, crsServiceSaveRequestBody, headerConfig);
            }
            if (subsidiariesAndLocationsFromModal === AccessLevelEnum.FULL ||
                subsidiariesAndLocationsFromModal === AccessLevelEnum.NO_ACCESS) {
                await axios.delete(`/crs-service/er-admin/subs-locs/` + props.userInfo.userId, headerConfig);
            }
            const userServiceRequestBody = {
                userId: props.userInfo.userId,
                subsidiariesAndLocations: subsidiariesAndLocationsFromModal,
            };
            await axios.patch(`/user-service/services/v1/users/employers`, userServiceRequestBody, headerConfig);
            setSuccess(true);
            setSuccessMessage('Subsidiaries/Locations saved successfully');
        }
        catch (error) {
            setError(true);
            setErrorMessage('Error while saving Subsidiaries/Locations');
            throw error;
        }
    };
    const closeAlert = () => {
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    };
    const alert = success ? (<Alert id='success' type='success' closeable={true} onClose={closeAlert}>
      {successMessage}
    </Alert>) : error ? (<Alert data-testid='error' id='error' type='error' closeable={true} onClose={closeAlert}>
      {errorMessage}
    </Alert>) : (<></>);
    return (<div style={{ marginTop: '1.5rem' }}>
      <Collapse type='box' isOpen={true} title='Subsidiaries/Locations' data-testid={'sublocCollapse'}>
        {alert}
        <Row>
          <Col>
            <Label style={{ marginLeft: '1rem' }} aria-label='Subsidiaries/Locations Access Level'>
              {subAndLocAccessLevel === AccessLevelEnum.FULL
            ? 'All'
            : subAndLocAccessLevel === AccessLevelEnum.NO_ACCESS
                ? 'None'
                : 'Limited'}{' '}
              Selected
            </Label>
          </Col>
        </Row>
        <EmployerSubLocModal data-testid={'employerSubLocModal'} employerId={props.userInfo.userId} subAndLocAccessLevel={subAndLocAccessLevel} products={props.products} getSubsLocData={getSubsLocData} saveSubsAndLocs={saveSubsAndLocs} subLocs={subLocs}/>
      </Collapse>
    </div>);
};
export default EmployerSubsAndLocs;
