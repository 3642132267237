import { Button, Col, Heading, Icon, InputMessage, Label, Radio, RadioGroup, Row, Spinner, TextInput, themes, } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useEffect, useRef, useState } from 'react';
import useAuthentication from '../../../../hooks/useAuthentication';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import useTranslate from '../../../../hooks/useTranslate';
import { DeleteInterviewData, GetPrePopData } from '../../api/EoiApiCalls';
import useEoiContext from '../../hooks/useEoiContext';
import EoiVerificationText from '../../i18n/EoiVerificationText';
import { VerificationTypeCode } from '../../providers/EoiProvider';
import { empIdValidation, fieldRequired, ssnValidation } from '../../utils/FormFieldValidations';
const IdentityVerificationForm = ({ setIsVerified, ssoUserEmpId }) => {
    const { t } = useTranslate();
    const [notFoundError, setNotFoundError] = useState(false);
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [empIdRetryCounter, setEmpIdRetryCounter] = useState(0);
    const { savedInterview, initializeInterviewAnswers, initialInformation, customContent, setEoiError, interviewAnswers, setVerificationInformation, setPrePopInfoAndInterviewAnswers, setVerifyingUserIdentity, } = useEoiContext();
    const [disableVerifyButton, setDisableVerifyButton] = useState(false);
    const { trackClickWithPage } = useGoogleAnalytics();
    const { user, authHeader } = useAuthentication();
    let identificationMethod;
    const formApiRef = useRef();
    const setFormApi = (formApis) => {
        formApiRef.current = formApis;
    };
    const deleteSavedInterview = async () => {
        if (savedInterview) {
            try {
                await DeleteInterviewData(authHeader());
            }
            catch (error) {
                // swallow error - if user saves again or hits continue, the old saved data will be overlayed with the new
            }
        }
    };
    useEffect(() => {
        if (ssoUserEmpId && !savedInterview) {
            setDisableVerifyButton(true);
            verifyUser('ssn', {
                employeeId: ssoUserEmpId,
                suppressPrepopIndicator: initialInformation
                    ? initialInformation.employerConfiguration.preference.suppressEligibilityPrePopIndicator
                    : false,
            }, true);
        }
    }, []);
    const verifyUser = async (type, data, ssoOnloadVerify) => {
        setVerifyLoading(true);
        try {
            setIsVerified(false);
            const prePopData = await GetPrePopData(data, authHeader());
            setPrePopInfoAndInterviewAnswers(prePopData, data);
            if (ssoOnloadVerify && prePopData && prePopData.applicants) {
                formApiRef.current.setValue('verificationSsn', prePopData.applicants.filter(app => app.applicantType === 'EMPLOYEE')[0]?.ssn);
            }
            setVerifySuccess(true);
            setIsVerified(true);
        }
        catch (error) {
            if (error.response.status === 404) {
                if (type === 'empId') {
                    setEmpIdRetryCounter(empIdRetryCounter + 1);
                }
                if (ssoUserEmpId) {
                    setDisableVerifyButton(false);
                }
                if (!ssoOnloadVerify) {
                    setNotFoundError(true);
                }
            }
            else {
                setEoiError(true);
            }
        }
        setVerifyLoading(false);
    };
    const radioSelection = (formState) => {
        if (identificationMethod === VerificationTypeCode.BTH && formState) {
            return formState.values['verificationType'];
        }
        return null;
    };
    const onSubmit = async (values) => {
        trackClickWithPage('verifyIdentity', '/customer/eoi/verification');
        setDisableVerifyButton(true);
        setVerifyingUserIdentity(true); // do not call SFL when interviewAnswers change
        initializeInterviewAnswers(); // reset all saved/prepopped data
        deleteSavedInterview(); // if it exists
        if (values.verificationEmpId) {
            setVerificationInformation({
                verificationEmpid: values.verificationEmpId,
                verificationSsn: undefined,
            });
            await verifyUser('empId', {
                employeeId: values.verificationEmpId,
                suppressPrepopIndicator: initialInformation
                    ? initialInformation.employerConfiguration.preference.suppressEligibilityPrePopIndicator
                    : false,
            });
        }
        else if (values.verificationSsn) {
            setVerificationInformation({
                verificationEmpid: undefined,
                verificationSsn: values.verificationSsn,
            });
            await verifyUser('ssn', {
                employeeSsn: values.verificationSsn,
                suppressPrepopIndicator: initialInformation
                    ? initialInformation.employerConfiguration.preference.suppressEligibilityPrePopIndicator
                    : false,
            });
        }
        setVerifyingUserIdentity(false);
        setDisableVerifyButton(false);
    };
    if (initialInformation) {
        identificationMethod = initialInformation.employerConfiguration.preference.applicantIdentificationMethodCode;
    }
    let radioSelected = 'ssn';
    if (interviewAnswers.verificationInformation && interviewAnswers.verificationInformation.verificationEmpid) {
        radioSelected = 'empId';
    }
    if (ssoUserEmpId) {
        radioSelected = 'ssn';
    }
    const requireSsn = empIdRetryCounter >= 3;
    return (<Form getApi={setFormApi} onSubmit={onSubmit} data-testid='identity-verification-form'>
      {({ formState }) => (<>
          <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
            <Row>
              <Col lg={11} md={11} sm={11} xs={11} data-public={true}>
                <Heading data-testid='eoi-verification-heading' elemType={'h3'} elemStyle={'h3'} style={{ marginBottom: '.5rem' }}>
                  {t(EoiVerificationText.verificationHeading)}
                </Heading>
              </Col>

              <Col lg={1} md={1} sm={1} xs={1} data-testid='verification-success' data-public={true} style={{ paddingTop: '7px' }}>
                {verifySuccess && <Icon name={'success'} color={'secondary'} theme={themes.lincoln} size={'medium'}/>}
                {verifyLoading && (<Spinner size='small' style={{
                    position: 'absolute',
                    height: '1.5rem',
                    width: '1.5rem',
                    marginTop: 0,
                }}/>)}
              </Col>
            </Row>
            {requireSsn && (<Row data-public={true}>
                <Col>
                  <Label data-testid='retry-limit-label'> {t(EoiVerificationText.verifyLimitReached)}</Label>
                </Col>
              </Row>)}

            {identificationMethod === VerificationTypeCode.BTH && !requireSsn && (<Row data-public={true}>
                <Col>
                  <Label> {t(EoiVerificationText.verificationSubHeading)}</Label>
                </Col>
              </Row>)}

            <Row>
              {identificationMethod === VerificationTypeCode.BTH && !requireSsn && (<Col lg={4} md={12} sm={12} xs={12} data-testid='verification-radio-group' data-public={true}>
                  <RadioGroup label={t(EoiVerificationText.verificationSubHeading)} hideLabel={true} field={'verificationType'} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={radioSelected} data-public={true}>
                    <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name={'verificationType'} id='ssn' label={t(EoiVerificationText.ssnLabel)} value={'ssn'} data-public={true}/>
                    <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name={'verificationType'} id='empId' label={customContent && customContent.employeeIdText && t(customContent?.employeeIdText)} value={'empId'} disabled={ssoUserEmpId ? true : false} data-public={true}/>
                  </RadioGroup>
                </Col>)}

              <Col lg={4} md={6} sm={6} xs={6}>
                {(radioSelection(formState) === 'ssn' ||
                identificationMethod === VerificationTypeCode.SSN ||
                requireSsn) && (<TextInput data-testid='verification-ssn-field' field={'verificationSsn'} label={t(EoiVerificationText.ssnLabel)} theme={themes.lincoln} validate={(ssn) => {
                    setNotFoundError(false);
                    return ssnValidation(ssn, ssoUserEmpId != null);
                }} type={'textmask'} ariaLabelText={'ssn'} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} initialValue={interviewAnswers.verificationInformation
                    ? interviewAnswers.verificationInformation.verificationSsn
                    : undefined}/>)}

                {(radioSelection(formState) === 'empId' || identificationMethod === VerificationTypeCode.EID) &&
                customContent &&
                customContent.employeeIdText &&
                !requireSsn && (<TextInput data-testid='verification-empId-field' field={'verificationEmpId'} label={t(customContent?.employeeIdText)} theme={themes.lincoln} type={'text'} ariaLabelText={'empId'} validate={(empId) => {
                    setNotFoundError(false);
                    return empIdValidation(empId);
                }} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} initialValue={interviewAnswers.verificationInformation
                    ? interviewAnswers.verificationInformation.verificationEmpid
                    : undefined}/>)}
                <InputMessage data-testid='not-found-error' error={notFoundError} style={{ marginTop: '-1rem' }} data-public={true}>
                  {t(EoiVerificationText.verificationNotFound)}
                </InputMessage>
              </Col>
              <Col lg={4} md={6} sm={6} xs={6} data-public={true}>
                <Button data-testid='verification-submit-button' buttonType={'primary'} theme={themes.lincoln} type='submit' disabled={disableVerifyButton}>
                  {t(EoiVerificationText.verifyButton)}
                </Button>
              </Col>
            </Row>
          </div>
          <hr />
        </>)}
    </Form>);
};
export default IdentityVerificationForm;
