export const BdmDisplayTextEs = {
    allocation: 'Asignación',
    name: 'Nombre',
    type: 'Tipo',
    toggleLabel: 'Dividir equitativamente',
    total: 'Total',
    bdmHeading: 'Administrar Mis Beneficiarios',
    bdmText: 'Agregue o modifique la información de sus beneficiarios. Se permite un máximo de 15 beneficiarios.',
    primaryBeneficiariesTitle: 'Beneficiarios Primarios',
    contingentBeneficiariesTitle: 'Beneficiarios Contingentes',
    isAgreedError: 'Por favor marque la casilla para enviar',
    signatureText: 'Al hacer clic en la casilla "Acepto", afirmo que las personas en este formulario es/son mi(s) beneficiario(s) designado(s). Por medio del presente certifico que he leído y entiendo las instrucciones en este formulario y que toda la información que he ingresado es verdadera y completa. Entiendo que el envío de este formulario revoca cualquier designación que haya realizado anteriormente.',
    agreeCheckbox: 'Acepto',
    signatureTitle: 'Firma',
    signature: 'Firma (ingrese su nombre)',
    signaturePlaceholder: ' Nombre',
    dateSigned: 'Fecha de firma',
    country: 'País',
    state: 'Estado',
    province: 'Provincia',
    otherAddress: 'Ingrese dirección e información de contacto',
    city: 'Ciudad',
    submitChangesButton: 'Enviar',
    select: 'Seleccione una opción',
    thankYou: '¡Gracias!',
    viewSubmissionButton: 'Ver Resumen',
    successMsgStart: 'Su designación de beneficiarios ha sido enviada exitosamente el ',
    successMsgEnd: ' Hora del Este.',
    confirmEmailMsgStart: 'Una confirmación ha sido enviada a ',
    confirmEmailMsgEnd: ' Por favor, imprima o guarde una copia de la confirmación para su constancia.',
    lastSubmitted: 'La información de sus beneficiarios fue actualizada por última vez el ',
    getHelp: 'Ayuda',
    getHelpModalText: 'Para obtener ayuda, consulte la Guía del usuario que se encuentra en el Centro de Recursos.',
    closeBtnText: 'Cerrar',
    noPrimaryBenError: 'No ha seleccionado un beneficiario primario. Por favor, designe a un beneficiario primario para continuar.',
    totalNotHundredPercentError: 'Asegúrese de que "Partes iguales" se encuentre seleccionado o que la suma de asignaciones totalice 100%.',
    allocationCannotBezeroError: 'La asignación no puede ser cero o estar en blanco',
    timeZone: 'Hora del Este.',
};
