import React from 'react';
import { Row, Col, TextInput, themes, Box, Icon } from '@digitools/honest-react';
import useAsDataContext from '../hooks/useAsDataContext';
import { Scope } from 'informed';
import useValidPhone from '../../../hooks/fieldValidation/useValidPhone';
import { validateEmail } from '../utils/validationUtils';
import { StyledDiv, DivBlock, SectionHeading, StyledRow } from './LayoutComponents';
import { phoneMask } from 'src/utils/validationUtils';
import { PreferredContactInformationText } from '../constants/PreferredContactInformationText';
import useTranslate from 'src/hooks/useTranslate';
import styled from 'styled-components';
const StyledIcon = styled(Icon) `
  font-size: 2.5rem; 
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 2rem;
}
`;
/**
 * Section of fields for contact information, for use within a <Form>
 */
const PreferredContactInformation = () => {
    const { claimantData } = useAsDataContext();
    const validatePhoneRequired = useValidPhone(true, 'Phone');
    const { t } = useTranslate();
    return (<StyledDiv data-public={true} data-testid='preferred-contact-information'>
      <Box className={'mt-4'}>
        <StyledRow>
          <DivBlock className={'d-flex align-items-center'}>
            <StyledIcon name={'contact'} size='large' color={'primary'}/>  
            <SectionHeading elemType={'h3'} color={'black'} theme={themes.lincoln}>
            {t(PreferredContactInformationText.contactInfoHeading)}
            </SectionHeading>
          </DivBlock>
        </StyledRow>
      {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} xs={12}>
            <TextInput data-testid='phone' data-private={true} field={'empPhoneNum'} label={t(PreferredContactInformationText.preferredPhoneQuestionLabel)} ariaLabelText={t(PreferredContactInformationText.preferredPhoneQuestionLabel)} theme={themes.lincoln} type={'text'} validate={validatePhoneRequired} validateOnChange={true} validateOnBlur={true} 
    //@ts-ignore //FIXME: type error                          
    mask={phoneMask} initialValue={claimantData && claimantData.empPhoneNum}/>
          </Col>
          <Col md={6} xs={12} data-private={true}>
            <TextInput data-testid='email' field={'empEmail'} label={t(PreferredContactInformationText.preferredEmailAddressQuestionLabel)} ariaLabelText={t(PreferredContactInformationText.preferredEmailAddressQuestionLabel)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateEmail} validateOnChange={true} initialValue={claimantData && claimantData.empEmail}/>
          </Col>
        </Row>
      </Scope>
      </Box>
    </StyledDiv>);
};
export default PreferredContactInformation;
