import React, { useContext } from 'react';
import { StepperContext, Row, Col, Collapse, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useCliDataContext from '../../hooks/useCliDataContext';
import ReviewField, { EditReviewField } from './ReviewField';
import PaymentMethodText from '../payment/PaymentMethod.Text';
import { DirectDepositInformationText } from '../../constants/DirectDepositInformationText';
const ReviewPaymentMethod = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { paymentMethodData, setIsDirectDepositSuccess } = useCliDataContext();
    const maskValues = (str, mask, n = 1) => {
        return ('' + str).slice(0, -n)
            .replace(/./g, mask)
            + ('' + str).slice(-n);
    };
    return (<div data-testid='PaymentMethodReview'>
            <div data-public={true} className={`mb-3`}>
            <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonPaymentMethodTestId" onClick={() => {
                setIsDirectDepositSuccess(false);
                stepper.back();
                window.scrollTo(0, 0);
            }}/>} isOpen={true} title={t(PaymentMethodText.pageTitle)} theme={themes.lincoln} type='alternate'>
                    <Row>
                        <Col md={6} lg={4}>
                            <ReviewField data-private={true} label={t(DirectDepositInformationText.firstName)} value={paymentMethodData && paymentMethodData.accountHolderFirstName}/>
                        </Col>
                        <Col md={6} lg={4}>
                            <ReviewField data-private={true} label={t(DirectDepositInformationText.lastName)} value={paymentMethodData && paymentMethodData.accountHolderLastName}/>
                        </Col>
                        <Col md={6} lg={4}>
                            <ReviewField data-private={true} label={t(DirectDepositInformationText.routingNumber)} value={paymentMethodData && maskValues(paymentMethodData.routingNumber, 'x', 4)}/>
                        </Col>
                        <Col md={6} lg={4}>
                            <ReviewField data-private={true} label={t(DirectDepositInformationText.accountNumber)} value={paymentMethodData && maskValues(paymentMethodData.accountNumber, 'x', 4)}/>
                        </Col>
                    </Row>
            </Collapse>
            </div>
        </div>);
};
export default ReviewPaymentMethod;
