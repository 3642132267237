import { useEffect, useState } from 'react';
const useTimeout = (ms = 0, started = true) => {
    const [ready, setReady] = useState(false);
    const [sRemaining, setSRemaining] = useState(ms / 1000);
    const [timeoutId, setTimeoutId] = useState(undefined);
    useEffect(() => {
        if (started) {
            setTimeoutId(setTimeout(() => {
                setReady(true);
            }, ms));
            const interval = setInterval(() => {
                setSRemaining(remaining => remaining - 1);
            }, 1000);
            return () => {
                clearTimeout(timeoutId);
                clearInterval(interval);
            };
        }
    }, [ms, started]);
    const reset = () => {
        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(() => {
            setReady(true);
        }, ms));
        setSRemaining(ms / 1000);
    };
    return [ready, sRemaining, reset];
};
export default useTimeout;
