const getLeaveStatusDefinition = (language, statusDefinitionList, typeCode, statusCode, statReasCode) => {
  let statusDef = '';
  let statusDefinitionFilter = [];
  try {
    statusDefinitionFilter = statusDefinitionList.filter(function (item) {
      let typeCodes = [];
      let products = [];
      let statusCodes = [];
      if (item.typeCode) typeCodes = item.typeCode.split(';');
      else typeCodes = [];
      if (item.product) products = item.product.split(';');
      else products = [];
      if (item.statusCode) statusCodes = item.statusCode.split(';');
      else statusCodes = [];

      return (
        products.indexOf('LEAVE') !== -1 &&
        statusCodes.indexOf(statusCode.trim()) !== -1 &&
        item.statusReasonCode.trim() === statReasCode.trim() &&
        typeCodes.indexOf(typeCode.trim()) !== -1 &&
        item.enabled === true
      );
    });

    if (statusDefinitionFilter.length > 0) {
      if (language === 'es') {
        statusDef = statusDefinitionFilter[0].reason_ES;
      } else {
        statusDef = statusDefinitionFilter[0].reason_EN;
      }
    }
  } catch (ex) {
    console.log('Exception occured in getLeaveStatusDefinition : ' + ex.toString());
  }
  return statusDef;
};

export default getLeaveStatusDefinition;
