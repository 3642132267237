import React from 'react';
import { Row, Col, TextInput, Heading, themes, Radio, RadioGroup, } from '@digitools/honest-react';
import useTranslate from '../../../hooks/useTranslate';
import useCliDataContext from '../hooks/useCliDataContext';
import { fieldRequired } from '../../eoi/utils/FormFieldValidations';
import { Scope, useFormState } from 'informed';
import { RadioContainer } from './LayoutComponents';
import useValidPhone from '../../../hooks/fieldValidation/useValidPhone';
import { PreferredContactInformationText } from '../constants/PreferredContactInformationText';
import useExperience from '../../../hooks/useExperience';
import { Experience } from '../../../types/Experience';
import { validateEmail, validateOptionalEmail } from '../utils/validationUtils';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
const phoneMask = (value) => {
    if (value) {
        var numbers = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        if (numbers) {
            return !numbers[2] ? numbers[1] : '(' + numbers[1] + ') ' + numbers[2] + (numbers[3] ? '-' + numbers[3] : '');
        }
    }
};
/**
 * Section of fields for residential information, for use within a <Form>
 */
const PreferredContactInformation = () => {
    const { t } = useTranslate();
    const { claimantData } = useCliDataContext();
    const { values } = useFormState();
    const { experience } = useExperience();
    const validatePhoneRequired = useValidPhone(true, 'Phone');
    const validateFaxRequired = useValidPhone(true, 'Fax');
    const validatePhoneOptional = useValidPhone(false);
    //@ts-ignore
    const isEmailRequired = experience === Experience.EMPLOYEE || values && values.claimantData && values.claimantData['selectedpreferredmethod'] === 'E';
    //@ts-ignore
    const isFaxRequired = values && values.claimantData && values.claimantData['selectedpreferredmethod'] === 'F';
    const isPhoneRequired = experience === Experience.EMPLOYEE;
    return (<div data-testid='preferred-contact-information' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>{t(PreferredContactInformationText.heading)}</Heading>
      </BorderLessGrayBox>
      {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} xs={12}>
            <TextInput id='phoneInput' data-testid='phone' validate={isPhoneRequired ? validatePhoneRequired : validatePhoneOptional} validateOnChange={true} validateOnBlur={true} label={isPhoneRequired ? t(PreferredContactInformationText.phone) : t(PreferredContactInformationText.phoneOptional)} aria-label={isPhoneRequired ? t(PreferredContactInformationText.phone) : t(PreferredContactInformationText.phoneOptional)} field={'phone'} 
    //@ts-ignore //FIXME: type error
    mask={phoneMask} initialValue={claimantData && claimantData.phone}/>
          </Col>
          <Col md={6} xs={12}>
              <TextInput data-private={true} data-testid='email' label={isEmailRequired ? t(PreferredContactInformationText.email) : t(PreferredContactInformationText.emailOptional)} aria-label={isEmailRequired ? t(PreferredContactInformationText.email) : t(PreferredContactInformationText.emailOptional)} field={'email'} validate={isEmailRequired ? validateEmail : validateOptionalEmail} validateOnChange={true} validateOnBlur={true} initialValue={claimantData && claimantData.email}/>
          </Col>
          
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <RadioGroup label={t(PreferredContactInformationText.preferredContactLabel)} field={'selectedpreferredmethod'} theme={themes.lincoln} legendStyles={{ marginBottom: '.75rem' }} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={claimantData && claimantData.selectedpreferredmethod || experience === Experience.EMPLOYEE && 'E' || experience === Experience.EMPLOYER && 'M'}>
              <RadioContainer>
                <Radio containerStyle={{ zIndex: 0 }} data-testid={'pref-email'} name='selectedpreferredmethod' label={"Email"} value={'E'}/>
                <Radio containerStyle={{ zIndex: 0 }} data-testid={'pref-fax'} name='selectedpreferredmethod' label={"Fax"} value={'F'}/>
                <Radio containerStyle={{ zIndex: 0 }} data-testid={'pref-mail'} name='selectedpreferredmethod' label={"Mail"} value={'M'}/>
              </RadioContainer>
            </RadioGroup>
          </Col>
          {isFaxRequired && <Col md={6} xs={12} style={{ marginTop: '1.1rem' }}>
            <TextInput data-testid='fax' validate={validateFaxRequired} validateOnChange={true} validateOnBlur={true} label={t(PreferredContactInformationText.fax)} aria-label={t(PreferredContactInformationText.fax)} field={'claimantpreferredfax'} 
        //@ts-ignore //FIXME: type error
        mask={phoneMask} initialValue={claimantData && claimantData.claimantpreferredfax}/>
          </Col>}
        </Row>
      </Scope>
    </div>);
};
export default PreferredContactInformation;
