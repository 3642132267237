import React from 'react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import styled from 'styled-components';
const StyledDiv = styled('div') `
  border-left: 3px solid ${themes.lincoln.palette.primary};
  padding-left: 1rem;
`;
/**
 * Reusable container for questions, possible to extend to include header & right aligned success checkmark
 */
const QuestionBox = ({ 'data-testid': dataTestId, children, heading, isSuccess, ...rest }) => {
    return (<>
      <StyledDiv data-testid={`${dataTestId}`} {...rest}>
          <div style={{ display: 'flex' }}>
            <Heading data-testid={`${dataTestId}-heading`} elemType={'h3'} elemStyle={'h3'} style={{ marginBottom: '1rem' }}>
              {heading}
            </Heading>
          {isSuccess && (<Icon name={'success'} color={'secondary'} theme={themes.lincoln} size={'medium'} style={{ justifySelf: 'flex-end', marginLeft: '1rem', marginTop: '7px' }}/>)}
          </div>
        {children}
      </StyledDiv>
      <hr />
      <br />
    </>);
};
export default QuestionBox;
