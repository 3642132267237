import React, { useContext, useEffect, useState } from 'react';
import { asField } from 'informed';
import { getEnvVariable } from 'src/utils/envUtils';
// @ts-ignore
import Styles from './Captcha.scss';
import { InputMessage } from '@digitools/honest-react';
import { ExperienceContext } from 'src/providers/ExperienceProvider';
import { v4 as uuidV4 } from 'uuid';
const siteKey = getEnvVariable('captchaSiteKey');
const Captcha = asField(({ fieldState, fieldApi, ...props }) => {
    const { language } = useContext(ExperienceContext);
    const [ready, setReady] = useState(false);
    const [rendered, setRendered] = useState(false);
    const [renderedLanguage, setRenderedLanguage] = useState();
    const [widgetId, setWidgetId] = useState('');
    const { setValue, setTouched, reset } = fieldApi;
    const { resetCaptcha, setCaptchaState, disabled, onBlur, id } = props;
    // temp fix to emulate undefined that is currently happnening in master/prod and working
    let _id;
    const parentId = 'captchaParent' + id;
    let captchaId = 'captchaId' + _id;
    const loadScript = (src) => {
        // need a unique callback for each instance of the component so we can have more than one Captcha rendered at a time
        // @ts-ignore
        window[captchaId + 'callback'] = () => {
            setReady(true);
        };
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.defer = true;
        document.getElementsByTagName('head')[0].appendChild(script);
    };
    const removeRenderedCaptcha = () => {
        setReady(false);
        setRendered(false);
        const captchaElement = document.getElementById(captchaId);
        if (captchaElement) {
            captchaElement.parentElement.removeChild(captchaElement);
            captchaId = 'captchaId' + uuidV4();
        }
    };
    useEffect(() => {
        if (language !== renderedLanguage) {
            setRenderedLanguage(language);
            removeRenderedCaptcha();
        }
        if (!ready) {
            loadScript(`https://www.google.com/recaptcha/api.js?onload=${captchaId}callback&hl=${language}`);
        }
        if (ready && !disabled && !rendered) {
            setRendered(true);
            // create div to render recaptcha
            const captchaDiv = document.createElement('div');
            captchaDiv.id = captchaId;
            const parent = document.getElementById(parentId);
            parent.appendChild(captchaDiv);
            setWidgetId(
            // @ts-ignore
            window.grecaptcha.render(captchaDiv, {
                sitekey: siteKey,
                callback: tokenCallback,
                'expired-callback': expiredCallback,
            }));
        }
        if (disabled && rendered) {
            // @ts-ignore
            window.grecaptcha.reset(widgetId);
        }
        if (resetCaptcha) {
            // @ts-ignore
            window.grecaptcha.reset(widgetId);
            setCaptchaState(false);
            reset();
        }
    });
    const expiredCallback = () => {
        reset();
    };
    const tokenCallback = (token) => {
        setValue(token);
    };
    const blurCallback = (e) => {
        setTouched(true);
        if (onBlur) {
            onBlur(e);
        }
    };
    return (<React.Fragment>
      <div style={{ display: disabled && rendered ? 'none' : 'block' }} id={parentId} className={Styles.divStyle} onBlur={blurCallback}/>
      <InputMessage className={Styles.errorMessage} id={`${parentId}-error-message`} role={'alert'} aria-live={'assertive'} error={true} hidden={!fieldState.error}>
        {fieldState.error ? fieldState.error : ''}
      </InputMessage>
    </React.Fragment>);
});
Captcha.displayName = 'Captcha';
export default Captcha;
