import useGet from '../hooks/useGet';
import { Experience, ExperienceCode } from '../types/Experience';
import axios from 'axios';
export const useGetCustomerNavigation = (experience) => {
    const experienceCode = experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER;
    return useGet({
        url: `/mlp-ui-service/services/v1/nav-menu?experience=${experienceCode}`,
        noInitial: true,
    });
};
export const getCustomerNavigation = (experience, isNewNav, authHeader) => {
    return axios.get(`/mlp-ui-service/services/v1/nav-menu`, {
        headers: { Authorization: authHeader },
        params: { experience: experience === Experience.EMPLOYEE ? ExperienceCode.EMPLOYEE : ExperienceCode.EMPLOYER, isNewNav },
    });
};
export default {
    useGetCustomerNavigation,
    getCustomerNavigation,
};
