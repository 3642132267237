const today = new Date();
const disclaimerDate = `${new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
}).format(today)}`;
export const DentalText = {
    dropdownText: {
        english: 'Select a member',
        spanish: 'Seleccione un miembro',
    },
    effectiveDateText: {
        english: 'Effective date',
        spanish: 'Fecha de entrada en vigor',
    },
    terminationDateText: {
        english: 'Termination date',
        spanish: 'Fecha del plazo',
    },
    dateOfBirthText: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento',
    },
    coveredServicesText: {
        english: 'Covered services',
        spanish: 'Servicios cubiertos',
    },
    deductibleText: {
        english: 'Deductibles and coinsurance',
        spanish: 'Deducibles y coaseguro',
    },
    claimsText: {
        english: 'Claims',
        spanish: 'Reclamaciones',
    },
    backText: {
        english: 'Back',
        spanish: 'Atrás',
    },
    disclaimerText: {
        english: `The summary provided below is based on processed claims as of ${disclaimerDate}. Future benefits will be applied in the order that claims are received and processed.`,
        spanish: `El resumen que se proporciona a continuación se basa en las reclamaciones procesadas a partir del ${disclaimerDate}. Los beneficios futuros se aplicarán en el orden en que se reciban y procesen las reclamaciones.`,
    },
    deductibleAmountPaidText: {
        english: 'AMOUNT PAID TOWARDS DEDUCTIBLE',
        spanish: 'AMOUNT PAID TOWARDS DEDUCTIBLE',
    },
    deductiblesText: {
        english: 'deductibles',
        spanish: 'deducibles',
    },
    coinsuranceText: {
        english: 'coinsurance',
        spanish: 'coaseguro',
    },
    coinsuranceTitleText: {
        english: 'Coinsurance',
        spanish: 'Coaseguro',
    },
    youPayText: {
        english: 'You pay',
        spanish: 'Tú pagas',
    },
    deductibleAppliesText: {
        english: 'Deductible applies',
        spanish: 'Se aplica deducible',
    },
};
export default DentalText;
