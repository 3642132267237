import React, { useContext } from 'react';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import { StepperContext } from '@digitools/honest-react';
import styled from 'styled-components';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../hooks/useTranslate';
import { CliText } from '../constants/CliText';
import useCliCustomerConfigContext from '../hooks/useCliContext';
import SaveForLaterButton from './SaveForLaterButton';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import CancelButton from './CancelApplicationButton';
import useCliDataContext from '../hooks/useCliDataContext';
const StyledDiv = styled('div') `
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 765px) {
    flex-direction: column-reverse;
  }
`;
const StyledContinueButton = styled(Button) `
  @media (max-width: 765px) {
    margin-bottom: 0.5rem;
  }
`;
const StyledBackButton = styled(Button) `
  @media (max-width: 765px) {
    margin-top: 0.5rem;
  }
`;
/* Reusable container for stepper button formatting at the bottom of each interview page */
const StepperButtons = ({ type = 'continue', firstStep, last, lastButton, suppressNext, onClickBack, onClickNext, analyticsPageName }) => {
    const { t } = useTranslate();
    const cliConfigContext = useCliCustomerConfigContext();
    const { setSubmitErrors, submitErrors } = useCliDataContext();
    const stepper = useContext(StepperContext);
    /* only calls next if there's no validation on this form - let Form handle that */
    const onNext = () => {
        if (!suppressNext) {
            stepper.next();
        }
    };
    const handleBackButtonClick = () => {
        if (submitErrors) {
            setSubmitErrors(false);
        }
        if (onClickBack) {
            onClickBack();
        }
        stepper.back();
        window.scrollTo(0, 0);
    };
    const handleContinueButtonClick = () => {
        if (submitErrors) {
            setSubmitErrors(false);
        }
        if (onClickNext) {
            onClickNext();
        }
        onNext();
    };
    const { experience } = useExperience();
    const label = type === 'continue' ? (cliConfigContext && cliConfigContext?.hasSaveForLater && !firstStep ? t(CliText.saveAndContinue) : t(CliText.nextButton)) : t(CliText.submit);
    const labelText = (experience === Experience.EMPLOYEE ? label : t(CliText.continue));
    //TODO: (Honest) make StepperContext give you it's current step & isFirst or isLast, would make this simpler
    return (<StyledDiv data-testid='stepper-buttons'>
      {!firstStep && (<StyledBackButton data-testid='stepper-back' type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={() => handleBackButtonClick()}>
          {t(CliText.backButton)}
        </StyledBackButton>)}

      {cliConfigContext && cliConfigContext?.hasSaveForLater && !firstStep && experience === Experience.EMPLOYEE &&
            <SaveForLaterButton analyticsPageName={analyticsPageName}/>}

      {!firstStep && experience === Experience.EMPLOYER &&
            <CancelButton analyticsPageName={analyticsPageName}/>}

      {!last &&
            <StyledContinueButton style={{ marginTop: !firstStep ? '0' : '-0.5rem' }} type={'submit'} buttonType={'primary'} theme={themes.lincoln} data-testid='stepper-next' onClick={() => handleContinueButtonClick()}>
          {labelText}
        </StyledContinueButton>}
      
      {lastButton}
    </StyledDiv>);
};
export default StepperButtons;
