export const MessageContainerText = {
    defaultMessageTitle: {
        english: "Employer message",
        spanish: "Mensaje del empleador"
    },
    messageButtonLabel: {
        english: "Employer messages",
        spanish: "Mensajes del empleador"
    },
    noMessages: {
        english: "Your employer may provide important notices or instructional information for you from time to time, currently there are not messages to display",
        spanish: "Su empleador puede proporcionarle avisos importantes o información instructiva de vez en cuando, actualmente no hay mensajes para mostrar"
    }
};
