import React from 'react';
import { Scope, useFormState } from 'informed';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import LeaveDates from '../components/LeaveDates';
import Relationship from '../components/Relationship';
import OtherRelationship from '../components/OtherRelationship';
const OtherFlow = () => {
    const { values } = useFormState();
    /* Show if leaveType === OTHER and they've answered leave reason */
    const shouldDisplayOtherFlow = values?.absenceData?.leaveType === LeaveType.OTHER && values?.absenceData?.leaveReason;
    /* Show realtionship question if leaveReason = SC [Sick Child] or WC [Wellness Care] */
    const shouldDisplayRelationship = values?.absenceData?.leaveReason === 'SC' || values?.absenceData?.leaveReason === 'WC';
    /* Show otherrealtionship if relationship = OT */
    const shouldDisplayOtherRelationship = values?.absenceData?.relationship === 'OT';
    return (<>
      {shouldDisplayOtherFlow && (<div data-testid='other-flow'>
          {/* @ts-ignore */}
          <Scope scope='absenceData'>
            {/* relationship */}
            {shouldDisplayRelationship && <Relationship />}
            {/* other relationship */}
            {shouldDisplayOtherRelationship && <OtherRelationship />}
            {/* leaveBeginDate & leaveEndDate */}
          </Scope>
          <LeaveDates />
        </div>)}
    </>);
};
export default OtherFlow;
