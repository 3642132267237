import { Alert, Box, Button, Col, Heading, Label, Loader, Row, StepperContext, themes } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import useTealium from '../../../hooks/useTealium';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import api from '../api';
import RabEnglish from '../i18n/languages/english';
import RabSpanish from '../i18n/languages/spanish';
const StyledLabel = styled('label') `
  margin: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  & + div {
    margin-top: 10px;
  }
`;
export const RabConfirmation = (props) => {
    const { t: translation } = useTranslation('rabTranslation');
    return (<>
      <Row>
        <Heading elemType={'h3'} theme={themes.lincoln}>
          {translation('claimAppliedText') + props.claimNumber}
        </Heading>
      </Row>
      {props.review ? (<Row>
          <Col>{translation('reviewHeaderText').toString()}</Col>
        </Row>) : null}
      <Row>
        <Col />
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('numberOfBirthsText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='numberOfBirths' style={{ fontWeight: 'normal' }}>
            {props.report.numberOfBirth}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('birthTypeText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='birthType' style={{ fontWeight: 'normal' }}>
            {props.report.deliveryType}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('birthDateText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='dateOfBirth' style={{ fontWeight: 'normal' }}>
            {props.report.dateOfBirth}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('birthTime')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='birthTime' style={{ fontWeight: 'normal' }}>
            {props.report.hours}:{props.report.minutes}:{props.report.ampm}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('lastDayWorkedText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='lastDayWorked' style={{ fontWeight: 'normal' }}>
            {props.report.lastDayWorked}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('hospitalAdmissionDateText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='hospitalAdmissionDate' style={{ fontWeight: 'normal' }}>
            {props.report.hospitalAdmissionDate}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
      <Row>
        <Col md={3} xs={12} sm={12} style={{ textAlign: 'left' }}>
          {/* @ts-ignore */}
          <StyledLabel>{translation('bondingTimeText')}:</StyledLabel>
        </Col>
        <Col md={2} xs={12} sm={12} style={{ textAlign: 'left' }}>
          <Label data-testid='bondingQuestionType' style={{ fontWeight: 'normal' }}>
            {props.report.bondingQuestionType}
          </Label>
        </Col>
        <Col md={7} xs={12} sm={12}/>
      </Row>
    </>);
};
const ReviewEditHeader = () => {
    const { t: translation } = useTranslation('rabTranslation');
    return (<Row style={{ marginBottom: '1rem' }}>
      <Col sm={6} md={6} lg={6}>
        <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'}>
          {translation('stepLabelReview').toString()}
        </Heading>
      </Col>
    </Row>);
};
const RabReview = (props) => {
    const stepper = useContext(StepperContext);
    const { t: translation } = useTranslation('rabTranslation');
    const [error, setError] = useState();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const { language } = useExperience();
    const [isPageLoading, setIsPageLoading] = useState(false);
    useEffect(() => setIsMessagingDisabled(false), []);
    const { authHeader, user } = useAuthentication();
    const { trackEvent } = useTealium();
    const closeAlert = () => {
        setError(false);
    };
    const alert = error ? (<Alert data-testid='error' id='error' type='error' closeable={true} onClose={closeAlert}>
      {translation('genericError').toString()}
    </Alert>) : (<></>);
    useEffect(() => {
        setError(false);
    }, []);
    const submit = () => {
        setIsPageLoading(true);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: 'submit',
            event_version: TEALIUM_EVENT.EVENT_VERSION.REVIEW,
            event_status: TEALIUM_EVENT.EVENT_STATUS.COMPLETE,
            user_id: user?.webGUID
        });
        const rabSubmissionResource = {
            numberOfBirths: props.report.numberOfBirth,
            hospitalAdmissionDate: props.report.hospitalAdmissionDate,
            deliveryType: props.report.deliveryType,
            childDateOfBirth: props.report.dateOfBirth,
            childTimeOfBirth: `${props.report.hours}:${props.report.minutes} ${props.report.ampm}`,
            lastDayWorked: props.report.lastDayWorked,
            bondingOption: props.report.bondingQuestionType,
            claimNumber: props.claimNumber,
        };
        if (language === 'es') {
            rabSubmissionResource.deliveryType =
                rabSubmissionResource.deliveryType === RabSpanish.cesareanText
                    ? RabEnglish.cesareanText
                    : rabSubmissionResource.deliveryType;
            rabSubmissionResource.deliveryType =
                rabSubmissionResource.deliveryType === RabSpanish.yesText
                    ? RabEnglish.yesText
                    : rabSubmissionResource.deliveryType;
            rabSubmissionResource.bondingOption =
                rabSubmissionResource.bondingOption === RabSpanish.yesText
                    ? RabEnglish.yesText
                    : rabSubmissionResource.bondingOption;
            rabSubmissionResource.bondingOption =
                rabSubmissionResource.bondingOption === RabSpanish.unknownText
                    ? RabEnglish.unknownText
                    : rabSubmissionResource.bondingOption;
        }
        if (rabSubmissionResource.bondingOption === '' || rabSubmissionResource.bondingOption === undefined) {
            rabSubmissionResource.bondingOption = 'Unchecked';
        }
        api
            .submit(authHeader(), rabSubmissionResource)
            .then((response) => {
            setIsPageLoading(false);
            stepper.next();
        })
            .catch((e) => {
            setIsPageLoading(false);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
                event_name: translation('genericError').toString(),
                event_version: TEALIUM_EVENT.EVENT_VERSION.REVIEW,
                user_id: user?.webGUID
            });
            setError(e);
        });
    };
    const edit = (step) => () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.RAB,
            event_name: 'edit',
            event_version: TEALIUM_EVENT.EVENT_VERSION.REVIEW,
            event_status: 'step 2',
            user_id: user?.webGUID
        });
        setError(false);
        stepper.edit(step);
    };
    return (<Loader id='rab-review-spinner' isShown={isPageLoading}>
      <div data-testid='rabReview' data-public={true}>
        <Row style={{ marginTop: '20px' }}>
          <Col>{alert}</Col>
        </Row>
        <ReviewEditHeader />
        <Box>
          <RabConfirmation review={props.review} claimNumber={props.claimNumber} report={props.report}/>
        </Box>
        <Row>
          <Col xs={12} sm={12}>
            <Button aria-label={'Edit'} buttonType='secondary' role='button' id='edit' type='submit' onClick={edit(0)} size={'medium'} data-testid='editButton'>
              {translation('editButton').toString()}
            </Button>
            <Button aria-label={'Submit'} buttonType='primary' role='button' id='submit' type='submit' onClick={submit} size={'medium'} data-testid='submitButton'>
              {translation('submitText').toString()}
            </Button>
          </Col>
        </Row>
      </div>
    </Loader>);
};
export default RabReview;
