import { Alert } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTranslate from "src/hooks/useTranslate";
import { MigrateDisplayText } from "src/packages/userMigration/i18n/MigrateDisplayText";
const MigrationErrorComponent = ({ migrationResponseCode }) => {
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    useEffect(() => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_name: t(MigrateDisplayText.unexpectedErrorAlert),
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: TEALIUM_EVENT.EVENT_VERSION.UPDATE_DETAILS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE,
        });
    }, [migrationResponseCode]);
    return (<Alert data-testid={'has-migration-error-alert'} type={'error'}>
        {t(MigrateDisplayText.unexpectedErrorAlert)}
      </Alert>);
};
export default MigrationErrorComponent;
