import { Col, Heading, Row, themes } from '@digitools/honest-react';
import * as _ from 'lodash';
import React from 'react';
import EditButton from './EditButton';
import NoFieldSelected from './NoFieldSelected';
import './StatusAndDatesDetails.scss';
export const getCheckedFilterStatusCodes = (filterStatusCodes) => {
    return filterStatusCodes.filter((filterStatusCode) => filterStatusCode.checked && filterStatusCode.code !== 'ASC');
};
const StatusAndDatesDetails = ({ filterStatusCodes, reportDateRange, history, }) => {
    const selectedStatus = getCheckedFilterStatusCodes(filterStatusCodes);
    const statusArray = _.chunk(selectedStatus, Math.ceil(selectedStatus ? selectedStatus.length / 3 : 2));
    return (<div style={{ marginTop: '1rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }} data-testid={'section-div'}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {'Status and/or dates'}
        </Heading>
        <h3><label>{'Status'}</label></h3>
        {/* @ts-ignore */}
        <Choose>
          {/* @ts-ignore */}
          <When condition={statusArray.length}>
            <Row>
              {statusArray.map((subStatusCodes, indexRow) => (<Col lg={4} key={indexRow} style={{ marginTop: '5px' }}>
                  {subStatusCodes.map((status, index) => (<Col key={'col' + index} style={{ marginTop: '.3rem' }}>
                      {<label>{status.label}</label>}
                    </Col>))}
                </Col>))}
            </Row>
            {/* @ts-ignore */}
          </When>
          {/* @ts-ignore */}
          <Otherwise>
            <NoFieldSelected />
            {/* @ts-ignore */}
          </Otherwise>
          {/* @ts-ignore */}
          {/* @ts-ignore */}
        </Choose>
        <Row style={{ marginTop: '1rem' }}><Col /></Row>
        <hr />
        <div>
          <h3><label>{'Dates'}</label></h3>
          {/* @ts-ignore */}
          <Choose>
            {/* @ts-ignore */}
            <When condition={reportDateRange.dateSearchBy || reportDateRange.fromDate || reportDateRange.toDate}>
              <Row>
                <Col lg={4} md={4}>
                  <Row><Col>{'Search On'}</Col></Row>
                  <Row style={{ marginTop: '0.2rem' }}><Col>{reportDateRange.dateSearchBy}</Col></Row>
                </Col>
                <Col lg={4} md={4}>
                  <Row className={'xs-margin-top'}><Col>{'From'}</Col></Row>
                  <Row style={{ marginTop: '0.2rem' }}><Col>{reportDateRange.fromDate}</Col></Row>
                </Col>
                <Col lg={4} md={4}>
                  <Row className={'xs-margin-top'}><Col>{'Through'}</Col></Row>
                  <Row style={{ marginTop: '0.2rem' }}><Col>{reportDateRange.toDate}</Col></Row>
                </Col>
              </Row>
              {/* @ts-ignore */}
            </When>
            {/* @ts-ignore */}
            <Otherwise>
              <NoFieldSelected />
              {/* @ts-ignore */}
            </Otherwise>
            {/* @ts-ignore */}
          </Choose>
        </div>
        <EditButton path='/create-my-report' history={history}/>
      </div>
      <hr />
    </div>);
};
export default StatusAndDatesDetails;
