import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePickerV2, Icon, InputMessage, Row, SelectInput, themes, withTheme, } from '@digitools/honest-react';
import { Form } from 'informed';
import { isAfter, isBefore, isEqual, parse } from 'date-fns';
import getLeaveProgramType from '../../../utilities/getLeaveProgramType';
import getLeaveStatus from '../../../utilities/getLeaveStatus';
import { BadgeButton } from 'src/components/Cards/StatusBadge';
import { compareByField } from 'src/utils/SortUtils';
import styles from '../CoverageDetails/CoverageDetails.scss';
import { getEnvVariable } from 'src/utils/envUtils';
const AbsenceDetailsFilter = ({ initialData, setFilteredData, theme = themes.secureSite }) => {
    const [formApi, setFormApi] = useState();
    const [sortBy, setSortBy] = useState({ field: 'date', ascending: false });
    if (!initialData) {
        return null;
    }
    const validateDateFilter = (values) => {
        if (values.fromDate && !values.throughDate) {
            formApi.setError('throughDate', 'Through date required');
        }
        else if (values.throughDate && !values.fromDate) {
            formApi.setError('fromDate', 'From date required');
        }
        else if (values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            if (isAfter(fromDate, throughDate)) {
                formApi.setError('fromDate', 'From Date must come before Through Date');
            }
        }
    };
    const clearFilterForm = () => {
        // clear out the values and errors in the form
        formApi.setValue('fromDate', undefined);
        formApi.setValue('throughDate', undefined);
        formApi.setValue('program', undefined);
        formApi.setValue('status', undefined);
        formApi.setError('fromDate', undefined);
        formApi.setError('throughDate', undefined);
        formApi.setError('program', undefined);
        formApi.setError('status', undefined);
        setFilteredData([...initialData].sort(compareByField(sortBy.field, sortBy.ascending)));
    };
    useEffect(() => {
        formApi?.getState() && filterAbsenceDetails(formApi.getState());
    }, [sortBy]);
    useEffect(() => {
        setSortBy({ field: 'date', ascending: false });
    }, []);
    const filterAbsenceDetails = (values) => {
        let filteredData = initialData;
        // // PROGRAM FILTER
        if (values.program) {
            filteredData = filteredData.filter((data) => {
                return values.program === getLeaveProgramType(data);
            });
        }
        // // STATUS FILTER
        if (values.status) {
            filteredData = filteredData.filter((data) => {
                return values.status === getLeaveStatus(data);
            });
        }
        // DATE FILTER
        if (values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            filteredData = filteredData.filter((data) => {
                const leaveDate = parse(data.date, 'P', new Date());
                return ((isBefore(fromDate, leaveDate) || isEqual(fromDate, leaveDate)) &&
                    (isBefore(leaveDate, throughDate) || isEqual(leaveDate, throughDate)));
            });
        }
        if (filteredData && filteredData?.length > 0) {
            filteredData = [...filteredData].sort(compareByField(sortBy.field, sortBy.ascending));
        }
        setFilteredData(filteredData);
        // setOutputData(outputData);
    };
    const programsInData = initialData.map((leave) => getLeaveProgramType(leave));
    let programOptions = [...new Set(programsInData)];
    programOptions = programOptions.map((program) => ({ label: program, value: program }));
    const statusesInData = initialData.map((leave) => getLeaveStatus(leave));
    let statusOptions = [...new Set(statusesInData)];
    statusOptions = statusOptions.map((status) => ({ label: status, value: status }));
    /**
     *  Flips sort if you click the already active sort field, otherwise sets it to ascending
     * @param newSort
     */
    const changeSort = (newSort) => {
        setSortBy({ field: newSort, ascending: sortBy.field === newSort ? !sortBy.ascending : false });
    };
    return (<>
    {/* Option 1 (most default) */}
    
    {/* OPTINO 2 */}
    <Collapse title='Sort & filter'>
        {/* TODO: export these styles/inlines into styled components or classes -- entire filter box should be its own component(s) - props:  filter, setFilter */}
        {/* <div style={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap', padding: '0', width: '100%' }}> */}
          <div className={styles.allTableFilterGroup} style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', width: '100%' }}>
            <span style={{
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '-1.33rem',
            padding: '0 .25rem 0 .25rem',
            fontWeight: 'bold',
        }}>
              Filter:
            </span>
            <Form style={{ width: '100%' }} onSubmit={filterAbsenceDetails} getApi={setFormApi} validate={validateDateFilter}>
        
          <Row>
            <Col md={3} sm={6}>
              <DatePickerV2 data-testid={'filterFromDate'} field={'fromDate'} ariaLabelText={'From Date'} label={'From date'} theme={themes.lincoln}/>
            </Col>
            <Col md={3} sm={6}>
              <DatePickerV2 data-testid={'filterThroughDate'} field={'throughDate'} ariaLabelText={'Through date'} label={'Through date'} theme={themes.lincoln}/>
            </Col>
            <Col md={3} sm={6}>
              <SelectInput data-testid={'filterProgram'} field={'program'} ariaLabelText={'Program'} label={'Program'} placeholderText={'Select program'} 
    //@ts-ignore FIXME: type error
    options={programOptions} theme={themes.lincoln}/>
            </Col>
            <Col md={3} sm={6}>
              <SelectInput data-testid={'filterStatus'} field={'status'} ariaLabelText={'Status'} label={'Status'} placeholderText={'Select status'} 
    //@ts-ignore FIXME: type error
    options={statusOptions} theme={themes.lincoln}/>
            </Col>
            <Col style={{ marginLeft: 'auto', marginBottom: '.5rem' }} md={3} sm={6}>
              <Button data-testid='absence-filter-clear' type='button' buttonType={'secondary'} onClick={() => clearFilterForm()} style={{ width: '100%' }}>
                Clear
              </Button>
              </Col>
              <Col md={3} sm={6}>
              <Button data-testid='absence-filter-submit' type='submit' buttonType={'primary'} style={{ width: '100%' }}>
                Apply
              </Button>
            </Col>
          </Row>
          {formApi && formApi.getState().error && (<InputMessage error={true}>{formApi && formApi.getState().error}</InputMessage>)}
          {formApi && formApi.getState().error && console.log('ERROR', formApi.getState().error)}
        </Form>
          </div>
          {/* TODO: export these styles/inlines into styled components or classes  -- entire sort box should be its own component(s) - props:  sortBy object, setSortBy (include changeSort in it) */}
          {getEnvVariable('statusNewAbsenceDetails') && <div className={styles.allTableFilterGroup} style={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap', marginTop: '1rem' }}>
            <span style={{
                position: 'absolute',
                backgroundColor: 'white',
                marginTop: '-1.33rem',
                padding: '0 .25rem 0 .25rem',
                fontWeight: 'bold',
            }}>
              Sort:
            </span>
              <BadgeButton onClick={() => changeSort('date')} backgroundColor={sortBy.field === 'date' ? 'secondaryDark' : 'grayLight'}>
                Date{' '}
                <Icon color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'} size='small'/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('duration')} backgroundColor={sortBy.field === 'duration' ? 'secondaryDark' : 'grayLight'}>
                Hours
                <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('program')} backgroundColor={sortBy.field === 'program' ? 'secondaryDark' : 'grayLight'}>
                Program
                <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('status')} backgroundColor={sortBy.field === 'status' ? 'secondaryDark' : 'grayLight'}>
                Status
                <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('reportedOn')} backgroundColor={sortBy.field === 'reportedOn' ? 'secondaryDark' : 'grayLight'}>
                Reported on
                <Icon className='ml-2' color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('addedOn')} backgroundColor={sortBy.field === 'addedOn' ? 'secondaryDark' : 'grayLight'}>
                Added on
                <Icon className='ml-2' color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
              <BadgeButton onClick={() => changeSort('updatedOn')} backgroundColor={sortBy.field === 'updatedOn' ? 'secondaryDark' : 'grayLight'}>
                Last updated
                <Icon className='ml-2' color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
              </BadgeButton>
          </div>}
      </Collapse>      
    </>);
};
export default withTheme(AbsenceDetailsFilter);
