import React, { useRef, useState, useMemo } from 'react';
import { Modal, CompositeTable as Table, Button, Heading, Row, Col, themes } from '@digitools/honest-react';
import SubsLocsColumns from '../../../Constants/SubsLocsColumns';
import { useFormApi } from 'informed';
import { getSelectedClaimProductsFromFormApi } from '../FilterMyReportValidation';
const ButtonLabelText = {
    SelectAll: 'Select all subsidiaries/locations',
    DeselectAll: 'Deselect all subsidiaries/locations',
};
const SubLocModal = ({ getSelected, data, selectedSubLocs, reportFilterCategory }) => {
    const tableApi = useRef({ setSelectedData: () => { } });
    const getSelectedRef = useRef([]);
    const formApi = useFormApi();
    const [buttonLabels, setDoSelectAll] = useState();
    const [tableReRender, setTableReRender] = useState(false);
    const [selectedLocationCount, setSelectedLocationCount] = useState(0);
    const getSelectAllButtonLabel = (selectedArrayLength) => {
        setSelectedLocationCount(selectedArrayLength);
        setDoSelectAll(selectedArrayLength === data.length ? ButtonLabelText.DeselectAll : ButtonLabelText.SelectAll);
    };
    const setTableApi = (api) => {
        tableApi.current = api;
    };
    const RenderOpenButton = ({ showModal, reportFilterCategory }) => {
        const selectedProducts = (typeof formApi.getValue === 'function' && getSelectedClaimProductsFromFormApi(formApi)) || [];
        const handleOnClick = () => {
            getSelectAllButtonLabel(getSelectedRef.current.length);
            setTableReRender(true);
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} disabled={reportFilterCategory === '' || (reportFilterCategory === 'Claim' && !selectedProducts.length)}>
        Modify subsidiaries/locations
      </Button>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            getSelected(getSelectedRef);
            setTableReRender(false);
            hideModal();
        };
        return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick} data-testid={'saveSubLocs'}>
        Save Changes
      </Button>);
    };
    const FooterSecondaryRenderButton = ({ hideModal }) => {
        const handleOnClick = () => {
            tableApi.current.setSelectedData(selectedSubLocs);
            setTableReRender(false);
            hideModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} data-testid={'cancelSubLocs'}>
        Cancel
      </Button>);
    };
    const selectDeSelectSubsLoc = () => buttonLabels === ButtonLabelText.SelectAll
        ? tableApi.current.setSelectedData(data)
        : tableApi.current.setSelectedData([]);
    const table = useMemo(() => (<Table data={data} theme={themes.lincoln} defaultPageSize={10} columns={SubsLocsColumns} paginated={true} filterable={true} isSelectable={true} selectedRef={getSelectedRef} rowIdentifier={'locationId'} data-testid={'subLocTable'} tableApi={setTableApi} onSelectedChange={(selected) => getSelectAllButtonLabel(selected.length)}/>), [tableReRender]);
    return (<Modal style={{ margin: '2rem', overflowX: 'scroll' }} headerText={'Modify subsidiaries/locations'} modalOpenRenderButton={props => <RenderOpenButton reportFilterCategory={reportFilterCategory} {...props}/>} footerPrimaryRenderButton={FooterSecondaryRenderButton} footerSecondaryRenderButton={FooterPrimaryRenderButton} size={'large'} theme={themes.lincoln} disabledBackdropClick={true}>
      <Row style={{ alignItems: 'center' }}>
        <Col>
          <Heading style={{ marginBottom: '10px' }} data-testid={'selectedSubLocHeader'} elemType={'h6'}>
            {selectedLocationCount} of {data.length} subsidiaries/locations selected
          </Heading>
        </Col>
        <Col>
          <Button style={{ marginBottom: '15px', float: 'right', textAlign: 'right', paddingRight: '0rem' }} theme={themes.lincoln} data-testid={'selectSubs'} type={'button'} onClick={selectDeSelectSubsLoc}>
            {buttonLabels}
          </Button>
        </Col>
      </Row>
      {table}
    </Modal>);
};
export default SubLocModal;
