import React from 'react';
import LanguageToggle from './LanguageToggle';
import { validationDisplayTextEn } from './language/validationDisplayTextEn';
import { validationDisplayTextEs } from './language/validationDisplayTextEs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
export const emailPattern = /^(?!.*\.{2})(?!.*?\.\@)(?!.*?\@\.)[a-zA-Z0-9\!#$%&'*+/.=?^_`{|}~-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z0-9]{2,9}$/i;
const usernamePattern = /^[a-zA-Z0-9_+\.-]{1,128}$/;
const legacyUsernamePattern = /^[a-zA-Z0-9_+\.@-]{1,128}$/;
const employeeIdPattern = /^[a-zA-Z0-9]+$/;
const passwordPattern = /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])))(?=.{8,})/;
const ssnPattern = /^[0-9]{9}$/;
export const validateAddress1 = (address) => {
    if (!address) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!address.match(/^[0-9A-Za-z .,?#'-]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidAddress} es={validationDisplayTextEs.invalidAddress}/>;
    }
    return undefined;
};
export const validateAddress2 = (address) => {
    if (address && !address.match(/^[0-9A-Za-z .,?#'-]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidAddress} es={validationDisplayTextEs.invalidAddress}/>;
    }
    return undefined;
};
export const validateCity = (city) => {
    if (!city) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!city.match(/^[ a-zA-Z.'-]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidCity} es={validationDisplayTextEs.invalidCity}/>;
    }
    return undefined;
};
export const validateOtherAddress = (address) => {
    if (!address) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!address.match(/^[0-9 a-zA-Z.,?#'-]{0,255}$/)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidOtherAddress} es={validationDisplayTextEs.invalidOtherAddress}/>);
    }
    return undefined;
};
export const validateCharityContact = (charityContact) => {
    if (!!charityContact && !charityContact.match(/^[-.',_\/?#@ a-zA-Z0-9]{1,50}$/)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidCharityContact} es={validationDisplayTextEs.invalidCharityContact}/>);
    }
    else if (!charityContact) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    return undefined;
};
export const validateCharityName = (charityName) => {
    if (!!charityName && !charityName.match(/^[-.',_\/?#@ a-zA-Z0-9]{1,50}$/)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidCharityName} es={validationDisplayTextEs.invalidCharityName}/>);
    }
    else if (!charityName) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    return undefined;
};
export const validateGuardianInfo = (info) => {
    if (!!info && !info.match(/^[-\s.',_\/?#@ \da-zA-Z]{0,255}$/)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidGuardianInfo} es={validationDisplayTextEs.invalidGuardianInfo}/>);
    }
    return undefined;
};
export const validateTrustName = (trustName) => {
    if (!!trustName && !trustName.match(/^[-.',_\/?#@ a-zA-Z0-9]{1,50}$/)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidTrustName} es={validationDisplayTextEs.invalidTrustName}/>);
    }
    else if (!trustName) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    return undefined;
};
export const validatePhone = (phoneNumber) => {
    if (!phoneNumber) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!phoneNumber.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/) && !phoneNumber.match(/^[0-9]{10}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidPhone} es={validationDisplayTextEs.invalidPhone}/>;
    }
    return undefined;
};
export const validatePhoneNotRequired = (phoneNumber) => {
    if (!!phoneNumber && !phoneNumber.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/) && !phoneNumber.match(/^[0-9]{10}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidPhone} es={validationDisplayTextEs.invalidPhone}/>;
    }
    return undefined;
};
export const formatPhone = (phoneNumber) => {
    if (!phoneNumber) {
        return phoneNumber;
    }
    const onlyNums = phoneNumber.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 7) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};
export const formatCity = (city) => {
    return city ? city.replace(/[^a-z-.' ]+/gi, '') : undefined;
};
export const formatName = (name) => {
    return name ? name.replace(/[^a-zA-Z-'. ]+/gi, '') : undefined;
};
export const isNotEmpty = (name) => {
    if (!name) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    return undefined;
};
export const validateName = (name, MAXLENGTH, field, required) => {
    if (required && !name) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (name && (name.length > MAXLENGTH || name.match(/[^a-zA-Z-'. ]+/gi))) {
        return `${field} must consist of up to ${MAXLENGTH} alpha[a-zA-Z], punctuation[-.'], and space characters only`;
    }
    return undefined;
};
export const validateEmail = (email) => {
    if (!email) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!email.match(emailPattern) || email.length >= 140) {
        return <LanguageToggle en={validationDisplayTextEn.invalidEmail} es={validationDisplayTextEs.invalidEmail}/>;
    }
    return undefined;
};
export const validateOptionalEmail = (email) => {
    if (email && (!email.match(emailPattern) || email.length >= 140)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidEmail} es={validationDisplayTextEs.invalidEmail}/>;
    }
    return undefined;
};
export const formatZipCode = (zipCode) => {
    zipCode = zipCode || '';
    return zipCode.replace(/[^\d]/g, '');
};
export const validateZipCode = (zipCode) => {
    if (!zipCode) {
        return <LanguageToggle en={validationDisplayTextEn.zipCodeRequired} es={validationDisplayTextEs.zipCodeRequired}/>;
    }
    else if (zipCode.length !== 5) {
        return <LanguageToggle en={validationDisplayTextEn.invalidZipCode} es={validationDisplayTextEs.invalidZipCode}/>;
    }
    return undefined;
};
export const validateEmployeeId = (id) => {
    if (!id || id == '') {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!id.match(employeeIdPattern)) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidEmployeeID} es={validationDisplayTextEs.invalidEmployeeID}/>);
    }
    return undefined;
};
export const validateUsername = (input) => {
    if (!input) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!input.match(usernamePattern)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidUsername} es={validationDisplayTextEs.invalidUsername}/>;
    }
    return undefined;
};
export const validateLegacyUsername = (input) => {
    if (!input) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!input.match(legacyUsernamePattern)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidUsername} es={validationDisplayTextEs.invalidUsername}/>;
    }
    return undefined;
};
export const compareEmails = (input, confirmEmailInput) => {
    if (!confirmEmailInput) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (input !== confirmEmailInput) {
        return (<LanguageToggle en={validationDisplayTextEn.confirmEmailNotMatching} es={validationDisplayTextEs.confirmEmailNotMatching}/>);
    }
    return undefined;
};
export const validatePassword = (input) => {
    if (!input) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!input.match(passwordPattern)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidPassword} es={validationDisplayTextEs.invalidPassword}/>;
    }
    return undefined;
};
export const numbersOnly = (input) => {
    if (input) {
        return input.replace(/[^\d]/g, '');
    }
    return undefined;
};
export const lettersAndNumbersOnly = (input) => {
    if (input) {
        return input.replace(/[^a-z0-9]/gi, '');
    }
    return undefined;
};
export const lettersNumbersAndSpacesOnly = (input) => {
    if (input) {
        return input.replace(/[^a-z0-9 ]/gi, '');
    }
    return undefined;
};
export const formatCurrency = (input) => {
    if (!input) {
        return '';
    }
    const currencyOnlyNumbers = input.replace(/[^0-9.]/g, '');
    if (!currencyOnlyNumbers) {
        return '';
    }
    const last = currencyOnlyNumbers[currencyOnlyNumbers.length - 1];
    const dot = last === '.' ? '.' : '';
    const [dollars, cents = ''] = currencyOnlyNumbers.split('.');
    const formattedCents = cents.slice(0, 2);
    const formattedDollars = parseFloat(dollars || '0').toLocaleString('en-US');
    return formattedCents ? `${formattedDollars}.${formattedCents}` : `${formattedDollars}${dot}`;
};
export const decimalDollar = (input) => {
    if (!input) {
        return '';
    }
    const formattedCurrency = formatCurrency(input);
    const [dollars, cents = ''] = formattedCurrency.split('.');
    const formattedCents = cents.length === 0 ? '00' : cents.length === 1 ? `${cents}0` : cents;
    return `${dollars}.${formattedCents}`;
};
export const validateCurrency = (str) => {
    if (!str) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    const regex = /[,\s]/g;
    const result = str.replace(regex, '');
    const amount = parseFloat(result);
    if (amount < 0 || amount > 999999999) {
        return 'Enter valid dollar amount greater than or equal to 0 and less than or equal to 999999999.';
    }
    return undefined;
};
export const validateCurrencyOptional = (str) => {
    if (!str) {
        return undefined;
    }
    const regex = /[,\s]/g;
    const result = str.replace(regex, '');
    const amount = parseFloat(result);
    if (amount < 0 || amount > 999999999) {
        return 'Enter valid dollar amount greater than or equal to 0 and less than or equal to 999999999.';
    }
    return undefined;
};
export const lettersOnly = (input) => {
    if (!input) {
        return undefined;
    }
    return input.replace(/[^a-zA-Z]/g, '');
};
export const validatePasswordsMatch = (input, input2) => {
    if (input !== input2) {
        return (<LanguageToggle en={validationDisplayTextEn.invalidPasswordMatch} es={validationDisplayTextEs.invalidPasswordMatch}/>);
    }
    return undefined;
};
export const validateBranch = (branchLocation) => {
    if (branchLocation) {
        if (!branchLocation.match(/^[0-9]{4}-[0-9]{8}$/)) {
            return 'Branch number is not valid';
        }
    }
    return undefined;
};
export const formatBranch = (branchLocation) => {
    if (!branchLocation) {
        return branchLocation;
    }
    const onlyNums = branchLocation.replace(/[^\d]/g, '');
    if (onlyNums.length <= 4) {
        return onlyNums;
    }
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 12)}`;
};
export const validatePostalCode = (usa, required) => (postalCode) => {
    return usa ? validateUsaPostalCode(postalCode, required) : validateCanadaPostalCode(postalCode, required);
};
function validateUsaPostalCode(postalCode, required) {
    if (!postalCode) {
        return required ? (<LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>) : undefined;
    }
    const usaPostalCodeRegex = /^\d{5}(?:[-]\d{4})?$/;
    return postalCode.match(usaPostalCodeRegex) ? undefined : (<LanguageToggle en={validationDisplayTextEn.invalidPostal} es={validationDisplayTextEs.invalidPostal}/>);
}
function validateCanadaPostalCode(postalCode, required) {
    if (!postalCode) {
        return required ? (<LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>) : undefined;
    }
    const canadaPostalCodeRegex = /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
    return postalCode.match(canadaPostalCodeRegex) ? undefined : (<LanguageToggle en={validationDisplayTextEn.invalidPostal} es={validationDisplayTextEs.invalidPostal}/>);
}
export const validateSSN = (input, errorMessage) => {
    if (input && !ssnPattern.test(input) && !input.match(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/)) {
        if (errorMessage === 'taxId') {
            return <LanguageToggle en={validationDisplayTextEn.invalidTaxId} es={validationDisplayTextEs.invalidTaxId}/>;
        }
        return <LanguageToggle en={validationDisplayTextEn.invalidSSN} es={validationDisplayTextEs.invalidSSN}/>;
    }
    return undefined;
};
export const validateSSNRequired = (input) => {
    if (!ssnPattern.test(input)) {
        return 'Enter a valid SSN';
    }
    return undefined;
};
export const optionalFieldCharacters = (input) => {
    if (!input) {
        return undefined;
    }
    return input.replace(/[^[-\w\s'$.]/g, '');
};
export const validateStreet = (input, field, required) => {
    if (required && !input) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (input && input.match(/[^a-zA-Z0-9\-\s'.,?#]/gi)) {
        return `${field} must consist of alpha[a-zA-Z], numeric[0-9], punctuation[.,?#'-], and space characters only`;
    }
    return undefined;
};
export const validateAddressCity = (input, required) => {
    if (required && !input) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (input && input.match(/[^a-zA-Z\s.'-]/gi)) {
        return `City must consist of alpha[a-zA-Z], punctuation [.'-], and space characters only`;
    }
    return undefined;
};
export const validateNotes = (input) => {
    const pattern = /^[a-zA-Z0-9\-\s\/@!#()_?'.,]+$/;
    if (!input) {
        return undefined;
    }
    if (!input.match(pattern)) {
        return 'Field accepts only English letters, numbers, hyphens, spaces, periods, commas, forward slash, apostrophes, exclamation, question mark, at symbol, pound parentheses, equals sign.';
    }
};
export const formatTitleCase = (input) => {
    if (!input) {
        return '';
    }
    const words = input.split(' ');
    return words
        .map(word => {
        if (!word) {
            return '';
        }
        const lowerCaseWord = word.toLowerCase();
        return `${lowerCaseWord.charAt(0).toUpperCase()}${lowerCaseWord.slice(1)}`;
    })
        .join(' ');
};
export const formatLowerCase = (input) => {
    if (!input) {
        return '';
    }
    const words = input.split(' ');
    return words
        .map(word => {
        if (!word) {
            return '';
        }
        return word.toLowerCase();
    })
        .join(' ');
};
export const isObjectEmpty = (obj) => {
    if (!obj) {
        return false;
    }
    return Object.entries(obj).filter(([key, value]) => !!value).length === 0;
};
export const phoneNumberValidator = (value) => {
    const blacklist = [
        '93',
        '375',
        '243',
        '236',
        '225',
        '86',
        '53',
        '964',
        '98',
        '850',
        '961',
        '231',
        '218',
        '95',
        '234',
        '40',
        '7',
        '252',
        '211',
        '963',
        '380',
        '967',
        '263',
    ];
    if (!value) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else {
        let formattedPhoneNumber = value.replace(/-/g, '').replace(/ /g, '');
        const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber, 'US');
        const countryCode = formattedPhoneNumber && formattedPhoneNumber.length > 10 && parsedPhoneNumber
            ? parsedPhoneNumber.countryCallingCode
            : '1';
        let isblacklistedcode = false;
        blacklist.forEach(element => {
            if (element === countryCode) {
                isblacklistedcode = true;
            }
        });
        if (isblacklistedcode) {
            return (<LanguageToggle en={validationDisplayTextEn.nonApprovedCountryError} es={validationDisplayTextEn.nonApprovedCountryError}/>);
        }
        if ((parsedPhoneNumber && !parsedPhoneNumber.isValid()) || formattedPhoneNumber.length < 10) {
            return (<LanguageToggle en={validationDisplayTextEn.phoneErrorMessage} es={validationDisplayTextEn.phoneErrorMessage}/>);
        }
    }
    return undefined;
};
export const globalPhoneNumberFormat = (value) => {
    if (!value) {
        return '';
    }
    const numbers = value.replace(/[^\d]/g, '');
    if (numbers.length <= 3) {
        return numbers;
    }
    else if (numbers.length <= 7) {
        return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    }
    else if (numbers.length <= 10) {
        return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
    }
    else if (numbers.length === 11) {
        return `+${numbers.slice(0, 1)} ${numbers.slice(1, 4)}-${numbers.slice(4, 7)}-${numbers.slice(7)}`;
    }
    else if (numbers.length === 12) {
        return `+${numbers.slice(0, 2)} ${numbers.slice(2)}`;
    }
    else if (numbers.length === 13) {
        return `+${numbers.slice(0, 3)} ${numbers.slice(3)}`;
    }
    else {
        return `+${numbers.slice(0, 3)} ${numbers.slice(3, 15)}`;
    }
};
export const validateClaimOrLeaveId = (input) => {
    if (input) {
        return input.replace(/[^\d]/g, '').substring(0, 10);
    }
    return undefined;
};
export const validateAlphaNumeric = (input) => {
    return input ? input.replace(/[^a-z0-9]/gi, '') : undefined;
};
export const formatCurrencyWithCurrencySign = (input, currency) => {
    const parseToString = String(input);
    if (parseToString) {
        const numbersOnly = parseToString.replace(/[^0-9.-]/g, '');
        const givenInput = parseFloat(numbersOnly);
        return !isNaN(givenInput) ? givenInput.toLocaleString('en-US', { style: 'currency', currency }) : undefined;
    }
    return undefined;
};
export const normalizePhone = (value) => {
    if (!value) {
        return value;
    }
    const numbers = value.replace(/[^\d]/g, '');
    if (numbers.length <= 3) {
        return numbers;
    }
    else if (numbers.length <= 7) {
        return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    }
    else if (numbers.length <= 10) {
        return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
    }
    else if (numbers.length === 11) {
        return `+${numbers.slice(0, 1)} ${numbers.slice(1, 4)}-${numbers.slice(4, 7)}-${numbers.slice(7)}`;
    }
    else if (numbers.length === 12) {
        return `+${numbers.slice(0, 2)} ${numbers.slice(2)}`;
    }
    else if (numbers.length === 13) {
        return `+${numbers.slice(0, 3)} ${numbers.slice(3)}`;
    }
    else {
        return `+${numbers.slice(0, 3)} ${numbers.slice(3, 15)}`;
    }
};
export const normalizeSpaces = (value) => {
    if (!value) {
        return value;
    }
    return value.replace(/\s+/g, '');
};
export const preventNonNumericEntry = (e) => {
    if (!/^[0-9 ()+-]+$/.test(e.key) && e.key.toLowerCase() !== 'enter') {
        e.preventDefault();
    }
};
export const validateLastFourSSN = (value, t) => {
    if (value && value.length !== 4) {
        return t({
            english: 'Please enter the last four digits of your SSN',
            spanish: 'Ingrese los últimos cuatro dígitos de su SSN',
        });
    }
    else {
        return isFieldRequired(value, t);
    }
};
export const isFieldRequired = (value, t) => {
    return !!!value ? t({ english: 'This field is required.', spanish: 'Este campo es obligatorio.' }) : undefined;
};
export const phoneMask = (value) => {
    if (value) {
        var numbers = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        if (numbers) {
            return !numbers[2] ? numbers[1] : '(' + numbers[1] + ') ' + numbers[2] + (numbers[3] ? '-' + numbers[3] : '');
        }
    }
};
