import React, { useEffect } from 'react';
import { Heading } from '@digitools/honest-react';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
const UnauthorizedPage = () => {
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.UNAUTHORIZED,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.UNAUTHORIZED.VIEW,
        });
    }, []);
    return (<div className={'mb-5'}>
      <Heading className={'mt-5'}>
        We're sorry! You are not authorized to view this page.
      </Heading>
      <p>
        Please go back and try again later. If the problem persists,
        contact 1-800-431-2958 for more information.
      </p>
    </div>);
};
export default UnauthorizedPage;
