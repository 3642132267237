import { Box, Col, Icon, Row, StepperContext } from '@digitools/honest-react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GrayTextDiv, GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import useAsDataContext from '../../hooks/useAsDataContext';
import styles from './review.scss';
import { phoneMask } from 'src/utils/validationUtils';
import useTranslate from 'src/hooks/useTranslate';
import { ReviewText } from '../../constants/ReviewText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { EditReviewField } from './ReviewField';
import { DivBlock, GrayBox, SectionHeading, StyledRow } from '../../components/LayoutComponents';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { AS_COUNTRIES } from '../../constants/Constants';
const ScalableBorderlessBox = styled(Col) `
  @media only screen and (max-width: 765px) {
    width: 50%;
    margin-left: 0;
  }
`;
const HeadingCol = styled(Col) `
  display: flex;
`;
const EditCol = styled(Col) `
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize !important;
  @media only screen and (max-width: 500px) {
    margin-top: -2.5rem;
}
`;
const StyledIcon = styled(Icon) `
    font-size: 3rem; 
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    margin-botton: -0.5rem;
    @media only screen and (max-width: 500px) {
      font-size: 2.5rem;
      margin-top: -1rem;
  }
  `;
const pStyle = {
    lineHeight: '2.25rem',
};
const spacerDiv = {
    marginTop: '-0.2rem',
};
const ReviewAboutYou = ({ displayButton }) => {
    const { claimantData } = useAsDataContext();
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { experience } = useExperience();
    const pageTitleLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(ReviewText.aboutYou.aboutClaimantEE) : t(ReviewText.aboutYou.aboutClaimantER));
    // @ts-ignore
    const isCountryUS = claimantData && claimantData.empCtryCde === 'USA';
    // @ts-ignore
    const isCountryCanada = claimantData && claimantData.empCtryCde === 'CAN';
    // @ts-ignore
    const selectedCountry = AS_COUNTRIES.find(country => country.value === claimantData?.empCtryCde);
    return (<>
      <Box className={'mt-4'} data-testid='aboutYouReview'>
        <StyledRow>
          <HeadingCol lg={10} md={10} sm={10} xs={10} className={styles.heading_col}>
            <DivBlock className={'d-flex align-items-center'}>
              <StyledIcon name={'personal-info'} size='large' color={'primary'}/>
              <SectionHeading elemType={'h3'}>{pageTitleLabel}</SectionHeading>
            </DivBlock>
          </HeadingCol>
          {displayButton &&
            <EditCol lg={2} md={2} sm={2} xs={2} className={styles.edit_col}>
              <EditReviewField data-testid='review-edit-field' onClick={() => { stepper.edit(1); }}/>
            </EditCol>}
        </StyledRow>

        <GrayBox>{t(ReviewText.personalInformation.personalInfoHeading).toUpperCase()}</GrayBox>
        <Row>
          <ScalableBorderlessBox md={4} className={styles.leftWidthStyling}>
            <GrayTextDiv data-testid='empFirstName'>
              {t(ReviewText.personalInformation.firstNameQuestionLabel)}
              <p style={pStyle}>{claimantData?.empFirstName?.trim() ? claimantData?.empFirstName : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </ScalableBorderlessBox>
          <ScalableBorderlessBox md={3} className={styles.rightWidthStyling}>
            <GrayTextDiv data-testid='empMiName'>
              {t(ReviewText.personalInformation.middleInitialQuestionLabel)}
              <p style={pStyle}>{claimantData?.empMiName?.trim() ? claimantData?.empMiName : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </ScalableBorderlessBox>
          <Col md={5}>
            <GrayTextDiv data-testid='empLastName'>
              {t(ReviewText.personalInformation.lastNameQuestionLabel)}
              <p style={pStyle}>{claimantData?.empLastName?.trim() ? claimantData?.empLastName : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
          <Col md={4}>
            <GrayTextDiv data-testid='empBthDte'>
              {t(ReviewText.personalInformation.dateOfBirthQuestionLabel)}
              <p style={pStyle}>{claimantData?.empBthDte?.trim() ? claimantData?.empBthDte : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
        </Row>

        <GrayBox>{t(ReviewText.contactInformation.contactInfoHeading).toUpperCase()}</GrayBox>
        <Row>
          <Col md={4}>
            <GrayTextDiv data-testid='empPhoneNum'>
              {t(ReviewText.contactInformation.preferredPhoneQuestionLabel)}
              <p style={pStyle}>{claimantData?.empPhoneNum?.trim() ? phoneMask(claimantData?.empPhoneNum) : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
          <Col md={8}>
            <GrayTextDiv data-testid='empEmail'>
              {t(ReviewText.contactInformation.preferredEmailAddressQuestionLabel)}
              <p style={pStyle}>{claimantData?.empEmail?.trim() ? claimantData?.empEmail : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
        </Row>

        <GrayBox>{t(ReviewText.residentialInformation.residentialInfoHeading).toUpperCase()}</GrayBox>

        <Row>
          <Col md={4}>
            <GrayTextDiv>
              {t(ReviewText.residentialInformation.address1)}
              <p style={pStyle}>{claimantData?.empLine1Addr?.trim() ? claimantData?.empLine1Addr : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
          <Col md={8}>
            <GrayTextDiv>
              {t(ReviewText.residentialInformation.address2)}
              <p style={pStyle}>{claimantData?.empLine2Addr?.trim() ? claimantData?.empLine2Addr : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
          <Col md={4}>
            <GrayTextDiv>
              {t(ReviewText.countryLabel)}
              <p style={pStyle}>{selectedCountry?.label}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
          {(isCountryUS || isCountryCanada) && (<ScalableBorderlessBox md={2} className={styles.leftWidthStyling}>
              <GrayTextDiv>
                {isCountryUS && t(ReviewText.stateLabel)}
                {isCountryCanada && t(ReviewText.provinceLabel)}
                <p style={pStyle}>{claimantData?.empState?.trim() ? claimantData?.empState?.trim() : '-'}</p>
                <GraySpacerDiv style={spacerDiv}/>
              </GrayTextDiv>
            </ScalableBorderlessBox>)}
          <ScalableBorderlessBox md={3} className={styles.rightWidthStyling}>
            <GrayTextDiv>
              {t(ReviewText.residentialInformation.cityQuestionLabel)}
              <p style={pStyle}>{claimantData?.empCity?.trim() ? claimantData?.empCity : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </ScalableBorderlessBox>
          <Col md={3}>
            <GrayTextDiv>
              {t(ReviewText.residentialInformation.zipQuestionLabel)}
              <p style={pStyle}>{claimantData?.empZip?.trim() ? claimantData?.empZip : '-'}</p>
              <GraySpacerDiv style={spacerDiv}/>
            </GrayTextDiv>
          </Col>
        </Row>
      </Box>
    </>);
};
export default ReviewAboutYou;
