import React, { useContext } from 'react';
import { Row, Col, TextInput } from '@digitools/honest-react';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { lettersOnly, isNotEmpty, formatPhone } from '../../../utils/validationUtils';
import { useIsLci } from '../../../providers/IsLciProvider';
const BdmModalContentEstate = ({ beneficiaryCategory, beneficiaryIndex, beneficiary }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const isLci = useIsLci();
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    return (<>
      <Row data-private={true}>
        <Col>
          <TextInput field={`${idPrefix}firstName`} label={displayText.firstName} type={'text'} mask={BdmBeneficiaryUtils.formatName} maxLength={35} validateOnBlur={true} validate={isNotEmpty} initialValue={beneficiary ? beneficiary.firstName : undefined} data-private={true}/>
        </Col>
        <Col>
          <TextInput field={`${idPrefix}lastName`} label={displayText.lastName} type={'text'} mask={BdmBeneficiaryUtils.formatName} maxLength={35} validateOnBlur={true} validate={isNotEmpty} initialValue={beneficiary ? beneficiary.lastName : undefined} data-private={true}/>
        </Col>
        <Col>
          <TextInput style={{ width: '3.75rem', minWidth: '2.5rem' }} field={`${idPrefix}middleInitial`} label={displayText.middleInitial} type={'text'} maxLength={1} mask={lettersOnly} initialValue={beneficiary ? beneficiary.middleInitial : undefined} data-private={true}/>
        </Col>
      </Row>
      {isLci && (<Row>
          <Col>
            <TextInput field={`${idPrefix}phoneNumber`} label={displayText.phoneNumber} type={'text'} mask={formatPhone}/>
          </Col>
          <Col>
            <TextInput field={`${idPrefix}emailAddress`} label={'Email Address (optional)'} type={'text'}/>
          </Col>
        </Row>)}
    </>);
};
export default BdmModalContentEstate;
