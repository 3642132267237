import React from 'react';
import useEoiContext from '../../../hooks/useEoiContext';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../hooks/useTranslate';
const EmployerMessage = () => {
    const { customerConfig } = useEoiContext();
    const { t } = useTranslate();
    if (customerConfig && customerConfig.confirmationTitle && customerConfig.confirmationBody) {
        return (<>
        <Heading theme={themes.lincoln} elemType={'h3'} color={'grayDark'} style={{ marginBottom: '0.5rem', marginTop: '2.5rem' }} data-testid={'employer-message-title'}>
            {t(customerConfig.confirmationTitle)}
        </Heading>
        <p data-testid={'employer-message-body'}>{t(customerConfig.confirmationBody)}</p>
      </>);
    }
    else {
        return <></>;
    }
};
export default EmployerMessage;
