import React from 'react';
import { Row, Col, Heading, themes } from '@digitools/honest-react';
import GetHelpModal from '../GetHelpModal';
const ReportFieldHeader = () => {
    return (<>
      <Row style={{ marginTop: '0.2rem' }}>
        <Col lg={8} md={8}>
          <Heading elemType={'h2'} style={{ paddingTop: '20px', paddingBottom: '2rem' }} color={'primary'} theme={themes.lincoln}>
            {'Include the following fields in my report: '}
          </Heading>
        </Col>
        <Col>
          <GetHelpModal headerText={'Create My Report - Help Guide'}/>
        </Col>
      </Row>
    </>);
};
export default ReportFieldHeader;
