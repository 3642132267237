import React from 'react';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { ScalableBorderlessGrayBox, ScalableDiv } from '../../../../../../components/Formatting/StyledComponents';
import { valueMatchRequired } from '../../../../utils/FormFieldValidations';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import useTranslate from '../../../../../../hooks/useTranslate';
import { format } from 'date-fns';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import { Icon } from '@digitools/honest-react';
const ESignatureContentGeneric = ({ valid, field, label, required }) => {
    const { t } = useTranslate();
    const dateText = () => {
        return `${t(EoiGeneralText.date)}: ${format(new Date(), 'MM/dd/yyyy')}`;
    };
    return (<ScalableDiv data-testid={`esignature-content-${field}`}>
      <Label theme={themes.lincoln} aria-label={`${label} signature`} style={{ marginBottom: '0.5rem', paddingLeft: '1.5rem' }}>
        {label}
      </Label>
      <ScalableBorderlessGrayBox>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <TextInput theme={themes.lincoln} field={field} label={t(ReviewAndSignText.firstLastName)} placeholder={t(ReviewAndSignText.firstLastName)} aria-label={'E-Signature'} data-testid={'agent-signature'} data-private={true} 
    //@ts-ignore FIXME: type error
    validate={required ? (value) => valueMatchRequired(value, label) : null}/>
            {valid && (<Icon name={'success'} color={'secondary'} theme={themes.lincoln} size={'medium'} style={{ paddingTop: '0.4375rem', marginLeft: '0.5rem' }}/>)}
          </div>
          <span style={{ fontSize: '0.875rem', marginTop: '-1rem' }}>
            {' '}
            {/* Need the negative margin here because the styles you pass to text input don't apply to the container that the input is embedded into.*/}
            {dateText()}
          </span>
        </div>
      </ScalableBorderlessGrayBox>
    </ScalableDiv>);
};
export default ESignatureContentGeneric;
