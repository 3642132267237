import React from 'react';
import useGet from '../../../hooks/useGet';

const useOfficeInfo = () => {
  const url = '/status-service/customer/officeInfo';

  const { error, loading, data, get } = useGet({
    url,
    noInitial: true,
  });

  return {
    officeInfo: data,
    loading,
    error,
    getOfficeInfo: get,
  };
};

export default useOfficeInfo;
