export const ReviewAccommodationDetailsText = {
    categoryTypeEE: {
        english: 'Category Type 0',
        spanish: 'Categoría Tipo 0',
    },
    descriptionEE: {
        english: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra.',
        spanish: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra.',
    },
    expectedDurationEE: {
        english: 'Expected Duration',
        spanish: 'Duración esperada',
    },
    temporaryTextEE: {
        english: 'Temporary',
        spanish: 'Temporaria',
    },
    desiredStartDateEE: {
        english: 'Desired Start Date',
        spanish: 'Fecha de inicio deseada',
    },
    startDateEE: {
        english: '01/05/2023',
        spanish: '01/05/2023',
    },
    alreadyReportedEE: {
        english: 'Relates to a condition already reported',
        spanish: 'Se relaciona con una condición ya reportada',
    },
    yesTextEE: {
        english: 'Yes',
        spanish: 'Sí',
    },
    willReturnToWorkEE: {
        english: 'Will you be returning to work after being out on leave, or will you be staying at work?',
        spanish: '¿Regresará al trabajo después de estar de baja o se quedará en el trabajo?',
    },
    returnToWorkTextEE: {
        english: 'Returning To Work',
        spanish: 'Regresando al trabajo',
    },
    anticipatedEndDate: {
        english: 'Anticipated End Date',
        spanish: 'Fecha prevista de finalización',
    },
    disclaimerText: {
        english: 'I acknowledge that the information provided is correct, and will be shared with my employer.',
        spanish: 'Reconozco que la información dada es correcta y se compartirá con mi empleador.',
    },
    AccommodationRequestsheading: {
        english: 'Accommodation request(s)',
        spanish: 'Solicitud(es) de alojamiento',
    }
};
