import React from 'react';
import styles from './App.scss';
import GenericErrorBoundary from 'src/components/errorPages/GenericErrorBoundary';
import FixedStatusRoutes from '../routes/FixedStatusRoutes';
const App = () => (<div className={styles.appContainer} data-public={true}>
    <GenericErrorBoundary>
      <div className={`${styles.routeContainer}`}>
        {/* <Routes /> */}
        <FixedStatusRoutes />
      </div>
    </GenericErrorBoundary>
  </div>);
export default App;
