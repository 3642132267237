import axios from 'axios';
export async function getActiveMaternityClaimNum(authHeader) {
    return axios.get(`/rab-service/activeMaternityClaimNum`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
// TODO this service call should be replaced with newer service endpoint which only returns data from config service
export async function getLanguageFile(authHeader, language) {
    return axios.get(`/rab-service/languageFile/${language}`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export async function submit(authHeader, postBody) {
    return axios.post(`/rab-service/submit`, postBody, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });
}
export default {
    getActiveMaternityClaimNum,
    getLanguageFile,
    submit,
};
