import React, { useEffect } from 'react';
import { ErrorPage } from 'status/components/pages';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';

const NotFound = () => {
  const { trackView } = useTealium();

  useEffect(() => {
    trackView({
      page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.PAGE_NOT_FOUND,
      page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.PAGE_NOT_FOUND.VIEW,
    });
  }, []);

  return <ErrorPage message="Sorry, we couldn't find that page" />;
};

export default NotFound;
