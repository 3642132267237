import { userNameInvalidMessageMap, getSpecificRequiredFieldMap } from './errorDictionary';
import { isNotEmpty, isValidUsername } from '../../utils/fieldValidators';
import useValidators from './useValidators';
import { Language } from '../../types/Language';
function useValidUsername(isRequired = false) {
    const errorMessageMap = [userNameInvalidMessageMap];
    const validators = [isValidUsername];
    // if the field is required make it the first validation to run
    if (isRequired) {
        errorMessageMap.unshift(getSpecificRequiredFieldMap({ [Language.ENGLISH]: 'Username', [Language.SPANISH]: 'Nombre de usuario' }));
        validators.unshift(isNotEmpty);
    }
    return useValidators(errorMessageMap, validators);
}
export default useValidUsername;
