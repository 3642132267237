export const reviewGettingStartedText = {
    reviewGettingStartPageHeading: {
        english: 'Getting started',
        spanish: 'Comenzar',
    },
    employeeIdLabel: {
        english: 'Employee ID',
        spanish: 'ID de empleado',
    },
    ssnLabel: {
        english: 'SSN',
        spanish: 'SSN',
    },
    reviewGettingStartSubHeading: {
        english: 'IDENTITY VERIFICATION',
        spanish: 'VERIFICACIÓN DE IDENTIDAD',
    },
};
