import React from 'react';
const InitialInfoText = {
    header: {
        english: 'What do I need to know before starting my application?',
        spanish: '¿Qué necesito saber antes de comenzar mi aplicación?',
    },
    collapseHeader: {
        english: 'Information you will need, quick tips and saving your progress',
        spanish: 'Información que necesitará, consejos rápidos y guardar su progreso',
    },
    applicantCoverageHeader: {
        english: 'What you will need for each applicant applying for coverage',
        spanish: 'Lo que necesitará para cada solicitante que solicite cobertura',
    },
    name: {
        english: 'First, last name',
        spanish: 'Nombre y apellido',
    },
    dob: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento',
    },
    height: {
        english: 'Height',
        spanish: 'Altura',
    },
    weight: {
        english: 'Weight',
        spanish: 'Peso',
    },
    social: {
        english: 'Social security number',
        spanish: 'Número de seguridad social',
    },
    diagnosis: {
        english: 'Diagnosis of any applicants that have a disease or condition',
        spanish: 'Diagnóstico de cualquier solicitante que tenga una enfermedad o afección',
    },
    quickTipsHeader: {
        english: 'Quick tips and saving your progress',
        spanish: 'Consejos rápidos y guardar su progreso',
    },
    answerAll: {
        english: 'All questions will need to be answered to complete the application process.',
        spanish: 'Todas las preguntas deberán ser respondidas para completar el proceso de solicitud.',
    },
    saveForLater: {
        english: 'Your progress will be saved as you click \'Continue\' and move between the steps in the application. You can also save partial progress within a step, if you wish to come back at a later time to complete your application. Please check with your employer if you have questions about the time to complete filling out the application.',
        spanish: 'Su progreso se guardará cuando haga clic en \'Continuar\' y se mueva entre los pasos de la aplicación. También puede guardar el progreso parcial dentro de un paso, si desea volver más tarde para completar su solicitud. Consulte con su empleador si tiene preguntas sobre el tiempo para completar la solicitud.',
    },
    timeOut: {
        english: <>For added security this site has a <em><strong>time-out feature</strong></em>. To protect your personal information, your session will automatically end after 20 minutes of inactivity.</>,
        spanish: <>Para mayor seguridad, este sitio tiene una <em><strong>función de tiempo de espera</strong></em>función de tiempo de espera. Para proteger su información personal, su sesión finalizará automáticamente después de 20 minutos de inactividad.</>,
    },
};
export default InitialInfoText;
