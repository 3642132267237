export const ResidentialInformationText = {
    residentialInfoHeading: {
        english: 'Residential information',
        spanish: 'Información residencial',
    },
    address1: {
        english: 'Residential address 1',
        spanish: 'Domicilio 1',
    },
    address2: {
        english: 'Residential address 2',
        spanish: 'Domicilio 2',
    },
    countryCodeQuestionLabel: {
        english: 'Select country',
        spanish: 'Seleccione un país',
    },
    stateQuestionLabel: {
        english: 'Select state',
        spanish: 'Seleccione un estado',
    },
    provinceQuestionLabel: {
        english: 'Select province',
        spanish: 'Seleccionar provincia',
    },
    cityQuestionLabel: {
        english: 'Residential city',
        spanish: 'Ciudad de residencia',
    },
    zipQuestionLabel: {
        english: 'Zip code',
        spanish: 'Código postal',
    }
};
