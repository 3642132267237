import { Box, Col, Heading, Icon, StepperContext, themes } from '@digitools/honest-react';
import React, { useContext } from 'react';
import AddRequest from './AddRequest';
import ReviewConditionsCircumstance from './ReviewConditionsCircumstance';
import { EditReviewField } from './ReviewField';
import useTranslate from 'src/hooks/useTranslate';
import AboutAccommodationText from '../../constants/AboutAccommodationText';
import styled from 'styled-components';
import useAsDataContext from '../../hooks/useAsDataContext';
import { DivBlock, GrayBox, headingStyle, SectionHeading, StyledRow } from '../../components/LayoutComponents';
import { getColorCode } from '../../utils/AsDataUtils';
import { ReviewAccommodationDetailsText } from '../../constants/ReviewAccommodationDetailsText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import styles from './review.scss';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const AccommodationDetails = ({ displayButton, }) => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { accommodationRequestDataArray } = useAsDataContext();
    const { experience } = useExperience();
    const pageTitle = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(AboutAccommodationText.aboutAccommodationEE)
        : t(AboutAccommodationText.aboutAccommodationER));
    const handleEditBtnClick = (e) => {
        e.preventDefault();
        stepper.edit(1);
        window.scrollTo(0, 0);
    };
    const bottomSpacing = {
        marginBottom: '50px',
    };
    const EditCol = styled(Col) `
    display: flex;
    justify-content: flex-end;
    margin-top: -1rem;
    text-transform: capitalize !important;
    @media only screen and (max-width: 500px) {
      margin-top: -4rem;
    }
  `;
    const HeadingCol = styled(Col) `
    display: flex;
  `;
    const StyledIcon = styled(Icon) `
    font-size: 2rem;
    margin-right: 0.5rem;
    margin-top: 0rem;
    margin-botton: -0.5rem;
    @media only screen and (max-width: 500px) {
      margin-top: -3.5rem;
    }
  `;
    return (<Box className={'mt-4'} data-testid='review-accommodation-details' style={bottomSpacing}>
      <StyledRow>
        <HeadingCol lg={10} md={10} sm={10} xs={10} className={styles.heading_col}>
          <DivBlock className={'d-flex align-items-center'}>
            <StyledIcon name={'condition'} size='large' color={'primary'}/>
            <SectionHeading elemType={'h3'} color='black' theme={themes.lincoln}>
              {pageTitle}
            </SectionHeading>
          </DivBlock>
        </HeadingCol>
        {displayButton && (<EditCol lg={2} md={2} sm={2} xs={2} className={styles.edit_col}>
            <EditReviewField data-testid='review-edit-field' onClick={handleEditBtnClick}/>
          </EditCol>)}
        <Col sm={10} md={12} className={'p-0'}>
          <ReviewConditionsCircumstance />
          <div>
            <GrayBox>
              <Heading style={headingStyle} elemType={'h4'} elemStyle='h4' color={'grayDark'} className={'text-decoration-none text-uppercase'}>
                {t(ReviewAccommodationDetailsText.AccommodationRequestsheading)}
              </Heading>
            </GrayBox>
            {accommodationRequestDataArray?.map(data => {
            let borderColor = getColorCode(data?.id);
            return <AddRequest key={data.id} color={borderColor} count={data.id} accommodationData={data}/>;
        })}
          </div>
        </Col>
      </StyledRow>
    </Box>);
};
export default React.memo(AccommodationDetails);
