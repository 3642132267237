export var CrsActionEnum;
(function (CrsActionEnum) {
    CrsActionEnum[CrsActionEnum["START_LOADING"] = 0] = "START_LOADING";
    CrsActionEnum[CrsActionEnum["STOP_LOADING"] = 1] = "STOP_LOADING";
    CrsActionEnum[CrsActionEnum["FILTER_PAGE_ACTION"] = 2] = "FILTER_PAGE_ACTION";
    CrsActionEnum[CrsActionEnum["REPORT_STATUS_SELECTION"] = 3] = "REPORT_STATUS_SELECTION";
    CrsActionEnum[CrsActionEnum["REPORT_DATE_PARAM_SELECTION"] = 4] = "REPORT_DATE_PARAM_SELECTION";
    CrsActionEnum[CrsActionEnum["REPORT_FROM_DATE_SELECTION"] = 5] = "REPORT_FROM_DATE_SELECTION";
    CrsActionEnum[CrsActionEnum["REPORT_TO_DATE_SELECTION"] = 6] = "REPORT_TO_DATE_SELECTION";
    CrsActionEnum[CrsActionEnum["REPORT_FILTER_BY_CATEGORY"] = 7] = "REPORT_FILTER_BY_CATEGORY";
    CrsActionEnum[CrsActionEnum["DEFAULT_ACTION"] = 8] = "DEFAULT_ACTION";
    CrsActionEnum[CrsActionEnum["FIELD_SELECTION"] = 9] = "FIELD_SELECTION";
    CrsActionEnum[CrsActionEnum["CONFIG_DATA_UPDATE"] = 10] = "CONFIG_DATA_UPDATE";
    CrsActionEnum[CrsActionEnum["UPDATE_RUN_REPORT_ID"] = 11] = "UPDATE_RUN_REPORT_ID";
    CrsActionEnum[CrsActionEnum["RUN_REPORT_SPINNER"] = 12] = "RUN_REPORT_SPINNER";
})(CrsActionEnum || (CrsActionEnum = {}));
export const startLoading = () => ({
    type: CrsActionEnum.START_LOADING,
});
export const stopLoading = () => ({
    type: CrsActionEnum.STOP_LOADING,
});
export const filterPageAction = (payload) => {
    return {
        type: CrsActionEnum.FILTER_PAGE_ACTION,
        payload,
    };
};
export const handleReportByCategory = (payload) => {
    return {
        type: CrsActionEnum.REPORT_FILTER_BY_CATEGORY,
        payload,
    };
};
export const fieldSelection = (payload) => {
    return {
        type: CrsActionEnum.FIELD_SELECTION,
        payload,
    };
};
export const custConfigDataUpdate = (payload) => {
    return {
        type: CrsActionEnum.CONFIG_DATA_UPDATE,
        payload,
    };
};
export const runReportId = (payload) => {
    return {
        type: CrsActionEnum.UPDATE_RUN_REPORT_ID,
        payload,
    };
};
export const showRunReportSpinner = (payload) => ({
    type: CrsActionEnum.RUN_REPORT_SPINNER,
    payload,
});
