import React from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import { ConfirmationText } from '../../../i18n/ConfirmationText';
const ConfirmationMessage = (props) => {
    const { t } = useTranslate();
    const message = props.approvalMessage && (props.approvalMessage === ConfirmationText.fullyApproved || props.approvalMessage === ConfirmationText.partiallyApproved)
        ? t(ConfirmationText.approvedConfirmationMessage)
        : t(ConfirmationText.otherConfirmationMessage);
    return <p style={{ marginBottom: '0.5rem', marginTop: '2.5rem' }} data-testid={'confirmation-message'}>{message}</p>;
};
export default ConfirmationMessage;
