import axios from 'axios';
import { UnauthenticatedError } from '../types/UnauthenticatedError';
import { UnauthorizedError } from '../types/UnauthorizedError';
import { NotFoundError } from '../types/NotFoundError';
export const fetchCustomers = async (authHeader) => {
    const response = await axios.get('/mlp-ui-service/services/v1/customers', {
        headers: {
            Authorization: authHeader,
        },
    });
    const status = response.status;
    if (status === 200) {
        return response.data;
    }
    else if (status === 401) {
        throw new UnauthenticatedError('Invalid Access Token');
    }
    else if (status === 403) {
        throw new UnauthorizedError('User Unauthorized');
    }
    else if (status === 404) {
        throw new NotFoundError('Customer Account Not Found');
    }
    else {
        throw new Error('Unable to retrieve customers');
    }
};
