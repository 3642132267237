import React, { useEffect, useRef, useState } from 'react';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import ToolTip from '@digitools/honest-react/dist/lib/components/ToolTip';
import ToggleSwitch from '@digitools/honest-react/dist/lib/components/ToggleSwitch';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import styled from 'styled-components';
import useTranslate from '../../../../hooks/useTranslate';
import { validatePhone, isNotEmpty, formatPhone } from '../../../../utils/validationUtils';
import { resendConfirmation } from '../../../../api/CommPrefApi';
import useAuthentication from '../../../../hooks/useAuthentication';
import CommPrefDisplayText from './language/CommPrefDisplayText';
import DeliveryMethodRadioButtons from './DeliveryMethodRadioButtons';
;
const GrayDiv = () => {
    return (<div style={{ backgroundColor: '#f2f2f2', height: '0.25rem', marginTop: 'auto', marginBottom: 0 }}/>);
};
const ColumnDiv = styled('div') `
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;
const ContainerDiv = styled('div') `
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: ${undefined};
  }
`;
const FieldDiv = styled('div') `
  width: 45%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const FieldContainer = styled('div') `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const CommunicationPreferencesBox = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [displayData, setDisplayData] = useState(props.data ? true : false);
    const [returnToWorkToggle, setReturnToWorkToggle] = useState(false);
    const toggleRef = useRef();
    const setToggleRef = useRef(() => { });
    const { t } = useTranslate();
    const { user, authHeader } = useAuthentication();
    useEffect(() => {
        setIsEditing(false);
        if (props.data) {
            setDisplayData(true);
        }
    }, [props.data]);
    const timeZones = [
        {
            label: t({ english: 'Eastern', spanish: 'Este' }),
            value: 'EST',
        },
        {
            label: t({ english: 'Central', spanish: 'Centro' }),
            value: 'CST',
        },
        {
            label: t({ english: 'Mountain', spanish: 'Montaña' }),
            value: 'MST',
        },
        {
            label: t({ english: 'Pacific', spanish: 'Pacifico' }),
            value: 'PST',
        },
        {
            label: t({ english: 'Alaska', spanish: 'Alaska' }),
            value: 'AKST',
        },
        {
            label: t({ english: 'Hawaii-Aleutian', spanish: 'Hawái y Aleutiana' }),
            value: 'HAST',
        },
    ];
    const heading = props.type === 'notifications' ? t(CommPrefDisplayText.claimOrLeave) : t(CommPrefDisplayText.returnToWork);
    const resendVerification = async () => {
        try {
            const payload = {
                phoneNumber: props.data.phoneNumber.length === 10 ? `1${props.data.phoneNumber}` : props.data.phoneNumber,
                method: props.data.method,
                recipient: user.firstName + ' ' + user.lastName,
            };
            await resendConfirmation(payload, authHeader());
            props.setResentVerification(true);
        }
        catch (error) {
            props.setIsError(true);
        }
    };
    const StatusPending = () => {
        const toolText = (<span style={{ fontSize: '1rem' }}>
        {t(CommPrefDisplayText.ourRecordsIndicate)}
        <Button type={'button'} theme={themes.lincoln} style={{ whiteSpace: 'normal', padding: 0, textAlign: 'left', height: 'auto' }} onClick={resendVerification} data-testid={'resend-verification-button'}>
          {t(CommPrefDisplayText.resendItNow)}
        </Button>
      </span>);
        return (<span style={{ color: themes.lincoln.palette.secondary, fontSize: '0.75rem', margin: 'auto 0 auto auto' }}>
        <span style={{ marginRight: '0.25rem' }}>{t(CommPrefDisplayText.statusPending)}</span>
        <ToolTip iconName={'error'} iconColor={'secondary'} theme={themes.lincoln} toolText={toolText} position={'bottom'} iconSize={'xs'} showOnHover={false} style={{ pointerEvents: 'auto' }} data-testid={'status-pending-tooltip'}/>
      </span>);
    };
    const CommunicationPreferencesFields = () => {
        if (isEditing) {
            return (<FieldContainer>
          <FieldDiv>
            <TextInput theme={themes.lincoln} field={`${props.type}PhoneNumber`} label={t(CommPrefDisplayText.phoneNumber)} aria-label={t(CommPrefDisplayText.phoneNumber)} validate={validatePhone} mask={formatPhone} placeholder={'XXX-XXX-XXXX'} validateOnBlur={true} data-testid={`${props.type}-contact-info-phone-number`}/>
          </FieldDiv>
          <FieldDiv>
            <SelectInput theme={themes.lincoln} field={`${props.type}Timezone`} label={t(CommPrefDisplayText.timeZone)} aria-label={t(CommPrefDisplayText.timeZone)} options={timeZones} validate={isNotEmpty} validateOnBlur={true} data-testid={`${props.type}-contact-info-time-zone`}/>
          </FieldDiv>
        </FieldContainer>);
        }
        else if (displayData && props.data) {
            return <span data-testid={`${props.type}-comm-preferences-data-display`}>{formatPhone(props.data.phoneNumber)}, {props.data.timeZone}</span>;
        }
        return <></>;
    };
    const editButtonOnClick = () => {
        if (isEditing) {
            setIsEditing(false);
            setReturnToWorkToggle(false);
            if (!props.data) {
                setToggleRef.current(false);
            }
            setDisplayData(true);
        }
        else {
            setIsEditing(true);
            setToggleRef.current(true);
            setReturnToWorkToggle(true);
        }
    };
    const toggleOnClick = () => {
        if (isEditing) {
            setIsEditing(false);
        }
        setReturnToWorkToggle(toggleRef.current === false);
        if (props.data) {
            if (toggleRef.current === true) {
                setDisplayData(false);
            }
            else if (toggleRef.current === false) {
                setDisplayData(true);
            }
        }
        else {
            if (toggleRef.current === true) {
                setIsEditing(false);
            }
            else if (toggleRef.current === false) {
                setIsEditing(true);
            }
        }
    };
    return (<>
      <Box theme={themes.lincoln} type={'header'} style={{ backgroundColor: '#f2f2f2', borderColor: themes.lincoln.palette.grayLight, borderBottom: 'none', fontSize: '1rem', padding: '0.5rem' }} data-testid={`${props.type}-header-box`}>
        {heading}
      </Box>
      <Box theme={themes.lincoln} style={{ borderColor: themes.lincoln.palette.grayLight, borderTop: 'none' }} data-testid={`${props.type}-content-box`}>
        <ContainerDiv>
        <ColumnDiv width={'25%'} data-testid={`${props.type}-toggle`}>
            <b style={{ marginBottom: '0.5rem' }}>{t(CommPrefDisplayText.notifications)}</b>
            <div style={{ marginBottom: '0.5rem', display: 'flex', flexDirection: 'row' }}>
              {/* @ts-ignore //FIXME: type error with valueRef & toggleRef */}
              <ToggleSwitch label={'toggle'} field={`${props.type}Toggle`} initialValue={props.data ? true : false} valueRef={toggleRef} setValueRef={setToggleRef} onClick={toggleOnClick}/>
              {props.data && props.data.status === 'new' && <StatusPending />}
            </div>
            <GrayDiv />
          </ColumnDiv>
          <ColumnDiv width={'25%'} data-testid={`${props.type}-delivery-method`}>
            <b style={{ marginBottom: '0.5rem' }}>{t(CommPrefDisplayText.deliveryMethod)}</b>
            {props.type === 'notifications'
            ? <span style={{ color: returnToWorkToggle ? undefined : themes.lincoln.palette.gray }}>{t(CommPrefDisplayText.textNotification)}</span>
            : <DeliveryMethodRadioButtons toggleOn={returnToWorkToggle} deliveryMethod={props.data && props.data.method}/>} 
            <GrayDiv />
          </ColumnDiv>
          <ColumnDiv width={'45%'} data-testid={`${props.type}-contact-info`}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
              <b>{t(CommPrefDisplayText.contactInfo)}</b>
              <Button buttonType={'text'} theme={themes.lincoln} type={'button'} style={{ margin: '0 0 0 0.25rem', padding: 0, height: 'auto' }} onClick={editButtonOnClick} data-testid={`${props.type}-edit`}>
                <Icon name={'edit'} size={'xs'} color={'secondary'}/> {t(CommPrefDisplayText.edit)}
              </Button>
            </div>
            <CommunicationPreferencesFields />
            <GrayDiv />
          </ColumnDiv>
        </ContainerDiv>
      </Box>
    </>);
};
export default CommunicationPreferencesBox;
