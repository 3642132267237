import { CompositeTable, Link } from '@digitools/honest-react';
import React from 'react';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useTealium from '../../../hooks/useTealium';
import { getFormattedEmpName, getFormattedNameEmpId } from '../api';
import EmployeeOverviewLink from './EmployeeOverviewLink';
const ReporteesLink = (props) => {
    const { trackEvent } = useTealium();
    if (props.item.hasReports === 'Y') {
        return (<Link onClick={() => {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.REPORTEE,
                    event_name: 'Reportee Link'
                });
                props.getHierarchy(props.item.empId);
            }} data-private={true} data-testid={`user-${props.idx}-link`} aria-label={`Press enter to view ${getFormattedEmpName(props.item)}'s employee heirarchy`}>
        {getFormattedNameEmpId(props.item)}
      </Link>);
    }
    else {
        return (<div data-private={true} data-testid={`user-${props.idx}-nolink`}>{getFormattedNameEmpId(props.item)}</div>);
    }
};
const Reportee = (props) => {
    const columns = [
        {
            Header: 'Employee Name (Employee ID)',
            accessor: 'reporteeLink',
            id: 'reporteeLink',
        },
        {
            Header: 'Employee Overview',
            accessor: 'reporteeEmployeeOverview',
            id: 'reporteeEmployeeOverview',
        },
    ];
    const reportees = props.reporteeList.map((reportee, index) => {
        return {
            reporteeLink: <ReporteesLink item={reportee} idx={index} getHierarchy={props.getHierarchy}/>,
            // @ts-ignore due to imperfect matches on types -- should work
            reporteeEmployeeOverview: <EmployeeOverviewLink id='abc' empId={reportee.empId} employee={reportee}/>,
        };
    });
    return (<CompositeTable data-testid='reportee-table' data={reportees} columns={columns} paginated={true}/>);
};
export default Reportee;
