import React, { useContext, useRef } from 'react';
import { StepperContext, Form } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import ConditionCircumstance from './ConditionCircumstance';
import AboutAccommodationText from '../../constants/AboutAccommodationText';
import StepperButtons from '../../components/StepperButtons';
import AddAccommodationRequest from './AddAccommodationRequest';
import useAsDataContext from '../../hooks/useAsDataContext';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { StyledHeading } from '../../components/LayoutComponents';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const AboutAccommodation = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { trackEvent } = useTealium();
    const { requestCount, conditionData, setConditionData, accommodationRequestDataArray, setAccommodationRequestDataArray } = useAsDataContext();
    const stepper = useContext(StepperContext);
    const ref = useRef();
    const pageTitle = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutAccommodationText.aboutAccommodationEE) : t(AboutAccommodationText.aboutAccommodationER));
    const submit = (values) => {
        let accdataArr = [];
        for (let i = 1; i <= requestCount; i++) {
            let updatedAccommodationRequestData1 = {
                rtwWithoutAccomodationDate: values.accommodationRequestValues[`rtwWithoutAccomodationDate${i}`],
                requestReceivedDate: values.accommodationRequestValues[`requestReceivedDate${i}`],
                status: values.accommodationRequestValues[`status${i}`],
                closedDate: values.accommodationRequestValues[`closedDate${i}`],
                statusReasonCode: values.accommodationRequestValues[`statusReasonCode${i}`],
                assistanceRequired: values.accommodationRequestValues[`assistanceRequired${i}`],
                providedAccomodationTypeCode: values.accommodationRequestValues[`providedAccomodationTypeCode${i}`],
                providedDetailsText: values.accommodationRequestValues[`providedDetailsText${i}`],
                actualBeginDate: values.accommodationRequestValues[`actualBeginDate${i}`],
                restrictionsAndLimits: values.accommodationRequestValues[`restrictionsAndLimits${i}`],
                suppRestrictionsAndLimitations: values.accommodationRequestValues[`suppRestrictionsAndLimitations${i}`],
                interactiveBeginDate: values.accommodationRequestValues[`interactiveBeginDate${i}`],
                initialMeetingDate: values.accommodationRequestValues[`initialMeetingDate${i}`],
                determinationInfoReceivedDate: values.accommodationRequestValues[`determinationInfoReceivedDate${i}`],
                determinationDate: values.accommodationRequestValues[`determinationDate${i}`],
                priorCarrier: values.accommodationRequestValues[`priorCarrier${i}`],
                requestedAccommodationCost: values.accommodationRequestValues[`requestedAccommodationCost${i}`],
                providedCostAmount: values.accommodationRequestValues[`providedCostAmount${i}`],
                actualEndDate: values.accommodationRequestValues[`actualEndDate${i}`],
                requestedAccommodationType: values.accommodationRequestValues[`accomodationRequestType${i}`],
                requestedAccommodationDetail: values.accommodationRequestValues[`isTimeawayfromWork${i}`],
                requestedPeriodType: values.accommodationRequestValues[`temporaryorPermanentRequest${i}`],
                requestedStartDate: values.accommodationRequestValues[`desiredStartDate${i}`],
                existingAccommodation: values.accommodationRequestValues[`isAlreadyReported${i}`],
                accommodationWorkOrHome: values.accommodationRequestValues[`workStatus${i}`],
                anticipatedEndDate: values.accommodationRequestValues[`anticipatedEndDate${i}`],
                id: i
            };
            const updatedAccommodationRequestData = { ...accommodationRequestDataArray, ...updatedAccommodationRequestData1 };
            accdataArr.push(updatedAccommodationRequestData);
        }
        setAccommodationRequestDataArray(accdataArr);
        const updatedConditionData = { ...conditionData, ...values.conditionData };
        setConditionData(updatedConditionData);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'save and continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            event_version: pageTitle,
            event_status: 'step 3',
        });
        /* Send to the next step */
        stepper.next();
        window.scrollTo(0, 0);
    };
    return (<div ref={ref} data-testid='about-accommodation'>
      <StyledHeading elemType={'h2'} color={'primary'}>
        {pageTitle}
      </StyledHeading>
      <Form onSubmit={submit}>
        <ConditionCircumstance />
        <AddAccommodationRequest />
        <StepperButtons suppressNext analyticsPageName='about accommodation'/>
      </Form>
    </div>);
};
export default React.memo(AboutAccommodation);
