import React from 'react';
import { Button, Col, Icon, Row } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
const EditButton = ({ path }) => {
    const navigate = useNavigate();
    return (<Row>
      <Col lg={12} md={12}>
        <Button onClick={() => navigate(path)} style={{ float: 'right' }} type='button' buttonType={'text'}>
          Edit&nbsp;
          <Icon name={'edit'} color={'secondary'}/>
        </Button>
      </Col>
    </Row>);
};
export default EditButton;
