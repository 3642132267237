const IMfaAuthyInstructionsDisplayTextEn = {
    link: 'What is Authy?',
    heading: 'What is Authy?',
    body: 'The Authy app allows you to generate two-factor authentication tokens using your iPhone, Android, and desktop devices, without waiting for an SMS or Voice Call. ',
    step1Title: 'Install.',
    step1TextA: 'Go to ',
    step1TextB: ' to install the app, or locate it in the App Store or Google Play.',
    step2Title: 'Configure.',
    step2Text: 'Authy will prompt you to enter your phone number. In order to link to your My Lincoln Portal account, you\'ll need to enter the same phone number that you use for My Lincoln Portal. Call us if you need to update the number associated with your account.',
    step3Title: 'Login.',
    step3Text: 'Navigate to My Lincoln Portal and enter your credentials to login. When prompted for your Two Factor Authentication preference, select Authy App.',
    step3Italic: 'Note: Even if you choose another method, the code from your authy app will be valid and will allow you to access your account.',
    step4Title: 'Obtain Code.',
    step4Text: 'Simply open the app and tap My Lincoln Portal from the account list at the bottom of your screen. You\'ll see a code displayed on your screen. This code will be valid for 20 seconds from the moment it appears on your screen. Enter the code into My Lincoln Portal.',
    step4Italic: 'Note: if you chose a different preference and receive another code, your authy code will be valid and will allow you to access your account.',
};
export default IMfaAuthyInstructionsDisplayTextEn;
