import React from 'react';
import { SelectInput, TextInput, Button, Container, Row, Col } from '@digitools/honest-react';
import { Form, useFormState } from 'informed';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const SearchForm = () => {
    const formState = useFormState();
    const options = [{ label: 'Employee ID', value: 'empId' }, { label: 'Employee name', value: 'empName' }];
    const getPlaceholder = (searchCriteria) => {
        switch (searchCriteria) {
            case 'empName':
                return 'Enter last name, first name';
            case 'empId':
                return 'Enter employee ID';
            default:
                return 'Select search criteria';
        }
    };
    const validateEmployeeName = (input) => {
        if (!input || input.length < 3) {
            return 'Please enter at least three letters of the last name.';
        }
    };
    const validateSelect = (input) => {
        if (!input) {
            return 'Employee search criteria is required';
        }
    };
    const validateContent = (input) => {
        if (!input) {
            return 'Please enter search criteria.';
        }
    };
    const getSearchValidator = (searchCriteria) => {
        switch (searchCriteria) {
            case 'empName':
                return validateEmployeeName;
            default:
                return validateContent;
        }
    };
    const getSearchLabel = (searchCriteria) => {
        switch (searchCriteria) {
            case 'empName':
                return 'Employee name';
            case 'empId':
                return 'Employee ID';
            default:
                return 'Search';
        }
    };
    const placeholder = getPlaceholder(formState.values.searchCriteria);
    const validateSearch = getSearchValidator(formState.values.searchCriteria);
    const searchLabel = getSearchLabel(formState.values.searchCriteria);
    return (<Container>
      <Row data-public={true}>
        <Col sm={12} md={4} lg={4}>
          <SelectInput data-testid='searchCriteria' field='searchCriteria' label='Search criteria' placeholderText='Search criteria' options={options} validate={validateSelect} aria-label={'Search criteria'}/>
        </Col>
        <Col sm={12} md={5} lg={5}>
          <TextInput data-testid='search' field='search' label={searchLabel} placeholder={placeholder} type={'text'} icon={'search'} validate={validateSearch} aria-label={'search'} data-private={true}/>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <Button aria-label={'search'} data-testid='submit' type='submit' buttonType='primary'>
            Search
          </Button>
        </Col>
      </Row>
    </Container>);
};
const Search = (props) => {
    const { trackEvent } = useTealium();
    const onHandleSubmit = (values) => {
        if (values.searchCriteria === 'empId') {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.SEARCH,
                event_name: 'Search by Employee Id'
            });
        }
        else if (values.searchCriteria === 'empName') {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.SEARCH,
                event_name: 'Search by Employee Name'
            });
        }
        props.onHandleSearch(values);
    };
    return (<Form onSubmit={onHandleSubmit}>
      <SearchForm />
    </Form>);
};
export default Search;
