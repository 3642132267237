import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGet from '../../../../../hooks/useGet';
import AbsenceDetailsFilter from './../filters/AbsenceDetailsFilter';
import Media from 'react-media';
import getLeaveBalanceAsOfDate from '../../../utilities/getLeaveBalanceAsOfDate';
import { compareDesc, parse } from 'date-fns';
import getLeaveProgramType from '../../../utilities/getLeaveProgramType';
import getLeaveStatus from '../../../utilities/getLeaveStatus';
import getLeaveStatusReason from '../../../utilities/getLeaveStatusReason';
import checkDate from '../../../utilities/checkDate';
import { downloadLeaveDates } from './downloadReport';
import { Alert, Button, CompositeTable, Heading, Icon, Spinner } from '@digitools/honest-react';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
const AbsenceDetailsTable = ({ leaveId, leave }) => {
    const { t } = useTranslation();
    const { trackEvent } = useTealium();
    const [filteredData, setFilteredData] = useState();
    const leaveDetailColumns = [
        {
            Header: t('dateText'),
            accessor: 'date',
            id: 'date',
            sortMethod: (a, b) => compareDesc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Cell: ({ data }) => {
                return (<>
            {Math.floor(data.duration / 60)}:{(data.duration % 60).toString().padStart(2, '0')}
          </>);
            },
            Header: t('hoursText'),
            accessor: 'duration',
            id: 'duration',
        },
        {
            Cell: ({ data }) => {
                return <>{getLeaveProgramType(data)}</>;
            },
            Header: t('programText'),
            accessor: 'program',
            id: 'program',
        },
        {
            Cell: ({ data }) => {
                return <>{getLeaveStatus(data)}</>;
            },
            Header: t('statusText'),
            accessor: 'status',
            id: 'status',
        },
        {
            Cell: ({ data }) => {
                return <>{getLeaveStatusReason(data)}</>;
            },
            Header: t('reasonText'),
            accessor: 'requestTimeReasonCode',
            id: 'requestTimeReasonCode',
        },
        {
            Cell: ({ data }) => {
                return <>{checkDate(data.reportedOn)}</>;
            },
            Header: t('reportedText'),
            accessor: 'reportedOn',
            id: 'reportedOn',
            sortMethod: (a, b) => compareDesc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Cell: ({ data }) => {
                return <>{checkDate(data.addedOn)}</>;
            },
            Header: t('addedToLeaveText'),
            accessor: 'addedOn',
            id: 'addedOn',
            sortMethod: (a, b) => compareDesc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Cell: ({ data }) => {
                return <>{checkDate(data.updatedOn)}</>;
            },
            Header: t('lastModifiedText'),
            accessor: 'updatedOn',
            id: 'updatedOn',
            sortMethod: (a, b) => compareDesc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
    ];
    const print = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.PRINT,
            event_name: 'Print-LeaveDatesTaken',
        });
        downloadLeaveDates(leaveDatesData, leave, name, t);
    };
    const { error, loading, data: leaveDatesData, } = useGet({
        url: '/status-service/absences/' + leaveId,
    });
    if (loading) {
        return <Spinner id='absence-details-table-spinner'/>;
    }
    const isNoData = leaveDatesData && leaveDatesData.length === 0;
    if (isNoData || (error && error.response && error.response.status === 404)) {
        return (<Alert type='info' data-testid='noAbsencesFound'>
        There are no absences taken to display at this time.
      </Alert>);
    }
    else if (error && error.response) {
        return (<Alert type='error' data-testid='AbsencesError'>
        <span>
          <strong>Error!</strong> Something went wrong when loading leave dates taken.
        </span>
      </Alert>);
    }
    // show filtered data if the form has updated that
    let dataToDisplay = filteredData ? filteredData : leaveDatesData;
    return (<>
      <AbsenceDetailsFilter initialData={leaveDatesData} setFilteredData={setFilteredData}/>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Heading elemType='h3' style={{ display: 'inline-block' }} data-testid='AbsencesTableHeading' color={'primary'}>
          {`${t('datesTakenText')} ${t('asOfText')} ${getLeaveBalanceAsOfDate(leave)}`}
        </Heading>
        <Button 
    //@ts-ignore
    style={{ marginLeft: 'auto', marginTop: '0' }} buttonType={'text'} type={'button'} onClick={print} aria-label='Download All' data-testid='download-all-absences'>
          <Icon name='download' color={'secondary'} style={{ paddingRight: '5px' }}/>
          <Media query='(min-width: 768px)'>{matches => (matches ? 'Download All' : null)}</Media>
        </Button>
      </div>
      <CompositeTable data-testid='AbsencesTable' data={dataToDisplay} columns={leaveDetailColumns} paginated={true} 
    //@ts-ignore
    defaultSorted={[{ id: 'date' }]}/>
    </>);
};
export default AbsenceDetailsTable;
