const RabSpanish = {
    reportABirthHeaderText: 'Reportar un nacimiento',
    numberOfBirthsQuestonText: 'Número de nacimientos?',
    numberOfBirthsText: 'Número de nacimientos',
    birthsText: 'Nacimientos',
    deliveryTypeMultipleBirthsQuestionText: '¿Usted ha tenido un parto por cesárea?',
    deliveryTypeQuestionText: '¿Qué tipo de nacimiento tuvo?',
    dateOfMultipleBirthsQuestionText: '¿Cuál fue la primera fecha de nacimiento?',
    dateOfBirthQuestionText: '¿Cuál es la fecha de nacimiento de su hijo?',
    timeOfMultipleBirthQuestionText: '¿A qué hora nació su primer bebé?',
    timeOfBirthQuestionText: '¿A qué hora nació su hijo?',
    lastDayWorkedQuestionText: '¿Cuál fue el último día que trabajó?',
    bondingQuestionText: 'Si procede, ¿tiene pensado tomarse tiempo libre del trabajo además del permiso de maternidad de {deliveryWeeks} semanas para establecer un vínculo con su recién nacido?',
    hospitalAdmissionDateQuestionText: ' ¿Qué día fue ingresado al hospital, si corresponde?',
    hospitalAdmissionDateText: 'Fecha de ingreso hospitalario',
    yesText: 'Sí',
    noText: 'No',
    unknownText: 'Desconocida',
    vaginalText: 'Vaginal',
    cesareanText: 'Cesárea',
    submitText: 'Enviar',
    verifyText: 'Verificar',
    reportText: 'Reportar',
    successText: 'Éxito',
    claimAppliedText: 'La información siguiente se aplicará al reclamo ',
    claimAppliedConfText: 'Esta información será aplicada al reclamo',
    confirmationText: 'Gracias {claimantFirstName}, su información se ha enviado.',
    caseManagerText: 'Su administrador de casos la llamará si surge alguna pregunta.',
    birthTypeText: 'Tipo de nacimiento',
    birthDateText: 'Fecha de nacimiento',
    birthTime: 'Hora de nacimiento',
    lastDayWorkedText: 'Último día en que trabajó',
    bondingTimeText: 'Hora de vínculo',
    editButton: 'Editar',
    fieldRequiredText: 'Obligatorio',
    invalidDateText: 'Ingrese una fecha válida mm/dd/yyyy',
    dateCannotBeInFutureText: 'La fecha no puede ser en el futuro.',
    timeCannotBeInFutureText: ' El tiempo no puede estar en el futuro.',
    noMaternityClaimText: 'La maternidad no existen créditos en nuestro sistema.',
    reviewTitleText: 'Revisión',
    reviewHeaderText: 'Por favor, revise la información a continuación para verificar su exactitud antes de enviar. Para realizar cualquier cambio, seleccione “Editar” para volver a la sección correspondiente.',
    viewSubmissionHeaderText: 'Ver presentación',
    confirmationViewPrintText: 'Ver e imprimir presentación',
    closeText: 'Cerrar',
    printText: 'Imprimir',
    backButton: 'Anterior',
    confirmationHeaderText: 'Confirmación',
    optional: ' (opcional)',
    genericError: '¡Lo sentimos! ¡Algo salió mal!',
    unauthorizedErrorHeader: '¡Lo sentimos! No tiene autorización para ver esta página.',
    unauthorizedErrorText: 'Regrese e intente nuevamente más tarde. Si el problema persiste, comuníquese al 1-800-431-2958 para obtener más información.',
    stepLabelReport: 'Reportar',
    stepLabelReview: 'Revisión',
    stepLabelSuccess: 'Éxito',
    employeeInfo: 'Información del empleado',
};
export default RabSpanish;
