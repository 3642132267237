import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import { ApplicantInformationText } from '../../i18n/ApplicantInformationText';
import { BorderLessGrayBox } from '../../../../components/Formatting/StyledComponents';
import EmploymentInformation from './components/EmploymentInformation';
import ContactInformation from './components/ContactInformation';
import PersonalInformation from './components/PersonalInformation';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import useEoiContext from '../../hooks/useEoiContext';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
const ApplicantInfoForm = (props) => {
    const { t } = useTranslate();
    const { initialInformation } = useEoiContext();
    const { trackClickWithPage } = useGoogleAnalytics();
    const provinces = () => {
        if (initialInformation) {
            return initialInformation.locations.provinces;
        }
        return [];
    };
    const states = () => {
        if (initialInformation) {
            return initialInformation.locations.states;
        }
        return [];
    };
    const countries = () => {
        if (initialInformation) {
            return initialInformation.locations.countries;
        }
        return [];
    };
    const deleteDependent = () => {
        trackClickWithPage('deleteDependent', '/customer/eoi/applicantInfo');
        props.setDependentToRemove(props.uuid);
    };
    const removeButton = props.type === 'dependent' && props.setDependentToRemove && props.uuid
        ? (<Button type={'button'} theme={themes.lincoln} onClick={() => deleteDependent()} style={{ display: 'flex', marginRight: 0, marginLeft: 'auto', height: '1rem', paddingRight: 0 }}>
        <Icon name={'trash'} color={'secondary'} style={{ marginRight: '0.5rem' }}/>
        {t(ApplicantInformationText.removeDependent)}
      </Button>) : <></>;
    return (<>
      {props.type === 'employee' &&
            <>
        <BorderLessGrayBox style={{ marginTop: '1rem' }}>
          {t(ApplicantInformationText.employmentInformation)}
        </BorderLessGrayBox>
        <EmploymentInformation interviewAnswersApplicant={props.interviewAnswersApplicant}/>
      </>}

      {props.type === 'dependent' &&
            <TextInput style={{ display: 'none' }} hideLabel={true} initialValue={props.uuid} label={'dependentId'} field={'dependentId'} success={true}/>}

      <BorderLessGrayBox style={{ marginTop: '1rem' }}>
        <span>{t(ApplicantInformationText.personalInformation)}</span>
        {removeButton}
      </BorderLessGrayBox>
      <PersonalInformation type={props.type} states={states()} interviewAnswersApplicant={props.interviewAnswersApplicant}/>

      {props.type === 'employee' &&
            <>
        <BorderLessGrayBox>
          {t(ApplicantInformationText.contactInformation)}
        </BorderLessGrayBox>
        <ContactInformation states={states()} provinces={provinces()} countries={countries()} interviewAnswersApplicant={props.interviewAnswersApplicant}/>
      </>}
    </>);
};
export default ApplicantInfoForm;
