import { Icon, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../../hooks/useTranslate';
import Message from './Message';
import { MessageContainerText } from './MessageContainerText';
const StyledContainerParent = styled('div') `
    background: rgba(255,0,0,0);
    height: calc(491px + (140 * (100vw - 768px)) / 1650);
    width: 50vw;
    max-width: 555px;
    position: absolute;
    top: ${props => props.logoExists ? '40px' : '35px'};
    margin-right: 15px;
    z-index: 5;
    right: 0vw;
    overflow: hidden;
    width: 50vw;
    flex-direction: column;
    visibility: ${props => props.isOpen ? 'visible;' : 'hidden;'}
    transition: visibility 0s;
    transition-delay: ${props => props.isOpen ? '0s;' : '1s;'}


    @media (max-width: 1200px) {
        height: calc(427px + (140 * (100vw - 768px)) / 1650);
    }

    @media (max-width: 993px) {
        height: calc(446px + (140 * (100vw - 768px)) / 1650);
    }

    @media (max-width: 768px) {
        padding-left: 15px;
        marging: none;
        top: ${props => props.logoExists ? '59px' : '46px'};
        width: calc(100% - 15px);
        max-width: none;
        height: auto;
    }


    
`;
const StyledContainer = styled('div') `
    background: white;
    height: 100%;
    width: 50vw;
    max-width: 555px;
    position: relative;
    left: ${props => props.isOpen ? '0;' : '100%;'}
    overflow: hidden;
    
    width: 50vw;
    border: 1px solid ${themes.lincoln.palette.grayLight}
    flex-direction: column;

    transition: left 1s ease-in-out;

    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
    }
`;
const StyledMessagesContainer = styled('div') `
    overflow-y: scroll;
    height: 80%;
    padding-bottom: 30px;
    padding-top: 30px;

    @media (max-width: 768px) {
        overflow-y: visible;
    }
`;
const StyledTitleContainer = styled('div') `
    width: 100%;
    margin: auto;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #AD112B;
    padding: 20px;
    padding-left: 5%;
    padding-right: calc(5% + 15px);
`;
const StyledTitle = styled('div') `
    
    line-height: 100%;
    font-size: 1.5rem;
    color: white;

    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
        font-size: 1.15rem;
    }
`;
const StyledButton = styled('button') `
    
    font-size: 0.8125rem;
    padding: 0.25rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    cursor: pointer;
    color: #244F8B;
    border: 2px solid transparent;
    background: none;
`;
const StyledCloseButton = styled.button `
  margin-left: auto;
  cursor: pointer;
  align-self: center;
  background: transparent;
  border: none;
`;
const StyledTransparentThing = styled('div') `
    position: absolute;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    height: 100px;
    z-index: 10;
    bottom: 0;
    width: 100%;

    @media (max-width: 753px) {
        height: 75px;
    }
`;
const MessagesContainer = ({ trackPageEvent, ...props }) => {
    const { landingPageResource, logoExists } = props;
    const { t } = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [employerMessages, setEmployerMessages] = useState([]);
    useEffect(() => {
        if (landingPageResource && landingPageResource.employerMessage && landingPageResource.employerMessage.english) {
            setEmployerMessages([{
                    title: MessageContainerText.defaultMessageTitle,
                    body: {
                        english: landingPageResource.employerMessage.english,
                        spanish: landingPageResource.employerMessage.spanish
                    }
                }]);
        }
        else if (landingPageResource && landingPageResource.employerMessages) {
            setEmployerMessages(landingPageResource.employerMessages);
        }
        else {
            setEmployerMessages([
                {
                    title: {
                        english: "",
                        spanish: ""
                    },
                    body: MessageContainerText.noMessages
                }
            ]);
        }
    }, [landingPageResource]);
    const toggleMessages = () => {
        setIsOpen(!isOpen);
    };
    const closeMessageBox = (event) => {
        if (event.key.toLowerCase() === 'enter' && isOpen) {
            setIsOpen(false);
        }
    };
    return (<>
        <StyledButton data-testid='messages-button' onClick={() => { toggleMessages(); trackPageEvent && trackPageEvent('Employer Messages'); }}>
            <Icon name='mail' color='secondary' size='sm' style={{ marginRight: '.25rem' }}/>
            {t(MessageContainerText.messageButtonLabel)}
        </StyledButton>
        <StyledContainerParent isOpen={isOpen} data-testid='messages-container-parent' logoExists={logoExists}>
            <StyledContainer isOpen={isOpen} data-testid='messages-container'>
            <StyledTitleContainer>
                <StyledTitle data-testid='employer-message-title'>{t(MessageContainerText.messageButtonLabel)}</StyledTitle>
                <StyledCloseButton onKeyPress={closeMessageBox}>
                    <Icon name='close-thin' style={{ fontSize: '1.75rem' }} color='white' data-testid='employer-message-close-icon' onClick={toggleMessages}/>
                </StyledCloseButton>
            </StyledTitleContainer>
            <StyledMessagesContainer>
                
                {employerMessages.map((message, index) => <Message key={`employer-message-${index}`} index={index} message={message}/>)}
               
            </StyledMessagesContainer>
            <StyledTransparentThing />
            </StyledContainer>
        </StyledContainerParent>
        </>);
};
export default MessagesContainer;
