import AboutAccommodationText from "./AboutAccommodationText";
import { AboutYouText } from "./AboutYouText";
import { ConditionCircumstanceText } from "./ConditionCircumstanceText";
import { PersonalInformationText } from "./PersonalInformationText";
import { PreferredContactInformationText } from "./PreferredContactInformationText";
import { ResidentialInformationText } from "./ResidentialInformationText";
import { AddRequestText } from "./AddAccommodationRequestText";
export const ReviewText = {
    pageTitle: {
        english: 'Review your request and submit',
        spanish: 'Revise su solicitud y envíela'
    },
    editButton: {
        english: 'EDIT',
        spanish: 'EDITAR',
    },
    countryUnitedStates: {
        english: 'United States',
        spanish: 'Estados Unidos',
    },
    countryCanada: {
        english: 'Canada',
        spanish: 'Canadá',
    },
    countryOther: {
        english: 'Other Countries',
        spanish: 'Otros Pais',
    },
    countryLabel: {
        english: 'Country',
        spanish: 'País'
    },
    stateLabel: {
        english: 'State',
        spanish: 'Estado'
    },
    provinceLabel: {
        english: 'Province',
        spanish: 'Provincia'
    },
    submitError: {
        english: `We're sorry, we were unable to submit your request at this time. Please wait a moment and try again. If issues persist, please`,
        spanish: `Lo sentimos, no hemos podido enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si los problemas persisten,`,
    },
    contactUs: {
        english: 'contact us',
        spanish: 'comuníquese con nosotros',
    },
    disclaimerFooterText: {
        english: 'According to Lincoln claim processing and compliance, Lincoln is not responsible for informing the employer of this request. Upon submission, any information for this request must be provided to the employer, by the employee who submitted the request.',
        spanish: 'Según el procesamiento y cumplimiento de reclamos de Lincoln, Lincoln no es responsable de informar al empleador sobre esta solicitud. Una vez enviada la solicitud, el empleado que la envió debe dar al empleador cualquier información para esta solicitud.'
    },
    personalInformation: { ...PersonalInformationText },
    contactInformation: { ...PreferredContactInformationText },
    residentialInformation: { ...ResidentialInformationText },
    aboutYou: { ...AboutYouText },
    conditionCircumstance: { ...ConditionCircumstanceText },
    aboutAccommodation: { ...AboutAccommodationText },
    addRequest: { ...AddRequestText }
};
