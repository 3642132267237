import React from 'react';
import { GrayTextDiv, GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import { Box, Icon, themes } from '@digitools/honest-react';
import styled from 'styled-components';
const StyledButton = styled('button') `
  outline: none;
  border: none;
  font-size: 1rem;
  background: inherit;
  color: #23669a;
  flex: 1;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;
const reviewTextValueStyle = {
    color: '#37424a',
    fontWeight: 400,
    fontSize: '18px',
    marginTop: '0.2rem',
    marginBottom: '0.15rem'
};
const lableStyle = {
    color: '#37424a',
    fontSize: '14px',
    fontWeight: 500
};
const reviewTextValueRequestStyle = {
    color: '#37424a',
    fontWeight: 400,
    fontSize: '15px',
    marginTop: '0.2rem',
    marginBottom: '0.15rem'
};
const lableRequestStyle = {
    color: '#37424a',
    fontSize: '16px',
    fontWeight: 500
};
export const GrayBox = styled(Box) `
  background-color: ${themes.lincoln.palette.grayLightest} !important;
  border: none;
  height: 45px;
  margin-top: 10px;
`;
export const EditReviewField = ({ onClick, ...rest }) => {
    return (<StyledButton onClick={onClick} {...rest} data-testid='review-edit-field'>
      <Icon color='secondary' name='edit'/>
      EDIT
    </StyledButton>);
};
export const ReviewField = ({ label, value, ...rest }) => {
    return (<GrayTextDiv {...rest} data-testid='review-field'>
      <span style={lableStyle}>{label}</span>
      <div style={reviewTextValueStyle}>{value}</div>
      <GraySpacerDiv style={{ marginTop: value ? undefined : '1.7rem' }}/>
    </GrayTextDiv>);
};
export const ReviewFieldForRequest = ({ label, value, ...rest }) => {
    return (<GrayTextDiv {...rest} data-testid='review-edit-request-field'>
      <span style={lableRequestStyle}>{label}</span>
      <div style={reviewTextValueRequestStyle}>{value}</div>
      <GraySpacerDiv style={{ marginTop: value ? undefined : '1.7rem' }}/>
    </GrayTextDiv>);
};
export default ReviewField;
