import React, { useState } from 'react';
import { Scope } from 'informed';
import { Alert, Button, Col, Heading, Radio, RadioGroup, Row, TextInput, themes } from '@digitools/honest-react';
import { empIdValidation, fieldRequired, ssnValidation } from 'src/packages/eoi/utils/FormFieldValidations';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import useTranslate from 'src/hooks/useTranslate';
import { VerificationText } from '../../constants/VerificationText';
import { PersonalInformationText } from 'src/packages/as/constants/PersonalInformationText';
import styled from 'styled-components';
import useAsCustomerConfigContext from '../../hooks/useAsConfigContext';
import useAsDataContext from '../../hooks/useAsDataContext';
import { CustomEmployeeIdText, CustomEmployeeText } from 'src/utils/CustomExperienceText';
const ColRightAlign = styled(Col) `
  @media (max-width: 992px) {
    left: 0px;
    top: 0px;
  }
  position: relative;
  left: 200px;
  top: 2px;
`;
const containerStyle = {
    borderLeft: `3px solid ${themes.lincoln.palette.primary}`,
    paddingLeft: '1rem',
};
const submitButtonStyle = {
    width: '100%',
};
const submitButtonColStyle = {
    marginBottom: 'auto',
    marginLeft: 'auto',
};
const radioLabelStyle = {
    marginBottom: '.5rem',
};
const PersonalIdentityVerificationForm = ({ formState, invalidUserEmail }) => {
    const [notFoundError, setNotFoundError] = useState(false);
    const { experience } = useExperience();
    let radioSelected = 'ssn';
    const { t } = useTranslate();
    const asConfigContext = useAsCustomerConfigContext();
    let lookupByEmpId = false;
    let lookupBySsn = false;
    const { claimantData } = useAsDataContext();
    if (asConfigContext.asConfig?.config?.uniqueIdIndicator === 'EMP ID') {
        lookupByEmpId = true;
    }
    else if (asConfigContext.asConfig?.config?.uniqueIdIndicator === 'SSN') {
        lookupBySsn = true;
    }
    else if (asConfigContext.asConfig?.config?.uniqueIdIndicator === 'Both') {
        lookupByEmpId = true;
        lookupBySsn = true;
    }
    const lastName = asConfigContext?.asConfig?.config?.userData?.lastName;
    const employeeId = asConfigContext && asConfigContext?.asConfig?.config?.userData?.employeeId;
    const initialEmpIDValue = () => {
        if (!asConfigContext.asConfig?.config?.prePopulateInterviewIndicator) {
            if (asConfigContext.asConfig?.config?.userData?.employeeId) {
                return asConfigContext.asConfig?.config?.userData?.employeeId;
            }
            return claimantData?.empId;
        }
    };
    const empIdValue = experience === Experience.EMPLOYEE ? initialEmpIDValue() : employeeId;
    const verificationHeadingLabel = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(VerificationText.verificationHeadingEE)
        : `${t(VerificationText.verificationHeadingER)}`);
    const verificationSubHeading = experience === Experience.EMPLOYER && t(VerificationText.verificationSubHeading);
    if (claimantData?.empId)
        radioSelected = 'empID';
    return (<>
      {/* @ts-ignore */}
      <Scope scope='accommodationData'>
        <div style={containerStyle}>
          <Row>
            <Col md={12} data-public={true}>
              <Heading elemType={'h3'} elemStyle={'h3'} className={`mb-0`}>
                {verificationHeadingLabel}
              </Heading>
            </Col>
          </Row>
          {asConfigContext && lookupByEmpId && asConfigContext && lookupBySsn && (<Row className={`mt-0 mb-4`} data-public={true} data-testid='subHeading'>
              <Col md={12}>
                <strong>{verificationSubHeading}</strong>
              </Col>
            </Row>)}
          {invalidUserEmail &&
            <Alert data-testid={'error-alert-useremail'} type={'error'} aria-labelledby='error-alert'>
                {t(VerificationText.invalidUserEmail)}
            </Alert>}
          <Row>
            {lookupBySsn && lookupByEmpId && (<Col md={'auto'} data-testid='verification-radio-group' data-public={true}>
                <RadioGroup label={CustomEmployeeText(t(VerificationText.verifyRadioButtonLegend))} hideLabel={true} field={'verificationType'} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} data-public={true} initialValue={radioSelected}>
                  <Radio containerStyle={radioLabelStyle} id='showSsn' data-testid='radio-ssn' label={t(VerificationText.ssnRadioLabel)} name={'verificationType'} data-private={true} theme={themes.lincoln} value={'ssn'}/>
                  <Radio containerStyle={radioLabelStyle} id='showEmpID' data-testid='radio-empID' label={CustomEmployeeIdText(t(VerificationText.employeeIdLabel))} name={'verificationType'} data-public={true} theme={themes.lincoln} value={'empID'}/>
                </RadioGroup>
              </Col>)}
            {experience === Experience.EMPLOYEE && (<ColRightAlign lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                {((lookupBySsn && !lookupByEmpId) ||
                formState?.values?.accommodationData?.['verificationType'] === 'ssn') && (<TextInput field={'ssn'} data-testid='verification-ssn-field' label={t(VerificationText.ssnLabel)} theme={themes.lincoln} validate={(ssn) => {
                    setNotFoundError(false);
                    return ssnValidation(ssn);
                }} autoComplete='off' type={'textmask'} ariaLabelText={'ssn'} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} skinny={true} data-private={true} initialValue={claimantData && claimantData?.ssn} toggleShowPassword={true}/>)}
                {((!lookupBySsn && lookupByEmpId) ||
                formState?.values?.accommodationData?.['verificationType'] === 'empID') && (<TextInput data-testid='verification-empID-field' field={'empID'} label={CustomEmployeeIdText(t(VerificationText.employeeIdLabel))} theme={themes.lincoln} type={'text'} ariaLabelText={'empId'} validate={(empId) => {
                    setNotFoundError(false);
                    return empIdValidation(empId);
                }} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} skinny={true} initialValue={claimantData && claimantData?.empId}/>)}
              </ColRightAlign>)}

            {experience === Experience.EMPLOYER &&
            ((lookupBySsn && !lookupByEmpId) ||
                formState?.values?.accommodationData?.['verificationType'] === 'ssn') && (<Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                  <TextInput data-testid='verification-ssn-field' field={'ssn'} label={t(VerificationText.ssnLabel)} theme={themes.lincoln} validate={(ssn) => {
                setNotFoundError(false);
                return ssnValidation(ssn);
            }} autoComplete='off' type={'textmask'} ariaLabelText={'ssn'} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} skinny={true} data-private={true} toggleShowPassword={true} initialValue={claimantData && claimantData?.ssn}/>
                </Col>)}
            {experience === Experience.EMPLOYER &&
            ((!lookupBySsn && lookupByEmpId) ||
                formState?.values?.accommodationData?.['verificationType'] === 'empID') && (<Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                  <TextInput data-testid='verification-empID-field' field={'empID'} label={CustomEmployeeIdText(t(VerificationText.employeeIdLabel))} theme={themes.lincoln} type={'text'} ariaLabelText={'empId'} validate={(empId) => {
                setNotFoundError(false);
                return empIdValidation(empId);
            }} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} skinny={true} initialValue={empIdValue}/>
                </Col>)}
            {experience === Experience.EMPLOYER && asConfigContext?.asConfig && (<Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                <TextInput data-testid='verification-last-name-field' data-private={true} field={'lastName'} label={t(PersonalInformationText.lastNameQuestionLabel)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} ariaLabelText={t(PersonalInformationText.lastNameQuestionLabel)} skinny={true} initialValue={lastName}/>
              </Col>)}
            <Col style={submitButtonColStyle} lg={3} data-public={true} className={experience === Experience.EMPLOYER ? 'mt-1' : 'mt-auto'}>
              <Button style={submitButtonStyle} buttonType={'primary'} theme={themes.lincoln} type='submit'>
                {t(VerificationText.verifyButton)}
              </Button>
            </Col>
          </Row>
        </div>
        <hr />
      </Scope>
    </>);
};
export default PersonalIdentityVerificationForm;
