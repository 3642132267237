import { Form, Heading, StepperContext } from '@digitools/honest-react';
import React, { useContext, useRef } from 'react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import useTranslate from 'src/hooks/useTranslate';
import useOnScreen from 'src/hooks/visibilityChecker';
import RouterPrompt from '../../components/RouterPrompt';
import StepperButtons from '../../components/StepperButtons';
import useCliDataContext from '../../hooks/useCliDataContext';
import GettingStartedText from '../start/GettingStartedText';
import EmploymentDetails from './components/EmploymentDetails';
const AdditionalInfo = () => {
    const { setAdditionalDetails, additionalDetails } = useCliDataContext();
    const stepper = useContext(StepperContext);
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const submit = (values) => {
        const parsedEarnings = values.additionalDetails && values.additionalDetails.earnings && values.additionalDetails.earnings.replace(/[^0-9.]/g, '');
        let mergedAdditionalDetailsData = { ...additionalDetails, ...values.additionalDetails, earnings: parsedEarnings };
        setAdditionalDetails(mergedAdditionalDetailsData);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_version: 'additional info',
            event_status: 'step 4',
        });
        stepper.next();
        window.scrollTo(0, 0);
    };
    return (<div ref={ref} data-testid="additional-info">
            {stepper.currentStep !== t(GettingStartedText.pageTitle) && isVisible && <RouterPrompt />}
            <Heading elemType='h2' color='primary'>
                Additional info
            </Heading>
            <Form onSubmit={submit}>
                <EmploymentDetails />
                <StepperButtons suppressNext analyticsPageName='additional info'/>
            </Form>
        </div>);
};
export default AdditionalInfo;
