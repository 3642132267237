import React from 'react';
import { Row, Col, Alert, Box, themes, Spinner } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { useUiData } from 'status/hooks';

const NotFound = () => (
  <Alert type='info' className='mb-3'>
    No EOI specialist found.
  </Alert>
);

const Error = () => (
  <Alert type='error' className='mb-3'>
    Something went wrong when looking up your EOI specialist.
  </Alert>
);

const EOISpecialist = () => {
  const { t } = useTranslation();

  const { error: uiDataError, loading: uiDataLoading, uiData } = useUiData();

  if (uiDataLoading) {
    return (
      <Box className='mb-3' data-testid='spinner' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <Spinner id='eoi-specialist-spinner' />
      </Box>
    );
  }

  if (uiDataError && uiDataError.response && uiDataError.response.status === 404) {
    return <NotFound />;
  }

  if (uiDataError) {
    return <Error />;
  }

  const eoiDetails = uiData.eoiDetails;

  return (
    <div className='mb-3'>
      <Box type='header' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        {t('eoiSpecialistHeader')}
      </Box>
      <Box style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        {eoiDetails ? (
          <Row>
            <Col>
              <strong>{t('eoiSpecialistMailingAddressHeader')}</strong>
              <small className='d-block'>{eoiDetails.eoiSpecilistMailingAddressLineTwo}</small>
              <small className='d-block'>{eoiDetails.eoiSpecilistMailingAddressLineThree}</small>
              <small className='d-block mb-2'>{eoiDetails.eoiSpecilistMailingAddressLineFour}</small>
              <strong>{t('eoiSpecialistPhoneNumberHeader')}</strong>
              <small className='d-block'>{eoiDetails.eoiSpecilistPhoneNumber}</small>
            </Col>
          </Row>
        ) : (
          <NotFound />
        )}
      </Box>
    </div>
  );
};

export default EOISpecialist;
