import React from 'react';
import { Icon, Heading, Logo} from '@digitools/honest-react';
import { Link } from 'react-router-dom';

import styles from './ErrorPage.scss';

const ErrorPage = ({ message, link, linkTitle, theme }) => (
  <div className={styles.container} data-testid="error-page" data-public={true}>
    <div className={styles.logo}>
      <img src={Logo} className={styles.logoIcon} alt='logo' />
    </div>
    <Heading elemType='h1' elemStyle='h4'>
      <div className={styles.message}>
        <Icon name='error' size='medium' color='errorDark' className='mr-2' />
        {message}
      </div>
    </Heading>
    {link && (
      <Heading elemType='h4'>
        <Link to={link}>{linkTitle}</Link>
      </Heading>
    )}
  </div>
);

export default ErrorPage;