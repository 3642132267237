import React from 'react';
import { AsConfigProvider } from './providers/AsConfigProvider';
import { AsDataProvider } from './providers/AsDataProvider';
import AsRouter from './routes/AsRouter';
/**
 * Container to setup Theme, Error & Providers for AS app, pass route props down to ASRouter
 * @param props RouteComponentProps
 * @returns
 */
const Container = () => {
    return (<AsConfigProvider>
      <AsDataProvider>
        <AsRouter />
      </AsDataProvider>
    </AsConfigProvider>);
};
export default Container;
