import React from 'react';
import { Heading } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import ReviewField from "../review/ReviewField";
import useAsDataContext from '../../hooks/useAsDataContext';
import { getSelectedDropDownItemsAsString, getDocumentList } from '../../utils/AsDataUtils';
import useAsContext from '../../hooks/useAsConfigContext';
import { GrayBox, headingStyle } from '../../components/LayoutComponents';
import { AS_YES_NO_OPTIONS } from '../../constants/Constants';
import { ReviewText } from '../../constants/ReviewText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
const ReviewConditionsCircumstance = () => {
    const { conditionData, documents } = useAsDataContext();
    const { asConfig } = useAsContext();
    const { t } = useTranslate();
    const { experience } = useExperience();
    const conditionText = conditionData?.conditions && getSelectedDropDownItemsAsString(asConfig?.config?.conditionDropDown, conditionData?.conditions, t);
    const workRelatedText = conditionData?.workRelated && getSelectedDropDownItemsAsString(AS_YES_NO_OPTIONS, conditionData?.workRelated, t);
    const uploadingDocuments = (documents && documents?.length > 0) ? getDocumentList(documents) : '';
    const rlLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(ReviewText.conditionCircumstance.formLabels.reportedRestrictonOrLimitationEE) : t(ReviewText.conditionCircumstance.formLabels.reportedRestrictonOrLimitationER));
    return (<div data-testid={'review-conditions-circumstance'}>
      <GrayBox>
        <Heading style={headingStyle} elemType={'h4'} elemStyle='h4' color={'grayDark'} className={'text-decoration-none text-uppercase'}>
          {t(ReviewText.conditionCircumstance.accommTitle)}
        </Heading>
      </GrayBox>
      {experience === Experience.EMPLOYER && asConfig?.config?.asRep &&
            <ReviewField label={CustomEmployeeText(t(ReviewText.conditionCircumstance.formLabels.receivedDate))} value={conditionData?.receivedDate}/>}
      <ReviewField label={t(ReviewText.conditionCircumstance.formLabels.conditions)} value={conditionText}/>
      <ReviewField label={t(ReviewText.conditionCircumstance.formLabels.workRelated)} value={workRelatedText}/>
      <ReviewField label={rlLabel} value={conditionData?.conditionText}/>
      <ReviewField label={t(ReviewText.conditionCircumstance.formLabels.availableDocumentation)} value={uploadingDocuments}/>
    </div>);
};
export default React.memo(ReviewConditionsCircumstance);
