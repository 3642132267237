import useGet from '../../../hooks/useGet';
const useCliOfficeInfo = (customerId) => {
    const url = `/config-service/services/v1/customer/app/status/office-info/${customerId}`;
    const { error, loading, data, get } = useGet({
        url,
        noInitial: true,
    });
    return {
        cliOfficeInfo: data,
        loading,
        error,
        getCliOfficeInfo: get,
    };
};
export default useCliOfficeInfo;
