import { Form } from 'informed';
import React from 'react';
import { connect } from 'react-redux';
import { fieldSelection } from '../../crsActions';
import ReportFieldFooter from './ReportFieldFooter';
import ReportFieldHeader from './ReportFieldHeader';
import { DateInformation, DetailInformation, EmployeePersonalInformation, EmployeeWorkInformation, } from './ReportFieldPageBody';
import { withRouter } from 'src/utils/withRouter';
class ReportFieldPage extends React.Component {
    navigate;
    constructor(props) {
        super(props);
        this.navigate = props.navigate;
    }
    componentDidMount() {
        if (this.props.customerConfigCall) {
            this.props.navigate('/create-my-report');
        }
        window.scrollTo(0, 0);
    }
    render() {
        return (<>
        <Form data-public={true}>
          <div>
            <ReportFieldHeader />
            <EmployeePersonalInformation infoFields={this.props.fieldPageInfo.personalInfoFields} employeeTextCustomContent={this.props.employeeTextCustomContent}/>
            <EmployeeWorkInformation infoFields={this.props.fieldPageInfo.workInfoFields} employeeTextCustomContent={this.props.employeeTextCustomContent}/>
            <DateInformation claimFields={this.props.fieldPageInfo.claimDatesFields} leaveFields={this.props.fieldPageInfo.leaveDatesFields} accommodationFields={this.props.fieldPageInfo.accommodationDatesFields} reportFilterCategory={this.props.reportFilterCategory}/>
            <DetailInformation claimFields={this.props.fieldPageInfo.claimDetailFields} leaveFields={this.props.fieldPageInfo.leaveDetailFields} accommodationFields={this.props.fieldPageInfo.accommodationDetailFields} reportFilterCategory={this.props.reportFilterCategory}/>
            <ReportFieldFooter {...this.props}/>
          </div>
        </Form>
      </>);
    }
}
const mapStateToProps = ({ crsReducer }) => {
    return {
        fieldPageInfo: crsReducer.fieldPageInfo,
        reportFilterCategory: crsReducer.reportFilterCategory,
        employeeTextCustomContent: crsReducer.employeeTextCustomContent,
        customerConfigCall: crsReducer.callCustomerConfig,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onFieldSelection: (payload) => dispatch(fieldSelection(payload)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportFieldPage));
