import React from 'react';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useEoiContext from '../hooks/useEoiContext';
import useTranslate from '../../../hooks/useTranslate';
const SaveForLaterErrorAlert = () => {
    const { saveForLaterError, setSaveForLaterError, displaySaveForLaterError } = useEoiContext();
    const { t } = useTranslate();
    if (saveForLaterError && displaySaveForLaterError) {
        return (<Alert theme={themes.lincoln} type={'error'} closeable={true} onClose={() => setSaveForLaterError(undefined)} data-testid={'sfl-error-alert'}>{t(saveForLaterError)}</Alert>);
    }
    else {
        return <></>;
    }
};
export default SaveForLaterErrorAlert;
