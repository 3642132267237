export const IMfaDisplayTextEn = {
    authy: 'Authy',
    authyHelpText: 'Note: In order to link to your My Lincoln Portal account to the Authy app, you\'ll need to make sure you setup Authy with the same phone number as you input here.',
    authyConfirmationText: 'Enter the verification code from your Authy app.',
    step: 'Step 3 of 3',
    mfaPreference: 'Two-Factor Authentication Preference',
    setMethodText: 'Lincoln Financial Group is committed to your security. For all future logins, you will need to enter a security code to access your account. Set your default method below.',
    enrolledSetMethodText: 'Lincoln Financial Group is committed to your security. Select the method to receive your security code.',
    email: 'Email',
    emailAddress: 'Email Address',
    textMessage: 'Text Message',
    phoneCall: 'Phone Call',
    phoneNumber: 'Phone Number',
    thankYou: 'Thank You!',
    phoneConfirmationText: 'Enter the verification code that was sent to <>.',
    submit: 'Submit',
    continue: 'Continue',
    tryAnotherMethod: 'Try another method',
    selectAnOption: 'Select a method',
    securityCode: 'Enter Code',
    genericError: 'We\'re sorry. We are experiencing technical difficulties at this time. If this problem persists, please contact technical assistance.',
    mfaRetreivalError: 'We\'re sorry. We are unable to retrieve your two-factor authentication options at this time. If this problem persists, please contact technical assistance.',
    mfaSaveError: 'We\'re sorry. We are unable to save your selected option at this time. If this problem persists, please contact technical assistance.',
    invalidCodeError: 'You have entered an invalid code, please try again. You will have 5 attempts to enter a code before you are locked out for 30 minutes.',
    accountLockedError: 'You have entered an invalid code too many times. Your account will be locked for 30 minutes.',
    didntReceiveCode: 'Didn\'t receive your code? ',
    havingTrouble: 'Having Trouble?',
    sendCode: 'Request another code',
    invalidEmailAlert: 'Your current email address on file is not permitted for authentication purposes. Please select another two-factor authentication method to log in. To enable email as an option in the future, please update the email address in your My Lincoln Portal profile to your employer provided email address.',
    dataAndMessagingDisclaimer: 'Standard messaging and data rates apply.',
    updatePhoneNumberNote: 'Note: Your profile will be updated with your new phone number.',
    rememberMyDevice: 'Remember my device',
    nonApprovedCountryError: 'This country is not approved for Two Factor Authentication. Please contact technical assistance.',
    invalidPhoneError: 'Oops! This phone number is not valid.',
};
