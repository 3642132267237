import titleCase from './titleCase';

const titleCaseAll = (str, split = ' ') => {
  if (str == null) {
    return str;
  }
  return str
    .split(split)
    .map(e => titleCase(e))
    .join(' ');
};

export default titleCaseAll;
