import styled from 'styled-components';
export const RadioContainer = styled('div') `
padding: 10px;
display: flex;
margin-top: -3%;
  label {
    margin-right: 1rem;
    margin-bottom: .5rem;
  }
`;
export const DateContainer = styled('div') `
margin-left: 2%;
margin-bottom: 4%;
width: 76%;
@media (max-width: 1199px) {
    width: 100%;
    margin-left: 0;
  }
`;
export const TextContainer = styled('div') `
label {
    display:none;
  }
  width: 76%;
  margin-left: 2%;
  @media (max-width: 1199px) {
    width: 100%;
    margin-left : 0;
  }
`;
export const StyledBanner = styled('div') `
  border-left: 13px solid  #ad112b;
  width: 100%;
  margin-bottom: 2%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  @media (max-width: 767px) {
    margin-top: 4%;
  }
`;
export const StyledUploadDocBanner = styled('div') `
  border-left: 13px solid  #ad112b;
  width: 100%;
  margin-bottom: 3%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  @media (min-width: 768px) {
    min-height: 80px;
  }
`;
export const StyledMessage = styled('div') `
  width: 100%;
  margin-bottom:2%;
  background: #f2f2f2;
  padding: 1rem;
  display: flex;
  @media (max-width: 767px) {
    flex-flow: column;
  }
`;
export const CustomDivider = styled('div') `
 width: 100%;
 height: 6px;
 background:#f2f2f2
 @media (max-width: 374px) {
  width: 90%;
  margin: 0 auto;
}
`;
export const SuccessContainer = styled('div') `
  border-left: 4px solid  #007A25;
  width: 100%;
  margin-bottom: 2%;
  background: #E8F5EB;
  padding: 10px;
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
`;
