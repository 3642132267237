export const SMALL_MID_OFFICE = {
    officeNameLine1: 'Lincoln Financial',
    officeAddressLine: 'PO Box 2609',
    officeCity: 'Omaha',
    officeState: 'NE',
    officeZipCode: '68103-2609',
    officePhoneNumber: '800-423-2765',
    officeFaxNumber: '877-843-3950',
    emailAddr: 'Claims@lfg.com',
};
