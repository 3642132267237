import React from 'react';
import { Alert } from '@digitools/honest-react';
import AbsenceDetailsTable from './AbsenceDetailsTable';

const Error = () => (
  <Alert type={'info'}>
    <span>There are no absence details to display at this time.</span>
  </Alert>
);

const AbsenceDetails = ({ leaveDetails, leave }) => {

  if (!leaveDetails || !leave) {
    return <Error />;
  }

  return (
    <>
      {leave.lveCatg !== 'C' ? <AbsenceDetailsTable leave={leave} leaveId={leave.lveNum} /> : null}
    </>
  );
};

export default AbsenceDetails;
