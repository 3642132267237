import axios from 'axios';
const baseUrl = '/auth-service/services/v1';
export function sendVerificationEmail(username) {
    return axios.post(`${baseUrl}/profile/${username}/verification-email`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
export function sendForgotUsernameEmail(emailAddress, captcha) {
    return axios.post(`${baseUrl}/profile/${emailAddress}/forgot-username-email?g-recaptcha-response=${captcha}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
export function sendForgotPasswordEmail(username, captcha) {
    return axios.post(`${baseUrl}/profile/${username}/forgot-password-email?g-recaptcha-response=${captcha}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
export function getMissingNPPIFields(authHeader) {
    return axios.get(`${baseUrl}/prove/getMissingNPPIFields`, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
    // for local mocking & testing of prove change this line & comment out above
    // return Promise.resolve({ status: 200,
    //   data: {
    //     ssn: true,
    //     dob: true,
    //     phone: true,
    //     identityVerified: false,
    //   }
    // });
}
export function verifyIdentity(verifyData, authHeader) {
    return axios.post(`${baseUrl}/prove/proveVerify`, verifyData, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
    // for local mocking & testing of prove change this line & comment out above
    // return Promise.resolve({
    //   status: 200,
    //   data: {
    //     mfaType: 'SMS',
    //     phoneType: 'MOBILE',
    //     score: 800,
    //     phoneNumber: '6037245750',
    //     valid: true,
    //     wsuid: '4a953ff7-a493-42a6-b655-66d39c4316e4',
    //     trustString: '7983868da5882583eac388dd3065dbc68d331ee7',
    //   },
    // });
}
export function validateProveCode(proveData, authHeader) {
    return axios.post(`${baseUrl}/prove/validateCode`, proveData, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
    // for local mocking & testing of prove change this line & comment out above
    // return Promise.resolve({ status: 200, data: { message: 'TOKEN_EXPIRED', valid: false } });
    // return Promise.resolve({ status: 200, data: { message: 'SUCCESS', valid: true } });
}
export function resendCode(mfaType, wsuid, authHeader) {
    return axios.post(`${baseUrl}/prove/resendCode`, { wsuid, mfaType }, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
    // for local mocking & testing of prove change this line & comment out above
    // return Promise.resolve({ status: 200, data: { message: 'SUCCESS', success: true } });
    // return Promise.resolve({ status: 200, data: { message: 'FAILURE', success: false } });
}
export function getUserMetadata(authHeader, loginId, customerId) {
    return axios.post(`${baseUrl}/applications/external/getUser`, { userId: loginId, customerId: customerId }, {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
        },
    });
}
export function getNewEoiInterviewFlag(authHeader) {
    return axios.get(`/eoi-service/new-interview`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export function getWellnessSAMLURI(authHeader) {
    return axios.get(`/wellness`, {
        headers: {
            Authorization: authHeader,
        },
    });
}
export function migrateUser(lookupKey, password, username) {
    return axios.post(`${baseUrl}/migrate`, { pw: password, username: username, state: lookupKey }, { headers: { 'Content-Type': 'application/json' } });
}
