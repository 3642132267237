export const HOSTNAME = {
    dev: 'https://www.np-mylincolnportal.com',
    nonProd: 'https://www.np-mylincolnportal.com',
    prod: 'https://www.mylincolnportal.com',
};
// will this help at all? 
// export enum EnvironmentVariable {
//   CLIURL: 'cliUrl'
//   cliUrlEE
//   cliUrlCsr
//   mlpHomeUrl
//   lfgHomeUrl
//   lfgLoginUrl
//   auth0Audience
//   auth0Domain
//   auth0SSODomain
//   auth0SSOClientId
//   auth0ClientId
//   logoutUrl
//   auth0RedirectUri
//   userMetadataUrl
//   vanityUrl
//   adminHomeUrl
//   captchaSiteKey
//   superUserRole
//   clientServiceRole
//   LOGROCKET_APP_ID
//   LOGROCKET_APP_SLUG
//   LOGROCKET_API_KEY
//   aciGatewaySSODataUrl
//   gatewaySSODataUrl
//   awsGatewaySSOUrl
//   pageLoaderUrl
//   groupSsoUrl
//   sourceSystemRSServiceUrl
//   secureLogoutUrl
//   lfgAuthUrl
//   lfgAuthClientId
//   keepAliveGifUrl
//   ssoErrorUrl
//   formPageUrl
//   lfgPublicPasswordResetUrl
//   enableEmployeeMigration
//   enableEmployeeMigrationForSoftLaunch
//   statusNewCoverageDetails
// }
const ENVIRONMENT_VARIABLES = {
    cliUrl: {
        dev: HOSTNAME.nonProd + '/cli/employer',
        nonProd: HOSTNAME.nonProd + '/cli/employer',
        prod: HOSTNAME.prod + '/cli/employer',
    },
    cliUrlEE: {
        dev: HOSTNAME.nonProd + '/cli/employee',
        nonProd: HOSTNAME.nonProd + '/cli/employee',
        prod: HOSTNAME.prod + '/cli/employee',
    },
    cliUrlCsr: {
        dev: HOSTNAME.nonProd + '/customer/cli/employer/intake',
        nonProd: HOSTNAME.nonProd + '/customer/cli/employer/intake',
        prod: HOSTNAME.prod + '/customer/cli/employer/intake',
    },
    dentalGraphqlUrl: {
        dev: HOSTNAME.nonProd + '/mlp-dental-consumer-bff/graphql',
        nonProd: HOSTNAME.nonProd + '/mlp-dental-consumer-bff/graphql',
        prod: HOSTNAME.prod + '/mlp-dental-consumer-bff/graphql'
    },
    mlpHomeUrl: {
        dev: HOSTNAME.nonProd,
        nonProd: HOSTNAME.nonProd,
        prod: HOSTNAME.prod
    },
    lfgHomeUrl: {
        dev: 'https://lincolnfinancial-test.lfg.com',
        nonProd: 'https://lincolnfinancial-uat.lfg.com',
        prod: 'https://www.lincolnfinancial.com',
    },
    lfgLoginUrl: {
        dev: 'https://lincolnfinancial-test.lfg.com/secure/login',
        nonProd: 'https://lincolnfinancial-uat.lfg.com/secure/login',
        prod: 'https://www.lincolnfinancial.com',
    },
    auth0Audience: {
        dev: 'com.lfg.digital.portal.api.external',
        nonProd: 'com.lfg.digital.portal.api.external',
        prod: 'com.lfg.digital.portal.api.external',
    },
    auth0Domain: {
        dev: 'auth.np-mylincolnportal.com',
        nonProd: 'auth.np-mylincolnportal.com',
        prod: 'auth.mylincolnportal.com',
    },
    auth0SSODomain: {
        dev: 'lfg-digital-nonprod.auth0.com',
        nonProd: 'lfg-digital-nonprod.auth0.com',
        prod: 'lfg-digital-prod.auth0.com',
    },
    auth0SSOClientId: {
        dev: 'Ci391HYRT2cumDEkpk61px1IapH4Tqsc',
        nonProd: 'Ci391HYRT2cumDEkpk61px1IapH4Tqsc',
        prod: 'W6TPp09kDXDFw9aPbcVEQSxMr1Jp0CEp',
    },
    auth0ClientId: {
        dev: 'K0lUWKo6UULzHMcA9fDyzaULeQ3uJSSB',
        nonProd: 'K0lUWKo6UULzHMcA9fDyzaULeQ3uJSSB',
        prod: '73uPBinxirU6YxGVrqQhc8DTwKAYcyBS',
    },
    logoutUrl: {
        dev: `http://${window.location.hostname}:8091/customer/public/login`,
        nonProd: HOSTNAME.nonProd + '/customer/public/login',
        prod: HOSTNAME.prod + '/customer/public/login',
    },
    auth0RedirectUri: {
        // for dev/local have to use old method of callback -- pair with "@digitools/auth0-token-manager": "0.5.0",
        dev: `http://${window.location.hostname}:8091/customer/v2/callback`,
        nonProd: HOSTNAME.nonProd + '/authn-callback',
        prod: HOSTNAME.prod + '/authn-callback',
    },
    userMetadataUrl: {
        dev: 'https://www.np-mylincolnportal.com/userMetadata',
        nonProd: 'https://www.np-mylincolnportal.com/userMetadata',
        prod: 'https://www.mylincolnportal.com/userMetadata',
    },
    vanityUrl: {
        dev: 'https://www.np-mylincolnportal.com/',
        nonProd: 'https://www.np-mylincolnportal.com/',
        prod: 'https://www.mylincolnportal.com/',
    },
    adminHomeUrl: {
        dev: `http://${window.location.hostname}:8090`,
        nonProd: 'https://admin.np-mylincolnportal.com',
        prod: 'https://admin.mylincolnportal.com',
    },
    captchaSiteKey: {
        dev: '6LeXM5wUAAAAAP37apBXON-2qIcKyQRccbu8jLvm',
        nonProd: '6Lct-pwUAAAAADGsU6_E2VR8Vbxe8lxio1GqKiQT',
        prod: '6Ldf-pwUAAAAAOyptuxeI0rMTe5jOiPsooza-zEP',
    },
    superUserRole: {
        dev: 'CORSAdmin',
        nonProd: 'CORSAdmin',
        prod: 'CORSAdmin',
    },
    clientServiceRole: {
        dev: 'GB_ECR_Group_Role_Client_Service_Rep',
        nonProd: 'GB_ECR_Group_Role_Client_Service_Rep',
        prod: 'GB_ECR_Group_Role_Client_Service_Rep',
    },
    LOGROCKET_APP_ID: {
        dev: 'o1n3rt/my-lincoln-portal-non-prod',
        nonProd: 'o1n3rt/my-lincoln-portal-non-prod',
        prod: 'o1n3rt/my-lincoln-portal-production',
    },
    LOGROCKET_APP_SLUG: {
        dev: 'my-lincoln-portal-non-prod',
        nonProd: 'my-lincoln-portal-non-prod',
        prod: 'my-lincoln-portal-production',
    },
    LOGROCKET_API_KEY: {
        dev: 'o1n3rt:my-lincoln-portal-non-prod:ep44uwZwFgzJHe90D7KV',
        nonProd: 'o1n3rt:my-lincoln-portal-non-prod:ep44uwZwFgzJHe90D7KV',
        prod: 'o1n3rt:my-lincoln-portal-production:wPW5GgJRe0ufWqM8HiMe',
    },
    aciGatewaySSODataUrl: {
        dev: 'https://api.ss-gpomni-dev.web.lfg.com/ssgpomniservice/converge-sso/vitech',
        nonProd: 'https://api.ss-gpomni-test.web.lfg.com/ssgpomniservice/converge-sso/vitech',
        prod: 'https://api.ss-gpomni.web.lfg.com/ssgpomniservice/converge-sso/vitech',
    },
    gatewaySSODataUrl: {
        dev: 'https://api.ss-gpomni-dev.web.lfg.com/ssgpomniservice/absence-management-sso/absencemanagement',
        nonProd: 'https://api.ss-gpomni-test.web.lfg.com/ssgpomniservice/absence-management-sso/absencemanagement',
        prod: 'https://api.ss-gpomni.web.lfg.com/ssgpomniservice/absence-management-sso/absencemanagement',
    },
    awsGatewaySSOUrl: {
        dev: '/aws-gateway-service-app/rest/aws-gateway/awsGatewayData',
        nonProd: '/aws-gateway-service-app/rest/aws-gateway/awsGatewayData',
        prod: '/aws-gateway-service-app/rest/aws-gateway/awsGatewayData',
    },
    pageLoaderUrl: {
        dev: '/secure/static/hub-global/templates/pages/page-loader.html',
        nonProd: '/secure/static/hub-global/templates/pages/page-loader.html',
        prod: '/secure/static/hub-global/templates/pages/page-loader.html',
    },
    groupSsoUrl: {
        dev: 'https://lincoln4benefits-qa.lfg.com/websecure/sso_logon.asp',
        nonProd: 'https://lincoln4benefits-qa.lfg.com/websecure/sso_logon.asp',
        prod: 'https://www.lincoln4benefits.com/websecure/sso_logon.asp',
    },
    sourceSystemRSServiceUrl: {
        dev: '/secure/service/hub-consumer-service-app/rest/consumer/destinationSiteData',
        nonProd: '/secure/service/hub-consumer-service-app/rest/consumer/destinationSiteData',
        prod: '/secure/service/hub-consumer-service-app/rest/consumer/destinationSiteData',
    },
    secureLogoutUrl: {
        dev: 'https://lincolnfinancial-test.lfg.com/secure/logout',
        nonProd: 'https://lincolnfinancial-test.lfg.com/secure/logout',
        prod: 'https://lincolnfinancial.com/secure/logout',
    },
    lfgAuthUrl: {
        dev: 'https://auth.lincolnfinancial-test.lfg.com',
        nonProd: 'https://auth.lincolnfinancial-test.lfg.com',
        prod: 'https://auth.lincolnfinancial.lfg.com',
    },
    lfgAuthClientId: {
        dev: 'l3ONQaNArM2yXuaClCfVVnaD359Y8YbR',
        nonProd: 'l3ONQaNArM2yXuaClCfVVnaD359Y8YbR',
        prod: 'tBse6ehbkVjb7UFAGLco52HIGbPNdTjX',
    },
    keepAliveGifUrl: {
        dev: 'https://lincolnfinancial-test.lfg.com/secure/static/hub-global/assets/images/session/keepalive.gif',
        nonProd: 'https://lincolnfinancial-test.lfg.com/secure/static/hub-global/assets/images/session/keepalive.gif',
        prod: 'https://lincolnfinancial.lfg.com/secure/static/hub-global/assets/images/session/keepalive.gif',
    },
    ssoErrorUrl: {
        dev: 'https://www.lfg.com/lfg-static/pblstatic/error/404/404.html',
        nonProd: 'https://www.lfg.com/lfg-static/pblstatic/error/404/404.html',
        prod: 'https://www.lfg.com/lfg-static/pblstatic/error/404/404.html',
    },
    formPageUrl: {
        dev: 'https://www.lincoln4benefits.com/jpfebill/forms/gfrmsel2.asp',
        nonProd: 'https://www.lincoln4benefits.com/jpfebill/forms/gfrmsel2.asp',
        prod: 'https://www.lincoln4benefits.com/jpfebill/forms/gfrmsel2.asp',
    },
    lfgPublicPasswordResetUrl: {
        dev: 'https://idm-uat.web.lfg.com/idm-self-service/index.html#/forgot-pwd?_cmatype=open',
        nonProd: 'https://idm-uat.web.lfg.com/idm-self-service/index.html#/forgot-pwd?_cmatype=open',
        prod: 'https://idm.web.lfg.com/idm-self-service/index.html#/forgot-pwd?_cmatype=open',
    },
    enableEmployeeMigration: {
        dev: true,
        nonProd: true,
        prod: true,
    },
    enableEmployeeMigrationForSoftLaunch: {
        dev: true,
        nonProd: true,
        prod: true,
    },
    statusNewCoverageDetails: {
        dev: true,
        nonProd: true,
        prod: true,
    },
    statusNewAbsenceDetails: {
        dev: true,
        nonProd: true,
        prod: true,
    },
    enableCategorizationForS1: {
        dev: true,
        nonProd: true,
        prod: true,
    },
    enableCategorizationForS1Rtw: {
        dev: true,
        nonProd: true,
        prod: false,
    },
    enableNewSurgeryFlow: {
        dev: true,
        nonProd: true,
        prod: false,
    }
};
export default ENVIRONMENT_VARIABLES;
