import { Alert, Heading } from '@digitools/honest-react';
import React, { useContext, useEffect } from 'react';
import { AuthenticationContext } from '../../../providers/AuthenticationProvider';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { EventAction, EventCategory, GoogleAnalyticsContext } from '../../../providers/GoogleAnalyticsProvider';
import { gaLabelFactory } from '../../../utils/gaUtils';
import getDisplayText from '../../../utils/languageDisplay';
import { VerifyEmailDisplayTextEn } from './i18n/VerifyEmailDisplayTextEn';
import { VerifyEmailDisplayTextEs } from './i18n/VerifyEmailDisplayTextEs';
import PublicContainer from '../shared/PublicContainer';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
const generateGALabel = gaLabelFactory('REREG_SUCCESS');
const EnrollmentSuccess = () => {
    const { trackEvent } = useContext(GoogleAnalyticsContext);
    const { logout } = useContext(AuthenticationContext);
    const { language } = useContext(ExperienceContext);
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.ENROLLMENT,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.ENROLLMENT.SUCCESS,
        });
    }, []);
    const handleLogout = () => {
        trackEvent(EventCategory.LINK, EventAction.CLICK, generateGALabel('logout'));
        logout();
    };
    return (<PublicContainer>
      <Heading className={'m-0'}>
        {getDisplayText('enrollmentTitle', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}
      </Heading>

      <Alert>{getDisplayText('emailSentSuccess', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}</Alert>

      <p>{getDisplayText('enrollmentSuccessBody1', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}</p>

      <p>
        {getDisplayText('enrollmentSuccessBody2', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}
      </p>
    </PublicContainer>);
};
export default EnrollmentSuccess;
