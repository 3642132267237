import { Button, Checkbox, CheckboxGroup, Col, Collapse, Form, InputMessage, Row, SelectInput, TextInput, } from '@digitools/honest-react';
import { BDM_COUNTRIES, BDM_COUNTRIES_ES, BDM_PROVINCES, BDM_STATES, EMPLOYEE_TEXT } from '../constants/BdmConstants';
import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { saveBeneficiaries } from '../../../api/BdmApi';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import { isNotEmpty } from '../../../utils/validationUtils';
import { BdmDisplayTextEn as LanguageEn } from '../language/BdmDisplayTextEn';
import { BdmDisplayTextEs as LanguageEs } from '../language/BdmDisplayTextEs';
import { Language } from '../../../types/Language';
import { UnexpectedErrorContext } from '../../../providers/UnexpectedErrorProvider';
import { formatInTimeZone } from 'date-fns-tz';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const StyledLabel = styled('label') `
  font-weight: 700;
`;
const StyledTextInput = styled(TextInput) `
  ::placeholder {
    color: #808080;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #808080;
  }
`;
const StyledCol = styled(Col) `
  @media (max-width: 992px) {
    margin-top: 0.5rem;
  }
`;
export const BdmSignature = ({ contingentBeneficiaries, contingentEqualShares, setSignatureData, primaryBeneficiaries, primaryEqualShares, setShowConfirmationPage, empId, ssn, id, dob, zip, }) => {
    const config = useBdmLciConfig();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    const { trackEvent } = useTealium();
    const [agreedState, setAgreedStatus] = useState(false);
    const [countrySelected, setCountrySelected] = useState('USA');
    const [dateSigned, setDateSigned] = useState('');
    const [missingPrimaryBeneError, setMissingPrimaryBeneError] = useState(false);
    const [missingAllocationError, setMissingAllocationError] = useState(false);
    const [insufficientAllocationError, setInsufficientAllocationError] = useState(false);
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    useEffect(() => setIsMessagingDisabled(false), []);
    const employeeText = BdmBeneficiaryUtils.getCustomContent(config.customContents, EMPLOYEE_TEXT, Language.ENGLISH);
    const displayText = language === Language.SPANISH ? LanguageEs : LanguageEn;
    const checkForFormErrors = () => {
        const noPrimaryBene = primaryBeneficiaries.length === 0;
        let insufficientAllocationPrimary = false;
        let insufficientAllocationContingent = false;
        let missingAllocation = 0;
        if (!primaryEqualShares) {
            const primaryBeneAllocation = primaryBeneficiaries.reduce((total, ben) => {
                if (!ben.allocation || parseInt(ben.allocation) === 0) {
                    missingAllocation++;
                }
                return total + parseInt(ben.allocation);
            }, 0);
            insufficientAllocationPrimary = primaryBeneAllocation !== 100;
        }
        if (!contingentEqualShares) {
            const contingentBeneAllocation = contingentBeneficiaries.reduce((total, ben) => {
                if (!ben.allocation || parseInt(ben.allocation) === 0) {
                    missingAllocation++;
                }
                return total + parseInt(ben.allocation);
            }, 0);
            insufficientAllocationContingent = contingentBeneficiaries.length !== 0 && contingentBeneAllocation !== 100;
        }
        const insufficientAllocation = insufficientAllocationPrimary || insufficientAllocationContingent;
        setMissingPrimaryBeneError(noPrimaryBene);
        setMissingAllocationError(insufficientAllocation);
        setInsufficientAllocationError(!!missingAllocation);
        const hasErrors = noPrimaryBene || insufficientAllocation || !!missingAllocation;
        return hasErrors;
    };
    const handleChange = (event) => {
        if (event) {
            const dateSigned = formatInTimeZone(new Date(), 'America/New_York', 'P, hh:mm a');
            setDateSigned(dateSigned);
        }
        else {
            setDateSigned('');
        }
    };
    const handleSubmit = async (values) => {
        const hasErrors = checkForFormErrors();
        if (!hasErrors) {
            const mappedPrimaryBens = primaryBeneficiaries.map(ben => BdmBeneficiaryUtils.convertProvinceFieldName(ben));
            const mappedContingentBens = contingentBeneficiaries.map(ben => BdmBeneficiaryUtils.convertProvinceFieldName(ben));
            const mappedSignatureData = BdmBeneficiaryUtils.convertProvinceFieldName(values);
            mappedSignatureData.dateSigned = dateSigned;
            mappedSignatureData.associateSignature = values.associateSignature.trim();
            mappedSignatureData.city = values.city.trim();
            const dataToBackend = {
                contingentBeneficiaries: mappedContingentBens,
                equalSharesContingent: mappedContingentBens.length > 0 ? contingentEqualShares : false,
                equalSharesPrimary: primaryEqualShares,
                primaryBeneficiaries: mappedPrimaryBens,
                signatureData: mappedSignatureData,
                language,
                employeeId: empId || null,
                ssn: ssn || null,
                id: id || null,
                dateOfBirth: dob || null,
                zipCode: zip || null,
            };
            const signature = { ...mappedSignatureData };
            const status = await saveBeneficiaries(authHeader(), dataToBackend);
            if (status === 200) {
                setSignatureData(signature);
                setShowConfirmationPage(true);
                // ToDo: Refresh token
            }
        }
        //trackClickWithPage('Submit', BDM_BENEFICIARIES_GA_ROUTE);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.SIGNATURE_SUBMIT,
            event_name: 'Submit',
        });
    };
    const validateSignatureCheckBox = (value) => {
        if (!value || value.length < 1) {
            setAgreedStatus(false);
            return displayText.isAgreedError;
        }
        else {
            setAgreedStatus(true);
            return undefined;
        }
    };
    return (<Collapse headerElemType={'h2'} type='box' title={displayText.signatureTitle} isOpen={true}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} xs={12}>
            <p>{displayText.signatureText}</p>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <CheckboxGroup hideLabel={true} field={'checkboxInputs'} label={''} validate={validateSignatureCheckBox} validateOnChange={true}>
              <Checkbox data-testid={'isAgreed'} field={'isAgreed'} label={displayText.agreeCheckbox} initialValue={false} onValueChange={handleChange}/>
            </CheckboxGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <StyledTextInput ariaLabelText={'Signature'} field={'associateSignature'} label={employeeText + displayText.signature} mask={BdmBeneficiaryUtils.formatSigAndCity} maxLength={75} placeholder={employeeText + displayText.signaturePlaceholder} type={'text'} validate={isNotEmpty} validateOnBlur={true} data-private={true}/>
          </Col>
          <StyledCol md={4} sm={12}>
            <StyledLabel htmlFor='dateSigned'>{displayText.dateSigned}</StyledLabel>
            <p data-testid='date-signed' id='dateSigned'>{dateSigned ? `${dateSigned} ${displayText.timeZone}` : ''}</p>
          </StyledCol>
        </Row>
        <Row>
          <Col md={4} xs={4}>
            <SelectInput ariaLabelText={'Country'} field={'country'} label={displayText.country} validateOnBlur={true} validate={isNotEmpty} options={language === Language.SPANISH ? BDM_COUNTRIES_ES : BDM_COUNTRIES} initialValue={'USA'} onValueChange={(value) => setCountrySelected(value)}/>
          </Col>
          <Col md={4} xs={4}>
            {countrySelected === 'USA' && (<SelectInput ariaLabelText={'State'} field={'state'} label={displayText.state} placeholderText={displayText.select} validateOnBlur={true} validate={isNotEmpty} options={BDM_STATES}/>)}
            {countrySelected === 'CANADA' && (<SelectInput ariaLabelText={'Province'} field={'province'} label={displayText.province} placeholderText={displayText.select} validateOnBlur={true} validate={isNotEmpty} options={BDM_PROVINCES}/>)}
            {countrySelected === 'OTHERCOUNTRIES' && (<TextInput ariaLabelText={'Other Address'} field={'otherAddress'} label={displayText.otherAddress} mask={BdmBeneficiaryUtils.formatOtherAddress} maxLength={255} textarea={true} resize={'vertical'} rows={1} validate={isNotEmpty} validateOnBlur={true}/>)}
          </Col>
          <Col md={4} xs={4}>
            <TextInput ariaLabelText={'City'} field={'city'} label={displayText.city} mask={BdmBeneficiaryUtils.formatSigAndCity} maxLength={30} type={'text'} validate={isNotEmpty} validateOnBlur={true} validateOnChange={true}/>
          </Col>
        </Row>
        <Button className={'mt-4'} buttonType={'primary'} size={'medium'} id='btn-submit-beneficiaries' type='submit' disabled={!agreedState}>
          {displayText.submitChangesButton}
        </Button>
      </Form>
      {missingPrimaryBeneError && (<InputMessage id={'error-noPrimaryBene'} error={true}>
          {displayText.noPrimaryBenError}
        </InputMessage>)}
      {missingAllocationError && (<InputMessage id={'error-insufficientAllocation'} error={true}>
          {displayText.totalNotHundredPercentError}
        </InputMessage>)}
      {insufficientAllocationError && (<InputMessage id={'error-missingAllocation'} error={true}>
          {displayText.allocationCannotBezeroError}
        </InputMessage>)}
    </Collapse>);
};
