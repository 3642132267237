import { Col } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField from '../ReviewField';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
const ReviewFamilyParentalFlow = () => {
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { relationships } = useCliCustomerConfigContext();
    const shouldDisplayRelationship = !(absenceData?.leaveReason === 'AD' || absenceData?.leaveReason === 'FC');
    const shouldDisplayPlacementDate = absenceData?.relationship === 'FC' || absenceData?.leaveReason === 'AD';
    const shouldDisplayOtherRelationship = absenceData?.relationship === 'OT';
    const shouldDisplayCareRecipientDob = (shouldDisplayOtherRelationship && absenceData?.otherRelationship) ||
        (!shouldDisplayOtherRelationship && absenceData?.relationship);
    return (<>
            {absenceData?.leaveType && (<>
                    {shouldDisplayRelationship && (<Col xs={12} md={6} lg={6} data-testid='relationship'>
                            <ReviewField label={t({ english: 'Relationship', spanish: 'relación' })} value={absenceData && (t(relationships.filter(relationship => relationship.code == absenceData?.relationship)[0]))}/>
                        </Col>)}
                    {shouldDisplayOtherRelationship && (<Col xs={12} md={6} lg={6} data-testid='otherRelationship'>
                            <ReviewField label={t(formLabels.otherRelationship)} value={absenceData && absenceData.otherRelationship}/>
                        </Col>)}
                    {shouldDisplayPlacementDate && (<Col xs={12} md={6} lg={6} data-testid='placementDate'>
                            <ReviewField label={t(formLabels.placementDateShortLabel)} value={absenceData && absenceData.careRecipientDateOfBirth}/>
                        </Col>)}
                    {shouldDisplayCareRecipientDob && (<Col xs={12} md={6} lg={6} data-testid='careRecipientDateOfBirth'>
                            <ReviewField label={t(formLabels.careRecipientDateOfBirthShortLabel)} value={absenceData && absenceData.careRecipientDateOfBirth}/>
                        </Col>)}
                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-testid='leaveBeginDate' label={t(formLabels.leaveBeginDateShortLabel)} value={absenceData.leaveBeginDate}/>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-testid='leaveEndDate' label={t(formLabels.leaveEndDateShortLabel)} value={absenceData.leaveEndDate}/>
                    </Col>
                </>)}
        </>);
};
export default ReviewFamilyParentalFlow;
