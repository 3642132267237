import React from 'react';
import { RtwContext } from '../context';

const ReturnToWorkProvider = ({ children }) => {
  const [rtwNavigation, setRtwNavigation] = React.useState(false);

  const saveRtwNavigation = () => {
    setRtwNavigation(true);
  };
  const removeRtwNavigation = () => {
    setRtwNavigation(false);
  };

  return (
    <RtwContext.Provider value={{ rtwNavigation, saveRtwNavigation, removeRtwNavigation }}>
      {children}
    </RtwContext.Provider>
  );
};

export default ReturnToWorkProvider;
