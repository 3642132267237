export const CliSaveForLaterText = {
    cliViewApplication: {
        english: 'VIEW MY APPLICATION',
        spanish: 'VER MI APLICACIÓN'
    },
    cliSaveForLater: {
        english: 'Save for later',
        spanish: 'Guardar para mas tarde'
    },
    loadSavedCliHeader: {
        english: 'Pick up where you left off!',
        spanish: '¡Continúe donde lo dejó!',
    },
    loadSavedCliBody: {
        english: 'Your application was saved on ',
        spanish: 'Su solicitud se guardó el ',
    },
    at: {
        english: ' at ',
        spanish: ' a las ',
    },
};
