import React, { useEffect } from 'react';
import { Heading, Spinner } from '@digitools/honest-react';
import styles from './EOI.scss';
import { useAuth0, useLanguage } from 'status/hooks';
import useGet from '../../../../../hooks/useGet';
import titleCase from 'status/utilities/titleCase';
import EoiInfo from './EoiInfo';
import { useTranslation } from 'react-i18next';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
const EOI = () => {
    const { userMeta } = useAuth0();
    const { language } = useLanguage();
    const { t } = useTranslation();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYEE_INFO,
        });
    }, []);
    const { error: eoiError, loading: loadingEoi, data: eoiData, } = useGet({
        url: '/status-service/eoi/status',
        options: {
            params: {
                languageCode: language,
            },
        },
    }, [language]);
    const loading = loadingEoi;
    if (loading) {
        return <Spinner id='eoi-spinner'/>;
    }
    const name = `${titleCase(userMeta.firstName)} ${titleCase(userMeta.lastName)}`;
    return (<div className={styles.container}>
      <div className='mb-4'>
        <Heading data-testid='status-header'>{t('statusHeader')}</Heading>
        <Heading elemType={'h2'} elemStyle={'h3'}>
          {t('eoiHeaderText')}
        </Heading>
        <Heading color={'primary'} elemType={'h3'}>
          {name}
        </Heading>
      </div>
      <EoiInfo eoi={eoiData} error={eoiError}/>
    </div>);
};
export default EOI;
