import React, { useEffect } from 'react';
import { LanguageContext } from '../context';
import useExperience from 'src/hooks/useExperience';
import { useTranslation } from 'react-i18next';

const LanguageProvider = ({ children }) => {
  const { changeLanguage, language } = useExperience();

  const { i18n } = useTranslation();

  const setLanguage = lang => {
    changeLanguage(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
