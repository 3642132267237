import React, { useRef, useEffect, useState } from 'react';
import { Heading, Alert, Col, Row, Box, Collapse, themes } from '@digitools/honest-react';
import Spinner from './../../Spinner/Spinner';
import Watchlist from '../Watchlist/Watchlist';
import { useTranslation } from 'react-i18next';
import useGet from '../../../../../hooks/useGet';
import { Form } from 'informed';
import EmployeeSearchResults from './EmployeeSearchResults';
import EmployeeSearchForm from './EmployeeSearchForm';
import queryString from 'query-string';
import styled from 'styled-components';
import search from './search';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import useTealium from '../../../../../hooks/useTealium';
import { withRouter } from 'src/utils/withRouter';
const StyledRow = styled(Row) `
  margin-top: 1rem;
`;
// Helper for generating search url based on search criteria
const getSearchUrl = (searchCrit) => {
    switch (searchCrit) {
        case 'empLastName':
            return 'employee/search/searchByLastName';
        case 'empId':
            return 'employee/search/searchByEmpId';
        case 'claimNum':
            return 'claims/search/searchByClaimNum';
        default:
            return 'employee/search/searchByLastName';
    }
};
const Error = (error) => {
    const notFound = (error.response && error.response.status === 404);
    const type = notFound ? 'info' : 'error';
    return (<Alert type={type}>
      {notFound ? ('There were no matches found based on your search criteria') :
            (<span><strong>Error!</strong>&nbsp;Something went wrong when trying to perform search.</span>)}
    </Alert>);
};
const MetaError = () => (<Alert type={'warning'}>
    <span>Unable to load search page at this time, please try again.</span>
  </Alert>);
const EmployeeSearch = ({ match }) => {
    const { t } = useTranslation();
    // read the search from the URL (searches made will change the current URL so you can link to it)
    const parsed = queryString.parse(location.search);
    const initialSearchString = parsed.search;
    const initialSearchCrit = parsed.searchCrit;
    const [searchValues, setSearchValues] = useState({});
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYEE_SEARCH,
        });
    }, []);
    // Refs used to cancel requests
    // there are two because when you search for claim we also search for leave
    // TODO may be better way to optomize this
    const cancel = useRef();
    // Queue up get request for searching, to be executed later
    const searchResult = useGet({
        url: '/status-service/employee/search/searchByLastName',
        noInitial: true,
    });
    // destructure searchResult values - error to be reassignable so it can be cleared on search click
    const { loading, data, get: searchGet } = searchResult;
    let { error } = searchResult;
    const { error: searchMetaError, loading: searchMetaLoading, data: searchMetaData } = useGet({
        url: '/status-service/customer/searchMetadata',
    });
    // 🤷‍♂️
    const employeeSearch = (values) => search(values, setSearchValues, cancel, error, getSearchUrl, searchGet);
    useEffect(() => {
        if (initialSearchString) {
            employeeSearch({
                search: initialSearchString,
                searchCriteria: initialSearchCrit,
            });
        }
    }, [initialSearchString]);
    if (searchMetaLoading) {
        return <Spinner id='ee-search-spinner' data-testid='loading-searchmeta'/>;
    }
    if (searchMetaError) {
        return <MetaError />;
    }
    return (<div data-public={true}>
      <div className='mb-4'>
        <Heading data-testid='status-header'>{t('statusHeader').toString()}</Heading>
      </div>
      <div>
        <Watchlist from={'search'}/>
        <Box style={{ marginTop: '2rem', border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
          <Collapse title='Employee search' isOpen={true} type={'alternate'} theme={themes.lincoln}>
            <StyledRow>
              <Col lg={12}>
                <Form onSubmit={(values) => employeeSearch(values)} initialValues={{
            search: initialSearchString,
            searchCriteria: initialSearchCrit,
        }}>
                  <EmployeeSearchForm loading={loading} searchMeta={searchMetaData}/>
                </Form>
              </Col>
            </StyledRow>
            <br />
            <EmployeeSearchResults results={data} searchMeta={searchMetaData} searchValues={searchValues}/>
            <div>{error ? <Error {...error}/> : null}</div>
          </Collapse>
        </Box>
      </div>
    </div>);
};
export default withRouter(EmployeeSearch);
