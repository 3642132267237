export const CliVerificationText = {
    pageTitleER: {
        english: 'Employee\'s personal identification',
        spanish: 'Identificación personal del Empleado',
    },
    pageTitleEE: {
        english: 'Personal identification',
        spanish: 'Identificación personal',
    },
    verificationHeadingEE: {
        english: 'First, we need to establish your identity',
        spanish: 'En primer lugar, debemos confirmar su identidad.',
    },
    verificationHeadingER: {
        english: 'First, we need to establish your Employee\'s identity',
        spanish: 'First, we need to establish your Employee\'s identity',
    },
    verificationSubHeading: {
        english: 'Given this information, we\'ll check our systems and prepopulate as much information as we can for you.',
        spanish: 'Una vez que tengamos esta información, comprobaremos nuestros sistemas y completaremos toda la información que podamos por usted.',
    },
    employeeIdLabel: {
        english: 'Employee ID',
        spanish: 'N.° de identificación del empleado',
    },
    ssnLabel: {
        english: 'Social security number',
        spanish: 'Número de Seguro Social',
    },
    verifyButton: {
        english: 'Continue',
        spanish: 'Continuar',
    },
    verifyRequiredError: {
        english: 'This field is required',
        spanish: 'Este campo es requerido',
    },
    invalidEmpId: {
        english: 'Invalid Employee ID',
        spanish: 'ID de empleado no válido',
    },
    invalidSsn: {
        english: 'Invalid Social security number',
        spanish: 'Número de seguridad social no válido',
    },
    verificationNotFound: {
        english: 'We were not able to find your information, please try again',
        spanish: 'No pudimos encontrar tu información, vuelve a intentarlo',
    },
    verifyLimitReached: {
        english: `We're sorry we couldn't find your information using employee ID, please enter your Social security number to proceed`,
        spanish: 'Lamentamos no poder encontrar su información usando la identificación de empleado, ingrese su Número de seguridad social para continuar',
    },
    verifyRadioButtonLegend: {
        english: `Choose to display Social security number or Employee ID text fields.`,
        spanish: 'Elija mostrar los campos de texto Número de seguro social o ID de empleado.',
    },
};
