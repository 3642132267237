import axios from 'axios';
import LogRocket from 'logrocket';
var recordingURL;
LogRocket.getSessionURL(sessionURL => {
    recordingURL = sessionURL;
});
export const cancelToken = (uuid) => {
    const accessToken = window.sessionStorage.getItem('mlpBearerToken');
    if (accessToken && uuid !== '' && uuid !== null) {
        return axios.post(`/token-blacklist-service/api/token`, { uuid }, {
            headers: { authorization: `Bearer ${accessToken}`, 'X-LogRocket-URL': recordingURL },
        });
    }
};
