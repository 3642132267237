import { Row, Col } from '@digitools/honest-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Payment.scss';

/**
 * This Offset display is used for Offset and Other deductions
 */
const OffsetDetails = ({ type, claimPayment }) => {
  const { t } = useTranslation();

  let noDataMessage = 'There is no data to display';
  let totalAmount = '$0';
  let offsets = [];
  let title = '';

  if (type === 'offset') {
    title = t('checkTotalOffsetAmountText');
    noDataMessage = t('noDataMessageOffset');
    totalAmount = claimPayment.totalOffsetDeductions;
    offsets = claimPayment.checkOffsetDeductions;
  } else {
    title = t('checkTotalDeductionAmountText');
    noDataMessage = t('noDataMessageOtherDeductions');
    totalAmount = claimPayment.totalOtherDeductions;
    offsets = claimPayment.checkOtherDeductions;
  }

  return (
    <div className={styles.offsetGridContainer}>
      <Row style={{ border: 'none' }} className={'pb-2'}>
        <Col md={12} data-testid={'offsetHeader'}>
          <b>{title}</b>
        </Col>
      </Row>
      {offsets && offsets.length > 0 ? (
        <>
          <Row style={{ border: 'none', marginTop: '0.5rem' }} data-testid={'offsetRowHeader'}>
            <Col md={3} className={'pr-0'}>
              <b>{t('beginDateText')}</b>
            </Col>
            <Col md={3} className={'pr-0'}>
              <b>{t('endDateText')}</b>
            </Col>
            <Col md={2} className={styles.alignRight}>
              <b>{t('amountText')}</b>
            </Col>
            <Col md={4}>
              <b>{t('reasonText')}</b>
            </Col>
          </Row>

          <div data-testid={'offsetGrid'}>
            {offsets.map((offset, i) => {
              return (
                <Row key={i} className={'mt-2'}>
                  <Col md={3}>{offset.fromDate}</Col>
                  <Col md={3}>{offset.throughDate}</Col>
                  <Col md={2} className={styles.alignRight}>
                    {offset.amount}
                  </Col>
                  <Col md={4}>{offset.reason}</Col>
                </Row>
              );
            })}
          </div>

          <Row style={{ border: 'none' }} className={'mt-2'}>
            <Col data-testid='offsetTotal' md={6}>
              <b>{t('totalText')}</b>
            </Col>
            <Col md={2} className={styles.alignRight}>
              <b>{totalAmount}</b>
            </Col>
          </Row>
        </>
      ) : (
        <div>{noDataMessage}</div>
      )}
    </div>
  );
};

export default OffsetDetails;
