import React, { useContext, useEffect, useState } from 'react';
import { Box, Collapse, Col, Row, Icon, Link, StepperContext, Button, Alert, Heading } from '@digitools/honest-react';
import { returnLabelByValue } from './../../utils/reasonForTimeOffTypes';
import Utils from './../../utils/CustomerUtils';
import EmployeeNameLogout from '../employeeNameLogout/EmployeeNameLogout';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
const VerifySubmission = ({ timeOffValues, employeeName, triggerFormReset, resetState, submitUA }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const stepper = useContext(StepperContext);
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.VERIFY_SUBMISSION,
        });
    }, []);
    const edit = () => {
        trackClickWithPage('Edit', '/uaMobile/verifySubmission');
        setShowError(false);
        stepper.back();
    };
    const submit = async () => {
        trackClickWithPage('Submit', '/uaMobile/verifySubmission');
        const res = await submitUA();
        if (res === 200) {
            setShowError(false);
            stepper.next();
        }
        else if (res === 409) {
            setErrorMessage('A conflict was found with an existing absence request. To modify existing absences, please use My Lincoln Portal instead.');
            setShowError(true);
        }
        else {
            setErrorMessage('Something went wrong. Please try again momentarily.');
            setShowError(true);
        }
    };
    const cancel = () => {
        trackClickWithPage('Cancel Request', '/uaMobile/verifySubmission');
        triggerFormReset();
        stepper.goToStep(0);
        resetState();
    };
    const closeAlert = () => {
        setShowError(false);
        setErrorMessage('');
    };
    const alert = showError ? (<Alert id='error' type='error' closeable={true} onClose={closeAlert}>
      {errorMessage}
    </Alert>) : (<></>);
    const renderTypeAndTime = () => {
        switch (timeOffValues.timeOffType) {
            case ('OUT'):
                if (!timeOffValues.spansMidnight) {
                    return (<div data-testid='absent-no'>
              <p><strong>Type: </strong>{'Absent for Full Day'}</p>
              <p><strong>Duration: </strong>{`${timeOffValues.durationHours} Hr, ${timeOffValues.durationMinutes} Min`}</p>
            </div>);
                }
                else {
                    return (<div data-testid='absent-yes'>
              <p><strong>Type: </strong>{'Absent for Full Day'}</p>
              <p><strong>Start Time: </strong>{Utils.convert(timeOffValues.startTime)}</p>
              <p><strong>End Time: </strong>{Utils.convert(timeOffValues.endTime)}</p>
            </div>);
                }
            case ('LATE'): {
                return (<div data-testid='late'>
            <p><strong>Type: </strong>{'Arriving to Work Late'}</p>
            <p><strong>Normal Start Time: </strong>{Utils.convert(timeOffValues.normalStartTime)}</p>
            <p><strong>Estimated Arrival Time: </strong>{Utils.convert(timeOffValues.estimatedArrival)}</p>
          </div>);
            }
            case ('EARLYNORTN'): {
                return (<div data-testid='no-return'>
            <p><strong>Type: </strong>{'Leave w/ No Return'}</p>
            <p><strong>Departure Time: </strong>{Utils.convert(timeOffValues.departureTime)}</p>
            <p><strong>Regular End Time: </strong>{Utils.convert(timeOffValues.regularEndTime)}</p>
          </div>);
            }
            case ('EARLYRTN'): {
                return (<div data-testid='return'>
            <p><strong>Type: </strong>{'Leave w/ Return'}</p>
            <p><strong>Departure Time: </strong>{Utils.convert(timeOffValues.departureTime)}</p>
            <p><strong>Estimated Return: </strong>{Utils.convert(timeOffValues.estimatedReturn)}</p>
          </div>);
            }
        }
    };
    const renderReason = () => {
        return (<Heading elemStyle={'h4'} data-testid='reason'><strong>Reason:</strong> {returnLabelByValue(timeOffValues.reasonForTimeOff)}</Heading>);
    };
    return (<>
      {alert}
      <Box color={'grayLighter'}>
        <EmployeeNameLogout triggerFormReset={triggerFormReset} employeeName={employeeName} page='verifySubmission'/>
        <Collapse aria-label='Expand or Collapse Time Off and Reason' title={timeOffValues.date} isOpen={true}>
          {renderTypeAndTime()}
          {renderReason()}
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col>
              <Link data-testid='edit-link' onClick={edit}>
                <span>
                  <Icon name={'edit'}/>
                </span> Edit
              </Link>
            </Col>
            <Col>
              <Link data-testid='cancel-link' onClick={cancel}>
                <span>
                  <Icon name={'trash'}/>
                </span> Cancel Request
              </Link>
            </Col>
          </Row>
        </Collapse>
      </Box>
      <Button style={{ width: '100%' }} id='cancel' onClick={cancel}>Cancel Request
      </Button>
      <br />
      <br />
      <Button data-testid={'submitUsaa'} style={{ width: '100%' }} id='submit' onClick={submit} buttonType={'primary'} type={'submit'}>Submit
      </Button>
    </>);
};
export default VerifySubmission;
