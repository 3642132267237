import { Col, Heading, Row, themes } from '@digitools/honest-react';
import * as _ from 'lodash';
import React from 'react';
import EditButton from './EditButton';
import NoFieldSelected from './NoFieldSelected';
export const getCheckedProducts = (products) => {
    return products.filter((product) => product.checked && product.code !== 'All');
};
const ProductDetails = ({ reportFilterCategory, products, history }) => {
    if (reportFilterCategory !== 'Claim') {
        return null;
    }
    const selectedProducts = getCheckedProducts(products);
    const productsArray = _.chunk(selectedProducts, Math.ceil(selectedProducts ? selectedProducts.length / 3 : 2));
    const productTitle = reportFilterCategory + ' products';
    return (<div style={{ marginTop: '1.5rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {productTitle}
        </Heading>
        <Choose>
          <When condition={productsArray.length}>
            <Row>
              {productsArray.map((subProducts, indexRow) => (<Col lg={4} key={'product' + indexRow}>
                  {subProducts.map((product, index) => (<Col style={{ marginTop: '.3rem' }} key={'col' + index}>
                      <Choose>
                        <When condition={product.code === product.label}>
                          <span> {product.label}</span>
                        </When>
                        <Otherwise>
                          <Choose>
                            <When condition={product.label}>
                              <>
                                <b>({product.code})</b>
                                <span> {product.label}</span>
                              </>
                            </When>
                            <Otherwise>
                              {'(' + product.code + ')'}
                            </Otherwise>
                          </Choose>
                        </Otherwise>
                      </Choose>
                    </Col>))}
                </Col>
        // </div>
        ))}
            </Row>
          </When>
          <Otherwise>
            <NoFieldSelected />
          </Otherwise>
        </Choose>
        <EditButton history={history} path='/create-my-report'/>
      </div>
      <hr />
    </div>);
};
export default ProductDetails;
