import React, { useMemo } from 'react';
import { Checkbox, CheckboxGroup, Col, DateInput, Heading, Row, SelectAllCheckbox, SelectInput, themes, } from '@digitools/honest-react';
import { useFormApi, useFormState } from 'informed';
import { enterValidDateMessage, resetDateFields, resetThroughDateError, validateDatesSearchOn, validateStatusAndDatesGroup, } from './FilterMyReportValidation';
import * as _ from 'lodash';
import styled from 'styled-components';
import { format, startOfTomorrow } from 'date-fns';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
const StyledLabel = styled('label') `
  margin: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;

  & + div {
    margin-top: 10px;
  }
`;
const SelectStatusAndDates = ({ filterStatusCodes, reportFilterCategory }) => {
    const filterReportsByDate = dateSearchOnDropDownValue(reportFilterCategory);
    const statusArray = _.chunk(filterStatusCodes, 3);
    // ---------------------------------------------------- //
    // TODO: Fix this atrocity.
    const formState = useFormState();
    const formApi = useFormApi();
    const statusGroupValidator = useMemo(() => {
        return validateStatusAndDatesGroup(formApi);
    }, [formState.values['dateSearchBy'], formState.values['dateSearchBy']]);
    const dateSearchByValidator = useMemo(() => {
        return validateDatesSearchOn(formApi);
    }, [formState.touched['dateSearchBy']]);
    const shouldValidateDateSearchByOnChange = useMemo(() => {
        return formApi.getTouched('dateSearchBy');
    }, [formState.touched['dateSearchBy']]);
    const fromDateErrorMessage = useMemo(() => {
        return formApi.getValue('fromDate') === '' || !formApi.getValue('fromDate')
            ? 'Invalid Date'
            : 'Date cannot be in the future';
    }, [formState.values['fromDate']]);
    const toDateErrorMessage = useMemo(() => {
        return formApi.getValue('toDate') === '' || !formApi.getValue('toDate')
            ? 'Invalid Date'
            : 'Date cannot be less than from date';
    }, [formState.values['toDate']]);
    // ---------------------------------------------------- //
    const statusCheckboxRow = statusArray.map((subStatusCodes, indexRow) => (<Col lg={4} key={indexRow}>
      {subStatusCodes.map((status, index) => {
            if (reportFilterCategory === 'Accommodation Services' && status.code === 'DENIED') {
                return (<Col key={'col' + index} style={{ marginTop: '.5rem' }} hidden={true}>
              <Checkbox field={status.code} checked={false} label={status.label}/>
            </Col>);
            }
            else {
                return (<Col key={'col' + index} style={{ marginTop: '.5rem' }}>
              <Checkbox field={status.code} checked={status.checked} label={status.label}/>
            </Col>);
            }
        })}
    </Col>));
    return (<div style={{ marginTop: '2rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
        <Heading elemType={'h2'} elemStyle={'h3'} color={'grayDarker'}>
          {'Status and/or dates'}
        </Heading>
        <CheckboxGroup key={'statusgroup'} field={'statusgroup'} label='Status' theme={themes.lincoln} validate={statusGroupValidator} validateOnChange={true} notify={['dateSearchBy']}>
          <Row>
            <Col lg={4} style={{ paddingLeft: '14px' }}>
              <SelectAllCheckbox field={'ASC'}/>
            </Col>
          </Row>
          <Row style={{ marginTop: '-0.05rem' }}>{statusCheckboxRow}</Row>
        </CheckboxGroup>
        <hr />
        <div>
          <Row>
            <Col lg={4} md={4}>
              <Row>
                <Col>
                  <StyledLabel id='searchOnText'>Search on</StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectInput field='dateSearchBy' validate={dateSearchByValidator} validateOnChange={shouldValidateDateSearchByOnChange} notify={['statusgroup']} theme={themes.lincoln} options={filterReportsByDate} hidePlaceholder={true} errorMessage={'Please select Status and/or Date'} onChange={resetDateFields(formApi)}/>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Row>
                <Col>
                  <StyledLabel id='fromText'>From</StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DateInput label={'(mm/dd/yyyy)'} field={'fromDate'} disabled={formApi.getValue('dateSearchBy') ? false : true} before={format(startOfTomorrow(), 'MM/dd/yyyy')} notify={['toDate']} theme={themes.lincoln} onChange={resetThroughDateError(formApi)} beforeMessage={fromDateErrorMessage} onBlur={enterValidDateMessage(formApi, 'fromDate')} mask={dateMask}/>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Row>
                <Col>
                  <StyledLabel id='fromText'>Through</StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DateInput label={'(mm/dd/yyyy)'} field={'toDate'} theme={themes.lincoln} disabled={formApi.getValue('dateSearchBy') ? false : true} onBlur={enterValidDateMessage(formApi, 'toDate')} afterMessage={toDateErrorMessage} mask={dateMask}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <hr />
    </div>);
};
export default SelectStatusAndDates;
const dateSearchOnDropDownValue = (reportFilterCategory) => {
    const claimFilterReportsByDate = [
        { label: 'Choose an option', value: '' },
        { label: 'claim received date', value: 'Claim Received Date' },
        { label: 'closed date', value: 'Closed Date' },
        { label: 'disability date', value: 'Disability Date' },
        { label: 'determination date', value: 'Determination Date' },
    ];
    const leaveFilterReportsByDate = [
        { label: 'Choose an option', value: '' },
        { label: 'leave received date', value: 'Leave Received Date' },
        { label: 'closed date', value: 'Closed Date' },
        { label: 'determination date', value: 'Determination Date' },
        { label: 'leave begin date', value: 'Leave Begin Date' },
    ];
    const asFilterReportsByDate = [
        { label: 'Choose an option', value: '' },
        { label: 'accommodation received date', value: 'Accommodation Received Date' },
        { label: 'accommodation closed date', value: 'Accommodation Closed Date' },
        { label: 'provided start date', value: 'Provided Start Date' },
    ];
    switch (reportFilterCategory) {
        case 'Claim': {
            return claimFilterReportsByDate;
        }
        case 'Leave': {
            return leaveFilterReportsByDate;
        }
        case 'Accommodation Services': {
            return asFilterReportsByDate;
        }
        default:
            return [{ label: 'Choose an option', value: '' }];
    }
};
