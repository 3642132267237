import React from 'react';
import { RiaConfigProvider } from 'src/packages/ria/provider/RiaConfigProvider';
import { RiaDataProvider } from 'src/packages/ria/provider/RiaDataProvider';
import RiaRouter from 'src/packages/ria/routes/RiaRouter';
import GenericErrorBoundary from 'src/components/errorPages/GenericErrorBoundary';
/**
 * Container to setup Theme, Error & Providers for Ria app, pass route props down to RIARouter
 * @param props RouteComponentProps
 * @returns
 */
const RiaContainer = props => {
    //NOTE: All appwide providers specific to RIA should go here
    return (<GenericErrorBoundary>
      <RiaConfigProvider>
        <RiaDataProvider>
          <RiaRouter {...props}/>
        </RiaDataProvider>
      </RiaConfigProvider>
    </GenericErrorBoundary>);
};
export default RiaContainer;
