import React from 'react';
import { Box } from '@digitools/honest-react';
import dashedDateFormatToString from '../../../utilities/dashedDateFormatToString';
// @ts-ignore
import styles from './AccommodationDetails.scss';
import RequestExtendedDetailsRow from './RequestExtendedDetailsRow';
const RequestExtendedDetails = ({ requestDetails }) => {
    return (<div className={styles.tableRowContent}>
      <Box style={{ paddingRight: '2rem', paddingLeft: '2rem', marginTop: '2rem', marginBottom: '2rem', border: 'none' }}>
        <RequestExtendedDetailsRow header='Closed date' value={dashedDateFormatToString(requestDetails.accmCloseDte)} withBackground={true}/>
        <RequestExtendedDetailsRow header='Requested accommodation details' value={requestDetails.reqAccmDtlText} withBackground={false}/>
        <RequestExtendedDetailsRow header='Provided accommodation details' value={requestDetails.prvdDetailsText} withBackground={true}/>
        <RequestExtendedDetailsRow header='Requested period type' value={requestDetails.reqPrdTypeText} withBackground={false}/>
        <RequestExtendedDetailsRow header='Actual period type' value={requestDetails.prvdPrdTypeText} withBackground={true}/>
        <RequestExtendedDetailsRow header='Requested start date' value={dashedDateFormatToString(requestDetails.reqBegDte)} withBackground={false}/>
        <RequestExtendedDetailsRow header='Actual start date' value={dashedDateFormatToString(requestDetails.actualBegDte)} withBackground={true}/>
        <RequestExtendedDetailsRow header='Anticipated end date' value={dashedDateFormatToString(requestDetails.reqEndDte)} withBackground={false}/>
        <RequestExtendedDetailsRow header='Actual end date' value={dashedDateFormatToString(requestDetails.actualEndDte)} withBackground={true}/>
        <RequestExtendedDetailsRow header='Confirmed restrictions and limitations' value={requestDetails.confirmedRandL_EN} withBackground={false}/>
        <RequestExtendedDetailsRow header='Confirmed restrictions and limitations details' value={requestDetails.supp_r_and_l_text} withBackground={true}/>
        <RequestExtendedDetailsRow header='Assistance requested indicator' value={requestDetails.lmSrvcReqdInd_EN} withBackground={false}/>
        <RequestExtendedDetailsRow header='Interactive begin date' value={dashedDateFormatToString(requestDetails.interactBegDte)} withBackground={true}/>
        <RequestExtendedDetailsRow header='Initial meeting date' value={dashedDateFormatToString(requestDetails.initMtgDte)} withBackground={false}/>
        <RequestExtendedDetailsRow header='Completed information date' value={dashedDateFormatToString(requestDetails.complInfoDte)} withBackground={true}/>
        <RequestExtendedDetailsRow header='Employer decision date' value={dashedDateFormatToString(requestDetails.erDtrmDte)} withBackground={false}/>
        <RequestExtendedDetailsRow header='Existing accommodation indicator' value={requestDetails.existingAccommodation} withBackground={true}/>
        <RequestExtendedDetailsRow header='Return or stay at work indicator' value={requestDetails.rtwSawInd_EN} withBackground={false}/>
        <RequestExtendedDetailsRow header='Return to work without accommodation' value={dashedDateFormatToString(requestDetails.actualEndDte)} withBackground={true}/>
      </Box>
    </div>);
};
export default RequestExtendedDetails;
