import React from 'react';
const InfoText = {
    pageTitle: {
        english: 'Accommodation request',
        spanish: 'Solicitud de adaptación'
    },
    welcomeTxt: {
        english: 'Welcome,',
        spanish: 'Bienvenido,'
    },
    pageInfo: {
        english: 'Accommodation requests must be associated with your own medical condition. Other requests for accommodation, related to a family member or other non-medical reasons, should be coordinated with your leadership/Human Resources.',
        spanish: 'Las solicitudes de alojamiento deben estar asociadas con su propia condición médica. Otras solicitudes de adaptaciones relacionadas con un familiar u otras razones no médicas deben coordinarse con sus superiores/Recursos Humanos.',
    },
    header: {
        english: 'What do I need before submitting an accommodation?',
        spanish: '¿Qué necesito antes de enviar una solicitud de adaptación?',
    },
    identityVerificationTitle: {
        english: 'Identity verification info',
        spanish: 'Información de verificación de identidad',
    },
    identityVerificationTitleDetailsEE: {
        english: 'Information to identify yourself (may include Social Security number or other ID as required by your employer).',
        spanish: 'Información para identificarlo a usted (puede ser su número del Seguro Social u otro ID según lo que pida su empleador).',
    },
    identityVerificationTitleDetailsER: {
        english: 'Information to identify the individual (may include Social Security number or other ID as required by company).',
        spanish: 'Información para identificarlo a usted (puede ser su número del Seguro Social u otro ID según lo que pida su empleador).',
    },
    accomodationDetailsTitle: {
        english: 'Accommodation event details',
        spanish: 'Información del evento de adaptación',
    },
    accomodationDetails: {
        english: 'This includes the accommodation reason, type, and applicable dates.',
        spanish: 'Esto incluye el motivo de la adaptación, el tipo y las fechas aplicables.',
    },
    supportingDocumentationTitle: {
        english: 'Supporting documentation',
        spanish: 'Documentación de respaldo',
    },
    supportingDocumentationDetailsEE: {
        english: 'This includes documentation provided by your physician stating the specific restrictions and limitations you have requiring the need for your accommodation request and the period of time the accommodation is needed.',
        spanish: 'Esto incluye la documentación que da su médico en la que se indiquen las restricciones y limitaciones específicas que usted tiene y que exigen la solicitud de una adaptación, y el período de tiempo durante el cual se necesita la adaptación.',
    },
    supportingDocumentationDetailsER: {
        english: 'This includes documentation provided by a physician stating the specific restrictions and limitations your Employee has requiring the need for your accommodation request and the period of time the accommodation is needed.',
        spanish: 'Esto incluye la documentación que da su médico en la que se indiquen las restricciones y limitaciones específicas que usted tiene y que exigen la solicitud de una adaptación, y el período de tiempo durante el cual se necesita la adaptación.',
    },
    supportingDocumentationMoreDetailsEE: {
        english: 'Lincoln Financial Group will not be making a decision on your request. This interview provides information to assist your employer with the determination, tracking and documentation of your accommodation request. Any subsequent medical information or documentation provided by you/your doctor to support your accommodation request will be shared with your employer to facilitate the determination of your request.',
        spanish: 'Lincoln Financial Group no tomará una decisión sobre su solicitud. Esta entrevista da información para ayudar a su empleador con la determinación, el seguimiento y la documentación de su solicitud de adaptación. Cualquier información médica o documentación posterior que dé usted o su médico para respaldar su solicitud de adaptación se compartirá con su empleador para facilitar la determinación de su solicitud.',
    },
    supportingDocumentationMoreDetailsER: {
        english: `Lincoln Financial Group will not be making a decision on the Employee's request. Any subsequent medical information or documentation provided by the Employee/Employee’s doctor to support the accommodation request will be shared with you to facilitate the determination of the Employee’s request.`,
        spanish: 'Lincoln Financial Group no tomará una decisión sobre su solicitud. Esta entrevista da información para ayudar a su empleador con la determinación, el seguimiento y la documentación de su solicitud de adaptación. Cualquier información médica o documentación posterior que dé usted o su médico para respaldar su solicitud de adaptación se compartirá con su empleador para facilitar la determinación de su solicitud.',
    },
    noteTxt: {
        english: 'Please note: ',
        spanish: 'Nota: ',
    },
    noteDetails: {
        english: 'Any information you provide regarding your request for accommodation, will be shared with your employer.',
        spanish: 'Cualquier información que dé sobre su solicitud de adaptación se compartirá con su empleador.',
    },
    employerToolkit: {
        english: <>Have questions about accommodation options, various processes, or something else accommodation related? Our <a data-testid='employer-toolkit' href='https://marketingmedia.lfg.com/lfg/DOCS/lfd/emailMarketing/2020/GP/accommodation_toolkit_01/story_html5.html' target='_blank'>accommodations toolkit</a> will provide the necessary resources to answer your questions.</>,
        spanish: <>Have questions about accommodation options, various processes, or something else accommodation related? Our  <a data-testid='employer-toolkit' href='https://marketingmedia.lfg.com/lfg/DOCS/lfd/emailMarketing/2020/GP/accommodation_toolkit_01/story_html5.html' target='_blank'>accommodations toolkit</a> will provide the necessary resources to answer your questions.</>,
    },
};
export default InfoText;
