export const validationDisplayTextEs = {
    confirmEmailNotMatching: 'Las direcciones de correo electrónico no coiniciden. Por favor, inténtelo nuevamente.',
    duplicateEmail: 'La dirección de correo electrónico ya está en uso.',
    fieldRequired: 'Este campo es obligatorio.',
    invalidAddress: 'Por favor ingrese una dirección válida.',
    invalidAllocation: 'Ingrese un número entre 1-100.',
    invalidCharityContact: 'El contacto de la Caridad no es válido.',
    invalidCharityName: 'El nombre de la Caridad no es válido.',
    invalidCity: 'El nombre de la ciudad no puede exceder 30 caracteres.',
    invalidEmail: 'La dirección de correo electrónico no es válida.',
    invalidEmployeeID: 'La Identificación de Empleado no es válida.',
    invalidGuardianInfo: 'La información del Tutor no es válida.',
    invalidOtherAddress: 'La dirección no puede exceder 255 caracteres.',
    invalidPassword: 'La contraseña no es válida',
    invalidPasswordMatch: 'Las contraseñas deben coincidir.',
    passwordUsernameErrorMessage: 'El nombre de usuario y la contraseña no pueden ser iguales.',
    invalidPhone: 'Ingrese un número telefónico válido (por ej., 123-456-7890).',
    invalidSSN: 'El NSS no es válido',
    invalidTaxId: 'El NSS o Número de Identificaión Fiscal no es válido.',
    invalidTrustName: 'El nombre del Fideicomiso no es válido.',
    invalidFirstName: 'El primer nombre no es válido.',
    invalidLastName: 'El apellido no es válido.',
    invalidUsername: 'El nombre de usuario no es válido.',
    invalidZipCode: 'Por favor ingrese un código postal válido en el formato de "XXXXX".',
    invalidZipCodeCAN: 'Por favor ingrese un código postal válido en el formato de "A1A1A1".',
    invalidZipCodeUSA: 'Por favor ingrese un código postal válido en el formato de "XXXXX".',
    phoneErrorMessage: 'Este número de teléfono no es válido.',
    nonApprovedCountryError: 'Este país no está aprobado para la autenticación de dos factores. Por favor contacte a asistencia técnica.',
    zipCodeRequired: 'Código Postal es un campo obligatorio.',
    usernameInvalidErrormessage: 'Ingrese un nombre de usuario válido',
    invalidPostal: 'Por favor ingrese un código postal válido.',
};
