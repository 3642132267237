import { Alert, Box, Heading, Loader, Stepper, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import { AsText } from '../../constants/Text';
import useAsCustomerConfigContext from '../../hooks/useAsConfigContext';
import useAsDataContext from '../../hooks/useAsDataContext';
import AboutYou from '../aboutYou/AboutYou';
import Review from '../review/Review';
import React from 'react';
import useTealium from 'src/hooks/useTealium';
import PageLoader from 'src/components/PageLoader/PageLoader';
import { TaggedCustomerName } from 'src/packages/cli/components/UtilityComponents';
import AboutAccommodation from '../aboutAccommodation/AboutAccommodation';
import styled from 'styled-components';
import { defineConstant } from '../../constants/Constants';
import Notifications, { FunctionalArea } from 'src/components/Notifications/Notifications';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { AboutYouText } from '../../constants/AboutYouText';
import AboutAccommodationText from '../../constants/AboutAccommodationText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import GettingStarted from '../start/GettingStarted';
import InfoText from '../start/GettingStartedText';
export const ScalableBorderlessBox = styled(Box) `
  margin-bottom: 1rem;
  margin-left: 0rem;
  border: none;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
    border: none;
  }
`;
const ErrorAlert = styled(Alert) `
  margin-right: -35%;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    margin-right: 0%;
  }
`;
const Intake = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { asConfigLoading } = useAsCustomerConfigContext();
    const { isLoading, isPrePopLoading, submitErrors } = useAsDataContext();
    const { trackView } = useTealium();
    const stepTitles = [
        t(InfoText.pageTitle),
        CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutYouText.aboutClaimantEE) : t(AboutYouText.aboutClaimantER)),
        CustomEmployeeText(experience === Experience.EMPLOYEE
            ? t(AboutAccommodationText.aboutAccommodationEE)
            : t(AboutAccommodationText.aboutAccommodationER)),
        defineConstant.reviewPageHeading,
    ];
    const errorTextStyle = {
        color: '#333333',
        fontSize: '15px',
        lineHeight: 'normal',
    };
    return (<>
      <ScalableBorderlessBox>
        {asConfigLoading ? (<PageLoader />) : (<Loader viewportCentered={true} id='as-intake-spinner' isShown={isLoading} data-testid={'as-intake-spinner'}>
            <TaggedCustomerName />
            <div data-testid='as-intake-box' data-public={true} style={{ marginTop: '1.875rem' }}>
              <Notifications data-testid='intake-notification-banner' functionalArea={FunctionalArea.AS}/>
              <Heading data-testid='intake-title-heading' theme={themes.lincoln} style={{ marginBottom: '1.5rem' }}>
                {<span data-testid='intake-title-heading-accommodation'>
                    {t(AsText.intakeHeadingAccommodationCustomer)}
                  </span>}
              </Heading>
              {submitErrors && (<ErrorAlert type={'error'}>
                  <p style={errorTextStyle}>
                    {t(AsText.submitErrorMessage)}{' '}
                    <u>
                      <a href='/customer/home/contacts'>{t(AsText.submitErrorMessageContactusLinkLabel)}</a>
                    </u>
                  </p>
                </ErrorAlert>)}
              {/* @ts-ignore */}
              <Stepper stepTitles={stepTitles} theme={themes.secureSite} data-testid={'as-stepper'}>
                <GettingStarted />
                {!isPrePopLoading && <AboutYou />}
                {!isPrePopLoading && <AboutAccommodation />}
                <Review />
              </Stepper>
            </div>
          </Loader>)}
      </ScalableBorderlessBox>
    </>);
};
export default Intake;
