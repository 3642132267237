import { Col, Label, Row, SelectInput, TextInput } from '@digitools/honest-react';
import { useFormState } from 'informed';
import React from 'react';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import useTranslate from 'src/hooks/useTranslate';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import { Experience } from 'src/types/Experience';
//Relationship mappings - based on answer to absenceData.leaveReason
const fmcRelationshipMapping_BD = ['AC', 'BC', 'CD', 'FC', 'LW', 'SC', 'IP'];
const fmcRelationshipMapping_CH = ['CH', 'CP', 'CD', 'GC', 'NK', 'SC'];
const fmcRelationshipMapping_DV = [
    'BP',
    'CH',
    'CP',
    'CD',
    'DP',
    'FP',
    'GC',
    'GP',
    'IP',
    'LG',
    'NK',
    'OT',
    'PL',
    'SI',
    'SP',
    'SC',
    'ST',
];
const fmcRelationshipMapping_OT = ['NK', 'OT', 'SI'];
const fmcRelationshipMapping_PA = ['BP', 'FP', 'GP', 'IP', 'LG', 'NK', 'PL', 'ST'];
const fmcRelationshipMapping_SG = ['SP'];
const fmcRelationshipMapping_SK = ['CH', 'CP', 'CD', 'GC', 'NK', 'SC'];
const fmcRelationshipMapping_SP = ['DP', 'NK', 'SP'];
const fmcRelationshipMapping_CS = ['CH', 'SC', 'FC', 'IP'];
// Parental mappings
const bndRelationshipMapping_AD = ['AC'];
const bndRelationshipMapping_FC = ['FC'];
const bndRelationshipMapping_NM = ['BC', 'CD', 'LW', 'NK', 'SC'];
const bndRelationshipMapping_NP = ['BC', 'CD', 'LW', 'NK', 'SC'];
const bndRelationshipMapping_OT = ['NK', 'OT'];
const bndRelationshipMapping_SS = ['AC', 'BC', 'CD', 'FC', 'LW', 'NK', 'SC'];
// Military mappings
const xmlRelationshipMapping_ML = [
    'BP',
    'CH',
    'CP',
    'CD',
    'DP',
    'FP',
    'GC',
    'GP',
    'IP',
    'LG',
    'NK',
    'OT',
    'PL',
    'SI',
    'SP',
    'SC',
    'ST',
];
// own mappings
const ownRelationshipMapping = ['SE']; //set in AboutYourAbsence.tsx
// other mappings
const othRelationshipMapping = ['SE']; //set in AboutYourAbsence.tsx
const othRelationshipMapping_SC = ['CH', 'CP', 'CD', 'FC', 'GC', 'LW', 'SC'];
const othRelationshipMapping_WC = [
    'BP',
    'CH',
    'CP',
    'CD',
    'FC',
    'FP',
    'GC',
    'GP',
    'IP',
    'LG',
    'LW',
    'OT',
    'PL',
    'SP',
    'SC',
    'ST',
];
const getRelationshipDisplayOptions = (t, relationships, leaveType, leaveReason) => {
    let potentialList = [];
    switch (leaveType) {
        case LeaveType.FAMILY_CARE:
            switch (leaveReason) {
                case 'BD':
                    potentialList = fmcRelationshipMapping_BD;
                    break;
                case 'CH':
                    potentialList = fmcRelationshipMapping_CH;
                    break;
                case 'DV':
                    potentialList = fmcRelationshipMapping_DV;
                    break;
                case 'OT':
                    potentialList = fmcRelationshipMapping_OT;
                    break;
                case 'PA':
                    potentialList = fmcRelationshipMapping_PA;
                    break;
                case 'SG':
                    potentialList = fmcRelationshipMapping_SG;
                    break;
                case 'SK':
                    potentialList = fmcRelationshipMapping_SK;
                    break;
                case 'SP':
                    potentialList = fmcRelationshipMapping_SP;
                    break;
                case 'CS':
                    potentialList = fmcRelationshipMapping_CS;
                    break;
                default:
                    break;
            }
            break;
        case LeaveType.PARENTAL:
            switch (leaveReason) {
                case 'NM':
                    potentialList = bndRelationshipMapping_NM;
                    break;
                case 'NP':
                    potentialList = bndRelationshipMapping_NP;
                    break;
                case 'OT':
                    potentialList = bndRelationshipMapping_OT;
                    break;
                case 'SS':
                    potentialList = bndRelationshipMapping_SS;
                    break;
                default:
                    break;
            }
            break;
        case LeaveType.MILITARY:
            switch (leaveReason) {
                case 'ML':
                    potentialList = xmlRelationshipMapping_ML;
                    break;
                default:
                    break;
            }
            break;
        case LeaveType.OTHER:
            switch (leaveReason) {
                case 'SC':
                    potentialList = othRelationshipMapping_SC;
                    break;
                case 'WC':
                    potentialList = othRelationshipMapping_WC;
                    break;
                default:
                    break;
            }
            break;
        // case LeaveType.OWN_ILLNESS:
        default:
            break;
    }
    // Filter out only codes found in the potention list
    const leaveReasonOptions = relationships.filter(relationship => potentialList.includes(relationship.code));
    // Convert to display options for input
    const leaveReasonDisplayOptions = leaveReasonOptions.map(leaveReasonOption => {
        return {
            label: t(leaveReasonOption),
            value: leaveReasonOption.code,
        };
    });
    return leaveReasonDisplayOptions;
};
const Relationship = () => {
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { relationships } = useCliCustomerConfigContext();
    const { values } = useFormState();
    const { experience } = useExperience();
    //@ts-ignore
    const leaveType = values?.absenceData?.leaveType;
    //@ts-ignore
    const leaveReason = values?.absenceData?.leaveReason;
    //@ts-ignore
    const leaveCategory = values?.absenceData?.leaveCategory;
    const options = getRelationshipDisplayOptions(t, relationships, leaveType, leaveReason);
    return (<>
      {/* default hidden value of 'AC' for leaveType of Parental with a leaveReason of AD */}
      {leaveReason === 'AD' && leaveType === LeaveType.PARENTAL && (leaveCategory === 'C' || leaveCategory === 'I') && (<TextInput hidden={true} skinny={true} hideLabel={true} label={''} field='relationship' initialValue={'AC'}/>)}
      {/* default hidden value of 'FC' for leaveType of Parental with a leaveReason of FC */}
      {leaveReason === 'FC' && leaveType === LeaveType.PARENTAL && (leaveCategory === 'C' || leaveCategory === 'I') && (<TextInput hidden={true} skinny={true} hideLabel={true} label={''} field='relationship' initialValue={'FC'}/>)}
      <Row className='mb-3'>
        <Col>
          {!(leaveReason === 'AD' || leaveReason === 'FC') && (<>
              <Label className='mb-2' htmlFor='relationship'>
                {CustomEmployeeText(t(experience === Experience.EMPLOYEE
                ? AboutYourAbsenceText.formLabels.relationshipEE
                : AboutYourAbsenceText.formLabels.relationshipER))}
              </Label>
              <SelectInput field={'relationship'} label={t({ english: 'Relationship', spanish: 'relación' })} options={options} data-testid={'relationship'} validateOnBlur={true} validate={fieldRequired} initialValue={absenceData?.relationship}/>
            </>)}
        </Col>
      </Row>
    </>);
};
export default Relationship;
