import React, { useState } from 'react';
import { Icon, Row, Container, Link, Label, Col, Alert, Loader } from '@digitools/honest-react';
import { Language } from '../../../types/Language';
import { DocumentUploadDisplayTextEs } from '../../../components/documentUpload/language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from '../../../components/documentUpload/language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import styled from 'styled-components';
import { getTraceId } from '../../../utils/axiosUtils';
import useAuthentication from '../../../hooks/useAuthentication';
import useExperience from '../../../hooks/useExperience';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import { supportedFiles, defineConstant, serviceUrl } from '../constants/Constants';
import useAsDataContext from '../hooks/useAsDataContext';
import useTranslate from 'src/hooks/useTranslate';
import { ConditionCircumstanceText } from '../constants/ConditionCircumstanceText';
const StyledCol = styled(Col) `
    padding: 12px;
`;
const StyledLink = styled(Link) `
    font-weight: bold;
`;
const StyledFileInput = styled('input') `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;
const DocumentUpload = (props) => {
    const { authHeader } = useAuthentication();
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const { documents, setDocuments } = useAsDataContext();
    const { t } = useTranslate();
    const onUpload = (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6291456 && selectedFile.size !== 0) {
                        uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                        trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                            event_name: languageFile.uploadSizeExceeded,
                            event_version: defineConstant.uploadDocument,
                        });
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                        event_name: languageFile.uploadFileTypeInvalid,
                        event_version: defineConstant.uploadDocument,
                    });
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                    event_name: languageFile.uploadFilenameInvalid,
                    event_version: defineConstant.uploadDocument,
                });
            }
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(serviceUrl.documentUploadSevice, getFormData(file, props.productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            setDocuments([...documents, { name: file.name, uuid: response.data }]);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                event_name: defineConstant.open,
                event_version: 'Your accommodation details',
                event_results: defineConstant.eventResult, //This will always be 1, you only can 'upload' one at a time, even if submitting ends up attaching all docs to one claim
            });
            setErrorMessage('');
            setUploading(false);
        }
        catch (error) {
            setUploading(false);
            setErrorMessage(`${languageFile.failureMessage}${getTraceId(error)}`);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                event_name: languageFile.failureMessage,
                event_version: defineConstant.uploadDocument,
            });
        }
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append(defineConstant.file, file, file.name);
        formData.append(defineConstant.interviewType, interviewType);
        formData.append(defineConstant.experience, experience);
        return formData;
    };
    const renderColumns = () => {
        return (<>
                <Col lg={6} md={6} sm={6} xs={6}>
                    {' '}
                    {documents.map((document, index) => {
                if (index <= 4)
                    return getSelectedDocuments(document);
            })}{' '}
                </Col>

                <Col lg={6} md={6} sm={6} xs={6}>
                    {' '}
                    {documents.map((document, index) => {
                if (index > 4)
                    return getSelectedDocuments(document);
            })}{' '}
                </Col>
            </>);
    };
    const getSelectedDocuments = (document) => {
        const handleDelete = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.DOCUMENT_UPLOAD,
                event_name: defineConstant.deleteUploadedDocument,
            });
            setDocuments(documents.filter(file => file.uuid !== document.uuid));
        };
        return (<>
                {' '}
                <Row key={document.uuid}>
                    <StyledCol xs={12} md={6} lg={6}>
                        <Row data-testid={'submitted-doc'} aria-labelledby={document.name}>
                            <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {document.name}
                        </Row>
                    </StyledCol>
                    <StyledCol md={3}>
                        <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
                            <Icon color='secondary' name='trash'/> {languageFile.delete}
                        </StyledLink>
                    </StyledCol>
                </Row>
            </>);
    };
    const documentUploadLabel = t(ConditionCircumstanceText.documentUploadExample);
    return (<div data-testid='as-document-upload'>
            <Loader id='document-upload-spinner' isShown={uploading}>
                <Container>
                    {errorMessage !== '' && (<Alert data-testid={'error-alert'} type={'error'} aria-labelledby='error-alert'>
                            {errorMessage}
                        </Alert>)}
                    <Row data-testid={'informationText'} style={{ marginTop: '1rem' }}>
                        {documentUploadLabel}
                    </Row>
                    <Row data-testid={'acceptableDocumentsText'}>
                        {t(ConditionCircumstanceText.documentUploadOverview)}
                    </Row>
                    {documents.length < 10 && (<Row style={{ marginTop: '2rem' }}>
                            <Col md={4}>
                                <Label htmlFor={'file-upload'}>
                                    <Icon color={'secondary'} name={'upload'} size={'medium'}/>
                                    <Link style={{ marginLeft: '.5rem', fontSize: '16px', fontWeight: 'bold' }} data-testid={'uploadButton'}>
                                        {languageFile.uploadButtonText}
                                    </Link>
                                </Label>
                                <StyledFileInput data-testid={'fileUpload'} type={'file'} id={'file-upload'} onChange={onUpload} accept={'.gif, .jpg, .pdf, .pptx, .tif, .tiff, .xls, .xlsx, .doc, .docx'} 
        // accept={supportedFiles}
        aria-label='Upload Document'/>
                            </Col>
                        </Row>)}
                    <Row style={{ marginBottom: '0.5rem' }}>{renderColumns()}</Row>
                </Container>
            </Loader>
        </div>);
};
export default DocumentUpload;
