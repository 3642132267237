const IMfaAuthyInstructionsDisplayTextEs = {
    link: '¿Qué es Authy?',
    heading: '¿Qué es Authy?',
    body: 'La aplicación de Authy le permite generar tokens de autenticación de dos factores usando dispositivos iPhone, Android y de escritorio, sin tener que esperar un SMS o una llamada.',
    step1Title: 'Instale la aplicación. ',
    step1TextA: 'Ingrese a ',
    step1TextB: ' para instalar la aplicación, o búsquela en App Store o Google Play.',
    step2Title: 'Configure.',
    step2Text: 'Authy le solicitará que ingrese su número de teléfono. Para vincular su cuenta de My Lincoln Portal, deberá ingresar el mismo número de teléfono que usa para My Lincoln Portal. Llámenos si necesita actualizar el número asociado a su cuenta.',
    step3Title: 'Inicie sesión.',
    step3Text: 'Vaya a My Lincoln Portal e ingrese sus credenciales para iniciar sesión. Cuando se le pregunte por sus preferencias de autenticación de dos factores, seleccione la aplicación de Authy.',
    step3Italic: 'Nota: Aunque elija otro método, el código de la aplicación de Authy será válido y le permitirá acceder a su cuenta.',
    step4Title: 'Obtenga un código.',
    step4Text: 'Simplemente abra la aplicación y presione My Lincoln Portal en la lista de cuentas en la parte inferior de la pantalla. Verá que aparece un código en su pantalla. Este código será válido por 20 segundos desde que aparece en su pantalla. Ingrese el código en My Lincoln Portal.',
    step4Italic: 'Nota: Si elige otras preferencias y recibe otro código, su código de Authy será válido y le permitirá acceder a su cuenta.',
};
export default IMfaAuthyInstructionsDisplayTextEs;
