import React, { useReducer } from 'react';
import { AdminPermissionContext } from './AdminPermissionContext';
import { adminPermissionReducer, SAVE_USER_PERMISSION } from './AdminPermissionReducer';
const AdminPermissionState = (props) => {
    const [dispatch] = useReducer(adminPermissionReducer, { cart: [] });
    const saveUserPermissions = (user, clearAllSelection) => {
        dispatch({
            type: SAVE_USER_PERMISSION,
            user: user,
            clearAll: clearAllSelection,
        });
    };
    return (<AdminPermissionContext.Provider value={{
            saveUserPermissions: saveUserPermissions,
        }}>
      {props.children}
    </AdminPermissionContext.Provider>);
};
export default AdminPermissionState;
