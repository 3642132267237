import React, { useContext } from 'react';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import { StepperContext, Icon } from '@digitools/honest-react';
import styled from 'styled-components';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../hooks/useTranslate';
import { AsText } from '../constants/Text';
import useAsDataContext from '../hooks/useAsDataContext';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
const StyledDiv = styled('div') `
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 765px) {
    flex-direction: column-reverse;
  }
`;
const StyledContinueButton = styled(Button) `
  @media (max-width: 765px) {
    margin-bottom: 0.5rem;
  }
`;
const StyledBackButton = styled(Button) `
  @media (max-width: 765px) {
    margin-top: 0.5rem;
  }
`;
/* Reusable container for stepper button formatting at the bottom of each interview page */
const StepperButtons = ({ type = 'continue', firstStep, last, lastButton, suppressNext, onClickBack, onClickNext, analyticsPageName, }) => {
    const { t } = useTranslate();
    const { setSubmitErrors, submitErrors } = useAsDataContext();
    const stepper = useContext(StepperContext);
    const { experience } = useExperience();
    const { trackEvent } = useTealium();
    const onNext = () => {
        if (!suppressNext) {
            stepper.next();
        }
    };
    const handleBackButtonClick = () => {
        if (experience === Experience.EMPLOYER) {
            if (analyticsPageName == 'about you') {
                analyticsPageName = "about your employee";
            }
            if (analyticsPageName == 'about accommodation') {
                analyticsPageName = "about your employee's accommodation";
            }
        }
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'back',
            event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            event_version: analyticsPageName == 'review' ? 'review your request and submit' : analyticsPageName,
        });
        if (submitErrors) {
            setSubmitErrors(false);
        }
        if (onClickBack) {
            onClickBack();
        }
        stepper.back();
        window.scrollTo(0, 0);
    };
    const handleContinueButtonClick = () => {
        if (submitErrors) {
            setSubmitErrors(false);
        }
        if (onClickNext) {
            onClickNext();
        }
        onNext();
        window.scrollTo(0, 0);
    };
    const label = type === 'continue' ? t(AsText.continue) : t(AsText.submit);
    return (<StyledDiv data-testid='stepper-buttons'>
      {!firstStep && (<StyledBackButton data-testid='stepper-back' type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={() => handleBackButtonClick()}>
          <Icon className={'mr-1'} name={'arrow-circle-left'} size={'small'} color={'secondary'}/>
          {t(AsText.backButton)}
        </StyledBackButton>)}

      {!last && (<StyledContinueButton style={{ marginTop: !firstStep ? '0' : '-0.5rem', marginLeft: '0' }} type={'submit'} buttonType={'primary'} theme={themes.lincoln} data-testid='stepper-next' onClick={() => handleContinueButtonClick()}>
          {label}
        </StyledContinueButton>)}

      {lastButton}
    </StyledDiv>);
};
export default StepperButtons;
