import { Box, Button, Heading, Link, Icon } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import { MigrateDisplayText } from 'src/packages/userMigration/i18n/MigrateDisplayText';
import useTranslate from 'src/hooks/useTranslate';
import { getEnvVariable, windowReplace } from 'src/utils/envUtils';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import styled from 'styled-components';
import { StyledLCNText } from 'src/components/styledComponents';
const StyledBox = styled(Box) `
   {
    padding: 3rem;
  }
`;
const MigrationEmailClashComponent = () => {
    const { t } = useTranslate();
    const { trackEvent, trackView } = useTealium();
    const trackLearnMoreEvent = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: TEALIUM_EVENT.EVENT_NAME.LEARN_MORE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMAIL_CONFLICT,
        });
    };
    const trackLogin = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: TEALIUM_EVENT.EVENT_NAME.LOGIN,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION,
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMAIL_CONFLICT,
        });
        windowReplace(`${getEnvVariable('lfgLoginUrl')}`);
    };
    useEffect(() => {
        //not sure about source system
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.MIGRATION,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE,
            page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.EMAIL_CONFLICT,
            user_audience: TEALIUM_PAGE_VIEW.USER_AUDIENCE.EMPLOYEE,
        });
    }, []);
    return (<>
      <Row className={'mt-3'}>
        <Col xl={6} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledBox>
            <Heading elemType={'h1'} elemStyle={'h2'} color={'primary'} className={'mb-2'} data-testid={'email-clash-heading'}>
              {t(MigrateDisplayText.emailClash.heading)}
            </Heading>
            <p>{t(MigrateDisplayText.emailClash.infoParagraph1)}</p>
            <p>{t(MigrateDisplayText.emailClash.infoParagraph2)}</p>

            <Button data-public={true} buttonType={'primary'} className={'mt-3 d-block w-100'} onClick={trackLogin} data-testid={'migrate-success-login-to-lfg-button'}>
              {t(MigrateDisplayText.success.loginButton)}
            </Button>
            <p className='text-center'>
              {t(MigrateDisplayText.emailClash.footerText)}
              <Link href='public/learn-more' target='_blank' onClick={() => trackLearnMoreEvent()}>
                {t(MigrateDisplayText.emailClash.learnMore)}
                <Icon size='xs' style={{ marginLeft: '.5rem' }} color='secondary' name='chevron-right-solid'/>
              </Link>{' '}
            </p>
          </StyledBox>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col xl={6} lg={7} md={9} sm={12} className={'position-relative ml-auto mr-auto'}>
          <StyledLCNText>{t(MigrateDisplayText.LCN)}</StyledLCNText>
        </Col>
      </Row>
    </>);
};
export default MigrationEmailClashComponent;
