const PaymentMethodText = {
    pageTitle: {
        english: 'Payment Method',
        spanish: 'Método de pago'
    },
    pageInfo: {
        english: 'Please choose how you would like to receive your payments. If your claim is approved and payment is issued by LFG, this is how you will receive your benefit.',
        spanish: 'Elija cómo le gustaría recibir sus pagos. Si se aprueba su reclamo y LFG emite el pago, así es como recibirá su beneficio.'
    },
    subHeading: {
        english: 'Preferred payment method',
        spanish: 'Método de pago preferido'
    },
    directDeposit: {
        english: 'Direct Deposit',
        spanish: 'Deposito Directo'
    },
    bankCheck: {
        english: 'Bank Check',
        spanish: 'Cheque Bancario'
    },
    successMessage: {
        english: 'Success! Your payment method has been verified and you may continue.',
        spanish: '¡Éxito! Su método de pago ha sido verificado y puede continuar.'
    },
    errorMessage: {
        english: "We're sorry, we were unable to submit your request at this time.  Please wait a moment and try again.  If the issue continues, please",
        spanish: "Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si el problema continúa, por favor"
    },
    warningMessage: {
        english: 'Your banking validation was unsuccessful.  Please enter a valid routing and account number and try again.',
        spanish: 'Su validación bancaria no tuvo éxito. Ingrese un número de ruta y de cuenta válido e intente nuevamente.'
    },
    contactUs: {
        english: 'contact us',
        spanish: 'contacta con nosotras'
    }
};
export default PaymentMethodText;
