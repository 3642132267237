import { themes } from "@digitools/honest-react";
import Icon from "@digitools/honest-react/dist/lib/components/Icon";
import React from "react";
export const CliConfirmationTextClaim = {
    pageHeading: {
        english: <><strong>Thank you!</strong> You have successfully submitted a new claim.</>,
        spanish: <><strong>Gracias!</strong> Ha enviado con éxito un nuevo.</>, // <- for claim only, 'una nueva ausencia' - this should conjugate different because of masculine/feminine nouns
    },
    subHeading: {
        english: 'Please keep your claim number handy for future reference.',
        spanish: 'Mantenga su número de reclamo a mano para futuras referencias.'
    },
    videoMessage: {
        english: <>For more information on next steps,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
            watch our step by step video of the claim process.
            </a>
          </strong></>,
        spanish: <>Para obtener más información sobre los próximos pasos,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
            vea nuestro video paso a paso del proceso de reclamación.
            </a>
        </strong></>
    },
    eventNumber: {
        english: 'CLAIM NUMBER: ',
        spanish: 'NUMERO DE RECLAMO: ' //TODO: get translation
    },
    ourNextStepsHeading: {
        english: 'Our Next Steps',
        spanish: 'Nuestros Próximos Pasos',
    },
    ourNextStepsBody: {
        english: 'Your Claim Specialist will contact you within 3 business days to gather additional details and answer any questions you may have regarding your claim. This will give them the opportunity to review the information you’ve provided today.',
        spanish: 'Su especialista en reclamos se comunicará con usted dentro de los 3 días hábiles para recopilar detalles adicionales y responder cualquier pregunta que pueda tener sobre su reclamo. Esto les dará la oportunidad de revisar la información que proporcionó hoy.',
    },
    nextStepsHeading: {
        english: 'Your Next Steps',
        spanish: 'Tus Siguientes Pasos',
    },
    nextSteps1Heading: {
        english: 'Contact your manager',
        spanish: 'Comuníquese con su gerente',
    },
    nextSteps1Body: {
        english: 'If you haven’t already done so, please contact your manager to inform them you’ll be out of work.',
        spanish: 'Si aún no lo ha hecho, comuníquese con su gerente para informarle que no tendrá trabajo.'
    },
    nextSteps2Heading: {
        english: 'Sign the medical authorization form and provide a copy to your doctor(s)',
        spanish: 'Firme el formulario de autorización médica y proporcione una copia a su(s) médico(s)',
    },
    nextSteps2Body: {
        english: 'Download the medical authorization form, sign it, and give a copy to each of your healthcare providers. Your doctor(s) will need this authorization in order to provide us with the medical information necessary for your claim.',
        spanish: 'Descargue el formulario de autorización médica, fírmelo y entregue una copia a cada uno de sus proveedores de atención médica. Su (s) médico (s) necesitarán esta autorización para proporcionarnos la información médica necesaria para su reclamo.'
    },
    nextSteps2Link: {
        english: <a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
            <Icon name='forward' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>View and download medical authorization form </strong>
            </a>,
        spanish: <a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
        <Icon name='forward' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>Ver y descargar el formulario de autorización médica</strong>
      </a>
    },
    nextSteps3Heading: {
        english: 'Upload the signed authorization, and any additional info requested, to My Lincoln Portal®',
        spanish: 'Cargue la autorización firmada y cualquier información adicional solicitada a My Lincoln Portal®'
    },
    nextSteps3BodyEE: {
        english: <>
          You can upload the signed medical authorization, claim related documents, and medical records via our secure portal, by selecting the link below. You can also upload at any time in the future by visiting Requests & Status {">"} View request status {">"} <a data-testid='view-status-link-3' href='/customer/status/employee' target='_blank'><strong>Claim & leave</strong></a> from the home page. These documents will be viewable by your Claim Specialist and may expedite the processing of your claim. If you prefer, you can also email documents and medical information to <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'><strong>disabilitydocuments@lfg.com</strong></a>
        </>,
        spanish: <>
          Puede cargar la autorización médica firmada, los documentos relacionados con la reclamación y los registros médicos a través de nuestro portal seguro, seleccionando el enlace a continuación. También puede cargar en cualquier momento en el futuro visitando Solicitudes y estado {">"} Ver el estado de la solicitud {">"} <a data-testid='view-status-link-3' href='/customer/status/employee' target='_blank'><strong>Reclamo y licencia</strong></a> desde la página de inicio. Estos documentos serán visibles por su especialista en reclamos y pueden acelerar el procesamiento de su reclamo. Si lo prefiere, también puede enviar documentos e información médica por correo electrónico a <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'><strong>disabilitydocuments@lfg.com</strong></a>
        </>
    },
    nextSteps3BodyER: {
        english: <>
          You can upload the signed medical authorization, claim related documents, and medical records via our secure portal, by selecting the link below. You can also upload at any time in the future by visiting Requests & Status {">"} View request status {">"}<a data-testid='view-status-link-3' href='/customer/status' target='_blank'><strong> Claim & leave</strong></a> from the home page. These documents will be viewable by your Claim Specialist and may expedite the processing of your claim. If you prefer, you can also email documents and medical information to <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'><strong>disabilitydocuments@lfg.com</strong></a>
        </>,
        spanish: <>
          Puede cargar la autorización médica firmada, los documentos relacionados con la reclamación y los registros médicos a través de nuestro portal seguro, seleccionando el enlace a continuación. También puede cargar en cualquier momento en el futuro visitando Solicitudes y estado {">"} Ver el estado de la solicitud {">"} <a data-testid='view-status-link-3' href='/customer/status/employee' target='_blank'><strong>Reclamo y licencia</strong></a>  desde la página de inicio. Estos documentos serán visibles por su especialista en reclamos y pueden acelerar el procesamiento de su reclamo. Si lo prefiere, también puede enviar documentos e información médica por correo electrónico a <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'><strong>disabilitydocuments@lfg.com</strong></a>
        </>
    },
    nextSteps4Heading: {
        english: 'Stay updated about your claim',
        spanish: 'Manténgase actualizado sobre su reclamo'
    },
    nextSteps4BodyEE: {
        english: <>You'll be able to view your claim online within 48 hours, simply select the <a data-testid='view-status-link-4' href='/customer/status/employee' target='_blank'><strong>View Status</strong></a> button from the home page. Here you’ll also find your Claim Specialist contact information should you have any questions.</>,
        spanish: <>Podrá ver su reclamo en línea dentro de las 48 horas, simplemente seleccione el botón <a data-testid='view-status-link-4' href='/customer/status/employee' target='_blank'><strong>Ver el Estado de</strong></a> en la página de inicio. Aquí también encontrará la información de contacto de su especialista en reclamos si tiene alguna pregunta.</>
    },
    nextSteps4BodyER: {
        english: <>You'll be able to view your claim online within 48 hours, simply select the <a data-testid='view-status-link-4' href='/customer/status' target='_blank'><strong>View Status</strong></a> button from the home page. Here you’ll also find your Claim Specialist contact information should you have any questions.</>,
        spanish: <>Podrá ver su reclamo en línea dentro de las 48 horas, simplemente seleccione el botón <a data-testid='view-status-link-4' href='/customer/status' target='_blank'><strong>Ver el Estado de</strong></a> en la página de inicio. Aquí también encontrará la información de contacto de su especialista en reclamos si tiene alguna pregunta.</>
    },
    nextSteps4Link: {
        english: <a href='/customer/profile/alertsNotifications' data-testid='customer-preferences-link' target='_blank'>
                  <Icon name="mobile-alt"/><strong>  Sign up for text notifications</strong>
                  </a>,
        spanish: <a href='/customer/profile/alertsNotifications' data-testid='customer-preferences-link' target='_blank'>
                  <Icon name="mobile-alt"/><strong>  Regístrese para recibir notificaciones de texto</strong>
                  </a>
    },
    automatedMessageText: {
        english: <>
          <br /><strong>Interested in getting automated claim status updates by text?</strong><br />
            Click the link below to update your communicated preferences in your portal profile. All communication will be sent in English.
          </>,
        spanish: <>
          <br /><strong>¿Está interesado en recibir actualizaciones automáticas del estado de las reclamaciones por mensaje de texto?</strong><br />
            Haga clic en el enlace a continuación para actualizar sus preferencias comunicadas en su perfil de portal. Toda la comunicación se enviará en inglés.
          </>
    },
    questions: {
        english: 'My Lincoln Portal® is your one-stop shop for everything you need to manage your claim. You can access the portal 24 hours a day, 7 days a week, from your desktop, tablet, or mobile device.',
        spanish: 'My Lincoln Portal® es su ventanilla única para todo lo que necesita para administrar su reclamo. Puede acceder al portal las 24 horas del día, los 7 días de la semana, desde su computadora de escritorio, tableta o dispositivo móvil.'
    },
    videoUrl: 'https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001',
    videoId: '5811829006001',
    videoHeading: {
        english: 'Have questions about your next steps in the claim process?',
        spanish: '¿Tiene preguntas sobre los pasos de su nido en el proceso de reclamo?'
    },
    videoSubheading: {
        english: 'Watch this video',
        spanish: 'Mira este video'
    }
};
