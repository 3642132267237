import React from 'react';
import { Container } from '@digitools/honest-react';
const Footer = () => {
    return (<Container>
      <p>
        <strong>NOTICE: </strong>
        Your leave and disability benefits are administered by The Lincoln National Life Insurance Company. 
      </p>
      {/* <p>
          <strong>Effective May 1, 2018, </strong>
          Liberty Life Assurance Company of Boston was acquired by The Lincoln National Life Insurance Company. For a period of time you may see references to both Liberty Mutual
          and Lincoln Financial Group on correspondence and other materials. Please open and read all communications
          you receive about your benefits from both companies. There will be no interruption to benefits or claims
          processing as a result of this transition.
        </p> */}
    </Container>);
};
export default Footer;
