export const RIA_VALIDATION_CONSTANTS = {
    validateDateRangeMessage: {
        english: 'Date is not within range.',
        spanish: 'La fecha no está dentro del rango.',
    },
    validateDurationOverMessage: {
        english: 'Duration cannot be more than 24 hours.',
        spanish: 'La duración no puede ser superior a 24 horas.',
    },
    validateDurationUnderMessage: {
        english: 'Duration cannot be less than 24 hours.',
        spanish: 'La duración no puede ser inferior a 24 horas.',
    },
    durationMessage: {
        english: (durationInterval) => `Duration must be in ${durationInterval} minute increments.`,
        spanish: (durationInterval) => `La duración debe estar en incrementos de ${durationInterval} minutos.`,
    },
    duplicateDateError: {
        english: 'You cannot submit multiple absences for the same date.',
        spanish: 'No puede enviar múltiples ausencias para la misma fecha.'
    },
    hourDurationMessage: {
        english: 'Duration must be entered in increments of 1 hour.',
        spanish: 'La duración debe ingresarse en incrementos de 1 hora.'
    }
};
