const StatusText = {
    FILTER: {
        sortAndFilter: {
            english: 'Sort & filter',
            spanish: 'Clasificar y filtrar',
        },
        filter: {
            english: 'Filter:',
            spanish: 'Filtrar',
        },
        all: {
            english: 'All',
            spanish: 'Todo',
        },
        claims: {
            english: 'Claims',
            spanish: 'Reclamos',
        },
        leaves: {
            english: 'Leaves',
            spanish: 'Licencias',
        },
        sort: {
            english: 'Sort:',
            spanish: 'Clasificar:',
        },
        eventNumber: {
            english: 'Event #',
            spanish: 'Evento #',
        },
        product: {
            english: 'Product',
            spanish: 'Producto',
        },
        recDate: {
            english: 'Rec. date',
            spanish: 'Fecha de rec.',
        },
        detDate: {
            english: 'Det. date',
            spanish: 'Fecha de det.',
        },
        status: {
            english: 'Status',
            spanish: 'Estado',
        },
    },
    LINKS: {
        returnToWork: {
            english: 'Return to work',
            spanish: 'Volver al trabajo',
        },
        viewDetails: {
            english: 'View details',
            spanish: 'Ver detalles',
        },
        uploadDocuments: {
            english: 'Upload documents',
            spanish: 'Subir documentos',
        },
    },
    ALERTS: {
        noClaimsOrLeaves: {
            english: 'No claims or leaves were found',
            spanish: 'No se encontraron reclamaciones ni hojas.',
        },
        fetchClaimsErrorER: {
            english: "Something went wrong while retrieving claims and leaves for this employee",
            spanish: 'Algo salió mal al recuperar reclamos y licencias para este empleado',
        },
        fetchClaimsErrorEE: {
            english: "Something went wrong while retrieving your claims and leaves",
            spanish: 'Algo salió mal al recuperar tus reclamos y permisos.',
        }
    },
    FIELDS: {
        eventNumberText: {
            english: 'Event no.',
            spanish: 'Evento no.'
        },
        receivedDateText: {
            english: 'Received date',
            spanish: 'Fecha de recepción'
        },
        determinationDateText: {
            english: 'Determination date',
            spanish: 'Fecha de determinación'
        },
        leaveCategoryText: {
            english: 'Leave category',
            spanish: 'Categoría de licencia'
        }
    },
    COVERAGE: {
        STD: {
            english: 'Short term disability',
            spanish: 'Incapacidad a corto plazo'
        },
        LTD: {
            english: 'Long term disability',
            spanish: 'Incapacidad a largo plazo'
        },
        STAT: {
            english: 'Statutory Disability',
            spanish: 'Incapacidad legal',
        },
        paidLeave: {
            english: 'Paid leave',
            spanish: 'Licencia paga'
        },
        leave: {
            english: 'Leave',
            spanish: 'Licencia'
        }
    },
    showMore: {
        english: 'Show more',
        spanish: 'Mostrar más'
    },
    showLess: {
        english: 'Show less',
        spanish: 'Mostrar menos'
    }
};
export default StatusText;
