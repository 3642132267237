import React from 'react';
import { Link } from 'react-router-dom';
import { compareAsc, parse } from 'date-fns';
import { withRouter } from '../../../../../../utils/withRouter';
import { Alert, Button, CompositeTable, Container, Modal, Spinner, TabContainer, TabSection, } from '@digitools/honest-react';
import useGet from '../../../../../../hooks/useGet';
import checkDate from 'status/utilities/checkDate';
import { encode } from 'status/utilities/hash';
import { absReasons, absTypes, enteredByTypes, submissionMethods, transTypes } from './UACodes';
import styles from './UnplannedAbsence.scss';
import { formatToUtc } from 'src/utils/dateUtils';
const formatDate = date => {
    if (date) {
        const formattedDate = checkDate(formatToUtc(date, 'MM/dd/yyyy'));
        if (formattedDate !== '') {
            return formattedDate;
        }
    }
    return 'N/A';
};
const formatDuration = duration => {
    if (duration && duration !== '00:00') {
        return duration;
    }
    return 'N/A';
};
const formatTime = time => {
    if (time) {
        if (checkDate(formatToUtc(time, 'MM/dd/yyyy')) !== '') {
            return formatToUtc(time, 'h:mm a');
        }
    }
    return 'N/A';
};
const formatFMLIndicator = fmlRelated => {
    if (fmlRelated === 'Y') {
        return 'Yes';
    }
    return 'No';
};
const OpenButton = ({ transNum, showModal, getUADetails }) => {
    const handleOnClick = () => {
        getUADetails();
        showModal();
    };
    // console.log('transNum', transNum);
    return (<Button data-testid='openmodal' style={{ paddingTop: '0', fontWeight: 'normal' }} onClick={handleOnClick} buttonType={'text'}>
      {transNum}
    </Button>);
};
const DisplayField = ({ label, children }) => (<div className='mb-2'>
    <span style={{ fontWeight: 'bold' }}>{label}&nbsp;</span>
    {children}
  </div>);
const UADetails = withRouter(({ getUADetails, uaDetails, error, loading, location }) => {
    const statusPath = location.pathname.split('/coveragedetail')[0];
    if (loading || (!uaDetails && !error)) {
        return (<div data-testid={'ua-details-loading'}>
        <Spinner id='uat-table-spinner1'/>
      </div>);
    }
    if (error) {
        return <Alert type={'error'}>There was a problem retrieving unplanned absence details.</Alert>;
    }
    let AssociatedTransField = () => <>N/A</>;
    if (uaDetails.associatedTransNum && uaDetails.associatedTransNum.trim()) {
        const getAssociatedTransNumDetails = () => {
            getUADetails({ url: `/ua-service/absences/${uaDetails.associatedTransNum}/getAbsenceDetails` });
        };
        AssociatedTransField = () => (<Button style={{ paddingTop: '0', fontWeight: 'normal' }} onClick={getAssociatedTransNumDetails} buttonType={'text'}>
        {uaDetails.associatedTransNum}
      </Button>);
    }
    return (<Container>
      <div className='mb-4'>
        <DisplayField label='Event ID:'>{uaDetails.transNum}</DisplayField>
      </div>
      <TabContainer>
        <TabSection name='Details' data-testid={'tabsection'}>
          <div className={styles.uaDetails}>
            <DisplayField label='Transaction Number:'>{uaDetails.transNum}</DisplayField>
            <DisplayField label='Entered By:'>{enteredByTypes[uaDetails.enteredBy]}</DisplayField>
            <DisplayField label='Time Off Date:'>{formatDate(uaDetails.uaStartDate)}</DisplayField>
            <DisplayField label='Time Off Duration:'>{formatDuration(uaDetails.uaDuration)}</DisplayField>
            <DisplayField label='Time Off Type:'>{absTypes[uaDetails.absType]}</DisplayField>
            <DisplayField label='Time Off Reason:'>{absReasons[uaDetails.absReason]}</DisplayField>
            <DisplayField label='Start Time:'>{formatTime(uaDetails.uaStartTime)}</DisplayField>
            <DisplayField label='End Time:'>{formatTime(uaDetails.uaEndTime)}</DisplayField>
            <DisplayField label='FML Related:'>{formatFMLIndicator(uaDetails.fmlRelated)}</DisplayField>
            <DisplayField label='Associated Leave Number:'>
              {uaDetails.associatedLeave ? (<Link to={`${statusPath}/leaves/${encode(uaDetails.associatedLeave)}/details`}>
                  {uaDetails.associatedLeave}
                </Link>) : ('N/A')}
            </DisplayField>
            <DisplayField label='Submissions Date (EST):'>
              {formatDate(uaDetails.uaSubmitDate)} {formatTime(uaDetails.uaSubmitTime)}
            </DisplayField>
            <DisplayField label='Submission Method:'>{submissionMethods[uaDetails.submissionMethod]}</DisplayField>
            <DisplayField label='Transaction Type:'>{transTypes[uaDetails.transType]}</DisplayField>
            <DisplayField label='Related Transaction Number:'>{<AssociatedTransField />}</DisplayField>
          </div>
        </TabSection>
      </TabContainer>
    </Container>);
});
const UAIDCell = ({ data }) => {
    const { error: uaDetailsError, loading: loadUaDetails, data: uaDetails, get: getUADetails, } = useGet({
        url: `/ua-service/absences/${data.transNum}/getAbsenceDetails`,
        noInitial: true,
        handleUnauthorized: true,
        options: {
            headers: {
                Accept: 'application/hal+json',
            },
        },
    });
    return (<Modal data-testid='ua-details-modal' hasCloseIcon={true} headerText='Unplanned Absence Details' size='large' footerPrimaryButtonText='Close' modalOpenRenderButton={props => <OpenButton transNum={data?.transNum} getUADetails={getUADetails} {...props}/>} containerMaxHeight={'90%'} contentMaxHeight={'90vh'}>
      <UADetails getUADetails={getUADetails} uaDetails={uaDetails} error={uaDetailsError} loading={loadUaDetails}/>
    </Modal>);
};
const UATable = ({ employeeData, empId }) => {
    const { loading, error, data: unplannedAbsences, } = useGet({
        url: '/ua-service/absences/getLastYear',
        options: {
            headers: {
                Accept: 'application/hal+json',
            },
            params: {
                empId: empId ? empId : employeeData.empId.trim(),
            },
        },
        handleUnauthorized: true,
    });
    if (loading) {
        return <Spinner id='uat-table-spinner2'/>;
    }
    if (error) {
        return <Alert type={'error'}>There was a problem retrieving unplanned absences.</Alert>;
    }
    if (!unplannedAbsences || unplannedAbsences.length === 0) {
        return <Alert type={'info'}>No unplanned absences found for this employee.</Alert>;
    }
    const data = [];
    if (unplannedAbsences.length > 0) {
        unplannedAbsences.forEach(ua => {
            const formattedUA = {
                transNum: ua.transNum,
                uaStartDate: formatDate(ua.uaStartDate),
                transType: transTypes[ua.transType],
                absReason: absReasons[ua.absReason],
            };
            data.push(formattedUA);
            // console.log('data', data);
        });
    }
    const columns = [
        {
            Cell: UAIDCell,
            Header: 'Transaction #',
            accessor: 'transNum',
            id: 'transNum',
        },
        {
            Header: 'Date',
            accessor: 'uaStartDate',
            id: 'uaStartDate',
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Header: 'Type',
            accessor: 'transType',
            id: 'transType',
        },
        {
            Header: 'Reason',
            accessor: 'absReason',
            id: 'absReason',
        },
    ];
    const defaultSorted = [
        {
            id: 'uaStartDate',
            desc: true,
        },
    ];
    return (<div className={styles.tableContainer}>
      <CompositeTable data-testid='ua-table' data={data} columns={columns} defaultSorted={defaultSorted} paginated={data.length > 10}/>
    </div>);
};
export default UATable;
