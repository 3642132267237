import { Alert, Box, Col, Heading, Icon, Row, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GrayTextDiv, GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import useTranslate from 'src/hooks/useTranslate';
import ConfirmationText from './confirmationText';
import useAsDataContext from '../../hooks/useAsDataContext';
import style from './confirmation.scss';
import { useNavigate } from 'react-router-dom';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import DownloadAndPrint from './DownloadPdf';
import useAsContext from '../../hooks/useAsConfigContext';
const ConfirmHeading = styled(Heading) `
  font-weigth: 400 !important;
  font-family: !important;
  font-size: 35px !important;
`;
const StyledMessage = styled('div') `
  font-size: 15px !important;
  font-weight: 700 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 1025px) {
    margin-top: 1rem;
  }
`;
const ScalableBorderlessBox = styled(Col) `
  @media (max-width: 500px) {
    width: 50%;
    margin-left: 0;
  }
`;
const pStyle = {
    lineHeight: '2.25rem',
};
const spacerDiv = {
    marginTop: '-0.2rem',
};
const BorderedTextDiv = styled('div') `
  border: 3px solid #127d00;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
`;
const FirstBorder = styled('div') `
  border-left: 20px solid #ad112b;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  height: 90px;
`;
const SecondBorder = styled('div') `
  border-left: 20px solid #762135;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  height: 90px;
`;
const ThirdBorder = styled('div') `
  border-left: 20px solid #ff5d0f;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  height: 90px;
`;
const NextStepCol = styled(Col) `
  padding-top: 35px;
  padding-left: 15px;
  font-size: 25px;
`;
const StepCol = styled(Col) ``;
const NextStepSpan = styled('span') `
  color: #37424a;
  font-size: 14px !important;
`;
const NextStepHeading = styled('span') `
  font-weight: bold;
  color: #333333;
`;
const StepHeading = styled(Row) `
  color: #5a5a5a;
  font-size: 20px;
  padding-left: 7px;
  padding-right: 7px;
  line-height: 60px;
`;
const DataDiv = styled('div') `
  width: 80%;
  @media only screen and (max-width: 765px) {
    width: 100%;
    margin-left: 0;
  }
`;
const Confirmation = () => {
    const { t } = useTranslate();
    let navigate = useNavigate();
    const { experience } = useExperience();
    const { claimantData, accommodationResponse } = useAsDataContext();
    const { trackView, trackEvent } = useTealium();
    const [downloadError, setDownloadError] = useState();
    const { asConfig } = useAsContext();
    useEffect(() => {
        if (accommodationResponse === undefined) {
            if (experience === Experience.EMPLOYEE) {
                navigate('/as/employee');
            }
            else if (experience === Experience.EMPLOYER) {
                navigate('/as/employer');
            }
        }
        trackView({
            page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
            page_l5: 'confirmation'
        });
    }, []);
    return (<>
      <ConfirmHeading data-public={true} data-testid='confirmation-title-heading' theme={themes.lincoln}>
        {<span data-testid='confirmation-title-heading-accommodation'>{t(ConfirmationText.pageTitle)}</span>}
      </ConfirmHeading>
      {downloadError &&
            <Alert type={'error'} theme={themes.lincoln} closeable={true} onClose={() => setDownloadError(undefined)} data-testid={'confirmation-download-error'}>{downloadError}</Alert>}
      <BorderedTextDiv data-public={true}>
        <StyledMessage data-testid='employeeMessage'>
          <Icon name={'success'} className={'mr-1'} size={'small'} color={'success'}/>
          <span style={{ paddingLeft: '7px' }}>{t(ConfirmationText.successMsg)}</span>
        </StyledMessage>
      </BorderedTextDiv>
      <DataDiv>
        <Box className='mt-2'>
          <Row>
            <ScalableBorderlessBox md={8} data-public={true}>
              <GrayTextDiv data-testid='eventNum' style={{ color: '#a30014', fontSize: '24px' }}>
                {t(ConfirmationText.requestId)}:#{accommodationResponse?.eventNum}
              </GrayTextDiv>
            </ScalableBorderlessBox>

            <ScalableBorderlessBox md={4} data-public={true}>
              <GrayTextDiv data-testid='downloadAndPrint' style={{ color: '#244f8b' }}>
                <DownloadAndPrint setErrorMessage={setDownloadError}/>
              </GrayTextDiv>
            </ScalableBorderlessBox>

            <ScalableBorderlessBox md={8} data-private={true}>
              <GrayTextDiv data-testid='empFirstName'>
                {t(ConfirmationText.name)}
                <p style={pStyle}>{claimantData?.empFirstName} {claimantData?.empLastName}</p>
                <GraySpacerDiv style={spacerDiv}/>
              </GrayTextDiv>
            </ScalableBorderlessBox>
            <ScalableBorderlessBox md={4} data-public={true}>
              <GrayTextDiv>
                {t(ConfirmationText.date)}
                <p style={pStyle}>
                  {accommodationResponse?.submittedDate?.replace(/\-/g, '/')} at {accommodationResponse?.submittedTime}
                </p>
                <GraySpacerDiv style={spacerDiv}/>
              </GrayTextDiv>
            </ScalableBorderlessBox>
          </Row>
        </Box>

        {asConfig?.config?.customMessageConfirmation &&
            <div>
            {t(asConfig.config.customMessageConfirmation)}
          </div>} 

        <StepHeading data-public={true} data-testid='next-steps'>{t(ConfirmationText.nextStepText)}</StepHeading>
        <FirstBorder data-public={true}>
          <Row>
            <NextStepCol md={1} className={style.numberCol}>
              1
            </NextStepCol>
            <StepCol md={11} className={style.descStepCol}>
              <NextStepHeading>{t(ConfirmationText.firstStepHeading)}</NextStepHeading>
              <br></br>
              <NextStepSpan>{t(ConfirmationText.firstStepDesc)}</NextStepSpan>
            </StepCol>
          </Row>
        </FirstBorder>
        <SecondBorder data-public={true}>
          <Row>
            <NextStepCol md={1} className={style.numberCol}>
              2
            </NextStepCol>
            <StepCol md={11} className={style.descStepCol}>
              <NextStepHeading>{t(ConfirmationText.secondStepHeading)}</NextStepHeading>
              <br></br>
              <NextStepSpan>{t(ConfirmationText.secondStepDesc)}</NextStepSpan>
            </StepCol>
          </Row>
        </SecondBorder>
        <ThirdBorder data-public={true}>
          <Row>
            <NextStepCol md={1} className={style.numberCol}>
              3
            </NextStepCol>
            <StepCol md={11} className={style.descStepCol}>
              <NextStepHeading>{t(ConfirmationText.thirdStepHeading)}</NextStepHeading>
              <br></br>
              <NextStepSpan>{t(ConfirmationText.thirdStepDesc)}</NextStepSpan>
            </StepCol>
          </Row>
        </ThirdBorder>
      </DataDiv>
    </>);
};
export default Confirmation;
