import React, { useContext, useEffect, useState } from 'react';
import { RegistrationDisplayTextEn } from '../language/RegistrationDisplayTextEn';
import { RegistrationDisplayTextEs } from '../language/RegistrationDisplayTextEs';
import { ExperienceContext } from 'src/providers/ExperienceProvider';
import withExperience from '../../../../hocs/withExperience';
import { EventAction } from 'src/providers/GoogleAnalyticsProvider';
import withGoogleAnalytics from '../../../../hocs/WithGoogleAnalytics';
import CompanyCode from '../components/companyCode/CompanyCode';
import RegistrationForm from '../components/registrationForm/RegistrationForm';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import { format } from 'date-fns';
// @ts-ignore
import Styles from './RegistrationContainer.scss';
import { Alert, Box, Col, Loader, Row } from '@digitools/honest-react';
import getDisplayText from '../../../../utils/languageDisplay';
import RegisterUserApi from '../../../../api/RegisterUserApi';
import { withRouter } from 'src/utils/withRouter';
import { useNavigate } from 'react-router-dom';
const RegistrationContainer = (props) => {
    const { history, googleAnalyticsContext, captchaInitialValue } = props;
    const [showSpinner, setShowSpinner] = useState(false);
    const [serviceError, setServiceError] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [invalidCompanyCode, setInvalidCompanyCode] = useState(false);
    const [employeeRegistrationAllowed, setEmployeeRegistrationAllowed] = useState(true);
    const [disableForm, setDisableForm] = useState(true);
    const [submitServiceError, setSubmitServiceError] = useState(false);
    const [resetCaptcha, setResetCaptcha] = useState(false);
    const navigate = useNavigate();
    const { language } = useContext(ExperienceContext);
    const { trackView } = useTealium();
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, RegistrationDisplayTextEn, RegistrationDisplayTextEs, language]);
    const registerNewUser = async (values) => {
        googleAnalyticsContext.trackClick('Register');
        const { firstName, lastName, preferredEmail, preferredPhone, username, pwd, termsAndConditions, captcha } = values;
        if (typeof selectedCompany !== 'undefined') {
            const userMetadata = {
                firstName,
                lastName,
                emailAddress: preferredEmail,
                phoneNumber: preferredPhone.toString().replace(/[^\d]/g, ''),
                termsAndConditionsAccepted: termsAndConditions,
                elecConsentAcceptedDate: format(new Date(), 'yyyy-MM-dd'),
                registrationCode: selectedCompany.registrationCode,
            };
            const requestBody = {
                userLoginId: username,
                roleNames: [selectedCompany.registrationRole],
                lastLogin: '1900-01-01 00:00:00.000Z',
                password: pwd,
                userMetadata,
            };
            try {
                setShowSpinner(true);
                await RegisterUserApi().registerUser(requestBody, captcha);
                // if 201 rederect
                navigate('/public/verify-email');
            }
            catch (error) {
                // If 409 username or email already in use
                window.scrollTo(0, 0); // put user at top of page to show alert
                if (error && error.response && error.response.status === 409) {
                    googleAnalyticsContext.trackError(EventAction.ENTERVALUE, 'Account already in use');
                    setServiceError('CONFLICT_ERROR');
                }
                else if (error?.response?.status === 400 && error?.response?.data[0]?.message === 'Weak Password') {
                    googleAnalyticsContext.trackError(EventAction.ENTERVALUE, 'Weak password');
                    setServiceError('WEAK_PASSWORD');
                }
                else {
                    googleAnalyticsContext.trackError(EventAction.CLICK, 'Error registering user');
                    setServiceError('SERVICE_ERROR');
                }
                setShowSpinner(false);
                setSubmitServiceError(true);
            }
            finally {
                setResetCaptcha(true);
            }
        }
    };
    const validateCompanyCode = async (values) => {
        googleAnalyticsContext.trackClick('Validate Company Code');
        setShowSpinner(true);
        setInvalidCompanyCode(false);
        setEmployeeRegistrationAllowed(true);
        await RegisterUserApi()
            .validateCompanyCode(values.companyCode)
            .then((response) => {
            if (response.data.registrationRole === 'employee' && response.data.isSpoeEnabledCustomer) {
                setEmployeeRegistrationAllowed(false);
                setShowSpinner(false);
            }
            else {
                setSelectedCompany(response.data);
                setDisableForm(false);
                setShowSpinner(false);
            }
        })
            .catch((error) => {
            googleAnalyticsContext.trackError(EventAction.ENTERVALUE, 'Invalid Company Code');
            if (error && error.response && error.response.status === 404) {
                setInvalidCompanyCode(true);
                setShowSpinner(false);
            }
            else {
                googleAnalyticsContext.trackError(EventAction.CLICK, 'Unable to validate Company Code');
                setServiceError('SERVICE_ERROR');
                setShowSpinner(false);
            }
        });
    };
    const renderServiceError = (errorType) => {
        let errorMessage;
        switch (errorType) {
            case 'SERVICE_ERROR':
                errorMessage = getLocalDisplayText('serviceError');
                break;
            case 'CONFLICT_ERROR':
                errorMessage = getLocalDisplayText('usernameEmailErrorMessage');
                break;
            case 'WEAK_PASSWORD':
                errorMessage = getLocalDisplayText('weakPasswordErrorMessage');
                break;
        }
        return (<Row className={'mt-3'}>
        <Col>
          <Alert id={errorType.toLowerCase()} type={'error'} auto-focus={true} data-public={true}>
            {errorMessage}
          </Alert>
        </Col>
      </Row>);
    };
    const companyName = selectedCompany ? selectedCompany.customerDisplayName : '';
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.REGISTRATION,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.REGISTRATION.REGISTER,
        });
    }, []);
    return (<Loader id='registration-container-spinner' isShown={showSpinner}>
      {/*  Need a reducer for all the error states we are trying to handle with the service Error alert */}
      {serviceError ? renderServiceError(serviceError) : null}
      <Box color={'grayLightest'} className={Styles.containerStyle} data-public={true}>
        {disableForm ? (<CompanyCode getLocalDisplayText={getLocalDisplayText} validateCompanyCode={validateCompanyCode} invalidCompanyCode={invalidCompanyCode} employeeRegistrationAllowed={employeeRegistrationAllowed} history={history}/>) : (<RegistrationForm getLocalDisplayText={getLocalDisplayText} history={history} registerNewUser={registerNewUser} setResetCaptcha={setResetCaptcha} companyName={companyName} submitError={submitServiceError} resetCaptcha={resetCaptcha} captchaInitialValue={captchaInitialValue}/>)}
      </Box>
    </Loader>);
};
export default withRouter(withExperience(withGoogleAnalytics(RegistrationContainer)));
