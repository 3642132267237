import { Alert, Button, Col, Collapse, Form, Row, TextInput } from '@digitools/honest-react';
import React, { useContext, useRef, useState } from 'react';
import getDisplayText from '../../../../utils/languageDisplay';
import ForgotInfoDisplayEn from '../i18n/ForgotInfoDisplayTextEn';
import ForgotInfoDisplayEs from '../i18n/ForgotInfoDisplayTextEs';
// @ts-ignore
import Styles from './ForgotInfo.scss';
import { withRouter } from 'src/utils/withRouter';
import { sendForgotUsernameEmail } from '../../../../api/AuthServiceApi';
import Captcha from '../../../../components/captcha/Captcha';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import useValidEmail from '../../../../hooks/fieldValidation/useValidEmail';
import useTealium from '../../../../hooks/useTealium';
import { ExperienceContext } from '../../../../providers/ExperienceProvider';
import { EventAction, GoogleAnalyticsContext } from '../../../../providers/GoogleAnalyticsProvider';
import { getTraceId } from '../../../../utils/axiosUtils';
import { gaLabelFactory } from '../../../../utils/gaUtils';
import LanguageToggle from '../../../../utils/LanguageToggle';
const GA_FEATURE_PREFIX = 'FORGOT_INFO';
const generateGALabel = gaLabelFactory(GA_FEATURE_PREFIX);
const ForgotUsername = ({ setIsLoading: setIsLoading, captchaInitialValue: captchaInitialValue, }) => {
    const { trackError } = useContext(GoogleAnalyticsContext);
    const { language } = useContext(ExperienceContext);
    const [forgotUsernameAlert, setForgotUsernameAlert] = useState();
    const [traceId, setTraceId] = useState('');
    const [resetCaptcha, setResetCaptcha] = useState(false);
    const formApiRef = useRef();
    const validateEmail = useValidEmail(true);
    const { trackEvent } = useTealium();
    const setFormApi = (formApi) => {
        formApiRef.current = formApi;
    };
    const resetForm = () => {
        if (formApiRef.current) {
            formApiRef.current.reset();
        }
    };
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, ForgotInfoDisplayEn, ForgotInfoDisplayEs, language]);
    const handleSubmit = async ({ emailAddress: emailAddress, captcha: captcha }) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.FORGOT_USERNAME,
            event_name: 'Forgot Username Submit'
        });
        try {
            setIsLoading(true);
            await sendForgotUsernameEmail(emailAddress, captcha);
            setForgotUsernameAlert({
                type: 'success',
                alertTitle: 'forgotUsernameSuccessAlertTitle',
                alertBody: 'forgotUsernameSuccessAlertBody',
            });
        }
        catch (error) {
            setTraceId(getTraceId(error));
            setResetCaptcha(true);
            resetForm();
            console.error('Error sending forgot username email', error);
            setForgotUsernameAlert({ type: 'error', alertTitle: 'unexpectedTitle', alertBody: 'unexpectedBody' });
            trackError(EventAction.CLICK, generateGALabel('forgot-username-submit-error'));
        }
        finally {
            setIsLoading(false);
        }
    };
    const requiredMessage = () => {
        return <LanguageToggle en={ForgotInfoDisplayEn.fieldRequired} es={ForgotInfoDisplayEs.fieldRequired}/>;
    };
    const captchaValidator = (value) => {
        return value == null ? requiredMessage() : undefined;
    };
    const format = (value) => (value != null ? value.trim() : value);
    const parse = (value) => (value != null ? value.trim() : value);
    return (
    /* TODO: allow Collapse to take style prop*/
    <Collapse bodyBackgroundColor='grayLightest' noBorder={true} type='box' title={getLocalDisplayText('forgotUsernameHeader')} isOpen={false} headerElemType='h2'>
      <Form onSubmit={handleSubmit} getApi={setFormApi}>
        <p style={{ marginTop: '-1.5rem', marginBottom: '1.5rem' }} data-testid={'forgot-username-paragraph-field'}>
          {getLocalDisplayText('forgotUsernameParagraph')}
        </p>
        {!!forgotUsernameAlert && (<Alert aria-describedby={'username-alert-message'} type={forgotUsernameAlert.type} data-testid={'alert-message'} style={{ marginBottom: '2rem' }}>
            <div>
              <strong className={'username-alert-message'}>
                {getLocalDisplayText(forgotUsernameAlert.alertTitle)}
              </strong>{' '}
              {getLocalDisplayText(forgotUsernameAlert.alertBody)}
              {traceId && ` (Error: ${traceId})`}
            </div>
          </Alert>)}
        <TextInput field={'emailAddress'} label={getLocalDisplayText('emailAddressLabel')} validate={validateEmail} validateOnBlur={true} format={format} parse={parse} data-testid={'email-field'}/>
        <Row className='mt-2'>
          <Col>
            <div className={Styles.parent}>
              <Captcha field={'captcha'} id={'forgotUsernameCaptcha'} validate={captchaValidator} validateOnChange={true} validateOnBlur={true} resetCaptcha={resetCaptcha} setCaptchaState={setResetCaptcha} initialValue={captchaInitialValue} // needed for testing purposes and should never be set outside of tests
    />
            </div>
          </Col>
        </Row>
        <div className={'d-flex mt-4'}>
          <Button type={'submit'} buttonType={'primary'} data-testid={'submit-button'}>
            {getLocalDisplayText('submitButton')}
          </Button>
        </div>
      </Form>
    </Collapse>);
};
export default withRouter(ForgotUsername);
