import React from 'react';
import styled from 'styled-components';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import DentalText from '../constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import useMediaQuery from 'src/hooks/useMediaQuery';
import StatusBadge from 'src/components/Cards/StatusBadge';
import { Row } from '@digitools/honest-react';
const StyledDiv = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
`;
const StyledItem = styled('div') `
  font-size: 0.9375rem;
  font-weight: 300;
  display: flex;
`;
const PatientNameBox = styled(Box) `
  border-radius: 0;
  border: none;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  background-color: ${({ theme }) => `${theme.palette.primary}`};
  display: flex;
`;
const PolicyDetailsBox = styled(Box) `
  padding: 0.75rem 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  border-radius: 0;
  border: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  border-top: none;
`;
const PatientStatusDiv = styled('div') `
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: ${({ hasTerminationDate }) => (hasTerminationDate ? '1.25rem' : '2.125rem')};
  margin-top: auto;
`;
const PatientDOB = styled('div') `
  border-left: ${({ theme }) => `3px solid ${theme.palette.primary}`};
  padding-left: 0.4rem;
`;
const StyledLabelHeader = styled.div `
  color: ${({ theme }) => theme.palette.primaryDarker};
  font-size: 0.9375rem;
  font-weight: 500;
`;
const getDentalStatusColor = (status) => {
    switch (status) {
        case 'INACTIVE':
            return 'gray';
        case 'FUTURE':
            return 'grayDarker';
        case 'ACTIVE':
        default:
            return 'accent2Light';
    }
};
const MemberInformationBox = () => {
    const { currentMember } = useDentalDataContext();
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const { t } = useTranslate();
    return (<>
      {!!currentMember && (<StyledDiv id='patientDetails'>
          {!isMobile && (<PatientNameBox theme={themes.lincoln}>
              <Heading elemType={'h2'} theme={themes.lincoln} color={'white'} style={{ fontSize: '1.5rem', fontWeight: 300 }} data-testid={'patient-name'} data-private>
                {currentMember.lastName}, {currentMember.firstName}
              </Heading>
            </PatientNameBox>)}
          <PolicyDetailsBox theme={themes.lincoln} data-public>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <StyledItem data-testid={'patient-effective-date'} className='mb-1'>
                <div id='effectiveDate'>{t(DentalText.effectiveDateText)}:</div>&nbsp;
                <div aria-labelledby='patientDetails effectiveDate'>{currentMember.effectiveDate}</div>
              </StyledItem>
              <StatusBadge data-testid={'status-badge'} backgroundColor={getDentalStatusColor(currentMember.status)}>
                {currentMember.status}
              </StatusBadge>
            </Row>
            {!!currentMember.terminationDate && (<StyledItem data-testid={'patient-termination-date'}>
                <div id='terminationDate'>{t(DentalText.terminationDateText)}:</div>&nbsp;
                <div aria-labelledby='patientDetails terminationDate'>{currentMember.terminationDate}</div>
              </StyledItem>)}
            <PatientStatusDiv hasTerminationDate={!!currentMember.terminationDate}>
              <PatientDOB theme={themes.lincoln} data-private>
                <StyledLabelHeader theme={themes.lincoln} data-testid={'patient-member-type'}>
                  {currentMember.memberTypeDescription}
                </StyledLabelHeader>
                <StyledItem data-testid={'patient-dob'}>
                  <div id='dateOfBirth'>{t(DentalText.dateOfBirthText)}:</div>&nbsp;
                  <div aria-labelledby='patientDetails dateOfBirth' data-private>
                    {currentMember.dateOfBirth}
                  </div>
                </StyledItem>
              </PatientDOB>
            </PatientStatusDiv>
          </PolicyDetailsBox>
        </StyledDiv>)}
    </>);
};
export default MemberInformationBox;
