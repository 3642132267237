import { Box, Col, Heading, themes } from '@digitools/honest-react';
import styled from '@digitools/honest-react/dist/lib/theming/styled-components';
import React from 'react';
import { ReviewAccommodationDetailsText } from '../../constants/ReviewAccommodationDetailsText';
import useTranslate from 'src/hooks/useTranslate';
import useAsContext from '../../hooks/useAsConfigContext';
import { displayField, getSelectedDropDownItemsAsString } from '../../utils/AsDataUtils';
import { GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import { AS_PERIOD_TYPE_OPTIONS, AS_YES_NO_OPTIONS } from '../../constants/Constants';
import { ReviewText } from '../../constants/ReviewText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
const AddRequest = ({ color, count, accommodationData: accommodationRequestData, }) => {
    const { t } = useTranslate();
    const { asConfig } = useAsContext();
    const { experience } = useExperience();
    const isEmployer = experience === Experience.EMPLOYER;
    const requestedPeriodType = accommodationRequestData?.requestedPeriodType &&
        getSelectedDropDownItemsAsString(AS_PERIOD_TYPE_OPTIONS, accommodationRequestData?.requestedPeriodType, t);
    const existingAccomm = accommodationRequestData?.existingAccommodation &&
        getSelectedDropDownItemsAsString(AS_YES_NO_OPTIONS, accommodationRequestData?.existingAccommodation, t);
    const rtwSaw = accommodationRequestData?.accommodationWorkOrHome &&
        getSelectedDropDownItemsAsString(asConfig?.config?.rtwSawDropDown, accommodationRequestData?.accommodationWorkOrHome, t);
    const requestedAccommType = accommodationRequestData?.requestedAccommodationType &&
        getSelectedDropDownItemsAsString(asConfig?.config?.providedAccommodationTypeDropDown, accommodationRequestData?.requestedAccommodationType, t);
    const restrictonOrLimitation = accommodationRequestData?.restrictionsAndLimits &&
        getSelectedDropDownItemsAsString(asConfig?.config?.rlListDropDown, accommodationRequestData?.restrictionsAndLimits, t);
    const status = accommodationRequestData?.status &&
        getSelectedDropDownItemsAsString(asConfig?.config?.requestStatusDropDown, accommodationRequestData?.status, t);
    const assistanceRequired = accommodationRequestData?.assistanceRequired &&
        getSelectedDropDownItemsAsString(asConfig?.config?.assistanceDropDown, accommodationRequestData?.assistanceRequired, t);
    const priorCarrier = accommodationRequestData?.priorCarrier &&
        getSelectedDropDownItemsAsString(AS_YES_NO_OPTIONS, accommodationRequestData?.priorCarrier, t);
    const providedAccomodationTypeCode = accommodationRequestData?.providedAccomodationTypeCode &&
        getSelectedDropDownItemsAsString(asConfig?.config?.providedAccommodationTypeDropDown, accommodationRequestData?.providedAccomodationTypeCode, t);
    const statusReasonCode = accommodationRequestData?.statusReasonCode &&
        getSelectedDropDownItemsAsString(asConfig?.config?.statusReasonDropDown, accommodationRequestData?.statusReasonCode, t);
    const BoxContainer = styled(Box) `
    border-left-color: ${color};
    border-left-width: 20px;
    gap: 5px;
    padding: 15px 10px 10px;
    overflow-wrap: anywhere;
    @media (min-width: 768px) {
      gap: 13px;
      padding: 20px;
      overflow-wrap: unset;
    }
  `;
    const topSpacing = {
        flex: '1',
    };
    const descriptionStyle = {
        fontSize: '14px',
        color: themes.lincoln.palette.grayDark,
    };
    const RequestNum = styled.span `
    font-size: 28px !important;
    color: #3d4a50 !important;
    line-height: 0.8 !important;
    @media (min-width: 768px) {
      font-size: 36px !important;
    }
  `;
    const questionText = {
        fontSize: '14px',
    };
    const answerStyles = {
        fontSize: '18px',
        color: themes.lincoln.palette.grayDark,
    };
    return (<BoxContainer data-testid='review-add-request' className={'d-flex align-items-start'}>
      <RequestNum theme={themes.lincoln}>{count}</RequestNum>
      <Col style={topSpacing} className={'mt-0'}>
        {(requestedAccommType || accommodationRequestData?.requestedAccommodationDetail) && (<div>
            {requestedAccommType && (<Heading elemType={'h4'} theme={themes.lincoln} className={'fw-bold'}>
                {requestedAccommType}
              </Heading>)}
            {accommodationRequestData?.requestedAccommodationDetail && (<span style={descriptionStyle}>{accommodationRequestData?.requestedAccommodationDetail}</span>)}
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer && asConfig?.config?.asRep && accommodationRequestData?.requestReceivedDate && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {CustomEmployeeText(t(ReviewText.addRequest.requestReceivedDate))}
            </Heading>
            <span style={answerStyles}>{accommodationRequestData?.requestReceivedDate}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer && asConfig?.config?.asRep && status && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.status)}
            </Heading>
            <span style={answerStyles}>{status}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData?.status === 'CL' &&
            accommodationRequestData?.closedDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.closedDate)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.closedDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer && asConfig?.config?.asRep && accommodationRequestData?.status === 'CL' && statusReasonCode && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.statusReasonCode)}
            </Heading>
            <span style={answerStyles}>{statusReasonCode}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer && asConfig?.config?.asRep && assistanceRequired && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.assistRequired)}
            </Heading>
            <span style={answerStyles}>{assistanceRequired}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            providedAccomodationTypeCode && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.providedAccomodationTypeCode)}
              </Heading>
              <span style={answerStyles}>{providedAccomodationTypeCode}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            accommodationRequestData?.providedDetailsText && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.providedDetailsText)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.providedDetailsText}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {!(isEmployer && asConfig?.config?.asRep && accommodationRequestData?.status !== 'IP') &&
            requestedPeriodType && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewAccommodationDetailsText.expectedDurationEE)}
              </Heading>
              <span style={answerStyles}>{requestedPeriodType}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer && asConfig?.config?.asRep && accommodationRequestData?.status !== 'IP' && requestedPeriodType && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.actualPeriodType)}
            </Heading>
            <span style={answerStyles}>{requestedPeriodType}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {accommodationRequestData?.status !== 'CL' && accommodationRequestData?.requestedStartDate && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewAccommodationDetailsText.desiredStartDateEE)}
            </Heading>
            <span style={answerStyles}>{accommodationRequestData?.requestedStartDate}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData?.status !== 'IP' &&
            accommodationRequestData?.actualBeginDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.actualBeginDate)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.actualBeginDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {accommodationRequestData.status !== 'CL' &&
            accommodationRequestData?.requestedPeriodType === 'T' &&
            accommodationRequestData?.anticipatedEndDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewAccommodationDetailsText.anticipatedEndDate)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.anticipatedEndDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            accommodationRequestData.requestedPeriodType === 'T' &&
            accommodationRequestData?.actualEndDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.actualEndDate)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.actualEndDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer && asConfig?.config?.asRep && restrictonOrLimitation && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.restrictonOrLimitation)}
            </Heading>
            <span style={answerStyles}>{restrictonOrLimitation}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            displayField(accommodationRequestData?.restrictionsAndLimits) &&
            accommodationRequestData?.suppRestrictionsAndLimitations && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.suppRestrictionsAndLimitations)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.suppRestrictionsAndLimitations}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer && asConfig?.config?.asRep && accommodationRequestData?.interactiveBeginDate && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {CustomEmployeeText(t(ReviewText.addRequest.interactiveBeginDate))}
            </Heading>
            <span style={answerStyles}>{accommodationRequestData?.interactiveBeginDate}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer && asConfig?.config?.asRep && accommodationRequestData?.initialMeetingDate && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {CustomEmployeeText(t(ReviewText.addRequest.initialMeetingDate))}
            </Heading>
            <span style={answerStyles}>{accommodationRequestData?.initialMeetingDate}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            accommodationRequestData?.determinationInfoReceivedDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {CustomEmployeeText(t(ReviewText.addRequest.determinationInfoReceivedDate))}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.determinationInfoReceivedDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            accommodationRequestData?.determinationDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.determinationDate)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.determinationDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {existingAccomm && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewAccommodationDetailsText.alreadyReportedEE)}
            </Heading>
            <span style={answerStyles}>{existingAccomm}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer && asConfig?.config?.asRep && priorCarrier && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
              {t(ReviewText.addRequest.priorCarrier)}
            </Heading>
            <span style={answerStyles}>{priorCarrier}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'CL' &&
            accommodationRequestData?.requestedAccommodationCost && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.requestedAccommCost)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.requestedAccommodationCost}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status !== 'IP' &&
            accommodationRequestData?.providedCostAmount && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln} className={'question'}>
                {t(ReviewText.addRequest.providedCostAmount)}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData?.providedCostAmount}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
        {rtwSaw && (<div>
            <Heading elemType={'h5'} style={questionText} theme={themes.lincoln}>
              {t(ReviewAccommodationDetailsText.willReturnToWorkEE)}
            </Heading>
            <span style={answerStyles}>{rtwSaw}</span>
            <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
          </div>)}
        {isEmployer &&
            asConfig?.config?.asRep &&
            accommodationRequestData.status === 'CL' &&
            accommodationRequestData.requestedPeriodType === 'T' &&
            accommodationRequestData?.rtwWithoutAccomodationDate && (<div>
              <Heading elemType={'h5'} style={questionText} theme={themes.lincoln}>
                {CustomEmployeeText(t(ReviewText.addRequest.rtwWithoutAccomodationDate))}
              </Heading>
              <span style={answerStyles}>{accommodationRequestData.rtwWithoutAccomodationDate}</span>
              <GraySpacerDiv className={'my-2 d-none d-md-block'}/>
            </div>)}
      </Col>
    </BoxContainer>);
};
export default React.memo(AddRequest);
