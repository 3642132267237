import React, {useEffect} from 'react';
import { Alert, Box, Spinner, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { useOfficeInfo, useCliOfficeInfo } from 'status/hooks';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { getOffice, getOfficeCustomizedInfo } from 'status/utilities/getOffice';
import titleCase from 'status/utilities/titleCase';
import formatPhone from 'status/utilities/formatPhone';
import { getEnvVariable, isProd } from '../../../../../utils/envUtils';

const Error = ({ error, notFound }) => {
  const noData = notFound || (error.response && error.response.status === 404);
  if (noData) {
    return <></>;
  } else {
    return (
      <Alert type={'error'}>
        <span>
          <strong>Error!</strong>&nbsp;Unable to load leave specialist.
        </span>
      </Alert>
    );
  }
};

const LeaveSpecialist = ({ specialistName, offNum, leaveNum, employeeName }) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const customerId = user?.customerId;

  const { error: infoError, loading: infoLoading, officeInfo, getOfficeInfo } = useOfficeInfo();
  const { error: cliOfficeError, loading: cliOfficeLoading, cliOfficeInfo, getCliOfficeInfo } = useCliOfficeInfo(customerId);

  useEffect(() => {
    getOfficeInfo();
    getCliOfficeInfo();
  },[]);

  if (infoLoading || cliOfficeLoading) {
    return (
      <Box className='mb-3'>
        <Spinner id='leave-specialist-spinner' />
      </Box>
    );
  }

  if (infoError || cliOfficeError) {
    return <Error className='mb-3' error={infoError || cliOfficeError} />;
  }

  // Unique case where there is no office number found
  // Or there are no leave details
  if (!offNum) {
    return <Error className='mb-3' notFound={true} />;
  }

  const specialistFirstName = specialistName ? specialistName.split(' ')[0] : '';
  const specialistLastName = specialistName ? specialistName.split(' ')[1] : '';

  const customPhone = getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityLine', 'selectedLeavePhone');
  const customFax = getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityFax', 'selectedLeaveFax');
  const customEmail = getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityEmail', 'selectedLeaveEmail');


  return (
    <div className='mb-3'>
      <Box type='header' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        {t('leaveSpecialistText')}
      </Box>
      <Box style={{ wordWrap: 'break-word', border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <small data-private={true} className='d-block'>
          {titleCase(specialistFirstName)} {titleCase(specialistLastName)}
        </small>
        {cliOfficeInfo ? (
          <>
            <small className='d-block'>{'Lincoln Financial'}</small>
            <small className='d-block'>{'P.O. Box 2578'}</small>
              <small className='d-block'>
                {'Omaha, NE 68172-9688'}
              </small>
            <small className='d-block'>
              <strong>{t('phoneText')}: </strong> {formatPhone(customPhone)}
            </small>
            {customEmail !== undefined && customEmail !== "" && 
              <small className='d-block'>
                <strong>Email: </strong>{' '}
                {(customEmail !== undefined && customEmail !== "") ? (
                  <a href={`mailto:${customEmail}?subject=${employeeName} %23${leaveNum}`}>
                    {customEmail}
                  </a>
                ) : null}
              </small>
            }
            {customFax !== undefined && 
              <small className='d-block'>
                <strong>{t('faxText')}: </strong> { formatPhone(customFax)}
              </small>
            }
          </>
        ) : (
          <Alert type='info' className='mt-2'>
            <small>No office info.</small>
          </Alert>
        )}
      </Box>
    </div>
  );
};

export default LeaveSpecialist;
