export var Experience;
(function (Experience) {
    Experience["PUBLIC"] = "Public";
    Experience["EMPLOYER"] = "Employer";
    Experience["EMPLOYEE"] = "Employee";
})(Experience || (Experience = {}));
export var ExperienceCode;
(function (ExperienceCode) {
    ExperienceCode["PUBLIC"] = "Public";
    ExperienceCode["EMPLOYER"] = "ER";
    ExperienceCode["EMPLOYEE"] = "EE";
})(ExperienceCode || (ExperienceCode = {}));
