// TODO: translate button texts
export const CliText = {
    intakeHeadingClaimCustomer: {
        english: 'Disability claim',
        spanish: 'Reclamación por discapacidad',
    },
    intakeHeadingLeaveCustomer: {
        english: 'Leave',
        spanish: 'Dejar',
    },
    intakeHeadingClaimLeaveCustomer: {
        english: 'Disability claim & leave',
        spanish: 'Reclamo y licencia por discapacidad',
    },
    nextButton: {
        english: 'Continue',
        spanish: 'Seguir',
    },
    backButton: {
        english: 'Back',
        spanish: 'Espalda',
    },
    saveForLaterButton: {
        english: 'Save for later',
        spanish: 'Guardar para más adelante'
    },
    saveAndContinue: {
        english: 'Save and continue',
        spanish: 'Guardar y continuar'
    },
    submit: {
        english: 'Submit',
        spanish: 'Enviar'
    },
    continue: {
        english: 'Continue',
        spanish: 'Seguir'
    },
    leaveModal: {
        english: 'Leave application',
        spanish: 'Deje la aplicación'
    },
    leaveModalHeader: {
        english: 'Are you sure you want to leave?',
        spanish: '¿Estás seguro de que quieres irte?'
    },
    leaveModalHeaderSuccess: {
        english: 'Changes will not be saved. Would you like to continue?',
        spanish: 'No se guardarán todos los cambios. ¿Te gustaria continuar?'
    },
    paymentContinue: {
        english: 'Continue',
        spanish: 'Continuar'
    },
};
