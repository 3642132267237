import React from 'react';
import { Auth0Context } from '../context';
import useAuthentication from 'src/hooks/useAuthentication';
import { getEnvVariable } from 'src/utils/envUtils';

const Auth0Provider = ({ children }) => {
  const { authHeader, isAuthenticated, user, logout, loading, error } = useAuthentication();

  const userMetadataUrl = getEnvVariable('userMetadataUrl');

  // TODO get rid of usermeta .. Usermeta and user were different in the old react status up
  const userMeta = user;

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        error,
        userMeta,
        loading,
        logout,
        authHeader,
      }}>
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
