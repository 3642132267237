export const SecurityAndSettingsText = {
    profileHeading: {
        english: 'Security settings',
        spanish: 'Seguridad configuración',
    },
    btnResetPassword: {
        english: 'Confirm',
        spanish: 'Confirmar',
    },
    emailVerificationWarning: {
        english: 'Your email address is not yet verified. Please verify your email address before requesting to reset your password.',
        spanish: 'Su dirección de correo electrónico aún no ha sido verificada. Por favor, verifique su dirección antes de solicitar un cambio de contraseña.',
    },
    requestError: {
        english: 'The application has encountered an unknown error, if this issue continues, please contact us for assistance ',
        spanish: 'Algo salió mal. Intente nuevamente dentro de unos instantes ',
    },
    resetPWHeading: {
        english: 'Change password',
        spanish: 'Cambiar contraseña',
    },
    resetPWText: {
        english: 'Click the confirm button below to request a reset password email. After clicking confirm you will be logged out and an email will be sent to ',
        spanish: 'Haga click en el botón \'Confirmar\' para solicitar un email para cambiar su contraseña. Luego de hacer click se cerrará la sesión y un email será enviado a '
    },
    resetPWRestrictedMsg: {
        english: 'To reset your password, ',
        spanish: 'Para restablecer su contraseña, '
    },
    resetPWRestrictedLink: {
        english: 'click here.',
        spanish: 'haga clic aquí.'
    },
};
