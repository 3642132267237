const dashedDateFormatToString = date => {
  if (date) {
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let year = date.substring(0, 4);

    const formattedDate = month + '/' + day + '/' + year;
    return formattedDate === '12/31/1969' || formattedDate === '01/01/1900' ? '' : formattedDate;
  }
  return '';
};

export default dashedDateFormatToString;
