const CliVerificationText = {
    verificationHeading: {
        english: 'First, we need to verify your identity',
        spanish: 'Primero, necesitamos verificar su identidad',
    },
    verificationSubHeading: {
        english: 'Given this information, we\'ll check our systems and prepopulate as much information as we can for you.',
        spanish: 'Dada esta información, revisaremos nuestros sistemas y completaremos toda la información que podamos para usted.',
    },
    employeeIdLabel: {
        english: 'Employee ID',
        spanish: 'ID de empleado',
    },
    ssnLabel: {
        english: 'Social security number',
        spanish: 'Número de seguridad social',
    },
    verifyButton: {
        english: 'VERIFY',
        spanish: 'VERIFICAR',
    },
    verifyRequiredError: {
        english: 'This field is required',
        spanish: 'Este campo es requerido',
    },
    invalidEmpId: {
        english: 'Invalid Employee ID',
        spanish: 'ID de empleado no válido',
    },
    invalidSsn: {
        english: 'Invalid Social security number',
        spanish: 'Número de seguridad social no válido',
    },
    verificationNotFound: {
        english: 'We were not able to find your information, please try again',
        spanish: 'No pudimos encontrar tu información, vuelve a intentarlo',
    },
    verifyLimitReached: {
        english: `We're sorry we couldn't find your information using employee ID, please enter your Social security number to proceed`,
        spanish: 'Lamentamos no poder encontrar su información usando la identificación de empleado, ingrese su Número de seguridad social para continuar',
    },
};
export default CliVerificationText;
