import React from 'react';
export const ViewSubmissionText = {
    viewSubmissionPageHeading: {
        english: 'Accommodation request confirmation',
        spanish: 'Confirmación de solicitud de adaptación',
    },
    requestIdLabel: {
        english: 'Request ID#',
        spanish: 'N.º de ID de solicitud',
    },
    nameLabel: {
        english: 'NAME',
        spanish: 'NOMBRE',
    },
    todayDateLabel: {
        english: 'TODAYS DATE',
        spanish: 'FECHA',
    },
    accommodationRequestHeading: {
        english: 'Accommodation request(s)',
        spanish: 'Solicitud(es) de alojamiento',
    },
    categoryTypeLabel: {
        english: 'Category type',
        spanish: 'Tipo de categoría',
    },
    expectedDurationLabel: {
        english: 'Expected duration',
        spanish: 'Duración esperada',
    },
    desiredDateLabel: {
        english: 'Desired start date',
        spanish: 'Fecha de inicio deseada',
    },
    conditionReportedQuestion: {
        english: 'Relates to a condition already reported',
        spanish: 'Se relaciona con una condición ya reportada',
    },
    statingAtWorkQuestion: {
        english: 'Will you be returning to work after being out on leave, or will you be staying at work?',
        spanish: '¿Regresará al trabajo después de estar de baja o se quedará en el trabajo?',
    },
    printLabel: {
        english: 'PRINT',
        spanish: 'IMPRIMIR',
    },
    printPageLfgAddress: {
        english: <>The Lincoln National Life Insurance Company<br />PO Box 2609, Omaha, NE 68103-2609<br />Toll Free (800) 423-2765 Fax (877) 843-3950<br />www.LincolnFinancial.com<br />disabilityclaims@lfg.com</>,
        spanish: <>Compañía Nacional de Seguros de Vida de Lincoln<br />PO Box 2609, Omaha, NE 68103-2609<br />Gratuito (800) 423-2765 Fax (877) 843-3950<br />www.LincolnFinancial.com<br />disabilityclaims@lfg.com</>,
    },
    printPageFooterTxt: {
        english: 'Lincoln Financial Group is pleased to offer employers assistance with the administration of the American with Disabilities Act and its related amendments. Please remember that our Accommodation Services are advisory only and Lincoln Financial Group assumes no responsibility for determinations and decisions that are required to be made by the employer including, identifying employees in need of accommodations, initiation and coordinating the interactive process, DETERMINING EMPLOYEE ELIGIBILITY, deciding whether any accommodations are offered or not offered to eligible employees, and determining the duration of any accommodations.',
        spanish: 'Lincoln Financial Group se complace en ofrecer asistencia a los empleadores con la administración de la Ley de Estadounidenses con Discapacidades y sus enmiendas relacionadas. Recuerde que nuestros Servicios de alojamiento son solo de asesoramiento y Lincoln Financial Group no asume ninguna responsabilidad por las determinaciones y decisiones que debe tomar el empleador, incluida la identificación de los empleados que necesitan adaptaciones, el inicio y la coordinación del proceso interactivo, la DETERMINACIÓN DE LA ELEGIBILIDAD DEL EMPLEADO, la decisión si se ofrecen o no adaptaciones a los empleados elegibles, y determinar la duración de las adaptaciones.'
    },
    lcnNo: {
        english: 'LCN 5438889-013023',
        spanish: 'LCN 5438889-013023',
    },
    closeButtonLabel: {
        english: 'Close',
        spanish: 'Cerca',
    }
};
