const diagnosticCategory = diagnosticCategoryCode => {
  if (!diagnosticCategoryCode) {
    return undefined;
  }
  const code = diagnosticCategoryCode.trim();

  const mappings = [
    { code: '1', diagnosticCategory: 'Infectious & Parasitic' },
    { code: '2', diagnosticCategory: 'Cancer' },
    { code: '3', diagnosticCategory: 'Endocrine and Metabolic' },
    { code: '4', diagnosticCategory: 'Heart & Circulatory' },
    { code: '5', diagnosticCategory: 'Mental & Behavioral' },
    { code: '6', diagnosticCategory: 'Nervous System' },
    { code: '7', diagnosticCategory: 'Respiratory System' },
    { code: '8', diagnosticCategory: 'Digestive System' },
    { code: '9', diagnosticCategory: 'Genitourinary System' },
    { code: '10', diagnosticCategory: 'Maternity' },
    { code: '11', diagnosticCategory: 'Back' },
    { code: '12', diagnosticCategory: 'Other Muscle/Skeletal' },
    { code: '13', diagnosticCategory: 'Eye, Ear, Nose & Throat' },
    { code: '14', diagnosticCategory: 'Drugs & Alcohol' },
    { code: '15', diagnosticCategory: 'Miscellaneous' },
    { code: '16', diagnosticCategory: 'Disability Non-Disclosed' },
    { code: '17', diagnosticCategory: 'Infecious & Parasitic' },
    { code: '18', diagnosticCategory: 'Neoplasms' },
    { code: '19', diagnosticCategory: 'Blood & Immune Mechanism' },
    { code: '20', diagnosticCategory: 'Endocrine, Nutritional & Metabolic' },
    { code: '21', diagnosticCategory: 'Mental & Behavioral' },
    { code: '22', diagnosticCategory: 'Nervous System' },
    { code: '23', diagnosticCategory: 'Eye & Adnexa' },
    { code: '24', diagnosticCategory: 'Ear & Mastoid Process' },
    { code: '25', diagnosticCategory: 'Heart & Circulatory' },
    { code: '26', diagnosticCategory: 'Respiratory System' },
    { code: '27', diagnosticCategory: 'Digestive System' },
    { code: '28', diagnosticCategory: 'Skin & Subcutaneous Tissue' },
    { code: '29', diagnosticCategory: 'Musculoskeletal & Connective Tissue' },
    { code: '30', diagnosticCategory: 'Genitourinary System' },
    { code: '31', diagnosticCategory: 'Maternity' },
    { code: '32', diagnosticCategory: 'Perinatal Conditions' },
    { code: '33', diagnosticCategory: 'Congenital Abnormalities' },
    { code: '34', diagnosticCategory: 'Clinical/Lab Findings, NOS' },
    { code: '35', diagnosticCategory: 'Injury/Poisoning/Other' },
    { code: '36', diagnosticCategory: 'External Causes of Morbidity' },
    { code: '37', diagnosticCategory: 'Health Status/Service Factors' },
    { code: '38', diagnosticCategory: 'Miscellaneous' },
    { code: '39', diagnosticCategory: 'Disability Non-Disclosed' },
  ];

  var foundCategory = mappings.find(element => element.code === code);
  if (foundCategory) return foundCategory.diagnosticCategory;

  return '';
};

export default diagnosticCategory;
