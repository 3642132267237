import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Confirmation from '../pages/confirmation/Confirmation';
import ReportIntermittentAbsence from '../ReportIntermittentAbsence';
const RiaRouter = () => {
    return (<Routes>
      <Route path={`confirmation`} element={<Confirmation />}/>
      <Route path={`/`} element={<ReportIntermittentAbsence />}/>
    </Routes>);
};
export default RiaRouter;
