import { Col } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField from '../ReviewField';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import { SURGERY_YES_TYPES, SURGERY_NO_INJURY_TYPES, SURGERY_NO_ILLNESS_TYPES } from 'src/packages/cli/constants/CliSurgeryTypeConstants';
import { getEnvVariable } from 'src/utils/envUtils';
const ReviewIllnessInjuryFlow = () => {
    const { t } = useTranslate();
    const { absenceData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { experience } = useExperience();
    const getIndicatorAnswers = (accidentIndicator) => {
        switch (accidentIndicator) {
            case 'Y': {
                return t({ english: 'Yes', spanish: 'Sí' });
            }
            case 'N': {
                return 'No';
            }
            case 'U': {
                return t({ english: 'Unknown', spanish: 'No conocida' });
            }
            default: {
                return '';
            }
        }
    };
    const surgeryTypeYesLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeYesEE : formLabels.surgeryTypeYesER));
    const surgeryTypeNoIllnessLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeNoIllnessEE : formLabels.surgeryTypeNoIllnessER));
    const surgeryTypeNoInjuryLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeNoInjuryEE : formLabels.surgeryTypeNoInjuryER));
    const surgeryTypeLabel = absenceData?.surgeryIndicator === 'Y' ? surgeryTypeYesLabel : (absenceData?.claimantCondition === 'S' ? surgeryTypeNoIllnessLabel : surgeryTypeNoInjuryLabel);
    const illnessInjuryTypeLabel = absenceData?.claimantCondition === 'S' ? surgeryTypeNoIllnessLabel : surgeryTypeNoInjuryLabel;
    const accidentIndicatorLabel = (experience === Experience.EMPLOYEE ? t(formLabels.accidentIndicator) : `${t(formLabels.accidentIndicator)} (optional)`);
    const shouldDisplayIncidentDate = absenceData?.claimantCondition === 'I';
    const shouldDisplayIllnessInjury = shouldDisplayIncidentDate || absenceData?.claimantCondition === 'S';
    const shouldDisplayAccidentIndicator = absenceData?.lastDayWorked;
    const shouldDisplayMotorVehicleAccidentIndicator = shouldDisplayAccidentIndicator && absenceData?.accidentIndicator === 'Y';
    const shouldDisplayHospitalizationIndicator = absenceData?.workRelatedIndicator;
    const shouldDisplayAdmissionDate = absenceData?.hospitalizationIndicator === 'Y';
    const shouldDisplaySurgeryDate = absenceData?.surgeryIndicator === 'Y';
    const shouldDisplaySurgeryType = absenceData?.leaveType === LeaveType.OWN_ILLNESS && absenceData?.claimantCondition && shouldDisplaySurgeryDate;
    const shouldDisplayIllnessInjuryType = (getEnvVariable('enableNewSurgeryFlow') && shouldDisplaySurgeryDate && absenceData?.surgerySubTier === 'Other' || (absenceData?.surgeryIndicator !== 'Y' && absenceData?.injuryTier));
    const shouldDisplayOtherSurgery = (getEnvVariable('enableNewSurgeryFlow') ? absenceData?.injuryTier === 'Other' : (absenceData?.surgerySubTier || absenceData?.injuryTier) === 'Other');
    const shouldDisplayReleaseDate = shouldDisplayAdmissionDate && absenceData?.admissionDate?.length === 10;
    const shouldDisplayLeaveDates = absenceData?.leaveType === LeaveType.OWN_ILLNESS && absenceData?.leaveReason;
    const surgeryInitialValue = (getEnvVariable('enableNewSurgeryFlow') ? absenceData?.surgerySubTier : absenceData?.surgeryIndicator === 'Y' ? absenceData?.surgerySubTier : absenceData?.injuryTier);
    const injuryInitalValue = (getEnvVariable('enableNewSurgeryFlow') && absenceData?.injuryTier);
    const otherInitialValue = (getEnvVariable('enableNewSurgeryFlow') && absenceData?.surgerySubTier === 'Other' && absenceData?.injuryTier === 'Other' ? absenceData?.injuryOther : absenceData?.surgeryIndicator === 'Y' ? absenceData?.surgeryOther : absenceData?.injuryOther);
    const translateableSurgeryYesTypeValue = absenceData && t(SURGERY_YES_TYPES.filter(surgeryType => surgeryType?.value == surgeryInitialValue)[0]?.label);
    //@ts-ignore
    const translateableSurgeryNoIllnessTypeValue = absenceData && (getEnvVariable('enableNewSurgeryFlow') ? t(SURGERY_NO_ILLNESS_TYPES.filter(surgeryType => surgeryType?.value == injuryInitalValue)[0]?.label) : t(SURGERY_NO_ILLNESS_TYPES.filter(surgeryType => surgeryType?.value == surgeryInitialValue)[0]?.label));
    //@ts-ignore
    const translateableSurgeryNoInjuryTypeValue = absenceData && (getEnvVariable('enableNewSurgeryFlow') ? t(SURGERY_NO_INJURY_TYPES.filter(surgeryType => surgeryType?.value == injuryInitalValue)[0]?.label) : t(SURGERY_NO_INJURY_TYPES.filter(surgeryType => surgeryType?.value == surgeryInitialValue)[0]?.label));
    const translateableSurgeryTypeValue = absenceData?.surgeryIndicator === 'Y' ? translateableSurgeryYesTypeValue : (absenceData?.claimantCondition === 'S' ? translateableSurgeryNoIllnessTypeValue : translateableSurgeryNoInjuryTypeValue);
    const translateableIllnessInjuryTypeValue = (getEnvVariable('enableNewSurgeryFlow') && absenceData?.claimantCondition === 'S' ? translateableSurgeryNoIllnessTypeValue : translateableSurgeryNoInjuryTypeValue);
    return (<>
            {shouldDisplayLeaveDates && (<>
                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-private={true} data-testid='leaveBeginDate' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                ? t(formLabels.leaveBeginDateEE)
                : t(formLabels.leaveBeginDateER))} value={absenceData && absenceData.leaveBeginDate}/>
                    </Col>

                    <Col xs={12} md={6} lg={6}>
                        <ReviewField data-private={true} data-testid='leaveEndDate' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                ? t(formLabels.leaveEndDateEE)
                : t(formLabels.leaveEndDateER))} value={absenceData && absenceData.leaveEndDate}/>
                    </Col> 
                </>)}
            {shouldDisplayIllnessInjury && ((absenceData?.leaveType && (<>
                        {shouldDisplayIncidentDate && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='incidentDate' label={experience === Experience.EMPLOYEE
                    ? t(formLabels.incidentDateEE)
                    : t(formLabels.incidentDateER)} value={absenceData && absenceData.incidentDate}/>
                            </Col>)}
                        {shouldDisplayIllnessInjury && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='claimBeginDate' label={t(formLabels.claimBeginDateShortLabel)} value={absenceData && absenceData.claimBeginDate}/>
                            </Col>)}
                        {shouldDisplayIllnessInjury && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='lastDayWorked' label={t(formLabels.lastDayWorkedShort)} value={absenceData && absenceData.lastDayWorked}/>
                            </Col>)}
                        {shouldDisplayAccidentIndicator && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='accidentIndicator' label={accidentIndicatorLabel} value={absenceData && getIndicatorAnswers(absenceData.accidentIndicator)}/>
                            </Col>)}
                        {shouldDisplayMotorVehicleAccidentIndicator && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='motorVehicleAccidentIndicator' label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.motorVehicleAccidentIndicatorEE)
                    : t(formLabels.motorVehicleAccidentIndicatorER))} value={absenceData && getIndicatorAnswers(absenceData.motorVehicleAccidentIndicator)}/>
                            </Col>)}
                        {absenceData?.workRelatedIndicator && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='workRelatedIndicator' label={experience === Experience.EMPLOYEE
                    ? t(formLabels.workRelatedIndicatorEE)
                    : t(formLabels.workRelatedIndicatorER)} value={absenceData && getIndicatorAnswers(absenceData.workRelatedIndicator)}/>
                            </Col>)}
                        {shouldDisplayHospitalizationIndicator && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='hospitalizationIndicator' data-private={true} label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.hospitalizationIndicatorEE)
                    : t(formLabels.hospitalizationIndicatorER))} value={absenceData && getIndicatorAnswers(absenceData?.hospitalizationIndicator)}/>
                            </Col>)}
                        {shouldDisplayAdmissionDate && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='admissionDate' label={t(formLabels.admissionDateShortLabel)} value={absenceData && absenceData.admissionDate}/>
                            </Col>)}
                        {shouldDisplayReleaseDate && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='releaseDate' label={t(formLabels.releaseDateShortLabel)} value={absenceData && absenceData.releaseDate}/>
                            </Col>)}
                        {absenceData?.surgeryIndicator && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='surgeryIndicator' data-private={true} label={CustomEmployeeText(experience === Experience.EMPLOYEE
                    ? t(formLabels.surgeryIndicatorEE)
                    : t(formLabels.surgeryIndicatorER))} value={absenceData && getIndicatorAnswers(absenceData.surgeryIndicator)}/>
                            </Col>)}
                        {shouldDisplaySurgeryDate && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='surgeryDate' label={t(formLabels.surgeryDateShortLabel)} value={absenceData && absenceData.surgeryDate}/>
                            </Col>)}
                        {shouldDisplaySurgeryType && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='surgeryType' label={(getEnvVariable('enableNewSurgeryFlow') ? surgeryTypeYesLabel : surgeryTypeLabel)} data-private={true} value={(getEnvVariable('enableNewSurgeryFlow') ? translateableSurgeryYesTypeValue : translateableSurgeryTypeValue)}/>
                            </Col>)}
                        {shouldDisplayIllnessInjuryType && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='illnessInjuryType' label={illnessInjuryTypeLabel} data-private={true} value={translateableIllnessInjuryTypeValue}/>
                            </Col>)}
                        {shouldDisplayOtherSurgery && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='otherSurgery' data-private={true} label={(getEnvVariable('enableNewSurgeryFlow') ? t(formLabels.otherIllnessInjurySurgery) : absenceData?.surgeryIndicator === 'Y' ? t(formLabels.otherSurgery) : t(formLabels.otherIllnessInjurySurgery))} value={otherInitialValue}/>
                            </Col>)}
                        {(absenceData?.leaveType === LeaveType.OWN_ILLNESS && absenceData?.leaveCategory === 'C') && (<Col xs={12} md={6} lg={6}>
                                <ReviewField data-testid='complicationsText' data-private={true} label={t(formLabels.surgeryComplicationsTextShort)} value={absenceData && absenceData?.surgeryComplicationText}/>
                            </Col>)}
                    </>)))}
        </>);
};
export default ReviewIllnessInjuryFlow;
