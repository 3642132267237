const RabEnglish = {
    reportABirthHeaderText: 'Report a birth',
    numberOfBirthsQuestonText: 'Number of births?',
    numberOfBirthsText: 'Number of births',
    birthsText: 'Births',
    deliveryTypeMultipleBirthsQuestionText: 'Were any of the births delivered cesarean?',
    deliveryTypeQuestionText: 'What type of birth did you have?',
    dateOfMultipleBirthsQuestionText: 'What was the earliest date of birth?',
    dateOfBirthQuestionText: 'What is your child’s date of birth?',
    timeOfMultipleBirthQuestionText: 'What time was the first baby born?',
    timeOfBirthQuestionText: 'What time was your child born?',
    lastDayWorkedQuestionText: 'What was your last day worked?',
    bondingQuestionText: 'If applicable, are you planning to take time off from work in addition to the {deliveryWeeks} week maternity leave to bond with your newborn?',
    hospitalAdmissionDateQuestionText: 'What day were you admitted to the hospital, if applicable?',
    hospitalAdmissionDateText: 'Hospital admission date',
    yesText: 'Yes',
    noText: 'No',
    unknownText: 'Unknown',
    vaginalText: 'Vaginal',
    cesareanText: 'Cesarean',
    submitText: 'Submit',
    verifyText: 'Verify',
    reportText: 'Report',
    successText: 'Success',
    claimAppliedText: 'The following information will be applied to claim ',
    claimAppliedConfText: 'This information will be applied to claim',
    confirmationText: 'Thanks {claimantFirstName}, your information has been submitted.',
    caseManagerText: 'Your case manager will contact you if there are any questions.',
    birthTypeText: 'Birth type',
    birthDateText: 'Birth date',
    birthTime: 'Birth time',
    lastDayWorkedText: 'Last day worked',
    bondingTimeText: 'Bonding time',
    editButton: 'Edit',
    fieldRequiredText: 'Required.',
    invalidDateText: 'Enter a valid date mm/dd/yyyy',
    dateCannotBeInFutureText: 'The date cannot be in the future.',
    timeCannotBeInFutureText: ' Time cannot be in the future.',
    noMaternityClaimText: 'No Maternity claims exist in our system.',
    reviewTitleText: 'Review',
    reviewHeaderText: 'Please review the information below for accuracy before submitting. To make any changes, select “Edit” to return to the appropriate section.',
    viewSubmissionHeaderText: 'View submission',
    confirmationViewPrintText: 'View & Print Submission',
    closeText: 'Close',
    printText: 'Print',
    backButton: 'Back',
    confirmationHeaderText: 'Confirmation',
    optional: ' (optional)',
    genericError: 'We are sorry! Something went wrong!',
    unauthorizedErrorHeader: 'We are sorry! You are not authorized to view this page.',
    unauthorizedErrorText: 'Please go back and try again later. If the problem persists, contact 1-800-431-2958 for more information.',
    stepLabelReport: 'Report',
    stepLabelReview: 'Review',
    stepLabelSuccess: 'Success',
    employeeInfo: 'Employee Info',
};
export default RabEnglish;
