export const LearnMoreText = {
    timeToMove: {
        english: 'Employees, it\'s time to move your account',
        spanish: 'Empleados, es hora de trasladar su cuenta'
    },
    benefitAccountChange: {
        english: <>We are making changes to where employees log in to access their employer sponsored benefits from <a href="#">MyLincolnPortal.com</a> to <a href="#">LincolnFinancial.com</a>.</>,
        spanish: <>Cambiaremos el lugar donde los empleados inician sesión para acceder a los beneficios patrocinados por su empleador, de <a href="#">MyLincolnPortal.com</a> <a href="#">LincolnFinancial.com</a>.</>
    },
    login: {
        english: 'Log in at MyLincolnPortal.com to start your move today.',
        spanish: 'Inicie sesión en MyLincolnPortal.com para iniciar el traslado.'
    },
    alreadyMoved: {
        english: <>If you already access your benefits from <a href="www.lincolnfinancial.com">LincolnFinancial.com</a> or the mobile app, you do not need to move your account.</>,
        spanish: <>Si ya accede a sus beneficios en <a href="www.lincolnfinancial.com">LincolnFinancial.com</a>  o en la aplicación móvil, no es necesario que traslade su cuenta.</>
    },
    faq: {
        english: 'Frequently asked questions',
        spanish: 'Preguntas frecuentes'
    },
    employeeQuestions: {
        english: 'Employee questions',
        spanish: 'Preguntas de los empleados'
    },
    employerQuestions: {
        english: 'Employer questions',
        spanish: 'Preguntas del empleador'
    },
    startMove: {
        english: 'START THE MOVE',
        spanish: 'COMENZAR EL TRASLADO'
    },
    LCNEmployee: {
        english: 'LCN-6190696-010224',
        spanish: 'LCN-6383186-020824'
    },
    LCNEmployer: {
        english: 'LCN-6190735-010224',
        spanish: 'LCN-6383186-020824'
    },
};
