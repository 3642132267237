import { Col, Row, TextInput } from '@digitools/honest-react';
import React from 'react';
import useBdmLciConfig from '../../../../../hooks/useBdmLciConfig';
import useExperience from '../../../../../hooks/useExperience';
import { getLabels } from '../../../utils/LCIUtils';
import { formatCurrency, decimalDollar, validateCurrencyOptional } from '../../../../../utils/validationUtils';
import { useFormApi } from 'informed';
const CoverageAmount = ({ claimant, event }) => {
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    const formApi = useFormApi();
    const handleBlur = (field) => (e) => {
        const val = formApi.getValue(field);
        formApi.setValue(field, decimalDollar(val));
    };
    return (<>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Employee Basic Amount'} field={'policyInfo_basicAmount'} icon={'usd'} label={`${labelEmployee} Basic (optional)`} mask={formatCurrency} onBlur={handleBlur('policyInfo_basicAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Employee Basic AD&D Amount'} field={'policyInfo_basicADandDAmount'} icon={'usd'} label={`${labelEmployee} Basic AD&D (optional)`} mask={formatCurrency} onBlur={handleBlur('policyInfo_basicADandDAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Employee Optional Amount'} field={'policyInfo_optionalAmount'} icon={'usd'} label={`${labelEmployee} Optional (optional)`} mask={formatCurrency} onBlur={handleBlur('policyInfo_optionalAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
      </Row>
      <Row style={{ marginTop: 0 }}>
        <Col lg={4} md={6} sm={12}>
          <TextInput ariaLabelText={'Employee Optional AD&D Amount'} field={'policyInfo_optionalADandDAmount'} icon={'usd'} label={`${labelEmployee} Optional AD&D (optional)`} mask={formatCurrency} onBlur={handleBlur('policyInfo_optionalADandDAmount')} type={'text'} validate={validateCurrencyOptional} validateOnChange={true}/>
        </Col>
      </Row>
    </>);
};
export default CoverageAmount;
