import React from "react";
import { Heading, themes } from "@digitools/honest-react";
import { EoiGeneralText } from "../i18n/EoiText";
import useTranslate from "src/hooks/useTranslate";
const EoiHeadings = ({ isSitusStateNY, isOnGettingStartedPage, }) => {
    const { t } = useTranslate();
    return (<div data-testid={'eoiHeadings'}>
            {isSitusStateNY &&
            <Heading data-testid='NY-Underwriter' theme={themes.lincoln} style={{ marginTop: '3.125rem', color: 'black', fontWeight: 'bold' }} elemType='h1'>
                    Lincoln Life & Annuity Company of New York
                </Heading>}
            {/* on Getting Started Page and NOT NY */}
            {isOnGettingStartedPage && !isSitusStateNY &&
            <Heading style={{ marginTop: '3.125rem', color: 'black' }} elemType='h1' theme={themes.lincoln} data-public={true}>
                    {t(EoiGeneralText.header)}
                </Heading>}
            {/* on Getting Started Page and NY */}
            {isOnGettingStartedPage && isSitusStateNY &&
            <Heading style={{ marginTop: '0.5rem' }} elemType={'h1'} elemStyle={'h3'} theme={themes.lincoln} data-public={true}>
                    {t(EoiGeneralText.nyHeader)}
                </Heading>}
            {/* NOT on Getting Started Page and NY */}
            {!isOnGettingStartedPage && isSitusStateNY &&
            <Heading style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} elemType={'h1'} elemStyle={'h3'} theme={themes.lincoln} data-public={true}>
                    {t(EoiGeneralText.header)}
                </Heading>}
            {/* NOT on Getting Started Page and NOT NY */}
            {!isOnGettingStartedPage && !isSitusStateNY &&
            <Heading style={{ marginTop: '3.125rem', color: 'black', marginBottom: '1.5rem' }} elemType={'h1'} theme={themes.lincoln} data-public={true}>
                    {t(EoiGeneralText.header)}
                </Heading>}
        </div>);
};
export default EoiHeadings;
