import React, { useContext, useState, useRef } from 'react';
import { Collapse, TextInput, Button, Form, Alert, Row, Col } from '@digitools/honest-react';
import getDisplayText from '../../../../utils/languageDisplay';
import ForgotInfoDisplayEn from '../i18n/ForgotInfoDisplayTextEn';
import ForgotInfoDisplayEs from '../i18n/ForgotInfoDisplayTextEs';
// @ts-ignore
import Styles from './ForgotInfo.scss';
import { GoogleAnalyticsContext, } from '../../../../providers/GoogleAnalyticsProvider';
import useTealium from '../../../../hooks/useTealium';
import { gaLabelFactory } from '../../../../utils/gaUtils';
import { ExperienceContext } from '../../../../providers/ExperienceProvider';
import { sendForgotPasswordEmail } from '../../../../api/AuthServiceApi';
import { getTraceId } from '../../../../utils/axiosUtils';
import Captcha from '../../../../components/captcha/Captcha';
import LanguageToggle from '../../../../utils/LanguageToggle';
import useValidUsername from '../../../../hooks/fieldValidation/useValidUsername';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
const GA_FEATURE_PREFIX = 'FORGOT_INFO';
const generateGALabel = gaLabelFactory(GA_FEATURE_PREFIX);
const ForgotPassword = ({ setIsLoading: setIsLoading, captchaInitialValue: captchaInitialValue, }) => {
    const { trackError } = useContext(GoogleAnalyticsContext);
    const { trackEvent } = useTealium();
    const { language } = useContext(ExperienceContext);
    const [forgotPasswordAlert, setForgotPasswordAlert] = useState();
    const [traceId, setTraceId] = useState('');
    const [resetCaptcha, setResetCaptcha] = useState(false);
    const formApiRef = useRef();
    const validateUsername = useValidUsername(true);
    const setFormApi = (formApi) => {
        formApiRef.current = formApi;
    };
    const resetForm = () => {
        if (formApiRef.current) {
            formApiRef.current.reset();
        }
    };
    const resetCaptchaForm = () => {
        setResetCaptcha(true);
        resetForm();
    };
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, ForgotInfoDisplayEn, ForgotInfoDisplayEs, language]);
    const handleSubmit = async ({ username: username, captcha: captcha }) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.FORGOT_PASSWORD,
            event_name: 'Forgot Password Submit'
        });
        try {
            setIsLoading(true);
            await sendForgotPasswordEmail(username, captcha);
            setForgotPasswordAlert({
                type: 'success',
                alertTitle: 'forgotPasswordSuccessAlertTitle',
                alertBody: 'forgotPasswordSuccessAlertBody',
            });
        }
        catch (error) {
            setTraceId(getTraceId(error));
            console.error('Error sending forgot password email', error);
            switch (error.response ? error.response.status : 500) {
                case 403:
                    setForgotPasswordAlert({
                        type: 'error',
                        alertTitle: 'unverifiedEmailTitle',
                        alertBody: 'unverifiedEmailBody',
                    });
                    resetCaptchaForm();
                    break;
                default:
                    resetCaptchaForm();
                    setForgotPasswordAlert({ type: 'error', alertTitle: 'unexpectedTitle', alertBody: 'unexpectedBody' });
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                        event_type: TEALIUM_EVENT.EVENT_TYPE.FORGOT_PASSWORD,
                        event_name: 'forgot-password-submit-error'
                    });
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    const requiredMessage = () => {
        return <LanguageToggle en={ForgotInfoDisplayEn.fieldRequired} es={ForgotInfoDisplayEs.fieldRequired}/>;
    };
    const captchaValidator = (value) => {
        return value == null ? requiredMessage() : undefined;
    };
    const format = (value) => (value != null ? value.trim() : value);
    const parse = (value) => (value != null ? value.trim() : value);
    return (
    /* TODO: allow Collapse to take style prop*/
    <Collapse bodyBackgroundColor='grayLightest' noBorder={true} type='box' title={getLocalDisplayText('forgotPasswordHeader')} isOpen={true} headerElemType='h2'>
      <Form onSubmit={handleSubmit} getApi={setFormApi}>
        {!!forgotPasswordAlert && (<Alert aria-describedby={'password-alert-message'} data-testid={'alert-message'} type={forgotPasswordAlert.type} style={{ marginBottom: '2rem' }}>
            <div>
              <strong className={'password-alert-message'}>
                {getLocalDisplayText(forgotPasswordAlert.alertTitle)}
              </strong>{' '}
              {getLocalDisplayText(forgotPasswordAlert.alertBody)}
              {traceId && ` (Error: ${traceId})`}
            </div>
          </Alert>)}
        <p style={{ marginTop: '-1.5rem', marginBottom: '1.5rem' }} data-testid={'forgot-password-paragraph-field'}>
          {getLocalDisplayText('forgotPasswordParagraph')}
        </p>
        <TextInput field={'username'} label={getLocalDisplayText('usernameLabel')} validate={validateUsername} validateOnBlur={true} format={format} parse={parse} data-testid={'username-field'}/>

        <Row className='mt-2'>
          <Col>
            <div className={Styles.parent}>
              <Captcha field={'captcha'} id={'forgotPasswordCaptcha'} validate={captchaValidator} validateOnChange={true} validateOnBlur={true} resetCaptcha={resetCaptcha} setCaptchaState={setResetCaptcha} initialValue={captchaInitialValue} // needed for testing purposes and should never be set outside of tests
    />
            </div>
          </Col>
        </Row>
        <div className={'d-flex mt-4'}>
          <Button type={'submit'} buttonType={'primary'} data-testid={'submit-button'}>
            {getLocalDisplayText('submitButton')}
          </Button>
        </div>
      </Form>
    </Collapse>);
};
export default ForgotPassword;
