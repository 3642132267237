import useGet from '../../../hooks/useGet';
import axios from 'axios';
import { serviceUrl } from '../constants/Constants';
import saveAs from 'file-saver';
export const getAsCustomerConfig = (experienceIdentifier) => {
    return useGet({
        url: serviceUrl.customerDataService + experienceIdentifier,
    });
};
export const CheckForPrePopData = async (prePropData, authHeader) => {
    let response = {};
    try {
        const result = await axios.post(serviceUrl.prePopService, prePropData, { headers: { Authorization: authHeader } });
        response = { data: result.data, code: result.status };
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            response = { data: null, code: e.response.status, message: e.response.statusText };
        }
    }
    return { response };
};
export const SubmitInterviewData = async (asInterviewData, experience, authHeader) => {
    return await axios.post(`/as-service/${experience.toLowerCase()}`, asInterviewData, { headers: { Authorization: authHeader } });
};
/* Calls Js-Pdf through as-service and saves as the event number - includes pass through for error messaging on calling component */
export const downloadAsConfirmationPdf = async (authHeader, submission, setDownloadError, errorMessage) => {
    try {
        const response = await axios.post(`/as-service/confirmation`, submission, {
            responseType: 'blob',
            headers: { Authorization: authHeader },
        });
        const filename = `${submission?.accommodationResponse?.eventNum}.pdf`;
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, filename);
    }
    catch (error) {
        setDownloadError(errorMessage);
    }
};
